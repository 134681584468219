import React from 'react';
import memoize from 'memoize-one';
import { BasicDropdown, Icon } from '../../../components';
import Status from './Status';

const items = (clickHandler, item, employeeMenu) => {
  const actions = [];

  if (employeeMenu[0] && !['disabled', 'approved', 'rejected', 'pending'].includes(item.status)) {
    actions.push({
      key: 'edit',
      title: 'Corregir',
      icon: <Icon icon="create" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(item, 'edit')
      }
    });
  }

  if (employeeMenu[1]) {
    actions.push({
      key: 'show',
      title: `Ver`,
      icon: <Icon icon="eye" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(item, 'show')
      }
    });
  }

  if (employeeMenu[2] && item.status === 'pending') {
    actions.push({
      key: 'approved',
      title: `Aprobar`,
      icon: <Icon icon="checkmark-circle" color="green" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(item, 'approved')
      }
    });

    actions.push({
      key: 'rejected',
      title: `Rechazar`,
      icon: <Icon icon="close-circle" height="16px" color="orange" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(item, 'rejected')
      }
    });
  }

  if (employeeMenu[3] && item?.attendance_id && !['rejected', 'pending', 'approved'].includes(item.status)) {
    if (item.status !== 'disabled') {
      actions.push({
        key: 'disabled',
        title: 'Desactivar',
        icon: <Icon icon="archive" height="16px" color="red" width="16px" style={{ marginRight: 5 }} />,
        props: {
          onClick: () => clickHandler(item, 'disabled')
        }
      });
    }
  }
  return actions;
};

const isAgreedTimeValid = item =>
  ([2, 3].includes(item.time_type) && item?.shift?.lunch_check === true && item?.shift?.collation_time === false) ||
  [0, 1].includes(item.time_type);

const columns = memoize((clickHandler, employeeMenu) => [
  {
    name: 'Fecha Inicio Turno',
    selector: 'parse_attendance_date',
    grow: '2'
  },
  {
    name: 'Rut',
    selector: 'rut',
    grow: '2'
  },
  {
    name: 'Trabajador',
    selector: 'name',
    grow: '2'
  },
  {
    name: 'Hora Pactada',
    selector: 'agreed_time',
    cell: item => (isAgreedTimeValid(item) ? item.agreed_time : ''),
    grow: '2'
  },
  {
    name: 'Fecha Marcación',
    selector: 'marking_date',
    grow: '3'
  },
  {
    name: 'Hora Marcación',
    selector: 'clock_in_out_time',
    grow: '2'
  },
  {
    name: 'Tipo Marcaje',
    selector: 'parsed_time_type',
    grow: '2'
  },
  {
    name: 'Estado',
    selector: 'status',
    cell: item => <Status status={item.status} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'Envio Correo',
    selector: 'send_email',
    sortable: true,
    grow: '2'
  },
  {
    name: 'Acciones',
    cell: item => (
      <>
        {employeeMenu.some(row => row) && (
          <BasicDropdown
            variant="outline-primary"
            noArrow
            block
            right
            titleDrop={
              <span className="align-middle">
                Acciones <Icon icon="ellipsis-vertical" height="20px" width="20px" />
              </span>
            }
            items={items(clickHandler, item, employeeMenu)}
          />
        )}
      </>
    ),
    ignoreRowClick: true,
    grow: '1',
    minWidth: '140px',
    center: true
  }
]);

export default columns;
