const overtimeDiscountsValues = [
  { label: 'Horas extras al 50%', value: 'fifty' },
  { label: 'Horas extras al 100%', value: 'hundred' },
  { label: 'Horas extras al 150%', value: 'hundred_and_fifty' },
  { label: 'Horas extras calculadas', value: 'calculated_overtime' },
  { label: 'Horas salida anticipada', value: 'early_departure_hours' },
  { label: 'Horas de atraso', value: 'hours_late' },
  { label: 'Permiso sin Goce', value: 'without_pay' }
];

const overtimeDiscountsWithoutCalculatedOvertimeValues = [
  { label: 'Horas extras al 50%', value: 'fifty' },
  { label: 'Horas extras al 100%', value: 'hundred' },
  { label: 'Horas extras al 150%', value: 'hundred_and_fifty' },
  { label: 'Horas salida anticipada', value: 'early_departure_hours' },
  { label: 'Horas de atraso', value: 'hours_late' },
];

const overtimeDiscountsFactors = [
  { label: 'Horas extras al 50%', value: '50%' },
  { label: 'Horas extras al 100%', value: '100%' },
  { label: 'Horas extras al 150%', value: '150%' }
];

const selectorTypes = [{ label: 'Todos', value: 'all_employees' }];

const onlyWithoutPay = [{ label: 'Permiso sin Goce', value: 'without_pay' }];

export {
  onlyWithoutPay,
  overtimeDiscountsValues,
  overtimeDiscountsWithoutCalculatedOvertimeValues,
  overtimeDiscountsFactors,
  selectorTypes
};
