export default {
  address: '',
  addressFloor: '',
  addressNumber: '',
  addressOffice: '',
  allowDownload: false,
  attendanceManagement: false,
  bankAccountsAttributes: [
    {
      bankId: '',
      account: ''
    }
  ],
  businessArea: '',
  businessLogo: {},
  businessName: '',
  collationPayment: '',
  communeId: '',
  communeName: '',
  compensationFund: {},
  compensationFundId: '',
  consultativeDtAccess: false,
  economicActivity: {},
  economicActivityId: '',
  email: '',
  fantasyName: '',
  mobilizationPayment: '',
  nationalIdentification: '',
  payFirstLicenseDays: false,
  phone: '',
  phoneCountryCode: '+56',
  regionId: '',
  remunerations: false,
  representantsAttributes: [
    {
      firstLastName: '',
      identificationType: '',
      name: '',
      nationalIdentification: '',
      secondLastName: '',
      imageSignature: {},
      image: ''
    }
  ],
  peopleManagement: false,
  safetyOrganization: {},
  safetyOrganizationId: '',
  signature: false,
  talentManagement: false,
  website: '',
  yearOfAgreement: ''
};
