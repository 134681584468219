import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';
import {
  deactivateShiftsRequest,
  debounceIndexShiftsRequest,
  deleteShiftRequest,
  massiveActiveShiftsRequest,
  massiveDeactivateShiftsRequest,
  massiveDestroyShiftsRequest
} from '../../requests/shifts';
import { sendAlert } from '../../actions/utils';
import { ComponentDataTable, DefaultModal } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import ShiftMassiveActions from '../../components/DatatableActions/ShiftMassiveActions';
import columns from './column';

const ShiftDataTable = ({ moreData, setMoreData }) => {
  const ability = useAbility(AbilityContext);
  const [onRequest, setOnRequest] = useState(true);
  const [shifts, setShifts] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [modalBody, setModalBody] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState('');
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setShifts(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = params => {
    const request = async () => {
      setOnRequest(true);
      debounceIndexShiftsRequest({ dispatch, params, successCallback: handleSuccessIndex });
    };
    request();
  };

  const handleSuccessActive = () => {
    setModalShow(false);
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'success', message: 'Turno Activado correctamente' }));
  };

  const handleSuccessDeactivate = (item, response) => {
    setModalShow(false);
    setMoreData(!moreData);
    if (!response.data) {
      dispatch(
        sendAlert({ kind: 'success', message: `Turno ${item.status ? 'desactivado' : 'activado'} correctamente` })
      );
    }
  };

  const handleSuccessRemove = () => {
    setModalShow(false);
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'success', message: 'Turno eliminado correctamente' }));
  };

  const handleFailureRequest = () => {
    setModalShow(false);
    dispatch(
      sendAlert({
        kind: 'error',
        message: 'Tienes trabajadores con registro de asistencia o contratos asociados a este turno'
      })
    );
    setOnRequest(false);
  };

  const handleFailureDeactivateRequest = () => {
    setModalShow(false);
    dispatch(
      sendAlert({
        kind: 'error',
        message: 'Tienes trabajadores con contratos asociados a este turno'
      })
    );
    setOnRequest(false);
  };

  const handleMassiveFailureRequest = error => {
    const { response } = error;
    setModalShow(false);
    setOnRequest(false);
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleMassiveFailureDeactivateRequest = error => {
    const { response } = error;
    setModalShow(false);
    setOnRequest(false);
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const removeShift = shift => {
    const { id } = shift;
    setOnRequest(true);
    deleteShiftRequest(id, {
      dispatch,
      successCallback: handleSuccessRemove,
      failureCallback: handleFailureRequest
    });
  };

  const deactivateShift = shift => {
    const { id } = shift;
    setOnRequest(true);
    deactivateShiftsRequest({
      params: { id },
      dispatch,
      successCallback: response => handleSuccessDeactivate(shift, response),
      failureCallback: handleFailureDeactivateRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'edit':
        if (ability.can('update', 'Shift')) history.push(`/shifts/${item.id}/edit`);
        break;
      case 'destroy':
        setModalTitle('Eliminar Turno');
        setModalShow(true);
        setModalItem(item);
        setModalBody(`¿Estás seguro que deseas eliminar el turno ${item.name}?`);
        setModalAction(() => removeShift);
        break;
      case 'deactivate':
        setModalTitle('Desactivar Turno');
        setModalShow(true);
        setModalItem(item);
        setModalBody('¿Estás seguro que deseas desactivar el turno seleccionado?');
        setModalAction(() => deactivateShift);
        break;
      case 'read':
        if (ability.can('read', 'Shift')) history.push(`/shifts/${item.id}/show`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const masiveDeleteShift = () => {
    const shiftSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    massiveDestroyShiftsRequest({
      params: { ids: shiftSelected },
      dispatch,
      successCallback: handleSuccessRemove,
      failureCallback: handleMassiveFailureRequest,
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveActiveShift = () => {
    const shiftSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    massiveActiveShiftsRequest({
      params: { ids: shiftSelected },
      dispatch,
      successCallback: handleSuccessActive,
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveDeactivateShift = () => {
    const shiftSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    massiveDeactivateShiftsRequest({
      params: { ids: shiftSelected },
      dispatch,
      successCallback: handleSuccessDeactivate,
      failureCallback: handleMassiveFailureDeactivateRequest,
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleMassAction = action => {
    switch (action) {
      case 'massive_destroy':
        setModalTitle('Eliminar Turnos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar los turnos seleccionados?');
        setModalAction(() => masiveDeleteShift);
        break;
      case 'massive_deactivate':
        setModalTitle('Desactivar Turnos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas desactivar los turnos seleccionados?');
        setModalAction(() => massiveDeactivateShift);
        break;
      case 'massive_active':
        setModalTitle('Activar Turnos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas activar los turnos seleccionados?');
        setModalAction(() => massiveActiveShift);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const shiftActions = [
    ability.can('update', 'Shift'),
    ability.can('destroy', 'Shift'),
    ability.can('deactivate', 'Shift'),
    ability.can('read', 'Shift')
  ];

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, 'shifts', shiftActions)}
        data={shifts}
        totalRows={amount}
        selectableRows
        moreData={moreData}
        resourceRequest={handleRequest}
        withMassActions
        onRowClicked={item => handleButtonClick(item, 'edit')}
        pointerOnHover={ability.can('update', 'Shift')}
        onSelectedRowsChange={handleSelectedRows}
        massActions={<ShiftMassiveActions model="Shift" handleClick={handleMassAction} disabled={!selectedCount} />}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
    </>
  );
};

export default ShiftDataTable;
