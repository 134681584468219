import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PeriodsDataTable from '../Datatables/PeriodsDataTable';

const ThirdStep = ({ handleFinishStep }) => {
  return (
    <>
      <Row className="border-line mr-1 ml-1">
        <Col className="top-header-light-gray justify-content-start border-radius-5">
          <p className="mt-0 text-left">
            <b>Etapa 3 - VER RESULTADO</b>
          </p>
        </Col>
      </Row>
      <PeriodsDataTable />
      <Row className="mb-2 row">
        <Col />
        <Col md={2}>
          <Button variant="primary" block onClick={() => handleFinishStep()}>
            Finalizar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ThirdStep;
