import React, { useState } from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import WorkdayReportSupervisorDataTable from './Datatable';
import { Icon } from '../../..';
import './Style.scss';

const Supervisor = ({ companyName, employeeName }) => {
  const [moreData, setMoreData] = useState(false);
  const [key, setKey] = useState('attendance-report');

  return (
    <Row className="main-div sm-12 pb-4">
      <Col sm={6} md={6} className="mt-2">
        {employeeName && (
          <p className="title">
            Hola <b>{employeeName}</b>, bienvenido a <b>{companyName}</b>
          </p>
        )}
      </Col>
      <Col sm={12}>
        <Tab.Container id="supervisor-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link className="mb-2 mt-2" eventKey="attendance-report">
                <Icon icon="location" />
                Asistencia
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="attendance-report">
              <WorkdayReportSupervisorDataTable moreData={moreData} setMoreData={setMoreData} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
    </Row>
  );
};

export default Supervisor;
