import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { FormikDatePicker, FormikInput } from '../Utils/Input';
import { InputSelect } from '../Utils/Select';
import { indexDocumentTemplateRequest } from '../../requests/documentTemplates';

const OvertimeAgreementsForm = ({ submitVariant, errors, touched, setFieldValue, isSubmitting, onHide }) => {
  const dispatch = useDispatch();
  const fetchDocumentTemplates = (inputValue, callback) => {
    indexDocumentTemplateRequest({
      dispatch,
      params: {
        query: inputValue,
        template_type: 'overtime_agreements',
        sort_column: 'name',
        display_length: 150
      },
      successCallback: response => callback(response.data.data)
    });
  };

  return (
    <>
      <Form>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Field name="overtimeAgreement[documentTemplateId]">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    abbr
                    label="Plantilla"
                    placeholder="Seleccionar Plantilla"
                    value={undefined}
                    request={fetchDocumentTemplates}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="overtimeAgreement[hoursNumber]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Número de horas"
                    placeholder="hh:mm:ss"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field name="overtimeAgreement[startDate]">
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    isOutsideRange={() => false}
                    label="Fecha Inicio"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    onChange={date => {
                      setFieldValue(field.name, date);
                    }}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="overtimeAgreement[endDate]">
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    isOutsideRange={() => false}
                    label="Fecha Fin"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    onChange={date => {
                      setFieldValue(field.name, date);
                    }}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ul>
                <li>Las horas ingresadas serán asignadas a todos los trabajadores seleccionados</li>
                <li>
                  Si el o los colaboradores tienen un pacto para el periodo seleccionado, este será reemplazado por uno
                  nuevo
                </li>
              </ul>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn" type="submit" variant={submitVariant} disabled={isSubmitting} onClick={onHide}>
            Crear
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const { employeeIds } = props;
  return {
    overtimeAgreement: {
      documentType: 'overtime_agreements',
      documentTemplateId: '',
      employeeIds,
      startDate: '',
      endDate: '',
      hoursNumber: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  overtimeAgreement: Yup.object().shape(
    {
      documentTemplateId: Yup.string().required('Debes seleccionar una plantilla'),
      startDate: Yup.date()
        .required('Debes seleccionar una fecha de inicio')
        .formatdate()
        .when(
          'endDate',
          (endDate, schema) => endDate && schema.max(endDate, 'Debe ser menor o igual a la fecha de término')
        ),
      endDate: Yup.date()
        .required('Debes seleccionar una fecha de fin')
        .formatdate()
        .when(
          'startDate',
          (startDate, schema) => startDate && schema.min(startDate, 'Debe ser mayor o igual a la fecha de inicio')
        ),
      hoursNumber: Yup.string()
        .trim()
        .required('Debes seleccionar el número de horas')
        .matches(
          /^([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])+$/,
          'Formato incorrecto, debe de ser: hh:mm:ss, ejemplo 02:30:00'
        )
    },

    [['endDate', 'startDate']]
  )
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(connect()(OvertimeAgreementsForm));
