import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';


export const showAdminDeviceRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/devices/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service: 'control_clock'
  });

export const indexAdminDevicesRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/devices`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: 'control_clock'
  });

export const createAdminDeviceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/devices', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    service: 'control_clock'
  });

export const updateAdminDeviceRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/devices/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    service: 'control_clock'
  });

export const deleteAdminDeviceRequest = (id, { dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', `/devices/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service: 'control_clock'
  });

export const deactivateAdminDeviceRequest = (id, { dispatch, params, formData, successCallback, failureCallback, callback }) =>
  ApiService.request('get', `/devices/${id}/change_status`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    service: 'control_clock'
  });


export const debounceIndexAdminDevicesRequest = AwesomeDebouncePromise(indexAdminDevicesRequest, 300);
