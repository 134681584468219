import ApiService from '../services/apiService';

export const indexCustomFieldsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/custom_fields', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const showCustomFieldsRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/custom_fields/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createCustomFieldRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', '/custom_fields', {
    dispatch,
    params,
    formData,
    callback,
    successCallback,
    showLeafArrayIndexes: true
  });

export const updateCustomFieldRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/custom_fields/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    showLeafArrayIndexes: true
  });

export const deleteCustomFieldRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/custom_fields/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const exportCustomFieldsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/custom_fields/export.xlsx', {
    dispatch,
    params,
    failureCallback,
    responseType: 'text',
    successCallback
  });
};

export const massiveDestroyCustomFieldRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', '/custom_fields/massive_destroy', {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const preImportCustomFieldRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/custom_fields/pre_import', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const importCustomFieldRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/custom_fields/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const importTemplateCustomFieldRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/custom_fields/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });

export const activeCustomFieldsRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/custom_fields/massive_active', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const deactivateCustomFieldsRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/custom_fields/massive_deactivate', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

// export const debounceindexCustomFieldsRequest = AwesomeDebouncePromise(indexCustomFieldsRequest, 300);
