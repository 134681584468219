import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexTerminationReasonsRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/termination_reasons', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexTerminationReasonsRequest = AwesomeDebouncePromise(indexTerminationReasonsRequest, 300);
