import React, { useState } from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import MarkingsReportIndex from './MarkingsReportIndex';
import { Icon } from '../../components';
import { useSetTab } from '../../services/hooks';
import { AbilityContext } from '../../config/abilityContext';
import TotalizedAbsenceReportIndex from '../AbsenceReports/TotalizedAbsenceReportIndex';
import AttendanceRecordReportIndex from '../AttendanceReports/AttendanceRecordReportIndex';
import AttendanceDetailReportIndex from '../AttendanceDetailReport/AttendanceDetailReportIndex';
import ArrearsReportIndex from '../AttendanceReports/ArrearsReports/ArrearsReportIndex';
import AttendanceRecordTotalIndex from '../AttendanceReports/AttendanceRecordTotalIndex';
import OvertimeRecordReportIndex from '../OvertimeReports/OvertimeRecordReportIndex';
import OvertimeAgreementsReportIndex from './OvertimeAgreements/OvertimeAgreementsReportIndex';

const CompanyReports = ({ location }) => {
  const [moreData, setMoreData] = useState(false);
  const ability = useAbility(AbilityContext);
  const setTab = () => {
    if (ability.can('export', 'Marking')) {
      return 'marking-report';
    }
    if (ability.can('export', 'AttendanceRecordReport')) {
      return 'attendance-record-report';
    }
    if (ability.can('export', 'ArrearsTotalizedReport')) {
      return 'arrears-report';
    }
    if (ability.can('export', 'OvertimeRecordReport')) {
      return 'overtime-report';
    }
    if (ability.can('index', 'OvertimeAgreementReport')) {
      return 'overtime-agreement-report';
    }
    return null;
  };
  const [key, setKey] = useSetTab(setTab, location);

  return (
    <>
      <Row className="mt-2">
        <Col>
          <h2 className="mb-2 mt-2">Informes</h2>
        </Col>
      </Row>
      <Tab.Container id="company-settings-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          {ability.can('export', 'Marking') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="marking-report">
                <Icon icon="location" />
                Registro de Marca
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('export', 'AttendanceRecordReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="attendance-record-report">
                <Icon icon="location" />
                Registro de Asistencias
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('export', 'AttendanceDetailReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="attendance-details">
                <Icon icon="location" />
                Detalle Asistencia
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('export', 'ArrearsTotalizedReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="arrears-report">
                <Icon icon="location" />
                Reporte de Atrasos
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('export', 'AttendanceTotalReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="attendance-record-total">
                <Icon icon="location" />
                Registro de asistencia TOT
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('export', 'AbsenceTotalizedReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="absence-report">
                <Icon icon="location" />
                Reporte de Ausencias
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('export', 'OvertimeRecordReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="overtime-report">
                <Icon icon="location" />
                Reporte HHEE
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('index', 'OvertimeAgreementReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="overtime-agreement-report">
                <Icon icon="location" />
                Pactos de Horas Extras
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          {ability.can('export', 'Marking') && (
            <Tab.Pane eventKey="marking-report">
              <MarkingsReportIndex moreData={moreData} setMoreData={setMoreData} />
            </Tab.Pane>
          )}
          {ability.can('export', 'AttendanceRecordReport') && (
            <Tab.Pane eventKey="attendance-record-report">
              <AttendanceRecordReportIndex rrhh />
            </Tab.Pane>
          )}
          {ability.can('export', 'AttendanceDetailReport') && (
            <Tab.Pane eventKey="attendance-details">
              <AttendanceDetailReportIndex rrhh />
            </Tab.Pane>
          )}

          {ability.can('export', 'ArrearsTotalizedReport') && (
            <Tab.Pane eventKey="arrears-report">
              <ArrearsReportIndex rrhh />
            </Tab.Pane>
          )}
          {ability.can('export', 'AttendanceTotalReport') && (
            <Tab.Pane eventKey="attendance-record-total">
              <AttendanceRecordTotalIndex rrhh />
            </Tab.Pane>
          )}
          {ability.can('export', 'AbsenceTotalizedReport') && (
            <Tab.Pane eventKey="absence-report">
              <TotalizedAbsenceReportIndex rrhh />
            </Tab.Pane>
          )}
          {ability.can('export', 'OvertimeRecordReport') && (
            <Tab.Pane eventKey="overtime-report">
              <OvertimeRecordReportIndex rrhh />
            </Tab.Pane>
          )}
          {ability.can('index', 'OvertimeAgreementReport') && (
            <Tab.Pane eventKey="overtime-agreement-report">
              <OvertimeAgreementsReportIndex />
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default CompanyReports;
