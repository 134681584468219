import React from 'react';
import memoizeOne from 'memoize-one';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import PayrollActions from './PayrollActions';
import { DatatableEmployeeName } from '../../../components';

const PayrollColumns = memoizeOne(handleClick => [
  {
    name: 'ACCIONES',
    selector: 'actions',
    cell: item => <PayrollActions handleClick={handleClick} item={camelCaseEmptyStringRecursive(item)} />,
    id: 'actions',
    grow: '1',
    style: {
      justifyContent: 'center',
      textAlign: 'center'
    }
  },
  {
    name: 'EMPLEADO',
    id: 'full_name',
    selector: 'full_name',
    grow: '1',
    cell: item => (
      <DatatableEmployeeName item={item} withPhoto={false} />
    ),
    sortable: true
  },
  {
    name: 'CENTRO DE COSTO',
    selector: 'cost_center',
    id: 'cost_center',
    grow: '1',
    sortable: true
  },
  {
    name: 'DÍAS TRABAJADOS',
    id: 'worked_days',
    cell: () => '0',
    grow: '1',
    sortable: false
  },
  {
    name: 'S. BASE',
    id: 'salary_amount',
    cell: () => '$ 0.00',
    grow: '1',
    sortable: false
  },
  {
    name: 'T. IMPONIBLE',
    id: 'assets_taxable_total',
    cell: () => '$ 0.00',
    grow: '1',
    sortable: false
  },
  {
    name: 'T. HABERES',
    id: 'assets_total',
    cell: () => '$ 0.00',
    grow: '1',
    sortable: false
  },
  {
    name: 'DESCUENTOS LEGALES',
    id: 'discounts_total_legal',
    cell: () => '$ 0.00',
    grow: '1',
    sortable: false
  },
  {
    name: 'T. DESCUENTOS',
    id: 'discounts_total',
    cell: () => '$ 0.00',
    grow: '1',
    sortable: false
  },
  {
    name: 'LÍQUIDO A PAGAR',
    id: 'liquid',
    cell: () => '$ 0.00',
    grow: '1',
    sortable: false
  }
]);

export default PayrollColumns;
