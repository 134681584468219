import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import { Card, Col, Row } from 'react-bootstrap';
import columns from './Columns';
import { ComponentDataTable, ButtonTooltip, Icon, ApprovementStatus } from '../../../components';
import {
  indexLoanInstallmentsRequest,
  sendDocumentLoanInstallmentRequest,
  sendMassiveDocumentLoanInstallmentRequest
} from '../../../requests/loanInstallments';
import { AbilityContext } from '../../../config/abilityContext';
import massActions from './massActions';
import { sendAlert } from '../../../actions/utils';
import useLoanInstallmentsHook from '../LoanInstallments/useLoanInstallmentHooks';
import useLoanProcessHook from '../useLoanProcessHooks';

const ExpandedComponent = ({ data, handleActions }) => {
  return (
    <Card>
      <Card.Body className="div-content-expander">
        <Row>
          <Col>
            <div className="info">
              <span className="type">CONCEPTO:</span>
              <span className="line" />
              <span className="answer"> {data.parsed_loan_type}</span>
            </div>
            <div className="info">
              <span className="type">ORIGEN:</span>
              <span className="line" />
              <span className="answer"> {data.parsed_origin_type}</span>
            </div>
            <div className="info">
              <span className="type">VALOR CUOTA:</span>
              <span className="line" />
              <span className="answer"> {data.parsed_amount}</span>
            </div>
            <div className="info">
              <span className="type">ESTADO:</span>
              <span className="line" />
              <span className="answer">
                <ApprovementStatus status={data.status} translatedStatus={data.parsed_status} margin="my-0" />
              </span>
            </div>
            <div className="info">
              <span className="type">ACCIONES:</span>
              <span className="line" />
              <>
                <ButtonTooltip
                  onClick={() => handleActions(data, 'send_document')}
                  variant="circle-info"
                  className="btn-circle"
                  size="sm"
                  text="Ver"
                  disabled={data.origin_type !== 'data_entry' || data.concept !== 'Préstamo' || data.sended_document}
                >
                  <Icon icon="document" />
                </ButtonTooltip>
              </>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const LoanProcessSummaryDataTable = ({ loanProcess }) => {
  const [loanProcessData, setLoanProcessData] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortParams, setSortParams] = useState({});
  const [amount, setAmount] = useState(0);
  const dispatch = useDispatch();
  const ability = useAbility(AbilityContext);
  const isMobile = window.innerWidth <= 767;
  const { handleDownloadInstallments } = useLoanInstallmentsHook();
  const { handleSortKeys } = useLoanProcessHook();

  const handleRequest = params => {
    setOnRequest(true);
    setSortParams(params);
    indexLoanInstallmentsRequest({
      dispatch,
      params: {
        filter_process_id: loanProcess.id,
        ...params
      },
      successCallback: response => {
        setLoanProcessData(response.data.data);
        setAmount(response?.data?.metadata?.amount || 0);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'send_document':
        setOnRequest(true);
        sendDocumentLoanInstallmentRequest(item.id, {
          dispatch,
          successCallback: () => {
            dispatch(sendAlert({ kind: 'success', message: 'Documento enviado correctamente' }));
          },
          callback: () => {
            setOnRequest(false);
            setClearSelectedRows(!clearSelectedRows);
            setMoreData(!moreData);
          }
        });
        break;
      default:
        break;
    }
  };

  const handleSelectedRows = item => {
    if (item) {
      const ids = item.selectedRows.map(row => row.id);
      setSelectedRows(ids);
    }
  };
  const handleMassAction = action => {
    switch (action) {
      case 'send_massive_document':
        if (selectedRows.length === 0) return;
        setOnRequest(true);
        sendMassiveDocumentLoanInstallmentRequest({
          dispatch,
          params: {
            ids: [...selectedRows]
          },
          successCallback: () => {
            dispatch(sendAlert({ kind: 'success', message: 'Documentos enviados correctamente' }));
          },
          callback: () => {
            setOnRequest(false);
            setClearSelectedRows(!clearSelectedRows);
            setMoreData(!moreData);
          }
        });
        break;
      case 'export': {
        const sortKeys = handleSortKeys(sortParams);
        const exportFilters = selectedRows.length === 0 ? { paginate: false } : { filter_ids: [...selectedRows] };
        const params = {
          filter_process_id: loanProcess.id,
          summary: true,
          ...exportFilters,
          ...sortKeys
        };
        handleDownloadInstallments(params, setClearSelectedRows, setMoreData, setOnRequest);
        break;
      }
      default:
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'national_identification':
        return { sort_employee_rut: name };
      case 'employee_name':
        return { sort_employees: name };
      case 'cost_center':
        return { sort_cost_center: name };
      case 'origin':
        return { sort_loan_origin: name };
      case 'parsed_loan_type':
        return { sort_balance: name };
      case 'parsed_first_discount_date':
        return { sort_first_discount_date: name };
      case 'amount':
        return { sort_amount: name };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      {loanProcess && (
        <ComponentDataTable
          onRequest={onRequest}
          columns={columns(handleButtonClick, isMobile, ability.can('manage', 'LoanProcess.LoanInstallment'))}
          data={loanProcessData}
          totalRows={amount}
          handleSortCase={sortColumnCase}
          moreData={moreData}
          selectableRows={ability.can('manage', 'LoanProcess.LoanInstallment')}
          clearSelectedRows={clearSelectedRows}
          resourceRequest={handleRequest}
          pointerOnHover
          massActions={massActions(handleMassAction, selectedRows.length === 0, amount)}
          withMassActions={ability.can('manage', 'LoanProcess.LoanInstallment')}
          onSelectedRowsChange={handleSelectedRows}
          expandableRows={isMobile}
          expandableRowExpanded={() => isMobile}
          expandableRowsComponent={
            isMobile ? <ExpandedComponent handleActions={handleButtonClick} isMobile={isMobile} /> : undefined
          }
          expandableRowsHideExpander
          withSearch={false}
        />
      )}
    </>
  );
};

export default LoanProcessSummaryDataTable;
