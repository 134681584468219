import React, { useContext, useState } from 'react';
import { useAbility } from '@casl/react';
import { useDispatch, useSelector } from 'react-redux';
import { ComponentDataTable } from '../../../../components';
import { AbilityContext } from '../../../../config/abilityContext';
import Columns from '../Columns/Columns';
import massiveActions from './massiveActions';
import {
  cancelAsociationPayrollApRequest,
  exportApProcesses,
  exportDataProcesses,
  indexApProcessesRequest
} from '../../../../requests/payrollAdvancePaymentProcess';
import { downloadFile } from '../../../../services/utils';
import { sendAlert } from '../../../../actions/utils';
import { PayrollProcessContext } from '../../context/PayrollProcessProvider';

const AdvancePaymentProcesses = ({ handleSelectedRows, selectedRows, handleCancelAssociation, onlyShow }) => {
  const ability = useAbility(AbilityContext);
  const { currentCompany } = useSelector(state => state.auth);
  const { payrollProcess } = useContext(PayrollProcessContext);
  const dispatch = useDispatch();
  const [apProcesses, setApProcesses] = useState([]);
  const [amount, setAmount] = useState(0);
  const [onRequest, setOnRequest] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);

  const handleSuccessIndex = response => {
    const { data } = response.data;
    setAmount(response.data.metadata.amount);
    setApProcesses(data);
    setOnRequest(false);
    setMoreData(false);
    setClearSelectedRows(false);
  };

  const handleRequest = params => {
    setOnRequest(true);
    indexApProcessesRequest({
      dispatch,
      params: {
        filter_company_id: currentCompany.id,
        filter_last_months_year: `${payrollProcess.month_number}-${payrollProcess.year}`,
        ...params
      },
      successCallback: handleSuccessIndex
    });
  };

  const callCancelAssociation = setModalShow => {
    setModalShow(oldState => ({
      ...oldState,
      disabled: true
    }));

    cancelAsociationPayrollApRequest({
      dispatch,
      params: {
        ids: selectedRows.map(row => ({ name: row.name, id: row.id })),
        payroll_process_id: payrollProcess.id
      },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Procesos anulados correctamente' }));
        setMoreData(!moreData);
        setClearSelectedRows(true);
      },
      callback: () => {
        setMoreData(!moreData);
        setClearSelectedRows(true);
        setOnRequest(false);
        setModalShow(oldState => ({
          ...oldState,
          show: false,
          body: <></>,
          title: '',
          showConfirm: false,
          text: '',
          disabled: false
        }));
      }
    });
  };

  const handleClick = action => {
    switch (action) {
      case 'massive_deactivate': {
        setClearSelectedRows(false);
        handleCancelAssociation(callCancelAssociation);
        break;
      }
      case 'massive_export': {
        const selectedIds = selectedRows.map(row => row.id);
        setOnRequest(true);
        exportApProcesses({
          dispatch,
          params: {
            sort_year_month: true,
            filter_last_months_year: `${payrollProcess.month_number}-${payrollProcess.year}`,
            filter_ids: selectedIds.length > 0 ? selectedIds : ''
          },
          successCallback: response => {
            setClearSelectedRows(true);
            downloadFile(response);
          },
          failureCallback: () => {
            dispatch(sendAlert({ kind: 'error', message: 'No se pudo exportar documento' }));
          },
          callback: () => setOnRequest(false)
        });
        break;
      }
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleExport = (payrollId, apProcessId) => {
    setOnRequest(true);
    exportDataProcesses({
      dispatch,
      params: {
        filter_process_id: apProcessId,
        payroll_process_id: payrollId
      },
      successCallback: response => {
        setMoreData(moreData);
        downloadFile(response);
      },
      failureCallback: error => {
        // eslint-disable-next-line no-console
        console.log(error);
      },
      callback: () => setOnRequest(false)
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      // case 'parsed_time':
      //   return { sort_time: name };
      // case 'employee_full_name':
      //   return { sort_employee_full_name: name };
      // case 'status':
      //   return { sort_status: name };
      // case 'shift':
      //   return { sort_shift: name };
      // case 'employee_cost_center':
      //   return { sort_cost_center: name };
      default:
        return { sort_year_month: true };
    }
  };

  return (
    <ComponentDataTable
      clearSelectedRows={clearSelectedRows}
      columns={Columns(handleExport, ability)}
      data={apProcesses}
      handleSortCase={sortColumnCase}
      massActions={massiveActions(handleClick, true, selectedRows.length === 0, onlyShow)}
      moreData={moreData}
      onRequest={onRequest}
      onSelectedRowsChange={handleSelectedRows}
      resourceRequest={handleRequest}
      selectableRows={!onlyShow}
      totalRows={amount}
      withMassActions
      withSearch={false}
      withSelection
    />
  );
};

export default AdvancePaymentProcesses;
