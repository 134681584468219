import axios from 'axios';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import jsonToFormData from 'json-form-data';

import { headers } from './api';
import API_CONFIG from '../config/configurations';
import { sendAlert, setHeaders } from '../actions/utils';
import { validateToken } from '../actions/auth';

const { url, urlPayroll, urlControlClock } = API_CONFIG;

const serializeJsonToString = obj => {
  if (!obj) return '';
  return Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
};

// Function to create formData
const bodyToFetch = (params, formData, showLeafArrayIndexes) => {
  if (formData) {
    return jsonToFormData(params, { showLeafArrayIndexes });
  }
  return JSON.stringify(params);
};

const paramsGetRequest = params => {
  const paramsUrl = serializeJsonToString(snakeCaseKeys(params));
  return paramsUrl !== '' ? `?${paramsUrl}` : '';
};

const genericErrorMessage = (error, dispatch) => {
  const { response } = error;
  if (!response) {
    return dispatch(sendAlert({ kind: 'error', message: error.message }));
  }
  let errorMessage = response.statusText;
  if (response.data && response.data.message) {
    errorMessage = response.data.message;
  }
  if (response.data && response.config.responseType === 'blob') {
    const reader = new FileReader();
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    reader.readAsText(blob);

    reader.addEventListener('loadend', () => {
      const { result } = reader;
      errorMessage = JSON.parse(result).message;
      dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    });
  } else if (response.status === 401) {
    if (response?.headers?.authorization) {
      dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    }
  } else {
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
  }
  switch (response.status) {
    case 400: // Bad request
      return { error: errorMessage, ...response };
    case 401: // Unauthorized
      dispatch(validateToken());
      return { error: errorMessage, ...response };
    case 403: // Forbidden
      return { error: errorMessage, ...response };
    case 404: // Not found
      return { error: errorMessage, ...response };
    case 408: // Request timeout
      return { error: errorMessage, ...response };
    case 422: // Unprocessable entity
      return { error: errorMessage, ...response };
    case 500: // Internal Server Error
      return { error: errorMessage, ...response };
    default:
      return { error: errorMessage, ...response };
  }
};

const genericSuccessCallback = response => {
  return camelCaseRecursive(response.data);
};

const getUrl = (api, params) => {
  let result = '';
  switch (api) {
    case 'back':
      result = url() + params;
      break;
    case 'payroll':
      result = urlPayroll() + params;
      break;
    case 'control_clock':
      result = urlControlClock() + params;
      break;
    default:
      result = '';
      break;
  }
  return result;
};

export default class ApiService {
  static request(
    method,
    route,
    {
      params = {},
      dispatch,
      responseType = 'json',
      formData = false,
      failureCallback = genericErrorMessage,
      successCallback = genericSuccessCallback,
      showLeafArrayIndexes = false,
      callback = () => null,
      service = 'back'
    }
  ) {
    let request;
    switch (method) {
      case 'get':
        request = axios({
          method: 'get',
          url: getUrl(service, route + paramsGetRequest(params)),
          headers: headers(),
          responseType
        });
        break;
      case 'post':
        request = axios({
          method: 'post',
          url: getUrl(service, route),
          responseType,
          cache: 'no-cache',
          data: bodyToFetch(params, formData, showLeafArrayIndexes),
          headers: headers(formData)
        });
        break;
      case 'put':
        request = axios({
          method: 'put',
          url: getUrl(service, route),
          responseType,
          cache: 'no-cache',
          data: bodyToFetch(params, formData, showLeafArrayIndexes),
          headers: headers(formData)
        });
        break;
      case 'delete':
        request = axios({
          method: 'delete',
          url: getUrl(service, route),
          cache: 'no-cache',
          data: JSON.stringify(snakeCaseKeys(params)),
          headers: headers()
        });
        break;
      default:
        request = axios({
          method: 'get',
          url: getUrl(service, route),
          headers: headers()
        });
    }

    return request
      .then(response => {
        dispatch(setHeaders(response.headers));
        return response;
      })
      .then(response => successCallback(response))
      .catch(error => failureCallback(error, dispatch))
      .finally(response => callback(response));
  }
}
