import React, { useState } from 'react';
import { Row, Col, Tab, Nav, Button } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { useHistory } from 'react-router-dom';
import { AbilityContext } from '../../config/abilityContext';
import { DefaultModal, Icon, ImportModal, SimpleCenteredModal } from '../../components';
import { useSetTab } from '../../services/hooks';
import ConceptsIndex from '../Concepts/ConceptsIndex';
import PreloadedConcepts from '../Concepts/PreloadedConcepts';
import RemunerationsImporter from './RemunerationsImporter';
import {
  exportBalancesRequest,
  importBalanceRequest,
  importTemplateBalanceRequest,
  preImportBalanceRequest
} from '../../requests/balances';
import { sendAlert } from '../../actions/utils';
import RemunerationsExporter from './RemunerationsExporter';
import { downloadFile } from '../../services/utils';
import BookkeeperDocumentTypeIndex from '../BookkeeperDocumentType/BookkeeperDocumentTypeIndex';
import BookkeeperDocumentTypeForm from '../BookkeeperDocumentType/BookkeeperDocumentTypeForm';
import basicBookkeeperDocumentType from '../BookkeeperDocumentType/bookkeeperDocumentType';
import { createBookkeeperDocumentTypeRequest } from '../../requests/bookkeepersDocumentTypes';
import basicBookkeeper from '../Bookkeeper/bookkeeper';
import { createBookkeeperRequest, debounceIndexBookkeepersRequest } from '../../requests/bookkeepers';
import BookkeeperForm from '../Bookkeeper/BookkeeperForm';
import BookkeeperIndex from '../Bookkeeper/BookkeeperIndex';
import SettlementSettingsIndex from '../SettlementSettings/SettlementSettingsIndex';

const RemunerationsSettings = ({ location }) => {
  const [moreData, setMoreData] = useState(false);
  const ability = useAbility(AbilityContext);
  const remunerationPermissions = [
    ability.can('read_concept', 'Balance'),
    ability.can('index', 'Bookkeeper'),
    ability.can('index', 'BookkeeperDocumentType')
  ];

  const [modalShow, setModalShow] = useState(false);
  const [modalBookkeeperShow, setModalBookkeeperShow] = useState(false);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [modalSize, setModalSize] = useState('lg');
  const [modalBody, setModalBody] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [asset, setAsset] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  let defaultTab = '';

  if (remunerationPermissions[0]) {
    defaultTab = 'concepts-tab';
  } else if (remunerationPermissions[1]) {
    defaultTab = 'bookkeepers';
  } else {
    defaultTab = 'bookkeeper_document_types';
  }

  const [key, setKey] = useSetTab(defaultTab, location);

  const handleModalClose = () => {
    setModalShow(false);
    setModalTitle('');
  };

  const handleSuccess = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setModalShow(false);
    setModalBookkeeperShow(false);
    setMoreData(!moreData);
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
    setModalShow(false);
  };

  const handleDownload = assetDownload => {
    exportBalancesRequest({
      dispatch,
      params: { filter_type: assetDownload ? 'asset' : 'discount' },
      successCallback: response => {
        setModalShow(false);
        downloadFile(response);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleCreateBookkeeperDocumentType = (bookkeeperDocumentTypeValue, setSubmitting) => {
    createBookkeeperDocumentTypeRequest({
      dispatch,
      params: snakeCaseKeys(bookkeeperDocumentTypeValue),
      formData: true,
      successCallback: () => handleSuccess('Tipo de Documento creado con éxito'),
      callback: () => setSubmitting(false)
    });
  };

  const newBookkeeperDocumentType = () => {
    setModalTitle('Nuevo Tipo de Documento');
    setModalSize('md');
    setModalBookkeeperShow(true);
    setModalBody(
      <BookkeeperDocumentTypeForm
        bookkeeperDocumentType={basicBookkeeperDocumentType}
        action="new"
        formRequest={handleCreateBookkeeperDocumentType}
        cancelAction={() => setModalBookkeeperShow(false)}
      />
    );

    return null;
  };

  const handleCreateBookkeeper = (bookkeeperValue, setSubmitting) => {
    createBookkeeperRequest({
      dispatch,
      params: snakeCaseKeys(bookkeeperValue),
      formData: true,
      successCallback: () => handleSuccess('Cuenta contable creada con éxito'),
      callback: () => setSubmitting(false)
    });
  };

  const newBookkeeper = bookkeeperLatestCode => {
    setModalTitle('Nueva cuenta contable');
    setModalSize('lg');
    setModalBody(
      <BookkeeperForm
        bookkeeper={{ ...basicBookkeeper, code: bookkeeperLatestCode }}
        action="new"
        formRequest={handleCreateBookkeeper}
        cancelAction={() => setModalBookkeeperShow(false)}
      />
    );
    // setModalShow(true);
    setModalBookkeeperShow(true);
  };

  const downloadModal = assetDownload => {
    setModalShow(true);
    setModalTitle(`Exportar ${assetDownload ? 'haberes' : 'descuentos'}`);
    setModalItem(assetDownload);
    setModalAction(() => handleDownload);
    setModalBody(`¿Estás seguro que deseas descargar ${assetDownload ? 'haberes' : 'descuentos'}?`);
  };

  const handleActions = action => {
    setModalItem({});
    switch (action) {
      case 'assets':
        setModalTitle('Importar Haberes');
        setSimpleModalShow(true);
        setAsset('haberes');
        setModalItem('assets');
        break;
      case 'discounts':
        setModalTitle('Importar Descuentos');
        setSimpleModalShow(true);
        setAsset('');
        setModalItem('discounts');
        break;
      case 'assets_download':
        downloadModal('assets');
        break;
      case 'discounts_download':
        downloadModal();
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const bookkeeperLatestCodeRequest = () => {
    debounceIndexBookkeepersRequest({
      dispatch,
      params: {
        display_length: 1,
        sort_code: 'code',
        sort_direction: 'desc'
      },
      successCallback: result => {
        if (result.data.data.length > 0) {
          const { code } = result?.data?.data[0];
          newBookkeeper(parseInt(code, 10) + 1);
        } else {
          newBookkeeper(1);
        }
      }
    });

    return null;
  };

  const handleClick = () => {
    switch (key) {
      case 'concepts-tab':
        history.push(`/remunerations_settings/new`);
        break;
      case 'bookkeeper_document_types':
        return newBookkeeperDocumentType();
      case 'bookkeepers':
        return bookkeeperLatestCodeRequest();
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        return '';
    }
    return null;
  };

  return (
    <>
      <Row className="mt-4 mb-2">
        <Col>
          <h2 className="mb-3 mt-3">Configuraciones</h2>
        </Col>
        {ability.can('create_concept', 'Balance') && key === 'concepts-tab' && (
          <Col md={5}>
            <RemunerationsExporter handleClick={handleActions} />
            <RemunerationsImporter handleClick={handleActions} />
          </Col>
        )}
        <Col md={2}>
          <Button variant="primary" block onClick={handleClick}>
            Nuevo
          </Button>
        </Col>
      </Row>
      <Tab.Container id="company-settings-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          {ability.can('read_concept', 'Balance') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="concepts-tab">
                <Icon icon="cube" />
                Mantenedor de conceptos
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('read_concept', 'Balance') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="preloaded-concepts-tab">
                <Icon icon="cube" />
                Conceptos Precargados
              </Nav.Link>
            </Nav.Item>
          )}

          {ability.can('index', 'BookkeeperDocumentType') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="bookkeeper_document_types">
                <Icon icon="location" />
                Tipo de Doc. Contables
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('index', 'Bookkeeper') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="bookkeepers">
                <Icon icon="albums" />
                Cuentas Contables
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="settlement-settings-tab">
              <Icon icon="albums" />
              Simulador de Finiquitos
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="concepts-tab">
            <ConceptsIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="preloaded-concepts-tab">
            <PreloadedConcepts moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          {ability.can('index', 'BookkeeperDocumentType') && (
            <Tab.Pane eventKey="bookkeeper_document_types">
              <BookkeeperDocumentTypeIndex moreData={moreData} setMoreData={setMoreData} />
            </Tab.Pane>
          )}
          {ability.can('index', 'Bookkeeper') && (
            <Tab.Pane eventKey="bookkeepers">
              <BookkeeperIndex moreData={moreData} setMoreData={setMoreData} />
            </Tab.Pane>
          )}
          <Tab.Pane eventKey="settlement-settings-tab">
            <SettlementSettingsIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <SimpleCenteredModal
        title={`Importar ${asset ? 'haberes' : 'descuentos'}`}
        body={
          <ImportModal
            onDropUploaded={preImportBalanceRequest}
            handleTemplate={importTemplateBalanceRequest}
            onHide={importBalanceRequest}
            hideModal={() => setSimpleModalShow(false)}
            params={{ filter_type: asset ? 'asset' : 'discount' }}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        }
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        size={modalSize}
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={modalBookkeeperShow}
        onHide={() => setModalBookkeeperShow(false)}
        size={modalSize}
      />
    </>
  );
};

export default RemunerationsSettings;
