import ApiService from '../services/apiService';

export const assetsDetailsReportRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_process_reports/assets_detail.xlsx`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: 'payroll'
  });

export const discountDetailsReportRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_process_reports/discounts_detail.xlsx`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: 'payroll'
  });

export const remunerationBookRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_process_reports/remuneration_book.xlsx`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: 'payroll'
  });

export const centralizationReportRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_process_reports/accounting_centralization.xlsx`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: 'payroll'
  });

export const processDetailReportRequest = (
  id,
  { dispatch, params = {}, format, responseType, successCallback, callback }
) =>
  ApiService.request('get', `/payroll_process_reports/${id}/process_detail${format}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback,
    service: 'payroll'
  });

export const processQuoteReportRequest = (
  id,
  { dispatch, params = {}, format, responseType, successCallback, callback }
) =>
  ApiService.request('get', `/payroll_process_reports/${id}/quote_detail${format}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback,
    service: 'payroll'
  });
