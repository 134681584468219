import React from 'react';
import { Table } from 'react-bootstrap';

const AttendanceRecordTotalFilterDataTable = ({ attendancesTotal }) => {
  const renderData = () => {
    return (
      <>
        <Table className="custom-data-table" bordered size="sm" style={{ fontWeight: 400 }}>
          <thead className="rdt_TableHeadRow" style={{ backgroundColor: '#EBEBEB', textTransform: 'uppercase' }}>
            <tr className="rdt_TableCol">
              <th className="text-center align-middle">Total Horas Asignadas en Jornada</th>
              <th className="text-center align-middle">Total Horas Reales Trabajadas</th>
              <th className="text-center align-middle">Total Horas Asignadas en Colación</th>
              <th className="text-center align-middle">Total Horas Reales en Colación</th>
              <th className="text-center align-middle">Total HHEE Autorizadas</th>
              <th className="text-center align-middle">Total HHEE Realizadas</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td className="text-center align-middle"> {attendancesTotal.parsed_shift_time} </td>
              <td className="text-center align-middle"> {attendancesTotal.parsed_attendance_day_t} </td>
              <td className="text-center align-middle"> {attendancesTotal.parsed_shift_break_time} </td>
              <td className="text-center align-middle"> {attendancesTotal.p_attendance_break_t} </td>
              <td className="text-center align-middle"> {attendancesTotal.p_overtime}</td>
              <td className="text-center align-middle"> {attendancesTotal.p_hours_extra_time} </td>
            </tr>
          </tfoot>
        </Table>
      </>
    );
  };

  return <>{renderData()}</>;
};

export default AttendanceRecordTotalFilterDataTable;
