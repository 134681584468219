import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexEmployeeCourseRequest = ({ dispatch, params, successCallback, callback }) => {
  ApiService.request('get', '/employee_courses', {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const createEmployeeCourseRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('post', `/courses/${id}/employee_courses`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const massCreateEmployeeCourseRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('post', `/courses/${id}/employee_courses/massive_create`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const updateEmployeeCourseRequest = (courseId, id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('put', `/courses/${courseId}/employee_courses/${id}`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const deleteEmployeeCourseRequest = (courseId, id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/courses/${courseId}/employee_courses/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const massiveDestroyEmployeeCourseRequest = (
  courseId,
  { dispatch, params, failureCallback, successCallback, callback }
) =>
  ApiService.request('post', `/courses/${courseId}/employee_courses/massive_destroy`, {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const massiveUpdateEmployeeCourseRequest = (
  courseId,
  { dispatch, params, failureCallback, successCallback, callback }
) =>
  ApiService.request('post', `/courses/${courseId}/employee_courses/massive_update`, {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const debounceIndexEmployeeCourseRequest = AwesomeDebouncePromise(indexEmployeeCourseRequest, 300);
