import React from 'react';
import Icon from '../../components/Icons';
import { BasicDropdown } from '../../components';

const ReportActions = ({ handleClick, disabled, dates, attrr = false, showDatePeriod = true, isArial = false }) => {
  const actions = () => {
    const authorizedActions = [];
    authorizedActions.push({
      icon: <Icon icon="file-earmark-excel" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'download_excel',
      props: {
        onClick: () => handleClick('download_excel'),
        disabled
      },
      title: 'Descargar Excel'
    });
    if (attrr === false) {
      authorizedActions.push({
        icon: <Icon icon="file-earmark-word" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'download_docx',
        props: {
          onClick: () => handleClick('download_docx'),
          disabled
        },
        title: 'Descargar Word'
      });

      authorizedActions.push({
        icon: <Icon icon="file-earmark-pdf" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'download_pdf',
        props: {
          onClick: () => handleClick('download_pdf'),
          disabled
        },
        title: 'Descargar PDF'
      });

      authorizedActions.push({
        icon: <Icon icon="printer" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'print',
        props: {
          onClick: () => handleClick('print'),
          disabled
        },
        title: 'Imprimir'
      });
    }

    return authorizedActions;
  };

  return (
    <div className="d-flex mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className={`align-middle ${isArial ? 'arial-dt' : ''}`}>
            Descargar <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
      {showDatePeriod && (
        <p style={{ marginBlock: 'auto', marginLeft: '15px' }} className={`${isArial ? 'arial-dt' : ''}`}>
          Periodo Seleccionado: {dates[0]} - {dates[1]}
        </p>
      )}
    </div>
  );
};

export default ReportActions;
