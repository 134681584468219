import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal } from '../../../components';
import { downloadFile } from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import ReportActions from './ReportActions';
import { debounceovertimeAgreementReportReport } from '../../../requests/overtimeAgreements';
import OvertimeAgreementsFilters from './OvertimeAgreementsFilters';
import columns from './Columns';
import OvertimeAgreementsReportFilterDataTable from './OvertimeAgreementsReportFilterDataTable';

export const WorkdayExpander = props => {
  const { data, setDocumentModalBody, setDocumentModalShow } = props;
  const { overtime_agreements: overtimeAgreements } = data;

  return (
    <OvertimeAgreementsReportFilterDataTable
      overtimeAgreements={overtimeAgreements}
      setDocumentModalBody={setDocumentModalBody}
      setDocumentModalShow={setDocumentModalShow}
    />
  );
};

const OvertimeAgreementsReportDataTable = () => {
  const [amount, setAmount] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const [tile, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [parameters, setParameters] = useState([]);
  const [filters, setFilters] = useState();
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [documentModalBody, setDocumentModalBody] = useState('');
  const [dateRange, setDateRange] = useState([]);

  const dispatch = useDispatch();
  const componentRef = useRef();

  const printInfo = useReactToPrint({
    content: () => componentRef.current
  });

  const handleFailureRequest = error => {
    const message = error?.response?.data?.message || 'TRABAJADOR NO ENCONTRADO';
    setAmount(0);
    setEmployees([]);
    dispatch(sendAlert({ kind: 'error', message }));
  };

  const handleRequest = (params, format = null, type = 'json') => {
    const responseType = params.type === '.docx' ? 'blob' : type;
    const responseFormat = format === '.print' ? '.json' : format;
    const customParams = {
      ...params,
      filter_overtime_agreements: params.range_date_workday
    };

    setOnRequest(true);
    debounceovertimeAgreementReportReport({
      dispatch,
      params: {
        display_start: 0,
        display_length: 15,
        ...customParams,
        sort_direction: 'asc',
        sort_column: 'name',
        is_dt: false,
        paginate: format === null
      },
      format: responseFormat,
      responseType,
      successCallback: response => {
        const { data } = response;
        if (format === '.xlsx' || format === '.docx' || format === '.pdf') {
          downloadFile(response);
        } else if (format === '.print') {
          setSubmitting(false);
          printInfo();
        } else {
          setAmount(data.metadata.amount);
          setEmployees(data.data);
        }
      },
      failureCallback: handleFailureRequest,
      callback: () => {
        setOnRequest(false);
        setSubmitting(false);
      }
    });
  };

  const handleMassAction = action => {
    switch (action) {
      case 'download_excel':
        setTitle('Exportar Reporte pacto de horas extras');
        setBody('¿Estás seguro que quieres exportar el reporte pacto de horas extras en formato Excel?');
        setParameters([{ ...filters, type: '.xlsx' }, '.xlsx', 'text']);
        setModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };
  const handleFilterAdvance = params => {
    setFilters(params);
    setDateRange(params.range_date_workday);
    setSubmitting(true);
    switch (params.type) {
      case null:
        setSearchFirstStart(true);
        setMoreData(!moreData);
        break;
      case '.xlsx':
        setTitle('Exportar Reporte pacto de Horas Extras');
        setBody('¿Estás seguro que quieres exportar el reporte pactos de Horas Extras en formato Excel?');
        setParameters([params, '.xlsx', 'text']);
        setModalShow(true);
        break;
      default:
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <OvertimeAgreementsFilters formRequest={handleFilterAdvance} isSubmit={isSubmitting} />

      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        moreData={moreData}
        data={employees}
        responsive
        totalRows={amount}
        handleSortCase={sortColumnCase}
        withMassActions
        massActions={
          <ReportActions
            attrr
            disabled={onRequest || dateRange.length === 0}
            handleClick={handleMassAction}
            dates={dateRange}
            showDatePeriod={false}
          />
        }
        resourceRequest={params => {
          handleRequest({ ...params, ...filters });
        }}
        expandableRows
        expandableRowExpanded={() => true}
        expandableRowsComponent={
          <WorkdayExpander setDocumentModalBody={setDocumentModalBody} setDocumentModalShow={setDocumentModalShow} />
        }
        searchFirstStart={searchFirstStart}
      />

      <DefaultModal
        title={tile}
        body={body}
        show={modalShow}
        handleClose={() => {
          setModalShow(false);
          setSubmitting(false);
        }}
        handleConfirm={() => {
          handleRequest(...parameters);
          setModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        body={documentModalBody}
        onHide={() => setDocumentModalShow(false)}
        show={documentModalShow}
        size="xl"
      />
    </>
  );
};

export default OvertimeAgreementsReportDataTable;
