import React from 'react';
import { Col } from 'react-bootstrap';

function VerticalBarGraphic({ subtitle = '', type = '' }) {
  if (typeof subtitle === 'object') {
    if (type === 'text') {
      return (
        <div className="chartWords">
          {Object.keys(subtitle).map(costCenter => (
            <Col key={costCenter} className="Division">
              <div className="Col-Max-width-Modified">
                <p className="rotated-text">{costCenter}</p>
              </div>
            </Col>
          ))}
        </div>
      );
    }

    return (
      <div className="chart">
        {Object.keys(subtitle).map(costCenter => (
          <Col key={costCenter} className="position-down">
            <p className="DescriptionBar">{subtitle[costCenter]}</p>
            <div className="Verticalbar" style={{ height: `${subtitle[costCenter] * 2}px` }} />
          </Col>
        ))}
      </div>
    );
  }
  return null;
}

export default VerticalBarGraphic;