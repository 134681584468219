import React from 'react';
import memoize from 'memoize-one';
import ActionButtons from './ActionButtons';
import { DatatableEmployeeName } from '../../../components';

export default memoize((clickHandler, onlyShow) => [
  {
    name: 'EMPLEADO',
    selector: 'employee_name',
    cell: item => (
      <>
        <DatatableEmployeeName
          item={item.employee}
          withPhoto={false}
          name="full_name"
          startDateContract={item?.start_date_contract}
        />
      </>
    )
  },
  {
    name: 'CENTRO DE COSTO',
    selector: 'cost_center',
    grow: '1'
  },
  {
    name: 'TIPO DE CONCEPTO',
    selector: 'parsed_type'
  },
  {
    name: 'CONCEPTO',
    selector: 'parsed_balance_name',
    grow: '1'
  },
  {
    name: 'MONTO',
    selector: 'parsed_amount',
    grow: '1'
  },
  {
    name: 'ACCIONES',
    selector: 'concept',
    cell: item => {
      if (!onlyShow) {
        return <ActionButtons clickHandler={clickHandler} isEditing={false} editingId={-1} row={item} />;
      }
      return null;
    }
  }
]);
