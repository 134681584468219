import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { Button, Row, Col, Table } from 'react-bootstrap';
import '../../../services/yupCustomMethods';
import {
  workdaysBetweenHolidayRequest,
  reincorporationDateHolidayRequest
} from '../../../requests/holidays';
import { handleDaysShow } from '../../../services/utils';
import {
  ButtonTooltip,
  DatatableEmployeeName,
  FormikDatePicker,
  FormikSelect,
  Icon,
  Loader,
  SimpleCenteredModal
} from '../../../components';
import { absenceTypes, selectorTypes } from '../../Absence/FormOptions';
import EmployeeSearchModalPayroll from '../../../components/Employee/EmployeeSearchModalPayroll';
import { payrollEmployeesRequest } from '../../../requests/payrolls';

const basicAbsence = () => ({
  absenceType: '',
  startDate: '',
  endDate: '',
  days: '',
  employeeId: ''
});

const AttendanceMovementsForm = props => {
  const { setFieldValue, values, isSubmitting, payroll } = props;
  const [selector, setSelector] = useState(selectorTypes);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const { attendanceMovement } = values;
  const payrollStartDate = moment(payroll?.start_date, 'YYYY-MM-DD');
  const payrollEndDate = moment(payroll?.end_date, 'YYYY-MM-DD').set({ hour: 23, minute: 59 });

  const fetchEmployees = () => {
    if (payroll.id) {
      setLoading(true);
      payrollEmployeesRequest({
        dispatch,
        params: {
          filter_payroll_process: payroll.id
        },
        successCallback: response => {
          const uniqueEmployees = response.data.data.filter((item, index, self) =>
            index === self.findIndex((t) => (
              t.id === item.id
            ))
          );
          const dataEmployees = [{ label: 'Todos', value: 'all_employees' }, ...uniqueEmployees];
          setSelector(uniqueEmployees.length > 0 ? dataEmployees : []);
          setLoading(false);
        }
      });
    }
  };

  useEffect(fetchEmployees, [payroll]);

  const addAllEmployees = () => {
    const vSelector = selector.filter(selected => selected.id !== undefined);
    const employeesToAdd = vSelector.map(employee => ({ ...basicAbsence(), employeeId: employee.value, employee }));
    const vAbsences = [...attendanceMovement, ...employeesToAdd];
    setFieldValue('attendanceMovement', vAbsences);
  };

  const handleSelector = () => {
    const { employeeSelector } = props.values;
    const vSelector = selector.filter(selected => selected.value !== employeeSelector);
    if (vSelector.length === selector.length) {
      return;
    }
    if (employeeSelector === 'all_employees') {
      addAllEmployees();
    } else {
      const employeeToAdd = selector.find(selected => selected.value === employeeSelector);
      const absencesLength = attendanceMovement.length;
      setFieldValue(`attendanceMovement[${absencesLength}]`, {
        ...basicAbsence(),
        employeeId: employeeToAdd.value,
        employee: employeeToAdd
      });
      setSelector(selector);
    }
  };

  const removeFromTable = (absence, action) => {
    if (action === 'destroy') {
      const indexObject = attendanceMovement.findIndex(selected => selected.employee.id === absence.employee.id);
      if (indexObject !== -1) {
        const updatedAbsences = [...attendanceMovement];
        updatedAbsences.splice(indexObject, 1);
        setFieldValue('attendanceMovement', updatedAbsences);
      }
    }
  };

  const calculateDays = ({ absence, index, startDate, endDate }) => {
    let newStartDate = '';
    let newEndDate = '';
    if (startDate) {
      newStartDate = startDate;
      newEndDate = absence.endDate;
    }
    if (endDate) {
      newStartDate = absence.startDate;
      newEndDate = endDate;
    }
    workdaysBetweenHolidayRequest({
      dispatch,
      params: {
        start_date: newStartDate,
        end_date: newEndDate,
        include_all_days: true
      },
      successCallback: response => setFieldValue(`attendanceMovement[${index}][days]`, response.data)
    });
  };

  const calculateReincorporationDate = ({ index, endDate }) => {
    reincorporationDateHolidayRequest({
      dispatch,
      params: {
        end_date: endDate,
        employee_id: attendanceMovement[index].employee.id
      },
      successCallback: response =>
        setFieldValue(`attendanceMovement[${index}][reincorporationDate]`, response.data.date)
    });
  };

  const updateReincorporationDate = ({ absence, index, endDate }) => {
    calculateDays({ absence, index, endDate });
    calculateReincorporationDate({ index, endDate });
  };

  const handleSearch = selectedEmployees => {
    const vSelector = selector.filter(selected => !selectedEmployees.includes(selected.value));
    setModalShow(false);
    if (vSelector.length === selector.length) return;
    const employeesToAdd = selector
      .filter(selected => selectedEmployees.includes(selected.value))
      .map(employee => ({ ...basicAbsence(), employeeId: employee.value, employee }));
    setFieldValue('attendanceMovement', [...values.attendanceMovement, ...employeesToAdd]);
    setSelector(vSelector);
  };

  const { onHide, submitVariant, errors, touched, action, setFieldTouched } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  return (
    <Form>
      <div className="info-box ml-0">
        <h4 className="text-uppercase">Agregar Receptores</h4>
        <p className="info">
          <span className="full-width">Seleccione trabajadores para incluir en el proceso</span>
        </p>
      </div>
      <Row className="align-items-center mb-3 mb-md-0">
        <Col md={5}>
          <Field name="employeeSelector">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Generar Para"
                placeholder="Seleccionar Trabajador"
                options={selector}
                defaultValue="all_employees"
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {loading ? (
          <Col md={2} xl={2}>
            <Loader
              containerStyle={{ height: '35px' }}
              loaderSize={30}
              message="Cargando trabajadores..."
              messageStyle={{ fontSize: '14px' }}
            />
          </Col>
        ) : (
          <Col xs={6} md={2} xl={2}>
            <Button variant="primary" onClick={handleSelector} style={{ marginTop: '5px' }}>
              Agregar
            </Button>
          </Col>
        )}
        <Col>
          <ButtonTooltip
            variant="circle-primary"
            className="advance-search"
            text="Búsqueda Avanzada"
            onClick={() => setModalShow(true)}
          >
            <Icon className="w-100 h-100" icon="people-circle" />
          </ButtonTooltip>
        </Col>
      </Row>
      <Row>
        <Table responsive>
          <thead>
            <tr>
              <th>NOMBRE</th>
              <th className="text-center" style={{ minWidth: '200px' }}>
                MOTIVO
              </th>
              <th className="text-center" style={{ maxWidth: '170px' }}>
                INICIO
              </th>
              <th className="text-center" style={{ maxWidth: '170px' }}>
                TÉRMINO
              </th>
              <th className="text-center" style={{ maxWidth: '70px' }}>
                DÍAS
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {attendanceMovement.map((absence, index) => (
              <tr key={`attendanceMovement-${index.toString()}`}>
                <td className="employee-name">
                  <DatatableEmployeeName
                    item={absence.employee}
                    fileName="file_info"
                    name="full_name"
                    fileUrl="file_url"
                  />
                </td>
                <td>
                  <Field name={`attendanceMovement[${index}][absenceType]`}>
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        placeholder="Seleccionar"
                        options={absenceTypes}
                        defaultValue={absence.absenceType}
                        onChange={data => {
                          setFieldValue(field.name, data ? data.value : '');
                        }}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        margin="mb-0"
                      />
                    )}
                  </Field>
                </td>
                <td>
                  <Field name={`attendanceMovement[${index}][startDate]`}>
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        isOutsideRange={day => day.isAfter(payrollEndDate) || day.isBefore(payrollStartDate)}
                        placeholder="dd/mm/aaaa"
                        onDateChange={date => {
                          const vDate = date !== null ? date.format('DD/MM/YYYY') : '';
                          calculateDays({ absence, index, startDate: vDate });
                          setFieldValue(field.name, vDate);
                          setFieldValue(`attendanceMovement[${index}][filterStartDate]`, date !== null ? date : '');
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        margin="mb-0"
                      />
                    )}
                  </Field>
                </td>
                <td>
                  <Field name={`attendanceMovement[${index}][endDate]`}>
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        isOutsideRange={day => day.isAfter(payrollEndDate) || day.isBefore(payrollStartDate)}
                        placeholder="dd/mm/aaaa"
                        onDateChange={date => {
                          const vDate = date !== null ? date.format('DD/MM/YYYY') : '';
                          updateReincorporationDate({ absence, index, endDate: vDate });
                          setFieldValue(field.name, vDate);
                          setFieldValue(`attendanceMovement[${index}][filterEndDate]`, date !== null ? date : '');
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        margin="mb-0"
                      />
                    )}
                  </Field>
                </td>
                <td className="text-center align-middle">{absence.days && handleDaysShow(absence, 'days')}</td>
                <td>
                  <ButtonTooltip
                    onClick={() => removeFromTable(absence, 'destroy')}
                    variant="circle-danger"
                    className="btn-circle"
                    size="sm"
                    text="Eliminar"
                  >
                    <Icon icon="trash" />
                  </ButtonTooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <Row className="d-flex justify-content-end m-t-10 m-b-30">
        <Col md={3} xl={2}>
          <Button type="submit" className="mb-4" disabled={isSubmitting} variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
      <SimpleCenteredModal
        title="Buscar Empleados"
        body={
          <EmployeeSearchModalPayroll
            customParams={{
              filter_payroll_process: payroll.id
            }}
            payrollValidIds={payroll?.payroll_employee_ids || []}
            handleClose={() => setModalShow(false)}
            formRequest={handleSearch}
          />
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Form>
  );
};

const setInitialValues = () => {
  return {
    attendanceMovement: [],
    employeeSelector: 'all_employees'
  };
};

const validationSchema = Yup.object().shape({
  attendanceMovement: Yup.array().of(
    Yup.object().shape(
      {
        endDate: Yup.date()
          .required('Debes seleccionar una fecha de término')
          .formatdate()
          .when(
            'startDate',
            (startDate, schema) => startDate && schema.min(startDate, 'Debe ser mayor o igual a la fecha de inicio')
          ),
        startDate: Yup.date()
          .required('Debes seleccionar una fecha de inicio')
          .formatdate()
          .when(
            'endDate',
            (endDate, schema) => endDate && schema.max(endDate, 'Debe ser menor o igual a la fecha de término')
          ),
        absenceType: Yup.string().required('Debes seleccionar un tipo de ausencia')
      },
      [['endDate', 'startDate']]
    )
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AttendanceMovementsForm);
