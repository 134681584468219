import React, { useState } from 'react';
import Icon from '../../Icons';
import MarkerCompanyLogo from '../empresa-pin.png';

const ToolTip = ({ title }) => {
  return (
    <div
      style={{
        position: 'absolute',
        backgroundColor: 'black',
        color: 'white',
        fontSize: '18',
        height: 20,
        width: `${title.length * 7}px`,
        top: -30,
        transform: 'translate(-50%, -50%)',
        borderRadius: 10,
        zIndex: 10
      }}
    >
      <div
        style={{
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 3
        }}
      >
        {title}
      </div>
    </div>
  );
};
const CustomMarker = ({ isBranchOffice = false, title = null, key }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  return (
    <>
      {isBranchOffice ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} key={key}>
          {showTooltip && <ToolTip title={title} />}
          <img
            alt="Company"
            src={MarkerCompanyLogo}
            style={{
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              width: 25,
              height: 30
            }}
          />
        </div>
      ) : (
        <>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} key={key}>
            {showTooltip && <ToolTip title={title} />}
            <Icon
              icon="location"
              style={{
                color: isBranchOffice ? 'black' : 'red',
                height: 30,
                width: 30,
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                left: '-50%',
                top: '-50%'
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
export default CustomMarker;
