import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexPayrollProcessesRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const indexApiPayrollRequest = ({ dispatch, params = {}, successCallback, callback, service = 'payroll' }) =>
  ApiService.request('get', `/payroll_processes`, {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });

export const showPayrollProcessRequest = (id, { dispatch, params = {}, successCallback, callback, service }) =>
  ApiService.request('get', `/payroll_processes/${id}`, {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });

export const createPayrollProcessRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('post', `/payroll_processes`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const createApiPayrollProcessRequest = ({ dispatch, params, successCallback, callback, service = 'payroll' }) =>
  ApiService.request('post', `/payroll_processes`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback,
    service
  });

export const deleteApiPayrollProcessRequest = (
  id,
  { dispatch, params = {}, successCallback, callback, service = 'payroll' }
) =>
  ApiService.request('delete', `/payroll_processes/${id}`, {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });

export const closeApiPayrollProcessRequest = (
  id,
  { dispatch, params = {}, successCallback, callback, service = 'payroll' }
) =>
  ApiService.request('put', `/payroll_processes/${id}/close`, {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });

export const massiveDestroyApiPayrollProcessRequest = ({
  dispatch,
  params = {},
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('delete', `/payroll_processes/massive_destroy`, {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });

export const closedPayrollProcessRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('put', `/payroll_processes/${id}/close`, {
    dispatch,
    successCallback,
    callback
  });

export const exportPayrollProcessRequest = ({ dispatch, params, successCallback, callback, service = 'payroll' }) =>
  ApiService.request('get', `/payroll_processes/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback,
    service
  });

export const budgetPayrollProcessRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/budget.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const remunerationsReportRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/${id}/remunerations.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const summarizedRemunerationsReportRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/${id}/summarized_remunerations.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const remunerationsSummaryReportRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/${id}/remunerations_summary.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const singleRemunerationsReportRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/${id}/single_remunerations.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const senceReportRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/${id}/sence_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const exportPreviredRequest = (
  id,
  { dispatch, responseType = 'text', params = {}, callback, successCallback, failureCallback }
) =>
  ApiService.request('get', `/payroll_processes/${id}/previred.text`, {
    dispatch,
    params,
    responseType,
    successCallback,
    failureCallback,
    callback
  });

export const payrollProcessSummaryRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/${id}/summary`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: 'payroll'
  });

export const exportPreviredPositionRequest = (
  id,
  { dispatch, responseType = 'text', params = {}, callback, successCallback }
) =>
  ApiService.request('get', `/payroll_processes/${id}/previred_position.text`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback,
    service: 'payroll'
  });

export const exportPreviredSeparatorRequest = (
  id,
  { dispatch, responseType = 'text', params = {}, callback, successCallback }
) =>
  ApiService.request('get', `/payroll_processes/${id}/previred_separator.${responseType}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback,
    service: 'payroll'
  });

export const payrollProcessSummaryExportRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_processes/export_summary.xlsx`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: 'payroll'
  });

export const payrollElectronicRemunerationBook = (
  id,
  { dispatch, params = {}, successCallback, callback, format, failureCallback }
) =>
  ApiService.request('get', `/payroll_processes/${id}/remuneration_book.${format}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    service: 'payroll'
  });

export const reportSummaryProcessRequest = ({
  dispatch,
  params,
  format,
  responseType,
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('get', `/payroll_processes/report_summary_process${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    failureCallback,
    callback,
    service
  });

export const payrollProcessReloadRequest = (
  id,
  { dispatch, params = {}, successCallback, callback, service = 'payroll' }
) =>
  ApiService.request('put', `/payroll_processes/${id}/reload`, {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });

export const debouncePayrollProcessSummaryRequest = AwesomeDebouncePromise(payrollProcessSummaryRequest, 300);
