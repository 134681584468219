export default {
  name: '',
  description: '',
  active: true,
  keyNameTemplate: '',
  fieldType: '',
  visibleWorker: true,
  fieldDisplay: '',
  company: {},
  companyId: '',
  customFieldTypesAttributes: []
};
