import React from 'react'
import { Col, Row } from 'react-bootstrap';
import AttendancesControlClockDataTable from './AttendancesControlClockDataTable';

const AttendancesControlClockIndex = () => {
  return (
    <>
      <Row className="mt-4 mb-1">
        <Col>
          <h2 className="text-uppercase mt-3">Log de Dispositivos</h2>
        </Col>
      </Row>
      <AttendancesControlClockDataTable clickRoute="/admin/attendances_control_clock" />
    </>
  );
};

export default AttendancesControlClockIndex