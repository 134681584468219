const conceptTypeEnum = [
  { label: 'Haberes', value: 'asset' },
  { label: 'Descuentos', value: 'discount' }
];

const classificationTypeEnum = [
  { label: 'Remuneración', value: 'remuneration' },
  { label: 'Anticipo', value: 'advance' },
  { label: 'Histórico', value: 'historical' },
  { label: 'Reproceso', value: 'reprocess' }
];

const amountTypeEnum = [
  { label: 'Igual para todos', value: 'distribute' },
  { label: 'Distinto por persona', value: 'different' }
];

const calculationTypeAssetEnum = [
  { label: 'Fijo', value: 'fixed' },
  { label: 'Proporcional Ausencia', value: 'proportional_absence' }
];

const calculationTypeDisEnum = [
  { label: 'Fijo', value: 'fixed' },
  { label: 'Proporcional Ausencia', value: 'proportional_absence' }
];

const defaultToShowEnum = [
  { label: 'Contrato', value: 'work_contract' },
  { label: 'Prenómina', value: 'prenomine' }
];

const discountTypeEnum = [
  { label: 'Descuento Voluntario', value: 'voluntary' },
  { label: 'Retención Judicial', value: 'judicial_retention' },
  { label: 'Cuota Sindical', value: 'sindical_quota' }
];

const ineCodeEnum = [
  { label: 'Sueldos Base: Fijo y por Semana Corrida', value: 'base_salary' },
  { label: 'Otros Sueldos y Salarios básicos y permanentes', value: 'other_salary' },
  { label: 'Sueldos y Salarios por Funciones ocasionales', value: 'occasional_salary' },
  { label: 'Incentivos y Premios Pagados al Trabajador', value: 'incentive' },
  { label: 'Pagos por trato', value: 'treatment_pay' },
  { label: 'Pagos por Horas Extraordinarias', value: 'overtime_pay' },
  { label: 'Comisiones pagadas por Ventas', value: 'commissions_paid' },
  { label: 'Reembolsos de gastos del trabajador por causas del trabajo', value: 'refund' },
  { label: 'Participaciones, gratificaciones y pagos adicionales NO mensuales', value: 'additional_pay' },
  { label: 'Pagos en especies y gastos de vivienda del trabajador', value: 'living_expenses' },
  { label: 'Pagos directos al trabajador de Servicio de Bienestar', value: 'welfare_service' },
  { label: 'Aportes Patronales por Seguros obligatorios para sus trabajadores', value: 'employer_insurance_pay' },
  { label: 'Gastos de los Serv. de bienestar y de capacitación del trabajador', value: 'training_expense' },
  { label: 'Indemnizaciones por término de la relación de trabajo', value: 'finished_compensation' },
  { label: 'Otros costos de la Mano de Obra', value: 'manpower_expense' }
];

const remunerationTypeEnum = [
  { label: 'Fijo', value: 'permanent' },
  { label: 'Variable', value: 'variable' }
];

const unitOfAccountEnum = [
  { label: 'UF', value: 'uf' },
  { label: 'Pesos', value: 'pesos' }
];

const judicialRetentionEnum = [
  { label: 'Alcance Líquido', value: 'liquid_reach' },
  { label: 'Sueldo Mínimo', value: 'min_wage' },
  { label: 'No Remuneracional', value: 'no_remunerational' },
  { label: 'Pesos', value: 'pesos' },
  { label: 'UF', value: 'uf' },
  { label: 'UTM', value: 'utm' }
];

const emptyConcept = {
  active: true,
  balanceType: '',
  code: '',
  name: '',
  remunerationType: '',
  maxInput: '',
  taxable: false,
  tributable: false,
  legal: false,
  formulation: false,
  proportional: false,
  companyCost: false,
  printOnLiquidation: true,
  compensationBase: false,
  showZeroValue: false,
  bookkeeperId: '',
  ineCode: '',
  classificationType: '',
  calculationOrder: '',
  observation: '',
  compensationYearsService: false,
  holidayCompensation: false,
  priorNoticeCompensation: false,
  voluntaryCompensation: false,
  royalties: false,
  assetVariable: false,
  electronicSettlement: ''
};

const conceptFE = [
  { label: 'Indemnización Feriado', value: 'holiday_compensation' },
  { label: 'Indemnización Aviso Previo', value: 'prior_notice_compensation' },
  { label: 'Indemnización Servicio', value: 'compensation_service' },
  { label: 'Indemnización Otras', value: 'compensation_others' },
  { label: 'Remuneración Pendiente', value: 'pending_remuneration' },
  { label: 'Gratificaciones', value: 'perks' },
  { label: 'Bonos', value: 'bonuses' },
  { label: 'Horas Extraordinarias', value: 'extraordinary_hours' },
  { label: 'Aguinaldo', value: 'bonus' },
  { label: 'Semana Corrida', value: 'running_week' },
  { label: 'Comisión o Participación', value: 'commission' },
  { label: 'Movilización', value: 'mobilization' },
  { label: 'Colación', value: 'collation' },
  { label: 'Perdida Caja', value: 'lost_box' },
  { label: 'Desgaste Herramientas', value: 'tool_wear' },
  { label: 'Viáticos', value: 'travel_expenses' },
  { label: 'Asignaciones Familiares', value: 'family_asignations' },
  { label: 'Descuento Seguridad Social', value: 'social_security_discount' },
  { label: 'Descuento Impuestos', value: 'tax_discount' },
  { label: 'Descuento AFC', value: 'afc_discount' },
  { label: 'Descuento Anticipado', value: 'early_discount' },
  { label: 'Descuento Indemnización', value: 'discount_compensation' },
  { label: 'Descuento Pensión', value: 'pension_discount' },
  { label: 'Descuento Caja Compensación', value: 'discount_compensation_box' },
  { label: 'Préstamo Adeudado', value: 'loan_owed' },
  { label: 'Anticipo Sueldo', value: 'salary_advance' },
  { label: 'Vacaciones Anticipadas', value: 'early_vacation' }
];

export {
  conceptTypeEnum,
  classificationTypeEnum,
  amountTypeEnum,
  calculationTypeAssetEnum,
  calculationTypeDisEnum,
  defaultToShowEnum,
  discountTypeEnum,
  ineCodeEnum,
  remunerationTypeEnum,
  unitOfAccountEnum,
  judicialRetentionEnum,
  emptyConcept,
  conceptFE
};
