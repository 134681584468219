import React, { useState, useEffect, useCallback } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import { FormikRangePicker, FormikSelect } from '../../..';
import { debounceIndexShiftsRequest } from '../../../../requests/shifts';
import { debounceIndexBranchOfficesRequest } from '../../../../requests/branchOffices';
import { debounceIndexEmployeesRequest } from '../../../../requests/employees';
import { indexJobTitleRequest } from '../../../../requests/jobTitles';

const WorkDayReportFilters = props => {
  const { errors, touched, setFieldValue, values, isSubmit, isArial, setFieldTouched, onlyPreview = false } = props;
  const dispatch = useDispatch();
  const [allShiftIds, setAllShiftIds] = useState([]);
  const [allJobTitleIds, setAllJobTitleIds] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [shifts, setShifts] = useState([]);

  const [contractJobTitles, setContractJobTitles] = useState([]);
  const [contractShifts, setContractShifts] = useState([]);
  const { branchOfficeIds } = useSelector(state => state.auth.user);
  const [branchOffices, setBranchOffices] = useState([]);
  const [requestBranchOffices, setRequestBranchOffices] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [dataRange, setDataRange] = useState([]);

  const ALL_SELECTED_LABEL = 'Todos';
  const [employees, setEmployees] = useState([]);
  const resultFetchData = (response, type) => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: `${type ? `${element.code} - ` : ''} ${element.name}`,
      value: element.id
    }));
  };

  const successFetchEmployees = useCallback(
    data => {
      setIsFiltering(false);
      const dataToGroupSearch = [{ id: '', label: 'Todos', value: '' }, ...data];
      setEmployees(dataToGroupSearch);
      const allEmployeeIds = data.flatMap(employee => employee.id);
      const arrayShiftsIds = data.flatMap(employee => employee.shift_ids).filter(id => id !== null);
      const arrayJobTitleIds = data.flatMap(employee => employee.job_title_id).filter(id => id !== null);
      setAllShiftIds([...new Set(arrayShiftsIds)]);
      setAllJobTitleIds([...new Set(arrayJobTitleIds)]);
      setFieldValue('all_branch_office_ids', branchOfficeIds);
      setFieldValue('all_employee_ids', allEmployeeIds);
    },
    [branchOfficeIds, setFieldValue]
  );

  const fetchEmployees = useCallback(() => {
    if (branchOfficeIds && branchOfficeIds.length > 0) {
      setIsFiltering(true);
      const selectBranchofficeIds = branchOffices.filter(id => id !== '');
      debounceIndexEmployeesRequest({
        dispatch,
        params: {
          sort_column: 'name',
          paginate: false,
          is_dt: false,
          filter_branch_offices_id_only: selectBranchofficeIds.length > 0 ? selectBranchofficeIds : branchOfficeIds,
          filter_active_shifts: shifts,
          filter_job_titles_id_only: jobTitles,
          filter_by_contract_date_range: dataRange
        },
        successCallback: ({ data: { data } }) => {
          successFetchEmployees(data);
        }
      });
    }
  }, [branchOfficeIds, branchOffices, shifts, jobTitles, dataRange, dispatch, successFetchEmployees]);

  const handleEmployeesSearch = (field, data) => {
    switch (field) {
      case 'filter_branch_offices_id_only':
        setBranchOffices(data);
        break;
      case 'filter_active_shifts':
        setShifts(data);
        break;
      case 'filter_job_titles_id_only':
        setJobTitles(data);
        break;
      default:
        break;
    }
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData);
    handleEmployeesSearch(field, newData);
  };

  const hasAllSelection = (groupSelection = []) => {
    return groupSelection.some(option => option.label === ALL_SELECTED_LABEL);
  };

  const handleDisabledSelection = (options, selectedOptions) => {
    if (hasAllSelection(selectedOptions)) {
      return true;
    }
    if (selectedOptions.length === 0) return false;
    return options.label === ALL_SELECTED_LABEL;
  };

  const fetchBranchOffices = useCallback(() => {
    if (branchOfficeIds && branchOfficeIds.length > 0) {
      debounceIndexBranchOfficesRequest({
        dispatch,
        params: {
          actives: true,
          sort_column: 'name',
          branch_office_ids: branchOfficeIds,
          paginate: false
        },
        successCallback: data => {
          setRequestBranchOffices(resultFetchData(data));
        }
      });
    }
  }, [branchOfficeIds, dispatch]);

  const fetchShifts = useCallback(() => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        shift_ids: allShiftIds,
        paginate: false
      },
      successCallback: data => {
        setContractShifts(resultFetchData(data));
      }
    });
  }, [allShiftIds, dispatch]);

  const fetchJobTitles = useCallback(() => {
    indexJobTitleRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        job_title_ids: allJobTitleIds,
        paginate: false
      },
      successCallback: data => {
        setContractJobTitles(resultFetchData(data));
      }
    });
  }, [allJobTitleIds, dispatch]);

  useEffect(() => {
    if (dataRange.length === 2 && branchOfficeIds && branchOfficeIds.length > 0) {
      fetchEmployees();
    }
  }, [branchOfficeIds, shifts, jobTitles, dataRange, fetchEmployees]);

  useEffect(() => {
    if (branchOfficeIds && branchOfficeIds.length > 0) {
      fetchBranchOffices();
    }
  }, [branchOfficeIds, fetchBranchOffices]);

  useEffect(() => {
    if (allShiftIds.length > 0) {
      fetchShifts();
    }
  }, [allShiftIds, fetchShifts]);

  useEffect(() => {
    if (allJobTitleIds.length > 0) {
      fetchJobTitles();
    }
  }, [allJobTitleIds, fetchJobTitles]);

  return (
    <Form>
      <Card>
        <Row>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className={`top-header-green-dt card-header-title-dt ${isArial ? 'arial-dt' : ''}`}>
                RANGO DE FECHAS INICIO DE JORNADA
              </Col>

              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={6} md={6} xs={6}>
                    <Field name="range_date_workday">
                      {({ field }) => (
                        <FormikRangePicker
                          {...field}
                          abbr
                          startDateName="date_from_workday"
                          endDateName="date_to_workday"
                          startDate={values.date_from_workday}
                          endDate={values.date_to_workday}
                          showClearDates
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isArial={isArial}
                          onDatesChange={({ startDate: startDateV, endDate: endDateV }) => {
                            const startValue = moment.isMoment(startDateV) ? startDateV.format('DD/MM/YYYY') : '';
                            const endValue = moment.isMoment(endDateV) ? endDateV.format('DD/MM/YYYY') : '';

                            setFieldValue('employee', '');
                            setFieldValue('employees', []);

                            if (startValue && endValue) setDataRange([startValue, endValue]);

                            setFieldValue('date_from_workday', startValue);
                            setFieldValue('date_to_workday', endValue);
                            setFieldTouched(field.name);
                            setFieldValue(field.name, [startValue, endValue]); // Important to be the last one
                          }}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={6} md={6} xs={6}>
                    <Field name="employees">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Nombres y Apellidos"
                          isMulti
                          options={employees}
                          isLoading={isFiltering}
                          placeholder="Seleccione colaboradores"
                          onChange={data => {
                            handleNewMulti(data || [], 'employee_ids', true);
                            setFieldValue('employee', '');
                            setFieldValue('employee_id', '');
                          }}
                          isOptionDisabled={handleDisabledSelection}
                          isClearable
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isArial={isArial}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>

      <Card style={{ zIndex: 'unset' }}>
        <Row>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className={`top-header-green-dt card-header-title-dt ${isArial ? 'arial-dt' : ''}`}>
                BUSQUEDA GRUPAL LISTADO
              </Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={4} md={4} xs={4}>
                    <Field name="branch_offices">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          isMulti
                          label="Lugar de Prestación de Servicios"
                          options={requestBranchOffices}
                          isOptionDisabled={handleDisabledSelection}
                          placeholder="Seleccione Lugar de Prestación de Servicios"
                          onChange={data => {
                            handleNewMulti(data || [], 'filter_branch_offices_id_only', true);
                            setFieldValue(field.name, data || []);
                          }}
                          isDisabled={requestBranchOffices.length === 0}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isArial={isArial}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={4} md={4} xs={4}>
                    <Field name="shiftIds">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          isMulti
                          isOptionDisabled={handleDisabledSelection}
                          label="Turnos"
                          placeholder="Seleccionar Turno"
                          options={contractShifts}
                          isDisabled={contractShifts.length === 0}
                          onChange={data => {
                            handleNewMulti(data || [], 'filter_active_shifts', true);
                            setFieldValue(field.name, data || []);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={4} md={4} xs={4}>
                    <Field name="job_titles">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          isMulti
                          isOptionDisabled={handleDisabledSelection}
                          placeholder="Seleccione cargo"
                          label="Cargo o Función"
                          options={contractJobTitles}
                          isDisabled={contractJobTitles.length === 0}
                          onChange={data => {
                            handleNewMulti(data || [], 'filter_job_titles_id_only', true);
                            setFieldValue(field.name, data || []);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isArial={isArial}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
      {branchOfficeIds && branchOfficeIds.length > 0 && (
        <>
          <Row className={`${onlyPreview ? 'd-flex justify-content-end' : 'd-flex'}`}>
            {onlyPreview === false && (
              <>
                <Col md={2}>
                  <Button
                    className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                    disabled={isSubmit}
                    type="submit"
                    onClick={() => {
                      setFieldValue('type', '.docx');
                    }}
                  >
                    Descargar Word
                  </Button>
                </Col>
                <Col md={2}>
                  <Button
                    className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                    disabled={isSubmit}
                    type="submit"
                    onClick={() => {
                      setFieldValue('type', '.xlsx');
                    }}
                  >
                    Descargar Excel
                  </Button>
                </Col>
                <Col md={2}>
                  <Button
                    className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                    disabled={isSubmit}
                    type="submit"
                    onClick={() => {
                      setFieldValue('type', '.pdf');
                    }}
                  >
                    Descargar PDF
                  </Button>
                </Col>
                <Col md={2}>
                  <Button
                    className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                    disabled={isSubmit}
                    type="submit"
                    onClick={() => {
                      setFieldValue('type', '.print');
                    }}
                  >
                    Imprimir
                  </Button>
                </Col>
              </>
            )}
            <Col md={2}>
              <Button
                className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                disabled={isSubmit}
                type="submit"
                onClick={() => {
                  setFieldValue('type', null);
                }}
              >
                PreVisualizar
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  range_date_workday: Yup.array()
    .when('$rangedate', (rangedate, schema) => {
      return schema
        .test('bothDatesRequired', 'Seleccione la fecha de inicio y fin del rango', values => {
          const rangedateFilter = values.filter(key => key !== undefined);
          return rangedateFilter && values.length === 2;
        })
        .test('maxThreeMonths', 'El rango de fechas no debe ser mayor a tres meses', values => {
          const rangedateFilter = values.filter(key => key !== undefined);
          if (!rangedateFilter || values.length !== 2) {
            return true; // No aplica la validación si no hay dos fechas seleccionadas
          }

          // Calcula la diferencia en meses
          const [startDate, endDate] = values;
          const startMoment = moment(startDate, 'DD/MM/YYYY', true);
          const endMoment = moment(endDate, 'DD/MM/YYYY', true);
          const diffInMonths = endMoment.diff(startMoment, 'months');
          // El rango de fechas es válido si la diferencia en meses es menor o igual a tres
          return diffInMonths <= 2;
        });
    })
    .required('El campo de fechas es requerido') // Mensaje de error si el campo de fechas está vacío
});

const setInitialValues = () => {
  return {
    date_from_workday: '',
    date_to_workday: '',
    range_date_workday: [],
    employee_id: '',
    employee_ids: [],
    filter_national_id: '',
    filter_job_titles_id_only: [],
    filter_active_shifts: [],
    filter_branch_offices_id_only: [],
    filter_work_schedule: '',
    type: null,
    all_employee_ids: [],
    all_branch_office_ids: []
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  const branchBfficeIds = values.filter_branch_offices_id_only.filter(id => id !== '');
  formRequest({
    ...values,
    filter_branch_offices_id_only: branchBfficeIds.length === 0 ? values.all_branch_office_ids : branchBfficeIds
  });
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(WorkDayReportFilters);
