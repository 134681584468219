const fieldTypes = [
  { label: 'Campo de Texto', value: 'text_field' },
  { label: 'Número', value: 'number' },
  { label: 'Fecha', value: 'date' },
  { label: 'Selector', value: 'selector' },
  { label: 'Verdadero/Falso', value: 'boolean' }
];

const fieldDisplays = [
  { label: 'Contrato ', value: 'contract_display' },
  { label: 'Ficha', value: 'card_employee_display' }
];

export { fieldTypes, fieldDisplays };
