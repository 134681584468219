import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { createEmployeeRequest, indexSelectEmployeesRequest } from '../../requests/employees';
import { showCandidatesRequest } from '../../requests/candidates';
import { sendAlert } from '../../actions/utils';
import { LinkBtn, Icon } from '../../components';
import { useAuthorization } from '../../services/hooks';
import { camelCaseEmptyStringRecursive, excludeKeys } from '../../services/utils';
import EmployeeForm from './EmployeeForm/EmployeeForm';
import basicEmployee from './employee';
import { indexCustomFieldsRequest } from '../../requests/customFields';

const EmployeeNew = ({ location }) => {
  const candidateId = location.state?.candidateId;
  const currentCompany = localStorage.getItem('currentCompany');
  const [employee, setEmployee] = useState(basicEmployee);
  const [contractCustomFields, setContractCustomFields] = useState([]);
  const [employeeCustomFields, setEmployeeCustomFields] = useState([]);
  const [signSubstitutes, setSignSubstitutes] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const handleRequestAndSuccessCustomFields = async params => {
      const filterCondition = field => {
        return field.company_id === Number(currentCompany) && field.active;
      };

      indexCustomFieldsRequest({
        dispatch,
        params,
        successCallback: response => {
          const { data } = response.data;

          const contractFields = data.filter(
            field => filterCondition(field) && field.field_display === 'contract_display'
          );
          const employeeFields = data.filter(
            field => filterCondition(field) && field.field_display === 'card_employee_display'
          );

          setContractCustomFields(contractFields);
          setEmployeeCustomFields(employeeFields);
        }
      });
    };
    handleRequestAndSuccessCustomFields();
  }, [currentCompany, dispatch]);

  const fetchCandidate = () => {
    if (!candidateId) return;
    showCandidatesRequest(candidateId, {
      dispatch,
      params: { hire_candidate: true },
      successCallback: response => {
        const data = camelCaseEmptyStringRecursive(response.data);
        const candidate = excludeKeys(data, ['id']);
        setEmployee({ ...employee, ...candidate });
      }
    });
  };

  const handleSuccessCreate = response => {
    const { id: employeeId } = response.data;
    dispatch(sendAlert({ kind: 'success', message: 'Trabajador creado con éxito' }));
    history.push(`/employees/${employeeId}`);
  };

  const handleCreateRequest = (values, setSubmitting) => {
    const { employee: copyEmployee } = values;
    const { employeeCertificationsAttributes, image, newContract, digitalSign } = copyEmployee;
    const { progressiveVacationsCertificate } = copyEmployee.contract;
    const { disabilityCertificate } = copyEmployee.contract;
    if (newContract) {
      copyEmployee.contractsAttributes = [copyEmployee.contract];
    }
    let myParams = {
      employee: { ...copyEmployee }
    };

    myParams = snakeCaseKeys(myParams);
    myParams.employee.image = image;
    myParams.employee.digital_sign = digitalSign;
    if (employeeCertificationsAttributes.length > 0) {
      employeeCertificationsAttributes.map((body, index) => {
        myParams.employee.employee_certifications_attributes[index].certificate = body.certificate;
        return true;
      });
    } else {
      delete myParams.employee.employee_certifications_attributes;
    }
    if (progressiveVacationsCertificate && myParams.employee.contracts_attributes) {
      myParams.employee.contracts_attributes[0].progressive_vacations_certificate = progressiveVacationsCertificate;
    }
    if (disabilityCertificate && myParams.employee.contracts_attributes) {
      myParams.employee.contracts_attributes[0].disability_certificate = disabilityCertificate;
    }

    if (candidateId) myParams.employee.candidate_id = candidateId;

    createEmployeeRequest({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: response => handleSuccessCreate(response),
      callback: () => setSubmitting(false)
    });
  };

  const withContract = useAuthorization('create', 'Contract');
  const canFamilyGroup = useAuthorization('create', 'FamilyGroup');
  const canCreateAsset = useAuthorization('assign_asset', 'EmployeeBalance');
  const canCreateDiscount = useAuthorization('assign_discount', 'EmployeeBalance');
  const canDestroyAsset = useAuthorization('destroy_asset', 'EmployeeBalance');
  const canDestroyDiscount = useAuthorization('destroy_discount', 'EmployeeBalance');
  const canPrevision = useAuthorization('update', 'EmployeePrevision');

  const initializeNewEmployee = () => {
    // Sing Substitutes
    indexSelectEmployeesRequest({
      dispatch,
      params: {
        sort_column: 'name',
        is_dt: false,
        paginate: false
      },
      successCallback: response => {
        setSignSubstitutes(camelCaseEmptyStringRecursive(response.data.data));
      }
    });
    window.scrollTo(0, 0);
  };

  useEffect(initializeNewEmployee, []);

  useEffect(fetchCandidate, [candidateId]);

  return (
    <>
      <div className="top-box sps sps--abv new-employee" />
      <div className="title-box sps sps--abv new-employee h-new-employee">
        <Row className="mt-3">
          <Col xs={1}>
            <LinkBtn variant="circle-dark" className="btn-circle mt-0 mt-md-2" block to="/employees">
              <Icon icon="chevron-back" />
            </LinkBtn>
          </Col>
          <Col>
            <h2 className="text-uppercase f-size-employee d-flex align-items-center h-100 mt-0 mt-md-n2 mb-3 mb-md-5">
              Ficha del Trabajador
            </h2>
          </Col>
        </Row>
      </div>
      <EmployeeForm
        newEmployee
        employee={employee}
        signSubstitutes={signSubstitutes}
        withContract={withContract}
        currentCompany={currentCompany}
        action="new"
        submitVariant="success"
        formRequest={handleCreateRequest}
        canFamilyGroup={canFamilyGroup}
        canCreateAsset={canCreateAsset}
        canCreateDiscount={canCreateDiscount}
        canDestroyAsset={canDestroyAsset}
        canDestroyDiscount={canDestroyDiscount}
        canPrevision={canPrevision}
        employeeCustomFields={employeeCustomFields}
        contractCustomFields={contractCustomFields}
      />
    </>
  );
};

export default EmployeeNew;
