import React from 'react';
import { Table } from 'react-bootstrap';
import { ButtonTooltip } from '../../../components/Utils/Tooltips';
import Icon from '../../../components/Icons';
import DocumentPreview from '../../DocumentPreview/DocumentPreview';

const OvertimeAgreementsReportFilterDataTable = ({
  overtimeAgreements,
  setDocumentModalBody,
  setDocumentModalShow
}) => {
  const styleButtonTooltip = {
    width: 'auto',
    height: 'auto',
    borderRadius: '10%',
    display: 'initial'
  };

  const renderObservation = observation => (
    <div dangerouslySetInnerHTML={{ __html: observation.replace(/\n/g, '<br>') }} />
  );

  const renderResult = overtimeAgreement => {
    return (
      <tr>
        <td className="text-center align-middle">{overtimeAgreement.cration_date}</td>
        <td className="text-center align-middle">{overtimeAgreement.creation_time}</td>
        <td className="text-center align-middle">{overtimeAgreement.start_date}</td>
        <td className="text-center align-middle">{overtimeAgreement.end_date}</td>
        <td className="text-center align-middle">{overtimeAgreement.hours_number}</td>
        <td className="text-center align-middle">{overtimeAgreement.status}</td>

        <td className="text-center align-middle">
          <>
            {overtimeAgreement?.document && (
              <ButtonTooltip
                onClick={() => {
                  setDocumentModalBody(
                    <DocumentPreview
                      documentId={overtimeAgreement?.document?.id}
                      employeeId={overtimeAgreement?.document?.employee_id}
                    />
                  );
                  setDocumentModalShow(true);
                }}
                variant="circle-info"
                className="btn-circle"
                style={styleButtonTooltip}
                toolbarVariant="justify-content-center"
                size="sm"
                text="Ver Pacto H.E"
                disabledTooltip
              >
                <Icon icon="profile" /> Ver Pacto H.E
              </ButtonTooltip>
            )}
            {renderObservation(overtimeAgreement.observation)}
          </>
        </td>
      </tr>
    );
  };

  const renderData = () => {
    return (
      <>
        <Table className="custom-data-table" bordered size="sm" style={{ fontWeight: 400 }}>
          <thead className="rdt_TableHeadRow" style={{ backgroundColor: '#EBEBEB', textTransform: 'uppercase' }}>
            <tr className="rdt_TableCol">
              <th className="text-center align-middle">FECHA DE CREACIÓN DEL PACTO</th>
              <th className="text-center align-middle">HORA DE CREACIÓN DEL PACTO</th>
              <th className="text-center align-middle">PERIODO DE INICIO</th>
              <th className="text-center align-middle">PERIODO DE TÉRMINO</th>
              <th className="text-center align-middle">NRO. HORAS AUTORIZADAS</th>
              <th className="text-center align-middle">ESTADO DE LA AUTORIZACION</th>
              <th className="text-center align-middle">OBSERVACIONES</th>
            </tr>
          </thead>
          <tbody>
            {overtimeAgreements && overtimeAgreements.map(overtimeAgreement => renderResult(overtimeAgreement))}
          </tbody>
        </Table>
      </>
    );
  };

  return <>{renderData()}</>;
};

export default OvertimeAgreementsReportFilterDataTable;
