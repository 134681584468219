import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { SmallCard, Icon, ButtonTooltip } from '../../components';
import DocumentsDatatable from './DocumentDataTable';
import { signaturesRequest } from '../../requests/dashboards';

const DocumentIndex = () => {
  const [activeCard, setActiveCard] = useState('');
  const [answeredCard, setAnsweredCard] = useState([]);
  const [approvedCard, setApprovedCard] = useState([]);
  const [customParams, setCustomParams] = useState({ with_history: false });
  const dispatch = useDispatch();
  const [moreData, setMoreData] = useState(false);
  const [pendingCard, setPendingCard] = useState([]);
  const [rejectedCard, setRejectedCard] = useState([]);
  const [total, setTotal] = useState(0);

  const updateCardValues = response => {
    const { documents } = response.data;
    setAnsweredCard(documents.answered);
    setApprovedCard(documents.approved);
    setPendingCard(documents.pending);
    setRejectedCard(documents.rejected);
    setTotal(documents.total);
  };

  const fetchDashboardCards = () => {
    const request = async () => signaturesRequest({ dispatch, successCallback: updateCardValues });
    request();
  };

  const setCard = filter => {
    setMoreData(!moreData);
    switch (filter) {
      case 'info':
        setActiveCard('info');
        setCustomParams({ status: ['approved', 'rejected'] });
        break;
      case 'primary':
        setActiveCard('primary');
        setCustomParams({ status: 'approved' });
        break;
      case 'warning':
        setActiveCard('warning');
        setCustomParams({ status: ['pending'] });
        break;
      case 'danger':
        setActiveCard('danger');
        setCustomParams({ status: 'rejected' });
        break;
      default:
        setCustomParams({ with_history: true });
        setActiveCard('');
    }
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchDashboardCards, []);

  return (
    <>
      <h2 className="m-top mb-3">Firma Digital</h2>
      <Row>
        <Col md={3} sm={4} xs={12}>
          <SmallCard
            variant="info"
            card={answeredCard}
            total={total}
            label="Respondidos"
            active={activeCard === 'info'}
            defaultCursor="false"
          />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <SmallCard
            variant="primary"
            card={approvedCard}
            total={total}
            label="Aprobados"
            active={activeCard === 'primary'}
            defaultCursor="false"
          />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <SmallCard
            variant="danger"
            card={rejectedCard}
            total={total}
            label="Rechazados"
            active={activeCard === 'danger'}
            defaultCursor="false"
          />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <SmallCard
            variant="warning"
            card={pendingCard}
            total={total}
            label="Pendientes"
            active={activeCard === 'warning'}
            defaultCursor="false"
          />
        </Col>
      </Row>
      {activeCard && (
        <>
          <ButtonTooltip onClick={setCard} variant="link" className="text-primary" size="sm" text="Volver">
            <Icon icon="chevron-back" width={15} />
            <span>Volver a tabla inicial</span>
          </ButtonTooltip>
        </>
      )}
      <DocumentsDatatable
        customParams={customParams}
        setCustomParams={setCustomParams}
        moreData={moreData}
        setMoreData={setMoreData}
        employeeSearch
        advanceFilters
        rrhh
        allowedActions={['download', 'remove', 'resend', 'show', 'request-sign', 'observation']}
      />
    </>
  );
};

export default DocumentIndex;
