import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CustomGraph, Icon, LinkBtn } from '../../../components';
import './style.scss';

const LoanProcessSummaryCards = ({ loanProcess }) => {
  const graphColors = ['#29B48F', '#FB7474', '#FFC107', '#0088FE', '#571263', '#A4CF42'];
  const graphColors2 = ['#FB7474', '#571263', '#FFC107', '#A4CF42', '#29B48F', '#0088FE'];
  const graphLegend = { layout: 'horizontal' };

  return (
    <>
      {loanProcess && (
        <>
          <Row className="m-top mb-2">
            <Col md={1}>
              <LinkBtn variant="circle-dark" className="btn-circle" block to="/loan_processes">
                <Icon icon="chevron-back" />
              </LinkBtn>
            </Col>
            <Col className="top-header-green title-header--text">
              <h2 className="m-top mt-0">{loanProcess.name}</h2>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={4} className="border-div">
              <div className="info-box lower-width--span">
                <p className="info mt-1 mb-2 mt-3">
                  <b>RESUMEN</b>
                </p>
                <p className="info mb-2">
                  <b>{loanProcess.name}</b>
                </p>
                <p className="info mb-1">
                  <span>
                    <b>TOTAL CUOTAS A DESCONTAR: </b>
                  </span>{' '}
                  <p className="mb-0">
                    <b>{loanProcess.installmentsCount}</b>
                  </p>
                </p>
                <p className="info mb-1">
                  <span>
                    <b>TOTAL CUOTAS SUSPENDIDAS:</b>
                  </span>{' '}
                  <p className="mb-0">
                    <b>{loanProcess.installmentDeferredCount}</b>
                  </p>
                </p>
                <p className="info mb-1">
                  <span>
                    <b>MONTO TOTAL A DESCONTAR:</b>
                  </span>{' '}
                  <p className="mb-0">
                    <b>{loanProcess.parsedDiscountTotal}</b>
                  </p>
                </p>
                <p className="info mb-1">
                  <span>
                    <b>MONTO TOTAL NO DESCONTADO:</b>
                  </span>{' '}
                  <p className="mb-0">
                    <b>{loanProcess.parsedDeferredTotal}</b>
                  </p>
                </p>
                <p className="info mb-1">
                  <span>
                    <b>FECHA ULTIMA ACTUALIZACIÓN:</b>{' '}
                  </span>
                  <p className="mb-0">
                    <b>{loanProcess.updatedAt}</b>
                  </p>
                </p>
              </div>
            </Col>
            <Col md={4} className="border-div">
              <CustomGraph
                title="Distribución porcentual sobre el total de cuotas mensuales"
                customizedLabel
                chartType="pie"
                data={[
                  { name: 'Descontado', value: loanProcess.installmentsCount },
                  { name: 'No Descontado', value: loanProcess.installmentDeferredCount }
                ]}
                customColors={graphColors}
                pieLegend={graphLegend}
                activeAnimation={false}
                customHeight={200}
                mb={2}
              />
            </Col>
            <Col md={4} className="border-div">
              <CustomGraph
                title="MONTOS TOTALES POR TIPO DE PAGO"
                customizedLabel
                chartType="bar"
                data={[
                  { name: 'Prestamo', value: loanProcess.parsedLoanAmount },
                  { name: 'Credito', value: loanProcess.parsedCreditAmount }
                ]}
                customColors={graphColors2}
                pieLegend={graphLegend}
                activeAnimation={false}
                customHeight={200}
                mb={2}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default LoanProcessSummaryCards;
