import React, { useEffect } from 'react';
import WorkdayReportDataTable from './WorkdayReportDataTable';

const WorkdayReportIndex = ({ rrhh }) => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <WorkdayReportDataTable rrhh={rrhh} />
    </>
  );
};

export default WorkdayReportIndex;
