import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, withRouter, useHistory } from 'react-router-dom';
import { Container, Nav, Dropdown, Row, Col } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../abilityContext';
import { updateAbility } from '../ability';
import { NavbarLogged, Sidebar } from '../../components';
import { setSidebar, toogleSidebar } from '../../actions/utils';
import { requestSignOut } from '../../actions/auth';
import { redirectUnauthorized, useCanAccess } from '../../services/hooks';

const LoggedLayout = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sidebarOpen } = useSelector(state => state.utils);
  const { module, action, resource, exclusive, component: Screen, ...otherProps } = props;
  const { user, currentEmployee } = useSelector(state => state.auth);
  const ability = useAbility(AbilityContext);
  const [sidebarBg, setSidebarBg] = useState('profile-bg');
  const authorized = useCanAccess(action, resource, exclusive);

  const fetchRedirect = () => {
    if (!authorized) redirectUnauthorized(dispatch, history, user, currentEmployee);
  };
  useEffect(fetchRedirect, [authorized]);

  const companyPath = () => {
    const {
      location: { pathname }
    } = props;
    const companyDropdown = [
      'company',
      'document_templates',
      'groupings',
      'job_managements',
      'organization_charts',
      'settings',
      'benefits'
    ];
    const paths = pathname.split('/');
    const path = paths[paths.length - 1];
    return companyDropdown.includes(path);
  };

  const employeePath = () => {
    const {
      location: { pathname }
    } = props;
    const paths = pathname.split('/');
    return paths.length >= 3 && paths[1] === 'employees';
  };

  const setUpLayout = useCallback(() => {
    const width = window.innerWidth;
    const isMobile = width <= 991;
    if (isMobile && sidebarOpen) dispatch(setSidebar({ show: false }));
    else if (!isMobile && !sidebarOpen) dispatch(setSidebar({ show: true }));
  }, [sidebarOpen, dispatch]);

  useEffect(setUpLayout, []);

  const sidebarColor = () => {
    switch (module) {
      case 'admin':
        setSidebarBg('remuneration-bg');
        break;
      case 'basic':
        setSidebarBg('remuneration-bg');
        break;
      default:
        if (ability.can('boss', 'Employee')) {
          setSidebarBg('boss-bg');
        } else {
          setSidebarBg('profile-bg');
        }
    }
  };
  useEffect(sidebarColor, [module]);

  const abilityUpdate = () => {
    updateAbility(ability);
    sidebarColor();
  };

  useEffect(abilityUpdate, [currentEmployee]);
  const isMobile = window.innerWidth <= 991;

  return (
    <>
      <Route
        {...otherProps}
        render={rest =>
          localStorage.getItem('jwt') ? (
            <>
              <NavbarLogged module={module}>
                <Dropdown.Item onClick={() => dispatch(requestSignOut())}>Cerrar Sesión</Dropdown.Item>
              </NavbarLogged>
              <Container fluid>
                <Row>
                  <div className={`d-lg-block ${sidebarBg} sidebar ${sidebarOpen ? '' : 'menu-responsive '}`}>
                    <Sidebar module={module} defaultActive="companies">
                      <Nav.Link className="logout-responsive animation-link" onClick={() => dispatch(requestSignOut())}>
                        Cerrar Sesión
                      </Nav.Link>
                    </Sidebar>
                  </div>
                  {isMobile && sidebarOpen ? (
                    <div className="overlay" onClick={() => dispatch(toogleSidebar())} aria-hidden="true" />
                  ) : (
                    <div className="overlay-hidden" />
                  )}
                  <Col
                    className={`sps sps--abv content-body ${companyPath() ? 'content-company' : ''} 
                      ${employeePath() ? 'content-employee' : ''} ${isMobile && 'pl-4'}`}
                  >
                    <Screen {...rest} />
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <Redirect to={{ pathname: '/', state: { from: rest.location } }} />
          )
        }
      />
    </>
  );
};

export default withRouter(LoggedLayout);
