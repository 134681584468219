import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Row, Col, Tab, Nav, Spinner, Badge } from 'react-bootstrap';

import {
  importAttendanceRequest,
  importTemplateAttendanceRequest,
  preImportAttendanceRequest
} from '../../requests/attendances';
import { sendAlert } from '../../actions/utils';
import { useSetTab, useAuthorization } from '../../services/hooks';
import { DefaultModal, ImportModal, LinkBtn, SimpleCenteredModal } from '../../components';

import AssistanceTab from './AssistanceTab';
import OvertimeTab from './OvertimeTab';
import PendingTab from './PendingTab';
import AttendanceImportForm from '../../components/Attendance/AttendanceImportForm';

import { downloadFile } from '../../services/utils';
import { pendingAssistanceBadgeRequest } from '../../requests/dashboards';

const AssistanceIndex = ({ location }) => {
  const [key, setKey] = useSetTab('assistance', location);
  const [badgeCount, setBadgeCount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [alertInfo, setAlertInfo] = useState('');
  const dispatch = useDispatch();
  const newAbsence = useAuthorization('massive_create', 'Absence');
  const importAttendance = useAuthorization('import', 'Attendance');
  const newOvertime = useAuthorization('massive_create', 'Overtime');

  const fetchPendingBadge = () => {
    const request = async () => {
      await pendingAssistanceBadgeRequest({
        dispatch,
        successCallback: response => setBadgeCount(response.data.badge_count)
      });
    };
    request();
  };

  useEffect(fetchPendingBadge, [moreData]);

  const setModal = title => {
    setModalShow(true);
    setModalTitle(title);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalTitle('');
  };

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    handleModalClose();
    setOnRequest(false);
  };

  const handleSuccessImportTemplate = response => {
    downloadFile(response);
    setOnRequest(false);
  };

  const handleAttendanceImportRequest = (attendance, setSubmitting) => {
    setOnRequest(true);
    importAttendanceRequest({
      dispatch,
      params: attendance,
      formData: true,
      successCallback: response => handleSuccessAction(response?.data?.message),
      callback: () => setSubmitting(false)
    });
  };

  const handleImportTemplateAttendanceRequest = () => {
    importTemplateAttendanceRequest({
      dispatch,
      successCallback: handleSuccessImportTemplate,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
    setOnRequest(true);
  };

  const handleAttendanceImportModal = () => {
    const newModalTitle = 'Importar Asistencias';
    const newModalBody = (
      <>
        <Button className="ml-3" variant="warning" onClick={handleImportTemplateAttendanceRequest}>
          Descargar ejemplo
        </Button>
        <AttendanceImportForm formRequest={handleAttendanceImportRequest} handleModalClose={handleModalClose} />
      </>
    );
    setModal(newModalTitle, newModalBody, 'lg');
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInf = <pre>{error?.response?.data?.message}</pre>;
      setAlertInfo(alertInf);
      setAlertModalShow(true);
      setModalShow(false);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const setHeader = () => {
    switch (key) {
      case 'assistance':
        return (
          <>
            <Col>
              <h2 className="m-top mb-3 mt-3">Control del Tiempo</h2>
            </Col>
            <Col md={5}>
              <Row className="d-flex justify-content-end">
                {newAbsence && (
                  <Col md={6} className="mt-2 mt-md-3">
                    <LinkBtn variant="info" block to="/absences/new?returnTo=assistance">
                      Agregar Ausentismo
                    </LinkBtn>
                  </Col>
                )}
                {importAttendance && (
                  <Col md={6} className="mt-2 mt-md-3">
                    <Button variant="primary" block onClick={handleAttendanceImportModal}>
                      Importar Asistencia
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </>
        );
      case 'overtime':
        return (
          <>
            <Col>
              <h2 className="text-uppercase mt-3">Horas Extra</h2>
            </Col>
            {newOvertime && (
              <Col md={4} xl={3}>
                <LinkBtn variant="info" className="mt-3" block to="/assistance/overtimes/new">
                  Agregar Horas Extra
                </LinkBtn>
              </Col>
            )}
          </>
        );
      case 'pending':
        return (
          <Col>
            <h2 className="text-uppercase mt-3">Pendiente</h2>
          </Col>
        );
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        return '';
    }
  };

  return (
    <>
      {onRequest && (
        <Spinner
          animation="border"
          variant="primary"
          style={{ position: 'absolute', left: '50%', top: '100%', height: '50px', width: '50px' }}
        />
      )}
      <Row className="mt-4 mb-4">{setHeader()}</Row>
      <Tab.Container id="assistance-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills" className="ab-pills">
          <Nav.Item>
            <Nav.Link eventKey="assistance">Asistencia</Nav.Link>
          </Nav.Item>
          {useAuthorization('index', 'Overtime') && (
            <Nav.Item>
              <Nav.Link eventKey="overtime">Horas Extra</Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item>
            <Nav.Link eventKey="pending">
              {badgeCount > 0 && (
                <Badge variant="danger" className="custom-badge">
                  {badgeCount}
                </Badge>
              )}
              <span className="ml-1">Pendiente</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="pt-3">
          <Tab.Pane eventKey="assistance">
            <AssistanceTab moreData={moreData} setMoreData={setMoreData} location={location} />
          </Tab.Pane>
          {useAuthorization('index', 'Overtime') && (
            <Tab.Pane eventKey="overtime">
              <OvertimeTab moreData={moreData} setMoreData={setMoreData} />
            </Tab.Pane>
          )}
          <Tab.Pane eventKey="pending">
            <PendingTab moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      <SimpleCenteredModal
        title={modalTitle}
        body={
          <ImportModal
            onDropUploaded={preImportAttendanceRequest}
            handleTemplate={importTemplateAttendanceRequest}
            onHide={importAttendanceRequest}
            sendNotifications
            hideModal={handleModalClose}
            handleExceptions={handleImportModalExceptions}
          />
        }
        show={modalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title="Información Relevante"
        body={alertInfo}
        show={alertModalShow}
        withClose={false}
        handleConfirm={() => setAlertModalShow(false)}
        handleClose={() => setAlertModalShow(false)}
        titleBtnSave="Confirmar"
        scrollable
      />
    </>
  );
};

export default AssistanceIndex;
