import React, { useContext, useState } from 'react';
import { useAbility } from '@casl/react';
import { useDispatch, useSelector } from 'react-redux';
import { ComponentDataTable } from '../../../../components';
import { AbilityContext } from '../../../../config/abilityContext';
import ColumnsDataProcess from '../Columns/ColumnsDataProcess';
import MassiveActionDataProcess from './MassiveActionDataProcess';
import { debounceIndexDataProcessRequest } from '../../../../requests/dataProcess';
import { sendAlert } from '../../../../actions/utils';
import { exportDataProcesses } from '../../../../requests/payrollAdvancePaymentProcess';
import { PayrollProcessContext } from '../../context/PayrollProcessProvider';
import { downloadFile } from '../../../../services/utils';

const DataProcessDataTable = ({ selectedRows }) => {
  const advancePaymentProcessIds = selectedRows.map(({ id }) => id);
  const dispatch = useDispatch();
  const ability = useAbility(AbilityContext);
  const { payrollProcess } = useContext(PayrollProcessContext);
  const { currentCompany } = useSelector(state => state.auth);
  const [dataProcesses, setDataProcesses] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [amount, setAmount] = useState(0);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessIndex = response => {
    const { data } = response.data;
    setAmount(response.data.metadata.amount);
    setDataProcesses(data);
    setOnRequest(false);
    setMoreData(false);
    setClearSelectedRows(false);
  };

  const handleRequest = params => {
    setOnRequest(true);
    debounceIndexDataProcessRequest({
      dispatch,
      params: {
        filter_process_ids: advancePaymentProcessIds,
        ...params,
        sort_column: 'advance_payment_process_id'
      },
      successCallback: handleSuccessIndex,
      failureCallback: handleFailureRequest
    });
  };

  const handleMassAction = action => {
    switch (action) {
      case 'export':
        setOnRequest(true);
        exportDataProcesses({
          dispatch,
          params: {
            filter_company_id: currentCompany.id,
            filter_process_ids: advancePaymentProcessIds,
            payroll_process_id: payrollProcess.id,
            sort_column: 'advance_payment_process_id'
          },
          successCallback: response => {
            setMoreData(moreData);
            downloadFile(response);
          },
          failureCallback: error => {
            // eslint-disable-next-line no-console
            console.log(error);
          },
          callback: () => setOnRequest(false)
        });
        break;

      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  return (
    <ComponentDataTable
      clearSelectedRows={clearSelectedRows}
      columns={ColumnsDataProcess(ability)}
      data={dataProcesses}
      massActions={<MassiveActionDataProcess handleMassAction={handleMassAction} />}
      moreData={moreData}
      onRequest={onRequest}
      resourceRequest={handleRequest}
      totalRows={amount}
      withMassActions
      withSearch={false}
    />
  );
};

export default DataProcessDataTable;
