import ApiService from '../services/apiService';

const API_PAYROLL_SERVICE = 'payroll';

export const indexPayrollBalanceEmployees = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_balance_employees`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const createPayrollBalanceEmployees = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('post', `/payroll_balance_employees`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const showPayrollBalanceEmployee = (id, { dispatch, params = {}, successCallback, failureCallback, callback }) =>
  ApiService.request('get', `/payroll_balance_employees/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const updatePayrollBalanceEmployees = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, callback }
) =>
  ApiService.request('put', `/payroll_balance_employees/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const getEmployeeBalances = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/payroll_balance_employees/employee_balances`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const deactivatePayrollBalanceEmployees = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, callback }
) =>
  ApiService.request('put', `/payroll_balance_employees/${id}/deactivate`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const massiveDeactivatePayrollBalanceEmployees = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback
}) =>
  ApiService.request('post', `/payroll_balance_employees/massive_deactivate`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const exportPayrollBalanceEmployees = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/payroll_balance_employees/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const preImportPayrollBalanceEmployee = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/payroll_balance_employees/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    service: API_PAYROLL_SERVICE
  });
};

export const importPayrollBalanceEmployee = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/payroll_balance_employees/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    service: API_PAYROLL_SERVICE
  });
};

export const importTemplatePayrollBalanceEmployee = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/payroll_balance_employees/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    service: API_PAYROLL_SERVICE
  });
};
