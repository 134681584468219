import React, { useMemo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { LongCard } from '../../components';

const LoanProcessCard = ({ loanProcess, handleUpdate }) => {
  const processStatus = useMemo(() => {
    if (loanProcess.status === 'open') {
      return <h4 className="text-uppercase text-primary my-0">ABIERTO</h4>;
    }
    if (loanProcess.status === 'closed') {
      return <h4 className="text-uppercase text-danger my-0">CERRADO</h4>;
    }
    return '';
  }, [loanProcess]);

  return (
    <>
      <Row className="my-1 mx-1">
        <Col>{processStatus}</Col>
        <Col md={2}>
          <Button className="ml-3" variant="primary" block onClick={() => handleUpdate()}>
            Actualizar
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={12}>
          <Row>
            <Col xs={12} md={6} xl={3}>
              <LongCard
                variant="primary"
                title={loanProcess.loans_count}
                label="Préstamos a procesar"
                active
                onClick={() => {}}
              />
            </Col>
            <Col xs={12} md={6} xl={3}>
              <LongCard
                variant="warning"
                title={loanProcess.parsed_discount_total}
                label="Monto Cuotas a procesar"
                active
                onClick={() => {}}
              />
            </Col>
            <Col xs={12} md={6} xl={3}>
              <LongCard
                variant="danger"
                title={loanProcess.parsed_deferred_total}
                label="Monto postergado"
                active
                onClick={() => {}}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default LoanProcessCard;
