import React from 'react'
import PropTypes from 'prop-types';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { Field, getIn, withFormik, Form } from 'formik';
import { delayMethod } from '../../../services/utils';
import { FormikInput } from '../../../components/Utils/Input';
import { PhoneWithCode, RegionCommune } from '../../../components/SharedFields';
import { searchEmployeeUniqueEmail } from '../../../requests/employees';

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

const EmployeeQuickEdit = props => {

  const {
    fieldType,
    errors,
    touched,
    setFieldValue,
    employee,
    handleModalClose,
    isSubmitting
  } = props

  const { phoneCountryCode } = employee

  return (
    <>
      <Form>
        <Modal.Body>
          {
            fieldType === 'email' &&
            <>
              <Col md={6}>
                <Field name="employee[oldEmail]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      label="E-mail Personal Actual"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="employee[email]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      label="E-mail Personal Nuevo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="employee[confirmEmail]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      label="Confirme E-mail Personal Nuevo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </>
          }
          {
            fieldType === 'phone' &&
            <>
              <Col md={6}>
                <PhoneWithCode
                  label="Teléfono Personal Actual"
                  countryCodeField="employee[oldPhoneCountryCode]"
                  phoneField="employee[oldPhone]"
                  workPhoneCountryCode={phoneCountryCode}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  disabled
                />
              </Col>
              <Col md={6}>
                <PhoneWithCode
                  label="Nuevo Teléfono Personal"
                  countryCodeField="employee[phoneCountryCode]"
                  phoneField="employee[phone]"
                  workPhoneCountryCode={phoneCountryCode}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </Col>
              <Col md={6}>
                <PhoneWithCode
                  label="Confirmar Nuevo Teléfono Personal"
                  countryCodeField="employee[confirmPhoneCountryCode]"
                  phoneField="employee[confirmPhone]"
                  workPhoneCountryCode={phoneCountryCode}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </Col>
            </>
          }
          {
            fieldType === 'address' &&
            <>
              <Row>
                <Col md={12}>
                  <Field name="employee[fullAddress]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Dirección Actual"
                        value={employee.fullAddress}
                        disabled
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Field name="employee[address]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Dirección"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={3}>
                  <Field name="employee[addressNumber]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Número"
                        inputType="number"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={3}>
                  <Field name="employee[addressApt]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Departamento/Casa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <RegionCommune modelKey="employee" />
              </Row>
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose, 100)}>
            Cerrar
          </Button>
          <Button
            className="text-center"
            variant="primary"
            type='submit'
            disabled={isSubmitting}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </>
  )
}

const initialValues = props => {
  const { fieldType, employee } = props
  return {
    employee: {
      fieldType,
      email: '',
      workEmail: employee.workEmail || '',
      phoneCountryCode: '+56',
      phone: '',
      address: '',
      addressNumber: '',
      addressApt: '',
      workPhone: employee.workPhone || '',
      confirmEmail: '',
      confirmPhoneCountryCode: '+56',
      confirmPhone: '',
      oldEmail: employee.email || '',
      oldPhone: employee.phone || '',
      oldPhoneCountryCode: employee.phoneCountryCode || '+56',
      oldAdress: employee.address || '',
      oldAdressNumber: employee.addressNumber,
      oldAddressApt: employee.addressApt || '',
      communeId: '',
      regionId: '',
    }
  };
};

const successCallback = (resolve, response) => {
  if (response?.status === 200) {
    resolve(true)
  }
}

const failedCallback = (resolve, error, response) => {// eslint-disable-line no-unused-vars
  resolve(false)
}

const validationSchema = ({ employee, dispatch }) => {
  return Yup.object().shape({
    employee: Yup.object().shape({
      email: Yup.string()
        .when('fieldType', {
          is: val => val === 'email',
          then: Yup.string()
            .required('Debes ingresar un e-mail personal')
            .email('Debes ingresar un e-mail válido')
            .notOneOf([Yup.ref('oldEmail'), Yup.ref('workEmail')], (e) => {
              if (e.value === employee.workEmail) return 'E-mail no puede ser igual al e-mail laboral';
              if (e.value === employee.email) return 'Nuevo e-mail no puede ser igual al actual';
              return 'Nuevo e-mail no puede ser igual al anterior';
            })
            .oneOf([Yup.ref('confirmEmail')], 'Los e-mail no coinciden')
            .test('checkDuplicatedEmail', 'E-mail esta en uso', async (value) => {
              if (value && value.length > 4) {
                const promise = await new Promise((resolve, _) => {// eslint-disable-line no-unused-vars
                  searchEmployeeUniqueEmail({
                    dispatch,
                    params: { email: value, id: employee.id },
                    successCallback: (response) => successCallback(resolve, response),
                    failureCallback: (error, response) => failedCallback(resolve, error, response)
                  })
                })
                return promise
              }
              return true
            })
        }),
      phone: Yup.string().when('fieldType', {
        is: val => val === 'phone',
        then: Yup.string()
          .when('phoneCountryCode', {
            is: val => val === '+56',
            then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
            otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
          })
          .required('Este campo es obligatorio')
          .oneOf([Yup.ref('confirmPhone'), null], 'Los números de telefono no coinciden')
          .notOneOf([Yup.ref('oldPhone'), null], 'Nuevo número no debe ser igual al anterior')
      }),
      phoneCountryCode: Yup.string().when('fieldType', {
        is: val => val === 'phone',
        then: Yup.string()
          .oneOf([Yup.ref('confirmPhoneCountryCode'), null], 'Los números de telefono no coinciden')
      }),
      address: Yup.string().when('fieldType', {
        is: val => val === 'address',
        then: Yup.string()
          .max(120, 'Deben ser menos que 120 caracteres')
          .required('Este campo es obligatorio')
      }),
      addressNumber: Yup.number().when('fieldType', {
        is: val => val === 'address',
        then: Yup.number()
          .nullable()
          .max(99999, 'Número maximo permitido superado')
      }),
      addressApt: Yup.string().when('fieldType', {
        is: val => val === 'address',
        then: Yup.string()
          .nullable()
          .max(120, 'Deben ser menos que 120 caracteres')
      }),
      communeId: Yup.number().when('fieldType', {
        is: val => val === 'address',
        then: Yup.number()
          .required('Este campo es obligatorio')
      }),
      regionId: Yup.number().when('fieldType', {
        is: val => val === 'address',
        then: Yup.number()
          .required('Este campo es obligatorio')
      }),
    })
  })
}

EmployeeQuickEdit.propTypes = {
  fieldType: PropTypes.string
}

EmployeeQuickEdit.defaultProps = {
  fieldType: ''
}

export default withFormik({
  mapPropsToValues: props => initialValues(props),
  validationSchema,
  handleSubmit
})(EmployeeQuickEdit)