import React from 'react';
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { useSetTab } from '../../../services/hooks';
import { Icon } from '../../../components';
import SyndicateDataTable from './SyndicateDataTable';
import JointCommitteeDataTable from './JointCommitteeDataTable';
import { AbilityContext } from '../../../config/abilityContext';

const GroupingIndex = ({ location }) => {
  const ability = useAbility(AbilityContext);
  const groupingPermissions = [ability.can('read', 'Syndicate'), ability.can('read', 'JointCommittee')];
  const [key, setKey] = useSetTab(groupingPermissions[0] ? 'syndicates' : 'joint-committees', location);

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mt-3 mb-3">Agrupaciones</h2>
        </Col>
      </Row>
      <Tab.Container id="grouping-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills" className="ab-pills">
          {groupingPermissions[0] && (
            <Nav.Item>
              <Nav.Link eventKey="syndicates">
                <Icon icon="people-circle" />
                Sindicatos
              </Nav.Link>
            </Nav.Item>
          )}

          {groupingPermissions[1] && (
            <Nav.Item>
              <Nav.Link eventKey="joint-committees">
                <Icon icon="chatbubbles" />
                Comités
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          {groupingPermissions[0] && (
            <Tab.Pane eventKey="syndicates">
              <SyndicateDataTable />
            </Tab.Pane>
          )}
          {groupingPermissions[1] && (
            <Tab.Pane eventKey="joint-committees">
              <JointCommitteeDataTable />
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default GroupingIndex;
