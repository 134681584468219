/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icon from '../Icons';
import BasicTooltip from '../Utils/Tooltips/BasicTooltip';

const DatatableEmployeeName = ({
  item,
  fileName,
  name,
  tooltipText,
  tooltipClass,
  withDot,
  withPhoto,
  fileUrl,
  onClick,
  errorColor,
  startDateContract
}) => {
  const pathFileUrl = item?.[fileName]?.[fileUrl];

  return (
    <>
      {withDot &&
        (tooltipText ? (
          <BasicTooltip text={tooltipText}>
            <span style={{ marginRight: 10 }} className={`dot ${tooltipClass}`} />
          </BasicTooltip>
        ) : (
          <span style={{ marginRight: 22 }} />
        ))}
      {withPhoto && (
        <>
          <span className="avatar" onClick={onClick} role="presentation">
            <span className={pathFileUrl ? 'content' : 'empty content'}>
              {pathFileUrl ? (
                <Image src={pathFileUrl} width="60px" height="60px" fluid />
              ) : (
                <Icon icon="camera" width="60px" height="60px" />
              )}
            </span>
          </span>
        </>
      )}
      <div className="d-flex flex-column" onClick={onClick} role="presentation">
        <span className={`${errorColor || 'text-muted'}`} data-tag="allowRowEvents">
          {item?.national_identification || item?.nationalIdentification}
        </span>
        <span data-tag="allowRowEvents">{item?.[name]}</span>
        {startDateContract && (
          <span className={`${errorColor || 'text-muted'}`} data-tag="allowRowEvents">
            ({startDateContract})
          </span>
        )}
      </div>
    </>
  );
};

DatatableEmployeeName.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  fileName: PropTypes.string,
  fileUrl: PropTypes.string,
  name: PropTypes.string,
  tooltipClass: PropTypes.string,
  tooltipText: PropTypes.string,
  withDot: PropTypes.bool,
  withPhoto: PropTypes.bool,
  onClick: PropTypes.func,
  startDateContract: PropTypes.string
};

DatatableEmployeeName.defaultProps = {
  fileName: 'file_info',
  fileUrl: 'file_url',
  name: 'short_name',
  tooltipClass: '',
  tooltipText: '',
  withDot: false,
  withPhoto: true,
  onClick: () => null,
  startDateContract: null
};

export default DatatableEmployeeName;
