import React from 'react';
import { Table } from 'react-bootstrap';

const TotalizedAbsenceReportExpanderBody = ({ records }) => {
  const renderRecords = record => {
    return (
      <>
        <td>{record.total_days}</td>
        <td>{record.non_worked_days}</td>
        <td>{record.total_worked_days}</td>
      </>
    );
  };

  return (
    <>
      <Table className="custom-data-table" bordered size="sm" style={{ fontWeight: 400 }}>
        <thead className="rdt_TableHeadRow" style={{ backgroundColor: '#EBEBEB', textTransform: 'uppercase' }}>
          <tr className="rdt_TableCol">
            <th className="text-center align-middle">Total de días consultados</th>
            <th className="text-center align-middle">Total de días no trabajados</th>
            <th className="text-center align-middle">Total de días trabajados</th>
          </tr>
        </thead>
        <tbody>{records && renderRecords(records)}</tbody>
      </Table>
    </>
  );
};

export default TotalizedAbsenceReportExpanderBody;
