import React, { Component } from 'react';
import { connect } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { showSyndicateRequest, updateSyndicateRequest } from '../../requests/syndicates';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import SyndicateForm from './SyndicateForm';

class SyndicateEdit extends Component {
  state = {
    syndicate: {
      address: '',
      addressFloor: '',
      addressNumber: '',
      addressOffice: '',
      code: '',
      communeId: '',
      communeName: '',
      employeeSyndicatesAttributes: [],
      identificationNumber: '',
      name: '',
      phone: '',
      phoneCountryCode: '+56',
      regionId: ''
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { match, dispatch } = this.props;
    const syndicateId = match.params.id;
    showSyndicateRequest(syndicateId, {
      dispatch,
      successCallback: this.handleSuccessShow
    });
  }

  handleSuccessShow = response => {
    const syndicate = camelCaseEmptyStringRecursive(response.data);
    this.setState({ syndicate });
  };

  handleFailureRequest = error => {
    const { dispatch } = this.props;
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  handleUpdateRequest = (syndicate, setSubmitting) => {
    const { dispatch } = this.props;
    const syndicateId = syndicate.syndicate.id;
    const myParams = snakeCaseKeys(syndicate, { exclude: ['_destroy'] });

    updateSyndicateRequest(syndicateId, {
      dispatch,
      params: myParams,
      successCallback: this.handleSuccessUpdate,
      failureCallback: error => {
        this.handleFailureRequest(error);
        setSubmitting(false);
      }
    });
  };

  handleSuccessUpdate = () => {
    const { history, dispatch, match } = this.props;
    const syndicateId = match.params.id;
    dispatch(sendAlert({ kind: 'success', message: 'Sindicato actualizado con éxito' }));
    history.push(`/syndicates/${syndicateId}`);
  };

  render() {
    const { syndicate } = this.state;
    return (
      <>
        <h2 className="text-uppercase mb-3 mt-5">Editar Sindicato</h2>
        <SyndicateForm
          syndicate={syndicate}
          action="edit"
          submitVariant="success"
          formRequest={this.handleUpdateRequest}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const { company } = state.auth;
  return { company };
};

export default connect(mapStateToProps)(SyndicateEdit);
