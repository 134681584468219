import React from 'react';
import { Field, Form, getIn, withFormik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { DatatableHourMinute, FormikSelect } from '../../../components';
import { overtimeDiscountsFactors } from './FormOptions';

const CalculatedOvertimeMovementForm = props => {
  const { errors, hideModal, isSubmitting, setFieldTouched, setFieldValue, touched, values } = props;
  const { overtimeDiscountMovement } = values;
  const { employee } = overtimeDiscountMovement;
  const itemValues = overtimeDiscountsFactors;

  return (
    <Form>
      <Row>
        <table className="table table-borderless">
          <tr>
            <th className="text-center" style={{ maxWidth: '150px' }}>
              NOMBRE
            </th>
            <th className="text-center" style={{ minWidth: '150px' }}>
              RUT
            </th>
          </tr>
          <tr>
            <td className="text-center align-middle">{employee?.fullName}</td>
            <td className="text-center align-middle">{employee?.nationalIdentification}</td>
          </tr>
          <tr>
            <th className="text-center" style={{ maxWidth: '150px' }}>
              ITEM
            </th>
            <th className="text-center" style={{ minWidth: '150px' }}>
              FECHA
            </th>
          </tr>
          <tr>
            <td className="text-center align-middle">{overtimeDiscountMovement.translatedHourValue}</td>
            <td className="text-center align-middle">{overtimeDiscountMovement.startDate}</td>
          </tr>
          <tr>
            <th className="text-center" style={{ maxWidth: '150px' }}>
              CANTIDAD
            </th>
            <th className="text-center" style={{ minWidth: '150px' }}>
              FACTOR
            </th>
          </tr>
          <tr>
            <td className="text-center align-middle">
              <DatatableHourMinute item={overtimeDiscountMovement} />
            </td>
            <td className="text-center align-middle">
              <Field name="overtimeDiscountMovement[factor]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    placeholder="Seleccionar"
                    options={itemValues}
                    defaultValue={overtimeDiscountMovement.factor}
                    onChange={data => {
                      setFieldValue(field.name, data ? data.value : '');
                    }}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    margin="mb-1"
                  />
                )}
              </Field>
            </td>
          </tr>
        </table>
      </Row>
      <Row className="d-flex justify-content-end mt-2">
        <Col md={3} lg={3} className="mb-2 mb-md-0 mr-md-n4 mr-lg-n2">
          <Button block variant="outline-info" onClick={hideModal}>
            Cancelar
          </Button>
        </Col>
        <Col md={5} lg={3}>
          <Button type="submit" disabled={isSubmitting} block>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    employee,
    hours,
    hourValue,
    minutes,
    startDate,
    translatedHourValue,
    factor
  } = props.overtimeDiscountMovement;

  return {
    overtimeDiscountMovement: {
      employee,
      factor,
      hours,
      hourValue,
      minutes,
      translatedHourValue,
      startDate
    }
  };
};

const validationSchema = Yup.object().shape({
  overtimeDiscountMovement: Yup.object().shape({
    factor: Yup.string().required('Debes seleccionar un factor')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(CalculatedOvertimeMovementForm);
