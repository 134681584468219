import React, { useState } from 'react';
import { withFormik, Form, useFormikContext } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import * as Yup from 'yup';
import camelCaseRecursive from 'camelcase-keys-recursive';
import AmountInput from './AmountInput';
import PeriodSelect from './PeriodSelect';
import DateInput from './DateInput';
import { delayMethod } from '../../../../services/utils';
import EmployeeInput from './EmployeeInput';
import { DefaultModal } from '../../../../components';

const EditBalanceForm = ({ payrollBalance, isSubmitting, payrollProcess, formRequest, handleModalClose, ...props }) => {
  const { values, submitForm, validateForm } = useFormikContext();

  const [modalState, setModalState] = useState({
    title: '',
    body: <></>,
    show: false,
    action: function empty() {}
  });

  const handleWarningModalClose = () => {
    setModalState({
      title: '',
      body: <></>,
      show: false,
      action: function empty() {}
    });
  };

  const handleBeforeSubmit = () => {
    const payrollBalanceForm = values.payrollBalance;
    const startDateToValidate = moment(new Date(payrollBalanceForm.startDate));
    const startDateToCompare = moment(new Date(payrollProcess.year, payrollProcess.month_number, 1));
    validateForm();
    if (startDateToCompare.isBefore(startDateToValidate)) {
      setModalState({
        title: 'Advertencia',
        body: (
          <p>
            La fecha inicio es mayor a la fecha del proceso, por tal motivo el concepto ya no va a pertenecer a este
            proceso de nomina
          </p>
        ),
        show: true,
        action: () => {
          submitForm();
          handleWarningModalClose();
        }
      });
      return;
    }
    submitForm();
  };

  return (
    <>
      <Form>
        <Row className="align-items-center">
          <Col sm={12} md={12}>
            <Row>
              <EmployeeInput payrollBalance={payrollBalance} {...props} />
            </Row>
          </Col>
          <Col sm={12} md={6}>
            <AmountInput payrollBalance={payrollBalance} {...props} />
          </Col>
          <Col sm={12} md={6}>
            <PeriodSelect payrollBalance={payrollBalance} {...props} />
          </Col>
          <DateInput payrollBalance={payrollBalance} {...props} />
        </Row>
        <Row>
          <Col />
          <Col md={3}>
            <Button
              className="mt-3"
              block
              variant="outline-info"
              onClick={() => delayMethod(handleModalClose)}
              disabled={isSubmitting}
            >
              Cancelar
            </Button>
          </Col>
          <Col md={3}>
            <Button className="mt-3" variant="primary" block onClick={handleBeforeSubmit} disabled={isSubmitting}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
      <DefaultModal
        title={modalState.title}
        body={modalState.body}
        show={modalState.show}
        handleClose={() => setModalState(state => ({ ...state, show: false }))}
        handleConfirm={() => modalState.action()}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

const handleSubmit = (values, { props, setSubmitting, resetForm }) => {
  const { formRequest, payrollBalance } = props;
  const data = {
    payrollBalanceEmployee: {
      amount: values.payrollBalance.amount,
      periodicity: values.payrollBalance.periodicity,
      startDate: moment(values.payrollBalance.startDate).format('YYYY-MM-DD'),
      endDate: values.payrollBalance.endDate ? moment(values.payrollBalance.endDate).format('YYYY-MM-DD') : null
    }
  };
  formRequest(payrollBalance.id, data, setSubmitting, resetForm);
};

const validationSchema = Yup.object().shape({
  payrollBalance: Yup.object().shape({
    periodicity: Yup.string().required('Debes seleccionar un periodo.'),
    startDate: Yup.string()
      .required('Debes seleccionar una fecha de inicio.')
      .when('periodicity', {
        is: val => val !== 'permanent',
        then: Yup.string().test(
          'startDate',
          'La fecha de inicio debe ser menor o igual a la fecha de fin',
          function valid(value) {
            const { endDate } = this.parent;
            if (endDate) {
              return !moment(new Date(endDate)).isBefore(new Date(value));
            }
            return true;
          }
        )
      }),
    endDate: Yup.string()
      .when('periodicity', {
        is: val => val !== 'permanent',
        then: Yup.string('Debes seleccionar una fecha fin').required('Debes seleccionar una fecha fin')
      })
      .nullable(true)
  })
});

const tranformDate = date => {
  if (date) {
    const dateList = date.split('-');
    return new Date(dateList[0], dateList[1] - 1, 1);
  }
  return null;
};

const setInitialValues = props => {
  const { payrollBalance } = props;
  const payrollBalanceToEdit = camelCaseRecursive(payrollBalance);

  payrollBalanceToEdit.startDate = tranformDate(payrollBalanceToEdit.startDate);
  payrollBalanceToEdit.endDate = tranformDate(payrollBalanceToEdit.endDate);
  return {
    payrollBalance: { ...payrollBalanceToEdit }
  };
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: false,
  validateOnChange: false,
  validateOnMount: false
})(EditBalanceForm);
