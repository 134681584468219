import React from 'react';
import ContentLoader from 'react-content-loader';

const SimpleChartSkeleton = () => (
  <ContentLoader height={200} width={400} viewBox="0 0 400 200">
    <rect x="120" y="142" rx="0" ry="0" width="25" height="58" />
    <rect x="150" y="152" rx="0" ry="0" width="25" height="60" />
    <rect x="180" y="162" rx="0" ry="0" width="25" height="80" />
    <rect x="210" y="172" rx="0" ry="0" width="25" height="90" />
    <rect x="240" y="182" rx="0" ry="0" width="25" height="130" />
    <rect x="0" y="160" rx="0" ry="0" width="25" height="40" />
    <rect x="30" y="145" rx="0" ry="0" width="25" height="55" />
    <rect x="60" y="126" rx="0" ry="0" width="25" height="74" />
    <rect x="90" y="80" rx="0" ry="0" width="25" height="120" />
  </ContentLoader>
);

const MultiChartSkeleton = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'white',
      padding: 10,
      paddingLeft: 50,
      paddingBottom: 80,
      borderRadius: 8
    }}
  >
    <SimpleChartSkeleton />
    <SimpleChartSkeleton />
  </div>
);

const CardSkeleton = () => (
  <div
    style={{
      display: 'flex',
      alignSelf: 'center',
      backgroundColor: 'white',
      paddingRight: 15,
      paddingLeft: 15,
      borderRadius: 8
    }}
  >
    <ContentLoader height={200} width={360} viewBox="0 0 360 200">
      <rect x="0" y="15" rx="4" ry="4" width="1000" height="25" />
      <rect x="0" y="50" rx="2" ry="2" width="1000" height="150" />
      <rect x="0" y="230" rx="2" ry="2" width="1000" height="20" />
      <rect x="0" y="230" rx="2" ry="2" width="1000" height="20" />
    </ContentLoader>
  </div>
);

function BlankCard({ isChart = false }) {
  return <div style={{ flex: 1 }}>{isChart ? <MultiChartSkeleton /> : <CardSkeleton />}</div>;
}

export default BlankCard;
