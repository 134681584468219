import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Row, Table, useAccordionToggle } from 'react-bootstrap';
import Header from './Header';
import './QuoteDetails.css';

const CustomAccordion = ({ eventKey, children, activeKey }) => {
  const handleClick = useAccordionToggle(eventKey, () => {});
  const currentEventKey = activeKey === eventKey;
  return (
    <>
      <Col onClick={handleClick} className="card-header-title">
        <Col className="d-flex" xs={11}>
          {children}
        </Col>
        <Col xs={1}>
          <button type="button" className={currentEventKey ? 'accordion-button collapsed' : 'accordion-button'} />
        </Col>
      </Col>
    </>
  );
};

export default function QuoteDetails({ reportData, handleDownload, disabled }) {
  const { processName, afp, afpDetails, isapre, isapreDetails, mutual, totalAmount, ccaf, ipsHealth } = reportData.data;
  const { filterParams } = reportData;
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {}, [activeKey]);
  return (
    <>
      <Header reportName={reportData.reportName} processName={processName} {...filterParams} />
      <Row className="mt-4 mb-3">
        <Col xs={8}>
          <h3>Detalle Cotizaciones</h3>
        </Col>
        <Col xs={2}>
          <h3>Total a Pagar</h3>
        </Col>
        <Col xs={2}>
          <h3>{totalAmount}</h3>
        </Col>
      </Row>
      <Accordion onSelect={key => setActiveKey(key)}>
        <Card className="quote-details--card">
          <Col className="top-header-green">
            <CustomAccordion eventKey="0" activeKey={activeKey}>
              <>
                <Col xs={8}>AFP</Col>
                <Col xs={2}>Subtotal</Col>
                <Col xs={2}>{afp}</Col>
              </>
            </CustomAccordion>
          </Col>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="div-content">
              <Table className="conceptDetailsTable" responsive>
                <>
                  <thead className="text-uppercase">
                    <tr>
                      <th className="text-left">INSTITUCIÓN</th>
                      <th className="text-left">TOTAL A PAGAR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {afpDetails &&
                      afpDetails.map(item => (
                        <tr key={item.name}>
                          <td className="text-left">{item.name}</td>
                          <td className="text-left">{item.value}</td>
                        </tr>
                      ))}
                  </tbody>
                </>
              </Table>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="quote-details--card">
          <Col className="top-header-green">
            <CustomAccordion eventKey="1" activeKey={activeKey}>
              <>
                <Col xs={8}>IPS / SALUD</Col>
                <Col xs={2}>Subtotal</Col>
                <Col xs={2}>{ipsHealth.value}</Col>
              </>
            </CustomAccordion>
          </Col>
          <Accordion.Collapse eventKey="1">
            <Card.Body className="div-content">
              <Table className="conceptDetailsTable" responsive>
                <>
                  <thead className="text-uppercase">
                    <tr>
                      <th className="text-left">INSTITUCIÓN</th>
                      <th className="text-left">TOTAL A PAGAR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left"> {ipsHealth.name}</td>
                      <td className="text-left"> {ipsHealth.value}</td>
                    </tr>
                  </tbody>
                </>
              </Table>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {ccaf?.name && (
          <Card className="quote-details--card">
            <Col className="top-header-green">
              <CustomAccordion eventKey="2" activeKey={activeKey}>
                <>
                  <Col xs={8}>CAJA DE COMPENSACIÓN</Col>
                  <Col xs={2}>Subtotal</Col>
                  <Col xs={2}>{ccaf.value}</Col>
                </>
              </CustomAccordion>
            </Col>
            <Accordion.Collapse eventKey="2">
              <Card.Body className="div-content">
                <Table className="conceptDetailsTable" responsive>
                  <>
                    <thead className="text-uppercase">
                      <tr>
                        <th className="text-left">{ccaf.name}</th>
                        <th className="text-left">{ccaf.value}</th>
                      </tr>
                    </thead>
                  </>
                </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
        <Card className="quote-details--card">
          <Col className="top-header-green">
            <CustomAccordion eventKey="3" activeKey={activeKey}>
              <>
                <Col xs={8}>MUTUAL</Col>
                <Col xs={2}>Subtotal</Col>
                <Col xs={2}>{mutual.value}</Col>
              </>
            </CustomAccordion>
          </Col>
          <Accordion.Collapse eventKey="3">
            <Card.Body className="div-content">
              <Table className="conceptDetailsTable" responsive>
                <>
                  <thead className="text-uppercase">
                    <tr>
                      <th className="text-left">{mutual.name}</th>
                      <th className="text-left">{mutual.value}</th>
                    </tr>
                  </thead>
                </>
              </Table>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="quote-details--card">
          <Col className="top-header-green">
            <CustomAccordion eventKey="4" activeKey={activeKey}>
              <>
                <Col xs={8}>ISAPRE</Col>
                <Col xs={2}>Subtotal</Col>
                <Col xs={2}>{isapre}</Col>
              </>
            </CustomAccordion>
          </Col>
          <Accordion.Collapse eventKey="4">
            <Card.Body className="div-content">
              <Table className="conceptDetailsTable" responsive>
                <>
                  <thead className="text-uppercase">
                    <tr>
                      <th className="text-left">INSTITUCIÓN</th>
                      <th className="text-left">TOTAL A PAGAR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isapreDetails &&
                      isapreDetails.map(item => (
                        <tr key={item.name}>
                          <td className="text-left">{item.name}</td>
                          <td className="text-left">{item.value}</td>
                        </tr>
                      ))}
                  </tbody>
                </>
              </Table>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Row className="d-flex justify-content-end mt-4 mb-4">
        <Col md={2}>
          <Button className="btn-block" type="submit" onClick={handleDownload} disabled={disabled}>
            Descargar Reporte
          </Button>
        </Col>
      </Row>
    </>
  );
}
