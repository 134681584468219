import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Row, Col, Spinner, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { sendAlert } from '../../../actions/utils';
import { updateStatusAdminCompanyRequest } from '../../../requests/adminCompanies';
import { previredIndicatorsRequest } from '../../../requests/indicators';

const BankShow = ({ account }) => (
  <>
    <div className="info">
      <span className="type">Banco:</span>
      <span className="line" />
      <span className="answer"> {account.bankName}</span>
    </div>
    <div className="info">
      <span className="type">Número de Cuenta:</span>
      <span className="line" />
      <span className="answer"> {account.account}</span>
    </div>
  </>
);

const yesNo = value => {
  return value ? 'Sí' : 'No';
};

const CompanyInfo = props => {
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const { company, admin, isProfile } = props;
  const history = useHistory();
  const {
    attendanceManagement,
    bankAccountsAttributes,
    businessName,
    compensationFund,
    consultativeDtAccess,
    economicActivity,
    email,
    fantasyName,
    fullAddress,
    nationalIdentification,
    parsedPhone,
    peopleManagement,
    remunerations,
    safetyOrganization,
    signature,
    talentManagement,
    website,
    mutualRateBasic,
    mutualRateExtraordinary,
    adherentNumber,
    translatedWorkActivity,
    billingCompany,
    yearOfAgreement
  } = company;

  let banks;
  if (bankAccountsAttributes) {
    banks = bankAccountsAttributes.map((account, index) => {
      return <BankShow key={`bank_${index.toString()}`} account={account} />;
    });
  }

  const updateStatus = status => {
    setOnRequest(true);
    const allParams = {
      dispatch,
      params: { status },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Empresa actualizada con éxito' }));
        history.push('/admin/companies');
      },
      callback: () => setOnRequest(false)
    };
    if (admin) updateStatusAdminCompanyRequest(company.id, allParams);
  };

  const [previredIndicators, setPreviredIndicators] = useState([]);
  const params = {};

  const fetchPreviredIndicators = () => {
    previredIndicatorsRequest({
      dispatch,
      params,
      successCallback: response => setPreviredIndicators(response.data)
    });
  };
  const initFetch = () => {
    fetchPreviredIndicators();
  };
  useEffect(initFetch, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <Card.Title>Datos empresa</Card.Title>
              <div className="info">
                <span className="type">Razón social:</span>
                <span className="line" />
                <span className="answer"> {businessName}</span>
              </div>
              <div className="info">
                <span className="type">Nombre de fantasía:</span>
                <span className="line" />
                <span className="answer"> {fantasyName}</span>
              </div>
              {admin && billingCompany && (
                <div className="info">
                  <span className="type">Empresa Facturación:</span>
                  <span className="line" />
                  <span className="answer"> {billingCompany?.businessName}</span>
                </div>
              )}
              <div className="info">
                <span className="type">RUT:</span>
                <span className="line" />
                <span className="answer"> {nationalIdentification}</span>
              </div>
              <div className="info text-truncate">
                <span className="type">Giro:</span>
                <span className="line" />
                <span className="answer"> {economicActivity?.label}</span>
              </div>
              <div className="info text-truncate">
                <span className="type">Actividad Laboral:</span>
                <span className="line" />
                <span className="answer"> {translatedWorkActivity}</span>
              </div>
              <div className="info text-truncate">
                <span className="type">¿En qué año se acogerá a las 40 horas?:</span>
                <span className="line" />
                <span className="answer"> {yearOfAgreement}</span>
              </div>
              {website && (
                <div className="info text-truncate">
                  <span className="type">Sitio web:</span>
                  <span className="line" />
                  <span className="answer">
                    <a
                      target="_blank"
                      className="font-weight-bold underline"
                      rel="noopener noreferrer"
                      href={website.substring(0, 3) === 'http' ? website : `http://${website}`}
                    >
                      {website}
                    </a>
                  </span>
                </div>
              )}
            </Col>
            <Col>
              <Card.Title>Representante Legal</Card.Title>
              {company.representantsAttributes &&
                company.representantsAttributes.map(representant => (
                  <div key={`representant-${representant.id}`}>
                    <div className="info">
                      <span className="type">Nombre Completo:</span>
                      <span className="line" />
                      <span className="answer">
                        {' '}
                        {representant.name} {representant.firstLastName} {representant.secondLastName}
                      </span>
                    </div>
                    <div className="info">
                      <span className="type">{representant.translatedIdentificationType}:</span>
                      <span className="line" />
                      <span className="answer"> {representant.nationalIdentification}</span>
                    </div>
                  </div>
                ))}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={!isProfile ? 12 : null} md={!isProfile ? 6 : null}>
              <Card.Title>Contacto empresa</Card.Title>
              <div className="info">
                <span className="type">Dirección:</span>
                <span className="line" />
                <span className="answer"> {fullAddress}</span>
              </div>
              {email && (
                <div className="info">
                  <span className="type">Email:</span>
                  <span className="line" />
                  <span className="answer"> {email}</span>
                </div>
              )}
              {parsedPhone && (
                <div className="info">
                  <span className="type">Teléfono:</span>
                  <span className="line" />
                  <span className="answer"> {parsedPhone}</span>
                </div>
              )}
            </Col>
            {!isProfile && (
              <Col>
                <Card.Title>Cuentas bancarias</Card.Title>
                {banks}
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
      {!isProfile && (
        <Card>
          <Card.Body>
            <Row>
              <Col xs={12}>
                <Card.Title>Otros</Card.Title>
              </Col>
              <Col md={6} xs={12}>
                <div className="info">
                  <span className="type">Mutual:</span>
                  <span className="line" />
                  <span className="answer"> {safetyOrganization?.label}</span>
                </div>
                <div className="info">
                  <span className="type">Caja de Compensación:</span>
                  <span className="line" />
                  <span className="answer"> {compensationFund?.label}</span>
                </div>
                <div className="info">
                  <span className="type">Ley Sanna:</span>
                  <span className="line" />
                  <span className="answer"> {previredIndicators?.sanna_law}%</span>
                </div>
                <div className="info">
                  <span className="type">Tasa de Cotización Básica:</span>
                  <span className="line" />
                  <span className="answer"> {mutualRateBasic}%</span>
                </div>
              </Col>
              <Col>
                <div className="info">
                  <span className="type">Tasa de Cotización Extraordinaria:</span>
                  <span className="line" />
                  <span className="answer"> {mutualRateExtraordinary}%</span>
                </div>
                <div className="info">
                  <span className="type">Número de Adherente (Código Contrato Mutual):</span>
                  <span className="line" />
                  <span className="answer"> {adherentNumber}%</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
      {admin && (
        <Card>
          <Card.Body>
            <Row>
              <>
                <Col xs={12} md={6}>
                  <Card.Title>Funcionalidades Permitidas</Card.Title>
                  <div className="info">
                    <span className="type">Gestión de Personas:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(peopleManagement)}</span>
                  </div>
                  <div className="info">
                    <span className="type">Firma Digital:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(signature)}</span>
                  </div>
                  <div className="info">
                    <span className="type">Control de Asistencia y Turnos:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(attendanceManagement)}</span>
                  </div>
                  <div className="info">
                    <span className="type">Remuneraciones:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(remunerations)}</span>
                  </div>
                  <div className="info">
                    <span className="type">Gestión de Talento:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(talentManagement)}</span>
                  </div>
                  <div className="info">
                    <span className="type">Acceso Consultivo DT:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(consultativeDtAccess)}</span>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <Card.Title>Exportar Empresa</Card.Title>
                  <div className="info">
                    <span className="type">Permite exportación:</span>
                    <span className="line" />
                    <span className="answer"> {yesNo(company.allowDownload)}</span>
                  </div>
                  <div className="info">
                    <span className="type">Fecha límite de descarga:</span>
                    <span className="line" />
                    <span className="answer"> {company.zipRequestEndDate}</span>
                  </div>
                  <div className="info">
                    <span className="type">Última descarga:</span>
                    <span className="line" />
                    <span className="answer"> {company.lastZipRequest}</span>
                  </div>
                </Col>
              </>
            </Row>
          </Card.Body>
        </Card>
      )}
      {admin && company.status === 'pending' && (
        <Card>
          <Card.Body>
            <Row>
              <Col xs={12} md={6}>
                <Card.Title>¿Acepta la creación de esta empresa?</Card.Title>
                <div className="info">
                  <span className="type">
                    Al aceptar la creación de esta empresa, se asignará como empleado a la persona que hizo la solicitud
                    y automaticamente se convertirá en administrador de la misma.
                  </span>
                </div>
              </Col>
              <Col sm={5} md={3}>
                <Button
                  onClick={() => updateStatus('accepted')}
                  className="w-100 mt-1 mt-md-4"
                  variant="info"
                  size="sm"
                  disabled={onRequest}
                >
                  Aceptar
                </Button>
              </Col>
              <Col sm={7} md={3}>
                <Button
                  onClick={() => updateStatus('rejected')}
                  className="w-100 mt-1 mt-md-4"
                  variant="danger"
                  size="sm"
                  disabled={onRequest}
                >
                  Rechazar
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
      {onRequest && (
        <div className="containerSpinnerLoad">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </>
  );
};

export default CompanyInfo;
