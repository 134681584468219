import React from 'react';
import { Accordion, Col, Card } from 'react-bootstrap';

const FamilyGroupShow = ({ familyGroup }) => (
  <>
    <Col md={6} sm={6} xs={12} className="mb-5">
      <div className="info">
        <span className="type">Tipo de Carga:</span>
        <span className="line" />
        <span className="answer"> {familyGroup.translatedChargeType}</span>
      </div>

      {familyGroup.translatedRelationType && (
        <div className="info">
          <span className="type">Relación:</span>
          <span className="line" />
          <span className="answer"> {familyGroup.translatedRelationType}</span>
        </div>
      )}
      {familyGroup.nationalIdentification && (
        <div className="info">
          <span className="type">Identificación ({familyGroup.translatedIdentificationType}):</span>
          <span className="line" />
          <span className="answer"> {familyGroup.nationalIdentification}</span>
        </div>
      )}
      {familyGroup.name && (
        <div className="info">
          <span className="type">Nombres:</span>
          <span className="line" />
          <span className="answer"> {familyGroup.name}</span>
        </div>
      )}
      {familyGroup.firstLastName && (
        <div className="info">
          <span className="type">Primer Apellido:</span>
          <span className="line" />
          <span className="answer"> {familyGroup.firstLastName}</span>
        </div>
      )}

      {familyGroup.secondLastName && (
        <div className="info">
          <span className="type">Segundo Apellido:</span>
          <span className="line" />
          <span className="answer"> {familyGroup.secondLastName}</span>
        </div>
      )}

      {familyGroup.birthdate && (
        <div className="info">
          <span className="type">Fecha de Nacimiento:</span>
          <span className="line" />
          <span className="answer"> {familyGroup.birthdate}</span>
        </div>
      )}
      {familyGroup.country?.label && (
        <div className="info">
          <span className="type">Nacionalidad:</span>
          <span className="line" />
          <span className="answer"> {familyGroup.country?.label}</span>
        </div>
      )}
      {familyGroup.gender && (
        <div className="info">
          <span className="type">Genero:</span>
          <span className="line" />
          <span className="answer"> {familyGroup.gender?.label}</span>
        </div>
      )}
      {familyGroup.chargeStartDate && (
        <div className="info">
          <span className="type">Fecha de Inicio:</span>
          <span className="line" />
          <span className="answer">{familyGroup.chargeStartDate}</span>
        </div>
      )}
      {familyGroup.chargeEndDate && (
        <div className="info">
          <span className="type">Fecha de Término:</span>
          <span className="line" />
          <span className="answer">{familyGroup.chargeEndDate}</span>
        </div>
      )}
      {familyGroup.observation && (
        <div className="info">
          <span className="type">Observación:</span>
          <span className="line" />
          <span className="answer"> {familyGroup.observation}</span>
        </div>
      )}
      {familyGroup.familyAllowanceSection && (
        <div className="info">
          <span className="type">Tramo asignación familiar</span>
          <span className="line" />
          <span className="answer"> {familyGroup.familyAllowanceSection}</span>
        </div>
      )}

      {familyGroup.chargeNumber && (
        <div className="info">
          <span className="type">Número de Carga</span>
          <span className="line" />
          <span className="answer"> {familyGroup.chargeNumber}</span>
        </div>
      )}
    </Col>
  </>
);

const FamilyGroupInfo = props => {
  const { familyGroupsAttributes, activeKey, activeAccordion } = props;

  return (
    <>
      <Card>
        <Col className={`${activeKey === 'family-group' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
          <Accordion.Toggle
            as={Col}
            eventKey="4"
            onClick={activeAccordion}
            className="card-header-title"
            style={{ textAlign: 'right' }}
          >
            GRUPO FAMILIAR
          </Accordion.Toggle>
        </Col>
        <Accordion.Collapse eventKey="4">
          <Card.Body className="div-content">
            {familyGroupsAttributes?.map((familyGroup, index) => {
              return <FamilyGroupShow key={`family_group_${index.toString()}`} familyGroup={familyGroup} />;
            })}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export default FamilyGroupInfo;
