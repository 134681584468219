import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const BasicTextArea = ({
  abbr,
  inputType,
  placeholder,
  helpText,
  label,
  size,
  row,
  error,
  touched,
  minInputHeight,
  maxLength,
  showCharacters,
  onChange,
  ...props
}) => {
  const [characters, setCharacters] = useState(0);

  const handleTextAreaChange = event => {
    if (maxLength && showCharacters) {
      const text = event.target.value;
      setCharacters(text.length);
    }
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Form.Group className={error && touched ? 'is-invalid' : ''}>
      {label && (
        <Form.Label className="d-flex align-items-center">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <Form.Control
        style={{ minHeight: `${minInputHeight}px` }}
        as="textarea"
        rows={row}
        size={size}
        maxLength={maxLength}
        type={inputType}
        placeholder={placeholder}
        onChange={handleTextAreaChange}
        {...props}
      />
      {maxLength && showCharacters && (
        <div style={{ marginTop: 10 }}>
          {' '}
          {characters}/{maxLength}
        </div>
      )}
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

export default BasicTextArea;
