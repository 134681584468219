import React from 'react';
import { Col } from 'react-bootstrap';
import * as Flags from 'country-flag-icons/react/3x2';

const styles = {
  flag: { height: 20, width: 25, borderRadius: 5 }
};

function LogicCountryRow({ nationality = {}, number }) {
  const FlagComponent = nationality?.code ? Flags[nationality?.code] : null;

  return (
    <>
      <Col md={2} xs={12}>
        {FlagComponent && <FlagComponent style={styles.flag} />}
      </Col>

      <Col md={5} xs={12} className="CountryContentText">
        <p className="CountryText">{nationality?.label}</p>
      </Col>
      <Col md={5} xs={12}>
        <div className="position-right">
          <div className="Horizontalbar" style={{ width: `${number * 2}px` }} />
          <div className="CountryContentText">
            <p className="CountryCountNumber">{number}</p>
          </div>
        </div>
      </Col>
    </>
  );
}

export default LogicCountryRow;
