import React, { useEffect, useState } from 'react';
import { MarkingsReport, markingsColumns } from '../../components/CompanyReport';

const MarkingsReportIndex = () => {
  const [moreData, setMoreData] = useState(false);
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <MarkingsReport columns={markingsColumns} moreData={moreData} setMoreData={setMoreData} />
    </>
  );
};

export default MarkingsReportIndex;
