import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ComponentDataTable, DefaultModal } from '../../components';
import {
  deleteApiPayrollProcessRequest,
  exportPayrollProcessRequest,
  indexApiPayrollRequest,
  massiveDestroyApiPayrollProcessRequest
} from '../../requests/payrollProcesses';
import columns from './ColumnsPayrollHistories';
import { sendAlert } from '../../actions/utils';
import massiveActions from '../RemunerationProcesses/AdvancePayments/Datatables/massiveActions';
import { downloadFile } from '../../services/utils';

const RemunerationProcessHistoriesDataTable = ({ moreData, setMoreData, customParams, setOnRequest, onRequest }) => {
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [amount, setAmount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [payrollProcesses, setPayrollProcesses] = useState([]);
  const [selectedCount, setSelectedCount] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const handleRequest = async params => {
    setOnRequest(true);
    setClearSelectedRows(false);
    indexApiPayrollRequest({
      dispatch,
      params: {
        ...customParams,
        ...params
      },
      successCallback: response => {
        setOnRequest(false);
        setPayrollProcesses(response.data.data);
        setAmount(response?.data?.metadata?.amount);
      },
      callback: () => {
        setOnRequest(false);
        setClearSelectedRows(true);
      }
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccess = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const removePayrollProcess = item => {
    setOnRequest(true);
    deleteApiPayrollProcessRequest(item.id, {
      dispatch,
      successCallback: () => handleSuccess('Proceso Eliminado Correctamente'),
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const massiveDestroy = () => {
    const payrollProcessIds = selectedRows.map(payroll => payroll.id);

    setOnRequest(true);
    setClearSelectedRows(false);
    massiveDestroyApiPayrollProcessRequest({
      dispatch,
      params: {
        ids: payrollProcessIds
      },
      successCallback: () => handleSuccess('Procesos Eliminados Correctamente'),
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(true);
        setMoreData(!moreData);
      }
    });
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
    setOnRequest(false);
  };

  const massiveExport = () => {
    const payrollProcessIds = selectedRows.map(payroll => payroll.id);
    setOnRequest(true);
    exportPayrollProcessRequest({
      dispatch,
      params: {
        filter_by_ids: payrollProcessIds,
        ...customParams
      },
      successCallback: response => {
        setModalShow(false);
        downloadFile(response);
        setOnRequest(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push({
          pathname: `/remuneration_histories/summary/${item.id}/edit`,
          state: { item, onlyShow: true }
        });
        break;
      case 'destroy':
        setModalTitle('Eliminar Proceso de Remuneración');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar este mes de proceso?');
        setModalAction(() => removePayrollProcess);
        setModalItem(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleClick = action => {
    switch (action) {
      case 'massive_deactivate':
        setModalTitle('Eliminar Procesos de Remuneración');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar los procesos seleccionados?');
        setModalAction(() => massiveDestroy);
        break;
      case 'massive_export':
        setModalTitle('Descargar Procesos de Remuneración');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas descargar los procesos seleccionados?');
        setModalAction(() => massiveExport);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        resourceRequest={handleRequest}
        columns={columns(handleButtonClick)}
        data={payrollProcesses}
        totalRows={amount}
        moreData={moreData}
        selectableRows
        selectedRowCount={selectedRows.selectedCount}
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleSelectedRows}
        withMassActions
        massActions={massiveActions(handleClick, true, !selectedCount)}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        variantBtnClose="outline-info"
        variantBtnSave="primary"
      />
    </>
  );
};

export default RemunerationProcessHistoriesDataTable;
