import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import LoanProcessesDataTable from '../Datatables/LoanProcessesDataTable';
import { DefaultModal } from '../../../../components';
import { PayrollProcessContext } from '../../context/PayrollProcessProvider';
import ModalWarningBody from '../ModalWarningBody';

const ERROR_STATE = {
  CANCEL_ERROR_PROCESS: false,
  CONTINUE_ERROR: false
};

const MODAL_STATE = {
  show: false,
  title: '',
  modalConfirm: false,
  handleConfirm: () => {},
  body: <></>
};

const FirstStep = ({ handleNextStep, handleSelectedRows, selectedRows = [], allSelected, onlyShow }) => {
  const [modalState, setModalState] = useState(MODAL_STATE);
  const { user } = useSelector(state => state.auth);
  const [errorState, setErrorState] = useState(ERROR_STATE);
  const { payrollProcess } = useContext(PayrollProcessContext);

  const validateSelectedRows = () => {
    if (selectedRows.length === 0) return;
    const notClosed = selectedRows.filter(row => row.status !== 'closed');
    const alreadyAssociated = selectedRows.filter(row => row.payroll_status === 'associated');
    const allProcesses = [...notClosed, ...alreadyAssociated];
    if (allProcesses.length > 0) {
      setErrorState({
        CONTINUE_ERROR: allProcesses.length > 0,
        PROCESSES: allProcesses
      });
      setModalState({
        ...MODAL_STATE,
        show: true,
        text: '',
        body: <></>,
        title: 'Seleccion de Periodo',
        modalConfirm: false
      });
      return;
    }
    handleNextStep();
  };

  const validateOnCancelAssociation = handleConfirm => {
    const payrollPending = selectedRows.filter(
      row => row.status === 'closed' && row.payroll_status === 'not_associated'
    );
    const notAssociated = selectedRows.filter(
      row => row.payroll_process_id !== payrollProcess.id && row.payroll_status === 'associated'
    );
    const notClosed = selectedRows.filter(row => row.status !== 'closed');
    const allProcesses = [...payrollPending, ...notAssociated, ...notClosed];

    if (allProcesses.length > 0) {
      setErrorState({
        CANCEL_ERROR_PROCESS: allProcesses.length > 0,
        PROCESSES: allProcesses
      });
      setModalState({
        ...MODAL_STATE,
        show: true,
        modalConfirm: true,
        text: '',
        handleConfirm: () => handleConfirm(setModalState),
        title: 'Seleccion de Periodo a Anular'
      });
      return;
    }
    setErrorState(ERROR_STATE);
    setModalState({
      ...MODAL_STATE,
      show: true,
      modalConfirm: true,
      handleConfirm: () => handleConfirm(setModalState),
      text: '¿Estás seguro que quieres anular los periodos seleccionados?',
      title: 'Seleccion de Periodo a Anular'
    });
  };

  return (
    <>
      {!onlyShow && (
        <Row className="border-line mr-1 ml-1">
          <Col className="top-header-light-gray justify-content-start border-radius-5">
            <p className="mt-0 text-left">
              <b>Etapa 1 - SELECCIONAR MES</b>
            </p>
          </Col>
          <Col sm={12} className="mt-2">
            <p>
              <b>Usuario {user?.employeeCard?.firstName}</b>
            </p>
            <p>
              En esta etapa del proceso, debe seleccionar el mes y año, del cual desea obtener la información de las
              cuotas a descontar, para posteriormente procesar en la nómina de sueldos.
            </p>
          </Col>
        </Row>
      )}
      <LoanProcessesDataTable
        handleSelectedRows={handleSelectedRows}
        selectedRows={selectedRows}
        handleCancelAssociation={validateOnCancelAssociation}
        allSelected={allSelected}
        onlyShow={onlyShow}
      />
      {!onlyShow && (
        <Row className="mb-2 row">
          <Col />
          <Col md={2}>
            <Button
              variant="primary"
              disabled={selectedRows.length === 0 || payrollProcess.status !== 'open'}
              block
              onClick={() => validateSelectedRows()}
            >
              Continuar
            </Button>
          </Col>
        </Row>
      )}

      <DefaultModal
        title={modalState.title}
        withConfirm={modalState.modalConfirm}
        body={<ModalWarningBody errorState={errorState} text={modalState.text} />}
        show={modalState.show}
        disabled={modalState.disabled}
        handleConfirm={modalState.handleConfirm}
        handleClose={() => setModalState(oldState => ({ ...oldState, show: false }))}
        titleBtnClose="Cancelar"
      />
    </>
  );
};

export default FirstStep;
