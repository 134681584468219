import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import collaborators from '../images/Colaboradores.png';
import DownloadButton from '../images/DownloadButton.png';
import { exportEmployeesRequest } from '../../../requests/employees';
import { downloadFile } from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import { DefaultModal } from '../../Utils/Modal';

function Downloadable({ title = '', subtitle = '', TopHeaderColor = '', onRequest = false }) {
  const dispatch = useDispatch();
  const setSimpleModalShow = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [modalTitle, setModalTitle] = useState('');
  const modalItem = useState({});

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    if (!response) {
      dispatch(sendAlert({ kind: 'error', message: error.message }));
    } else {
      let errorMessage = response.statusText;
      if (response.data && response.data.message) {
        errorMessage = response.data.message;
      }
      dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    }
    setDisabled(false);
    setSimpleModalShow(false);
    setModalShow(false);
  };

  const handleExportRequest = () => {
    setDisabled(true);
    exportEmployeesRequest({
      dispatch,
      params: {
        display_start: 0,
        display_length: 15,
        sort_direction: 'asc',
        sort_column: 'name',
        active: true
      },
      successCallback: response => {
        downloadFile(response);
        setDisabled(false);
        setModalShow(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleClick = () => {
    setModalTitle('Exportar empleados');
    setModalShow(true);
    setModalBody('¿Estás seguro que deseas exportar empleados?');
    setModalAction(() => handleExportRequest);
  };

  return (
    <>
      {!onRequest && (
        <>
          <div style={{ height: '145px' }}>
            <Row className="pt-3" style={{ alignItems: 'center', paddingRight: 20, paddingLeft: 20 }}>
              <Col xs={4} md={4} xl={4} className="pt-2">
                <img src={collaborators} alt="collaborators.png" style={{ height: 65, width: 65, marginLeft: 10 }} />
              </Col>
              <Col xs={8} md={8} xl={8}>
                <Row className="pl-3 pt-2">
                  <p style={{ fontSize: '25px', fontWeight: 'bold', color: 'gray', marginRight: 10 }}>{title}</p>
                  <p style={{ fontSize: '18px', fontWeight: 'bold', color: 'black', alignSelf: 'center' }}>
                    {subtitle}
                  </p>
                </Row>
              </Col>
            </Row>

            <div className="DownloadButton">
              <button type="button" onClick={handleClick} style={{ backgroundColor: TopHeaderColor }}>
                <img src={DownloadButton} alt="DownloadButtonImage.png" />
              </button>
            </div>
          </div>
          <DefaultModal
            title={modalTitle}
            body={modalBody}
            show={modalShow}
            handleClose={handleModalClose}
            handleConfirm={() => modalAction(modalItem)}
            titleBtnClose="Cancelar"
            titleBtnSave="Confirmar"
            disabled={onRequest || disabled}
          />
        </>
      )}
    </>
  );
}

export default Downloadable;
