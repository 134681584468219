import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn, Icon } from '../../../components';

export default function Header({ reportName, processName, ...props }) {
  const { employeNames, costCenterName, branchOfficeName, jobManagementName } = props;
  return (
    <>
      <div className="info-box mt-5">
        <Row className="mt-4 mb-4 align-items-center">
          <Col xs={1}>
            <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/process_summary?tab=process-reports">
              <Icon icon="chevron-back" />
            </LinkBtn>
          </Col>
          <h3 className="text-uppercase mt-4">Reportes</h3>
        </Row>
        <Row>
          <Col md={4}>
            <p className="info mb-0">
              <span>Nombre del Reporte: </span>
            </p>
            <p className="info">{reportName}</p>
          </Col>
          <Col md={4}>
            <p className="info mb-0">
              <span>Mes de Proceso:</span>
            </p>
            <p className="info">{processName}</p>
          </Col>
          <Col md={4}>
            <p className="info mb-0">
              <span>Trabajadores:</span>
            </p>
            <p className="info">{employeNames || 'Todos'}</p>
          </Col>
        </Row>
        <hr className="mb-2 mt-2" />
      </div>
      <div className="info-box">
        <Row>
          <Col md={4}>
            <p className="info mb-0">
              <span>Centro de Costo:</span>
            </p>
            <p className="info">{costCenterName || 'Todos'}</p>
          </Col>
          <Col md={4}>
            <p className="info mb-0">
              <span>Lugar de prestacion de servicios:</span>
            </p>
            <p className="info">{branchOfficeName || 'Todos'}</p>
          </Col>
          <Col md={4}>
            <p className="info mb-0">
              <span>Area:</span>
            </p>
            <p className="info">{jobManagementName || 'Todos'}</p>
          </Col>
        </Row>
        <hr className="mb-2 mt-2" />
      </div>
    </>
  );
}
