import React from 'react';
import Icon from '../Icons';
import { BasicDropdown } from '..';

const ReportActions = ({ handleClick, disabled }) => {
  const actions = () => {
    const authorizedActions = [];
    authorizedActions.push({
      icon: <Icon icon="file-earmark-excel" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'download_excel',
      props: {
        onClick: () => handleClick('download_excel'),
        disabled
      },
      title: 'Descargar Excel'
    });
    return authorizedActions;
  };

  return (
    <div className="d-flex mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Descargar <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
};

export default ReportActions;
