import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Button, Row, Col, Spinner, Card, Table } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { showDocumentPreviewRequest } from '../../requests/documents';
import {
  approveWorkflowRequestRequest,
  signWorkflowRequestRequest,
  rejectWorkflowRequestRequest
} from '../../requests/workflowRequests';
import { sendAlert } from '../../actions/utils';
import { parseUrlParams, handleWorkflowStatus } from '../../services/utils';
import WorkflowRequestNew from '../WorkflowRequest/WorkflowRequestNew';
import WorkflowRequestReject from '../WorkflowRequest/WorkflowRequestReject';
import SignatureKeyForm from './SignatureKeyForm';

import SimpleCenteredModal from '../../components/Utils/Modal/SimpleCenteredModal';
import DefaultModal from '../../components/Utils/Modal/DefaultModal';
import WorkflowRequestActionBtns from '../../components/ActionBtns/WorkflowRequestActionBtns';
import { getMeRequest } from '../../requests/auth';
import { sendSignatureKeyUserRequest } from '../../requests/user';
import ChangePin from '../Auth/ChangePin';

class DocumentPreviewToken extends Component {
  state = {
    document: {
      documentType: '',
      name: '',
      file: {
        fileUrl: '',
        filename: ''
      },
      workflowRequest: {
        status: '',
        employeeRequestReviewer: {}
      },
      pendingToApprove: ''
    },
    file: {
      fileUrl: '',
      filename: ''
    },
    modalShow: false,
    defaultModalShow: false,
    modalSize: '',
    modalBody: '',
    modalTitle: '',
    onRequest: true,
    signatureKey: '',
    titleBtnSave: '',
    approveDocument: ''
  };

  componentDidMount() {
    this.getDocument();
  }

  getDocument = async () => {
    const {
      dispatch,
      location: { search },
      match: { params },
      employeeId,
      documentId
    } = this.props;

    const documentIdRequest = typeof documentId === 'undefined' ? params.id : documentId;
    const employeeIdRequest = typeof employeeId === 'undefined' ? parseUrlParams(search) : { employee: employeeId };

    this.setState({ onRequest: true });
    showDocumentPreviewRequest(documentIdRequest, {
      employeeId: employeeIdRequest.employee,
      dispatch,
      successCallback: this.handleSuccessIndex
    });
  };

  handleSuccessIndex = response => {
    const { data } = response;
    const document = camelCaseRecursive(data);
    const file = document.fileInfo;

    this.setState({
      document,
      file,
      onRequest: false
    });
  };

  handleSuccessAction = message => {
    const { dispatch } = this.props;
    dispatch(sendAlert({ kind: 'success', message }));
    this.getDocument();
    this.handleModalClose();
  };

  handleErrorAction = message => {
    const { dispatch } = this.props;
    dispatch(sendAlert({ kind: 'error', message }));
    this.handleModalClose();
  };

  approveDocument = () => {
    const { dispatch } = this.props;
    const {
      document: { workflowRequest }
    } = this.state;

    this.setState({ onRequest: true, defaultModalShow: false });

    approveWorkflowRequestRequest(workflowRequest.id, {
      dispatch,
      successCallback: () => this.handleSuccessAction('Documento aprobado con éxito')
    });
  };

  sendSignatureKey = () => {
    const { signatureKey } = this.state;

    this.setState({
      modalTitle: 'FIRMAR DOCUMENTO',
      modalShow: true,
      modalSize: 'lg',
      modalBody: (
        <SignatureKeyForm formRequest={this.signDocument} onHide={this.handleModalClose} signatureKey={signatureKey} />
      )
    });
  };

  pendingContractAnnex = () => {
    const {
      document: { pendingAnnexes, fileInfo }
    } = this.state;

    this.setState({
      modalTitle: 'Validación de firma',
      modalShow: true,
      modalSize: 'md',
      modalBody: (
        <>
          <p>
            El documento {fileInfo.filename} no se puede firmar/aprobar debido a que existen otros anexos pendientes de
            firma/aprobación que a continuación se detallan:
          </p>
          <br />
          <ul>
            {pendingAnnexes.map(annex => (
              <li key={annex.id}>
                <strong>{annex.fileName}</strong> <br />
                <strong>Fecha de Inicio del anexo:</strong> {annex.startDate}
                <br />
                <br />
              </li>
            ))}
          </ul>
          <p> Debes firmar el primero de la lista.</p>
        </>
      )
    });
  };

  handleWorkflowRequest = () => {
    const { document } = this.state;

    this.setState({
      modalTitle: `Enviar a firmar ${document.fileInfo.cleanFilename}`,
      modalShow: true,
      modalSize: 'lg',
      modalBody: (
        <WorkflowRequestNew
          document={document}
          workflowType={document.documentType}
          handleModalClose={this.handleModalClose}
          handleSuccessWorkflowRequest={this.handleSuccessAction}
        />
      )
    });
  };

  showPendingAnnexModal = onContinueClick => {
    const {
      document: { employee }
    } = this.state;

    this.setState({
      modalTitle: 'Anexos de contratos',
      modalShow: true,
      modalSize: 'md',
      modalBody: (
        <>
          <p>
            Existen Anexos de Contratos vinculados al trabajador(a) {employee.fullName} que se encuentran pendientes de
            Firma en el Flujo de Aprobación que aún no ha finalizado
          </p>
          <br />
          <p> ¿Estás seguro que deseas continuar? </p>
          <div className="modal-footer">
            <Button onClick={onContinueClick} className="btn btn btn-primary">
              Continuar
            </Button>
          </div>
        </>
      )
    });
  };

  sendPendingContractAnnex = () => {
    this.showPendingAnnexModal(this.handleWorkflowRequest);
  };

  approvePendingContractAnnex = () => {
    this.showPendingAnnexModal(this.approveDocumentModal);
  };

  signPendingContractAnnex = () => {
    this.showPendingAnnexModal(this.dispatchGetMe);
  };

  handleSignatureKey = params => {
    const { dispatch } = this.props;
    sendSignatureKeyUserRequest({
      dispatch,
      params
    });
  };

  setModalShow = params => {
    this.setState({
      modalShow: params
    });
  };

  showChangePin = () => {
    this.setState({
      defaultModalShow: false,
      modalTitle: 'FIRMAR DOCUMENTO',
      modalShow: true,
      modalBody: <ChangePin setModalShow={this.setModalShow} handleSignatureKey={this.handleSignatureKey} />
    });
  };

  showMessageWithoutCode = () => {
    this.setState({
      modalTitle: 'FIRMAR DOCUMENTO',
      defaultModalShow: true,
      modalSize: 'md',
      modalBody: 'Usted no posee el PIN de firma, ¿Desea crearlo?',
      titleBtnSave: 'Crear PIN',
      approveDocument: false
    });
  };

  signDocument = signatureKey => {
    const { dispatch } = this.props;
    const {
      document: { workflowRequest }
    } = this.state;

    signWorkflowRequestRequest(workflowRequest.id, {
      dispatch,
      params: snakeCaseKeys(signatureKey),
      successCallback: () => this.handleSuccessAction('Documento firmado con éxito'),
      failureCallback: err => this.handleErrorAction(err.response?.data?.message)
    });
  };

  approveDocumentModal = () => {
    this.setState({
      modalShow: false,
      modalTitle: 'Aprobar documento',
      defaultModalShow: true,
      modalBody: '¿Estás seguro de aprobar este documento?',
      titleBtnSave: 'Aprobar',
      approveDocument: true
    });
  };

  dispatchGetMe = () => {
    const { dispatch } = this.props;
    getMeRequest({
      dispatch,
      successCallback: response => {
        const hasPasswordSignature = response.data.user_info.has_password_signature;
        if (hasPasswordSignature === true) this.sendSignatureKey();
        else this.showMessageWithoutCode();
      }
    });
  };

  rejectDocument = values => {
    const { dispatch } = this.props;
    const {
      document: { workflowRequest }
    } = this.state;

    this.setState({ onRequest: true });
    this.handleModalClose();

    rejectWorkflowRequestRequest(workflowRequest.id, {
      params: snakeCaseKeys(values),
      dispatch,
      successCallback: () => this.handleSuccessAction('Documento rechazado con éxito')
    });
  };

  handleSuccessWorkflowRequest = () => {
    this.getDocument();
    this.handleModalClose();
  };

  handleActions = action => {
    const { document } = this.state;
    const { currentTab } = this.props;
    const isDocumentMatching =
      document.documentType === 'contract_annex' &&
      document.pendingAnnexes?.[0]?.documentId !== undefined &&
      document.pendingAnnexes?.[0]?.documentId !== document.id;

    switch (action) {
      case 'sign_request':
        if (isDocumentMatching) {
          this.sendPendingContractAnnex();
        } else {
          this.handleWorkflowRequest();
        }
        break;
      case 'approve':
        if (isDocumentMatching && currentTab !== 'team') {
          this.pendingContractAnnex();
        } else if (isDocumentMatching && currentTab === 'team') {
          this.approvePendingContractAnnex();
        } else {
          this.approveDocumentModal();
        }
        break;
      case 'sign':
        if (isDocumentMatching && currentTab !== 'team') {
          this.pendingContractAnnex();
        } else if (isDocumentMatching && currentTab === 'team') {
          this.signPendingContractAnnex();
        } else {
          this.dispatchGetMe();
        }
        break;
      case 'reject':
        this.setState({
          modalTitle: `Rechazar ${document.fileInfo.cleanFilename}`,
          modalShow: true,
          modalSize: 'lg',
          modalBody: (
            <WorkflowRequestReject
              workflowRequest={document.workflowRequest}
              cancelAction={this.handleModalClose}
              formRequest={this.rejectDocument}
            />
          )
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  handleModalClose = () => {
    this.setState({ modalShow: false });
  };

  handleOnRequest = value => {
    this.setState({ onRequest: value });
  };

  handleDefaultModalClose = () => {
    this.setState({ defaultModalShow: false });
  };

  render() {
    const {
      onRequest,
      document,
      file,
      modalBody,
      modalShow,
      modalTitle,
      modalSize,
      defaultModalShow,
      titleBtnSave,
      approveDocument
    } = this.state;
    const { pendingToApprove, workflowRequest } = document;
    const { employeeId, documentId } = this.props;
    const evidencesArray = document?.participatingEmployees?.map(p => {
      return p?.employeeReviewerIds?.map(e => {
        return { ...e, status: p.translatedStatus };
      });
    });

    return (
      <>
        {onRequest && (
          <div className="containerSpinnerLoad" style={{ position: 'absolute', height: '100%' }}>
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        <Row
          className={`mb-4 ${typeof employeeId === 'undefined' && typeof documentId === 'undefined' ? 'mt-100' : ''}`}
        >
          <Col md={7} lg={8}>
            <h2 className="text-uppercase word-break">{file?.cleanFilename}</h2>
            {handleWorkflowStatus(workflowRequest?.status)}
          </Col>
          <WorkflowRequestActionBtns
            document={document}
            file={file}
            handleActions={this.handleActions}
            workflowRequest={workflowRequest}
          />
        </Row>
        <Row className="mb-4 mt-4">
          <Col>
            <div className="info-box mb-4">
              <p className="info">
                <span>Nombre completo:</span> {document?.employee?.fullName}
              </p>
              <p className="info">
                <span>Identificación:</span> {document?.employee?.nationalIdentification}
              </p>
              <p className="info">
                <span>Fecha de Creación:</span> {file?.createdAt}
              </p>
              {pendingToApprove && (
                <p className="info">
                  <span>Pendiente por:</span> {pendingToApprove}
                </p>
              )}
            </div>
          </Col>
          {workflowRequest?.observation && (
            <Col md={6}>
              <div className="info-box mb-4">
                <p className="info">
                  <span>Observaciones:</span>
                  <br />
                  {workflowRequest?.observation}
                </p>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={8}>
            <iframe src={file?.fileUrl} style={{ width: '100%', height: '800px' }} title={`${file?.filename}`}>
              <p>
                Este navegador no soporta PDFs. Por favor descarga el PDF para verlo:
                <Button variant="secondary" block href={file?.fileUrl} download>
                  Descargar
                </Button>
              </p>
            </iframe>
          </Col>
          <Col md={4}>
            <Card>
              <Card.Body className="table-responsive">
                <h3 className="mb-4">Evidencias</h3>
                {evidencesArray?.map(element => {
                  return (
                    <div key={element.id}>
                      <Table className="table table-sm" style={{ borderCollapse: 'inherit' }}>
                        <tbody>
                          <tr>
                            <td>{element[0].status} por:</td>
                            <td>{element[0].label}</td>
                          </tr>
                          <tr>
                            <td>{element[0].identificationType}:</td>
                            <td>{element[0].nationalIdentification}</td>
                          </tr>
                          <tr>
                            <td>Fecha/Hora:</td>
                            <td>{element[0].signatureDate}</td>
                          </tr>
                          <tr>
                            <td>Dirección IP:</td>
                            <td>{element[0].signatureIp}</td>
                          </tr>
                          <tr>
                            <td>Validador firma:</td>
                            <td>{element[0].jti}</td>
                          </tr>
                          {element[0].signatureKey && (
                            <tr>
                              <td>Firma:</td>
                              <td>{element[0].signatureKey}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <br />
                    </div>
                  );
                })}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <DefaultModal
          title={modalTitle}
          body={modalBody}
          show={defaultModalShow}
          handleClose={this.handleDefaultModalClose}
          handleConfirm={approveDocument ? this.approveDocument : this.showChangePin}
          titleBtnClose="Cancelar"
          titleBtnSave={titleBtnSave}
        />
        <SimpleCenteredModal
          size={modalSize}
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          onHide={this.handleModalClose}
        />
      </>
    );
  }
}
export default withRouter(connect()(DocumentPreviewToken));
