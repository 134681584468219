import React from 'react';
import DualListBox from 'react-dual-listbox';
import PropTypes from 'prop-types';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { Form } from 'react-bootstrap';
import Icon from '../../Icons/Icon';
import './style.scss';

const DualList = ({
  options,
  handleChange,
  selected,
  filterPlaceholder,
  es,
  isCustomReport,
  error,
  touched,
  ...props
}) => {
  return (
    <>
      <DualListBox
        className={`${error && touched ? 'react-dual-listbox-invalid' : ''} ${
          isCustomReport ? 'custom-dual-listbox' : ''
        }`}
        options={options}
        selected={selected}
        filterPlaceholder={filterPlaceholder}
        canFilter
        lang={es}
        showHeaderLabels
        icons={{
          moveLeft: [
            <Icon key={1} width={15} className="text-white" icon="chevron-back" />,
            <span key={0} className="d-none d-md-inline">
              Quitar
            </span>
          ],
          moveAllLeft: [
            <Icon key={2} width={15} className="text-white" icon="chevron-back" />,
            <Icon key={1} width={15} className="text-white" icon="chevron-back" />,
            <span key={0} className="d-none d-md-inline">
              Todos
            </span>
          ],
          moveRight: [
            <span key={0} className="d-none d-md-inline">
              Agregar
            </span>,
            <Icon key={1} width={15} className="text-white" icon="chevron-forward" />
          ],
          moveAllRight: [
            <span key={0} className="d-none d-md-inline">
              Todos
            </span>,
            <Icon key={1} width={15} className="text-white" icon="chevron-forward" />,
            <Icon key={2} width={15} className="text-white" icon="chevron-forward" />
          ],
          moveUp: <Icon key={1} width={15} icon="chevron-up1" />,
          moveDown: <Icon key={1} width={15} icon="chevron-down" />,
          moveTop: [<Icon key={1} width={15} icon="chevron-up1" />, <Icon key={2} width={15} icon="chevron-up1" />],
          moveBottom: [<Icon key={1} width={15} icon="chevron-down" />, <Icon key={2} width={15} icon="chevron-down" />]
        }}
        {...props}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    </>
  );
};

DualList.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.arrayOf(PropTypes.number),
  filterPlaceholder: PropTypes.string,
  es: PropTypes.oneOfType([PropTypes.object])
};

DualList.defaultProps = {
  handleChange: () => null,
  options: [],
  selected: [],
  filterPlaceholder: 'Buscar permisos...',
  es: {
    availableFilterHeader: 'Filtro Disponible',
    availableHeader: 'PERMISOS DISPONIBLES',
    moveLeft: 'Mover a la izquierda',
    moveAllLeft: 'Mover todos a la izquierda',
    moveRight: 'Mover a la derecha',
    moveAllRight: 'Mover todos a la derecha',
    moveBottom: 'Mover ítem al final',
    moveDown: 'Mover ítem hacia abajo',
    moveTop: 'Mover ítem al principio',
    moveUp: 'Mover ítem hacia arriba',
    noAvailableOptions: 'No hay opciones disponibles',
    noSelectedOptions: 'No hay opciones seleccionadas',
    selectedFilterHeader: 'Filtro seleccionado',
    selectedHeader: 'PERMISOS SELECCIONADOS',
    search: 'Buscar'
  }
};

export default DualList;
