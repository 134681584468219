import React from 'react';

const ModalWarningBody = ({ errorState, text }) => {
  return (
    <>
      {errorState.CANCEL_ERROR_PROCESS && (
        <>
          Has seleccionado procesos de préstamo que no tienen un proceso de nomina asociado o no se pueden anular en
          este mes, por lo que no se tomaran en cuenta en la anulacion
          <ul>
            {errorState.PROCESSES.map(row => (
              <li key={row.id}>{row.parsed_date}</li>
            ))}
          </ul>
        </>
      )}
      {errorState.CONTINUE_ERROR && (
        <>
          Has seleccionado procesos de préstamo no validos para continuar con el proceso de nomina
          <ul>
            {errorState.PROCESSES.map(row => (
              <li key={row.id}>{row.parsed_date}</li>
            ))}
          </ul>
        </>
      )}
      {text}
    </>
  );
};

export default ModalWarningBody;
