import React from 'react';
import GoogleMapReact from 'google-map-react';
import { useSelector } from 'react-redux';
import CustomMarker from './CustomMarker';

const MapAttendance = ({ brachOfficesCoordinates = [{ lat: 0, lng: 0 }], myLocation = { lat: 0, lng: 0 } }) => {
  const { advanceSettings } = useSelector(state => state.utils);
  const attendanceRadius = advanceSettings?.filter(asItem => asItem.code === 'mobile_assistance_distance')[0]
    ?.integerValue;

  const allBranchOfficesCoordinates = brachOfficesCoordinates?.map(m => {
    return { lat: parseFloat(m?.lat ?? 0), lng: parseFloat(m?.lng ?? 0), title: m?.label ?? '' };
  });
  const calculateAverage = () => {
    const allCoordinates = [...allBranchOfficesCoordinates, myLocation];
    const validCoordinates = allCoordinates.filter(coord => coord.lat && coord.lng);
    if (validCoordinates.length === 0) {
      return { lat: 0, lng: 0 };
    }
    const sumLatitudes = validCoordinates.reduce((sum, coord) => sum + coord.lat, 0);
    const sumLongitudes = validCoordinates.reduce((sum, coord) => sum + coord.lng, 0);
    const middlePointLatitud = sumLatitudes / validCoordinates?.length;
    const middlePointLongitud = sumLongitudes / validCoordinates?.length;
    return {
      lat: parseFloat(middlePointLatitud ?? 0),
      lng: parseFloat(middlePointLongitud ?? 0)
    };
  };
  const defaultProps = {
    center: {
      lat: calculateAverage().lat,
      lng: calculateAverage().lng
    },
    zoom: 10
  };
  return (
    <>
      <div style={{ height: '30vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyCwndRpOtXKHlX079dUfDE0fO7e4vWWgeE',
            language: 'es',
            region: 'cl'
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            allBranchOfficesCoordinates.forEach(e => {
              // eslint-disable-next-line no-new
              new maps.Circle({
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.3,
                map,
                center: { lat: e.lat, lng: e.lng },
                radius: attendanceRadius
              });
            });
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          center={calculateAverage()}
          zoom={10}
        >
          {allBranchOfficesCoordinates?.map((m, i) => (
            <CustomMarker key={`${i + 2}-customMarket`} title={m.title} lat={m.lat} lng={m.lng} isBranchOffice />
          ))}
          {myLocation && <CustomMarker title="Mi Ubicación" lat={myLocation?.lat} lng={myLocation?.lng} />}
        </GoogleMapReact>
      </div>
    </>
  );
};
export default MapAttendance;
