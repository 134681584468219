import React from 'react';

function AgeDistributionCakeGraphic({ label = '', color = '', gender = '', image = '' }) {
  const styling = {
    stroke: color,
    strokeDasharray: `${101} 100`
  };

  return (
    <div className="tense">
      <div className="mb0 mt0 circular-progress col-md-4 col-sm-12 col-12">
        <div className="Percentage">
          <svg width="80" height="80">
            <circle r="30%" cx="50%" cy="50%" pathLength="100" style={styling} />
          </svg>
          <span style={{ color: '#f8c444' }}>
            <img src={image} alt="MenImage.png" />
          </span>
        </div>
      </div>
      <p className="AgeDistributionText" style={{ color }}>
        {label}
      </p>
      <p className="AgeDistributionNumber">{gender}</p>
    </div>
  );
}

export default AgeDistributionCakeGraphic;
