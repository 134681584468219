import React, { useEffect } from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { Icon } from '../../../components';
import { sendAlert } from '../../../actions/utils';
import { createPermissionRequest } from '../../../requests/permissions';
import { createVacationRequest } from '../../../requests/vacations';
import PermissionForm from './PermissionForm';
import permissionTypes from './FormOptions';

const basicPermission = {
  endDate: '',
  endTime: '',
  middayEnd: false,
  middayStart: false,
  permissionType: '',
  reason: '',
  startDate: '',
  startTime: ''
};

const PermissionNew = ({ onSuccess, employeeId, rrhh = false }) => {
  const { advanceSettings } = useSelector(state => state.utils);
  const dispatch = useDispatch();

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud creada con éxito' }));
    onSuccess();
  };

  const handleCreateRequest = (permission, setSubmitting = () => null) => {
    if (permission.permission.permissionType === 'administrative_days') {
      const vacation = {
        vacation: { ...permission.permission, vacationType: 'administrative_days', isPermission: true }
      };
      const snakeCaseVacation = snakeCaseKeys(vacation);
      createVacationRequest({
        dispatch,
        params: {
          ...snakeCaseVacation
        },
        successCallback: handleSuccessCreate,
        callback: () => setSubmitting(false)
      });
    } else {
      const snakeCasePermission = snakeCaseKeys(permission);
      createPermissionRequest({
        dispatch,
        params: {
          ...snakeCasePermission
        },
        successCallback: handleSuccessCreate,
        callback: () => setSubmitting(false)
      });
    }
  };

  const filterPermissionTypes = from => {
    const permTypesFiltered = [...permissionTypes];

    const checkAdvancedSettings = (item, type) => {
      const asCheck = advanceSettings.filter(asItem => asItem.code === type)[0].textValue === 'false';
      if (asCheck) permTypesFiltered.splice(permTypesFiltered.indexOf(item), 1);
    };

    permissionTypes.forEach(item => {
      if (item.value === 'with_pay') checkAdvancedSettings(item, 'paid_leave');
      if (item.value === 'without_pay') checkAdvancedSettings(item, 'without_paid_leave');
      if (item.value === 'administrative_days') {
        if (from === 'hours') permTypesFiltered.splice(permTypesFiltered.indexOf(item), 1);
        else checkAdvancedSettings(item, 'administrative_days');
      }
    });

    return permTypesFiltered;
  };

  const disabledHours = filterPermissionTypes('hours').length === 0;

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Row>
      <Col>
        <Tab.Container id="profile-index.tabs" defaultActiveKey="days">
          <Nav variant="pills" className="mb-4">
            <Nav.Item>
              <Nav.Link eventKey="days">
                <Icon icon="briefcase" />
                Días
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="hours" disabled={disabledHours}>
                <Icon icon="clock2" />
                Horas
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="days">
              <PermissionForm
                action="new"
                formRequest={handleCreateRequest}
                employeeId={employeeId}
                from="days"
                permission={basicPermission}
                rrhh={rrhh}
                filterPermissionTypes={filterPermissionTypes}
              />
            </Tab.Pane>
            {!disabledHours && (
              <Tab.Pane eventKey="hours">
                <PermissionForm
                  action="new"
                  employeeId={employeeId}
                  formRequest={handleCreateRequest}
                  from="hours"
                  permission={basicPermission}
                  rrhh={rrhh}
                  filterPermissionTypes={filterPermissionTypes}
                />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Tab.Container>
      </Col>
    </Row>
  );
};

export default PermissionNew;
