export default function Referencing(region) {
  // esta funcion sirve para enlazar el pais del backend con el frondend. En otras palabras, de alguna forma hay que relacionar la region (en dibujo) con su nombre (en codigo)

  if (region !== undefined) {

    const splitRegion = region.split(" ");

    if (region.includes(' Arica')) {
      return splitRegion[2];
    }
    if (region.includes(' Metropolitana')) {
      return splitRegion[1];
    }
    if (region.includes(' Aysén')) {
      return splitRegion[1];
    }
    if (region.includes(' Magallanes')) {
      return splitRegion[2];
    }

    return splitRegion.pop();
  }
  return 'Sin definir';
}
