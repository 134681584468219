import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { FormikSelect } from '../../components';
import statuses from './formOptions';
import { delayMethod } from '../../services/utils';

const EmployeeCourseForm = props => {
  const { errors, touched, setFieldValue, setFieldTouched, handleClose, employeeCourse } = props;

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col>
            <Field name="employeeCourse[status]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Estado"
                  placeholder="Seleccionar Estado"
                  options={statuses}
                  defaultValue={employeeCourse.status || ''}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleClose)}>
          Cancelar
        </Button>
        <Button type="submit" variant="primary">
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { employeeCourse } = props;
  return { employeeCourse };
};

const validationSchema = Yup.object().shape({
  employeeCourse: Yup.object().shape({
    status: Yup.string().required('Debes escoger un estado')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(EmployeeCourseForm);
