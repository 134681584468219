import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { sendAlert } from '../../../actions/utils';
import { ComponentDataTable, DefaultModal, FormikSelect, SimpleCenteredModal } from '../../../components';
import { deleteShiftChangeRequest, debounceIndexShiftChangeRequest } from '../../../requests/shiftChange';
import ItemsInfo from './Items/ItemsInfo';
import { AbilityContext } from '../../../config/abilityContext';
import ItemsInfoStyled from './Items/ItemsInfoStyled';
import shiftChangeStyledColumns from '../../Profile/ShiftChange/StyledColumns';

const ShiftChangeDataTableStyled = ({
  currentEmployee,
  employeesBelongBoss,
  preName,
  rrhh,
  moreData,
  setMoreData,
  setWorkflowIds = () => null
}) => {
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [firstModalShow, setFirstModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalSize, setModalSize] = useState('');
  const [modalButtons, setModalButtons] = useState(true);
  const [onRequest, setOnRequest] = useState(true);
  const [workflowRequests, setWorkflowRequests] = useState([]);
  const [status, setStatus] = useState('0');
  const shiftStatus = [
    { label: 'Todos', value: '' },
    { label: 'Aprobado', value: '1' },
    { label: 'Pendiente', value: '0' },
    { label: 'Rechazado', value: '2' }
  ];
  const dispatch = useDispatch();

  const handleCustomParams = () => {
    if (status !== '0' && employeesBelongBoss.length > 0) {
      return {
        // sort_shifts: 'created_at desc',
        filter_employee: employeesBelongBoss
      };
    }

    return {
      // sort_shifts: 'created_at desc',
      review: currentEmployee
    };
  };

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setWorkflowRequests(data);
    setWorkflowIds(data.map(element => element.id));
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = params => {
    const customParams = handleCustomParams(status);
    setOnRequest(true);
    debounceIndexShiftChangeRequest({
      dispatch,
      params: {
        ...params,
        ...customParams,
        status
        // paginate: false
      },
      successCallback: handleSuccessIndex
    });
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
    setFirstModalShow(false);
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud de cambio de turno eliminada correctamente' }));
    setMoreData(!moreData);
  };

  const removeShiftChange = item => {
    setOnRequest(true);
    deleteShiftChangeRequest(item.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const handleManageRequest = item => {
    setModalBody(
      <ItemsInfoStyled item={item} setModalShow={setFirstModalShow} moreData={moreData} setMoreData={setMoreData} />
    );
    setModalButtons(false);
    setFirstModalShow(true);
    setModalSize('xl');
  };

  const handleActions = (item, action) => {
    setModalItem({});
    switch (action) {
      case 'actions':
        handleManageRequest(camelCaseRecursive(item));
        break;
      case 'destroy':
        setModalTitle('Eliminar Cambio de Turno');
        setFirstModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar esta solicitud de cambio de turno?');
        setModalAction(() => removeShiftChange);
        setModalItem(item);
        break;
      case 'show':
        setModalTitle('Cambio de Turno');
        setModalBody(<ItemsInfo item={item} rrhh={rrhh} />);
        setModalItem(item);
        setCenterModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'shift':
        return { sort_shifts: name };
      case 'shift_replacement':
        return { sort_shift_replacements: name };
      case 'employee':
        return { sort_employees: name };
      case 'employee_replacement':
        return { sort_employee_replacements: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        columns={shiftChangeStyledColumns(handleActions, ability, rrhh)}
        data={workflowRequests}
        handleSortCase={sortColumnCase}
        customHeaderCenter={
          <>
            <FormikSelect
              options={shiftStatus}
              label=""
              value={status}
              defaultValue={status}
              onChange={data => {
                setStatus(data ? data.value : '');
                setOnRequest(true);
                setMoreData(!moreData);
              }}
              placeholder="Todos"
            />
          </>
        }
        moreData={moreData}
        nameRangePicker="filter_dates"
        onRequest={onRequest}
        preName={preName || 'shiftChange'}
        rangePicker
        resourceRequest={handleRequest}
        totalRows={amount}
        withEndDate
        withStartDate
      />
      <SimpleCenteredModal
        size="md"
        title={modalTitle}
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={firstModalShow}
        modalSize={modalSize}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        variantBtnClose="outline-info"
        variantBtnSave="primary"
        withClose={modalButtons}
        withConfirm={modalButtons}
      />
    </>
  );
};

export default ShiftChangeDataTableStyled;
