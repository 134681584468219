import { useAbility } from '@casl/react';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';
import { ComponentDataTable, CustomGraph, DefaultModal, Icon, LinkBtn, SimpleCenteredModal } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { advancePaymentProcessCardsRequest } from '../../requests/advancePaymentProcess';
import {
  debounceIndexDataProcessRequest,
  deleteDataProcessRequest,
  exportDataProcessRequest,
  generateMassPaymentDataProcessRequest,
  generatePaymentDataProcessRequest
} from '../../requests/dataProcess';
import { showDocumentPreviewRequest } from '../../requests/documents';
import { camelCaseEmptyStringRecursive, downloadFile } from '../../services/utils';
import DocumentPreview from '../DocumentPreview/DocumentPreview';
import columns from './APShowColumns';
import AdvancePaymentProcessMassiveActions from '../../components/DatatableActions/AdvancePaymentProcessMassiveActions';

const graphColors = ['#FFC107', '#A4CF42', '#571263', '#0088FE', '#959595'];
const graphLegend = { layout: 'horizontal' };

const AdvancePaymentProcessShow = ({ match }) => {
  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(true);
  const [dataProcessesRequests, setDataProcessesRequest] = useState([]);
  const [advancePaymentProcess, setAdvancePaymentProcess] = useState();
  const ability = useAbility(AbilityContext);
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [modalItem, setModalItem] = useState({});
  const [documentModalBody, setDocumentModalBody] = useState('');
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [clearSelecion, setClearSelection] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    const newData = data.map(item => {
      if (item.source_type === 'Solicitudes') {
        return { ...item, disabledCheck: true };
      }

      return item;
    });
    setOnRequest(false);
    setTotalAmount(metadata.amount);
    setDataProcessesRequest(newData);
  };

  const fetchAdvancePaymentProcess = () => {
    const { id } = match.params;
    advancePaymentProcessCardsRequest(id, {
      dispatch,
      successCallback: response => setAdvancePaymentProcess(camelCaseEmptyStringRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  const handleRequest = params => {
    setOnRequest(true);
    debounceIndexDataProcessRequest({
      dispatch,
      params: { ...params, filter_process_id: match.params.id },
      successCallback: handleSuccessIndex,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Anticipo eliminado correctamente' }));
    handleRequest();
    fetchAdvancePaymentProcess();
  };

  const removeDataProcess = item => {
    setOnRequest(true);
    deleteDataProcessRequest(item.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const handleDownload = item => {
    showDocumentPreviewRequest(item.document, {
      employeeId: item.employee_id,
      dispatch,
      successCallback: response => {
        const { data } = response;
        if (data) {
          setDocumentModalBody(<DocumentPreview documentId={data.id} employeeId={data.employee_id} />);
          setDocumentModalShow(true);
        } else {
          dispatch(sendAlert({ kind: 'error', message: 'No se ha generado el documento para este registro' }));
        }
      },
      callback: () => {
        setOnRequest(false);
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'error', message: 'Documento no encontrado' }));
      },
      format: ''
    });
  };

  const generatePaymentDocument = item => {
    setOnRequest(true);
    generatePaymentDataProcessRequest(item.data_process_id, {
      dispatch,
      successCallback: response => {
        const { data } = response;
        dispatch(sendAlert({ kind: 'success', message: 'Documento generado con éxito' }));
        handleDownload(data);
      },
      callback: () => {
        setOnRequest(false);
      }
    });
  };

  const handleMassPaymentDocument = () => {
    setOnRequest(true);
    generateMassPaymentDataProcessRequest({
      dispatch,
      params: {
        ids: selectedRows.map(item => item.data_process_id)
      },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Documentos generados con éxito' }));
        setMoreData(!moreData);
        setClearSelectedRows(!clearSelectedRows);
      },
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const handleMassDownloadDataProcesses = () => {
    const { id } = match.params;
    const dataProcessIds = selectedRows.map(item => item.data_process_id);
    setOnRequest(true);
    exportDataProcessRequest({
      dispatch,
      params: {
        filter_data_process_ids: dataProcessIds,
        filter_process_id: id
      },
      successCallback: response => {
        setModalShow(false);
        downloadFile(response);
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      },
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'payment_document':
        generatePaymentDocument(item);
        break;
      case 'destroy':
        setModalTitle('Eliminar Anticipo');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar este anticipo?');
        setModalAction(() => removeDataProcess);
        setModalItem(item);
        break;
      case 'show':
        handleDownload(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'generate_documents':
        setModalTitle('Generar Documentos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas generar los documentos para los trabajadores seleccionados?');
        setModalAction(() => handleMassPaymentDocument);
        setClearSelection(true);
        break;
      case 'export':
        setModalTitle('Descargar');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas descargar los registros seleccionados?');
        setModalAction(() => handleMassDownloadDataProcesses);
        setClearSelection(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleDocumentModalClose = () => {
    setMoreData(!moreData);
    setDocumentModalShow(false);
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'national_identification':
        return { sort_rut: 'sort_rut' };
      case 'full_name':
        return { sort_employee_name: 'sort_employee_name' };
      case 'cost_center':
        return { sort_cost_center: 'sort_cost_center' };
      case 'job_management':
        return { sort_job_management: 'sort_job_management' };
      case 'balance_name':
        return { sort_balance_name: 'sort_balance_name' };
      case 'parsed_amount':
        return { sort_column: 'amount' }
      case 'created_at':
        return { sort_column: 'advance_payment_process_id' }
      default:
        return { sort_column: name };
    }
  };

  useEffect(fetchAdvancePaymentProcess, []);

  return (
    <>
      {advancePaymentProcess && (
        <>
          <Row className="m-top mb-2">
            <Col md={1}>
              <LinkBtn variant="circle-dark" className="btn-circle" block to="/advance_payments_process">
                <Icon icon="chevron-back" />
              </LinkBtn>
            </Col>
            <Col>
              <h2 className="m-top mb-3 mt-0">{`Proceso de Anticipos ${advancePaymentProcess.monthYearProccess}`}</h2>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col md={4} className="border-div">
              <div className="info-box">
                <p className="info mt-1 mb-2 mt-3">
                  <b>RESUMEN</b>
                </p>
                <p className="info mb-2">
                  <b>{`Proceso de Anticipos ${advancePaymentProcess.monthYearProccess}`}</b>
                </p>
                <p className="info mb-2">
                  <span>
                    <b>TOTAL TRABAJADORES PROCESADOS: </b>
                  </span>
                  <br />
                  {advancePaymentProcess.employeesCount}
                </p>
                <p className="info mb-2">
                  <span>
                    <b>MONTO TOTAL DE ANTICIPOS: </b>
                  </span>
                  <br />
                  {advancePaymentProcess.parsedTotalAmount}
                </p>
                <p className="info mb-4">
                  <span>
                    <b>FECHA DE ÚLTIMA ACTUALIZACIÓN: </b>
                  </span>
                  <br />
                  {advancePaymentProcess.updatedAt}
                </p>
              </div>
            </Col>
            <Col md={4} className="border-div">
              <CustomGraph
                title="DISTRIBUCIÓN FORMA DE PAGO"
                customizedLabel
                chartType="pie"
                data={[
                  { name: 'Efectivo', value: advancePaymentProcess.cash },
                  { name: 'Transferencia Bancaria', value: advancePaymentProcess.bankTransfer },
                  { name: 'Vale Vista', value: advancePaymentProcess.cashiersCheck },
                  { name: 'Cheque', value: advancePaymentProcess.paycheck },
                  { name: 'Sin Forma de Pago', value: advancePaymentProcess.emptyValue }
                ]}
                customColors={graphColors}
                pieLegend={graphLegend}
                activeAnimation={false}
                customHeight={230}
              />
            </Col>
            <Col md={4} className="border-div">
              <CustomGraph
                title="TOTALES POR TIPO DE PAGO"
                customizedLabel
                chartType="pie"
                data={[
                  { name: 'Efectivo', value: advancePaymentProcess.totalCash },
                  { name: 'Transferencia Bancaria', value: advancePaymentProcess.totalBankTransfer },
                  { name: 'Vale Vista', value: advancePaymentProcess.totalCashiersCheck },
                  { name: 'Cheque', value: advancePaymentProcess.totalPaycheck },
                  { name: 'Sin Forma de Pago', value: advancePaymentProcess.totalEmptyValue }
                ]}
                customColors={graphColors}
                pieLegend={graphLegend}
                activeAnimation={false}
                customHeight={230}
              />
            </Col>
          </Row>
        </>
      )}

      <ComponentDataTable
        resourceRequest={handleRequest}
        columns={columns(handleActions, ability)}
        data={dataProcessesRequests}
        onRequest={onRequest}
        withMassActions
        massActions={
          <AdvancePaymentProcessMassiveActions
            model="DataProcess"
            handleClick={handleMassAction}
            disabled={!selectedCount}
            statusProcess={advancePaymentProcess && advancePaymentProcess.status === 'close'}
          />
        }
        totalRows={totalAmount}
        clearSelectedRows={clearSelecion}
        selectableRows
        selectedRowCount={selectedCount}
        onSelectedRowsChange={handleSelectedRows}
        withSearch={false}
        handleSortCase={sortColumnCase}
      />

      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        variantBtnClose="outline-info"
        variantBtnSave="primary"
      />
      <SimpleCenteredModal
        body={documentModalBody}
        onHide={handleDocumentModalClose}
        show={documentModalShow}
        size="xl"
      />
    </>
  );
};

export default AdvancePaymentProcessShow;
