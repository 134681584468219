import { useDispatch } from 'react-redux';
import { indexJobTitleRequest } from '../../../requests/jobTitles';
import { debounceIndexShiftsRequest } from '../../../requests/shifts';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';
import { debounceIndexSelectEmployeesRequest, indexSelectEmployeesRequest } from '../../../requests/employees';

const useFiltersHookRequest = () => {
  const dispatch = useDispatch();

  const currentCompany = window.localStorage.getItem('currentCompany');
  const cacheKey = `FILTER_EMPLOYEES_OVERTIME_AGREEMENT_${currentCompany}`;
  const cachedData = window.sessionStorage.getItem(cacheKey);
  // window.sessionStorage.removeItem(cacheKey);// borrar item cache

  const fetchEmployees = (inputValue, callback, single, useDebounce = false) => {
    const requestFunction = useDebounce ? debounceIndexSelectEmployeesRequest : indexSelectEmployeesRequest;

    // Verificar si los resultados están en la caché
    if (cachedData) {
      const data = JSON.parse(cachedData);

      const filteredData = inputValue
        ? data.filter(item => item.label.toLowerCase().includes(inputValue.toLowerCase()))
        : data;

      // Verificar si se encontraron resultados
      if (filteredData.length > 0) {
        callback(single ? filteredData : [{ id: 'allTodos', label: 'Todos', value: 'Todos' }, ...filteredData]);
        return;
      }
    }

    const customParams = {
      active: true,
      active_contracts: true,
      sort_column: 'name',
      filter_name: inputValue,
      is_dt: false,
      display_length: 100
    };

    if (inputValue?.length > 0) {
      customParams.display_length = 50;
    }

    requestFunction({
      dispatch,
      params: customParams,
      successCallback: ({ data: { data: arrayEmployees } }) => {
        const multiple = useDebounce
          ? [{ id: 'allTodos', label: 'Todos', value: 'Todos' }, ...arrayEmployees]
          : arrayEmployees;

        const oldData = JSON.parse(cachedData);

        const newData = oldData ? [...oldData, ...arrayEmployees] : arrayEmployees;

        window.sessionStorage.setItem(cacheKey, JSON.stringify(newData));

        callback(single ? arrayEmployees : multiple);
      }
    });
  };

  const fetchBranchOffices = (inputValue, callback) => {
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: response => {
        callback([{ id: '', label: 'Todos', value: '' }, ...response.data.data]);
      }
    });
  };

  const fetchJobTitles = (inputValue, callback) => {
    indexJobTitleRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: response => {
        callback([{ id: '', label: 'Todos', value: '' }, ...response.data.data]);
      }
    });
  };

  const fetchShifts = (inputValue, callback) => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: response => {
        callback([{ id: '', label: 'Todos', value: '' }, ...response.data.data]);
      }
    });
  };

  const fetchSuggestions = (inputValue, callback) => {
    // Verificar si los resultados están en la caché
    if (cachedData) {
      const data = JSON.parse(cachedData);

      const filteredData = inputValue
        ? data.filter(item => item.national_identification.toLowerCase().includes(inputValue.toLowerCase()))
        : data;

      // Verificar si se encontraron resultados
      if (filteredData.length > 0) {
        callback(filteredData.map(employee => employee.national_identification));
        return;
      }
    }

    debounceIndexSelectEmployeesRequest({
      dispatch,
      params: {
        active: true,
        active_contracts: true,
        filter_suggestions_national_id: inputValue,
        is_dt: false,
        display_length: 100
      },
      successCallback: ({ data: { data: arrayEmployees } }) => {
        const oldData = JSON.parse(cachedData);

        const newData = oldData ? [...oldData, ...arrayEmployees] : arrayEmployees;

        window.sessionStorage.setItem(cacheKey, JSON.stringify(newData));

        if (typeof callback === 'function') {
          callback(arrayEmployees.map(employee => employee.national_identification));
        }
      },
      errorCallback: error => {
        if (typeof callback === 'function') {
          callback([]);
        }
        console.error(error);
      }
    });
  };

  return {
    fetchJobTitles,
    fetchShifts,
    fetchBranchOffices,
    fetchEmployeesMulti: (inputValue, callback) => fetchEmployees(inputValue, callback, false, !!inputValue),
    fetchEmployeesSingle: (inputValue, callback) => fetchEmployees(inputValue, callback, true, !!inputValue),
    fetchSuggestions
  };
};

export default useFiltersHookRequest;
