import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { useHistory, useLocation } from 'react-router-dom';

import snakecaseKeys from 'snakecase-keys';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { sendAlert } from '../../../actions/utils';
import DataLoadForm from '../../DataLoad/DataLoadForm';
import { createDataLoadRequest } from '../../../requests/dataLoads';
import { showMonthOfProcessActiveRequest } from '../../../requests/monthOfProcesses';
import dataLoad from '../../DataLoad/dataload';

const AttendanceMovementsNew = () => {
  const dispatch = useDispatch();
  const [moreData, setMoreData] = useState(false);
  const [listEmployees, setListEmployees] = useState([]);
  const [activeMonth, setActiveMonth] = useState({});
  const history = useHistory();
  const location = useLocation();
  const returnTo = new URLSearchParams(location.search).get('returnTo') || '';

  const handleSuccessAction = (message, resetForm, setFieldValue) => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    setFieldValue('dataLoad[employeeIds]', []);
    resetForm();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
  };

  const handleCreate = (values, setSubmitting, resetForm, setFieldValue) => {
    const params = snakecaseKeys(values);
    createDataLoadRequest({
      dispatch,
      params,
      successCallback: () => {
        handleSuccessAction('Cargas de datos creadas correctamente', resetForm, setFieldValue);
        history.push(`/remuneration_processes?tab=attendance-movement`);
      },
      failureCallback: handleFailureRequest,
      callback: () => setSubmitting(false)
    });
  };

  const handleShowMonthOfProcess = () => {
    showMonthOfProcessActiveRequest({
      dispatch,
      successCallback: response => setActiveMonth(response.data || '')
    });
  };

  useEffect(handleShowMonthOfProcess, []);

  return (
    <>
      <DataLoadForm
        dataLoad={dataLoad}
        action="new"
        formRequest={handleCreate}
        setListEmployees={setListEmployees}
        listEmployees={listEmployees}
        activeMonth={activeMonth}
        moreData={moreData}
        returnTo={returnTo}
      />
    </>
  );
};

export default AttendanceMovementsNew;
