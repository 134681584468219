import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { withFormik, Field, Form, getIn } from 'formik';
import FormikCheckBox from '../../components/Utils/Input/FormikCheckBox';
import { FormikSelect, FormikNumber } from '../../components';
import { updateAdvanceSettingAdvancePaymentsRequest } from '../../requests/advanceSettings';

const FormAdvancePayment = ({ errors, touched, setFieldValue, values, isSubmitting }) => {
  const [paymentAmount, setPaymentAmount] = useState(
    values?.advanceSetting[0]?.text_value === 'false' || values?.advanceSetting[0]?.text_value === 't'
  );
  const [paymentPercentage, setPaymentPercentage] = useState(
    values?.advanceSetting[2]?.text_value === 'false' || values?.advanceSetting[2]?.text_value === 't'
  );
  const advancePaymentPercentageLimit = Array.from(Array(101), (e, i) => ({ label: i, value: i }));
  const limitDayAdvancePayment = Array.from(Array(28), (e, i) => ({ label: i + 1, value: i + 1 }));
  const advancePaymentPercentage = values?.advanceSetting[2]?.integer_value;
  const dayAdvancePayment = values?.advanceSetting[3]?.integer_value;
  const limitMailingDay = Array.from(Array(28), (e, i) => ({ label: i + 1, value: i + 1 }));
  const mailingDay = values?.advanceSetting[1]?.integer_value;

  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col md={11}>
        <Form className="d-flex flex-column justify-content-end mt-2">
          <div>
            <p>Seleccione si el anticipo sera pagado por un monto fijo o por porcentaje</p>
            <Row>
              <Col md={6}>
                <Field name="advanceSetting[0]text_value">
                  {({ field }) => (
                    <>
                      <FormikCheckBox
                        {...field}
                        value={field.value === 'true' || field.value === true}
                        checked={field.value === 'true' || field.value === true}
                        field={field}
                        label="Monto"
                        custom
                        onClick={() => {
                          setFieldValue(field.name, field.value === 'true' || field.value === true);
                          setFieldValue('advanceSetting[2]text_value', field.value === 'true' || field.value === true);
                          setPaymentAmount(field.value === 'true' || field.value === true);
                          setPaymentPercentage(!(field.value === 'true' || field.value === true));
                        }}
                      />
                    </>
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="advanceSetting[2]text_value">
                  {({ field }) => (
                    <FormikCheckBox
                      {...field}
                      value={field.value === 'true' || field.value === true}
                      checked={field.value === 'true' || field.value === true}
                      field={field}
                      label="Porcentaje"
                      custom
                      onClick={() => {
                        setFieldValue(field.name, field.value === 'true' || field.value === true);
                        setFieldValue('advanceSetting[0]text_value', field.value === 'true' || field.value === true);
                        setPaymentAmount(!(field.value === 'true' || field.value === true));
                        setPaymentPercentage(field.value === 'true' || field.value === true);
                      }}
                    />
                  )}
                </Field>
              </Col>

              <Col md={6}>
                <Field name="advanceSetting[0]integer_value">
                  {({ field }) => (
                    <FormikNumber
                      {...field}
                      disabled={paymentAmount}
                      label="Monto máximo a solicitar $"
                      thousandSeparator=""
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6} className="px-2">
                <Field name="advanceSetting[2]integer_value">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      label="Monto máximo a solicitar %"
                      tooltipText="Corresponde al sueldo base del empleado"
                      isDisabled={paymentPercentage}
                      options={advancePaymentPercentageLimit}
                      defaultValue={advancePaymentPercentage}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="advanceSetting[1]integer_value">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      label="Dia apertura de solicitudes"
                      options={limitMailingDay}
                      defaultValue={mailingDay}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      tooltipClass="ml-2 mt-2"
                      tooltipText="Día de envío de correo para la apertura solicitudes"
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="advanceSetting[3]integer_value">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      label="Dia cierre de solicitudes"
                      options={limitDayAdvancePayment}
                      defaultValue={dayAdvancePayment}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      tooltipClass="ml-2 mt-2"
                      tooltipText="Día de envío de correo para el cierre de solicitudes"
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </div>
          <Row className="d-flex justify-content-end mt-3 mb-1">
            <Col md={6}>
              <Button type="submit" block variant="primary" disabled={isSubmitting}>
                GUARDAR
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { user, currentEmployee } = state.auth;
  return { user, currentEmployee };
};

const initialValues = ({ advanceSetting }) => {
  return { advanceSetting };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { dispatch, extraAction } = props;
  setSubmitting(true);
  updateAdvanceSettingAdvancePaymentsRequest({
    dispatch,
    params: snakeCaseKeys(values),
    successCallback: () => {
      setSubmitting(false);
    }
  });
  extraAction();
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: params => initialValues(params),
      handleSubmit
    })(FormAdvancePayment)
  )
);
