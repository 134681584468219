import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';
import { listedSettings } from './CardInfo';
import { BasicCard, BasicTooltip, Icon } from '../../components';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { indexSettlementSettingsRequest, updateSettlementSettingRequest } from '../../requests/settlementSettings';
import { sendAlert } from '../../actions/utils';
import StagesForm from './StagesForm';

const SettlementSettingsIndex = () => {
  const [settingData, setSettingData] = useState([]);
  const vListedSettings = listedSettings;
  const canUpdateSettings = true;
  const dispatch = useDispatch();
  const { currentCompany } = useSelector(state => state.auth);

  const changeSetting = (settIndex, settValue) => {
    const newSettData = [...settingData];
    newSettData[settIndex].textValue = String(!settValue);
    setSettingData(newSettData);
  };

  const setttlementSettingIndex = () => {
    indexSettlementSettingsRequest({
      dispatch,
      params: { filter_by_company: currentCompany.id },
      successCallback: response => {
        setSettingData(camelCaseEmptyStringRecursive(response.data.data));
      }
    });
  };

  const handleSuccessUpdate = message => {
    dispatch(sendAlert({ kind: 'success', message }));
  };

  const settingUpdate = settlementSetting => {
    updateSettlementSettingRequest(settlementSetting.id, {
      dispatch,
      params: snakecaseKeys({ settlementSetting }),
      formData: true,
      successCallback: () => {
        handleSuccessUpdate('Configuración modificada con éxito');
      }
    });
  };

  const handleCardToggleChange = (settIndex, checked) => {
    changeSetting(settIndex, checked);
    settingUpdate(settingData[settIndex]);
  };

  useEffect(setttlementSettingIndex, []);

  const cardBody = ({ text, name, tooltipText, inputType }) => {
    const isToggle = inputType === 'toggle';
    let settIndex = '';
    if (settingData.length) {
      settIndex = settingData.findIndex(value => value.code === name);
      if (settIndex < 0) settIndex = '';
    }
    const checked = settingData[settIndex]?.textValue === 'true';

    return (
      <>
        <Row>
          <Col sm={isToggle ? 9 : 8} md={12} lg={isToggle ? 6 : 5} xl={isToggle ? 8 : 7}>
            <p className="text-uppercase mt-1" style={{ letterSpacing: '1.5px' }}>
              {text}
            </p>
          </Col>
          <Col
            xs={6}
            sm={isToggle ? 2 : 3}
            md={6}
            lg={isToggle ? 4 : 5}
            xl={isToggle ? 3 : 4}
            className={`${isToggle ? 'd-flex justify-content-lg-end' : ''}`}
          >
            {isToggle && (
              <>
                <Form.Group className="mt-1">
                  <Form.Check
                    id={`checkbox-${settIndex}`}
                    disabled={!canUpdateSettings}
                    checked={checked}
                    className={canUpdateSettings ? 'as-checkbox' : 'as-checkbox-disabled'}
                    label=""
                    type="switch"
                    onChange={() => {
                      handleCardToggleChange(settIndex, checked);
                    }}
                  />
                </Form.Group>
              </>
            )}
          </Col>
          {tooltipText && (
            <Col className="d-flex justify-content-end">
              <Form.Label className="position-sm-absolute mt-2 mb-0">
                <BasicTooltip text={tooltipText}>
                  <Icon icon="help-circle" width={20} />
                </BasicTooltip>
              </Form.Label>
            </Col>
          )}
        </Row>
      </>
    );
  };

  return (
    <>
      <h4 className="text-uppercase mt-2 mb-3">Configuraciones Simulador de Finiquitos</h4>
      <Row>
        {vListedSettings.map(body => {
          return (
            <Col key={`asd-${body.name}`} md={6}>
              <BasicCard
                size="100%"
                classNameCard="z-index-auto"
                bodyMarginBottom="-10px"
                colorCard="white"
                divBody
                text={cardBody(body)}
              />
            </Col>
          );
        })}
      </Row>
      <StagesForm settingData={settingData} setSettingData={setSettingData} />
    </>
  );
};

export default SettlementSettingsIndex;
