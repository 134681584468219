import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormikInput } from '../../components';
import DocumentTokenDataTable from './DocumentTokenDataTable';

const DocumentTokenIndex = () => {
  const [token, setToken] = useState('');
  const [moreData, setMoreData] = useState(false);
  return (
    <>
      <Row className="m-top mb-4">
        <Col>
          <h2 className="m-top mt-1 mb-1">Busqueda de Documentos por Token</h2>
        </Col>
        <Col md={3}>
          <FormikInput
            label="Token"
            name="rut"
            placeholder="Ingresa un Token"
            onChange={data => {
              setToken(data ? data.target.value : '');
              setMoreData(!moreData);
            }}
            value={token}
          />
        </Col>
      </Row>
      <DocumentTokenDataTable
        moreData={moreData}
        customParams={{ filter_by_token: token }}
        setMoreData={setMoreData}
        employeeSearch
        rrhh
        allowedActions={['download', 'remove', 'resend', 'show', 'request-sign']}
      />
    </>
  );
};

export default DocumentTokenIndex;
