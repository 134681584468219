export const translateStatus = {
  incomplete: 'Incompleto',
  inconsistent: 'Inconsistente',
  pending: 'Pendiente',
  approved: 'Aprobado',
  rejected: 'Rechazado',
  correct: 'Correcto',
  disabled: 'Desactivado'
};

export const markingTypes = [
  { label: 'Entrada Jornada', value: 0 },
  { label: 'Salida Jornada', value: 1 },
  { label: 'Entrada Colación', value: 2 },
  { label: 'Salida Colación', value: 3 }
];

export const statuses = [
  { label: 'Incompleto', value: 'incomplete' },
  { label: 'Inconsistente', value: 'inconsistent' },
  { label: 'Pendiente', value: 'pending' },
  { label: 'Aprobado', value: 'approved' },
  { label: 'Rechazado', value: 'rejected' },
  { label: 'Correcto', value: 'correct' },
  { label: 'Desactivado', value: 'disabled' }
];
