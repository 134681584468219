import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ComponentDataTable } from '../../../../components';
import Columns from '../Columns/ColumnsLoanProcesses';
import massiveActions from './massiveActions';
import {
  cancelAsociationPayrollLoanRequest,
  exportLoanProcesses,
  exportPayrollLoanRequest,
  indexLoanProcessesRequest
} from '../../../../requests/payrollLoanProcesses';
import { PayrollProcessContext } from '../../context/PayrollProcessProvider';
import { downloadFile } from '../../../../services/utils';
import { sendAlert } from '../../../../actions/utils';

const LoanProcessesDataTable = ({
  handleSelectedRows,
  selectedRows,
  handleCancelAssociation,
  allSelected,
  onlyShow
}) => {
  const { payrollProcess } = useContext(PayrollProcessContext);
  const dispatch = useDispatch();
  const { currentCompany } = useSelector(state => state.auth);
  const [loanProcessesData, setLoanProcessesData] = useState([]);
  const [amount, setAmount] = useState(0);
  const [onRequest, setOnRequest] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);

  const handlePayrollLoanProcesses = params => {
    setOnRequest(true);
    indexLoanProcessesRequest({
      dispatch,
      params: {
        filter_company_id: currentCompany.id,
        filter_last_months: `${payrollProcess.month_number}-${payrollProcess.year}`,
        ...params
      },
      successCallback: ({ data }) => {
        const loanProcesses = data.data.map(loanProcess => ({
          ...loanProcess,
          disabledCheck: payrollProcess.status !== 'open'
        }));
        setLoanProcessesData(loanProcesses);
        setAmount(data.metadata.amount);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleExport = (payrollId, loanProcessId) => {
    setOnRequest(true);
    exportPayrollLoanRequest({
      dispatch,
      params: {
        payroll_process_id: payrollId,
        loan_process_id: loanProcessId
      },
      successCallback: response => {
        downloadFile(response);
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'error', message: 'No se pudo exportar documento' }));
      },
      callback: () => {
        setOnRequest(false);
      }
    });
  };

  const callCancelAssociation = setModalShow => {
    setModalShow(oldState => ({ ...oldState, disabled: true }));
    cancelAsociationPayrollLoanRequest({
      dispatch,
      params: {
        ids: selectedRows.map(row => ({ parsed_date: row.parsed_date, id: row.id })),
        payroll_process_id: payrollProcess.id
      },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Procesos anulados correctamente' }));
      },
      callback: () => {
        setModalShow(oldState => ({ ...oldState, show: false, body: <></>, title: '', showConfirm: false, text: '' }));
        setClearSelection(true);
        setMoreData(!moreData);
      }
    });
  };

  const handleClick = action => {
    switch (action) {
      case 'cancel_association': {
        setClearSelection(false);
        handleCancelAssociation(callCancelAssociation);
        break;
      }
      case 'export_processes': {
        setClearSelection(false);
        setOnRequest(true);
        const filters = selectedRows.lenght === 0 || allSelected ? {} : { filter_ids: selectedRows.map(row => row.id) };
        exportLoanProcesses({
          dispatch,
          params: {
            filter_company_id: currentCompany.id,
            filter_last_months: `${payrollProcess.month_number}-${payrollProcess.year}`,
            sort_year_month: true,
            ...filters
          },
          successCallback: response => {
            downloadFile(response);
          },
          failureCallback: () => {
            dispatch(sendAlert({ kind: 'error', message: 'No se pudo exportar documento' }));
          },
          callback: () => {
            setClearSelection(true);
            setOnRequest(false);
          }
        });
        break;
      }
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'month':
        return { sort_month: name };
      case 'parsed_date':
        return { sort_column: 'month' };
      default:
        return { sort_year_month: true };
    }
  };

  return (
    <ComponentDataTable
      onRequest={onRequest}
      columns={Columns(handleExport, payrollProcess.status !== 'open')}
      data={loanProcessesData}
      totalRows={amount}
      handleSortCase={sortColumnCase}
      moreData={moreData}
      resourceRequest={handlePayrollLoanProcesses}
      selectableRows={!onlyShow}
      onSelectedRowsChange={handleSelectedRows}
      withMassActions
      clearSelectedRows={clearSelection}
      withSearch={false}
      massActions={massiveActions(handleClick, selectedRows.length === 0 || payrollProcess.status !== 'open', onlyShow)}
    />
  );
};

export default LoanProcessesDataTable;
