import React, { useState } from 'react';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import ThirdStep from './Steps/ThirdStep';

const LoanProcessSelection = ({ handleNextStep, handleBackStep, step, handleFinishStep, payrollProcess, onlyShow }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const handleSelectedRows = rows => {
    setAllSelected(rows.allSelected);
    const rowsObject = rows.selectedRows.map(row => row);
    setSelectedRows(rowsObject);
  };

  switch (step) {
    case 0: {
      return (
        <FirstStep
          allSelected={allSelected}
          payrollProcess={payrollProcess}
          handleNextStep={handleNextStep}
          step={step}
          handleSelectedRows={handleSelectedRows}
          selectedRows={selectedRows}
          onlyShow={onlyShow}
        />
      );
    }
    case 1: {
      return (
        <SecondStep
          payrollProcess={payrollProcess}
          handleBackStep={handleBackStep}
          handleNextStep={handleNextStep}
          selectedRows={selectedRows}
        />
      );
    }
    case 2: {
      return <ThirdStep payrollProcess={payrollProcess} handleFinishStep={handleFinishStep} />;
    }
    default:
      return <FirstStep />;
  }
};

export default LoanProcessSelection;
