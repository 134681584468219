import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';

import {
  replaceAttendanceRequest,
  disableAttendanceRequest,
  massDisableAttendanceRequest,
  debounceIndexAttendanceRequest
} from '../../requests/attendances';
import { sendAlert } from '../../actions/utils';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal, ButtonTooltip, Icon } from '../index';
import AttendanceReplaceForm from './AttendanceReplaceForm';
import AttendanceInfo from './AttendanceInfo';
import { AbilityContext } from '../../config/abilityContext';
import { downloadFile } from '../../services/utils';

const AttendanceDataTable = ({
  customParams,
  moreData,
  setMoreData,
  columns,
  employeesBelongBoss,
  tab,
  defaultStartDate = null,
  defaultEndDate = null
}) => {
  const ability = useAbility(AbilityContext);
  const [onRequest, setOnRequest] = useState(true);
  const [attendances, setAttendances] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledAction, setDisableAction] = useState(true);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [simpleModalBody, setSimpleModalBody] = useState({});
  const [simpleModalTitle, setSimpleModalTitle] = useState('');
  const [size, setSize] = useState('lg');
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [amount, setAmount] = useState(0);
  const [query, setQuery] = useState({});
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setAttendances(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    const { date_from: dateTimeFrom, date_to: dateTimeTo, ...restParams } = params;

    const updatedParams = {
      ...restParams,
      dateTimeFrom,
      dateTimeTo
    };

    const snakeParams = snakeCaseKeys(updatedParams);

    setOnRequest(true);
    setQuery({ ...snakeParams, ...customParams });

    const queryParams = { ...snakeParams, ...customParams };
    if (employeesBelongBoss) {
      queryParams.filter_by_employee = employeesBelongBoss;
    }

    const request = async () =>
      debounceIndexAttendanceRequest({
        dispatch,
        params: queryParams,
        successCallback: handleSuccessIndex
      });
    request();
  };

  const handleModalClose = () => {
    setSimpleModalShow(false);
  };

  const handleSuccessRequest = status => {
    let message = '';

    switch (status) {
      case 'updated':
        message = 'Asistencia reemplazada correctamente';
        break;
      case 'deleted':
        message = 'Asistencia cambiada correctamente';
        break;
      case 'mass_disable':
        message = 'Asistencias cambiadas correctamente';
        break;
      default:
        message = 'No action';
        break;
    }

    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleShowModal = (title, body) => {
    setSimpleModalTitle(title);
    setSimpleModalShow(true);
    setSimpleModalBody(body);
    setSize('md');
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdateRequest = attendance => {
    replaceAttendanceRequest({
      dispatch,
      params: snakeCaseKeys(attendance),
      formData: true,
      successCallback: () => handleSuccessRequest('updated'),
      failureCallback: handleFailureRequest
    });
  };

  const handleUpdateModal = attendance => {
    const parsedAttendance = camelCaseRecursive(attendance);
    setSimpleModalBody(
      <AttendanceReplaceForm
        attendance={parsedAttendance}
        formRequest={handleUpdateRequest}
        handleModalClose={handleModalClose}
      />
    );
    setSimpleModalTitle('ASISTENCIA');
    setSimpleModalShow(true);
    setSize('lg');
  };

  const handleDefaultModalClose = () => {
    setModalShow(false);
  };

  const handleDeleteRequest = item => {
    disableAttendanceRequest({
      dispatch,
      params: snakeCaseKeys(item),
      successCallback: () => handleSuccessRequest('deleted'),
      failureCallback: error => handleFailureRequest(error)
    });

    handleDefaultModalClose();
  };

  const handleDeleteModal = item => {
    setModalShow(true);
    setModalTitle('Cambiar Registro');
    setModalItem(item);
    setModalAction(() => handleDeleteRequest);
    setModalBody('¿Estás seguro que deseas cambiar el estado de esta asistencia?');
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        handleShowModal(`Asistencia - ${item.employee_full_name}`, <AttendanceInfo attendance={item} />);
        break;
      case 'edit':
        if (ability.can('update', 'Attendance')) handleUpdateModal(item);
        break;
      case 'destroy':
        handleDeleteModal(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'parsed_time':
        return { sort_time: name };
      case 'real_marking_date':
        return { sort_column: 'time' };
      case 'employee_full_name':
        return { sort_employee_full_name: name };
      case 'status':
        return { sort_status: name };
      case 'shift':
        return { sort_shift: name };
      case 'employee_cost_center':
        return { sort_cost_center: name };
      case 'parsed_amount':
        return { sort_column: 'amount' };
      default:
        return { sort_column: name };
    }
  };

  const handleDownload = () => {
    setOnRequest(true);
    debounceIndexAttendanceRequest({
      dispatch,
      params: query,
      responseType: 'text',
      format: '.xlsx',
      successCallback: response => {
        setModalShow(false);
        downloadFile(response);
        setOnRequest(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadRequest = () => {
    setModalTitle('Exportar Asistencia');
    setModalShow(true);
    setModalBody('¿Estás seguro que deseas descargar asistencia?');
    setModalAction(() => handleDownload);
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    if (!item.selectedCount) {
      setDisableAction(true);
    } else {
      setDisableAction(false);
    }
  };

  const handleMassDisable = () => {
    const attendanceIds = selectedRows.map(attendance => attendance.id);
    massDisableAttendanceRequest({
      dispatch,
      params: { attendance_ids: attendanceIds },
      successCallback: () => handleSuccessRequest('mass_disable'),
      failureCallback: error => handleFailureRequest(error)
    });
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, ability.can('update', 'Attendance'))}
        data={attendances}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        onRowClicked={item => handleActions(item, 'show')}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        pointerOnHover
        withStartDate
        withEndDate
        preName="attendance"
        selectableRows
        onSelectedRowsChange={handleSelectedRows}
        withMassActions={tab !== 'team'}
        massActions={
          tab !== 'team' && (
            <>
              {ability.can('export', 'Permission') && (
                <ButtonTooltip
                  variant="circle-primary"
                  className="mr-2 btn-circle mb-3"
                  text="Exportar"
                  onClick={() => handleDownloadRequest()}
                >
                  <Icon icon="download-2" />
                </ButtonTooltip>
              )}
              {ability.can('deactivate', 'Employee') && (
                <ButtonTooltip
                  variant="circle-danger"
                  className="btn-circle mb-3 mr-2"
                  text="Desactivar/Activar"
                  disabled={disabledAction}
                  onClick={() => handleMassDisable()}
                >
                  <Icon icon="archive" />
                </ButtonTooltip>
              )}
            </>
          )
        }
      />
      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        size={size}
        show={simpleModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleDefaultModalClose}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default AttendanceDataTable;
