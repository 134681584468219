import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class GlobalErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // eslint-disable-next-line no-console
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Customized error handling goes here!
    // eslint-disable-next-line no-console
    console.log(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <div>
            <h2 className="text-primary text-center mb-4">¡Ups!</h2>
            <h5 className="text-black-50 text-uppercase text-center">Algo no salió como esparabamos</h5>
            <Button className="d-flex justify-content-center" variant="primary" href="/">
              Volver
            </Button>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default GlobalErrorBoundary;
