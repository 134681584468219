import React from 'react';

function DoubleVerticalBar({ numberMen = 0, numberWomen = 0, ageRange = 0 }) {
  return (
    <div className="chart SeparateDoubleBar">
      <div className="position-center">
        <div className="DisplayFlex">
          <div className="SeparatebyMiddleAndAlign">
            <p className="MenColor">{numberMen}</p>
            <div
              className="VerticalbarWithoutBackgrounfcolor"
              style={{ backgroundColor: '#f8c444', height: `${numberMen * 2}px`, maxHeight: 200 }}
            />
          </div>

          <div className="SeparatebyMiddleAndAlign">
            <p className="WomenColor">{numberWomen}</p>
            <div
              className="VerticalbarWithoutBackgrounfcolor"
              style={{ backgroundColor: '#8054fc', height: `${numberWomen * 2}px`, maxHeight: 200 }}
            />
          </div>
        </div>
        <div className="AgeRangeBotton">
          <p className="AgeRange">{ageRange}</p>
        </div>
      </div>
    </div>
  );
}

export default DoubleVerticalBar;
