/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { Button } from 'react-bootstrap';
import { DatatableEmployeeName, ApprovementStatus } from '../../../components';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} />,
    sortable: true,
    grow: '2',
    width: '200px',
    align: 'left'
  },
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'MONTO',
    selector: 'amount',
    cell: item => item.parsed_amount,
    sortable: true,
    grow: '1'
  },
  {
    name: 'INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TÉRMINO',
    selector: 'end_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => {
      return (
        <Button variant="pw" block onClick={() => clickHandler(item, 'actions')} size="sm">
          Gestionar
        </Button>
      );
    },
    ignoreRowClick: false,
    allowOverflow: false,
    right: true,
    minWidth: '100px'
  }
]);

export default columns;
