import React, { useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es-mx';
import { useFormikContext } from 'formik';
import { Form, InputGroup, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import { ICON_AFTER_POSITION } from 'react-dates/constants';
// import Select from 'react-select';
import RenderMonthElement from './RenderMonthElement';
import Icon from '../../Icons';

const FormikDatePicker = ({
  abbr,
  delayHide,
  delayShow,
  direction,
  disabled,
  error,
  helpText,
  label,
  margin,
  tooltipText,
  touched,
  value,
  name,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  moment.locale('es-mx');
  const [focus, setFocus] = useState(false);
  let valueDate = null;
  if (moment.isMoment(value)) valueDate = value;
  else if (value) valueDate = moment(value, 'DD/MM/YYYY');
  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${margin} ${error && touched ? 'is-invalid' : ''}`}>
      {label && (
        <Form.Label className="d-flex align-items-center">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          {tooltipText && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                <Icon icon="help-circle" height="15px" width="15px" />
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      )}
      <InputGroup>
        <SingleDatePicker
          block
          id={label || 'date_input'}
          date={valueDate}
          placeholder="dd/mm/aaaa"
          focused={focus}
          showClearDate
          onFocusChange={({ focused }) => setFocus(focused)}
          numberOfMonths={1}
          onDateChange={date => setFieldValue(name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : '')}
          customCloseIcon={<Icon icon="close" width={18} />}
          customInputIcon={<Icon icon="calendar" width={18} />}
          hideKeyboardShortcutsPanel
          isOutsideRange={() => false}
          inputIconPosition={ICON_AFTER_POSITION}
          firstDayOfWeek={1}
          renderMonthElement={RenderMonthElement}
          {...props}
        />
      </InputGroup>
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikDatePicker.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  margin: PropTypes.string,
  tooltipText: PropTypes.string
};

FormikDatePicker.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  margin: '',
  tooltipText: ''
};

export default FormikDatePicker;
