import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { legalAttendanceReport } from '../../requests/legalreports';
import { ComponentDataTable } from '../index';

const AttendanceFilterDataTable = ({ customParams, moreData, columns, customStyles }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [attendances, setAttendances] = useState([]);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    setAttendances(response.data);
    setOnRequest(false);
  };

  const handleAttendanceReportRequest = () => {
    if (attendances.length > 0) {
      return;
    }
    setOnRequest(true);
    const request = async () =>
      legalAttendanceReport({
        dispatch,
        params: { ...customParams },
        successCallback: handleSuccessIndex
      });
    request();
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'parsed_time':
        return { sort_time: name };
      case 'employee_full_name':
        return { sort_employee_full_name: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        data={attendances}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        resourceRequest={handleAttendanceReportRequest}
        pointerOnHover
        preName="attendance"
        withSearch={false}
        pagination={false}
        paginationServer={false}
        customStyles={customStyles}
      />
    </>
  );
};

export default AttendanceFilterDataTable;
