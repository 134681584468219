import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import './style.scss';

const DocumentEditor = ({ abbr, data, label, margin, error, helpText, touched, isReadOnly, ...props }) => {
  return (
    <Form.Group className={margin}>
      {label && (
        <Form.Label>
          {label} {abbr && <abbr className="text-danger">*</abbr>}
        </Form.Label>
      )}
      <InputGroup>
        <div className={`document-editor ${error && touched ? ' is-invalid' : ''}`}>
          <div className={`document-editor__toolbar ${isReadOnly && 'is-read-only'}`} />
          <div className="document-editor__editable-container">
            <div className="document-editor__editable">
              <Editor
                tinymceScriptSrc='/js/tinymce/tinymce.min.js'
                value={data}
                init={{
                  height: 500,
                  plugins:
                    'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount charmap emoticons',
                  menu: {
                    file: { title: 'File', items: 'restoredraft | preview | print ' },
                    edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
                    view: {
                      title: 'View',
                      items: 'visualaid visualchars visualblocks | spellchecker | preview fullscreen'
                    },
                    insert: {
                      title: 'Insert',
                      items:
                        'image link inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'
                    },
                    format: {
                      title: 'Format',
                      items:
                        'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat'
                    },
                    tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | wordcount' },
                    table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' }
                  },
                  toolbar:
                    'fontfamily | fontsize | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | undo redo | bold italic underline strikethrough | forecolor backcolor removeformat | save print',
                  toolbar_sticky: true,
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  language: 'es_MX',
                  language_url: '/langs/es_MX.js',
                  directionality: 'ltr'
                }}
                {...props}
              />
            </div>
          </div>
        </div>
      </InputGroup>
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

export default DocumentEditor;
