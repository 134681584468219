import ApiService from '../services/apiService';

export const indexPayrollRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) => {
  ApiService.request('get', '/payrolls', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service
  });
};

export const deletePayrollRequest = (id, { dispatch, params = {}, successCallback, callback, service = 'payroll' }) =>
  ApiService.request('delete', `/payrolls/${id}`, {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });

export const downloadPayrollRequest = (id, { dispatch, params = {}, successCallback, callback, service = 'payroll' }) =>
  ApiService.request('get', `/payrolls/${id}/download`, {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });

export const payrollEmployeesRequest = ({ dispatch, params = {}, successCallback, callback, service = 'payroll' }) => {
  ApiService.request('get', '/payrolls/employees', {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });
};

export const employeesErrorsRequest = ({ dispatch, params = {}, successCallback, callback, service = 'payroll' }) => {
  ApiService.request('get', '/payrolls/employees_errors', {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });
};

export const downloadPayrollDocumentsRequest = ({
  dispatch,
  params = {},
  format,
  responseType,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('get', '/payrolls/download.zip', {
    dispatch,
    params,
    format,
    responseType,
    successCallback,
    callback,
    service
  });

export const sendSalaryStatementRequest = ({ dispatch, params = {}, successCallback, callback, service = 'payroll' }) =>
  ApiService.request('get', '/payrolls/send_email', {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });

export const createWorkflowRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('get', '/payrolls/create_workflow', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    service
  });

export const showPayroll = (id, { dispatch, params = {}, successCallback, callback, service = 'payroll' }) => {
  ApiService.request('get', `/payrolls/${id}`, {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });
};

export const updatePayrollRequest = ({ dispatch, params = {}, successCallback, callback, service = 'payroll' }) =>
  ApiService.request('put', '/payrolls/process_payroll', {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });

export const payrollCostCenterRequest = ({ dispatch, params = {}, successCallback, callback, service = 'payroll' }) => {
  ApiService.request('get', '/payrolls/cost_center', {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });
};

export const payrollJobManagementRequest = ({
  dispatch,
  params = {},
  successCallback,
  callback,
  service = 'payroll'
}) => {
  ApiService.request('get', '/payrolls/job_management', {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });
};
