import React from 'react';
import memoizeOne from 'memoize-one';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import SimpleActionsHistories from './SimpleActionsHistories';

const columns = memoizeOne((handleClick, onlyShow) => [
  {
    name: 'EMPLEADO',
    selector: 'full_name',
    id: 'full_name',
    cell: item => {
      return (
        <div className="d-flex flex-column" role="presentation">
          <span data-tag="allowRowEvents">{item?.full_name}</span>
          <span className="text-muted" data-tag="allowRowEvents">
            {item?.start_date_contract ? `(${item?.start_date_contract})` : null}
          </span>
        </div>
      );
    },
    grow: '1',
    sortable: true
  },
  {
    name: 'DÍAS TRABAJADOS',
    selector: 'worked_days',
    id: 'worked_days',
    grow: '1',
    sortable: true,
    style: {
      justifyContent: 'center'
    }
  },
  {
    name: 'S. BASE',
    selector: 'salary_amount',
    id: 'salary_amount',
    grow: '1',
    sortable: true
  },
  {
    name: 'T. IMPONIBLE',
    selector: 'assets_taxable_total',
    id: 'assets_taxable_total',
    grow: '1',
    sortable: true
  },
  {
    name: 'T. HABERES',
    selector: 'assets_total',
    id: 'assets_total',
    grow: '1',
    sortable: true
  },
  {
    name: 'DESCUENTOS LEGALES',
    selector: 'discounts_total_legal',
    id: 'discounts_total_legal',
    grow: '1',
    sortable: true
  },
  {
    name: 'T. DESCUENTOS',
    selector: 'discounts_total',
    id: 'discounts_total',
    grow: '1',
    sortable: true
  },
  {
    name: 'LÍQUIDO A PAGAR',
    selector: 'liquid',
    id: 'liquid',
    grow: '1',
    sortable: true
  },
  {
    name: 'ACCIONES',
    selector: 'actions',
    cell: item => (
      <SimpleActionsHistories
        handleClick={handleClick}
        item={camelCaseEmptyStringRecursive(item)}
        onlyShow={onlyShow}
      />
    ),
    width: '180px',
    id: 'actions',
    grow: '1'
  }
]);

export default columns;
