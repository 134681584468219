import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Icon from '../../components/Icons';

const PasswordChecklist = ({ password, achieveRequirements, containerStyle = null }) => {
  const [correctLength, setCorrectLength] = useState(false);
  const [containsUpperCase, setContainsUpperCase] = useState(false);
  const [specialCharacter, setSpecialCharacter] = useState(false);
  const [containsLowerCase, setContainsLowerCase] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);

  const checkAllParameters = () => {
    if (correctLength && containsUpperCase && specialCharacter && containsLowerCase && containsNumber) {
      achieveRequirements(true);
    } else {
      achieveRequirements(false);
    }
  };

  useEffect(() => {
    const minLength = 8;

    const checkLength = () => {
      const lengthCharacters = password.length;

      if (lengthCharacters < minLength) {
        setCorrectLength(false);
      } else if (lengthCharacters >= minLength) {
        setCorrectLength(true);
      }
    };

    const checkNumber = () => {
      const format = /\d/;
      if (password.match(format)) {
        return setContainsNumber(true);
      }
      return setContainsNumber(false);
    };

    const checkLowerCase = () => {
      const format = /[a-z]/;
      if (password.match(format)) {
        return setContainsLowerCase(true);
      }
      return setContainsLowerCase(false);
    };

    const checkUpperCase = () => {
      const format = /[A-Z]/;
      if (password.match(format)) {
        return setContainsUpperCase(true);
      }
      return setContainsUpperCase(false);
    };

    const checkSpecialCharacter = () => {
      const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/;

      if (password.match(format)) {
        return setSpecialCharacter(true);
      }
      return setSpecialCharacter(false);
    };

    checkLength();
    checkUpperCase();
    checkLowerCase();
    checkSpecialCharacter();
    checkNumber();
  }, [password]);

  useEffect(checkAllParameters, [
    correctLength,
    containsUpperCase,
    specialCharacter,
    containsLowerCase,
    containsNumber
  ]);

  const ShowMessage = (action, message) => {
    if (password === '') {
      return (
        <div>
          <Icon icon="alert-circle" height="20px" width="20px" className="mr-2" />
          {message}
        </div>
      );
    }
    const style = action ? 'text-success' : 'text-danger';
    const icon = action ? 'checkmark-circle' : 'close-circle';

    return (
      <div className={style}>
        <Icon icon={icon} height="20px" width="20px" className="mr-2" />
        {message}
      </div>
    );
  };

  return (
    <>
      <Row style={containerStyle}>
        <Col md={10}>
          {ShowMessage(correctLength, `Debe tener minimo 8 caracteres`)}
          {ShowMessage(containsUpperCase, 'Debe tener minimo un caracter en mayuscula')}
          {ShowMessage(containsLowerCase, 'Debe tener minimo un caracter en minuscula')}
          {ShowMessage(specialCharacter, 'Debe tener minimo un caracter especial')}
          {ShowMessage(containsNumber, 'Debe tener minimo un numero')}
        </Col>
      </Row>
    </>
  );
};
PasswordChecklist.prototype = {
  password: PropTypes.string.isRequired
};

PasswordChecklist.defaultProps = {
  password: ''
};
export default PasswordChecklist;
