import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import CustomFieldInfo from './CustomFieldInfo';
import { delayMethod } from '../../services/utils';

const CustomFieldShow = ({ customField, hideModal }) => (
  <>
    <Modal.Body>
      <CustomFieldInfo customField={customField} />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-info" onClick={() => delayMethod(hideModal)}>
        Cerrar
      </Button>
    </Modal.Footer>
  </>
);

export default CustomFieldShow;
