import React from 'react';
import memoize from 'memoize-one';

export default memoize(() => [
  {
    name: 'NOMBRE',
    selector: 'employee_name',
    grow: '1',
    cell: item => <span>{item?.employee?.full_name}</span>
  },
  {
    name: 'RUT',
    selector: 'national_identification',
    cell: item => <span>{item?.employee?.national_identification}</span>,
    grow: '1'
  },
  {
    name: 'PROCESO DE PRÉSTAMO',
    selector: 'parsed_month_year',
    grow: '1'
  },
  {
    name: 'MONTO DE LA CUOTA',
    selector: 'parsed_amount',
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'parsed_status',
    grow: '1'
  },
  {
    name: 'CONCEPTO',
    selector: 'parsed_loan_type',
    id: 'parsed_loan_type'
  },
  {
    name: 'MES DE REMUNERACION',
    selector: 'payroll_process_name'
  }
]);
