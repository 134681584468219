import React, { useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Form as BSForm } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import {
  CheckBoxBtn,
  FormikDatePicker,
  FormikInput,
  FormikMaterialUiTimePicker,
  FormikNumber,
  FormikRangePicker,
  FormikSelect,
  LinkBtn,
  NestedAttributes,
  UploadFile,
  UploadImage,
  InputSelect,
  RegionCommune
} from '../../components';
import { debounceIndexCountriesRequest } from '../../requests/countries';

import { rutFormat, validRutInput } from '../../services/utils';
import '../../services/yupCustomMethods';
import { modality, activity, statuses, identificationTypes } from './FormOptions';

const InstructorInput = ({ errors, touched, index, instructorAttribute, setFieldValue, setFieldTouched }) => {
  const handleNationalIdentificationFormat = (e, isRut) => {
    if (isRut && validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  return (
    <>
      <Col md={4}>
        <Field name={`course[instructorsAttributes][${index}][name]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              label="Nombre Relator"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={4} xl={3}>
        <Field name={`course[instructorsAttributes][${index}][identificationType]`}>
          {({ field }) => (
            <FormikSelect
              {...field}
              defaultValue={instructorAttribute.identificationType}
              label="Tipo de Identificación"
              options={identificationTypes}
              placeholder="Seleccionar"
              onChange={data => {
                setFieldValue(`course[instructorsAttributes][${index}][nationalIdentification]`, '');
                setFieldValue(field.name, data ? data.value : 'rut');
              }}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={3} xl={4}>
        <Field name={`course[instructorsAttributes][${index}][nationalIdentification]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              label={instructorAttribute.identificationType === 'rut' ? 'RUT' : 'Nº de Identificación'}
              onChange={e => handleNationalIdentificationFormat(e, instructorAttribute.identificationType === 'rut')}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </>
  );
};

const CourseForm = ({ errors, touched, values, setFieldValue, setFieldTouched, isSubmitting, action }) => {
  const {
    startDate,
    endDate,
    courseModality,
    activityType,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    bipartiteCommittee,
    senceRegister,
    instructorsAttributes,
    courseCertificate,
    courseImage,
    country
  } = values.course;

  const vSenceRegister = !senceRegister;
  const vBipartiteCommittee = !bipartiteCommittee;
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const isChile = country?.label === 'Chile';
  const dispatch = useDispatch();

  const addInstructor = () => {
    const mapResults = instructorsAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <InstructorInput
          key={`instructor-${index.toString()}`}
          errors={errors}
          touched={touched}
          index={index}
          instructorAttribute={body}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      );
    });

    mapResults.push(
      <>
        <Col md={4} className="sample-row">
          <FormikInput label="Nombre Relator" disabled />
        </Col>
        <Col md={4} xl={3} className="sample-row">
          <FormikInput label="Tipo de Identificación" disabled />
        </Col>
        <Col md={3} xl={4} className="sample-row">
          <FormikInput label="Nº de Identificación" disabled />
        </Col>
      </>
    );

    return (
      <>
        <NestedAttributes
          removeFirstItem
          mapInputs={mapResults}
          arrayValues={instructorsAttributes}
          setFieldValue={setFieldValue}
          valuePath="course[instructorsAttributes]"
          newAttributes={{ identificationType: '', nationalIdentification: '', name: '' }}
        />
      </>
    );
  };

  const resultFetchData = response => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: element.label,
      value: element.value
    }));
  };

  const fetchCountries = (inputValue, callback) => {
    debounceIndexCountriesRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'position',
        display_length: 250
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const handleRegionCommuneDisabled = () => {
    if (isChile) return;
    setFieldValue('course[regionId]', '');
    setFieldValue('course[communeId]', '');
  };
  useEffect(handleRegionCommuneDisabled, [isChile]);

  const clearSenceCode = () => {
    if (vSenceRegister) setFieldValue('course[senceCode]', '');
  };
  useEffect(clearSenceCode, [vSenceRegister]);

  return (
    <Form className="form-course mx-1 mx-xl-4">
      <Row>
        <Col md={4}>
          <Field name="course[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre del curso"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <BSForm.Group className="button-group max-width-group">
            <BSForm.Label>Registro en sence</BSForm.Label>
            <Row>
              <Field name="course[senceRegister]">
                {({ field }) => <CheckBoxBtn {...field} id="sence" label="Si" checked={senceRegister} />}
              </Field>
              <Field name="course[vSenceRegister]">
                {({ field }) => <CheckBoxBtn {...field} id="sence" label="No" checked={vSenceRegister} />}
              </Field>
            </Row>
          </BSForm.Group>
        </Col>
        <Col className={`${vSenceRegister && 'bg-opacity'}`} md={4}>
          <Field name="course[senceCode]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr={senceRegister}
                label="Código Sence"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled={vSenceRegister}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Field name="course[parsedTotalValue]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                label="Valor total del curso"
                fieldName="course[totalValue]"
                setFieldValue={setFieldValue}
                value={getIn(values, 'course[totalValue]')}
                errors={errors}
                touched={touched}
                // margin='mb-0'
              />
            )}
          </Field>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={12}>
          <Row>
            <Col md={4}>
              <Field name="course[countryId]">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    abbr
                    label="País"
                    placeholder="Seleccionar País"
                    defaultValue={values.course.country}
                    request={fetchCountries}
                    onChange={data => {
                      setFieldValue(field.name, data ? data.value : '');
                      setFieldValue('course[country]', data || {});
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={8}>
              <Row>
                <RegionCommune regionAbbr={isChile} communeAbbr={isChile} modelKey="course" allDisabled={!isChile} />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Field name="course[address]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Dirección de realización del curso"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="course[addressNumber]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Número"
                inputType="number"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="course[addressApt]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Departamento"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field name="rangeDate">
            {({ field }) => (
              <FormikRangePicker
                {...field}
                abbr
                startDateName="course[startDate]"
                endDateName="course[endDate]"
                startDate={startDate}
                endDate={endDate}
                showClearDates
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="course[limitDate]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                isOutsideRange={() => false}
                label="Fecha limite de Inscripción"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <BSForm.Group className="button-group max-width-group">
            <BSForm.Label>Días de Clases</BSForm.Label>
            <Row>
              <Field name="course[monday]">
                {({ field }) => <CheckBoxBtn {...field} id="monday" label="Lu" checked={monday} />}
              </Field>
              <Field name="course[tuesday]">
                {({ field }) => <CheckBoxBtn {...field} id="tuesday" label="Ma" checked={tuesday} />}
              </Field>
              <Field name="course[wednesday]">
                {({ field }) => <CheckBoxBtn {...field} id="wednesday" label="Mi" checked={wednesday} />}
              </Field>
              <Field name="course[thursday]">
                {({ field }) => <CheckBoxBtn {...field} id="thursday" label="Ju" checked={thursday} />}
              </Field>
              <Field name="course[friday]">
                {({ field }) => <CheckBoxBtn {...field} id="friday" label="Vi" checked={friday} />}
              </Field>
              <Field name="course[saturday]">
                {({ field }) => <CheckBoxBtn {...field} id="saturday" label="Sa" checked={saturday} />}
              </Field>
              <Field name="course[sunday]">
                {({ field }) => <CheckBoxBtn {...field} id="sunday" label="Do" checked={sunday} />}
              </Field>
            </Row>
          </BSForm.Group>
        </Col>
        <Col md={3}>
          <Field name="course[startTime]">
            {({ field }) => (
              <FormikMaterialUiTimePicker
                {...field}
                abbr
                timeSelector
                label="Hora de inicio"
                onChange={time => setFieldValue(field.name, time)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field name="course[endTime]">
            {({ field }) => (
              <FormikMaterialUiTimePicker
                {...field}
                abbr
                timeSelector
                label="Hora de Termino"
                onChange={time => setFieldValue(field.name, time)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={4}>
          <Field name="course[theme]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Tema"
                tooltipText="Categoría a la que pertence el curso"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="course[parsedMaxAvailability]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                label="Máximo de Cupos"
                fieldName="course[maxAvailability]"
                setFieldValue={setFieldValue}
                value={getIn(values, 'course[maxAvailability]')}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="course[courseCertificate]">
            {({ field }) => (
              <UploadFile
                {...field}
                label="Certificado o Diploma"
                tooltipText="Formatos permitidos (JPG, PNG, WORD, PDF, RTF)"
                formats={['.docx', '.png', '.jpeg', '.pdf', '.jpg', '.doc', '.rtf']}
                name={courseCertificate?.filename || 'Adjuntar Archivo'}
                onChange={file => setFieldValue(field.name, file)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Field name="course[activityType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de actividad de capacitación"
                tooltipText=""
                placeholder="Seleccionar actividad"
                options={activity}
                defaultValue={activityType}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="course[courseModality]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Modalidad del curso"
                tooltipText="Tipo de modalidad que será impartido el curso"
                placeholder="Seleccionar modalidad"
                options={modality}
                defaultValue={courseModality}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <BSForm.Group className="button-group max-width-group">
            <BSForm.Label>Comité Bipartito</BSForm.Label>
            <Row>
              <Field name="course[bipartiteCommittee]">
                {({ field }) => (
                  <CheckBoxBtn {...field} id="bipartiteCommittee" label="Si" checked={bipartiteCommittee} />
                )}
              </Field>
              <Field name="course[vBipartiteCommittee]">
                {({ field }) => (
                  <CheckBoxBtn {...field} id="bipartiteCommittee" label="No" checked={vBipartiteCommittee} />
                )}
              </Field>
            </Row>
          </BSForm.Group>
        </Col>
        <Col md={4}>
          <Field name="course[parsedMandateIndicator]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                label="Indicador de Mandato"
                fieldName="course[mandateIndicator]"
                setFieldValue={setFieldValue}
                value={getIn(values, 'course[mandateIndicator]')}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="course[courseAgency]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Organismo que imparte el curso"
                tooltipText="Entidad o empresa que imparte el curso"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <hr />
      <h4 className="text-uppercase mb-3">Relatores</h4>
      <div className="bg-color-course p-course b-radius">{addInstructor()}</div>
      <hr />
      <Row>
        <Col md={4} className="">
          <Field name="course[courseImage]">
            {({ field }) => (
              <>
                <BSForm.Label className="d-md-block d-none">Banner del Curso</BSForm.Label>
                <UploadImage
                  {...field}
                  name="Agregar imagen"
                  imageUrl={getIn(courseImage, 'fileUrl')}
                  onChange={image => setFieldValue(field.name, image)}
                  helpText="Formato sugerido 535x175px de máximo 5mb."
                  fileAccept=".png, .jpg, .jpeg"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              </>
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field name="course[description]">
            {({ field }) => (
              <FormikInput
                {...field}
                as="textarea"
                label="Descripción"
                minInputHeight="120"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="course[status]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Estado del Curso"
                tooltipText="Indica el estado actual del curso"
                placeholder="Seleccionar estado"
                options={statuses}
                defaultValue={values.course.status}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-3 mb-5">
        <Col md={3} className="mb-2 mb-md-0 mr-md-n4 mr-lg-n2">
          <LinkBtn
            block
            variant="outline-info"
            className="btn-circle"
            to={action === 'new' ? '/courses' : `/courses/${id}`}
          >
            Cancelar
          </LinkBtn>
        </Col>
        <Col md={3}>
          <Button type="submit" disabled={isSubmitting} block>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { course } = props;

  return {
    course,
    rangeDate: [course.startDate, course.endDate]
  };
};

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/rtf',
  'text/rtf'
];

const validationSchema = Yup.object().shape({
  course: Yup.object().shape(
    {
      activityType: Yup.string().required('Debes seleccionar una actividad'),
      address: Yup.string().required('Debes ingresar una dirección'),
      addressNumber: Yup.number()
        .required('Debes ingresar un número')
        .typeError('Debes ingresar un número')
        .min(0, 'Debe ser mayor o igual a 0'),
      communeId: Yup.string().when('country', {
        is: val => val?.label === 'Chile',
        then: Yup.string().required('Debes seleccionar una comuna'),
        otherwise: Yup.string().nullable()
      }),
      countryId: Yup.string().required('Debes seleccionar un país'),
      courseAgency: Yup.string().required('Debes ingresar el organismo que imparte el curso'),
      courseCertificate: Yup.mixed()
        .nullable()
        .notRequired()
        .test(
          'FILE_SIZE',
          'El archivo cargado excede el tamaño maximo permitido (5mb).',
          value => !value?.size || (value && value?.size <= 5242880)
        )
        .test(
          'FILE_FORMAT',
          'El archivo cargado tiene un formato no compatible.',
          value => !value?.size || (value && SUPPORTED_FORMATS.includes(value?.type))
        ),
      courseImage: Yup.mixed()
        .nullable()
        .notRequired()
        .test(
          'FILE_SIZE',
          'La imagen cargada excede el tamaño maximo permitido (5mb).',
          value => !value?.size || (value && value?.size <= 5242880)
        )
        .test(
          'FILE_FORMAT',
          'El archivo cargado tiene un formato no compatible.',
          value => !value?.type || (value && ['image/jpg', 'image/png', 'image/jpeg'].includes(value?.type))
        ),
      courseModality: Yup.string().required('Debes seleccionar una modalidad de curso'),
      endDate: Yup.date()
        .required('Debes seleccionar una fecha de término')
        .formatdate()
        .when(
          'limitDate',
          (limitDate, schema) =>
            limitDate && schema.min(limitDate, 'Debe ser mayor o igual a la fecha limite de inscripción')
        ),
      endTime: Yup.string().required('Debes seleccionar una hora de término'),
      instructorsAttributes: Yup.array().of(
        Yup.object().shape(
          {
            name: Yup.string().required('Debes ingresar el nombre del relator'),
            nationalIdentification: Yup.string().when('identificationType', (identificationType, schema) => {
              if (identificationType) {
                return identificationType === 'rut'
                  ? schema.required('Debes ingresar el RUT').rut('Debes ingresar un RUT válido')
                  : schema
                      .required('Debes ingresar el número de identificación')
                      .alphanumeric('Deben ser caracteres alfanuméricos');
              }
              return schema.nullable();
            }),
            identificationType: Yup.string().when('nationalIdentification', (nationalId, schema) =>
              nationalId ? schema.required('Debes ingresar el tipo de identificación') : schema.nullable()
            )
          },
          [['identificationType', 'nationalIdentification']]
        )
      ),
      limitDate: Yup.date()
        .required('Debes seleccionar una fecha limite de inscripción')
        .formatdate()
        .when(
          'endDate',
          (endDate, schema) => endDate && schema.max(endDate, 'Debe ser menor o igual a la fecha de término')
        ),
      mandateIndicator: Yup.string().required('Debes ingresar un indicador de mandato'),
      maxAvailability: Yup.number()
        .required('Debes ingresar un máximo de cupo')
        .typeError('Debes ingresar un número')
        .positive('Debe ser mayor a 0'),
      name: Yup.string().required('Debes ingresar un nombre del curso'),
      regionId: Yup.string().when('country', {
        is: val => val?.label === 'Chile',
        then: Yup.string().required('Debes seleccionar una región'),
        otherwise: Yup.string().nullable()
      }),
      startDate: Yup.date().formatdate(),
      startTime: Yup.string().required('Debes seleccionar una hora de inicio'),
      status: Yup.string().required('Debes seleccionar un estado'),
      senceCode: Yup.string().when('senceRegister', {
        is: true,
        then: Yup.string().required('Debes ingresar un código sence')
      }),
      theme: Yup.string().required('Debes ingresar un tema'),
      totalValue: Yup.number()
        .required('Debes ingresar un valor total')
        .typeError('Debes ingresar solo números')
        .positive('Debe ser mayor a 0')
    },
    [['endDate', 'limitDate']]
  ),
  rangeDate: Yup.array().rangedate(true)
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(CourseForm);
