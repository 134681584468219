import React from 'react';
import TotalizedAbsenceReportExpanderBody from './TotalizedAbsenceReportExpanderBody';

const Expander = props => {
  const { data } = props;
  const { absence_values: abcenceValues } = data;
  return <TotalizedAbsenceReportExpanderBody records={abcenceValues} />;
};

export default Expander;
