import React, { useContext } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ImportModal } from '../../../components';
import { sendAlert } from '../../../actions/utils';
import { PayrollProcessContext } from '../context/PayrollProcessProvider';
import {
  importBalancesHistoriesRequest,
  importBalancesHistoriesTemplateRequest,
  preImportBalancesHistoriesRequest
} from '../../../requests/payrollHistories';

const ActionsHistories = ({ setModalState, setMoreData, setSimpleModalState }) => {
  const dispatch = useDispatch();
  const { payrollProcess } = useContext(PayrollProcessContext);
  const { currentCompany } = useSelector(state => state.auth);

  const handleModalClose = () => {
    setModalState(state => ({
      ...state,
      body: <></>,
      show: false
    }));
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalState({
        title: 'Importar Conceptos',
        body: alertInfo,
        show: true,
        action: handleModalClose
      });
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleExport = exportType => {
    switch (exportType) {
      case 'import_benefits':
        setSimpleModalState({
          title: 'Importar Conceptos',
          body: (
            <ImportModal
              onDropUploaded={preImportBalancesHistoriesRequest}
              handleTemplate={importBalancesHistoriesTemplateRequest}
              onHide={importBalancesHistoriesRequest}
              hideModal={() => setSimpleModalState(oldState => ({ ...oldState, show: false }))}
              updateData={() => setMoreData(oldValue => !oldValue)}
              handleExceptions={handleImportModalExceptions}
              params={{
                company_id: currentCompany.id,
                payroll_process_id: payrollProcess.id,
                paginate: false,
                show_payroll: true
              }}
              service="payroll"
            />
          ),
          show: true
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Col />
      <Col md={3}>
        <Button variant="primary" block onClick={() => handleExport('import_benefits')}>
          Importar
        </Button>
      </Col>
    </>
  );
};

export default ActionsHistories;
