import React, { useMemo, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { useAbility } from '@casl/react';
import snakeCaseKeys from 'snakecase-keys';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSetTab } from '../../services/hooks';
import { sendAlert } from '../../actions/utils';
import { FormikSelect, SimpleCenteredModal } from '../../components';
import { createLoanProcessRequest } from '../../requests/loanProcesses';
import { AbilityContext } from '../../config/abilityContext';
import LoanProcessForm from './LoanProcessForm';
import LoanProcessDataTable from './LoanProcessDataTable';

const LoanProcessIndex = ({ location }) => {
  const ability = useAbility(AbilityContext);
  const [year, setYear] = useSetTab(moment().year(), location, 'year', e => parseInt(e, 10));
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const history = useHistory();
  const years = useMemo(() => Array.from(Array(10), (e, i) => ({ label: i + 2020, value: i + 2020 })), []);
  const dispatch = useDispatch();

  const handleAfterCreate = setSubmitting => {
    setSubmitting(false);
    setMoreData(val => !val);
  };

  const handleCreate = (values, setSubmitting) => {
    createLoanProcessRequest({
      dispatch,
      params: snakeCaseKeys(values),
      successCallback: response => {
        dispatch(sendAlert({ kind: 'success', message: 'Proceso creado con éxito' }));
        setModalShow(false);
        history.push(`/loan_processes/${response.data.id}`);
      },
      callback: () => handleAfterCreate(setSubmitting)
    });
  };

  return (
    <>
      <Row className="m-top mb-4">
        <Col>
          <h2 className="m-top mt-1 mb-1">Préstamos / Créditos</h2>
        </Col>
        <Col md={3}>
          <FormikSelect
            options={years}
            label=""
            defaultValue={year}
            value={year}
            onChange={data => {
              setYear(data ? data.value : '');
              setMoreData(!moreData);
            }}
            placeholder="Selecciona un año"
          />
        </Col>
        {ability.can('manage', 'LoanProcess.LoanInstallment') && (
          <Col md={3}>
            <Button variant="primary" block onClick={() => setModalShow(true)}>
              Nuevo
            </Button>
          </Col>
        )}
      </Row>
      <LoanProcessDataTable moreData={moreData} setMoreData={setMoreData} customParams={{ filter_year: year }} />
      <SimpleCenteredModal
        title="Nuevo Proceso de PRÉSTAMO / CRÉDITO"
        body={<LoanProcessForm formRequest={handleCreate} currentYear={year} />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default LoanProcessIndex;
