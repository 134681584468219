/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

const ApprovementStatus = ({ margin, status, translatedStatus, onClick }) => {
  const renderBody = () => {
    switch (status) {
      case 'pending':
      case 'in_process':
        return (
          <p className={`text-warning ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Pendiente'}
          </p>
        );
      case 'to_discount':
      case 'approved':
        return (
          <p className={`text-primary ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Aprobado'}
          </p>
        );
      case 'deferred':
      case 'rejected':
        return (
          <p className={`text-danger ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Rechazado'}
          </p>
        );
      case 'not_signed':
        return (
          <p className={`text-info ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Emitido'}
          </p>
        );
      case 'sent':
        return (
          <p className={`text-info ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Enviado'}
          </p>
        );
      case 'closed':
        return (
          <p className={`text-danger ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Cerrado'}
          </p>
        );
      case 'prenomina':
        return (
          <p className={`text-danger ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Incompleto'}
          </p>
        );
      case 'completed':
        return (
          <p className={`text-primary ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Completo'}
          </p>
        );
      case 'draft':
      case 'not_started':
        return (
          <p className={`text-dark ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'No Comenzado'}
          </p>
        );
      case 'active':
        return (
          <p className={`text-primary ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Activo'}
          </p>
        );
      case 'open':
        return (
          <p className={`text-primary ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Abierto'}
          </p>
        );
      case 'disabled':
        return (
          <p className={`text-danger ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Deshabilitado'}
          </p>
        );

      case 'replaced':
        return (
          <p className={`text-warning ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Reemplazado'}
          </p>
        );
      default:
        return (
          <p className={`text-info ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            -
          </p>
        );
    }
  };
  return renderBody();
};

ApprovementStatus.propTypes = {
  margin: PropTypes.string,
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

ApprovementStatus.defaultProps = {
  margin: 'my-0',
  onClick: () => null
};

export default ApprovementStatus;
