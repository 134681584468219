import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { profileTeamDashboardRequest, profileDashboardRequest } from '../../../requests/dashboards';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import { LinkBtn } from '../../Utils/Button';
import { Icon, SimpleCenteredModal } from '../..';
import columns from '../../../screens/WorkflowRequest/Columns';
import WorkflowRequestDataTable from '../../../screens/WorkflowRequest/WorkflowRequestDataTable';

const PendingDocuments = ({ handleMoreInfoButton, topClass, tab, moreData, currentEmployee, currentTab }) => {
  const dispatch = useDispatch();
  const [cards, setCards] = useState({});
  const [showSpinner, setShowSpinner] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const pendingCard = tab === 'profile' ? cards.signature : cards?.totalRequestsCount;
  const hasAdvanceSetting = cards.hasAdvanceSetting === 'true';

  const handleFailureRequest = error => {
    setShowSpinner(0);
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const fetchCardData = () => {
    if (tab === 'profile') {
      profileDashboardRequest({
        dispatch,
        successCallback: response => {
          setCards(camelCaseEmptyStringRecursive(response.data));
          setShowSpinner(0);
        },
        failureCallback: error => handleFailureRequest(error)
      });
    } else {
      profileTeamDashboardRequest({
        dispatch,
        successCallback: response => {
          setCards(camelCaseEmptyStringRecursive(response.data).allRequestsCount);
          setShowSpinner(0);
        }
      });
    }
  };
  useEffect(fetchCardData, [moreData]);

  useEffect(() => {
    if (pendingCard > 0 && currentEmployee !== '') {
      setShowModal(true);
    }
  }, [pendingCard, currentEmployee]);

  return (
    <Card className="mb-1">
      <Col className={topClass}>
        <Col className="card-header-title">DOCUMENTOS POR FIRMAR</Col>
      </Col>
      {showSpinner ? (
        <Col className="mt-5">
          <div className="containerSpinnerLoadDash mb-5">
            <Spinner animation="border" variant="primary" />
          </div>
        </Col>
      ) : (
        <Col className="div-content">
          <Row style={{ height: tab === 'team' ? 140 : null }}>
            <Col sm={12} md={4} xs={12} className="mb-2 mt-2 circular-progress">
              <div style={{ width: 100, height: 100 }}>
                <CircularProgressbar
                  value={pendingCard}
                  text={`${pendingCard}`}
                  styles={buildStyles({
                    textSize: '26px',
                    pathColor: '#29B48F',
                    textColor: '#29B48F'
                  })}
                />
              </div>
            </Col>
            <Col sm={12} md={8} xs={12} className="mb-3 mt-4">
              <p className="text-dark-bold text-center" style={{ paddingTop: tab === 'team' ? 30 : 0 }}>
                Tienes {pendingCard} documento(s) por Aprobar/Firmar
              </p>
              {tab !== 'team' && (
                <LinkBtn variant="light-gray" block size="sm" to="/profile?tab=signature">
                  Ver más
                </LinkBtn>
              )}
            </Col>
          </Row>
          {tab === 'team' && (
            <button
              type="button"
              onClick={handleMoreInfoButton}
              style={{
                border: '1px solid #65b68f',
                backgroundColor: '#65b68f',
                position: 'absolute',
                right: 20,
                bottom: 20
              }}
            >
              <Icon icon="search" height="25px" width="16px" color="white" />
            </button>
          )}
        </Col>
      )}
      {hasAdvanceSetting && (
        <SimpleCenteredModal
          show={showModal}
          onHide={() => setShowModal(false)}
          title="Documentos por Firmar"
          body={
            <WorkflowRequestDataTable
              customFilter={{ document_employee: currentEmployee }}
              columns={columns}
              currentTab={currentTab}
              customSpinnerClass="containerSpinnerLoad h-100 w-100"
            />
          }
        />
      )}
    </Card>
  );
};

export default PendingDocuments;
