import React from 'react';
import RemunerationProcesses from './RemunerationProcesses';
import { PayrollProcessProvider } from './context/PayrollProcessProvider';

const RemunerationProcessesIndex = props => {
  return (
    <PayrollProcessProvider>
      <RemunerationProcesses {...props} />
    </PayrollProcessProvider>
  );
};

export default RemunerationProcessesIndex;
