const basicLoan = {
  amount: 0,
  bookkeeperId: '',
  employeeId: '',
  grantDate: '',
  installments: 0,
  name: '',
  parsedAmount: '',
  reason: '',
  startDate: '',
  status: ''
};

export default basicLoan;
