import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexOvertimeDiscountsMovementsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  service = 'payroll'
}) => {
  ApiService.request('get', '/overtime_discounts_movements', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service
  });
};

export const showOvertimeDiscountsMovementRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, service = 'payroll' }
) =>
  ApiService.request('get', `/overtime_discounts_movements/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service
  });

export const createOvertimeDiscountsMovementsRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('post', '/overtime_discounts_movements', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    service
  });

export const updateOvertimeDiscountsMovementRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, service = 'payroll' }
) =>
  ApiService.request('put', `/overtime_discounts_movements/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    service
  });

export const destroyOvertimeDiscountsMovementsRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, service = 'payroll' }
) => {
  ApiService.request('delete', `/overtime_discounts_movements/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service
  });
};

export const massiveDestroyMovementsRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('post', '/overtime_discounts_movements/massive_destroy', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    service
  });

export const preImportOvertimeDiscountsMovementsRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  service = 'payroll'
}) => {
  ApiService.request('get', '/overtime_discounts_movements/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    service
  });
};

export const importOvertimeDiscountsMovementsRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  service = 'payroll'
}) => {
  ApiService.request('post', '/overtime_discounts_movements/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    service
  });
};

export const changeStatusOvertimeDiscountsMovementsRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('post', '/overtime_discounts_movements/change_status', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    service
  });

export const exportOvertimeDiscountsMovementsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  service = 'payroll'
}) => {
  ApiService.request('get', `/overtime_discounts_movements/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    service
  });
};

export const importTemplateOvertimeDiscountsMovements = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/overtime_discounts_movements/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    service: 'payroll'
  });
};

export const debounceIndexOvertimeDiscountsMovementsRequest = AwesomeDebouncePromise(
  indexOvertimeDiscountsMovementsRequest,
  300
);
