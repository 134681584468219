import React, { useContext } from 'react';
import { Col, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, ImportModal } from '../../../components';
import { downloadFile } from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import { PayrollProcessContext } from '../context/PayrollProcessProvider';
import {
  exportPayrollBalanceEmployees,
  importPayrollBalanceEmployee,
  importTemplatePayrollBalanceEmployee,
  preImportPayrollBalanceEmployee
} from '../../../requests/payrollProcessBalances';

const Actions = ({
  setModalState,
  params,
  setActiveComponent,
  setOnRequest,
  setMoreData,
  setSimpleModalState,
  onlyShow
}) => {
  const dispatch = useDispatch();
  const { payrollProcess, setPayrollProcess } = useContext(PayrollProcessContext);
  const { currentCompany } = useSelector(state => state.auth);

  const handleDownload = filterOptions => {
    setOnRequest(true);
    exportPayrollBalanceEmployees({
      dispatch,
      params: { ...filterOptions },
      successCallback: response => {
        downloadFile(response);
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'error', message: 'Error al descargar el archivo' }));
      },
      callback: () => {
        setOnRequest(false);
        setModalState(state => ({ ...state, show: false }));
      }
    });
  };

  const handleModalClose = () => {
    setModalState(state => ({
      ...state,
      body: <></>,
      show: false
    }));
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalState({
        title: 'Importar Haberes',
        body: alertInfo,
        show: true,
        action: handleModalClose
      });
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleExport = exportType => {
    switch (exportType) {
      case 'export_benefits':
        setModalState({
          title: 'Exportar Haberes',
          body: '¿Estás seguro que deseas descargar los Haberes?',
          show: true,
          action: () =>
            handleDownload({
              ...params,
              filter_type: 'asset',
              filter_payroll_process_ids: payrollProcess.id,
              filter_active: true,
              available_on_payroll: true,
              show_payroll: true
            })
        });
        break;
      case 'export_discounts':
        setModalState({
          title: 'Exportar Descuentos',
          body: '¿Estás seguro que deseas descargar los Descuentos?',
          show: true,
          action: () =>
            handleDownload({
              ...params,
              filter_type: 'discount',
              filter_payroll_process_ids: payrollProcess.id,
              filter_active: true,
              available_on_payroll: true,
              show_payroll: true
            })
        });
        break;

      case 'import_benefits':
        setSimpleModalState({
          title: 'Importar Haberes',
          body: (
            <ImportModal
              onDropUploaded={preImportPayrollBalanceEmployee}
              handleTemplate={importTemplatePayrollBalanceEmployee}
              onHide={importPayrollBalanceEmployee}
              hideModal={() => setSimpleModalState(oldState => ({ ...oldState, show: false }))}
              updateData={() => setMoreData(oldValue => !oldValue)}
              handleExceptions={handleImportModalExceptions}
              params={{
                balance_type: 'asset',
                payroll_process_month: payrollProcess.month_number,
                company_id: currentCompany.id,
                payroll_process_id: payrollProcess.id
              }}
              service="payroll"
            />
          ),
          show: true
        });
        break;
      case 'import_discounts':
        setSimpleModalState({
          title: 'Importar Descuentos',
          body: (
            <ImportModal
              onDropUploaded={preImportPayrollBalanceEmployee}
              handleTemplate={importTemplatePayrollBalanceEmployee}
              onHide={importPayrollBalanceEmployee}
              hideModal={() => setSimpleModalState(oldState => ({ ...oldState, show: false }))}
              updateData={() => setMoreData(oldValue => !oldValue)}
              handleExceptions={handleImportModalExceptions}
              params={{
                balance_type: 'discount',
                payroll_process_month: payrollProcess.month_number,
                company_id: currentCompany.id,
                payroll_process_id: payrollProcess.id
              }}
              service="payroll"
            />
          ),
          show: true
        });
        break;
      default:
        break;
    }
  };

  const changeComponentTo = component => {
    setActiveComponent(component);
  };

  return (
    <>
      <Col />
      <Col md={2}>
        <Dropdown alignRight className="shift-dropdown">
          <Dropdown.Toggle className="dropdown-no-arrow w-100">
            Exportar
            <Icon style={{ marginLeft: 25 }} width={23} icon="chevron-down" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleExport('export_benefits')}>Haberes</Dropdown.Item>
            <Dropdown.Item onClick={() => handleExport('export_discounts')}>Descuentos</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      {!onlyShow && (
        <>
          <Col md={2}>
            <Dropdown alignRight className="shift-dropdown">
              <Dropdown.Toggle className="dropdown-no-arrow w-100">
                Importar
                <Icon style={{ marginLeft: 25 }} width={23} icon="chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleExport('import_benefits')}>Haberes</Dropdown.Item>
                <Dropdown.Item onClick={() => handleExport('import_discounts')}>Descuentos</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={2}>
            <Dropdown alignRight className="shift-dropdown">
              <Dropdown.Toggle className="dropdown-no-arrow w-100">
                Nuevo
                <Icon style={{ marginLeft: 25 }} width={23} icon="chevron-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setPayrollProcess(current => ({ ...current, balanceType: 'asset' }));
                    changeComponentTo('PayrollBalancesIndex');
                  }}
                >
                  {' '}
                  Haberes{' '}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setPayrollProcess(current => ({ ...current, balanceType: 'discount' }));
                    changeComponentTo('PayrollBalancesIndex');
                  }}
                >
                  {' '}
                  Descuentos{' '}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </>
      )}
    </>
  );
};

export default Actions;
