import { withFormik, Field, Form, getIn } from 'formik';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { BasicTextArea } from '../../../components';

const AttendanceChageRejectForm = ({ isSubmitting, isDisabled, errors, touched, ...props }) => {
  const { onHide } = props;

  return (
    <Form className="mx-3">
      <Row>
        <Col md={12}>
          <Field name="attendanceChangeRequest[reason]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Motivo del Rechazo"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-3 mb-2">
        <Col xs={6} sm={4}>
          <Button block type="submit" variant="primary" onClick={onHide} disabled={isSubmitting || isDisabled}>
            Rechazar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { replaceId } = props;
  return {
    attendanceChangeRequest: {
      id: replaceId,
      reason: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  attendanceChangeRequest: Yup.object().shape({
    reason: Yup.string().required('Debe indicar el motivo del rechazo')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(AttendanceChageRejectForm);
