import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon } from '../../../../components';

export default memoize((handleExport, payrollCurrentState) => [
  {
    name: 'PERIODO SELECCIONADO',
    selector: 'parsed_month',
    grow: '1',
    Cell: ({ row }) => <span>{row.original.parsed_month_year}</span>
  },
  {
    name: 'EMPLEADOS PROCESADOS',
    selector: 'employees_count',
    grow: '1'
  },
  {
    name: 'MONTO PROCESADO',
    selector: 'parsed_discount_total',
    grow: '1'
  },
  {
    name: 'ESTADO',
    grow: '1',
    cell: item => {
      switch (payrollCurrentState) {
        case 'associated':
          return (
            <span className="text-primary" data-tag="allowRowEvents">
              <b>Procesado</b>
            </span>
          );

        default:
          return <span className="text-muted">{item.process_status}</span>;
      }
    }
  },
  {
    name: 'DESCARGAR',
    cell: item => (
      <ButtonTooltip
        onClick={() => handleExport(item.payroll_process_id, item.id)}
        variant="circle-primary"
        toolbarVariant="mr-auto"
        className="btn-circle"
        size="sm"
        text=""
      >
        <Icon icon="download-2" />
      </ButtonTooltip>
    )
  }
]);
