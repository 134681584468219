import React from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import Header from './Header';
import './ProcessDetails.css';

export default function ProcessDetails({ reportData, handleDownload, disabled }) {
  const { assets, discounts, assetsTotal, discountsTotal, processName } = reportData.data;
  const { filterParams } = reportData;
  return (
    <>
      <Header reportName={reportData.reportName} processName={processName} {...filterParams} />
      <div className="info-box mt-4">
        <Row>
          <Col md={6}>
            <h3 className="text-uppercase mb-2">Haberes</h3>
            <Table className="conceptDetailsTable" responsive>
              <>
                <thead className="text-uppercase">
                  <tr>
                    <th className="text-left">Cuenta</th>
                    <th className="text-left">Concepto</th>
                    <th className="text-left">Monto</th>
                  </tr>
                </thead>
                <tbody>
                  {assets &&
                    assets.map(item => (
                      <tr key={item.name}>
                        <td className="text-left">{item.account}</td>
                        <td className="text-left">{item.name}</td>
                        <td className="text-left">{item.value}</td>
                      </tr>
                    ))}
                </tbody>
              </>
            </Table>
          </Col>
          <Col md={6}>
            <h3 className="text-uppercase mb-2">Descuentos</h3>
            <Table className="conceptDetailsTable" responsive>
              <>
                <thead className="text-uppercase">
                  <tr className="text-left">
                    <th>Cuenta</th>
                    <th>Concepto</th>
                    <th>Monto</th>
                  </tr>
                </thead>
                <tbody>
                  {discounts &&
                    discounts.map(item => (
                      <tr key={item.name}>
                        <td className="text-left">{item.account}</td>
                        <td className="text-left">{item.name}</td>
                        <td className="text-left">{item.value}</td>
                      </tr>
                    ))}
                </tbody>
              </>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Table className="conceptDetailsTable" responsive>
              <tbody>
                <tr>
                  <td colSpan={2} className="text-left">
                    {' '}
                    Total Haberes
                  </td>
                  <td>{assetsTotal}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md={6}>
            <Table className="conceptDetailsTable" responsive>
              <tbody>
                <tr>
                  <td colSpan={2} className="text-left">
                    {' '}
                    Total Descuentos
                  </td>
                  <td>{discountsTotal}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row className="d-flex justify-content-end mt-4 mb-4">
        <Col md={2}>
          <Button className="btn-block" type="submit" onClick={handleDownload} disabled={disabled}>
            Descargar Reporte
          </Button>
        </Col>
      </Row>
    </>
  );
}
