import React, { useState, useEffect } from 'react';
import { useFormikContext, Field, getIn } from 'formik';
import memoize from 'memoize-one';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, FormControl, Button } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { camelCaseEmptyStringRecursive, sortByAttribute } from '../../services/utils';
import { AbilityContext } from '../../config/abilityContext';
import { activeContractsRequest } from '../../requests/contracts';
import {
  FormikSelect,
  ButtonTooltip,
  Icon,
  ComponentDataTable,
  SimpleCenteredModal,
  EmployeeSearchModal,
  DatatableEmployeeName
} from '../../components';

const columns = memoize(handleClick => [
  {
    name: 'NOMBRE',
    selector: 'employee.fullName',
    cell: item => <DatatableEmployeeName item={item.employee} fileName="fileInfo" name="fullName" fileUrl="fileUrl" />,
    grow: '3',
    sortable: true
  },
  {
    name: 'JORNADA',
    selector: 'workSchedule',
    cell: item => (item.workSchedule ? <span>{item.workSchedule}</span> : <span>Sin Jornada</span>),
    grow: '3',
    sortable: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <ButtonTooltip
        onClick={() => handleClick(item, 'destroy')}
        variant="circle-danger"
        className="btn-circle"
        size="sm"
        text="Eliminar"
      >
        <Icon icon="trash" />
      </ButtonTooltip>
    ),
    sortable: false,
    grow: '1',
    right: true
  }
]);

const selectorTypes = [{ label: 'Todos', value: 'all_contracts' }];

const ShiftContractForm = ({ shift }) => {
  const ability = useAbility(AbilityContext);
  const [filterEmployees, setFilterEmployees] = useState([]);
  const [selector, setSelector] = useState(selectorTypes);
  const [modalShow, setModalShow] = useState(false);
  const [textInput, setTextInput] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const { values, errors, setFieldValue, setFieldTouched, touched } = useFormikContext();
  const dispatch = useDispatch();

  const successCallback = response => {
    const { contractIds } = shift;
    const contracts = camelCaseEmptyStringRecursive(response.data.data);
    const contractsToAdd = contracts.filter(selected => !contractIds.includes(selected.id));
    const vSelector = [...selectorTypes, ...contractsToAdd];
    setSelector(vSelector);
  };

  const fetchEmployees = () => {
    if (ability.can('read', 'Contract'))
      activeContractsRequest({
        dispatch,
        params: { paginate: false },
        successCallback
      });
  };

  const removeFromTable = (contract, action) => {
    if (action !== 'destroy') return;
    const { contracts } = values.shift;
    const notRemovedEmployees = contracts.filter(selected => selected.value !== contract.value);
    setSelector([...selector, contract]);
    setFieldValue('shift[contracts]', notRemovedEmployees);
    if (notRemovedEmployees.length === 0) setFieldValue('shift[contractIds]', ['']);
    else
      setFieldValue(
        'shift[contractIds]',
        notRemovedEmployees.map(item => item.id)
      );
  };

  const RemoveAllFromTable = () => {
    const selectedRowsIds = selectedRows.map(item => item.id);
    const notRemovedEmployees = values.shift.contracts.filter(selected => !selectedRowsIds.includes(selected.id));
    setSelector([...selectorTypes, ...selector, ...selectedRows].filter(selected => selected.label));
    setFieldValue('shift[contracts]', notRemovedEmployees);
    setFieldValue(
      'shift[contractIds]',
      notRemovedEmployees.map(item => item.id)
    );
    if (notRemovedEmployees.length === 0) setFieldValue('shift[contractIds]', ['']);
    setFilterEmployees(notRemovedEmployees);
    setSelectedCount(0);
    setSelectedRows([]);
  };

  const filterArray = (array, query, keys) => {
    if (query === '') return array;
    if (array.length === 0) return array;
    return array.filter(item => {
      let inQuery = false;
      keys.forEach(key => {
        if (item.employee[[key]]?.toLowerCase().includes(query.toLowerCase())) inQuery = true;
      });
      return inQuery;
    });
  };

  const filteredArray = () => {
    setFilterEmployees(filterArray(values.shift.contracts, textInput, ['fullName', 'nationalIdentification']));
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const addAllEmployees = () => {
    const { shift: shiftValues } = values;
    const vSelector = selector.filter(selected => selected.id !== undefined);
    const selectedEmployeeIds = vSelector.map(employee => employee.value);
    const selectedEmployees = vSelector.map(employee => ({ ...employee }));
    setSelector([selectorTypes]);
    setFieldValue('employeeSelector', '');
    setFieldValue('shift[contracts]', [...selectedEmployees, ...shiftValues.contracts]);
    setFilterEmployees([...selectedEmployees, ...shiftValues.contracts]);
    setFieldValue('shift[contractIds]', [...selectedEmployeeIds, ...shiftValues.contractIds]);
  };

  const handleSelector = () => {
    const vSelector = selector.filter(selected => selected.value !== values.employeeSelector);
    if (vSelector.length === selector.length) return;
    if (values.employeeSelector === 'all_contracts') {
      addAllEmployees();
    } else {
      const employeeToAdd = selector.find(selected => selected.value === values.employeeSelector);
      setFieldValue(`shift[contractIds]`, [...values.shift.contractIds, employeeToAdd.value]);
      setFieldValue('shift[contracts]', [...values.shift.contracts, employeeToAdd]);
      setFilterEmployees([...values.shift.contracts, employeeToAdd]);
      setSelector(vSelector);
    }
  };

  const handleSearch = selectedEmployees => {
    setModalShow(false);
    const vSelector = selector.filter(selected => !selectedEmployees.includes(selected.employee?.id));
    if (vSelector.length === selector.length) return;
    const contractsToAdd = selector.filter(selected => selectedEmployees.includes(selected.employee?.id));
    setFieldValue(`shift[contractIds]`, [...values.shift.contractIds, ...contractsToAdd.map(item => item.value)]);
    setFieldValue('shift[contracts]', [...values.shift.contracts, ...contractsToAdd]);
    setSelector(vSelector);
  };

  const subHeaderComponent = () => (
    <>
      <ButtonTooltip
        onClick={RemoveAllFromTable}
        variant="circle-danger"
        className="btn-circle"
        size="sm"
        text="Eliminar Seleccionados"
        disabled={selectedCount === 0}
      >
        <Icon icon="trash" />
      </ButtonTooltip>
      <Form.Group className="ml-auto w-25">
        <FormControl
          placeholder="Buscar"
          name="textInput"
          value={textInput}
          onChange={e => setTextInput(e.target.value)}
        />
      </Form.Group>
    </>
  );
  useEffect(fetchEmployees, [shift]);
  useEffect(filteredArray, [textInput, values.shift.contracts]);

  return ability.can('read', 'Contract') ? (
    <>
      <div className="info-box ml-0 mt-5">
        <h4 className="text-uppercase">Selecciona Trabajadores</h4>
        <p className="info">
          <span className="full-width">Seleccione trabajadores a los que se le asignará este turno</span>
        </p>
      </div>
      <Row>
        <Col md={5}>
          <Field name="employeeSelector">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label=""
                placeholder="Seleccionar Trabajador"
                options={sortByAttribute(selector, 'fullName')}
                defaultValue="all_contracts"
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={2} xl={2}>
          <Button variant="primary" onClick={handleSelector}>
            Agregar
          </Button>
        </Col>
        <Col className="ml-2">
          <ButtonTooltip
            variant="circle-primary"
            className="advance-search"
            toolbarVariant="mt-n3"
            text="Búsqueda avanzada de empleados mediante filtros de cargos, Lugares de Prestación de Servicios, otros"
            onClick={() => setModalShow(true)}
          >
            <Icon className="w-100 h-100" icon="people-circle" />
          </ButtonTooltip>
        </Col>
      </Row>
      <ComponentDataTable
        columns={columns(removeFromTable)}
        data={filterEmployees}
        onRequest={false}
        totalRows={filterEmployees.length}
        defaultSortField="fullName"
        pointerOnHover
        sortServer={false}
        subHeaderComponent={subHeaderComponent()}
        onSelectedRowsChange={handleSelectedRows}
        selectableRows
        paginationServer={false}
        pagination
      />
      <SimpleCenteredModal
        title="Buscar Empleados"
        body={
          <EmployeeSearchModal
            customParams={{ active: true }}
            handleClose={() => setModalShow(false)}
            formRequest={handleSearch}
          />
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  ) : (
    ''
  );
};

export default ShiftContractForm;
