import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import {
  BasicTooltip,
  FormikCheckBox,
  FormikDatePicker,
  FormikInput,
  FormikMaterialUiTimePicker,
  FormikNumber,
  Icon
} from '../../../components';
import './style.scss';

const ShiftRotative = ({ errors, touched, setFieldValue, shift, action, values }) => {
  const [activeDays, setActiveDays] = useState([]);

  const setInitialShiftDaysAttributes = () => {
    let vinitialActiveDays = [];
    if (action === 'new') {
      vinitialActiveDays = [...Array(7)].map(() => ({
        startTime: '09:00',
        endTime: '18:00',
        send: true,
        active: true
      }));
    } else {
      vinitialActiveDays = shift.shiftDaysAttributes.map(item => ({ ...item, send: true }));
    }
    setActiveDays(vinitialActiveDays);
    setFieldValue('shift[activeDays]', vinitialActiveDays.length);
  };

  const { collationTime, flexibleShift, agreedSchedule } = values.shift;

  useEffect(setInitialShiftDaysAttributes, [shift]);

  return (
    <Form>
      <Row>
        <Col md={6}>
          <Field name="shift[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                disabled
                label="Nombre"
                placeholder="NOMBRE DE TURNO"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={2}>
          <Field name="shift[parsedTolerance]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                disabled
                label="Tolerancia"
                rightAddon="min"
                placeholder="0"
                fieldName="shift[tolerance]"
                value={values.shift.tolerance}
                tooltipSize="21"
                tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={2} className="mt-4 d-flex align-items-center">
          <Field name="shift[workHolidays]">
            {({ field }) => (
              <FormikCheckBox
                {...field}
                custom
                disabled
                field={field}
                label="Trabaja festivos"
                tooltipSize="23"
                tooltipText="Turno con días festivos incluídos"
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={2} className="mt-4 d-flex align-items-center">
          <Field name="shift[flexibleShift]">
            {({ field }) => (
              <FormikCheckBox {...field} custom disabled field={field} label="Turno flexible" tooltipSize="23" />
            )}
          </Field>
        </Col>
        {flexibleShift && (
          <>
            <Col xs={4} md={2}>
              <Field name="shift[initialTime]">
                {({ field }) => (
                  <FormikMaterialUiTimePicker
                    {...field}
                    abbr
                    disabled
                    timeSelector
                    label="Hora de Inicio"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col xs={4} md={2}>
              <Field name="shift[maximumStartTime]">
                {({ field }) => (
                  <FormikMaterialUiTimePicker
                    {...field}
                    abbr
                    disabled
                    timeSelector
                    label="Tiempo Max. Inicio"
                    onChange={time => setFieldValue(field.name, time)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col xs={4} md={2}>
              <Field name="shift[workHours]">
                {({ field }) => (
                  <FormikMaterialUiTimePicker
                    {...field}
                    abbr
                    disabled
                    timeSelector
                    label="Horas Trabajo"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
      </Row>
      <Row className="mt-1">
        <Col xs={12} md={2} className="d-flex align-items-center">
          <Field name="shift[lunchCheck]">
            {({ field }) => (
              <FormikCheckBox
                {...field}
                custom
                disabled
                field={field}
                label="Marcar colación"
                tooltipSize="23"
                tooltipText="Indica si deben marcar el ingreso y salida de colación"
              />
            )}
          </Field>
        </Col>
        <Col xs={12} md={2} className="d-flex align-items-center">
          <Field name="shift[agreedSchedule]">
            {({ field }) => (
              <FormikCheckBox
                {...field}
                custom
                disabled
                field={field}
                label="Horario pactado"
                tooltipSize="23"
                tooltipText="Indica que deben marcar el ingreso y salida de colación en cada uno de los días seleccionados"
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={2} className="d-flex align-items-center">
          <Field name="shift[collationTime]">
            {({ field }) => (
              <FormikCheckBox {...field} custom disabled field={field} label="Tiempo de colación" tooltipSize="23" />
            )}
          </Field>
        </Col>
        {collationTime && (
          <>
            <Col xs={6} md={2} className="d-flex align-items-center">
              <Field name="shift[parsedLunchTime]">
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    abbr
                    rightAddon="min"
                    placeholder="0"
                    label="Colación"
                    fieldName="shift[lunchTime]"
                    value={values.shift.lunchTime}
                    tooltipSize="21"
                    tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    disabled
                  />
                )}
              </Field>
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row>
        <Col md={4} className="rotative-proportion-card">
          <Row>
            <Col md={12}>
              <p className="text-uppercase text-center">Días</p>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <Icon width={24} className="as-button mt-1" icon="remove" />
            </Col>
            <Col xs={8}>
              <Field name="shift[activeDays]" style={{ textAlign: 'center' }}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    disabled
                    label={false}
                    readOnly
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col xs={2}>
              <Icon width={24} className="as-button mt-1" icon="add" />
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <BasicTooltip text="Número de días totales en este turno rotativo">
            <Icon icon="help-circle" width={22} color="grey" />
          </BasicTooltip>
        </Col>
        <Col md={6}>
          <Field name="shift[startDate]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                disabled
                isOutsideRange={() => false}
                label="Fecha de Inicio"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      {activeDays.length > 0 && <h3 className="text-uppercase mt-4 mb-3">Días Rotativos</h3>}
      {agreedSchedule ? (
        <Row>
          {activeDays.map((item, index) =>
            item.active ? (
              <Col xs={5} sm={2} md={2} key={`day-${index + 1}`} className="shift-card-container-rotative mt-3">
                <Row>
                  <Col md={12}>
                    <Icon
                      className="text-primary"
                      style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                      width={18}
                      icon="checkmark-circle"
                    />
                    <p className="text-uppercase shift-card-title">Día {index + 1}</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="align-items-center" md={12}>
                    <p className="text-uppercase shift-card-label">Desde</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][startTime]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          defaultValue="09:00"
                          onChange={time => setFieldValue(field.name, time)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          disabled
                        />
                      )}
                    </Field>
                  </Col>
                  <Col md={12}>
                    <p className="text-uppercase shift-card-label">Hasta</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][endTime]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          defaultValue="18:00"
                          onChange={time => setFieldValue(field.name, time)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          disabled
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="text-uppercase shift-card-title">Colación</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="align-items-center" md={12}>
                    <p className="text-uppercase shift-card-label">Desde</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][startLunch]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          disabled
                          defaultValue="14:00"
                          onChange={time => setFieldValue(field.name, time)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col md={12}>
                    <p className="text-uppercase shift-card-label">Hasta</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][endLunch]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          disabled
                          defaultValue="15:00"
                          onChange={time => setFieldValue(field.name, time)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col
                key={`day-${activeDays.length + index + 1}`}
                xs={5}
                sm={2}
                md={2}
                className="shift-card-container-rotative-free mt-3"
              >
                <p className="text-uppercase shift-card-title text-center mt-4">Día {index + 1}</p>
                <p className="text-uppercase text-center mb-4">Libre</p>
              </Col>
            )
          )}
        </Row>
      ) : (
        <Row>
          {activeDays.map((item, index) =>
            item.active ? (
              <Col xs={5} sm={2} md={2} key={`day-${index + 1}`} className="shift-card-container-rotative mt-3">
                <Row>
                  <Col md={12}>
                    <Icon
                      className="text-primary"
                      style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                      width={18}
                      icon="checkmark-circle"
                    />
                    <p className="text-uppercase shift-card-title">Día {index + 1}</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3 align-items-center" md={12}>
                    <p className="text-uppercase shift-card-label">Desde</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][startTime]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          disabled
                          defaultValue="09:00"
                          onChange={time => setFieldValue(field.name, time)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3" md={12}>
                    <p className="text-uppercase shift-card-label">Hasta</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][endTime]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          disabled
                          defaultValue="18:00"
                          onChange={time => setFieldValue(field.name, time)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col
                key={`day-${activeDays.length + index + 1}`}
                xs={5}
                sm={2}
                md={2}
                className="shift-card-container-rotative-free mt-3"
              >
                <p className="text-uppercase shift-card-title text-center mt-4">Día {index + 1}</p>
                <p className="text-uppercase text-center mb-4">Libre</p>
              </Col>
            )
          )}
        </Row>
      )}
      <hr />
    </Form>
  );
};

const setInitialValues = props => {
  const { shiftDaysAttributes } = props.shift;
  let shiftDays = [];
  if (shiftDaysAttributes.length) {
    shiftDays = shiftDaysAttributes.map(item => ({ ...item, send: true }));
  } else {
    shiftDays = [...Array(7)].map(() => ({
      startTime: '09:00',
      endTime: '18:00',
      send: true,
      active: true
    }));
  }
  return {
    shift: {
      ...props.shift,
      shiftDaysAttributes: shiftDays,
      shiftType: 'rotative'
    }
  };
};

const validationSchema = Yup.object().shape({
  shift: Yup.object().shape({
    lunchCheck: Yup.boolean(),
    workHolidays: Yup.boolean(),
    tolerance: Yup.number()
      .required('Debes ingresar una tolerancia')
      .min(0, 'Debe ser mayor o igual a 0'),
    lunchTime: Yup.number()
      .required('Debes ingresar tiempo de colación')
      .min(0, 'Debe ser mayor o igual a 0'),
    name: Yup.string().required('Debes ingresar un nombre'),
    startDate: Yup.string().required('Debes ingresar fecha de inicio'),
    shiftDaysAttributes: Yup.array().of(
      Yup.object().shape({
        groups: Yup.array().notRequired(),
        endTime: Yup.string().when('active', {
          is: val => val,
          then: Yup.string()
            .required('Debes ingresar hora de término')
            .when(['startTime', 'endTime'], (startTime, endTime, schema) => {
              return (startTime > endTime && schema.min(endTime, 'Debe ser mayor que la hora de inicio')) || schema;
            }),
          otherwise: Yup.string().nullable()
        }),
        startTime: Yup.string().when('active', {
          is: val => val,
          then: Yup.string().required('Debes ingresar hora de inicio'),
          otherwise: Yup.string().nullable()
        }),
        send: Yup.boolean()
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ShiftRotative);
