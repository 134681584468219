import React, { useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { validateToken } from '../../requests/user';
import Logo from './logo.png';
import './style.scss';
import API_CONFIG from '../../config/configurations';

const { tokenDt } = API_CONFIG

const BrandFullScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchValidateToken = () => {
    if (tokenDt) {
      validateToken(tokenDt, {
        dispatch,
        successCallback: () => { history.push(`/auth/${tokenDt}`) },
        failureCallback: () => { history.push(`/login`) }
      });
    }
  };

  useEffect(fetchValidateToken, [])


  return (
    <>
      <Row className="align-items-center expand-view-height">
        <Col lg={12} md={12} xs={12} className="align-self-center">
          <Image src={Logo} fluid alt="peoplework logo" />
        </Col>
      </Row>
    </>
  )
};

export default BrandFullScreen;
