/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import camelCaseRecursive  from 'camelcase-keys-recursive';
import {
  debounceIndexWorkflowRequestsRequest,
  resendNotificationRequest,
  massiveSingWorkflowRequestRequest,
  massiveRejectWorkflowRequestRequest
} from '../../requests/workflowRequests';
import { sendAlert } from '../../actions/utils';
import { ComponentDataTable, DefaultModal, DocumentActions, SimpleCenteredModal } from '../../components';
import SignatureKeyForm from '../DocumentPreview/SignatureKeyForm';

import DocumentPreview from '../DocumentPreview/DocumentPreview';
import WorkflowRequestRejectMasiveForm from './WorkflowRequestRejectMasiveForm';

class WorkflowRequestDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 0,
      clearSelectedRows: false,
      defaultModalBody: '',
      defaultModalShow: false,
      defaultModalTitle: '',
      defaultModalConfirm: false,
      defaultModalHandleConfirm: () => null,
      documentModalShow: false,
      documentModalBody: '',
      moreData: false,
      onRequest: true,
      selectedRows: [],
      selectedCount: 0,
      signatureKey: '',
      workflowRequests: []
    };
  }

  refreshTable = () => {
    const { refreshDashboard } = this.props;
    const { moreData } = this.state;
    this.setState({ moreData: !moreData });
    if (refreshDashboard) refreshDashboard();
  };

  singWorkflowRequest = ({ signatureKey }, setSubmitting) => {
    const { dispatch } = this.props;
    const { selectedRows } = this.state;

    const elementsSummaryIds = selectedRows.map(element => element.id);

    massiveSingWorkflowRequestRequest({
      dispatch,
      params: {
        workflowsIds: elementsSummaryIds,
        signatureKey
      },
      successCallback: () => this.handleSuccessAction('Documento firmado con éxito'),
      failureCallback: err => this.handleErrorAction(err.response?.data?.message),
      callback: () => (typeof setSubmitting !== 'undefined' ? setSubmitting(false) : false)
    });
  };

  rejectWorkflowRequest = ({ rejectObservation }, setSubmitting) => {
    const { dispatch } = this.props;
    const { selectedRows } = this.state;

    const elementsSummaryIds = selectedRows.map(element => element.id);

    massiveRejectWorkflowRequestRequest({
      dispatch,
      params: {
        workflows_ids: elementsSummaryIds,
        observation: rejectObservation
      },
      successCallback: () => this.handleSuccessAction('Documento(s) rechazados con éxito'),
      failureCallback: err => this.handleErrorAction(err.response?.data?.message),
      callback: () => (typeof setSubmitting !== 'undefined' ? setSubmitting(false) : false)
    });
  };

  handleRequest = async params => {
    const { dispatch, customFilter } = this.props;
    this.setState({ onRequest: true });

    debounceIndexWorkflowRequestsRequest({
      dispatch,
      params: {
        sort_column: 'created_at',
        type: [
          'contract',
          'contract_annex',
          'other_document',
          'personal_protection',
          'induction_risk_prevention',
          'acceptance_riohs',
          'certificate',
          'reprimand_document',
          'report_occupational_hazards',
          'congratulation_letter',
          'overtime_agreements',
          'salary_settlement'
        ],
        ...customFilter,
        ...params
      },
      successCallback: this.handleSuccessIndex
    });
  };

  handleSuccessIndex = response => {
    const workflowRequests = response.data.data;
    const { amount } = response.data.metadata;
    this.setState({
      workflowRequests,
      amount,
      onRequest: false
    });
  };

  resendNotification = async item => {
    const { dispatch } = this.props;
    this.setState({ onRequest: true });
    resendNotificationRequest(item.id, {
      dispatch,
      successCallback: () => {
        this.refreshTable();
        dispatch(sendAlert({ kind: 'success', message: 'Recordatorio enviado con éxito.' }));
      }
    });
  };

  handleButtonClick = (item, action) => {
    const { currentTab } = this.props;
    if (action === 'resend') {
      this.resendNotification(item);
      return;
    }

    this.setState({
      documentModalShow: true,
      documentModalBody: (
        <DocumentPreview
          documentId={item.document?.document_id}
          employeeId={item.document?.employee_id}
          currentTab={currentTab}
        />
      )
    });
  };

  handleSelectedRows = item => {
    this.setState({
      selectedRows: item.selectedRows,
      selectedCount: item.selectedCount
    });
  };

  handleDefaultModalClose = () => {
    this.setState({
      defaultModalShow: false
    });
  };

  handleDocumentModalClose = () => {
    this.refreshTable();

    this.setState(prevState => ({
      clearSelectedRows: !prevState.clearSelectedRows,
      documentModalShow: false,
      selectedCount: 0,
      selectedRows: []
    }));
  };

  hasPendingAnnexes = () => {
    const { selectedRows } = this.state;
    return selectedRows.filter(
      row =>
        row.workflow_type === 'contract_annex' &&
        row.document?.document_id &&
        row.pending_annexes?.[0]?.document_id !== row.document.document_id
    );
  };

  handleMassAction = action => {
    const { selectedRows, signatureKey } = this.state;

    const elementsSummary = selectedRows.map(element => {
      return {
        id: element.id,
        actionType: element.employee_request_reviewer.signature ? 'signature' : 'approval'
      };
    });

    const esFirma = elementsSummary.some(element => element.actionType === 'signature');

    const hasPendingAnnexesModal = (
      <div>
        <p>Los siguientes trabajadores tienen anexos de contratos pendientes:</p>
        <br />
        <ul>
          {[...new Set(this.hasPendingAnnexes().map(item => item.document_employee_name.full_name))].map(
            (name, index) => (
              <li key={index.id}>{name}</li>
            )
          )}
        </ul>
        <p>¿Estás seguro que deseas continuar?</p>
      </div>
    );

    switch (action) {
      case 'massAprove':
        if (this.hasPendingAnnexes().length > 0) {
          this.setState(() => {
            return {
              defaultModalTitle: 'Validacion de Firma / Aprobación Masiva',
              defaultModalBody: hasPendingAnnexesModal,
              defaultModalShow: true,
              defaultModalConfirm: true,
              defaultModalHandleConfirm: () => {
                this.setState({ defaultModalConfirm: false });

                if (esFirma) {
                  this.setState(() => {
                    return {
                      defaultModalTitle: 'Firma / Aprobación Masiva',
                      defaultModalBody: (
                        <SignatureKeyForm
                          formRequest={this.singWorkflowRequest}
                          onHide={this.handleDefaultModalClose}
                          signatureKey={signatureKey}
                        />
                      ),
                      defaultModalShow: true
                    };
                  });
                } else {
                  this.setState(() => {
                    return {
                      defaultModalTitle: 'Firma / Aprobación Masiva',
                      defaultModalBody: `¿Estas seguro de que deseas Aprobar / Firmar ${selectedRows.length} elementos ? `,
                      defaultModalConfirm: true,
                      defaultModalHandleConfirm: () => this.singWorkflowRequest({ signatureKey: '' }),
                      defaultModalShow: true
                    };
                  });
                }
              }
            };
          });
          break;
        }
        if (esFirma) {
          this.setState({ defaultModalConfirm: false });
          this.setState(() => {
            return {
              defaultModalTitle: 'Firma / Aprobación Masiva',
              defaultModalBody: (
                <SignatureKeyForm
                  formRequest={this.singWorkflowRequest}
                  onHide={this.handleDefaultModalClose}
                  signatureKey={signatureKey}
                />
              ),
              defaultModalShow: true
            };
          });
        } else {
          this.setState(() => {
            return {
              defaultModalTitle: 'Firma / Aprobación Masiva',
              defaultModalBody: `¿Estas seguro de que deseas Aprobar / Firmar ${selectedRows.length} elementos ? `,
              defaultModalConfirm: true,
              defaultModalHandleConfirm: () => this.singWorkflowRequest({ signatureKey: '' }),
              defaultModalShow: true
            };
          });
        }
        break;
      case 'massReject':
        this.setState(() => {
          return {
            defaultModalTitle: 'Rechazo Masivo',
            defaultModalBody: (
              <WorkflowRequestRejectMasiveForm
                formRequest={this.rejectWorkflowRequest}
                handleModalClose={this.handleDefaultModalClose}
              />
            ),
            defaultModalConfirm: false,
            defaultModalShow: true
          };
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  handleSuccessAction = message => {
    const { dispatch } = this.props;
    dispatch(sendAlert({ kind: 'success', message }));

    this.refreshTable();
    this.handleDefaultModalClose();

    this.setState(prevState => ({
      clearSelectedRows: !prevState.clearSelectedRows,
      selectedCount: 0,
      selectedRows: []
    }));
  };

  handleErrorAction = message => {
    const { dispatch } = this.props;
    dispatch(sendAlert({ kind: 'error', message }));
  };

  render() {
    const {
      amount,
      clearSelectedRows,
      defaultModalConfirm,
      defaultModalBody,
      defaultModalShow,
      defaultModalTitle,
      documentModalShow,
      documentModalBody,
      moreData,
      onRequest,
      selectedCount,
      workflowRequests,
      defaultModalHandleConfirm
    } = this.state;
    const { columns, withMassActions, customSpinnerClass } = this.props;
    return (
      <>
        <ComponentDataTable
          onRequest={onRequest}
          columns={columns(this.handleButtonClick)}
          data={workflowRequests}
          totalRows={amount}
          moreData={moreData}
          resourceRequest={this.handleRequest}
          onRowClicked={item => this.handleButtonClick(item, 'show')}
          pointerOnHover
          selectableRows={withMassActions}
          clearSelectedRows={clearSelectedRows}
          onSelectedRowsChange={this.handleSelectedRows}
          withMassActions={withMassActions}
          customSpinnerClass={customSpinnerClass}
          massActions={
            <>
              <DocumentActions handleClick={this.handleMassAction} disabled={!selectedCount || onRequest} />
            </>
          }
        />
        <DefaultModal
          backdrop="static"
          body={defaultModalBody}
          handleClose={this.handleDefaultModalClose}
          handleConfirm={defaultModalHandleConfirm}
          show={defaultModalShow}
          title={defaultModalTitle}
          withClose={false}
          withConfirm={defaultModalConfirm}
        />
        <SimpleCenteredModal
          backdrop="static"
          body={documentModalBody}
          show={documentModalShow}
          size="xl"
          onHide={this.handleDocumentModalClose}
        />
      </>
    );
  }
}

export default connect()(WorkflowRequestDataTable);
