import React from 'react';

const DataProcessInfo = data => {
  const { item } = data;

  return (
    <div className="info-box">
      <table className="table table-borderless">
        <tbody>
          <tr>
            <td>
              <span>Origen:</span>
            </td>
            <td>{item.source_type}</td>
          </tr>
          <tr>
            <td>
              <span>Concepto:</span>
            </td>
            <td>{item.balance_name || 'advance_payments'}</td>
          </tr>
          <tr>
            <td>
              <span>Monto:</span>
            </td>
            <td>{item.parsed_amount}</td>
          </tr>
          <tr>
            <td>
              <span>Opción de pago:</span>
            </td>
            <td>{item.translate_payment_type}</td>
          </tr>
          <tr>
            <td>
              <span>Mes de Inicio:</span>
            </td>
            <td>{item.start_date}</td>
          </tr>
          <tr>
            <td>
              <span>Mes de Término:</span>
            </td>
            <td>{item.end_date}</td>
          </tr>
        </tbody>
      </table>
      <hr />
    </div>
  );
};

export default DataProcessInfo;
