import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexMarkingTypesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/marking_types', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexMarkingTypesRequest = AwesomeDebouncePromise(indexMarkingTypesRequest, 300);
