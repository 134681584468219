import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Form, Row, Col, Button } from 'react-bootstrap';

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

const videoConstraints = {
  facingMode: FACING_MODE_USER
};

const CustomWebcam = ({ error, touched, ...props }) => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

  const handleClick = React.useCallback(() => {
    setFacingMode(prevState => (prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER));
  }, []);

  function base64ImageToBlob(str) {
    // extract content type and base64 payload from original string
    const pos = str.indexOf(';base64,');
    const type = str.substring(5, pos);
    const b64 = str.substr(pos + 8);

    // decode base64
    const imageContent = atob(b64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (let n = 0; n < imageContent.length; n += 1) {
      view[n] = imageContent.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    const blob = new Blob([buffer], { type });

    const file = new File([blob], 'picture1.jpeg', { type });

    return file;
  }

  const capture = () => {
    const { onChange } = props;
    const imageSrc = webcamRef.current.getScreenshot({});
    setImgSrc(imageSrc);
    const imagefile = base64ImageToBlob(imageSrc);
    onChange(imagefile);
  };

  const retake = () => {
    setImgSrc(null);
  };

  return (
    <>
      <Row className="justify-content-center align-items-center h-100 mb-2">
        <Col>
          {imgSrc ? (
            <img src={imgSrc} alt="webcam" />
          ) : (
            <Webcam
              height={200}
              width={200}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                ...videoConstraints,
                facingMode
              }}
            />
          )}
        </Col>
      </Row>
      <Row className="h-100 mb-2">
        <Col>
          {imgSrc ? (
            <Button className="btn" onClick={retake}>
              Retomar Foto
            </Button>
          ) : (
            <>
              <Button className="btn" onClick={capture}>
                Tomar Foto
              </Button>
              <Button className="btn ml-1" onClick={handleClick}>
                Cambiar Camara
              </Button>
            </>
          )}
        </Col>
        {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      </Row>
    </>
  );
};

CustomWebcam.defaultProps = {
  onChange: () => {},
  inputFileId: 'inputGroupFile01'
};

export default CustomWebcam;
