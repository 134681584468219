import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import Filters from './Filters';
import { indexApiPayrollRequest, payrollElectronicRemunerationBook } from '../../../requests/payrollProcesses';
import { downloadFile } from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import { DefaultModal } from '../../../components';
import ModalErrors from './ModalErrors';

const formatValue = {
  0: 'xlsx',
  1: 'text',
  2: 'csv'
};

function DTElectronic() {
  // eslint-disable-next-line
  const [filters, setFilters] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const { currentCompany } = useSelector(state => state.auth);
  const [modalState, setModalState] = useState({
    show: false,
    title: '',
    body: ''
  });

  const exportDTelectronic = async (payrollProcess, params) => {
    if (payrollProcess) {
      setOnRequest(true);
      payrollElectronicRemunerationBook(payrollProcess.id, {
        dispatch,
        params: {
          company_id: currentCompany.id,
          ...params
        },
        format: formatValue[params.format],
        successCallback: response => downloadFile(response),
        callback: () => setOnRequest(false),
        failureCallback: error => {
          if (error.response && error.response?.data?.errors) {
            setModalState({
              show: true,
              title: 'Errores en informacion de empleados',
              body: <ModalErrors errors={error.response?.data?.errors} />
            });
            return;
          }
          dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
        }
      });
    }
  };

  const handleRequest = async params => {
    const month = moment(params.monthDate).format('MM') - 1;
    const year = moment(params.yearDate).format('YYYY');
    const request = async () =>
      indexApiPayrollRequest({
        dispatch,
        service: 'payroll',
        params: {
          filter_month: month,
          year,
          filter_by_company: currentCompany.id
        },
        format: 'JSON',
        successCallback: response => {
          setOnRequest(false);
          /* onTable */
          const payrollProcess = response?.data?.data[0];
          if (response?.data?.data.length === 0) {
            dispatch(sendAlert({ kind: 'error', message: 'No existe un proceso de nomina con la fecha indicada' }));
            return;
          }
          exportDTelectronic(payrollProcess, { ...params, monthDate: month, yearDate: year });
        }
      });
    request();
  };

  const handleFilters = async params => {
    setFilters(params);
    setOnRequest(true);
    handleRequest(params);
  };

  return (
    <>
      <Filters formRequest={handleFilters} onRequest={onRequest} />
      <div className="position-relative">
        {onRequest && (
          <div className="containerSpinnerLoad position-absolute h-100 w-100">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        <DefaultModal
          show={modalState.show}
          title={modalState.title}
          body={modalState.body}
          handleClose={() => setModalState({ ...modalState, show: false })}
          withClose
          withConfirm={false}
        />
      </div>
    </>
  );
}

export default DTElectronic;
