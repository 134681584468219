export default function RegionsColor(region) {
  if (region.includes(' Arica')) {
    return '#3498db';
  }
  if (region.includes(' Tarapacá')) {
    return '#e74c3c';
  }
  if (region.includes(' Antofagasta')) {
    return '#f39c12';
  }
  if (region.includes(' Atacama')) {
    return '#9b59b6';
  }
  if (region.includes(' Coquimbo')) {
    return '#1abc9c';
  }
  if (region.includes(' Valparaíso')) {
    return '#e67e22';
  }
  if (region.includes(' Metropolitana')) {
    return '#16a085';
  }
  if (region.includes(" O'Higgins")) {
    return '#27ae60';
  }
  if (region.includes(' Maule')) {
    return '#c0392b';
  }
  if (region.includes(' Ñuble')) {
    return '#8e44ad';
  }
  if (region.includes(' Biobío')) {
    return '#00fa53';
  }
  if (region.includes(' Araucanía')) {
    return '#e74c3c';
  }
  if (region.includes(' Biobío')) {
    return '#4cedcd';
  }
  if (region.includes(' Ríos')) {
    return '#86f0cb';
  }
  if (region.includes(' Lagos')) {
    return '#ff6b6b';
  }
  if (region.includes(' Aysén')) {
    return '#fca877';
  }
  if (region.includes(' Magallanes')) {
    return '#c6ff6b';
  }

  return 'Sin definir';
}
