import React from 'react';

function Errors({ errorLabels = [], ...props }) {
  return (
    <ul className="list-two-columns" {...props}>
      {errorLabels.map(error => {
        return (
          <li key={error} type="disc" className="font-weight-light text-danger">
            <span className="text-dark">{error}</span>
          </li>
        );
      })}
    </ul>
  );
}

export default Errors;
