import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showBalanceRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/balances/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexBalancesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/balances', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const indexConceptRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/balances/view_concept', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createBalanceRequest = ({ dispatch, params, formData, successCallback, callback }) => {
  ApiService.request('post', '/balances', {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });
};

export const updateBalanceRequest = (id, { dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('put', `/balances/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const deleteBalanceRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/balances/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const massiveDestroyBalanceRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', '/balances/massive_destroy', {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const exportBalancesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/balances/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const preImportBalanceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/balances/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importBalanceRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/balances/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateBalanceRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/balances/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const indexContractBalancesRequest = (id, { dispatch, params = {}, successCallback, callback }) => {
  ApiService.request('get', `/balances/${id}/contract_balances`, {
    dispatch,
    params,
    successCallback,
    callback
  });
};

export const activeBalancesRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/balances/massive_active', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const deactivateBalancesRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', '/balances/massive_deactivate', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });

export const indexConceptLreRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/balances/concept_lres', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexBalancesRequest = AwesomeDebouncePromise(indexBalancesRequest, 300);
export const debounceIndexConceptRequest = AwesomeDebouncePromise(indexConceptRequest, 300);
export const debounceIndexConceptLreRequest = AwesomeDebouncePromise(indexConceptLreRequest, 300);
