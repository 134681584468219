import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { SimpleCenteredModal } from '../../components';
import { updateCustomFieldRequest } from '../../requests/customFields';
import { sendAlert } from '../../actions/utils';
import CustomFieldDataTable from './CustomFieldDataTable';
import CustomFieldForm from './CustomFieldForm';
import CustomFieldShow from './CustomFieldShow';
import { camelCaseEmptyStringRecursive } from '../../services/utils';

const CustomFieldIndex = ({ moreData, setMoreData }) => {
  const [modalTitle, setModalTitle] = useState('Nuevo Campo');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const dispatch = useDispatch();

  const handleSuccess = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setModalShow(false);
    setMoreData(!moreData);
  };

  const handleFailure = error => {
    const {
      response: { data }
    } = error;
    dispatch(sendAlert({ kind: 'error', message: data.message }));
  };

  const handleUpdate = customFieldValue => {
    const {
      customField: { id: customFieldId }
    } = customFieldValue;
    updateCustomFieldRequest(customFieldId, {
      dispatch,
      params: snakeCaseKeys(customFieldValue),
      formData: true,
      successCallback: () => {
        handleSuccess('Campo actualizado con éxito');
      },
      failureCallback: error => handleFailure(error)
    });
  };

  const editCustomField = customFieldRow => {
    setModalShow(true);
    setModalBody(
      <CustomFieldForm
        customField={camelCaseEmptyStringRecursive(customFieldRow)}
        action="edit"
        formRequest={handleUpdate}
        handleModalClose={() => setModalShow(false)}
      />
    );
    setModalTitle('Editar campo');
  };

  const showCustomField = customFieldRow => {
    setModalShow(true);
    setModalBody(
      <CustomFieldShow customField={camelCaseRecursive(customFieldRow)} hideModal={() => setModalShow(false)} />
    );
    setModalTitle(customFieldRow.name);
  };
  return (
    <>
      <CustomFieldDataTable
        editCustomField={editCustomField}
        moreData={moreData}
        setMoreData={setMoreData}
        showCustomField={showCustomField}
      />
      <SimpleCenteredModal title={modalTitle} backdrop="static" closeButton={false} body={modalBody} show={modalShow} />
    </>
  );
};

export default CustomFieldIndex;
