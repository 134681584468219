import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Row, Button, Accordion, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ComponentDataTable, DefaultModal, Icon, ImportModal, SimpleCenteredModal } from '../../../components';
import AttendanceMovementsFilter from './AttendanceMovementsFilter';
import './style.scss';
import ImportAttendanceMovements from './ImportAttendanceMovements';
import columns from './Columns';
import {
  changeStatusAttendanceMovementsRequest,
  createAttendanceMovementsRequest,
  debounceIndexAttendanceMovementsRequest,
  destroyAttendanceMovementsRequest,
  exportAttendanceMovementsRequest,
  importAttendanceMovementRequest,
  importTemplateAttendanceMovements,
  massiveDestroyAttendanceMovementsRequest,
  preImportAttendanceMovementRequest
} from '../../../requests/attendanceMovements';
import { indexAbsenceRequest } from '../../../requests/absences';
import { sendAlert } from '../../../actions/utils';
import { showPayrollProcessRequest } from '../../../requests/payrollProcesses';
import { downloadFile } from '../../../services/utils';
import AttendanceMovementsMassiveActions from '../../../components/DatatableActions/AttendanceMovementsMassiveActions';
import AttendanceMovementsEdit from './AttendanceMovementsEdit';
import { legalAttendanceReport } from '../../../requests/legalreports';
import { indexPermissionsRequest } from '../../../requests/permissions';

const AttendanceMovementsIndex = props => {
  const { id } = props;
  const { currentCompany } = useSelector(state => state.auth);
  const { companyModules } = currentCompany;
  const { onlyShow } = props;
  const attendanceManagement = companyModules.includes('attendance_management');
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [simpleModalBody, setSimpleModalBody] = useState({});
  const [simpleModalTitle, setSimpleModalTitle] = useState('');
  const [modalItem, setModalItem] = useState([]);
  const [option, setOption] = useState('');
  const [absences, setAbsences] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [modalShow, setModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [filters, setFilters] = useState({});
  const [size, setSize] = useState('lg');
  const dispatch = useDispatch();
  const [payrollDates, setPayrollDates] = useState([]);
  const [payrollProcess, setPayrollProcess] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState('');
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [defaultModalTitle, setDefaultModalTitle] = useState('');
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const title = attendanceManagement ? 'Importar Asistencia - Control del Tiempo' : 'Importador de Permisos sin goce';

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const handleShowRequest = () => {
    showPayrollProcessRequest(id, {
      dispatch,
      service: 'payroll',
      successCallback: res => {
        setPayrollProcess(res.data);
        setPayrollDates([res.data.start_date, res.data.end_date]);
      }
    });
  };

  const handleModalClose = () => {
    setSimpleModalShow(false);
    setModalShow(false);
  };

  const handleSuccessImport = () => {
    setSimpleModalShow(false);
    setMoreData(!moreData);
    setOnRequest(false);
    dispatch(sendAlert({ kind: 'success', message: 'Ausencias importadas correctamente' }));
  };

  const handleRequest = params => {
    setOnRequest(true);
    debounceIndexAttendanceMovementsRequest({
      dispatch,
      params: {
        payroll_process: id,
        ...params
      },
      successCallback: response => {
        setAbsences(response.data.data);
        setAmount(response.data.metadata.amount);
      },
      callback: () => {
        setOnRequest(false);
      }
    });
  };

  const handleExport = () => {
    setOnRequest(true);
    exportAttendanceMovementsRequest({
      dispatch,
      params: {
        payroll_process: id,
        ...filters
      },
      successCallback: response => {
        downloadFile(response);
        setOnRequest(false);
        setModalShow(false);
      }
    });
  };

  const handleFilter = async params => {
    setFilters(params);
    setClearSelectedRows(!clearSelectedRows);
    setMoreData(!moreData);
  };

  const handleFailureImport = error => {
    const { response } = error;
    const message = response?.data?.message;
    setSimpleModalShow(false);
    setMoreData(!moreData);
    setSimpleModalShow(false);
    setDefaultModalTitle('Mov. Asistencias ya existen');
    setDefaultModalBody(message);
    setDefaultModalShow(true);
  };

  const permissionRequest = async params => {
    return new Promise(resolve => {
      const myParams = params;
      if (myParams.filter_type.length === 0 || myParams.filter_type.includes('without_pay')) {
        delete myParams.date_from;
        delete myParams.date_to;
        indexPermissionsRequest({
          dispatch,
          params: {
            ...myParams,
            filter_permission_type: 'without_pay',
            filter_for_days: true,
            status: 'approved',
            paginate: false
          },
          successCallback: response => {
            const { arrEmployees } = params;
            const { data } = response.data;
            const permissions = [];
            data.forEach(permission => {
              const employee = arrEmployees.find(emp => emp.id === permission.employee.id);
              const startDate = moment(permission.start_date, 'DD/MM/YYYY');
              const endDate = moment(permission.end_date, 'DD/MM/YYYY');
              permissions.push({
                employee: { ...employee },
                absence_type: 'without_pay',
                start_date: permission.start_date,
                end_date: permission.end_date,
                days: endDate.diff(startDate, 'days') + 1,
                status: 'approved'
              });
            });
            resolve(permissions);
          }
        });
      } else {
        resolve([]);
      }
    });
  };

  const absencesRequest = async params => {
    const myParams = params.filter_type.includes('without_pay')
      ? { ...params, filter_type: params.filter_type.filter(type => type !== 'without_pay') }
      : { ...params };
    return new Promise(resolve => {
      if (!(params.filter_type.length === 1 && params.filter_type.includes('without_pay'))) {
        delete myParams.date_from;
        delete myParams.date_to;
        indexAbsenceRequest({
          dispatch,
          params: {
            ...myParams,
            only_license: true,
            paginate: false
          },
          successCallback: response => {
            const { arrEmployees } = params;
            const { data } = response.data;
            const dataArray = Array.isArray(data) ? data : [data];
            const arrAbsence = dataArray.map(absence => {
              const employee = arrEmployees.find(emp => emp.id === absence.employee.id);
              return { ...absence, employee: { ...employee } };
            });
            resolve(arrAbsence);
          }
        });
      } else {
        resolve([]);
      }
    });
  };

  const unjustifiedAbsences = async (params, format, responseType) => {
    return new Promise(resolve => {
      if (params.filter_type.length === 0 || params.filter_type.includes('unjustified_absence')) {
        legalAttendanceReport({
          dispatch,
          params: { ...params, paginate: false },
          format,
          responseType,
          successCallback: res => {
            const { arrEmployees } = params;
            const result = [];
            res.data.forEach(employeeData => {
              const employee = arrEmployees.find(emp => emp.national_identification === employeeData.employee_rut);

              employeeData.records.forEach((record, index, arr) => {
                if (record.justification === 'Ausencia Injustificada') {
                  if (index === 0 || (index > 0 && arr[index - 1].justification !== 'Ausencia Injustificada')) {
                    result.push({
                      employee: { ...employee },
                      start_date: moment(record.date, 'DD/MM/YY').format('DD/MM/YYYY'),
                      absence_type: 'unjustified_absence'
                    });
                  }
                  if (
                    index === arr.length - 1 ||
                    (index < arr.length - 1 && arr[index + 1].justification !== 'Ausencia Injustificada')
                  ) {
                    const currentGroup = result[result.length - 1];
                    currentGroup.end_date = moment(record.date, 'DD/MM/YY').format('DD/MM/YYYY');
                    const startDate = moment(currentGroup.start_date, 'DD/MM/YYYY');
                    const endDate = moment(currentGroup.end_date, 'DD/MM/YYYY');
                    const days = endDate.diff(startDate, 'days') + 1;
                    currentGroup.days = days;
                  }
                }
              });
            });
            resolve(result);
          }
        });
      } else {
        resolve([]);
      }
    });
  };

  const request = async (params, format = '.print', responseType = 'json') => {
    try {
      const arrPermission = await permissionRequest(params);
      if (attendanceManagement) {
        const arrAbsence = await absencesRequest(params);
        const legalAbsences = await unjustifiedAbsences(params, format, responseType);
        return [...arrAbsence, ...legalAbsences, ...arrPermission];
      }
      return [...arrPermission];
    } catch (error) {
      throw error;
    }
  };

  const handleImportAbsences = async (filter, setSubmitting) => {
    setOnRequest(true);
    try {
      const data = await request(filter);

      if (data && data.length > 0) {
        const attendanceMovementsValues = data.map(absence => ({
          ...absence,
          payroll_process_id: id,
          date_from: filter.date_range[0],
          date_to: filter.date_range[1]
        }));

        createAttendanceMovementsRequest({
          dispatch,
          params: { attendance_movements: attendanceMovementsValues },
          successCallback: handleSuccessImport,
          failureCallback: error => handleFailureImport(error),
          callback: () => {
            setOnRequest(false);
            setSubmitting(false);
          }
        });
      } else {
        dispatch(sendAlert({ kind: 'error', message: 'No se encontraron ausencias para importar' }));
        setOnRequest(false);
        setSubmitting(false);
      }
    } catch (error) {
      if (error) {
        dispatch(sendAlert({ kind: 'error', message: 'No se logró realizar la importación' }));
        setOnRequest(false);
        setSubmitting(false);
      }
    }
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleImport = options => {
    switch (options) {
      case 'attendance':
        setSimpleModalTitle(title);
        setSimpleModalBody(
          <ImportAttendanceMovements
            formRequest={handleImportAbsences}
            payroll={payrollProcess}
            withAttendance={attendanceManagement}
          />
        );
        setSimpleModalShow(true);
        setSize('lg');
        break;
      case 'data-loads':
        setSimpleModalTitle('Importar Asistencia - Sin Control del Tiempo');
        setSimpleModalBody(
          <ImportModal
            onDropUploaded={preImportAttendanceMovementRequest}
            handleTemplate={importTemplateAttendanceMovements}
            onHide={importAttendanceMovementRequest}
            hideModal={() => setSimpleModalShow(false)}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
            params={{
              payroll_process_id: id,
              active_contracts: true,
              active: true,
              company_id: currentCompany.id,
              filter_identification_type: 'rut',
              filter_termination_date_month: `${payrollProcess.month_number}-${payrollProcess.year}`,
              paginate: false
            }}
            service="payroll"
          />
        );
        setSimpleModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const handleChangeStatus = (item, action) => {
    setOnRequest(true);
    changeStatusAttendanceMovementsRequest({
      dispatch,
      params: {
        ids: item.id,
        option: action
      },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Movimiento actualizado correctamente' }));
        handleModalClose();
      },
      callback: () => {
        setMoreData(!moreData);
        setOnRequest(false);
      }
    });
  };

  const handleMassiveChangeStatus = action => {
    const attendanceMovementIds = selectedRows.map(movement => movement.id);
    setClearSelectedRows(true);
    changeStatusAttendanceMovementsRequest({
      dispatch,
      params: {
        ids: attendanceMovementIds,
        option: action
      },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Movimientos actualizados correctamente' }));
      },
      callback: () => {
        setModalShow(false);
        setMoreData(!moreData);
        setClearSelectedRows(false);
      }
    });
  };

  const handleDestroyAttendanceMovements = item => {
    setOnRequest(true);
    destroyAttendanceMovementsRequest(item.id, {
      dispatch,
      successCallback: () => {
        setModalShow(false);
        setMoreData(!moreData);
        dispatch(sendAlert({ kind: 'success', message: 'Movimiento eliminado correctamente' }));
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleMassiveDestroyAttendanceMovements = () => {
    const attendanceMovementIds = selectedRows.map(movement => movement.id);
    setClearSelectedRows(true);
    setOnRequest(true);
    massiveDestroyAttendanceMovementsRequest({
      dispatch,
      params: {
        ids: attendanceMovementIds
      },
      successCallback: () => {
        setModalShow(false);
        setMoreData(!moreData);
        dispatch(sendAlert({ kind: 'success', message: 'Movimiento eliminado correctamente' }));
        setClearSelectedRows(false);
      },
      callback: () => {
        setOnRequest(false);
      }
    });
  };

  const handleModalConfirm = (item, action) => {
    handleChangeStatus(item, action);
  };

  const handleModalChangeStatus = (_, action) => {
    handleMassiveChangeStatus(action);
  };

  const handleModalDeleteConfirm = item => {
    handleDestroyAttendanceMovements(item);
  };

  const handleButtonClick = (item, action) => {
    setModalItem([]);
    switch (action) {
      case 'edit':
        setSimpleModalTitle('Editar Movimiento de Asistencia');
        setSimpleModalBody(
          <AttendanceMovementsEdit
            id={item.id}
            moreData={moreData}
            setMoreData={setMoreData}
            hideModal={() => setSimpleModalShow(false)}
            payrollDates={payrollDates}
          />
        );
        setSimpleModalShow(true);
        setSize('lg');
        break;
      case 'approved':
        setModalTitle('Aprobar Movimiento de asistencia');
        setModalShow(true);
        setModalItem(item);
        setOption(action);
        setModalBody('¿Estás seguro que deseas aprobar el movimiento de asistencia?');
        setModalAction(() => handleModalConfirm);
        break;
      case 'undo':
        setModalTitle('Deshacer Movimiento de asistencia');
        setModalShow(true);
        setModalItem(item);
        setOption(action);
        setModalBody('¿Estás seguro que deseas deshacer el estado del movimiento de asistencia?');
        setModalAction(() => handleModalConfirm);
        break;
      case 'cancel':
        setModalTitle('Anular Movimiento de asistencia');
        setModalShow(true);
        setModalItem(item);
        setModalBody('¿Estás seguro que deseas anular el movimiento de asistencia?');
        setModalAction(() => handleModalDeleteConfirm);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleMassAction = action => {
    setClearSelectedRows(true);
    switch (action) {
      case 'approved':
        setModalTitle('Aprobar Movimientos de asistencia');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas aprobar los movimiento de asistencia seleccionados?');
        setOption(action);
        setModalAction(() => handleModalChangeStatus);
        break;
      case 'undo':
        setModalTitle('Deshacer Movimientos de asistencia');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas deshacer los movimiento de asistencia seleccionados?');
        setOption(action);
        setModalAction(() => handleModalChangeStatus);
        break;
      case 'destroy':
        setModalTitle('Anular Movimientos de asistencia');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas anular los movimiento de asistencia seleccionados?');
        setModalAction(() => handleMassiveDestroyAttendanceMovements);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const downloadModal = () => {
    setModalTitle('Exportar Movimiento de asistencia');
    setModalShow(true);
    setModalBody('¿Estás seguro que deseas descargar los movimientos de asistencia?');
    setModalAction(() => handleExport);
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'cost_center':
        return { sort_cost_center: name };
      case 'translated_absence_type':
        return { sort_column: 'absence_type' };
      default:
        return { sort_column: name };
    }
  };

  useEffect(handleShowRequest, []);

  return (
    <>
      <Col md={12}>
        <Row className="d-flex justify-content-end">
          <Col md={2} className="mt-2 mt-md-3">
            <Button variant="primary" block onClick={() => downloadModal()}>
              Exportar
            </Button>
          </Col>
          {!onlyShow && (
            <>
              <Col md={2}>
                <Dropdown alignRight className="attendance-movement-dropdown">
                  <Dropdown.Toggle className="dropdown-no-arrow mt-3 w-100">
                    Importar
                    <Icon style={{ marginLeft: 25 }} width={23} icon="chevron-down" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleImport('attendance')}>
                      {attendanceManagement ? 'Control del Tiempo' : 'Importador de Permisos'}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleImport('data-loads')} disabled={attendanceManagement}>
                      Importador de Datos
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col md={2} className="mt-2 mt-md-3">
                <Button
                  variant="info"
                  block
                  to={`/attendance_movements/new?returnTo=remuneration_processes&id=${id}`}
                  as={attendanceManagement ? 'button' : Link}
                  disabled={attendanceManagement}
                >
                  Nuevo
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Col>
      <div className="position-relative">
        <Accordion defaultActiveKey="1" className="ml-2 mt-2 mr-2">
          <AttendanceMovementsFilter formRequest={handleFilter} />
        </Accordion>
        <ComponentDataTable
          onRequest={onRequest}
          columns={columns(handleButtonClick, attendanceManagement, onlyShow)}
          data={absences}
          totalRows={amount}
          moreData={moreData}
          handleSortCase={sortColumnCase}
          resourceRequest={params => {
            handleRequest({ ...params, ...filters });
          }}
          withSearch={false}
          withMassActions
          massActions={
            <>
              {!onlyShow && (
                <div>
                  <AttendanceMovementsMassiveActions disabled={!selectedCount} handleClick={handleMassAction} />
                </div>
              )}
            </>
          }
          clearSelectedRows={clearSelectedRows}
          selectableRows={!onlyShow}
          selectedRowCount={selectedRows.selectedCount}
          onSelectedRowsChange={handleSelectedRows}
        />
      </div>
      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        size={size}
        show={simpleModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem, option)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
      <DefaultModal
        title={defaultModalTitle}
        body={defaultModalBody}
        show={defaultModalShow}
        handleClose={() => setDefaultModalShow(false)}
        withConfirm={false}
        variantBtnSave="light"
        titleBtnClose="Cerrar"
      />
    </>
  );
};

export default AttendanceMovementsIndex;
