import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Row, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';
import { Icon } from '../..';
import LinkBtn from '../../Utils/Button/LinkBtn';
import { createWebAttendanceRequest } from '../../../requests/attendances';
import DashboardAttendance from '../../../screens/Profile/Dashboard/DashboardAttendance';
import { SimpleCenteredModal } from '../../Utils/Modal';
import { sendAlert } from '../../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { profileRegisterRequest } from '../../../requests/dashboards';
import { AbilityContext } from '../../../config/abilityContext';
import { showEmployeesRequest } from '../../../requests/employees';

const AttendanceData = ({ attendances, currentEmployee }) => {
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const canCreate = ability.can('create', 'Attendance');
  const [modalShow, setModalShow] = useState(false);
  const [todayRegisterCard, setTodayRegisterCard] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const lastAttendance = todayRegisterCard[todayRegisterCard.length - 1];
  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessRegisterCard = response => {
    const { data } = response;
    setTodayRegisterCard(camelCaseEmptyStringRecursive(data.attendances));
  };

  const fetchEmployeeCards = () => {
    if (currentEmployee) {
      profileRegisterRequest({
        dispatch,
        successCallback: handleSuccessRegisterCard
      });
    }
  };

  const fetchBranchOfficesEmployee = () => {
    if (currentEmployee) {
      showEmployeesRequest(currentEmployee, {
        dispatch,
        successCallback: response => {
          setBranchOffices(response.data.contract.branch_offices || []);
        }
      });
    }
  };

  useEffect(fetchBranchOfficesEmployee, [currentEmployee]);

  useEffect(fetchEmployeeCards, [currentEmployee]);

  const handleSuccessCreate = () => {
    const message = 'Asistencia creada con éxito';
    dispatch(sendAlert({ kind: 'success', message }));
    fetchEmployeeCards();
    handleModalClose();
  };

  const handleFailureRequest = error => {
    const message = error.response?.data?.message;
    dispatch(sendAlert({ kind: 'error', message }));
  };

  const handleCreate = attendanceValues => {
    const params = snakeCaseKeys(attendanceValues);
    createWebAttendanceRequest({
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <>
      <Card className="mb-1">
        <Col className="top-header-orange">
          <Col className="card-header-title">ÚLTIMO REGISTRO ASISTENCIA</Col>
        </Col>
        <Col className="div-content">
          <Row>
            <Col sm={6} xs={6} className="mt-2 ml-3 text-uppercase">
              <b>Tipo:</b>
              {lastAttendance ? (
                <p key={`attendance-type-${lastAttendance.id}`} className="mb-3">
                  {lastAttendance.translatedTimeType}
                </p>
              ) : (
                <p>Sin registros...</p>
              )}
            </Col>
            <Col sm={5} xs={5} className="mt-2 text-right text-uppercase">
              <b>Hora:</b>
              {lastAttendance ? (
                <p key={`attendance-time-${lastAttendance.id}`} className="mb-3">
                  {lastAttendance.parsedTime}
                </p>
              ) : (
                <p>--:--</p>
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={1} />
            <Col sm={10} md={12} className="mb-2">
              {canCreate && (
                <Button variant="warning-dash" size="sm" block onClick={() => setModalShow(true)}>
                  <Icon icon="clock2" className="icon-15" /> Marca tu Asistencia
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={6} className="mt-1" />
            <Col sm={6} md={6} className="mb-2 mt-1">
              <LinkBtn variant="light-gray" block to="/profile/attendances" size="sm">
                Ver más
              </LinkBtn>
            </Col>
          </Row>
        </Col>
      </Card>
      <SimpleCenteredModal
        title={`Marcar Asistencia ${moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}`}
        body={
          <DashboardAttendance
            handleModalClose={handleModalClose}
            formRequest={handleCreate}
            attendances={attendances}
            branchOffices={branchOffices}
          />
        }
        show={modalShow}
        size="xl"
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default AttendanceData;
