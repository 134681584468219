import React, { useState } from 'react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { DatatableEmployeeName, DefaultModal, Reminder } from '../../../components';
import ApproveRejectStyledActions from '../../../components/DatatableActions/ApproveRejectStyledActions';
import { sendAlert } from '../../../actions/utils';
import AdvancePaymentEdit from './AdvancePaymentEdit';
import { delayMethod } from '../../../services/utils';
import WorkflowRequestReject from '../../WorkflowRequest/WorkflowRequestReject';
import { rejectWorkflowRequestRequest } from '../../../requests/workflowRequests';
import { deleteAdvancePaymentRequest } from '../../../requests/advancePayments';
import ApprovementStatusStyled from '../../../components/DatatableColumns/ApprovementStatusStyled';

const AdvancePaymentInfoTable = props => {
  const { currentEmployee, item, moreData, setModalShow, setMoreData } = props;
  const {
    createdAt,
    endDate,
    name,
    parsedAmount,
    permanent,
    reason,
    startDate,
    status,
    translatedPaymentType
  } = camelCaseRecursive(item);
  const dispatch = useDispatch();
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [defaultModalButtons, setDefaultModalButtons] = useState(true);
  const [defaultModalTitle, setDefaultModalTitle] = useState('');
  const [defaultModalAction, setDefaultModalAction] = useState(() => null);
  const [modalItem, setDefaultModalItem] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const [isDisabled, setIsDisable] = useState(false);
  const [reminderShow, setReminderShow] = useState(false);
  const [reminderItem, setReminderItem] = useState({});

  const handleModalClose = () => {
    setModalShow(false);
    setDefaultModalShow(false);
    setDefaultModalTitle('');
    setDefaultModalBody('');
    setOnRequest(false);
  };

  const handleSuccessAction = message => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleApproveModal = () => {
    setDefaultModalTitle(item.requestReviewerSignature ? 'Firmar Documento' : 'Aprobar Solicitud');
    setDefaultModalBody(
      <AdvancePaymentEdit
        item={item}
        reviewerSignature={item.requestReviewerSignature}
        handleSuccessAction={handleSuccessAction}
        cancelAction={() => delayMethod(() => setDefaultModalShow(false))}
        employee={currentEmployee}
      />
    );
    setDefaultModalItem(item);
    setDefaultModalShow(true);
    setDefaultModalButtons(false);
  };

  const rejectDocument = values => {
    const {
      workflowRequest: { id }
    } = values;
    setOnRequest(true);
    rejectWorkflowRequestRequest(id, {
      params: snakeCaseKeys(values),
      dispatch,
      successCallback: () => handleSuccessAction('Solicitud rechazada con éxito'),
      callback: () => setOnRequest(false)
    });
  };

  const handleRejectModal = () => {
    setDefaultModalTitle('Rechazar Solicitud');
    setDefaultModalBody(
      <WorkflowRequestReject
        workflowRequest={item.workflowRequestId}
        cancelAction={() => delayMethod(() => setDefaultModalShow(false))}
        formRequest={rejectDocument}
      />
    );
    setDefaultModalItem(item);
    setDefaultModalShow(true);
    setDefaultModalButtons(false);
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud eliminada correctamente' }));
    setMoreData(!moreData);
  };

  const removeAdvancePayment = () => {
    setOnRequest(true);
    deleteAdvancePaymentRequest(item.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const handleDeleteModal = () => {
    setIsDisable(false);
    setOnRequest(false);
    setDefaultModalTitle('Eliminar Solicitud');
    setDefaultModalShow(true);
    setDefaultModalBody('¿Estás seguro que deseas eliminar esta solicitud?');
    setDefaultModalAction(() => removeAdvancePayment);
    setDefaultModalItem(item);
  };

  const handleActions = action => {
    switch (action) {
      case 'approved':
        handleApproveModal(camelCaseRecursive(item));
        break;
      case 'rejected':
        handleRejectModal(camelCaseRecursive(item));
        break;
      case 'resend':
        setReminderShow(true);
        setReminderItem(snakeCaseKeys(item));
        break;
      case 'destroy':
        handleDeleteModal(camelCaseRecursive(item));
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  return (
    <>
      <Modal.Body>
        <div className="info-box">
          <Row>
            <Col md={2} lg={2}>
              <ApprovementStatusStyled margin="mt-1" status={status} />
            </Col>
            <Col md={8} lg={8} className="title-pw">
              <b>Gestionar Anticipo</b>
            </Col>
            <Col md={2} lg={2} className="title-pw" />
          </Row>
          <table className="simple-table">
            <tbody>
              <tr>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Solicita</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Nombre del Anticipo</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Fecha de Solicitud</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Monto</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Opción de Pago</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Mes de Inicio</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Mes de Término</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Motivo del Anticipo</th>
              </tr>
              <tr>
                <td style={{ width: '150px' }}>
                  <div bis_skin_checked="1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div aria-hidden="true" bis_skin_checked="1" className="employee-info" />
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <DatatableEmployeeName
                        item={item.employee}
                        fileName="fileInfo"
                        name="shortName"
                        fileUrl="fileUrl"
                      />
                    </span>
                  </div>
                </td>
                <td align="center">{name}</td>
                <td align="center">{createdAt}</td>
                <td align="center" style={{ width: '70px' }}>
                  {parsedAmount}
                </td>
                <td align="center">{translatedPaymentType}</td>
                <td align="center">{startDate}</td>
                <td align="center">{permanent ? 'Permanente' : endDate}</td>
                <td align="center">{reason || '--'}</td>
              </tr>
            </tbody>
          </table>
          <br />

          <Row>
            <Col>
              {item.documents.length > 0 && (
                <>
                  <h4>Documentos asociados</h4>
                  {item.documents.map(document => (
                    <div className="info" key={`advance-payment-document-${document.id}`}>
                      <a
                        target="_blank"
                        className="font-weight-bold underline text-primary"
                        rel="noopener noreferrer"
                        href={`/document_preview/${document.id}?employee=${item.employee?.id}`}
                      >
                        {document.name}
                      </a>
                    </div>
                  ))}
                </>
              )}
            </Col>
          </Row>

          <Row>
            <Col className="text-center">
              <ApproveRejectStyledActions
                resource="AdvancePayment"
                item={item}
                clickHandler={handleActions}
                approveText={item.requestReviewerSignature ? 'Firmar / Aprobar' : 'Aprobar'}
                withDestroy
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <DefaultModal
        body={defaultModalBody}
        disabled={onRequest || isDisabled}
        handleClose={handleModalClose}
        handleConfirm={() => defaultModalAction(modalItem)}
        show={defaultModalShow}
        title={defaultModalTitle}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose={defaultModalButtons}
        withConfirm={defaultModalButtons}
      />
      <Reminder
        item={reminderItem}
        show={reminderShow}
        modalShow={setReminderShow}
        moreData={moreData}
        setOnRequest={setOnRequest}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default AdvancePaymentInfoTable;
