import React from 'react';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { delayMethod } from '../../../services/utils';

const CreateEmailAccountModal = (props) => {
  const { handleModalClose } = props;
  return (
    <>
      <Modal.Body>
        <Row style={{
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Col md={12} xl={8} style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem'
          }}>
            <Button as="a"
              href="https://accounts.google.com/signup/v2/webcreateaccount?service=mail&hl=es&continue=http%3A%2F%2Fmail.google.com%2Fmail%2F%3Fpc%3Dtopnav-about-es&flowName=GlifWebSignIn&flowEntry=SignUp#__utma=29003808.565777831.1675787822.1675787822.1675787822.1&__utmb=29003808.0.10.1675787822&__utmc=29003808&__utmx=-&__utmz=29003808.1675787822.1.1.utmcsr=google%7Cutmccn=(organic)%7Cutmcmd=organic%7Cutmctr=(not%20provided)&__utmv=-&__utmk=17665274"
              target="_blank"
            >
              Gmail
            </Button>

            <Button
              href="https://login.yahoo.com/account/create?lang=es-ES&src=ym&done=https%3A%2F%2Fmail.yahoo.com%2F%3F.intl%3Des%26.lang%3Des-ES%26guce_referrer%3DaHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8%26guce_referrer_sig%3DAQAAAD7xHgxlAPXCEvlN8vwZnIB5JOG9MX5wFjbtJtXnKNTMHnSa1jJhZdsDFSp9ygRx1xzUDXYED6bisjC9sxBSNHmjEbs8Ys9QcERhwEmaQs8MY7beWAT7FfZmH71Mj3b8KlhASDhUivFnNvWfaAJ5-sw9CEuVvegwL76PJM9gCdaz&specId=yidregsimplified"
              target="_blank"
            > Yahoo </Button>

            <Button
              href="https://signup.live.com/signup?wa=wsignin1.0&rpsnv=13&ct=1675787642&rver=7.0.6737.0&wp=MBI_SSL&wreply=https%3a%2f%2foutlook.live.com%2fowa%2f%3fRpsCsrfState%3dd2a13e34-a955-e8ac-03d7-3af90223cd5e&id=292841&aadredir=1&CBCXT=out&lw=1&fl=dob%2cflname%2cwld&cobrandid=90015&ru=https://outlook.live.com/owa/%3fRpsCsrfState%3dd2a13e34-a955-e8ac-03d7-3af90223cd5e&contextid=8187740366C79334&bk=1675787653&uiflavor=web&lic=1&mkt=ES-ES&lc=3082&uaid=93e05ef1d3164354bd1c0cc988db9740"
              target="_blank"
            > Outlook</Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose, 100)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </>);
};

export default CreateEmailAccountModal;