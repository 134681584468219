import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useDispatch } from 'react-redux';
import { advanceSettingAdvancePaymentsRequest } from '../../../requests/advanceSettings';
import Icon from '../../Icons';

const PendingRequests = ({ cards, handleMoreInfoButton, showSpinner }) => {
  const dispatch = useDispatch();
  const [hasTabsRequest, setHasTabsRequest] = useState(1);
  const pendingCard = hasTabsRequest > 0 ? cards?.cardBoss?.totalRequestsBossCount : 0;

  const fetchAdvancePayments = () => {
    advanceSettingAdvancePaymentsRequest({
      dispatch,
      params: {
        codes: [
          'legal_holiday',
          'progressive_holiday',
          'additional_days',
          'paid_leave',
          'without_paid_leave',
          'administrative_days',
          'shift_change',
          'loan_request',
          'advance_payment_request'
        ]
      },
      successCallback: response => {
        setHasTabsRequest(response.data.filter(setting => setting.text_value !== 'false').length);
      }
    });
  };

  useEffect(fetchAdvancePayments, []);

  return (
    <Card className="mb-1">
      <Col className="top-header-green">
        <Col className="card-header-title">SOLICITUDES PENDIENTES</Col>
      </Col>
      {showSpinner ? (
        <Col className="mt-5">
          <div className="containerSpinnerLoadDash mb-5">
            <Spinner animation="border" variant="primary" />
          </div>
        </Col>
      ) : (
        <Col className="div-content">
          <Row style={{ height: 140 }}>
            <Col sm={12} md={4} xs={12} className="mb-2 mt-2 circular-progress">
              <div style={{ width: 100, height: 100 }}>
                <CircularProgressbar
                  value={pendingCard}
                  text={`${pendingCard}`}
                  styles={buildStyles({
                    textSize: '26px',
                    pathColor: '#29B48F',
                    textColor: '#29B48F'
                  })}
                />
              </div>
            </Col>
            {hasTabsRequest > 0 ? (
              <Col sm={12} md={8} xs={12} className="mb-3 mt-4">
                <p className="text-dark-bold text-center" style={{ paddingTop: 30 }}>
                  Tienes {pendingCard} solicitudes pendientes por Aprobar
                </p>
              </Col>
            ) : (
              <p className="text-dark-bold text-center" style={{ marginTop: '50px' }}>
                No tienes solicitudes asociadas
              </p>
            )}
          </Row>
          {
            <button
              type="button"
              onClick={handleMoreInfoButton}
              style={{
                border: '1px solid #65b68f',
                backgroundColor: '#65b68f',
                position: 'absolute',
                right: 20,
                bottom: 20
              }}
            >
              <Icon icon="search" height="25px" width="16px" color="white" />
            </button>
          }
        </Col>
      )}
    </Card>
  );
};

export default PendingRequests;
