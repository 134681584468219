import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../Icons';
import { SimpleCenteredModal } from '../Modal';

const FormikCheckBox = ({
  delayShow,
  delayHide,
  direction,
  disabled,
  field,
  label,
  margin,
  tooltipClass,
  tooltipIcon,
  tooltipSize,
  tooltipText,
  tooltipVariant,
  toolbarVariant,
  labelTop,
  ...props
}) => {
  const [tooltipModalShow, setTooltipModalShow] = useState(false);

  const handleTooltipModalClose = () => {
    setTooltipModalShow(false);
  };

  const handelTooltipIconClick = () => {
    setTooltipModalShow(true);
  };

  return (
    <>
      <Form.Group className={`${!labelTop ? 'd-flex' : ''} align-items-center ${margin} ${disabled ? 'disabled' : ''}`}>
        {labelTop && <Form.Label>{label}</Form.Label>}
        <Form.Check
          {...field}
          custom
          type="checkbox"
          checked={field.value}
          label={!labelTop ? label : ''}
          id={field.name}
          disabled={disabled}
          {...props}
        />
        {tooltipText && tooltipVariant === 'regular' && (
          <Form.Label>
            <ButtonToolbar className={tooltipClass}>
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                <Icon icon={tooltipIcon} width={tooltipSize} />
              </OverlayTrigger>
            </ButtonToolbar>
          </Form.Label>
        )}
        {tooltipText && tooltipVariant === 'modal' && (
          <Form.Label>
            <Icon
              className="ml-1"
              icon={tooltipIcon}
              style={{ cursor: 'pointer' }}
              width={tooltipSize}
              onClick={handelTooltipIconClick}
            />
          </Form.Label>
        )}
      </Form.Group>

      {tooltipText && tooltipVariant === 'modal' && (
        <SimpleCenteredModal
          title={label}
          body={tooltipText}
          show={tooltipModalShow}
          onHide={handleTooltipModalClose}
        />
      )}
    </>
  );
};

FormikCheckBox.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  margin: PropTypes.string,
  toolbarVariant: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipIcon: PropTypes.string,
  tooltipSize: PropTypes.string,
  tooltipClass: PropTypes.string,
  tooltipVariant: PropTypes.string,
  labelTop: PropTypes.bool
};

FormikCheckBox.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  margin: '',
  toolbarVariant: '',
  tooltipText: '',
  tooltipIcon: 'help-circle',
  tooltipSize: '15',
  tooltipClass: 'ml-2 mt-1',
  tooltipVariant: 'regular',
  labelTop: false
};
export default FormikCheckBox;
