import React from 'react';
import memoize from 'memoize-one';
import { Icon, ApprovementStatus, ButtonTooltip } from '../../components';

const columns = memoize((clickHandler, permissions = false, isMobile) => {
  if (isMobile) {
    return [
      {
        name: 'FECHA DE CREACIÓN',
        selector: 'created_at',
        cell: item => item.parsed_created_at || '-',
        sortable: true,
        grow: '1',
        center: true
      },
      {
        name: 'MES',
        selector: 'month',
        cell: item => item.parsed_month || '-',
        sortable: true,
        grow: '1'
      },
      {
        name: 'MONTO A DESCONTAR',
        selector: 'parsed_discount_total',
        sortable: false,
        grow: '1',
        center: true
      }
    ];
  }
  return [
    {
      name: 'FECHA DE CREACIÓN',
      selector: 'created_at',
      cell: item => item.parsed_created_at || '-',
      sortable: true,
      grow: '1',
      center: true
    },
    {
      name: 'MES',
      selector: 'month',
      cell: item => item.parsed_month || '-',
      sortable: true,
      grow: '1'
    },
    {
      name: 'MONTO A DESCONTAR',
      selector: 'parsed_discount_total',
      sortable: false,
      grow: '1',
      center: true
    },
    {
      name: 'TRABAJADORES',
      selector: 'sort_employees_count',
      cell: item => item.employees_count,
      sortable: false,
      grow: '1',
      center: true
    },
    {
      name: 'MONTO POSTERGADO',
      selector: 'parsed_deferred_total',
      cell: item => item.parsed_deferred_total || '-',
      sortable: false,
      grow: '1',
      center: true
    },
    {
      name: 'ESTADO',
      selector: 'status',
      cell: item => (
        <ApprovementStatus
          status={item.status}
          translatedStatus={item.parsed_status}
          margin="my-0"
          onClick={() => clickHandler(item, 'show')}
        />
      ),
      sortable: true,
      grow: '1',
      center: true
    },
    {
      name: 'ACCIONES',
      cell: item => (
        <>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'show')}
            variant="circle-info"
            className="btn-circle"
            size="sm"
            text="Ver"
          >
            <Icon icon="eye" />
          </ButtonTooltip>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'edit')}
            variant="circle-warning"
            className="btn-circle"
            size="sm"
            text="Ver"
            disabled={item.status === 'closed' || !permissions.manage}
          >
            <Icon icon="pencil" />
          </ButtonTooltip>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'destroy')}
            variant="circle-danger"
            className="btn-circle"
            size="sm"
            text="Eliminar"
            disabled={item.status === 'closed' || !permissions.manage}
          >
            <Icon icon="trash" />
          </ButtonTooltip>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'close')}
            variant="circle-dark"
            className="btn-circle"
            size="sm"
            text="Cerrar"
            disabled={item.status === 'closed' || !permissions.manage}
          >
            <Icon icon="lock" />
          </ButtonTooltip>
        </>
      ),
      ignoreRowClick: true,
      grow: '1',
      minWidth: '140px',
      center: true
    }
  ];
});

export default columns;
