import React, { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakecaseKeys from 'snakecase-keys';
import { useDispatch } from 'react-redux';
import { camelCaseEmptyStringRecursive } from '../../../../services/utils';
import ApprovementStatusStyled from '../../../../components/DatatableColumns/ApprovementStatusStyled';
import { DefaultModal } from '../../../../components';
import { approvedShiftChangeRequest, rejectShiftChangeRequest } from '../../../../requests/shiftChange';
import { sendAlert } from '../../../../actions/utils';
import ShiftChangeStyledActions from '../../../../components/DatatableActions/ShiftChangeStyledActions';
import ShiftChageRejectForm from '../ShiftChageRejectForm';

const ItemsInfoStyled = props => {
  const dispatch = useDispatch();
  const { item, rrhh, setModalShow, setMoreData, moreData } = camelCaseEmptyStringRecursive(props);
  const [modalItem, setDefaultModalItem] = useState({});
  const [defaultModalTitle, setDefaultModalTitle] = useState('');
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [defaultModalButtons, setDefaultModalButtons] = useState(true);
  const [onRequest, setOnRequest] = useState(true);
  const [isDisabled, setIsDisable] = useState(false);
  const [defaultModalAction, setDefaultModalAction] = useState(() => null);

  const handleModalClose = () => {
    setModalShow(false);
    setDefaultModalShow(false);
    setDefaultModalTitle('');
    setDefaultModalBody('');
    setOnRequest(false);
  };

  const handleSuccessApprove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud de cambio de turno aprobada correctamente' }));
    setMoreData(!moreData);
  };

  const handleSuccessReject = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud de cambio de turno rechazada correctamente' }));
    setMoreData(!moreData);
  };

  const approvedShiftChange = () => {
    setIsDisable(false);
    setOnRequest(true);
    approvedShiftChangeRequest(item.id, {
      dispatch,
      successCallback: handleSuccessApprove,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const rejectShiftChange = params => {
    setOnRequest(true);
    const { id } = params.shiftChangeRequest;
    rejectShiftChangeRequest(id, {
      dispatch,
      params: snakecaseKeys(params),
      successCallback: handleSuccessReject,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setIsDisable(false);
      }
    });
  };

  const handleApproveModal = () => {
    setOnRequest(false);
    setIsDisable(false);
    setDefaultModalTitle('Aprobar Cambio de Turno');
    setDefaultModalShow(true);
    setDefaultModalBody('¿Estás seguro que deseas aprobar la solicitud de cambio de turno?');
    setDefaultModalItem(item);
    setDefaultModalAction(() => approvedShiftChange);
    setDefaultModalButtons(true);
  };

  const handleRejectModal = () => {
    setDefaultModalTitle('Rechazar Solicitud');
    setDefaultModalBody(
      <ShiftChageRejectForm shiftChange={item} formRequest={rejectShiftChange} handleModalClose={handleModalClose} />
    );
    setDefaultModalItem(item);
    setDefaultModalShow(true);
    setDefaultModalButtons(false);
  };

  const handleActions = action => {
    switch (action) {
      case 'approved':
        handleApproveModal(camelCaseRecursive(item));
        break;
      case 'rejected':
        handleRejectModal(camelCaseRecursive(item));
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  return (
    <>
      <Modal.Body>
        <div className="info-box">
          <Row>
            <Col md={2} lg={2}>
              <ApprovementStatusStyled status={item.status} margin="mb-1" />
            </Col>
            <Col md={8} lg={8} className="title-pw">
              <b>Gestionar Cambio de Turno</b>
            </Col>
            <Col md={2} lg={2} className="title-pw" />
          </Row>
          <table className="simple-table">
            <tbody>
              <tr>
                {rrhh && <th style={{ textAlign: 'center', paddingTop: '25px' }}>Solicitante</th>}
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Horario</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Fecha Solicitud</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Fecha de Reemplazo</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Reemplazar con</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Horario</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Motivo</th>
                {item.status === 'rejected' && (
                  <th style={{ textAlign: 'center', paddingTop: '25px' }}>Motivo del Rechazo</th>
                )}
              </tr>
              <tr>
                {rrhh && <td align="center">{item.employee.fullName}</td>}

                <td align="center">{`${item.shift.name} ${item.shift.scheduleType}`}</td>
                <td align="center">{item.createdAt}</td>
                <td align="center">{item.replacementDate}</td>
                <td align="center">{item.employeeReplacement.fullName}</td>
                <td align="center">{`${item.shiftReplacement.name} ${item.shiftReplacement.scheduleType}`}</td>
                <td align="center">{item.reason}</td>
                {item.status === 'rejected' && <td align="center">{item.observation}</td>}
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Row>
        <Col className="text-center">
          <ShiftChangeStyledActions
            resource="ShiftChangeRequest"
            item={item}
            clickHandler={handleActions}
            approveText="Aprobar"
          />
        </Col>
      </Row>

      <DefaultModal
        body={defaultModalBody}
        disabled={onRequest || isDisabled}
        handleClose={handleModalClose}
        handleConfirm={() => defaultModalAction(modalItem)}
        show={defaultModalShow}
        title={defaultModalTitle}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose={defaultModalButtons}
        withConfirm={defaultModalButtons}
      />
    </>
  );
};

export default ItemsInfoStyled;
