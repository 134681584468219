import React from 'react';
import { Table } from 'react-bootstrap';
import { DatatableEmployeeName, FormikNumber } from '../../../../components';

const PayrollBalancesEmployeesTable = ({ employees, setFieldValue, errors, touched }) => {
  const balances = employees[0]?.balances ? employees[0]?.balances : [];
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>NOMBRE DEL TRABAJADOR</th>
          {balances.map((balance, index) => {
            return <th key={`balance-${index.toString()}`}>{balance?.label?.toUpperCase()}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {employees?.map((employee, index) => {
          return (
            <tr key={`employee-${index.toString()}`}>
              <td className="employee-name sticky-column">
                <DatatableEmployeeName item={employee} fileName="fileInfo" name="fullName" fileUrl="fileUrl" startDateContract={employee.startDateContract} />
              </td>
              {employee?.balances?.map((_balance, indexBalance) => {
                return (
                  <td key={`employee-balance-${indexBalance.toString()}`}>
                    <FormikNumber
                      abbr
                      leftAddon="$"
                      placeholder="0"
                      fieldName={`payrollBalance[employees][${index}][balances][${indexBalance}][amount]`}
                      setFieldValue={setFieldValue}
                      value={employee?.balances[indexBalance]?.amount}
                      errors={errors}
                      touched={touched}
                      margin="mb-0"
                    />
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default PayrollBalancesEmployeesTable;
