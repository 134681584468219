export default [
  {
    label:'Permanente',
    value:'permanent'
  },
  {
    label: 'Plazo Fijo',
    value: 'fixed_term'
  },
  {
    label: 'Definido',
    value: 'defined'
  }
]