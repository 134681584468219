import React from 'react';
import DOMPurify from 'dompurify';
import './styles.scss';

const selectedStyle = {
  backgroundColor: '#FF0000',
  color: '#FFFFFF'
};

const styleArticle22 = {
  backgroundColor: '#FFA07A',
  color: '#515151'
};

const styleNotWorking = {
  backgroundColor: '#F3C342',
  color: '#515151'
};

const styleHasReplaced = {
  backgroundColor: '#B0E0E6',
  color: '#515151'
};


const renderResult = (isArticle22, attendance, employeeId) => {
  let styles = null;
  if (attendance.non_working_day_assis) {
    styles = styleNotWorking;
  }
  if (attendance.attendance_incomplete) {
    styles = selectedStyle;
  }
  if (isArticle22) {
    styles = styleArticle22;
  }
  if (attendance.has_replaced || (employeeId === 2570 && attendance.date_formatted === '17/05/23')) {
    styles = styleHasReplaced;
  }

  return (
    <tr style={styles}>
      <td className="text-center align-middle">{attendance.date_formatted}</td>
      <td className="text-center align-middle">{attendance.shift}</td>
      <td className="text-center align-middle">{attendance.attendance_day}</td>
      <td className="text-center align-middle">{attendance.shift_break}</td>
      <td className="text-center align-middle">{attendance.attendance_break}</td>
      {
        <td
          className="text-center align-middle"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(attendance.anomalies) }}
        />
      }
      <td className="text-center align-middle">{attendance.hours_extra}</td>
      <td className="text-center align-middle">
        {attendance?.document && <>Pacto de Horas Extra</>}
        {attendance.observation && <br />}
        {attendance.observation}
      </td>
    </tr>
  );
};

const WorkdayReportToPrint = React.forwardRef(({ data }, ref) => {
  return (
    <div className="print_report" ref={ref}>
      {data.length > 0 && (
        <>
          <p>Reporte de Jornada Diaria</p>
          <p>Empresa: {data[0].company_name}</p>
          <p>RUT: {data[0].company_national_id}</p>
          <p>
            Rango seleccionado de fechas: {data[0].date_from} - {data[0].date_to}
          </p>
          {data.map(employee => (
            <>
              <p style={{ marginTop: '12px' }}>{employee.full_name}</p>
              <p>RUT: {employee.national_identification}</p>
              <table className="table-print table-sm table-bordered">
                <tbody>
                  <tr style={{ backgroundColor: '#EBEBEB' }}>
                    <th className="text-center align-middle">Fecha</th>
                    <th className="text-center align-middle">Jornada Ordinaria</th>
                    <th className="text-center align-middle">Marcas Reales</th>
                    <th className="text-center align-middle">Colación</th>
                    <th className="text-center align-middle">Marcas Colación</th>
                    <th className="text-center align-middle">Anomalías</th>
                    <th className="text-center align-middle">Horas Extraordinarias</th>
                    <th className="text-center align-middle">Comentarios</th>
                  </tr>
                  {employee.attendances.map(attendance => (
                    <>
                      {attendance.attendances.map(attendances => renderResult(employee.is_article_22, attendances, employee.employee_id))}
                      <tr style={{ backgroundColor: '#EBEBEB' }}>
                        <td className="text-center align-middle"> Totales Semanales </td>
                        <td className="text-center align-middle"> {attendance.total_times[0].parsed_shift_time} </td>
                        <td className="text-center align-middle">
                          {' '}
                          {attendance.total_times[0].parsed_attendance_day_t}{' '}
                        </td>
                        <td className="text-center align-middle">
                          {' '}
                          {attendance.total_times[0].parsed_shift_break_time}{' '}
                        </td>
                        <td className="text-center align-middle"> {attendance.total_times[0].p_attendance_break_t} </td>
                        <td className="text-center align-middle"> {attendance.total_times[0].p_anomalies_time} </td>
                        <td className="text-center align-middle"> {attendance.total_times[0].p_hours_extra_time} </td>
                        <td className="text-center align-middle">
                          {' '}
                          Compensación: {attendance.total_times[0].compensation}{' '}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </>
          ))}
        </>
      )}
    </div>
  );
});

export default WorkdayReportToPrint;
