import camelCaseRecursive from 'camelcase-keys-recursive';
import { authTypes } from '../actions/auth';

const initialState = {
  user: {
    id: 0,
    email: '',
    uid: '',
    role: '',
    avatar: {},
    isPretending: false
  },
  company: {
    id: 0,
    businessLogo: {},
    businessName: '',
    nationalIdentification: '',
    phone: ''
  },
  currentCompany: {
    id: '',
    businessName: '',
    businessLogo: {},
    companyModules: [],
    allowDownload: false,
    availableDownload: false,
    zipRequestEndDate: null
  },
  currentEmployee: '',
  currentRoles: [],
  headers: {
    accessToken: ''
  },
  enrollmentSent: {},
  signedIn: localStorage.jwt !== undefined,
  ongoingRequest: {
    signIn: false,
    signUp: false,
    signOut: false,
    company: false,
    validateToken: false
  }
};

const setHeaders = action => ({
  accessToken: action.response.headers.get('jwt')
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signIn: true }
      };
    case authTypes.SIGN_IN_REQUEST_DT:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signIn: true }
      };
    case authTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.result,
        headers: setHeaders(action),
        ongoingRequest: { ...state.ongoingRequest, signIn: false },
        signedIn: true
      };
    case authTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signIn: false }
      };
    case authTypes.SIGN_UP_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signUp: true }
      };
    case authTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        user: action.result,
        headers: setHeaders(action),
        ongoingRequest: { ...state.ongoingRequest, signUp: false },
        signedIn: true
      };
    case authTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signIn: false }
      };
    case authTypes.SIGN_OUT_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signOut: true }
      };
    case authTypes.SIGN_OUT_SUCCESS:
      localStorage.removeItem('currentRoles');
      return {
        ...initialState,
        ongoingRequest: { ...state.ongoingRequest, signOut: false },
        signedIn: false
      };
    case authTypes.SIGN_OUT_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signOut: false }
      };
    case authTypes.VALIDATE_TOKEN_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, validateToken: true }
      };
    case authTypes.VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.user,
        headers: setHeaders(action),
        currentRoles: action.roles,
        enrollmentSent: action.enrollmentSent,
        ongoingRequest: { ...state.ongoingRequest, validateToken: false }
      };
    case authTypes.RESTORE_AUTH_INFO:
      return {
        ...state,
        headers: action.auth,
        signedIn: true
      };
    case authTypes.CLEAR_AUTH_INFO:
      localStorage.removeItem('jwt');
      localStorage.removeItem('currentCompany');
      localStorage.removeItem('currentRoles');
      localStorage.removeItem('role');
      localStorage.removeItem('enrolled');
      return {
        ...state,
        user: initialState.user,
        headers: initialState.headers,
        currentRoles: initialState.currentRoles,
        enrollmentSent: initialState.enrollmentSent,
        signedIn: false,
        ongoingRequest: { ...state.ongoingRequest, validateToken: false }
      };
    case authTypes.SELECT_COMPANY:
      return {
        ...state,
        currentCompany: action.params.company,
        currentEmployee: action.params.employee
      };
    case authTypes.CURRENT_COMPANY_FAILURE:
      return {
        ...state,
        company: { ...initialState.company },
        ongoingRequest: { ...state.ongoingRequest, company: false }
      };
    case authTypes.CURRENT_COMPANY_REQUEST:
      return { ...state, ongoingRequest: { ...state.ongoingRequest, company: true } };
    case authTypes.CURRENT_COMPANY_SUCCESS:
      return {
        ...state,
        company: camelCaseRecursive(action.result),
        ongoingRequest: { ...state.ongoingRequest, company: false }
      };
    default:
      return state;
  }
};

export default reducer;
