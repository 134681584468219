import { withFormik, Field, Form, getIn } from 'formik';
import React, { useState } from 'react';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { InputSelect, EmployeeSearchDataTable, FormikDatePicker, FormikSelect } from '../../components';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { indexDocumentTemplateRequest } from '../../requests/documentTemplates';

const selectorTypes = [{ label: 'Todos', value: 'all_employees' }];

const GenerateDocuments = ({ errors, touched, values, setFieldValue, isSubmitting, templatePdf, onRequest }) => {
  const [selector, setSelector] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const dispatch = useDispatch();
  const documentType = [
    'certificate',
    'congratulation_letter',
    'contract_annex',
    'other_documents',
    'termination_contract_notice_letter',
    'selection_process_acceptance_notice',
    'selection_process_rejection_notice',
    'order_hygiene_safety',
    'labor_direction',
    'emergency_evaluation',
    'riohs_labor_inspection',
    'riohs_seremi',
    'voting_workers',
    'committee_hygiene_safety',
    'work_accidents',
    'election_representatives_hygiene_safety',
    'meeting_hygiene_safety',
    'personal_protection',
    'report_occupational_hazards',
    'acceptance_riohs',
    'induction_risk_prevention'
  ];

  const fetchDocumentTemplates = (inputValue, callback) => {
    indexDocumentTemplateRequest({
      dispatch,
      params: {
        query: inputValue,
        template_type: documentType,
        sort_column: 'name',
        display_length: 150
      },
      successCallback: response => {
        const data = [{ label: 'Contrato', value: 'work_contract' }, ...response.data.data];
        callback(data);
      }
    });
  };

  const handleSuccessEmployees = response => {
    const vEmployees = camelCaseEmptyStringRecursive(response?.data?.data) || [];
    const { employees } = templatePdf;
    const employeeIds = employees.map(item => item.id);

    const employeesToAdd = vEmployees.filter(selected => !employeeIds.includes(selected.id));
    const vSelector = [...selectorTypes, ...employeesToAdd];
    setSelector(vSelector);
  };

  const removeFromTable = (employee, action) => {
    if (action === 'destroy') {
      const { employees: templatePdfEmployees } = values.templatePdf;
      const notRemovedEmployees = templatePdfEmployees.filter(selected => selected.id !== employee.id);
      const addToSelector = [...selector, employee];
      setSelector(addToSelector);
      setFieldValue('templatePdf[employees]', notRemovedEmployees);
      if (notRemovedEmployees.length === 0) setFieldValue('templatePdf[employeeIds]', ['']);
      else
        setFieldValue(
          'templatePdf[employeeIds]',
          notRemovedEmployees.map(item => item.id)
        );
    }
  };

  const removeAllFromTable = () => {
    const selectedRowsIds = selectedRows.map(item => item.id);
    const notRemovedEmployees = values.templatePdf.employees.filter(selected => !selectedRowsIds.includes(selected.id));
    setSelector([...selector, ...selectedRows, ...selectorTypes].filter(item => item.label));
    setFieldValue('templatePdf[employees]', notRemovedEmployees);
    setFieldValue(
      'templatePdf[employeeIds]',
      notRemovedEmployees.map(item => item.id)
    );
    setSelectedCount(0);
    setSelectedRows([]);
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const addAllEmployees = () => {
    const { templatePdf: templatePdfValues } = values;
    const vSelector = selector.filter(selected => selected.id !== undefined);
    const selectedEmployeeIds = vSelector.map(employee => employee.value);
    const selectedEmployees = vSelector.map(employee => ({ ...employee }));
    setSelector([selectorTypes]);
    setFieldValue('employeeSelector', '');
    setFieldValue('templatePdf[employees]', [...selectedEmployees, ...templatePdfValues.employees]);
    setFieldValue('templatePdf[employeeIds]', [...selectedEmployeeIds, ...templatePdfValues.employeeIds]);
  };

  const handleSelector = () => {
    const vSelector = selector.filter(selected => selected.value !== values.employeeSelector);
    if (vSelector.length === selector.length) return;
    if (values.employeeSelector === 'all_employees') {
      addAllEmployees();
    } else {
      const employeeToAdd = selector.find(selected => selected.value === values.employeeSelector);
      setFieldValue(`templatePdf[employeeIds]`, [...values.templatePdf.employeeIds, employeeToAdd.value]);
      setFieldValue('templatePdf[employees]', [...values.templatePdf.employees, employeeToAdd]);
      setSelector(vSelector);
    }
  };

  const handleSearch = selectedEmployees => {
    const vSelector = selector.filter(selected => !selectedEmployees.includes(selected.value));
    if (vSelector.length === selector.length) return;
    const employeesToAdd = selector.filter(selected => selectedEmployees.includes(selected.value));
    setFieldValue(`templatePdf[employeeIds]`, [
      ...values.templatePdf.employeeIds,
      ...employeesToAdd.map(item => item.value)
    ]);
    setFieldValue('templatePdf[employees]', [...values.templatePdf.employees, ...employeesToAdd]);
    setSelector(vSelector);
  };
  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad fix-middle center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Form className={`${onRequest && 'bg-opacity'}`}>
        <Row>
          <Col md={6}>
            <Field name="templatePdf[templateId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  label="Documentos"
                  placeholder="Seleccionar documento"
                  value={undefined}
                  request={fetchDocumentTemplates}
                  onChange={data => {
                    setFieldValue(field.name, data ? data.value : '');
                    setFieldValue(
                      'templatePdf[documentType]',
                      documentType.find(item => item === data.template_type)
                    );
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          {values.templatePdf?.documentType === 'contract_annex' && (
            <>
              <Col md={6}>
                <Field name="templatePdf[extraKeys][0][value]">
                  {({ field }) => (
                    <FormikDatePicker
                      {...field}
                      isOutsideRange={() => false}
                      filedName="templatePdf[startDate]"
                      label="Fecha Inicio"
                      placeholder="dd/mm/aaaa"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="templatePdf[extraKeys][2][value]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      label="Tipo de Contrato o Periodo"
                      placeholder="Seleccionar Tipo de Contrato"
                      options={[
                        { label: 'Plazo Fijo', value: 'fixed_term' },
                        { label: 'Indefinido', value: 'undefined_term' }
                      ]}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              {values.templatePdf.extraKeys?.[2]?.value === 'fixed_term' && (
                <Col md={6}>
                  <Field name="templatePdf[extraKeys][3][value]">
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        isOutsideRange={() => false}
                        label="Fecha finalización contrato"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              )}
            </>
          )}
        </Row>
        <div className="info-box ml-0 mt-4">
          <h4 className="text-uppercase">Selecciona Trabajadores</h4>
          <p className="info">
            <span className="full-width">Seleccione trabajadores a los que se le asignará este documento</span>
          </p>
        </div>
        <EmployeeSearchDataTable
          baseModel={templatePdf}
          employees={values.templatePdf.employees}
          handleActions={removeFromTable}
          handleMassAction={removeAllFromTable}
          handleSearch={handleSearch}
          handleSelectedRows={handleSelectedRows}
          handleSelector={handleSelector}
          handleSuccessEmployees={handleSuccessEmployees}
          options={selector}
          selectedCount={selectedCount}
        />
        <Row className="d-flex justify-content-end my-5">
          <Col md={3}>
            <Button type="submit" disabled={isSubmitting || values.templatePdf.employees.length === 0} block>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const { employees, employeeIds } = props.templatePdf;

  return {
    templatePdf: {
      employees,
      employeeIds,
      templateId: '',
      extraKeys: [
        { code: '[[fecha_inicio_clausula]]', value: '' },
        { code: '[[clausula_adicional]]', value: '' },
        { code: '[[tipo_de_contrato]]', value: '' },
        { code: '[[fecha_fin_anexo_contrato]]', value: '' }
      ]
    }
  };
};

const validationSchema = Yup.object().shape({
  templatePdf: Yup.object().shape({
    templateId: Yup.string().required('Debes seleccionar una plantilla'),
    extraKeys: Yup.array(),
    'extraKeys[0]': Yup.object().when(['extraKeys[0].value', 'extraKeys[3].value'], {
      is: (startDate, endDate) => {
        const startDateMoment = moment(startDate, 'DD/MM/YYYY', true);
        const endDateMoment = moment(endDate, 'DD/MM/YYYY', true);

        return startDateMoment.isValid() && endDateMoment.isValid() && startDateMoment.isSameOrAfter(endDateMoment);
      },
      then: Yup.object().shape({
        value: Yup.date().required('Debe ser menor a la fecha de término')
      }),
      otherwise: Yup.object()
    }),
    'extraKeys[3]': Yup.object()
      .when(['extraKeys[2].value', 'extraKeys[3].value'], {
        is: (contractType, endDate) => contractType === 'fixed_term' && endDate === undefined,
        then: Yup.object().shape({
          value: Yup.date().required('Debes seleccionar una fecha de fin')
        })
      })
      .when(['extraKeys[0].value', 'extraKeys[3].value'], {
        is: (startDate, endDate) => {
          const startDateMoment = moment(startDate, 'DD/MM/YYYY', true);
          const endDateMoment = moment(endDate, 'DD/MM/YYYY', true);

          return startDateMoment.isValid() && endDateMoment.isValid() && endDateMoment.isSameOrBefore(startDateMoment);
        },
        then: Yup.object().shape({
          value: Yup.date().required('Debe ser mayor a la fecha de inicio')
        })
      })
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(GenerateDocuments);
