import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import attendanceColumns from '../../components/Attendance/Columns';
import AttendanceDataTable from '../../components/Attendance/AttendanceDataTable';
import AttendanceImportForm from '../../components/Attendance/AttendanceImportForm';
import { DefaultModal, SimpleCenteredModal } from '../../components';
import { importAttendanceRequest, importTemplateAttendanceRequest } from '../../requests/attendances';
import { sendAlert } from '../../actions/utils';
import { downloadFile } from '../../services/utils';

const AttendanceIndex = () => {
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [alertInfo, setAlertInfo] = useState('');
  const dispatch = useDispatch();

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleImportTemplateAttendanceRequest = () => {
    importTemplateAttendanceRequest({
      dispatch,
      successCallback: response => {
        downloadFile(response);
      },
      callback: () => setModalShow(false)
    });
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInf = <pre>{error?.response?.data?.message}</pre>;
      setAlertInfo(alertInf);
      setAlertModalShow(true);
      setModalShow(false);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
      setModalShow(false);
    }
  };

  const handleAttendanceImportRequest = attendance => {
    importAttendanceRequest({
      dispatch,
      params: attendance,
      formData: true,
      successCallback: response => handleSuccessAction(response?.data?.message),
      callback: () => setModalShow(false),
      failureCallback: response => handleImportModalExceptions(response)
    });
  };

  return (
    <>
      <AttendanceDataTable columns={attendanceColumns} moreData={moreData} setMoreData={setMoreData} />
      <SimpleCenteredModal
        title="Importar Asistencias"
        body={
          <>
            <Button className="ml-3" variant="warning" onClick={handleImportTemplateAttendanceRequest}>
              Descargar ejemplo
            </Button>
            <AttendanceImportForm
              formRequest={handleAttendanceImportRequest}
              handleModalClose={() => setModalShow(false)}
            />
          </>
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
      />

      <DefaultModal
        title="Información Relevante"
        body={alertInfo}
        show={alertModalShow}
        withClose={false}
        handleConfirm={() => setAlertModalShow(false)}
        handleClose={() => setAlertModalShow(false)}
        titleBtnSave="Confirmar"
        scrollable
      />
    </>
  );
};

export default AttendanceIndex;
