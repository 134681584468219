import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ChileMap from './ChileMap';
import Referencing from '../utils/Referencing';
import RegionsColor from '../utils/RegionsColor';
import LogicEmployeeDistributionRow from './LogicEmployeeDistributionRow';
import VerticalBarGraphic from './VerticalBarGraphic';

function EmployeeDistribution({ title = '', subtitle = '', AnyList = '', onRequest = false }) {
  const regions = [];
  let size = 0;

  if (typeof title === 'object') {
    title.forEach(costCenter => {
      regions[Referencing(costCenter.name)] = RegionsColor(costCenter.name);
      size += 1;
    });
  }

  return (
    <>
      {!onRequest && (
        <div className="EmployeeDistributionBody">
          <Row>
            <Col xl={5} md={6} xs={12} className="LittleMargin">
              <div className="MarkContent">
                <Row className="ServiceProvisionTitle">
                  <p className="Justify">Lugar de Prestación de servicios</p>
                </Row>

                <Row className="MapHeight">
                  <Col xl={3} md={3} xs={6}>
                    <div className="pt-3" />
                    <ChileMap colorRegion={regions} />
                  </Col>

                  <Col xl={9} md={9} xs={6} className="CentralizeTextMap">
                    {typeof title === 'object' &&
                      title.map(costCenter => (
                        <div key={costCenter.name}>
                          <LogicEmployeeDistributionRow
                            region={Referencing(costCenter.name)}
                            regionslength={size}
                            communes={AnyList}
                            number={costCenter.employeeCount}
                            colormap={regions[Referencing(costCenter.name)]}
                          />
                        </div>
                      ))}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xl={7} md={6} xs={12} className="LittleMargin">
              <div className="MarkContent">
                <Row className="ServiceProvisionTitle">
                  <p className="Justify">Centro de Costo</p>
                </Row>

                <Row className="Separate">
                  <VerticalBarGraphic subtitle={subtitle} type="" />
                  <div className="line" style={{ top: '0%' }} />
                  <div className="line" style={{ top: '20%' }} />
                  <div className="line" style={{ top: '40%' }} />
                  <div className="line" style={{ top: '60%' }} />
                  <div className="line" style={{ top: '80%' }} />
                  <div className="line" style={{ top: '100%' }} />
                </Row>

                <Row className="Separate">
                  <VerticalBarGraphic subtitle={subtitle} type="text" />
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default EmployeeDistribution;
