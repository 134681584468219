import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useAbility } from '@casl/react';

import {
  debounceIndexAbsenceRequest,
  deleteAbsenceRequest,
  exportAbsencesRequest,
  preImportAbsenceRequest,
  importAbsenceRequest,
  importTemplateAbsenceRequest
} from '../../requests/absences';
import { AbilityContext } from '../../config/abilityContext';
import { sendAlert } from '../../actions/utils';
import defaultColumns from './defaultColumns';
import {
  ComponentDataTable,
  DefaultModal,
  SimpleCenteredModal,
  ButtonTooltip,
  Icon,
  ImportModal
} from '../../components';
import AbsenceEdit from './AbsenceEdit';
import AbsenceInfo from './AbsenceInfo';
import { downloadFile } from '../../services/utils';

const AbsenceDataTable = ({
  columns,
  customParams,
  moreData,
  setMoreData,
  reloadData,
  defaultStartDate = null,
  defaultEndDate = null
}) => {
  const ability = useAbility(AbilityContext);
  const [absences, setAbsences] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [query, setQuery] = useState({});
  const [simpleModalBody, setSimpleModalBody] = useState('');
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const isInitialMount = useRef(true);
  const dispatch = useDispatch();
  const tableColumns = columns || defaultColumns;

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
    setModalItem({});
    setSimpleModalShow(false);
    setSimpleModalBody('');
    setModalTitle('');
  };

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    handleModalClose();
    setMoreData(!moreData);
  };

  const handleSuccessIndex = response => {
    const { amount: rAmount } = response.data.metadata;
    setAbsences(response.data.data);
    setAmount(rAmount);
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
    setOnRequest(false);
  };

  const handleRequest = params => {
    setOnRequest(true);
    setQuery({ ...customParams, ...params });
    const request = async () =>
      debounceIndexAbsenceRequest({
        dispatch,
        params: {
          ...customParams,
          ...params
        },
        successCallback: handleSuccessIndex
      });
    request();
  };

  const removeAbsence = item => {
    deleteAbsenceRequest(item.id, {
      dispatch,
      successCallback: () => handleSuccessAction('Ausencia eliminada con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const setEditModal = (title, body) => {
    setModalTitle(title);
    setSimpleModalBody(body);
    setSimpleModalShow(true);
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        setEditModal(`Ausencia - ${item.employee.full_name}`, <AbsenceInfo absence={camelCaseRecursive(item)} />);
        break;
      case 'edit':
        setEditModal(
          `Razón de Ausencia - ${item.employee.full_name}`,
          <AbsenceEdit
            absence={camelCaseRecursive(item)}
            handleSuccessAction={handleSuccessAction}
            handleFailureAction={handleFailureRequest}
          />
        );
        break;
      case 'destroy':
        setModalItem(item);
        setModalBody(`¿Estás seguro que deseas eliminar esta Ausencia de ${item.employee.full_name}?`);
        setModalTitle('Eliminar Ausencia');
        setModalAction(() => removeAbsence);
        setModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleDownload = () => {
    setOnRequest(true);

    exportAbsencesRequest({
      dispatch,
      params: query,
      successCallback: response => {
        setModalShow(false);
        downloadFile(response);
        setOnRequest(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const downloadModal = () => {
    setModalTitle('Exportar Ausentismo');
    setModalShow(true);
    setModalBody('¿Estás seguro que deseas descargar el ausentismo?');
    setModalAction(() => handleDownload);
  };

  const handleMassAction = action => {
    switch (action) {
      case 'import':
        setModalTitle('Importar Ausentismo');
        setSimpleModalBody(
          <ImportModal
            onDropUploaded={preImportAbsenceRequest}
            handleTemplate={importTemplateAbsenceRequest}
            onHide={importAbsenceRequest}
            hideModal={() => setSimpleModalShow(false)}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        );
        setSimpleModalShow(true);
        break;
      case 'export':
        downloadModal();
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee':
        return { sort_employees: name };
      default:
        return { sort_column: name };
    }
  };

  const handleOnUpdate = () => {
    if (isInitialMount.current) isInitialMount.current = false;
    else handleRequest();
  };

  useEffect(handleOnUpdate, [reloadData]);

  const withStartDate = !customParams.filter_absence_exception;
  const withEndDate = !customParams.filter_absence_exception;

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={tableColumns(handleButtonClick)}
        data={absences}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        resourceRequest={handleRequest}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        withStartDate={withStartDate}
        withEndDate={withEndDate}
        rangePicker
        preName="absence"
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
        withMassActions
        massActions={
          <>
            {ability.can('export', 'Absence') && (
              <ButtonTooltip
                variant="circle-primary"
                className="mr-2 btn-circle mb-3"
                text="Exportar"
                onClick={() => handleMassAction('export')}
              >
                <Icon icon="download-2" />
              </ButtonTooltip>
            )}
            {/* {ability.can('import', 'Absence') && (
              <ButtonTooltip
                variant="circle-primary"
                className="mr-2 btn-circle mb-3"
                text="Importar"
                onClick={() => handleMassAction('import')}
              >
                <Icon icon="cloud-upload-outline" />
              </ButtonTooltip>
            )} */}
          </>
        }
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal title={modalTitle} body={simpleModalBody} show={simpleModalShow} onHide={handleModalClose} />
    </>
  );
};

export default AbsenceDataTable;
