// import { useAbility } from '@casl/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Col, Nav, Tab } from 'react-bootstrap';
import moment from 'moment';
import { Icon } from '../../components';
import PermissionDataTableStyled from '../Shared/Permission/PermissionDataTableStyled';
import VacationDataTableStyled from '../Shared/Vacation/VacationDataTableStyled';
import AttendanceDataTableStyled from '../../components/Attendance/AttendanceDataTableStyled';
import DailyAttendanceDataTable from '../../components/Attendance/DailyAttendanceDataTable';
import dailyColumns from '../../components/Attendance/DailyColumnsToday';
import attendanceColumns from '../../components/Attendance/bossColumns';
import AttendanceDataTable from '../../components/Attendance/AttendanceDataTable';
import columnsSimplify from './ColumnsSimplify';
import AbsenceDataTable from './AbsenceDataTable';

const TeamAbsenceIndex = ({ currentEmployee, employeesBelongBoss, moreData, setMoreData }) => {
  const { advanceSettings } = useSelector(state => state.utils);
  const { currentCompany } = useSelector(state => state.auth);
  const { attendanceManagement } = currentCompany;
  const location = useLocation();
  const [key, setKey] = useState('');
  const today = moment().format('YYYY-MM-DD');
  const [todayMoment, setTodayMoment] = useState('');
  const vacationsTab = advanceSettings
    .filter(asItem => ['legal_holiday', 'progressive_holiday', 'additional_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  const loanTab = advanceSettings.filter(asItem => asItem.code === 'loan_request').some(x => x.textValue === 'true');

  const permissionsTab = advanceSettings
    .filter(asItem => ['paid_leave', 'without_paid_leave', 'administrative_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  const administrativeTab = advanceSettings
    .filter(asItem => ['administrative_days'].includes(asItem.code))
    .some(x => x.textValue === 'true');

  const advancePaymentsTab = advanceSettings
    .filter(asItem => asItem.code === 'advance_payment_request')
    .some(x => x.textValue === 'true');

  const checkTab = () => {
    const tab = new URLSearchParams(location.search).get('tab');
    setTodayMoment(moment());
    if (tab === 'permissions' && permissionsTab) return 'permissions';
    if (tab === 'vacations' && vacationsTab) return 'vacations';
    if (tab === 'licenses' && administrativeTab) return 'licenses';
    if (tab === 'unjustified' && loanTab) return 'unjustified';
    if (tab === 'advance_payments' && advancePaymentsTab) return 'advance_payments';
    return 'permissions';
  };

  const customLicenceParams = {
    filter_absence_exception: 'unjustified_absence',
    filter_date_from: today,
    filter_date_to: today
  };

  const setDatatable = tabKey => {
    switch (tabKey) {
      case 'permissions':
        return (
          <PermissionDataTableStyled
            currentEmployee={currentEmployee}
            dateRange={today}
            defaultStatus="1"
            employeesBelongBoss={employeesBelongBoss}
            moreData={moreData}
            setMoreData={setMoreData}
            showActions={false}
            todayMoment={todayMoment}
            preName="permissions"
            withHeaders={false}
            withDateSelect={false}
            withSearch={false}
          />
        );
      case 'vacations':
        return (
          <Col>
            <VacationDataTableStyled
              currentEmployee={currentEmployee}
              dateRange={today}
              defaultStatus="1"
              employeesBelongBoss={employeesBelongBoss}
              moreData={moreData}
              todayMoment={todayMoment}
              range_filter={today}
              setMoreData={setMoreData}
              showActions={false}
              preName="vacations_absences"
              withHeaders={false}
              withDateSelect={false}
              withSearch={false}
            />
          </Col>
        );
      case 'administrative':
        return (
          <Col>
            <VacationDataTableStyled
              currentEmployee={currentEmployee}
              dateRange={today}
              defaultStatus="1"
              employeesBelongBoss={employeesBelongBoss}
              moreData={moreData}
              todayMoment={todayMoment}
              range_filter={today}
              setMoreData={setMoreData}
              showActions={false}
              preName="administrative_absences"
              withHeaders={false}
              withDateSelect={false}
              withSearch={false}
            />
          </Col>
        );
      case 'licenses':
        return (
          <AbsenceDataTable
            defaultStartDate={todayMoment}
            defaultEndDate={todayMoment}
            columns={columnsSimplify}
            moreData={moreData}
            setMoreData={setMoreData}
            customParams={customLicenceParams}
          />
        );
      case 'unjustified':
        return (
          <AttendanceDataTableStyled
            employeesBelongBoss={employeesBelongBoss}
            dateRange={today}
            filterKey={['Ausencia Injustificada']}
            moreData={moreData}
            setMoreData={setMoreData}
            type="unjustified"
            withHeaders={false}
            withDateSelect={false}
            withSearch={false}
          />
        );
      case 'daily-attendance-datatable':
        return (
          <DailyAttendanceDataTable
            employeesBelongBoss={employeesBelongBoss}
            columns={dailyColumns}
            moreData={moreData}
            setMoreData={setMoreData}
            tab="team"
          />
        );
      case 'attendance-datatable':
        return (
          <AttendanceDataTable
            employeesBelongBoss={employeesBelongBoss}
            defaultStartDate={todayMoment}
            defaultEndDate={todayMoment}
            columns={attendanceColumns}
            moreData={moreData}
            setMoreData={setMoreData}
            tab="team"
          />
        );
      default:
        return '';
    }
  };

  const updateDefaultKey = () => setKey(checkTab());

  useEffect(updateDefaultKey, [advanceSettings]);

  return (
    <Card className="mb-1">
      <Col className="top-header-green">
        <Col className="card-header-title">
          {attendanceManagement ? 'ASISTENCIA/AUSENCIA DE HOY' : 'AUSENCIAS DE MI EQUIPO HOY'}
        </Col>
      </Col>
      <Col className="div-content mt-2 mb-2">
        <Tab.Container className="ml-3" id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="permissions">
                <Icon icon="clock2" />
                Permisos
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="vacations">
                <Icon icon="sunny" />
                Vacaciones
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="administrative">
                <Icon icon="calendar" />
                Días Adm.
              </Nav.Link>
            </Nav.Item>
            {attendanceManagement && (
              <>
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="licenses">
                    <Icon icon="pencil" />
                    Licencias
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="unjustified">
                    <Icon icon="clock2" />
                    Sin Marcación
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="daily-attendance-datatable">
                    <Icon icon="chevron-up" />
                    Asistencia del Día
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="attendance-datatable">
                    <Icon icon="trello" />
                    Asistencia
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="permissions">{setDatatable('permissions')}</Tab.Pane>
            <Tab.Pane eventKey="vacations">{setDatatable('vacations')}</Tab.Pane>
            <Tab.Pane eventKey="administrative">{setDatatable('administrative')}</Tab.Pane>
            <Tab.Pane eventKey="licenses">{setDatatable('licenses')}</Tab.Pane>
            <Tab.Pane eventKey="unjustified">{setDatatable('unjustified')}</Tab.Pane>
            <Tab.Pane eventKey="daily-attendance-datatable">{setDatatable('daily-attendance-datatable')}</Tab.Pane>
            <Tab.Pane eventKey="attendance-datatable">{setDatatable('attendance-datatable')}</Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
    </Card>
  );
};
export default TeamAbsenceIndex;
