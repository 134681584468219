import React from 'react';
import memoize from 'memoize-one';
import { BasicDropdown, Icon } from '../../../components';

const massiveActions = memoize((handleClick, disabled) => {
  const actions = () => {
    const authorizedActions = [];

    authorizedActions.push({
      icon: <Icon icon="trash" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'massive_export',
      props: {
        onClick: () => handleClick(undefined, 'massive_destroy'),
        disabled
      },
      title: 'Eliminar'
    });

    return authorizedActions;
  };

  return (
    <div className="mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
});

export default massiveActions;
