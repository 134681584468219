import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { LinkBtn, Icon, DatatableEmployeeName } from '../../components';
import { showShiftRequest } from '../../requests/shifts';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import basicShift from './shift';

const columns = [
  {
    name: 'NOMBRE',
    selector: 'employee.fullName',
    cell: item => <DatatableEmployeeName item={item.employee} fileName="fileInfo" name="fullName" fileUrl="fileUrl" />,
    grow: '3',
    sortable: true
  },
  {
    name: 'JORNADA',
    selector: 'workSchedule',
    cell: item => (item.workSchedule ? <span>{item.workSchedule}</span> : <span>Sin Jornada</span>),
    grow: '3',
    sortable: true
  }
];

const ShiftShow = ({ match }) => {
  const [shift, setShift] = useState(basicShift);
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();
  const fetchShift = () => {
    const shiftId = match.params.id;
    setOnRequest(true);
    showShiftRequest(shiftId, {
      dispatch,
      successCallback: response => setShift(camelCaseEmptyStringRecursive(response.data)),
      callback: () => setOnRequest(false)
    });
  };
  const { contracts } = shift;

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchShift, []);

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/shifts">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
      </Row>
      <div className="info-box mt-5">
        <h3 className="text-uppercase mb-5">Información del Turno </h3>
        <Row>
          <Col md={6}>
            <p className="info">
              <span>Nombre de Turno:</span>
              {shift.flexibleShift ? `${shift.name}-Flexible` : shift.name}
            </p>
          </Col>
          <Col md={6}>
            <p className="info">
              <span>Tipo de Turno:</span>
              {shift.shiftType !== 'weekly' || 'Semanal'}
              {shift.shiftType !== 'rotative' || 'Rotativo'}
              {shift.shiftType !== 'monthly' || 'Mensual'}
            </p>
          </Col>
        </Row>
        <hr />
      </div>
      <div className="info-box">
        <Row>
          <Col md={6}>
            <p className="info">
              <span>Trabaja Festivos:</span> {shift.workHolidays ? 'Si' : 'No'}
            </p>
          </Col>

          <Col md={6}>
            <p className="info">
              <span>Tolerancia:</span> {shift.tolerance} Minutos
            </p>
          </Col>
        </Row>
        <hr />
      </div>
      <div className="info-box">
        <Row>
          <>
            {shift.agreedSchedule && (
              <Col md={6}>
                <p className="info">
                  <span>Horario Pactado:</span> {shift.agreedSchedule ? 'Si' : 'No'}
                </p>
              </Col>
            )}
            {shift.collationTime && (
              <>
                <Col md={6}>
                  <p className="info">
                    <span>Horario de Colacion:</span> {shift.collationTime ? 'Si' : 'No'}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="info">
                    <span>Tiempo:</span> {shift.lunchTime} minutos
                  </p>
                </Col>
              </>
            )}
          </>
        </Row>
        <hr />
      </div>

      {shift.flexibleShift && (
        <div className="info-box">
          <Row>
            <>
              <Col md={4}>
                <p className="info">
                  <span>Horario Inicio:</span> {shift.initialTime}
                </p>
              </Col>

              <>
                <Col md={4}>
                  <p className="info">
                    <span>Tiempo Max. Inicio:</span> {shift.maximumStartTime}
                  </p>
                </Col>
                <Col md={4}>
                  <p className="info">
                    <span>Horas de Trabajo:</span> {shift.workHours}
                  </p>
                </Col>
              </>
            </>
          </Row>
          <hr />
        </div>
      )}
      <div className="info-box">
        <h3 className="text-uppercase">Trabajadores</h3>
        <DataTable columns={columns} data={contracts} noDataComponent="No tiene trabajadores asociados" noHeader />
      </div>
    </>
  );
};

export default ShiftShow;
