import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

const styles = {
  paginationContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    width: '100%'
  },
  paginationCount: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 5
  },
  paginationCountAlign: {
    display: 'flex',
    height: 40,
    alignItems: 'center'
  },
  paginationChangeSize: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 5,
    marginRight: 20
  },
  paginationSelectSize: {
    height: 40,
    alignItems: 'center',
    padding: 5,
    borderRadius: 10,
    borderWidth: 1
  },
  paginationButtonSelection: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 5
  },
  defaultButton: {
    height: 40,
    marginRight: 5
  }
};

function Pagination({ pageIndex, pageCount, handleGoToPage, pageSize, setPageSize, pageSizes }) {
  return (
    <Row style={styles.paginationContent}>
      <Col xs={4} md={2} xl={2} style={styles.paginationCount}>
        <span style={styles.paginationCountAlign}>
          Página
          <strong style={{ paddingLeft: 10 }}>
            {pageIndex + 1} de {pageCount}
          </strong>{' '}
        </span>
      </Col>
      <Col xs={2} md={2} xl={2} style={styles.paginationChangeSize}>
        <select
          style={styles.paginationSelectSize}
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {pageSizes.map(pSize => (
            <option key={pSize} value={pSize}>
              Mostrar {pSize}
            </option>
          ))}
        </select>
      </Col>
      <Col xs={6} md={6} xl={3} style={styles.paginationButtonSelection}>
        <Button
          variant="outline-info"
          style={styles.defaultButton}
          disabled={pageIndex === 0}
          onClick={() => handleGoToPage(0)}
        >
          {'<<'}
        </Button>
        <Button
          variant="outline-info"
          style={styles.defaultButton}
          disabled={pageIndex === 0}
          onClick={() => handleGoToPage(pageIndex - 1)}
        >
          Anterior
        </Button>
        <Button
          variant="outline-info"
          style={styles.defaultButton}
          disabled={pageIndex === pageCount - 1}
          onClick={() => handleGoToPage(pageIndex + 1)}
        >
          Siguiente
        </Button>
        <Button
          variant="outline-info"
          style={{ height: 40 }}
          disabled={pageIndex === pageCount - 1}
          onClick={() => handleGoToPage(pageCount - 1)}
        >
          {'>>'}
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(Pagination);
