import { Field, useFormikContext } from 'formik';
import React from 'react';
import { Col } from 'react-bootstrap';
import { FormikInput } from '../../../../components';

const EmployeeInput = () => {
  const { values } = useFormikContext();
  return (
    <>
      <Col xs={6} md={6}>
        <Field name="payrollBalance[employee]">
          {() => (
            <FormikInput
              inputType="text"
              label="Empleado"
              placeholder=""
              value={values?.payrollBalance?.employee?.fullName}
              margin="mb-0"
              readOnly
            />
          )}
        </Field>
      </Col>
      <Col xs={6} md={6}>
        <Field name="payrollBalance[employee]">
          {() => (
            <FormikInput
              inputType="text"
              label="Concepto"
              placeholder=""
              value={`${values?.payrollBalance?.balance?.label} - ${values?.payrollBalance?.parsedType}`}
              margin="mb-0"
              readOnly
            />
          )}
        </Field>
      </Col>
    </>
  );
};

export default EmployeeInput;
