import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import memoize from 'memoize-one';

import {
  debounceIndexAdminEmployeesRequest,
  deactivateAdminEmployeeRequest,
  sendActivationEmailsRequestAdmin,
  sendActivationEmailRequestAdmin
} from '../../../requests/adminEmployees';
import { sendAlert } from '../../../actions/utils';
import {
  ComponentDataTable,
  DefaultModal,
  ActiveDot,
  ButtonTooltip,
  Icon,
  DatatableEmployeeName,
  SimpleCenteredModal
} from '../../../components';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE COMPLETO',
    selector: 'name',
    cell: item => <DatatableEmployeeName item={item} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'CORREO',
    selector: 'email',
    sortable: true,
    grow: '1'
  },
  {
    name: 'IDENTIFICACIÓN',
    selector: 'national_identification',
    sortable: true,
    grow: '1'
  },
  {
    name: 'EMPRESA',
    selector: 'company',
    cell: item => item.company?.label,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACTIVO',
    selector: 'active',
    cell: item => <ActiveDot item={item} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'edit')}
          variant="circle-warning"
          className="btn-circle"
          size="sm"
          text="Editar"
        >
          <Icon icon="pencil" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'archive')}
          variant="circle-danger"
          className="btn-circle"
          size="sm"
          text={item.active ? 'Desactivar' : 'Activar'}
        >
          <Icon icon="archive" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'send_activation_email')}
          disabled={item.user.active_for_authentication}
          variant="circle-danger"
          className="btn-circle"
          size="sm"
          text="Enviar correo de enrolamiento"
        >
          <Icon icon="paper-plane-outline" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

const EmployeeDataTable = ({ refreshDashboard }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [modalTitle, setModalTitle] = useState('Desactivar Empleado');
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const [modalBody, setModalBody] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [simpleModalTitle, setSimpleModalTitle] = useState('');
  const [simpleModalBody, setSimpleModalBody] = useState({});
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const refreshTable = () => {
    setMoreData(!moreData);
    if (refreshDashboard) refreshDashboard();
  };

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setEmployees(data);
    setAmount(metadata.amount);
  };

  const handleRequest = async params => {
    setOnRequest(true);
    debounceIndexAdminEmployeesRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex,
      callback: () => setOnRequest(false)
    });
  };

  const handleCallback = () => {
    setModalShow(false);
    setOnRequest(false);
    setMoreData(!moreData);
  };

  const handleMassArchive = () => {
    setOnRequest(true);

    deactivateAdminEmployeeRequest({
      dispatch,
      params: {
        employee_ids: [modalItem.id]
      },
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Acción realizada con éxito' }));
      },
      callback: handleCallback
    });
  };

  const handleSendActivationEmail = item => {
    const { company } = item;
    sendActivationEmailRequestAdmin(item.id, {
      dispatch,
      params: {
        company_id: company.value
      },
      successCallback: response => {
        dispatch(sendAlert({ kind: 'success', message: response?.data.message }));
      },
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/admin/employees/${item.id}`);
        break;
      case 'edit':
        history.push(`/admin/employees/${item.id}/edit`);
        break;
      case 'archive':
        setModalItem(item);
        setModalTitle(`${item.active ? 'Desactivar' : 'Activar'} Empleado`);
        setModalBody(`¿Estás seguro que deseas ${item.active ? 'desactivar' : 'activar'} a ${item.full_name}?`);
        setModalShow(true);
        setModalAction(() => handleMassArchive);
        break;
      case 'send_activation_email':
        setModalItem(item);
        setModalShow(true);
        setModalBody(`¿Estás seguro que deseas enviar el correo de enrolamiento a ${item.full_name}?`);
        setModalTitle('Enviar correo de enrolamiento');
        setModalAction(() => handleSendActivationEmail);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };

  const handleMassSendActivationEmail = () => {
    const myParams = {
      employee_attributes: selectedRows.reduce((acc, current) => {
        acc.push({
          id: current.id,
          company_id: current.company.value
        });
        return acc;
      }, [])
    };
    setOnRequest(true);

    sendActivationEmailsRequestAdmin({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: response => {
        let message = '';
        const { errors, success } = response.data;
        if (success.length >= 1) {
          message = (
            <h4 className="text-success mb-3 enrrolment mt-md-n1">
              Correo(s) de enrolamiento al sistema enviado(s) con éxito
            </h4>
          );
        }
        if (success.length === 0) {
          message = (
            <h4 className="text-danger mb-3 enrrolment mt-md-n1">
              No se enviaron correo(s) de enrolamiento al sistema
            </h4>
          );
        }
        refreshTable();
        setSimpleModalTitle('Envío de correo de enrolamiento');
        if (errors.length > 1) {
          setSimpleModalBody(
            <>
              {message}
              <p>
                Los correos de enrolamiento no fueron enviados a los siguientes colaboradores:&nbsp;
                <strong>{errors.join(', ')}</strong>.&nbsp; Ya se encuentra enrolado(a) en el sistema.
              </p>
            </>
          );
        }
        if (errors.length === 1) {
          setSimpleModalBody(
            <>
              {message}
              <p>
                El correo de enrolamiento no fue enviado al colaborador&nbsp;
                <strong>{errors[0].trim()}</strong>.&nbsp; Ya se encuentra enrolado(a) en el sistema.
              </p>
            </>
          );
        }
        if (errors.length === 0) {
          setSimpleModalBody(
            <>
              <center>{message}</center>
            </>
          );
        }
        setSimpleModalShow(true);
        setOnRequest(false);
      },
      failureCallback: error => {
        const { data } = error.response;
        setOnRequest(false);
        dispatch(sendAlert({ kind: 'error', message: data.message }));
      }
    });
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'company':
        return { sort_companies: name };
      default:
        return { sort_column: name };
    }
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: '55px'
      }
    },
    headCells: {
      style: {
        textAlign: 'center'
      }
    }
  };
  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions)}
        customStyles={customStyles}
        data={employees}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        selectableRows
        onRowClicked={item => history.push(`/admin/employees/${item.id}`)}
        resourceRequest={handleRequest}
        pointerOnHover
        withMassActions
        massActions={
          <>
            <ButtonTooltip
              variant="circle-primary"
              className="btn-circle mb-3 mr-2"
              text="Enviar correo de enrolamiento"
              disabled={!selectedCount || onRequest}
              onClick={() => handleMassSendActivationEmail()}
            >
              <Icon icon="paper-plane-outline" />
            </ButtonTooltip>
          </>
        }
        onSelectedRowsChange={handleSelectedRows}
        query="admin_query"
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
    </>
  );
};

export default EmployeeDataTable;
