import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import memoize from 'memoize-one';
import { Button, Row, Col } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { useAbility } from '@casl/react';
import { legalreportOffdaysReport } from '../../requests/legalreports';
import { DataTableDT, DefaultModal, Icon } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { OffdaysFilterDataTable } from '../../components/LegalReport';
import { downloadFile } from '../../services/utils';
import ReportActions from './ReportActions';
import OffdayReportFilters from './Filters/OffdayReportFilters';
import OffdayReportToPrint from './PrintComponent/OffdayReportToPrint';
import { indexEmployeesRequest } from '../../requests/employees';

const columns = memoize(() => [
  {
    name: 'NOMBRE',
    selector: 'name',
    cell: item => <>{item.full_name}</>,
    sortable: true,
    grow: '2'
  },
  {
    name: 'RUT',
    selector: 'national_identification',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO DE SERVICIO PRESTADO',
    selector: 'job_title',
    sortable: false,
    grow: '2'
  }
]);

const recordsColumns = memoize(() => [
  {
    name: 'T.C.B.D.D.A. Descanso Dominical Adicional',
    selector: 'default_no',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA',
    selector: 'date',
    sortable: true,
    grow: '4'
  },
  {
    name: 'ASISTENCIA',
    selector: 'attendance',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'AUSENCIA',
    selector: 'absence',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'OBSERVACIONES',
    selector: 'justification',
    sortable: true,
    grow: '5',
    center: true,
    conditionalCellStyles: [
      {
        when: row => row.justification && row.justification.includes('Marcación'),
        style: {
          backgroundColor: 'rgba(248, 148, 6, 0.9)',
          color: 'white',
          '&:hover': {
            cursor: 'pointer'
          }
        }
      }
    ]
  }
]);

export const MyExpander = props => {
  const { data, dateRange } = props;
  const { id } = data;

  return (
    <OffdaysFilterDataTable
      columns={recordsColumns}
      customParams={{ filter_by_employee: id, date_range: dateRange }}
      props
    />
  );
};

export const LoadingTranslation = () => {
  return <h3>Cargando...</h3>;
};

const OffdaysReportDataTable = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [wordModalShow, setWordModalShow] = useState(false);
  const [pdfModalShow, setPdfModalShow] = useState(false);
  const [printModalShow, setPrintModalShow] = useState(false);
  const [query, setQuery] = useState({});
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const [filters, setFilters] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [showFilter, setShowFilter] = useState(true);
  const [moreData, setMoreData] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isFiltered, setFiltered] = useState(false);
  const [printLog, setPrintLog] = useState('');
  const componentRef = useRef();
  const [employeeIds, setEmployeeIds] = useState([]);
  const [amount, setAmount] = useState(0);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const printInfo = useReactToPrint({
    content: () => componentRef.current
  });

  const handleOffdayReportRequest = async (params, format, type = 'json') => {
    const responseType = format === '.docx' ? 'blob' : type;
    const employeeIdsSelected = employeeIds.present ? employeeIds : filters.filter_ids;
    setOnRequest(true);
    legalreportOffdaysReport({
      dispatch,
      params: { filter_by_employee: employeeIdsSelected, date_range: dateRange, ...params, paginate: false },
      format,
      responseType,
      successCallback: response => {
        if (format === '.xlsx' || format === '.docx' || format === '.pdf') {
          downloadFile(response);
        } else if (format === '.print') {
          setSubmitting(false);
          setPrintLog(response.data);
          printInfo();
        }
      },
      callback: () => {
        setOnRequest(false);
        setSubmitting(false);
        setFiltered(false);
        setEmployeeIds([]);
      }
    });
  };

  const handleRequest = async (params, format, responseType = 'json') => {
    setOnRequest(true);
    const empParams = { ...params };
    delete empParams.date_to;
    delete empParams.date_from;
    delete empParams.range_date_workday;
    setQuery(empParams);
    indexEmployeesRequest({
      dispatch,
      params: {
        ...filters,
        ...empParams,
        active: true,
        is_dt: false,
        active_contracts: true,
        sort_direction: 'asc',
        sort_column: 'name'
      },
      format: 'json',
      responseType,
      successCallback: response => {
        if (format === '.xlsx' || format === '.docx' || format === '.pdf' || format === '.print') {
          handleOffdayReportRequest(params, format, 'text');
        } else {
          const { data } = response;
          setAmount(data.metadata.amount);
          setFilteredEmployees(data.data);
          setOnRequest(false);
          setSubmitting(false);
        }
      },
      failureCallback: () => {
        setOnRequest(false);
        setSubmitting(false);
      }
    });
  };

  const handleFilters = params => {
    const empParams = { ...params };
    delete empParams.date_to;
    delete empParams.date_from;
    delete empParams.range_date_workday;
    if (empParams.filter_id) {
      empParams.filter_ids.push(empParams.filter_id);
    }
    setFilters(empParams);
    setSearchFirstStart(true);
    setDateRange(params.range_date_workday);
    setSubmitting(true);
    switch (params.type) {
      case null:
        setMoreData(true);
        handleRequest(params);
        setShowFilter(false);
        setSearchFirstStart(true);
        break;
      case '.docx':
        setWordModalShow(true);
        break;
      case '.xlsx':
        setModalShow(true);
        break;
      case '.pdf':
        setPdfModalShow(true);
        break;
      case '.print':
        setPrintModalShow(true);
        break;
      default:
        break;
    }
  };

  const handleMassAction = action => {
    setEmployeeIds(filteredEmployees.map(employee => employee.id));

    switch (action) {
      case 'download_docx':
        setWordModalShow(true);
        setFiltered(true);
        break;
      case 'download_excel':
        setModalShow(true);
        setFiltered(true);
        break;
      case 'download_pdf':
        setPdfModalShow(true);
        setFiltered(true);
        break;
      case 'print':
        setPrintModalShow(true);
        setFiltered(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className={showFilter ? '' : 'd-none'}>
        <OffdayReportFilters formRequest={handleFilters} isSubmit={isSubmitting} />
      </div>
      <div className={showFilter ? 'd-none' : ''}>
        <Row>
          <Col md={6} className="mt-3">
            <Button variant="primary" onClick={() => setShowFilter(true)}>
              <Icon icon="chevron-back" width={15} />
              <span className="arial-dt">Volver al filtro</span>
            </Button>
          </Col>
        </Row>
        <DataTableDT
          onRequest={onRequest}
          columns={columns()}
          data={filteredEmployees}
          totalRows={amount}
          withMassActions={ability.can('index', 'HolidayReport')}
          massActions={<ReportActions disabled={onRequest} handleClick={handleMassAction} dates={dateRange} isArial />}
          resourceRequest={handleRequest}
          moreData={moreData}
          expandableRows
          expandableRowsComponent={<MyExpander dateRange={dateRange} />}
          expandableRowExpanded={() => true}
          progressPending={onRequest}
          progressComponent={<LoadingTranslation />}
          searchFirstStart={searchFirstStart}
        />
      </div>
      <div style={{ display: 'none' }}>
        <OffdayReportToPrint data={printLog} ref={componentRef} />
      </div>
      <DefaultModal
        title="Exportar Reporte de Festivos"
        body="¿Estás seguro que quieres exportar el reporte de Festivos en formato Excel?"
        show={modalShow}
        handleClose={() => {
          setModalShow(false);
          setSubmitting(false);
          setFiltered(false);
        }}
        handleConfirm={() => {
          if (isFiltered) {
            handleOffdayReportRequest(query, '.xlsx', 'text');
          } else {
            handleRequest(query, '.xlsx', 'text');
          }
          setModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <DefaultModal
        title="Exportar Reporte de Festivos"
        body="¿Estás seguro que quieres exportar el reporte de Festivos en formato Word?"
        show={wordModalShow}
        handleClose={() => {
          setWordModalShow(false);
          setSubmitting(false);
          setFiltered(false);
        }}
        handleConfirm={() => {
          if (isFiltered) {
            handleOffdayReportRequest(query, '.docx', 'text');
          } else {
            handleRequest(query, '.docx', 'text');
          }
          setWordModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <DefaultModal
        title="Exportar Reporte de Festivos"
        body="¿Estás seguro que quieres exportar el reporte de Festivos en formato PDF?"
        show={pdfModalShow}
        handleClose={() => {
          setPdfModalShow(false);
          setSubmitting(false);
          setFiltered(false);
        }}
        handleConfirm={() => {
          if (isFiltered) {
            handleOffdayReportRequest(query, '.pdf', 'text');
          } else {
            handleRequest(query, '.pdf', 'text');
          }
          setPdfModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <DefaultModal
        title="Imprimir Reporte de Domingos y Festivos"
        body="¿Estás seguro que quieres imprimir el reporte de Festivos?"
        show={printModalShow}
        handleClose={() => {
          setPrintModalShow(false);
          setSubmitting(false);
          setFiltered(false);
        }}
        handleConfirm={() => {
          if (isFiltered) {
            handleOffdayReportRequest(filters, '.print', 'text');
          } else {
            handleRequest(filters, '.print', 'text');
          }
          setPrintModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default OffdaysReportDataTable;
