import React, { useMemo, useState } from 'react';
import { Col, Row, Spinner, Button } from 'react-bootstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { DefaultModal, FormikSelect, SimpleCenteredModal } from '../../components';
import ImportForm from './ImportForm';
import RemunerationProcessHistoriesDataTable from '../PayrollProcess/RemunerationProcessHistoriesDataTable';

const DEFAULT_MODAL = {
  title: '',
  body: <></>,
  show: false,
  action: function empty() {}
};

const RemunerationHistoriesIndex = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [year, setYear] = useState(moment().year() - 1);
  const [modalState, setModalState] = useState(DEFAULT_MODAL);
  const [simpleModalState, setSimpleModalState] = useState(DEFAULT_MODAL);
  const [moreData, setMoreData] = useState(false);
  const years = useMemo(() => Array.from(Array(100), (e, i) => ({ label: i + 2020, value: i + 2020 })), []);
  const { currentCompany } = useSelector(state => state.auth);

  const handleShowImportModal = () => {
    setSimpleModalState({
      title: 'Importar Nuevo Historico',
      body: (
        <ImportForm
          year={year}
          setMoreData={setMoreData}
          setSimpleModalState={setSimpleModalState}
          setModalState={setModalState}
        />
      ),
      show: true
    });
  };

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="m-top mb-4">
        <Col>
          <h2 className="m-top mt-1 mb-1">Históricos de Remuneraciones</h2>
        </Col>
        <Col md={3}>
          <FormikSelect
            options={years}
            label=""
            defaultValue={year}
            value={year}
            onChange={data => {
              setYear(data ? data.value : '');
              setMoreData(!moreData);
            }}
            placeholder="Selecciona un año"
          />
        </Col>
        {/* {ability.can('create', 'PayrollProcess') && ( */}
        <Col md={3}>
          <Button variant="primary" block onClick={handleShowImportModal}>
            Importar
          </Button>
        </Col>
        {/* )} */}
      </Row>
      {currentCompany?.id && (
        <RemunerationProcessHistoriesDataTable
          moreData={moreData}
          setOnRequest={setOnRequest}
          onRequest={onRequest}
          setMoreData={setMoreData}
          customParams={{
            year,
            filter_by_company: currentCompany.id,
            historical: true
          }}
        />
      )}
      <DefaultModal
        title={modalState.title}
        body={modalState.body}
        show={modalState.show}
        disabled={modalState.disabled}
        handleClose={() => setModalState(state => ({ ...state, disabled: false, show: false }))}
        handleConfirm={() => modalState.action()}
        titleBtnClose="Cancelar"
        withConfirm={false}
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        title={simpleModalState.title}
        body={simpleModalState.body}
        show={simpleModalState.show}
        onHide={() => setSimpleModalState(state => ({ ...state, show: false }))}
      />
    </>
  );
};

export default RemunerationHistoriesIndex;
