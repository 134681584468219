import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DefaultModal } from '../../../../components';
import AdvancePaymentProcesses from '../Datatables/AdvancePaymentProcesses';
import { PayrollProcessContext } from '../../context/PayrollProcessProvider';
import ModalWarningBody from '../ModalWarningBody';

const ERROR_STATE = {
  CANCEL_ERROR_PROCESS: false,
  CONTINUE_ERROR: false
};

const MODAL_STATE = {
  show: false,
  title: '',
  modalConfirm: false,
  disabled: false,
  handleConfirm: () => {},
  body: <></>
};

const FirstStep = ({ handleNextStep, handleSelectedRows, selectedRows = [], onlyShow }) => {
  const { payrollProcess } = useContext(PayrollProcessContext);
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [errorState, setErrorState] = useState(ERROR_STATE);
  const [modalState, setModalState] = useState(MODAL_STATE);
  const { user } = useSelector(state => state.auth);
  const validateSelectedRows = () => {
    if (selectedRows.length === 0) return;
    const notClosed = selectedRows.filter(row => {
      return row.status !== 'close';
    });

    const associated = selectedRows.filter(row => {
      return row.payroll_status === 'associated';
    });

    if (notClosed.length > 0) {
      setModalShow(true);
      setModalBody(
        <>
          <p>Ha seleccionado períodos que aún no se encuentran CERRADOS:</p>
          <ul>
            {notClosed.map(row => (
              <li key={row.id}>{row.name}</li>
            ))}
          </ul>
          <p>Debe cerrarlos primeramente, en el módulo de Anticipos.</p>
        </>
      );
      return;
    }

    if (associated.length > 0) {
      setModalShow(true);
      setModalBody(
        <>
          <p>Ha seleccionado períodos que ya se encuentran asociados a un proceso de nómina:</p>
          <ul>
            {associated.map(row => (
              <li key={row.id}>{row.name}</li>
            ))}
          </ul>
        </>
      );
      return;
    }
    handleNextStep();
  };

  const validateOnCancelAssociation = handleConfirm => {
    if (payrollProcess.status === 'closed') {
      setModalShow(true);
      setModalBody(
        <>
          <p>
            No se pueden anular procesos de Anticipos que ya se encuentran asociados a un proceso de Nómina cerrado.
          </p>
        </>
      );
      return;
    }

    const payrollPending = selectedRows.filter(
      row => row.status === 'close' && row.payroll_status === 'not_associated'
    );
    const notAssociated = selectedRows.filter(
      row => row.payroll_process_id !== payrollProcess.id && row.payroll_status === 'associated'
    );
    const notClosed = selectedRows.filter(row => row.status !== 'close');
    const allProcesses = [...payrollPending, ...notAssociated, ...notClosed];

    if (allProcesses.length > 0) {
      setErrorState({
        CANCEL_ERROR_PROCESS: allProcesses.length > 0,
        PROCESSES: allProcesses
      });
      setModalState({
        ...MODAL_STATE,
        show: true,
        modalConfirm: false,
        text: '',
        handleConfirm: () => handleConfirm(setModalState),
        title: 'Selección de Periodo a Anular'
      });
      return;
    }
    setErrorState(ERROR_STATE);
    setModalState({
      ...MODAL_STATE,
      show: true,
      modalConfirm: true,
      handleConfirm: () => handleConfirm(setModalState),
      text: '¿Estás seguro que quieres anular los periodos seleccionados?',
      title: 'Selección de Periodo a Anular'
    });
  };

  return (
    <>
      {!onlyShow && (
        <Row className="border-line mr-1 ml-1">
          <Col className="top-header-light-gray justify-content-start border-radius-5">
            <p className="mt-0 text-left">
              <b>Etapa 1 - SELECCIONAR MES</b>
            </p>
          </Col>
          <Col sm={12} className="mt-2">
            <p>
              <b>Usuario {user?.employeeCard?.firstName}</b>
            </p>
            <p>
              En esta etapa del proceso, debe seleccionar el mes y año, del cual desea obtener la información de los
              anticipos a descontar, para posteriormente procesar en la nómina de sueldos.
            </p>
            <p>Debe tener presente, que la selección del período considera el proceso de anticipos cerrado.</p>
          </Col>
        </Row>
      )}
      <AdvancePaymentProcesses
        handleSelectedRows={handleSelectedRows}
        selectedRows={selectedRows}
        handleCancelAssociation={validateOnCancelAssociation}
        onlyShow={onlyShow}
      />
      {!onlyShow && (
        <Row className="mb-2 row">
          <Col />
          <Col md={2}>
            <Button variant="primary" disabled={selectedRows.length === 0} block onClick={() => validateSelectedRows()}>
              Continuar
            </Button>
          </Col>
        </Row>
      )}

      <DefaultModal
        title="Seleccion de Periodo"
        withConfirm={false}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        titleBtnClose="Cancelar"
      />

      <DefaultModal
        title={modalState.title}
        withConfirm={modalState.modalConfirm}
        body={<ModalWarningBody errorState={errorState} text={modalState.text} />}
        show={modalState.show}
        disabled={modalState.disabled}
        handleConfirm={modalState.handleConfirm}
        handleClose={() => setModalState(oldState => ({ ...oldState, show: false }))}
        titleBtnClose="Cancelar"
      />
    </>
  );
};

export default FirstStep;
