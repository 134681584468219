import React from 'react';
import { Table } from 'react-bootstrap';

const ShiftFilterDataTable = ({ shiftLogs }) => {
  const renderData = () => {
    if (shiftLogs.length === 0) {
      return (
        <div className="text-center pt-3 pb-3">
          <p className="text-muted">SIN CAMBIOS O MODIFICACIONES DE TURNOS EN EL PERIODO CONSULTADO</p>
        </div>
      );
    }
    return (
      <>
        <Table bordered size="sm">
          <thead style={{ backgroundColor: '#EBEBEB' }}>
            <tr>
              <th className="text-center align-middle">Fecha Asignación</th>
              <th className="text-center align-middle">Turno Asignado</th>
              <th className="text-center align-middle">Tipo de Turno</th>
              <th className="text-center align-middle">Fecha de Asignación de Nuevo Turno</th>
              <th className="text-center align-middle">Nuevo Turno Asignado</th>
              <th className="text-center align-middle">Solicitante</th>
            </tr>
          </thead>
          {shiftLogs.map(log => (
            <tbody style={{ fontWeight: 'normal' }}>
              <tr>
                <td className="text-center">{log.original_shift_date}</td>
                <td className="text-left">{log.original_turn_name}</td>
                <td className="text-center">{log.shift_type}</td>
                <td className="text-center">{log.new_shift_date}</td>
                <td className="text-left">{log.new_turn_name}</td>
                <td className="text-center">{log.solicitor}</td>
              </tr>
            </tbody>
          ))}
        </Table>
      </>
    );
  };

  return <>{renderData()}</>;
};

export default ShiftFilterDataTable;
