import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Field, getIn } from 'formik';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import {
  BasicTextArea,
  FormikDatePicker,
  FormikInput,
  FormikSelect,
  InputSelect,
  NestedAttributes,
  Genders
} from '../../../components';

import { relationTypes, identificationTypes, chargeTypes, familyAllowanceSections } from './FormOptions';
import { debounceIndexCountriesRequest, indexCountriesRequest } from '../../../requests/countries';
import { rutFormat, validRutInput } from '../../../services/utils';
import { useAuthorization } from '../../../services/hooks';

const FamilyGroupInput = ({
  errors,
  index,
  setFieldValue,
  setFieldTouched,
  touched,
  defaultValue,
  defaultOptions,
  familyGroupsAttribute,
  fetchCountries,
  handleNationalIdentificationFormat,
  values
}) => {
  const countryFieldName = `employee[contract][familyGroupsAttributes][${index}][country][label]`;
  const { relationType, identificationType, chargeType, familyAllowanceSection } = familyGroupsAttribute;
  const [myChargeTypes, setMyChargeTypes] = useState(chargeTypes);
  const onchangechargeType = (field, data) => {
    setFieldValue(field.name, data ? data.value : '');
    if (data.value === 'is_not_charge') {
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][chargeStartDate]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][chargeEndDate]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][chargeNumber]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][familyAllowanceSection]`, '');
    }
    if (data.value === 'simple') {
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][chargeNumber]`, '');
    }
    if (data.value === 'maternal' || data.value === 'charge_invalid') {
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][relationType]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][name]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][firstLastName]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][secondLastName]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][identificationType]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][nationalIdentification]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][countryId]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][birthdate]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][genderId]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][observation]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][chargeStartDate]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][chargeEndDate]`, '');
      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][chargeNumber]`, '1');
    }
  };

  useEffect(() => {
    const familyGroupsAttributes =
      values?.employee?.contract?.familyGroupsAttributes.filter(f => f._destroy !== 'true' && f.id !== null) || [];
    if (familyGroupsAttributes?.length > 0 && !['charge_invalid', 'maternal'].includes(chargeType)) {
      const chargeTypeValues = familyGroupsAttributes?.map(f => f?.chargeType);
      const excludeChargeInvalid = chargeTypeValues?.includes('charge_invalid');
      const excludeMaternal = chargeTypeValues?.includes('maternal');

      if (excludeChargeInvalid && !excludeMaternal) {
        setMyChargeTypes(chargeTypes.filter(f => f.value !== 'charge_invalid'));
      } else if (excludeMaternal && !excludeChargeInvalid) {
        setMyChargeTypes(chargeTypes.filter(f => f.value !== 'maternal'));
      } else if (excludeChargeInvalid && excludeMaternal) {
        setMyChargeTypes(chargeTypes.filter(f => f.value !== 'charge_invalid' && f.value !== 'maternal'));
      } else {
        setMyChargeTypes(chargeTypes);
      }
    } else {
      setMyChargeTypes(chargeTypes);
    }
  }, [values, chargeType]);

  return (
    <Col>
      <Row>
        <Col md={6}>
          <Field name={`employee[contract][familyGroupsAttributes][${index}][chargeType]`}>
            {({ field }) => (
              <>
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Carga"
                  placeholder="Seleccionar Tipo de Carga"
                  options={myChargeTypes}
                  defaultValue={chargeType}
                  onChange={data => onchangechargeType(field, data)}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              </>
            )}
          </Field>
        </Col>

        {['is_not_charge', 'simple'].includes(chargeType) && (
          <Col md={12}>
            <Field name={`employee[contract][familyGroupsAttributes][${index}][relationType]`}>
              {({ field }) => (
                <>
                  <FormikSelect
                    {...field}
                    abbr
                    label="Relación"
                    placeholder="Seleccionar Relación"
                    options={relationTypes}
                    defaultValue={relationType}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                </>
              )}
            </Field>
          </Col>
        )}
        {['is_not_charge', 'simple'].includes(chargeType) && (
          <Col md={4}>
            <Field name={`employee[contract][familyGroupsAttributes][${index}][name]`}>
              {({ field }) => (
                <>
                  <FormikInput
                    {...field}
                    abbr
                    label="Nombres"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                </>
              )}
            </Field>
          </Col>
        )}

        {['is_not_charge', 'simple'].includes(chargeType) && (
          <Col md={4}>
            <Field name={`employee[contract][familyGroupsAttributes][${index}][firstLastName]`}>
              {({ field }) => (
                <>
                  <FormikInput
                    {...field}
                    abbr
                    label="Primer Apellido"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                </>
              )}
            </Field>
          </Col>
        )}

        {['is_not_charge', 'simple'].includes(chargeType) && (
          <Col md={4}>
            <Field name={`employee[contract][familyGroupsAttributes][${index}][secondLastName]`}>
              {({ field }) => (
                <>
                  <FormikInput
                    {...field}
                    label="Segundo Apellido"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                </>
              )}
            </Field>
          </Col>
        )}

        {['is_not_charge', 'simple'].includes(chargeType) && (
          <Col md={4}>
            <Field name={`employee[contract][familyGroupsAttributes][${index}][identificationType]`}>
              {({ field }) => (
                <>
                  <FormikSelect
                    {...field}
                    abbr
                    defaultValue={identificationType}
                    label="Tipo de Identificación"
                    options={identificationTypes}
                    placeholder="Seleccionar Tipo de Identificación"
                    tooltipText=""
                    onChange={data => {
                      setFieldValue(`employee[contract][familyGroupsAttributes][${index}][nationalIdentification]`, '');
                      setFieldValue(field.name, data ? data.value : 'RUT');
                    }}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                </>
              )}
            </Field>
          </Col>
        )}
        {['is_not_charge', 'simple'].includes(chargeType) && (
          <Col md={4}>
            <Field name={`employee[contract][familyGroupsAttributes][${index}][nationalIdentification]`}>
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label={identificationType === 'rut' ? 'RUT' : 'Nº de Identificación'}
                  onChange={e => handleNationalIdentificationFormat(e, identificationType === 'rut')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        {['is_not_charge', 'simple'].includes(chargeType) && (
          <Col md={4}>
            <Field name={`employee[contract][familyGroupsAttributes][${index}][countryId]`}>
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  label="Nacionalidad"
                  placeholder="Seleccionar Nacionalidad"
                  defaultOptions={defaultOptions}
                  value={defaultValue}
                  request={fetchCountries}
                  onChange={data => {
                    setFieldValue(field.name, data.value);
                    setFieldValue(countryFieldName, data.label);
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        {['is_not_charge', 'simple'].includes(chargeType) && (
          <Col md={4}>
            <Field name={`employee[contract][familyGroupsAttributes][${index}][birthdate]`}>
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  isOutsideRange={day => moment().diff(day) < 0}
                  label="Fecha de Nacimiento"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}

        {['is_not_charge', 'simple'].includes(chargeType) && (
          <Genders modelKey={`employee[contract][familyGroupsAttributes][${index}]`} md={4} />
        )}

        {['simple'].includes(chargeType) && (
          <>
            <Col md={4}>
              <Field name={`employee[contract][familyGroupsAttributes][${index}][chargeStartDate]`}>
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    isOutsideRange={() => false}
                    label="Fecha de Inicio"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field name={`employee[contract][familyGroupsAttributes][${index}][chargeEndDate]`}>
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    isOutsideRange={() => false}
                    label="Fecha de Término"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        {['simple', 'maternal', 'charge_invalid'].includes(chargeType) && (
          <Col md={4}>
            <Field name={`employee[contract][familyGroupsAttributes][${index}][familyAllowanceSection]`}>
              {({ field }) => (
                <>
                  <FormikSelect
                    {...field}
                    abbr
                    label="Tramo asignación familiar"
                    placeholder="Seleccionar tramo asignación familiar"
                    options={familyAllowanceSections}
                    defaultValue={familyAllowanceSection}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                </>
              )}
            </Field>
          </Col>
        )}
      </Row>
      <Row>
        {['is_not_charge', 'simple'].includes(chargeType) && (
          <Col md={12}>
            <Field name={`employee[contract][familyGroupsAttributes][${index}][observation]`}>
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  label="Observación"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
      </Row>
    </Col>
  );
};

const FamilyGroupTab = ({ errors, touched, values, setFieldValue, setFieldTouched }) => {
  const [initialCountries, setInitialCountries] = useState([]);
  const { familyGroupsAttributes } = values?.employee?.contract;
  const dispatch = useDispatch();
  const createFamilyGroup = useAuthorization('create', 'FamilyGroup');
  const destroyFamilyGroup = useAuthorization('destroy', 'FamilyGroup');

  const resultFetchData = response => {
    const result = response.data.data;
    return result.map(element => ({
      label: element.label,
      value: element.value
    }));
  };

  const fetchInitialCountries = () => {
    indexCountriesRequest({
      dispatch,
      params: {
        nationality: true,
        sort_column: 'position',
        display_length: 100
      },
      successCallback: response => setInitialCountries(resultFetchData(response))
    });
  };

  useEffect(fetchInitialCountries, []);

  const fetchCountries = (inputValue, callback) => {
    debounceIndexCountriesRequest({
      dispatch,
      params: {
        nationality: true,
        name: inputValue,
        sort_column: 'position',
        display_length: 250
      },
      successCallback: response => {
        callback(resultFetchData(response));
      }
    });
  };

  const initialCountryValue = value => {
    const allCountries = initialCountries;
    if (value && value?.id && allCountries?.length > 0) {
      const myCountry = allCountries.find(f => f.value === value?.countryId);
      if (myCountry) {
        return myCountry;
      }
    }
    return undefined;
  };

  const handleNationalIdentificationFormat = (e, isRut) => {
    if (isRut && validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  const setNewfamilyGroup = () => {
    if (!values.newFamilyGroup) setFieldValue('employee[contract][newFamilyGroup]', true);
  };

  const familyGroupsInputs = () => {
    const mapResults = familyGroupsAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <FamilyGroupInput
          key={`family-group-${index.toString()}`}
          defaultOptions={initialCountries}
          defaultValue={initialCountryValue(body)}
          familyGroupsAttribute={body}
          fetchCountries={fetchCountries}
          handleNationalIdentificationFormat={handleNationalIdentificationFormat}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
          index={index}
          values={values}
        />
      );
    });

    mapResults.push(
      <Col>
        <Row>
          <Col className="sample-row">
            <FormikSelect label="Tipo de Carga" placeholder="Seleccionar Tipo de Carga" isDisabled />
          </Col>
        </Row>
      </Col>
    );
    return (
      <>
        <NestedAttributes
          removeFirstItem
          mapInputs={mapResults}
          arrayValues={familyGroupsAttributes}
          setFieldValue={setFieldValue}
          actionOnAdd={setNewfamilyGroup}
          valuePath="employee[contract][familyGroupsAttributes]"
          newAttributes={{
            birthdate: '',
            charge: false,
            chargeStartDate: '',
            chargeEndDate: '',
            countryId: '',
            firstLastName: '',
            genderId: '',
            name: '',
            nationalIdentification: '',
            observation: '',
            relationType: '',
            secondLastName: '',
            identificationType: '',
            chargeType: '',
            chargeNumber: '',
            familyAllowanceSection: ''
          }}
          canCreate={createFamilyGroup}
          canDestroy={destroyFamilyGroup}
        />
      </>
    );
  };

  return familyGroupsInputs();
};

export const yupFamilyGroup = Yup.object().shape({
  familyGroupsAttributes: Yup.array().of(
    Yup.object().shape({
      chargeType: Yup.number().required('Debes seleccionar un tipo de carga')
    })
  ),
  birthdate: Yup.date()
    .formatdate()
    .when('chargeType', {
      is: val => val === 'is_not_charge' || val === 'simple',
      then: Yup.date()
        .required('Debes seleccionar una fecha de nacimiento')
        .typeError('Debes ingresar una fecha de nacimiento válida')
    }),

  charge: Yup.boolean(),
  chargeStartDate: Yup.date()
    .formatdate()
    .when('chargeType', {
      is: val => val === 'simple',
      then: Yup.date().required('Debes seleccionar una fecha de inicio')
    })
    .when(['birthdate', 'chargeType', '_destroy'], (birthdate, chargeType, _destroy, schema) => {
      if (chargeType === 'simple' && birthdate && !_destroy) {
        return schema.min(birthdate, 'La fecha de inicio debe ser mayor a la fecha de nacimiento');
      }
      return schema.nullable();
    }),
  chargeEndDate: Yup.date()
    .formatdate()
    .when('chargeType', {
      is: val => val === 'simple',
      then: Yup.date().required('Debes seleccionar una fecha de término')
    })
    .when(['chargeStartDate', 'chargeType', '_destroy'], (chargeStartDate, chargeType, _destroy, schema) => {
      if (chargeType === 'simple' && chargeStartDate && !_destroy) {
        return schema.min(chargeStartDate, 'La fecha de término debe ser mayor a la fecha de inicio');
      }
      return schema.nullable();
    }),
  countryId: Yup.string()
    .when('chargeType', {
      is: val => val === 'is_not_charge',
      then: Yup.string().required('Debes seleccionar una nacionalidad')
    })
    .when('chargeType', {
      is: val => val === 'simple',
      then: Yup.string().required('Debes seleccionar una nacionalidad')
    }),
  firstLastName: Yup.string()
    .when('chargeType', {
      is: val => val === 'is_not_charge',
      then: Yup.string()
        .required('Debes ingresar un apellido')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
    })
    .when('chargeType', {
      is: val => val === 'simple',
      then: Yup.string()
        .required('Debes ingresar un apellido')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
    }),
  identificationType: Yup.string()
    .when('chargeType', {
      is: val => val === 'is_not_charge',
      then: Yup.string().required('Debes seleccionar un tipo de documento')
    })
    .when('chargeType', {
      is: val => val === 'simple',
      then: Yup.string().required('Debes seleccionar un tipo de documento')
    }),
  name: Yup.string()
    .when('chargeType', {
      is: val => val === 'is_not_charge',
      then: Yup.string()
        .required('Debes ingresar un nombre')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
    })
    .when('chargeType', {
      is: val => val === 'simple',
      then: Yup.string()
        .required('Debes ingresar un nombre')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
    }),

  nationalIdentification: Yup.string()
    .when('chargeType', {
      is: val => val === 'is_not_charge',
      then: Yup.string()
        .required('Debes ingresar un número de identificación')
        .when('identificationType', (identificationType, schema) =>
          identificationType === 'rut'
            ? schema.rut('Debes ingresar un RUT válido')
            : schema.alphanumeric('Deben ser caracteres alfanuméricos')
        )
    })
    .when('chargeType', {
      is: val => val === 'simple',
      then: Yup.string()
        .required('Debes ingresar un número de identificación')
        .when('identificationType', (identificationType, schema) =>
          identificationType === 'rut'
            ? schema.rut('Debes ingresar un RUT válido')
            : schema.alphanumeric('Deben ser caracteres alfanuméricos')
        )
    }),

  observation: Yup.string()
    .alphanumeric('Deben ser caracteres alfanuméricos')
    .nullable(),
  relationType: Yup.string()
    .when('chargeType', {
      is: val => val === 'is_not_charge',
      then: Yup.string()
        .required('Debes seleccionar una relación')
        .nullable()
    })
    .when('chargeType', {
      is: val => val === 'simple',
      then: Yup.string()
        .required('Debes seleccionar una relación')
        .nullable()
    }),
  secondLastName: Yup.string()
    .max(120, 'Deben ser menos que 120 caracteres')
    .alphanumeric('Deben ser caracteres alfanuméricos')
    .nullable(),
  chargeNumber: Yup.number()
    .when('chargeType', {
      is: val => val === 'maternal',
      then: Yup.number()
        .positive('Debe ser mayor a 0')
        .max(99, 'Debe ser de 2 dígitos')
        .required('Debes ingresar el número de carga')
    })
    .when('chargeType', {
      is: val => val === 'charge_invalid',
      then: Yup.number()
        .positive('Debe ser mayor a 0')
        .max(99, 'Debe ser de 2 dígitos')
        .required('Debes ingresar el número de carga')
    }),

  familyAllowanceSection: Yup.string()
    .when('chargeType', {
      is: val => val === 'simple',
      then: Yup.string()
        .required('Debes ingresar un tramo asignación familiar')
        .nullable()
    })
    .when('chargeType', {
      is: val => val === 'maternal',
      then: Yup.string()
        .required('Debes ingresar un tramo asignación familiar')
        .nullable()
    })
    .when('chargeType', {
      is: val => val === 'charge_invalid',
      then: Yup.string()
        .required('Debes ingresar un tramo asignación familiar')
        .nullable()
    }),
  chargeType: Yup.string().required('Debes seleccionar una tipo de carga')
});

export default FamilyGroupTab;
