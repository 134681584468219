import React, { useEffect, useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import { FormikTimePicker, FormikSelect, FormikInput } from '../../components';
import FormikSelectClear from '../../components/Utils/Select/FormikSelectClear';
import {
  payrollCostCenterRequest,
  payrollEmployeesRequest,
  payrollJobManagementRequest
} from '../../requests/payrolls';
import { camelCaseEmptyStringRecursive } from '../../services/utils';

const Filters = props => {
  const { errors, touched, setFieldValue, values, onHide, isSubmitting, setFieldTouched } = props;
  const [costCenters, setCostCenters] = useState([]);
  const [areas, setAreas] = useState([]);
  const [branchOffice, setBranchOffice] = useState([]);
  const [employees, setEmployees] = useState([]);
  const { currentCompany } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { costCenterId, jobManagementId, branchOfficeId, employeeId, filterNationalId } = values;

  const successEmployees = data => {
    const parsedEmployees = camelCaseEmptyStringRecursive(data);
    return parsedEmployees.map(employee => ({
      label: employee.startDateContract ? `${employee.fullName} - (${employee.startDateContract})` : employee.fullName,
      value: employee.payrollId
    }));
  };

  const fetchEmployeesPayrolls = params => {
    payrollEmployeesRequest({
      dispatch,
      params: {
        ...params,
        filter_by_company: currentCompany.id,
        paginate: false
      },
      successCallback: response => {
        const allEmployees = successEmployees(response.data.data);
        const dataToGroupSearch = [{ id: 'Todos', label: 'Todos', value: 'all' }, ...allEmployees];
        setEmployees(response.data.data.length > 0 ? dataToGroupSearch : []);
      }
    });
  };

  const handleEmployeeFilters = () => {
    let params = {};
    if (values.parsedMonth && values.parsedYear) {
      setFieldValue('employee', []);
      setFieldValue('employeeId', '');
      params = { month_date: values.parsedMonth, year_date: values.parsedYear };
      fetchEmployeesPayrolls(params);
    }
  };

  const fetchCostCentersName = params => {
    payrollCostCenterRequest({
      dispatch,
      params: {
        ...params,
        distinct_cost_center_name: true,
        filter_by_company: currentCompany.id,
        paginate: false
      },
      successCallback: response => {
        setCostCenters(response.data.data);
      }
    });
  };

  const fetchJobManagementsName = params => {
    payrollJobManagementRequest({
      dispatch,
      params: {
        ...params,
        distinct_job_management_name: true,
        filter_by_company: currentCompany.id,
        paginate: false
      },
      successCallback: response => {
        setAreas(response.data.data);
      }
    });
  };

  const getUniqueBranchOffices = dataArray => {
    const uniqueBranchOffices = new Map();

    dataArray.forEach(item => {
      item.branch_offices.forEach(branchOfficeFilter => {
        uniqueBranchOffices.set(branchOfficeFilter.name, branchOfficeFilter.id);
      });
    });

    const uniqueBranchOfficesArray = Array.from(uniqueBranchOffices, ([label, value]) => ({ label, value }));
    return uniqueBranchOfficesArray;
  };

  const fetchBranchOfficesName = params => {
    payrollEmployeesRequest({
      dispatch,
      params: {
        ...params,
        distinct_branch_offices_name: true,
        filter_by_company: currentCompany.id,
        paginate: false
      },
      successCallback: response => {
        const uniqueBranchOffices = getUniqueBranchOffices(response.data.data);
        setBranchOffice(uniqueBranchOffices);
      }
    });
  };

  const handleFilterCostCentersName = () => {
    let params;
    if (values.parsedMonth && values.parsedYear) {
      setFieldValue('costCenterId', '');
      params = { month_date: values.parsedMonth, year_date: values.parsedYear };
      fetchCostCentersName(params);
    }
  };

  const handleFilterJobManagementsName = () => {
    let params;
    if (values.parsedMonth && values.parsedYear) {
      setFieldValue('jobManagementId', '');
      params = { month_date: values.parsedMonth, year_date: values.parsedYear };
      fetchJobManagementsName(params);
    }
  };

  const handleFilterBranchOfficesName = () => {
    let params;
    if (values.parsedMonth && values.parsedYear) {
      setFieldValue('branchOfficeId', '');
      params = { month_date: values.parsedMonth, year_date: values.parsedYear };
      fetchBranchOfficesName(params);
    }
  };

  const setFilterFields = (fieldName, value, allOptions) => {
    setFieldValue('filterName', '');
    setFieldValue('filterValue', '');
    const filter = {
      costCenterId: 'Centro de Costos: ',
      jobManagementId: 'Área: ',
      branchOfficeId: 'Lugar de Prestación de Servicios: ',
      employee: 'Trabajador: '
    };
    const filterName = filter[fieldName];
    if (!filterName || !value) return;
    const field = allOptions.find(option => option.value === value?.value);
    setFieldValue('filterName', filterName);
    setFieldValue('filterValue', field.label);
  };

  const disabledButton = employeeId || filterNationalId.length > 0;

  useEffect(handleFilterBranchOfficesName, [values.parsedMonth, values.parsedYear]);
  useEffect(handleFilterJobManagementsName, [values.parsedMonth, values.parsedYear]);
  useEffect(handleFilterCostCentersName, [values.parsedMonth, values.parsedYear]);
  useEffect(handleEmployeeFilters, [values.parsedMonth, values.parsedYear]);

  return (
    <Form>
      <Card style={{ zIndex: 'unset' }}>
        <Row>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className="top-header-green-dt card-header-title-dt pt-1 pb-1">Busqueda por periodo</Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={12} md={12} xs={12}>
                    <Field name="parsedMonth">
                      {({ field }) => (
                        <FormikTimePicker
                          {...field}
                          abbr
                          label="Mes y Año de proceso"
                          placeholder="Seleccionar mes y año de proceso"
                          dateFormat="MMMM - yyyy"
                          showMonthYearPicker
                          setLocale
                          onChange={date => {
                            const formatedMonth = moment(date, 'ddd MMM DD YYYY');
                            setFieldValue(field.name, date);
                            setFieldValue('filterMonth', formatedMonth.month());
                            setFieldValue('parsedYear', date);
                            setFieldValue('year', formatedMonth.year());
                          }}
                          selected={field.value}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className="top-header-green-dt card-header-title-dt pt-1 pb-1">Busqueda por empleado</Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="employee">
                      {({ field }) => (
                        <FormikSelectClear
                          {...field}
                          label="Trabajadores"
                          placeholder="Seleccionar Trabajador"
                          options={employees}
                          defaultValue={values.employee}
                          value={values.employee}
                          isClearable
                          onChange={data => {
                            setFieldValue(field.name, data || []);
                            setFieldValue('employeeId', data?.value || '');
                            setFilterFields(field.name, data, employees);
                          }}
                          setFieldTouched={() => setFieldTouched(field.name)}
                          isDisabled={costCenterId || jobManagementId || branchOfficeId || filterNationalId}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="filterNationalId">
                      {({ field }) => (
                        <FormikInput
                          {...field}
                          label="RUT"
                          placeholder="Sin puntos y sin guion"
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          disabled={costCenterId || jobManagementId || branchOfficeId || employeeId}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className="top-header-green-dt card-header-title-dt pt-1 pb-1">Busqueda personalizada</Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="costCenterId">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Centro de Costos"
                          menuPlacement="top"
                          placeholder="Seleccionar Centro de Costos"
                          options={costCenters}
                          isClearable
                          onChange={data => {
                            setFieldValue(field.name, data ? data.value : '');
                            setFilterFields(field.name, data, costCenters);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isDisabled={disabledButton || jobManagementId || branchOfficeId}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="jobManagementId">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Área"
                          menuPlacement="top"
                          placeholder="Seleccionar Área"
                          options={areas}
                          isClearable
                          onChange={data => {
                            setFieldValue(field.name, data ? data.value : '');
                            setFilterFields(field.name, data, areas);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isDisabled={disabledButton || costCenterId || branchOfficeId}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="branchOfficeId">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Lugar de Prestación de Servicios"
                          menuPlacement="top"
                          placeholder="Seleccione Lugar de Prestación de Servicios"
                          options={branchOffice}
                          isClearable
                          onChange={data => {
                            setFieldValue(field.name, data ? data.value : '');
                            setFilterFields(field.name, data, branchOffice);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isDisabled={disabledButton || costCenterId || jobManagementId}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
      <Row className="d-flex justify-content-end mt-4 mb-4">
        <Col md={2}>
          <Button className="btn-block" type="submit" disabled={isSubmitting} onClick={onHide}>
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  parsedMonth: Yup.string().required('Este campo es obligatorio'),
  parsedYear: Yup.string()
    .required('Este campo es obligatorio')
    .nullable(),
  employee: Yup.string().test({
    name: 'employee',
    message: 'Este campo es obligatorio',
    test(value) {
      const filterNationalId = this.parent.filterNationalId || '';
      const centerCost = this.parent.costCenterId || '';
      const jobManagement = this.parent.jobManagementId || '';
      const branchOffice = this.parent.branchOfficeId || '';

      return !filterNationalId && !centerCost && !jobManagement && !branchOffice ? !!value : true;
    }
  }),
  filterNationalId: Yup.string().test({
    name: 'filterNationalId',
    message: 'Este campo es obligatorio',
    test(value) {
      const employeeId = this.parent.employeeId || '';
      const centerCost = this.parent.costCenterId || '';
      const jobManagement = this.parent.jobManagementId || '';
      const branchOffice = this.parent.branchOfficeId || '';

      return !employeeId && !centerCost && !jobManagement && !branchOffice ? !!value : true;
    }
  }),
  costCenterId: Yup.string().test({
    name: 'costCenterId',
    message: 'Este campo es obligatorio',
    test(value) {
      const employeeId = this.parent.employeeId || '';
      const filterNationalId = this.parent.filterNationalId || '';
      const jobManagement = this.parent.jobManagementId || '';
      const branchOffice = this.parent.branchOfficeId || '';
      return !employeeId && !filterNationalId && !jobManagement && !branchOffice ? !!value : true;
    }
  }),
  jobManagementId: Yup.string().test({
    name: 'jobManagementId',
    message: 'Este campo es obligatorio',
    test(value) {
      const employeeId = this.parent.employeeId || '';
      const filterNationalId = this.parent.filterNationalId || '';
      const centerCost = this.parent.costCenterId || '';
      const branchOffice = this.parent.branchOfficeId || '';
      return !employeeId && !filterNationalId && !centerCost && !branchOffice ? !!value : true;
    }
  }),
  branchOfficeId: Yup.string().test({
    name: 'branchOfficeId',
    message: 'Este campo es obligatorio',
    test(value) {
      const employeeId = this.parent.employeeId || '';
      const filterNationalId = this.parent.filterNationalId || '';
      const centerCost = this.parent.costCenterId || '';
      const jobManagement = this.parent.jobManagementId || '';
      return !employeeId && !filterNationalId && !centerCost && !jobManagement ? !!value : true;
    }
  })
});

const setInitialValues = () => {
  return {
    parsedMonth: '',
    parsedYear: '',
    employees: [],
    employee: '',
    employeeId: '',
    filterNationalId: '',
    filterMonth: '',
    year: '',
    format: '',
    costCenterId: '',
    jobManagementId: '',
    branchOfficeId: '',
    filterName: '',
    filterValue: ''
  };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  const allValues = { ...values };
  if (!values.filterName && values.filterNationalId) {
    allValues.filterName = 'Rut: ';
    allValues.filterValue = values.filterNationalId;
  }
  formRequest(allValues, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(Filters);
