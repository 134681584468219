import React from 'react';

const BodyModalPayroll = ({ messages, bodyMessages }) => {
  return (
    <>
      {bodyMessages}
      {messages && (
        <ul>
          {messages?.map(message => (
            <li>{message}</li>
          ))}
        </ul>
      )}
    </>
  );
};

export default BodyModalPayroll;
