import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { LinkBtn, Icon } from '../../components';
import ConceptForm from './Form/ConceptForm';
import { emptyConcept } from './Form/FormOptions';
import useConceptHook from './useConceptHook';

const ConceptEdit = props => {
  const { match, location } = props;
  const { tab } = location.state;
  const balanceId = match.params.id;

  const [balance, setBalance] = useState(emptyConcept);
  const { handleUpdateConcept, handleFetchConcept } = useConceptHook();

  const fetchConcept = () => {
    handleFetchConcept(balanceId, setBalance);
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchConcept, []);

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to={`/remunerations_settings?tab=${tab}`}>
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">Editar Concepto {balance.code}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ConceptForm
            balance={balance}
            action="edit"
            formRequest={(conceptChanges, setSubmitting) => handleUpdateConcept(conceptChanges, setSubmitting, tab)}
            submitVariant="primary"
          />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(ConceptEdit);
