import React from 'react';
import { Field, getIn } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { FormikCheckBox, FormikDatePicker, FormikInput, FormikSelect } from '../../../components';

const CustomFieldTab = ({ customFields, errors, touched, setFieldValue, setFieldTouched }) => {
  const setCommonFieldValues = (fieldPath, customField) => data => {
    setFieldValue(`${fieldPath}[value]`, data);
    setFieldValue(`${fieldPath}[custom_field_id]`, customField.id);
    setFieldValue(`${fieldPath}[name]`, customField.name);
  };

  const renderCustomField = (customField, index) => {
    const options = customField.custom_field_types_attributes.map(option => ({
      label: option.name,
      value: String(option.id)
    }));
    const contractPath = `employee[contract][extraFieldsAttributes][${index}]`;
    const employeePath = `employee[extraFieldsAttributes][${index}]`;
    const isContract = customField.field_display === 'contract_display';
    const fieldPath = isContract ? contractPath : employeePath;

    const handleChange = setCommonFieldValues(fieldPath, customField);

    switch (customField.field_type) {
      case 'text_field':
        return (
          <Field name={`${fieldPath}[value]`} key={index}>
            {({ field }) => (
              <FormikInput
                {...field}
                label={customField.name}
                onChange={e => {
                  if (e.target.value.length <= 50) {
                    handleChange(e.target.value);
                  }
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        );
      case 'number':
        return (
          <Field name={`${fieldPath}[value]`} key={index}>
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="number"
                label={customField.name}
                onChange={e => handleChange(e.target.value)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        );
      case 'date':
        return (
          <Field name={`${fieldPath}[value]`} key={index}>
            {({ field }) => (
              <FormikDatePicker
                {...field}
                value={field.value}
                onDateChange={date => handleChange(date ? date.format('DD/MM/YYYY') : '')}
                isOutsideRange={() => false}
                label={customField.name}
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        );
      case 'selector':
        return (
          <Field name={`${fieldPath}[value]`} key={index}>
            {({ field }) => {
              const selectedOption = options.find(option => option.value === field.value);
              return (
                <FormikSelect
                  {...field}
                  label={customField.name}
                  placeholder={`Seleccionar ${customField.name}`}
                  options={options}
                  defaultValue={selectedOption ? selectedOption.value : null}
                  onChange={data => handleChange(data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              );
            }}
          </Field>
        );
      case 'boolean':
        return (
          <Field name={`${fieldPath}[value]`} key={index}>
            {({ field }) => {
              return (
                <FormikCheckBox
                  {...field}
                  margin="mt-4"
                  value={field.value === '1' || field.value === true}
                  checked={field.value === '1' || field.value === true}
                  field={field}
                  onChange={e => handleChange(e.target.checked)}
                  label={customField.name}
                  type="switch"
                  tooltipText={customField.description}
                />
              );
            }}
          </Field>
        );
      default:
        return null;
    }
  };

  const renderCustomFieldRows = () => {
    const rows = [];
    for (let i = 0; i < customFields.length; i += 2) {
      rows.push(
        <Row key={i}>
          <Col md={6}>{renderCustomField(customFields[i], i)}</Col>
          {customFields[i + 1] && <Col md={6}>{renderCustomField(customFields[i + 1], i + 1)}</Col>}
        </Row>
      );
    }
    return rows;
  };

  return <div>{renderCustomFieldRows()}</div>;
};

export default CustomFieldTab;
