import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns, ActiveDot, GenericActiveDot } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'SIGLA',
    selector: 'acronym',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACTIVO',
    selector: 'is_active',
    cell: item => <ActiveDot item={item} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'PRECARGADO',
    selector: 'is_preloaded',
    cell: item => <GenericActiveDot item={item} field="is_preloaded" />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => {
      if (item.is_preloaded) {
        return (
          <BasicActionBtns
            item={item}
            resource="BookkeeperDocumentType"
            clickHandler={clickHandler}
            withDestroy={false}
            withEdit={false}
          />
        );
      }

      return <BasicActionBtns item={item} resource="BookkeeperDocumentType" clickHandler={clickHandler} />;
    },
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
