import React from 'react';
import memoize from 'memoize-one';

export default memoize(() => [
  {
    name: 'NOMBRE',
    selector: 'full_name',
    accessor: 'full_name',
    grow: '1'
  },
  {
    name: 'RUT',
    selector: 'parsed_national_identification',
    accessor: 'parsed_national_identification',
    grow: '1',
    sortable: true,
    center: true
  },
  {
    name: 'NOMBRE DEL PROCESO',
    selector: 'ap_process_name',
    accessor: 'ap_process_name',
    grow: '1',
    sortable: true,
    center: true
  },
  {
    name: 'MONTO DE ANTICIPO',
    selector: 'parsed_amount',
    accessor: 'parsed_amount',
    grow: '1',
    center: true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    accessor: 'status',
    grow: '1',
    cell: row => {
      switch (row.status) {
        case 'Aprobado':
          return (
            <span className="text-muted" data-tag="allowRowEvents">
              APROBADO
            </span>
          );

        default:
          return <span className="text-muted">{row.data_process_status}</span>;
      }
    }
  },
  {
    name: 'CONCEPTO',
    selector: 'balance_name',
    accessor: 'balance_name',
    center: true
  },
  {
    name: 'MES DE REMUNERACION',
    selector: 'start_date',
    accessor: 'start_date',
    center: true
  }
]);
