import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';

import { markingCorrectionAttendanceReport } from '../../../requests/legalreports';
import { sendAlert } from '../../../actions/utils';
import { ComponentDataTable, SimpleCenteredModal, DefaultModal } from '../../../components';

import AttendanceReplaceForm from './AttendanceReplaceForm';

import AttendanceChageRejectForm from './AttendanceChageRejectForm';
import { AbilityContext } from '../../../config/abilityContext';
import Filters from './Filters';
import AttendanceShow from './AttendanceShow';
import MassiveActions from './MassiveActios/MassiveActions';
import useMassiveActionHook from './MassiveActios/useMassiveActionHook';
import {
  createAttendanceChangeRequest,
  approvedAttendanceChangeRequest,
  rejectAttendanceChangeRequest
} from '../../../requests/AttendanceChange';

import { camelCaseEmptyStringRecursive } from '../../../services/utils';

export const LoadingTranslation = () => {
  return <h3>Cargando...</h3>;
};

const MarkingCorrectionDatatable = ({ moreData, setMoreData, columns, branchOffices }) => {
  const ability = useAbility(AbilityContext);
  const [onRequest, setOnRequest] = useState(false);
  const [attendances, setAttendances] = useState([]);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [simpleModalBody, setSimpleModalBody] = useState({});
  const [simpleModalTitle, setSimpleModalTitle] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState('');
  const [size, setSize] = useState('lg');
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [amount, setAmount] = useState(0);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const [filters, setFilters] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const dispatch = useDispatch();
  const { currentCompany } = useSelector(state => state.auth);

  const handleModalClose = () => {
    setCenterModalShow(false);
    setModalShow(false);
    setSimpleModalShow(false);
  };

  const handleSuccessRequest = status => {
    let message = '';
    setOnRequest(true);
    setMoreData(!moreData);
    switch (status) {
      case 'updated':
        message = 'Solicitud de Correción de Marcación realizada con exito.';
        break;
      case 'disabled':
        message = 'Registro de asistencia desactivado exitosamente.';
        break;
      case 'active':
        message = 'Registro de asistencia activado exitosamente.';
        break;
      case 'massive-updated':
        message = 'Solicitud masiva de Correción de Marcación realizada con exito.';
        break;
      case 'massive-approved':
        message = 'Aprobación  masiva de Correciones de marcacion realizada conexito.';
        break;
      case 'massive-rejected':
        message = 'Reachazo masivo de Correciones de Marcación realizado con exito.';
        break;
      case 'massive-disabled':
        message = 'Desactivacion masiva de Marcaciones realizada con exito.';
        break;
      case 'massive-active':
        message = 'Activación masiva de Marcaciones realizada con exito';
        break;
      case 'massive-create-attendance':
        message = 'Solicitud de creación masiva de asistencia realizada con exito.';
        break;
      default:
        message = 'No action';
        break;
    }

    dispatch(sendAlert({ kind: 'success', message }));
    handleModalClose();
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleRequest = (params, format, responseType = 'json') => {
    if (Object.keys(params).length > 0) {
      setOnRequest(true);
      const empParams = { ...params };
      delete empParams.date_to;
      delete empParams.date_from;
      markingCorrectionAttendanceReport({
        dispatch,
        params: { ...filters, ...empParams },
        format,
        responseType,
        successCallback: response => {
          const { data } = response;
          setAmount(data.metadata.amount);
          setAttendances(data.data);
          setOnRequest(false);
        },
        callback: () => setOnRequest(false)
      });
    }
  };
  const handleFilters = params => {
    setFilters(params);
    setSearchFirstStart(true);
    setClearSelectedRows(!clearSelectedRows);
    handleRequest(params);
  };

  const handleUpdateRequest = attendance => {
    setOnRequest(true);
    setMoreData(!moreData);
    createAttendanceChangeRequest({
      dispatch,
      params: {
        ...snakeCaseKeys(attendance),
        front_company_id: currentCompany?.id
      },
      successCallback: () => handleSuccessRequest('updated'),
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessApprove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud aprobada correctamente' }));
    setMoreData(!moreData);
    setOnRequest(false);
    handleModalClose();
  };

  const handleSuccessReject = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud rechazada correctamente' }));
    setMoreData(!moreData);
  };

  const handleUpdateModal = attendance => {
    const parsedAttendance = camelCaseRecursive(attendance);
    const correctionType = !parsedAttendance.arrivalMark && !parsedAttendance.departureMark ? 'add' : 'replacing';
    setSimpleModalBody(
      <AttendanceReplaceForm
        attendance={parsedAttendance}
        correctionType={correctionType}
        formRequest={handleUpdateRequest}
        handleModalClose={handleModalClose}
      />
    );

    setSimpleModalTitle('Correción de Marcación');
    setSimpleModalShow(true);
    setSize('lg');
  };

  const handleShowModal = item => {
    const parsedAttendance = camelCaseEmptyStringRecursive(item);
    setSimpleModalTitle(`Asistencia - ${item.name}`);
    setSimpleModalShow(true);
    setSimpleModalBody(<AttendanceShow attendance={parsedAttendance} />);
    setSize('lg');
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const handleApprovedAttendanceChange = item => {
    setOnRequest(true);
    const attendance = camelCaseEmptyStringRecursive(item);
    const { lastReplace } = attendance;
    approvedAttendanceChangeRequest(lastReplace.id, {
      dispatch,
      successCallback: handleSuccessApprove,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const handleDisabledAttendance = item => {
    setOnRequest(true);
    const attendance = camelCaseEmptyStringRecursive(item);
    const form = {
      attendance: {
        ...attendance,
        substitutionStatus: 'disabled',
        correctionType: 'replacing'
      }
    };

    createAttendanceChangeRequest({
      dispatch,
      params: snakeCaseKeys(form),
      successCallback: () => handleSuccessRequest('disabled'),
      failureCallback: handleFailureRequest
    });
  };

  const handleActivateAttendance = item => {
    setOnRequest(true);
    const attendance = camelCaseEmptyStringRecursive(item);
    const form = {
      attendance: {
        ...attendance,
        substitutionStatus: 'active',
        correctionType: 'replacing'
      }
    };

    createAttendanceChangeRequest({
      dispatch,
      params: snakeCaseKeys(form),
      successCallback: () => handleSuccessRequest('active'),
      failureCallback: handleFailureRequest
    });
  };

  const rejectAttendanceChange = (params, setSubmitting) => {
    setOnRequest(true);
    const { id } = params.attendanceChangeRequest;
    rejectAttendanceChangeRequest(id, {
      dispatch,
      params: snakeCaseKeys(params),
      successCallback: handleSuccessReject,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setSubmitting(false);
      }
    });
  };

  const handleRejectAttendanceChange = item => {
    const attendance = camelCaseEmptyStringRecursive(item);
    const { lastReplace } = attendance;
    handleModalClose();
    setModalTitle('Rechazar Corrección de marcacion de asistencia');
    setModalBody(<AttendanceChageRejectForm replaceId={lastReplace.id} formRequest={rejectAttendanceChange} />);
    setModalItem(item);
    setCenterModalShow(true);
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        handleShowModal(item);
        break;
      case 'edit':
        handleUpdateModal(item);
        break;
      case 'approved':
        setModalShow(true);
        setModalTitle('Aprobar Corrección de marcacion de asistencia');
        setModalBody('¿Estás seguro que deseas aprobar la solicitud?');
        setModalItem(item);
        setModalAction(() => handleApprovedAttendanceChange);
        break;

      case 'rejected':
        setModalTitle('Confirmación');
        setModalShow(true);
        setModalBody('¿Seeguro que deseas rechazar el registro?');
        setModalItem(item);
        setModalAction(() => handleRejectAttendanceChange);
        break;
      case 'disabled':
        setModalShow(true);
        setModalTitle('Desactivar Asistencia');
        setModalBody('¿Seguro que deseas desactivar el registro?');
        setModalItem(item);
        setModalAction(() => handleDisabledAttendance);
        break;
      case 'active':
        setModalShow(true);
        setModalTitle('Activar Asistencia');
        setModalBody('¿Seguro que deseas Activar este registro de asistencia?');
        setModalItem(item);
        setModalAction(() => handleActivateAttendance);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const employeeMenu = [
    ability.can('create', 'AttendanceChangeRequest'),
    ability.can('show', 'AttendanceChangeRequest'),
    ability.can('attendance_change_action', 'AttendanceChangeRequest'),
    ability.can('destroy', 'Attendance')
  ];

  const { handleMassiveEdit, handleConfirmMassive } = useMassiveActionHook(
    selectedRows,
    dispatch,
    setSimpleModalBody,
    setSimpleModalTitle,
    setSimpleModalShow,
    setModalShow,
    setModalTitle,
    setModalBody,
    setCenterModalShow,
    setModalAction,
    setSize,
    handleSuccessRequest,
    handleFailureRequest,
    handleModalClose,
    branchOffices
  );

  const handleMassAction = action => {
    switch (action) {
      case 'massive-edit':
        handleMassiveEdit();
        break;
      case 'massive-approved':
        handleConfirmMassive('approved');
        break;
      case 'massive-rejected':
        handleConfirmMassive('rejected');
        break;
      case 'massive-disabled':
        handleConfirmMassive('disabled');
        break;
      case 'massive-active':
        handleConfirmMassive('active');
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  return (
    <>
      <Filters formRequest={handleFilters} isSubmit={onRequest} />
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, employeeMenu)}
        data={attendances}
        totalRows={amount}
        resourceRequest={handleRequest}
        searchFirstStart={searchFirstStart}
        onRowClicked={null}
        progressPending={onRequest}
        progressComponent={<LoadingTranslation />}
        massActions={
          <MassiveActions handleClick={handleMassAction} disabled={!selectedCount} employeeMenu={employeeMenu} />
        }
        preName="attendance"
        selectableRows
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleSelectedRows}
        withMassActions
        moreData={moreData}
        withSearch={false}
      />

      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        size={size}
        show={simpleModalShow}
        onHide={handleModalClose}
      />

      <SimpleCenteredModal
        size="lg"
        title={modalTitle}
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        variantBtnClose="outline-info"
        variantBtnSave="primary"
      />
    </>
  );
};

export default MarkingCorrectionDatatable;
