import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Stepper, Step } from 'react-form-stepper';
import LoanProcessSelection from './LoanProcessSelection';
import '../stepsStyles.css';

const LoansCreditsIndex = ({ payrollProcess, onlyShow = false }) => {
  const [step, setStep] = useState(0);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleBackStep = () => {
    setStep(step - 1);
  };

  const handleFinishStep = () => {
    setStep(0);
  };

  const stepProps = {
    activeBgColor: '#29b48f',
    completedBgColor: '#1f896d'
  };

  const connectorProps = {
    size: 3,
    activeColor: '#29b48f',
    completedColor: '#1f896d'
  };

  return (
    <>
      {!onlyShow && (
        <Row className="mt-2 mr-1 ml-1">
          <Col sm={12}>
            <Stepper
              orientation="horizontal"
              activeStep={step}
              styleConfig={{ ...stepProps }}
              connectorStyleConfig={{ ...connectorProps }}
            >
              <Step label="Seleccionar Mes" />
              <Step label="Procesar" />
              <Step label="Resultado" />
            </Stepper>
          </Col>
        </Row>
      )}
      <LoanProcessSelection
        step={step}
        handleNextStep={handleNextStep}
        handleBackStep={handleBackStep}
        handleFinishStep={handleFinishStep}
        payrollProcess={payrollProcess}
        onlyShow={onlyShow}
      />
    </>
  );
};

export default LoansCreditsIndex;
