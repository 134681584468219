import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexLoanRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/loans`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createLoanRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', '/loans', {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });

export const updateLoanRequest = (id, { dispatch, params = {}, callback, failureCallback, successCallback }) =>
  ApiService.request('put', `/loans/${id}`, {
    dispatch,
    params,
    callback,
    failureCallback,
    successCallback
  });

export const approveLoanRequest = (id, { dispatch, params, formData, callback, successCallback, failureCallback }) =>
  ApiService.request('post', `/loans/${id}/approve`, {
    dispatch,
    params,
    formData,
    callback,
    successCallback,
    failureCallback
  });

export const rejectLoanRequest = (id, { dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', `/loans/${id}/reject`, {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });

export const deleteLoanRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/loans/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const exportLoansRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/loans/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const preImportLoanRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('get', '/loans/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importLoanRequest = ({ dispatch, params, formData, failureCallback, successCallback }) => {
  ApiService.request('post', '/loans/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
};

export const importTemplateLoanRequest = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/loans/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback
  });
};

export const debounceIndexLoanRequest = AwesomeDebouncePromise(indexLoanRequest, 300);
