import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { updateAdvancePaymentRequest } from '../../requests/advancePayments';
import DataProcessesForm from './DataProcessesForm';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { sendAlert } from '../../actions/utils';

const DataProcessesEdit = ({
  onSuccess,
  action,
  apAmountLimit,
  apDayLimit,
  nextMonth,
  employeeId,
  rrhh = false,
  apProcessId,
  advancePayment
}) => {
  const dispatch = useDispatch();

  useEffect(() => window.scrollTo(0, 0), []);

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdateRequest = (params, setSubmitting) => {
    updateAdvancePaymentRequest(advancePayment.advance_payment_id, {
      dispatch,
      params: snakeCaseKeys(params),
      successCallback: () => onSuccess(),
      failureCallback: handleFailureRequest,
      callback: () => setSubmitting(false)
    });
  };

  return (
    <Row>
      <Col>
        <DataProcessesForm
          action={action || 'new'}
          nextMonth={nextMonth}
          advancePayment={camelCaseEmptyStringRecursive(advancePayment)}
          apAmountLimit={apAmountLimit}
          apDayLimit={apDayLimit}
          employeeId={employeeId}
          formRequest={handleUpdateRequest}
          rrhh={rrhh}
          apProcessId={apProcessId}
        />
      </Col>
    </Row>
  );
};

export default DataProcessesEdit;
