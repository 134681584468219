import React, { Component } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { FormikMaterialUiTimePicker, FormikCheckBox, FormikInput, FormikNumber } from '../../../components/index';
import daysOfWeek from './FormOptions';
import Icon from '../../../components/Icons';
import './style.scss';

class ShiftForm extends Component {
  dayCheckBoxValue = (day, index, element) => {
    const { setFieldValue, values } = this.props;
    if (element._dispatchListeners[0] === undefined) {
      values.shift.shiftDaysAttributes[index].send = !values.shift.shiftDaysAttributes[index].send;
    }
    setFieldValue(`shift[shiftDaysAttributes][${index}][weekDay]`, day);
  };

  render() {
    const { values, errors, touched, setFieldValue } = this.props;
    const { shiftDaysAttributes, collationTime, flexibleShift, agreedSchedule } = values.shift;
    return (
      <Form>
        <Row>
          <Col md={6}>
            <Field name="shift[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  disabled
                  label="Nombre"
                  placeholder="NOMBRE DE TURNO"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2}>
            <Field name="shift[parsedTolerance]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr
                  disabled
                  label="Tolerancia"
                  rightAddon="min"
                  placeholder="0"
                  fieldName="shift[tolerance]"
                  value={values.shift.tolerance}
                  tooltipSize="21"
                  tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2} className="mt-4 d-flex align-items-center">
            <Field name="shift[workHolidays]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  disabled
                  field={field}
                  label="Trabaja festivos"
                  tooltipSize="23"
                  tooltipText="Turno con días festivos incluídos"
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={2} className="mt-4 d-flex align-items-center">
            <Field name="shift[flexibleShift]">
              {({ field }) => (
                <FormikCheckBox {...field} custom field={field} label="Turno flexible" tooltipSize="23" disabled />
              )}
            </Field>
          </Col>
          {flexibleShift && (
            <>
              <Col xs={4} md={2}>
                <Field name="shift[initialTime]">
                  {({ field }) => (
                    <FormikMaterialUiTimePicker
                      {...field}
                      abbr
                      timeSelector
                      label="Hora de Inicio"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled
                    />
                  )}
                </Field>
              </Col>
              <Col xs={4} md={2}>
                <Field name="shift[maximumStartTime]">
                  {({ field }) => (
                    <FormikMaterialUiTimePicker
                      {...field}
                      abbr
                      timeSelector
                      label="Tiempo Max. Inicio"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled
                    />
                  )}
                </Field>
              </Col>
              <Col xs={4} md={2}>
                <Field name="shift[workHours]">
                  {({ field }) => (
                    <FormikMaterialUiTimePicker
                      {...field}
                      abbr
                      timeSelector
                      label="Horas Trabajo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
        </Row>
        <Row className="mt-1">
          <Col xs={12} md={2} className="d-flex align-items-center">
            <Field name="shift[lunchCheck]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  disabled
                  field={field}
                  label="Marcar colación"
                  tooltipSize="23"
                  tooltipText="Indica si deben marcar el ingreso y salida de colación"
                />
              )}
            </Field>
          </Col>
          <Col xs={12} md={2} className="d-flex align-items-center">
            <Field name="shift[agreedSchedule]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  disabled
                  field={field}
                  label="Horario pactado"
                  tooltipSize="23"
                  tooltipText="Indica que deben marcar el ingreso y salida de colación en cada uno de los días seleccionados"
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2} className="d-flex align-items-center">
            <Field name="shift[collationTime]">
              {({ field }) => (
                <FormikCheckBox {...field} disabled custom field={field} label="Tiempo de colación" tooltipSize="23" />
              )}
            </Field>
          </Col>
          {collationTime && (
            <>
              <Col xs={6} md={2} className="d-flex align-items-center">
                <Field name="shift[parsedLunchTime]">
                  {({ field }) => (
                    <FormikNumber
                      {...field}
                      abbr
                      rightAddon="min"
                      placeholder="0"
                      label="Colación"
                      fieldName="shift[lunchTime]"
                      value={values.shift.lunchTime}
                      tooltipSize="21"
                      tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      disabled
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
        </Row>
        <hr />
        <p>Haz click en un día para activarlo o desactivarlo en este turno semanal.</p>
        {agreedSchedule ? (
          <Row className="justify-content-center">
            {daysOfWeek.map((item, index) => (
              <Col
                xs={5}
                sm={2}
                lg
                key={item.value}
                className={`shift-card-container${shiftDaysAttributes[index].send === true ? '' : '-disabled'}`}
              >
                <Row>
                  <Col md={12}>
                    {shiftDaysAttributes[index].send && (
                      <Icon
                        className="text-primary"
                        style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                        width={18}
                        icon="checkmark-circle"
                      />
                    )}
                    <p className="text-uppercase shift-card-title">{item.label}</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="align-items-center" md={12}>
                    <p className="text-uppercase shift-card-label">Desde</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][startTime]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          disabled
                          defaultValue="09:00"
                          onChange={time => setFieldValue(field.name, time)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col md={12}>
                    <p className="text-uppercase shift-card-label">Hasta</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][endTime]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          disabled
                          defaultValue="18:00"
                          onChange={time => setFieldValue(field.name, time)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="text-uppercase shift-card-title">Colación</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="align-items-center" md={12}>
                    <p className="text-uppercase shift-card-label">Desde</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][startLunch]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          disabled
                          defaultValue="14:00"
                          onChange={time => setFieldValue(field.name, time)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col md={12}>
                    <p className="text-uppercase shift-card-label">Hasta</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][endLunch]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          disabled
                          defaultValue="15:00"
                          onChange={time => setFieldValue(field.name, time)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="justify-content-center">
            {daysOfWeek.map((item, index) => (
              <Col xs={5} sm={2} lg key={item.value} className={`shift-card-container${'-disabled'}`}>
                <Row>
                  <Col md={12}>
                    {shiftDaysAttributes[index].send && (
                      <Icon
                        className="text-primary"
                        style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                        width={18}
                        icon="checkmark-circle"
                      />
                    )}
                    <p className="text-uppercase shift-card-title">{item.label}</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3 align-items-center" md={12}>
                    <p className="text-uppercase shift-card-label">Desde</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][startTime]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          defaultValue="09:00"
                          onChange={time => setFieldValue(field.name, time)}
                          disabled
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3" md={12}>
                    <p className="text-uppercase shift-card-label">Hasta</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][endTime]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          defaultValue="18:00"
                          onChange={time => setFieldValue(field.name, time)}
                          disabled
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        )}
      </Form>
    );
  }
}

const setInitialValues = props => {
  const { shiftDaysAttributes } = props.shift;
  let shiftDays = daysOfWeek;
  if (shiftDaysAttributes.length) {
    shiftDays = daysOfWeek.map(day => {
      const shiftDay = shiftDaysAttributes.filter(item => item.weekDay === day.value);
      if (shiftDay.length) {
        return { ...shiftDay[0], send: true };
      }
      return { ...day, send: false };
    });
  }

  return {
    shift: {
      ...props.shift,
      shiftDaysAttributes: shiftDays,
      shiftType: 'weekly'
    }
  };
};

const validationSchema = Yup.object().shape({
  shift: Yup.object().shape({
    lunchCheck: Yup.boolean(),
    workHolidays: Yup.boolean(),
    tolerance: Yup.number()
      .required('Debes ingresar un número')
      .min(0, 'Debe ser mayor o igual a 0'),
    lunchTime: Yup.number()
      .required('Debes ingresar tiempo de colación')
      .min(0, 'Debe ser mayor o igual a 0'),
    name: Yup.string().required('Debes ingresar un nombre'),
    shiftDaysAttributes: Yup.array().of(
      Yup.object().shape({
        endTime: Yup.string().when('send', {
          is: val => val,
          then: Yup.string().required('Debes ingresar hora de término'),
          otherwise: Yup.string().nullable()
        }),
        startTime: Yup.string().when('send', {
          is: val => val,
          then: Yup.string().required('Debes ingresar hora de inicio'),
          otherwise: Yup.string().nullable()
        }),
        send: Yup.boolean()
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ShiftForm);
