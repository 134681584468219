import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexAdvancePaymentProcessRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/advance_payment_processes`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createAdvancePaymentProcessRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', '/advance_payment_processes', {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });

export const updateAdvancePaymentProcessRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback }
) =>
  ApiService.request('put', `/advance_payment_processes/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback
  });

export const advancePaymentProcessCardsRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/advance_payment_processes/${id}/advance_payment_process_cards`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const deleteAdvancePaymentProcessRequest = (id, { dispatch, successCallback, callback, failureCallback }) =>
  ApiService.request('delete', `/advance_payment_processes/${id}`, {
    dispatch,
    successCallback,
    callback,
    failureCallback
  });

export const closeAdvancePaymentProcessRequest = (id, { dispatch, successCallback, callback, failureCallback }) =>
  ApiService.request('post', `/advance_payment_processes/${id}/close_month`, {
    dispatch,
    successCallback,
    callback,
    failureCallback
  });

export const showAdvancePaymentProcessRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/advance_payment_processes/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const massiveCloseMonthRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('post', `/advance_payment_processes/massive_close_month`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback
  });

export const massiveDeleteAdvancePaymentRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', `/advance_payment_processes/massive_destroy`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback
  });

export const massiveExportAdvancePaymentRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/advance_payment_processes/export.xlsx`, {
    dispatch,
    params,
    successCallback,
    responseType: 'text',
    failureCallback
  });

export const debounceIndexAdvancePaymentProcessRequest = AwesomeDebouncePromise(indexAdvancePaymentProcessRequest, 300);
