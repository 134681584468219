import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { Spinner, Row, Col } from 'react-bootstrap';

import { sendAlert } from '../../../actions/utils';
import { LinkBtn, Icon } from '../../../components';
import { createAttendanceMovementsRequest } from '../../../requests/attendanceMovements';
import { showPayrollProcessRequest } from '../../../requests/payrollProcesses';
import AttendanceMovementsForm from './AttendanceMovementsForm';

const AttendanceMovementsNew = () => {
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const returnTo = new URLSearchParams(location.search).get('returnTo') || 'absences';
  const id = new URLSearchParams(location.search).get('id');
  const [payroll, setPayroll] = useState({});

  const handleShowRequest = () => {
    setOnRequest(true);
    showPayrollProcessRequest(id, {
      dispatch,
      service: 'payroll',
      successCallback: res => {
        setPayroll(res.data);
        setOnRequest(false);
      }
    });
  };

  const handleSuccessCreate = () => {
    const message = 'Ausencias creadas con éxito';
    setOnRequest(false);
    history.push(`/${returnTo}/${id}/edit?tab=attendance-movement`);
    dispatch(sendAlert({ kind: 'success', message }));
  };

  const validateLength = absenceValues => {
    if (!absenceValues.attendanceMovement.length) {
      dispatch(sendAlert({ kind: 'error', message: 'Debes seleccionar al menos un trabajador' }));
      return true;
    }
    return false;
  };

  const handleFailureRequest = error => {
    history.push(`/${returnTo}/${id}/edit?tab=attendance-movement`);
    const { response } = error;
    setOnRequest(false);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleCreate = (absenceValues, setSubmitting) => {
    if (validateLength(absenceValues)) {
      setSubmitting(false);
      return;
    }
    const attendanceMovementsValues = {
      attendanceMovements: absenceValues.attendanceMovement.map(movement => ({
        ...movement,
        payrollProcessId: id,
        status: 'approved'
      }))
    };
    setOnRequest(true);
    const params = snakeCaseKeys(attendanceMovementsValues);

    createAttendanceMovementsRequest({
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest,
      callback: () => {
        setSubmitting(false);
        setOnRequest(false);
      }
    });
  };

  useEffect(handleShowRequest, []);

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn
            variant="circle-dark"
            className="btn-circle mt-3"
            block
            to={`/${returnTo}/${id}/edit?tab=attendance-movement&id=${id}`}
          >
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">Nuevo Movimiento de Personal</h2>
        </Col>
      </Row>
      {payroll?.id && <AttendanceMovementsForm action="new" formRequest={handleCreate} payroll={payroll} id={id} />}
    </>
  );
};

export default AttendanceMovementsNew;
