import React from 'react';
import memoize from 'memoize-one';

import { ApprovementStatus, ButtonTooltip, Icon } from '../../../components';

export default memoize((clickHandler, permissions = false, isMobile) => {
  if (isMobile) {
    return [
      {
        name: 'NOMBRE DEL TRABAJADOR',
        selector: 'employee_name',
        cell: item => (
          <div
            className="d-flex flex-column"
            onClick={() => clickHandler(item, 'show')}
            role="presentation"
            aria-hidden="true"
          >
            <span className="text-muted" data-tag="allowRowEvents">
              {item?.national_identification || item?.nationalIdentification}
            </span>
            <span className="text-truncate" data-tag="allowRowEvents">
              {item?.employee_name}
            </span>
          </div>
        ),
        sortable: true,
        grow: '1',
        center: true
      },
      {
        name: 'ESTADO DEL TRABAJADOR',
        selector: 'employee_status',
        sortable: true,
        grow: '1',
        center: true
      },
      {
        name: 'TIPO Y ORIGEN',
        selector: 'origin',
        sortable: true,
        grow: '1',
        cell: item => (
          <div className="d-flex flex-column" role="presentation">
            <span className="text-muted" data-tag="allowRowEvents">
              {item?.parsed_loan_type}
            </span>
            <span data-tag="allowRowEvents">{item?.parsed_origin_type}</span>
          </div>
        ),
        center: true
      }
    ];
  }
  return [
    {
      name: 'NOMBRE DEL TRABAJADOR',
      selector: 'employee_name',
      cell: item => (
        <div
          className="d-flex flex-column"
          onClick={() => clickHandler(item, 'show')}
          role="presentation"
          aria-hidden="true"
        >
          <span className="text-muted" data-tag="allowRowEvents">
            {item?.national_identification || item?.nationalIdentification}
          </span>
          <span className="text-truncate" data-tag="allowRowEvents">
            {item?.employee_name}
          </span>
        </div>
      ),
      sortable: true,
      grow: '1',
      center: true
    },
    {
      name: 'ESTADO DEL TRABAJADOR',
      selector: 'employee_status',
      sortable: true,
      grow: '1',
      center: true
    },
    {
      name: 'TIPO Y ORIGEN',
      selector: 'origin',
      sortable: true,
      grow: '1',
      cell: item => (
        <div className="d-flex flex-column" role="presentation">
          <span className="text-muted" data-tag="allowRowEvents">
            {item?.parsed_loan_type}
          </span>
          <span data-tag="allowRowEvents">{item?.parsed_origin_type}</span>
        </div>
      ),
      center: true
    },
    {
      name: 'PRIMER DESCUENTO',
      selector: 'parsed_first_discount_date',
      sortable: true,
      grow: '1',
      center: true
    },

    {
      name: 'TOTAL CUOTAS',
      selector: 'installment_number',
      cell: item => item.total_installments,
      sortable: true,
      grow: '1',
      center: true
    },
    {
      name: 'MONTO CRÉDITO',
      selector: 'parsed_loan_amount',
      sortable: true,
      grow: '1',
      center: true
    },
    {
      name: 'VALOR CUOTA',
      selector: 'amount',
      cell: item => item.parsed_amount,
      sortable: true,
      grow: '1',
      center: true
    },
    {
      name: 'ESTADO',
      selector: 'status',
      cell: item => (
        <ApprovementStatus
          status={item.status}
          translatedStatus={item.parsed_status}
          margin="my-0"
          onClick={() => clickHandler(item, 'show')}
        />
      ),
      sortable: true,
      grow: '1',
      center: true
    },
    {
      name: 'ACCIONES',
      cell: item => (
        <>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'show')}
            variant="circle-info"
            className="btn-circle"
            size="sm"
            text="Ver"
          >
            <Icon icon="eye" />
          </ButtonTooltip>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'edit')}
            variant="circle-warning"
            className="btn-circle"
            size="sm"
            text="Editar"
            disabled={
              item?.loan_process?.status === 'closed' ||
              !permissions.manage ||
              item?.origin_type === 'request' ||
              item?.installment_number > 1 ||
              item.status === 'deferred'
            }
          >
            <Icon icon="pencil" />
          </ButtonTooltip>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'postpone')}
            variant="circle-dark"
            className="btn-circle"
            size="sm"
            text="Postergar"
            disabled={item?.loan_process?.status === 'closed' || !permissions.manage || item?.status === 'deferred'}
          >
            <Icon icon="close" />
          </ButtonTooltip>
        </>
      ),
      ignoreRowClick: true,
      grow: '1',
      minWidth: '140px',
      center: true
    }
  ];
});
