import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputBase } from '@material-ui/core';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../Icons';

const FormikMaterialUiTimePicker = ({
  abbr,
  timeSelector,
  delayHide,
  delayShow,
  direction,
  disabled,
  error,
  helpText,
  label,
  margin,
  onChange,
  defaultValue,
  value,
  tooltipText,
  touched
}) => {
  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${margin} ${error && touched ? 'is-invalid' : ''}`}>
      {label && (
        <Form.Label className="d-flex align-items-center">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          {tooltipText && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                <Icon icon="help-circle" height="15px" width="15px" />
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      )}
      {timeSelector ? (
        <InputBase
          type="time"
          value={value || defaultValue}
          className="mui-textfield"
          disabled={disabled}
          onChange={event => onChange(event.target.value)}
        />
      ) : (
        <TextField
          type="time"
          disabled={disabled}
          value={value || defaultValue}
          fullWidth
          inputProps={{
            step: 300 // 5 min
          }}
          onChange={event => onChange(event.target.value)}
        />
      )}
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikMaterialUiTimePicker.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  margin: PropTypes.string,
  tooltipText: PropTypes.string,
  defaultValue: PropTypes.string
};

FormikMaterialUiTimePicker.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  margin: '',
  tooltipText: '',
  defaultValue: ''
};

export default FormikMaterialUiTimePicker;
