import React, { useEffect, useState } from 'react';
import { Tab, Nav, Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import MapMarkingReport from '../Map/MapMarkingReport/MapMarkingReport';
import { debounceIndexAttendanceRequest } from '../../requests/attendances';
import { ComponentDataTable, DefaultModal } from '../index';
import { downloadFile } from '../../services/utils';
import markingReport from '../../requests/markings';
import MarkingsReportFilters from './Filters/MarkingsReportFilters';
import ReportActions from './ReportActions';
import { indexBranchOfficesRequest } from '../../requests/branchOffices';
import { AbilityContext } from '../../config/abilityContext';

const MarkingsReport = ({ customParams, columns }) => {
  const [onRequest, setOnRequest] = useState(false);
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const [attendances, setAttendances] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [tile, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [parameters, setParameters] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [filters, setFilters] = useState();
  const [dateRange, setDateRange] = useState([]);
  const [key, setKey] = useState('map');
  const [employeeCoordinates, setEmployeeCoordinates] = useState([]);
  const [brachOfficesCoordinates, setBrachOfficesCoordinates] = useState([]);
  const [allBranchOffices, setAllBranchOffices] = useState([]);
  const [middlePoint, setMiddlePoint] = useState({});
  const [showTabs, setShowTabs] = useState(false);
  const [amount, setAmount] = useState(0);
  const [paramsFilters, setParamsFilters] = useState(null);
  const ability = useAbility(AbilityContext);

  const dispatch = useDispatch();

  const fetchAllBranchOffices = () => {
    indexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name'
      },
      successCallback: response => {
        setAllBranchOffices(response.data.data);
      }
    });
  };

  const calculateAverage = coordinates => {
    const validCoordinates = coordinates.filter(coord => coord.lat && coord.lng);

    if (validCoordinates.length === 0) {
      return { lat: 0, lng: 0 };
    }

    const sumLatitudes = validCoordinates.reduce((sum, coord) => sum + coord.lat, 0);
    const sumLongitudes = validCoordinates.reduce((sum, coord) => sum + coord.lng, 0);
    const middlePointLatitud = sumLatitudes / validCoordinates.length;
    const middlePointLongitud = sumLongitudes / validCoordinates.length;
    return {
      lat: middlePointLatitud,
      lng: middlePointLongitud
    };
  };

  const handleSuccessIndex = (response, activeBranchOfficesIds = []) => {
    const { data } = response.data;
    setAmount(response.data.metadata.amount);
    setAttendances(data);

    const assistanceCoordinates = data
      .filter(m => m.latitude && m.longitude)
      .map(({ id, latitude, longitude, employee }) => ({
        id,
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
        title: employee?.full_name?.trim()
      }));

    const allCoordinatesOffices = allBranchOffices.map(({ id, lat, lng }) => ({
      employee_branch_office_id: id,
      lat: parseFloat(lat),
      lng: parseFloat(lng)
    }));

    const singleBrachOffices = Array.from(
      new Set(
        data
          .flatMap(({ employee_branch_office_lat_lng: employeeBranchOfficeLatLng }) =>
            employeeBranchOfficeLatLng.map(({ lat, lng }) => ({
              lat: parseFloat(lat),
              lng: parseFloat(lng)
            }))
          )
          .map(JSON.stringify)
      ),
      JSON.parse
    ).map(obj => {
      const { employeeBranchOfficeId } =
        data.find(({ employee_branch_office_lat_lng: employeeBranchOfficeLatLng }) =>
          employeeBranchOfficeLatLng.some(({ lat, lng }) => obj.lat === parseFloat(lat) && obj.lng === parseFloat(lng))
        ) ?? '';

      return {
        ...obj,
        employeeBranchOfficeId
      };
    });

    const additionalData = allCoordinatesOffices.filter(
      ({ employee_branch_office_id: employeeBranchOfficeId, lat, lng }) =>
        !singleBrachOffices.some(
          obj => obj.employee_branch_office_id === employeeBranchOfficeId && obj.lat === lat && obj.lng === lng
        )
    );
    let updatedSingleBranchOffices = [...singleBrachOffices, ...additionalData];
    if (activeBranchOfficesIds.length > 0 && activeBranchOfficesIds[0] !== '') {
      updatedSingleBranchOffices = updatedSingleBranchOffices.filter(obj =>
        activeBranchOfficesIds.includes(obj.employee_branch_office_id)
      );
    }
    setBrachOfficesCoordinates(updatedSingleBranchOffices);
    setMiddlePoint(calculateAverage(updatedSingleBranchOffices.concat(assistanceCoordinates)));
    setEmployeeCoordinates(assistanceCoordinates);
    setOnRequest(false);
    setSubmitting(false);
  };

  const handleIndexRequest = myParams => {
    setOnRequest(true);
    const filterByEmployee =
      paramsFilters?.filter_ids && paramsFilters?.filter_ids.length
        ? paramsFilters?.filter_ids
        : paramsFilters?.filter_id;
    const request = async () =>
      debounceIndexAttendanceRequest({
        dispatch,
        params: {
          display_length: 15,
          filterByEmployee,
          ...paramsFilters,
          ...customParams,
          ...myParams
        },
        successCallback: resp => handleSuccessIndex(resp, paramsFilters?.filter_active_branch_offices)
      });
    request();
  };

  const handleOffdayReportRequest = (params, format, type = 'json') => {
    if (dateRange.length > 0 || params.range_date_workday !== undefined) {
      const responseType = format === '.docx' ? 'blob' : type;
      const filterByEmployee = params.filter_ids && params.filter_ids.length ? params.filter_ids : params.filter_id;
      setOnRequest(true);
      markingReport({
        dispatch,
        params: {
          ...params,
          filterByEmployee,
          paginate: false
        },
        format,
        responseType,
        successCallback: response => {
          if (format === '.xlsx') {
            downloadFile(response);
          }
        },
        callback: () => {
          setOnRequest(false);
          setSubmitting(false);
        }
      });
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'download_excel':
        setTitle('Exportar Reporte de Registro de Marca');
        setBody('¿Estás seguro que quieres exportar Registro de Marca en formato Excel?');
        setParameters([{ ...filters, type: '.xlsx' }, '.xlsx', 'text']);
        setModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleFilterAdvance = async params => {
    setParamsFilters(params);
    switch (params.type) {
      case null:
        setSearchFirstStart(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (paramsFilters) {
      setEmployeeCoordinates([]);
      setBrachOfficesCoordinates([]);
      setShowTabs(true);
      setFilters(paramsFilters);
      setDateRange(paramsFilters.date_range);
      setSubmitting(true);
      setMoreData(!moreData);
      handleIndexRequest();
    }
    // eslint-disable-next-line
  }, [paramsFilters]);

  useEffect(
    () => fetchAllBranchOffices(),
    // eslint-disable-next-line
    []
  );

  const sortColumnCase = name => {
    switch (name) {
      case 'time':
        return { sort_time: name };
      case 'employee_full_name':
        return { sort_employee_full_name: name };
      case 'translated_device_type':
        return { sort_column: 'device_type' };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <MarkingsReportFilters formRequest={handleFilterAdvance} isSubmit={isSubmitting} />
      {showTabs && (
        <Tab.Container id="MarkingsReportFilters.tabs" activeKey={key} onSelect={k => setKey(k)}>
          <Nav variant="pills" className="ab-pills">
            <Nav.Item>
              <Nav.Link eventKey="map">Ver Mapa</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="table">Ver Lista</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="map">
              <Row>
                <Col md={12} style={{ marginRight: 15, marginTop: 30, marginBottom: 70 }}>
                  {!onRequest ? (
                    <MapMarkingReport
                      middlePoint={middlePoint}
                      brachOfficesCoordinates={brachOfficesCoordinates}
                      employeeCoordinates={employeeCoordinates}
                    />
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="table">
              <Row>
                <Col md={12}>
                  <ComponentDataTable
                    onRequest={onRequest}
                    columns={columns()}
                    handleSortCase={sortColumnCase}
                    resourceRequest={handleIndexRequest}
                    withMassActions={ability.can('export', 'Marking')}
                    data={attendances}
                    totalRows={amount}
                    moreData={moreData}
                    preName="marking_attendance"
                    massActions={
                      <ReportActions
                        attrr
                        disabled={onRequest}
                        handleClick={handleMassAction}
                        dates={dateRange}
                        showDatePeriod={false}
                      />
                    }
                    searchFirstStart={searchFirstStart}
                  />
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      )}
      <DefaultModal
        title={tile}
        body={body}
        show={modalShow}
        handleClose={() => {
          setModalShow(false);
          setSubmitting(false);
        }}
        handleConfirm={() => {
          handleOffdayReportRequest(...parameters);
          setModalShow(false);
        }}
      />
    </>
  );
};

export default MarkingsReport;
