import React from 'react';
import memoize from 'memoize-one';
import { BasicDropdown, Icon } from '../../components';

const ButtonShow = memoize(({ handleClick, tableView }) => {
  const actions = () => {
    const authorizedActions = [];

    authorizedActions.push({
      icon: <Icon icon="close-circle" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'incomplete_records',
      props: {
        onClick: () => handleClick('incomplete_records'),
        active: tableView === 'incomplete_records'
      },
      title: 'Fichas Incompletas'
    });

    authorizedActions.push({
      icon: <Icon icon="checkmark-circle" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'complete_records',
      props: {
        onClick: () => handleClick('complete_records'),
        active: tableView === 'complete_records'
      },
      title: 'Fichas Completas'
    });

    return authorizedActions;
  };

  return (
    <div className="mb-4 ml-2">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Mostrar <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
});

export default ButtonShow;
