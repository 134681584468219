import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';

import {
  replaceAttendanceRequest,
  disableAttendanceRequest,
  debounceIndexAttendanceRequest
} from '../../../requests/attendances';
import { sendAlert } from '../../../actions/utils';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal, ButtonTooltip, Icon } from '../../../components/index';
import AttendanceReplaceForm from '../../../components/Attendance/AttendanceReplaceForm';
import AttendanceInfo from '../../../components/Attendance/AttendanceInfo';
import { AbilityContext } from '../../../config/abilityContext';
import { downloadFile } from '../../../services/utils';

const AttendanceDataTable = ({
  customParams,
  moreData,
  setMoreData,
  columns,
  defaultStartDate = null,
  defaultEndDate = null
}) => {
  const ability = useAbility(AbilityContext);
  const [onRequest, setOnRequest] = useState(true);
  const [attendances, setAttendances] = useState([]);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [simpleModalBody, setSimpleModalBody] = useState({});
  const [simpleModalTitle, setSimpleModalTitle] = useState('');
  const [size, setSize] = useState('lg');
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState('');
  const [modalAction, setModalAction] = useState(() => null);
  const [amount, setAmount] = useState(0);
  const [query, setQuery] = useState({});
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setAttendances(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    const { date_from: dateTimeFrom, date_to: dateTimeTo, ...restParams } = params;

    const updatedParams = {
      ...restParams,
      dateTimeFrom,
      dateTimeTo
    };

    const snakeParams = snakeCaseKeys(updatedParams);

    setOnRequest(true);
    setQuery({ ...snakeParams, ...customParams });
    const request = async () =>
      debounceIndexAttendanceRequest({
        dispatch,
        params: { ...snakeParams, ...customParams },
        successCallback: handleSuccessIndex
      });
    request();
  };

  const handleModalClose = () => {
    setSimpleModalShow(false);
  };

  const handleSuccessRequest = status => {
    let message = '';

    switch (status) {
      case 'updated':
        message = 'Asistencia reemplazada correctamente';
        break;
      case 'deleted':
        message = 'Asistencia deshabilitada correctamente';
        break;
      case 'mass_disable':
        message = 'Asistencias deshabilitadas correctamente';
        break;
      default:
        message = 'No action';
        break;
    }

    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleShowModal = (title, body) => {
    setSimpleModalTitle(title);
    setSimpleModalShow(true);
    setSimpleModalBody(body);
    setSize('md');
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleUpdateRequest = attendance => {
    replaceAttendanceRequest({
      dispatch,
      params: snakeCaseKeys(attendance),
      formData: true,
      successCallback: () => handleSuccessRequest('updated'),
      failureCallback: handleFailureRequest
    });
  };

  const handleUpdateModal = attendance => {
    const parsedAttendance = camelCaseRecursive(attendance);
    setSimpleModalBody(
      <AttendanceReplaceForm
        attendance={parsedAttendance}
        formRequest={handleUpdateRequest}
        handleModalClose={handleModalClose}
      />
    );
    setSimpleModalTitle('ASISTENCIA');
    setSimpleModalShow(true);
    setSize('lg');
  };

  const handleDefaultModalClose = () => {
    setModalShow(false);
  };

  const handleDeleteRequest = item => {
    disableAttendanceRequest({
      dispatch,
      params: snakeCaseKeys(item),
      successCallback: () => handleSuccessRequest('deleted'),
      failureCallback: error => handleFailureRequest(error)
    });

    handleDefaultModalClose();
  };

  const handleDeleteModal = item => {
    setModalShow(true);
    setModalTitle('Deshabilitar Registro');
    setModalItem(item);
    setModalAction(() => handleDeleteRequest);
    setModalBody('¿Estás seguro que deseas deshabilitar esta asistencia?');
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        handleShowModal(`Asistencia - ${item.employee_full_name}`, <AttendanceInfo attendance={item} />);
        break;
      case 'edit':
        if (ability.can('update', 'Attendance')) handleUpdateModal(item);
        break;
      case 'destroy':
        handleDeleteModal(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'parsed_time':
        return { sort_time: name };
      case 'employee_full_name':
        return { sort_employee_full_name: name };
      case 'status':
        return { sort_status: name };
      case 'real_marking_date':
        return { sort_column: 'time' };
      default:
        return { sort_column: name };
    }
  };

  const handleDownload = () => {
    setOnRequest(true);
    debounceIndexAttendanceRequest({
      dispatch,
      params: query,
      responseType: 'text',
      format: '.xlsx',
      successCallback: response => {
        setModalShow(false);
        downloadFile(response);
        setOnRequest(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleDownloadRequest = () => {
    setModalTitle('Exportar Asistencia');
    setModalShow(true);
    setModalBody('¿Estás seguro que deseas descargar asistencia?');
    setModalAction(() => handleDownload);
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, ability.can('update', 'Attendance'))}
        data={attendances}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        onRowClicked={item => handleActions(item, 'show')}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        pointerOnHover
        withStartDate
        withEndDate
        preName="attendance"
        withMassActions
        massActions={
          <>
            {ability.can('export', 'Permission') && (
              <ButtonTooltip
                variant="circle-primary"
                className="mr-2 btn-circle mb-3"
                text="Exportar"
                onClick={() => handleDownloadRequest()}
              >
                <Icon icon="download-2" />
              </ButtonTooltip>
            )}
          </>
        }
      />
      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        size={size}
        show={simpleModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleDefaultModalClose}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default AttendanceDataTable;
