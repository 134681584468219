import React from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Modal } from 'react-bootstrap';
import ReviewerFields from '../../components/WorkflowFields/ReviewerFields';
import { delayMethod } from '../../services/utils';

const defaultReviewer = {
  position: 0,
  approval: false,
  signature: false,
  employeeReviewerIds: []
};

const WorkflowRequestForm = ({ ...props }) => {
  const { onHide, submitVariant, errors, touched, action, values, setFieldValue, cancelAction, isSubmitting } = props;
  const { workflowRequest } = values;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';

  return (
    <Form>
      <Modal.Body>
        <h4> Pasos a seguir:</h4>
        <ReviewerFields
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          reviewerAttributes={workflowRequest.requestReviewersAttributes}
          attributesPath="workflowRequest[requestReviewersAttributes]"
          attributeReviewerIds="employeeReviewerIds"
          attributeEmployeeReviewers="employeeReviewersAttributes"
          defaultReviewer={defaultReviewer}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn" variant="outline-info" onClick={() => delayMethod(cancelAction)}>
          Cancelar
        </Button>
        <Button type="submit" className="btn" disabled={isSubmitting} variant={submitVariant} onClick={onHide}>
          {btnMessage}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { workflowRequest } = props;
  return { workflowRequest };
};

const validationSchema = Yup.object().shape({
  workflowRequest: Yup.object().shape({
    requestReviewersAttributes: Yup.array().of(
      Yup.object().shape({
        approval: Yup.boolean().test('check-signature', 'Debe seleccionar al menos una acción', function oneBoolean(
          value
        ) {
          if (value || this.parent.signature) {
            return true;
          }
          return false;
        }),
        position: Yup.number(),
        signature: Yup.boolean(),
        employeeReviewerIds: Yup.array()
          .min(1, 'Debes seleccionar al menos un responsable')
          .required('Debes seleccionar un responsable')
      })
    )
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(WorkflowRequestForm);
