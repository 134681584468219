import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AttendanceReportDataTable from '../../Legalreports/AttendanceReport';

const AttendanceReportIndex = () => {
  const { currentEmployee } = useSelector(state => state.auth);

  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <AttendanceReportDataTable currentEmployee={currentEmployee} />
    </>
  );
};

export default AttendanceReportIndex;
