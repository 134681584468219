import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const generateReport = ({
  dispatch,
  params,
  format,
  responseType,
  failureCallback,
  successCallback,
  callback
}) => {
  ApiService.request('get', `/attendance_reports/attendance_record_reports/generate_report${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    failureCallback,
    callback
  });
};

export const generateAttendanceDetailReport = ({
  dispatch,
  params,
  format,
  responseType,
  failureCallback,
  successCallback,
  callback
}) => {
  ApiService.request('get', `/attendance_reports/attendance_detail_reports/generate_report${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    failureCallback,
    callback
  });
};

export const debounceGenerateReport = AwesomeDebouncePromise(generateReport, 300);
export const debounceGenerateAttendanceDetailReport = AwesomeDebouncePromise(generateAttendanceDetailReport, 300);
