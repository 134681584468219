import React from 'react';
import memoize from 'memoize-one';
import { BasicDropdown, Icon } from '../../../components';

const SimpleActionsHistories = memoize(({ handleClick, item, onlyShow }) => {
  const actions = () => {
    const authorizedActions = [];

    authorizedActions.push(
      {
        icon: <Icon icon="eye" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'show',
        props: {
          onClick: () => handleClick(item, 'show')
        },
        title: 'Ver'
      },
      {
        icon: <Icon icon="trash" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'destroy',
        props: {
          onClick: () => handleClick(item, 'destroy')
        },
        title: 'Eliminar'
      }
    );

    if (!onlyShow) {
      authorizedActions.push(
        {
          icon: <Icon icon="pencil" height="16px" width="16px" style={{ marginRight: 5 }} />,
          key: 'edit',
          props: {
            onClick: () => handleClick(item, 'edit')
          },
          title: 'Editar'
        },
        {
          icon: <Icon icon="refresh" height="16px" width="16px" style={{ marginRight: 5 }} />,
          key: 'refresh',
          props: {
            onClick: () => handleClick(item, 'process')
          },
          title: 'Procesar'
        },
        {
          icon: <Icon icon="download-2" height="16px" width="16px" style={{ marginRight: 5 }} />,
          key: 'download',
          props: {
            onClick: () => handleClick(item, 'download')
          },
          title: 'Descargar'
        }
      );
    }

    return authorizedActions;
  };

  return (
    <div style={{ position: 'absolute' }}>
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
});

export default SimpleActionsHistories;
