import ApiService from '../services/apiService';

const API_PAYROLL_SERVICE = 'payroll';

export const indexPayrollLoanProcessRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_process_loans`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const indexLoanProcessesRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_process_loans/index_loan_processes`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const indexLoanInstallmentsRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_process_loans/index_loan_installments`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const exportPayrollLoanRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/payroll_process_loans/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const exportLoanInstallments = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/payroll_process_loans/export_loan_installments.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const exportLoanProcesses = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/payroll_process_loans/export_loan_processes.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const cancelAsociationPayrollLoanRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('post', `/payroll_process_loans/cancel_association`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const createPayrollLoanRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('post', `/payroll_process_loans`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });
