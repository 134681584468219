import React, { useEffect, useState } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.scss';
import Icon from '../../Icons';

const SidebarAdmin = ({ children, pathName, closeSidebar, showChangePassword, showChangePin }) => {
  const [activeKey, setActiveKey] = useState(pathName);
  const [controlClockActive, setControlClockActive] = useState(false);

  useEffect(() => {
    const controlClockDropdown = [
      'control_clock',
      'attendances_control_clock',
      'device_control_clock',
    ];

    setControlClockActive(controlClockDropdown.includes(pathName));
    setActiveKey(pathName);
  }, [pathName]);

  const setDropdownKey = (navLink, currentStatus) => {
    setActiveKey(navLink);
    setControlClockActive(!currentStatus);
  }
  return (

    <div className="scroll-box">
      <Nav className="flex-column" activeKey={pathName}>
        <Nav.Link eventKey="companies" as={Link} to="/admin/companies" onClick={closeSidebar}>
          <Icon icon="briefcase-outline" />
          <span className="link-menu position-absolute">Empresas</span>
        </Nav.Link>
        <Nav.Link eventKey="employees" as={Link} to="/admin/employees" onClick={closeSidebar}>
          <Icon icon="people-outline" />
          <span className="link-menu position-absolute">Trabajadores</span>
        </Nav.Link>
        <Nav.Link eventKey="profiles" as={Link} to="/admin/roles" onClick={closeSidebar}>
          <Icon icon="flag-outline" />
          <span className="link-menu position-absolute">Roles de usuario</span>
        </Nav.Link>

        <Dropdown as={Nav.Item} show={controlClockActive}>
          <Dropdown.Toggle as={Nav.Link} onClick={() => setDropdownKey('device_control_clock', controlClockActive)} >
            <Icon icon="finger-print" />
            <span className="link-menu position-absolute">Reloj Control</span>
          </Dropdown.Toggle>
          <Dropdown.Menu renderOnMount show={controlClockActive}>
            <div className="border-line" />
            <Dropdown.Item
              eventKey="control_clock"
              as={Link}
              active={activeKey === 'control_clock'}
              to="/admin/control_clock"
              onClick={closeSidebar}
            >
              <span className="link-menu position-absolute">Dispositivos</span>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="attendances_control_clock"
              as={Link}
              active={activeKey === 'attendances_control_clock'}
              to="/admin/attendances_control_clock"
              onClick={closeSidebar}
            >
              <span className="link-menu position-absolute">Log de Dispositivos</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Nav.Link eventKey="tickets" as={Link} to="/admin/tickets" onClick={closeSidebar}>
          <Icon icon="help-circle-outline" />
          <span className="link-menu position-absolute">Soporte</span>
        </Nav.Link>

        <div className="d-lg-none">
          <Nav.Link
            eventKey="change_pin"
            onClick={() => {
              closeSidebar();
              showChangePin();
            }}
          >
            <Icon icon="pencil" />
            <div className="position-relative position-link width-fixed">
              <span className="link-menu position-absolute">Cambiar PIN de Firma</span>
            </div>
          </Nav.Link>
          <Nav.Link
            eventKey="change_password"
            onClick={() => {
              closeSidebar();
              showChangePassword();
            }}
          >
            <Icon icon="settings-outline" />
            <div className="position-relative position-link width-fixed">
              <span className="link-menu position-absolute">Cambiar Contraseña</span>
            </div>
          </Nav.Link>
          {children}
        </div>
      </Nav>
    </div>
  )
};

export default SidebarAdmin;
