import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

const VacationCard = ({ title, vacationCard, excessAccumulatedPeriods, rrhh, proportionalVacation }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Header>{title}</Card.Header>
        <Row className="mt-3">
          <Col md={12}>
            <Row>
              <Col xs={6} md={6}>
                <Card.Title className="text-uppercase">Tipo</Card.Title>
                {proportionalVacation ? <p>(+) Legales</p> : <p>(+) Generadas</p>}
                {proportionalVacation && <p>(+) Proporcionales</p>}
                <p>(-) Tomadas</p>
                <p>(-) Solicitadas</p>
              </Col>
              <Col xs={6} md={6} className="attendance-card">
                <Card.Title className="text-uppercase">Cantidad</Card.Title>
                <p>{vacationCard?.accumulatedVacation} días</p>
                {proportionalVacation && <p>{vacationCard?.proportionalValue} días</p>}
                <p>{vacationCard?.vacationsTakenToDate} días</p>
                <p>{vacationCard?.vacationToTake} días</p>
              </Col>
            </Row>
            <hr className="mt-0 mb-3" />
          </Col>
          <Col md={12}>
            <Row>
              <Col>
                <p>Acumulados</p>
              </Col>
              <Col className="attendance-card">
                <p>{vacationCard?.vacationsAvailable} días</p>
              </Col>
            </Row>
          </Col>
        </Row>
        {excessAccumulatedPeriods && (
          <p className="warning-excess-accumulated-periods">Tiene{rrhh ? '' : 's'} más de dos períodos acumulados</p>
        )}
      </Card.Body>
    </Card>
  );
};

export default VacationCard;
