import React from 'react';

const LoanShow = ({ item }) => {
  return (
    <div className="info-box">
      <p className="info">
        <span>Trabajador:</span> {item.employee_name}
      </p>
      <p className="info">
        <span>Tipo de Préstamo / Crédito:</span> {item.parsed_loan_type}
      </p>
      <p className="info">
        <span>Origen de Datos:</span> {item.parsed_origin_type}
      </p>
      <p className="info">
        <span>Monto Total del Crédito:</span> {item.parsed_loan_amount}
      </p>
      <p className="info">
        <span>Cantidad de cuotas:</span> {item.total_installments}
      </p>
      <p className="info">
        <span>Valor Cuota:</span> {item.parsed_amount}
      </p>
      <p className="info">
        <span>Primer Descuento</span> {item.parsed_first_discount_date}
      </p>
      <p className="info">
        <span>Motivo</span> {item.loan.reason}
      </p>
    </div>
  );
};

export default LoanShow;
