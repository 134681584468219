import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { sendAlert } from '../../../actions/utils';
import { LinkBtn, Icon } from '../../../components';
import basicDevice from './controlClock';
import ControlClockForm from './ControlClockForm';
import {
  showAdminDeviceRequest,
  updateAdminDeviceRequest
} from '../../../requests/adminDevices';

const ControlClockEdit = ({ match }) => {
  const [device, setDevice] = useState(basicDevice);
  const dispatch = useDispatch();
  const history = useHistory();
  const deviceId = match.params.id;
  const requestDevice = () => {
    const request = async () => {
      showAdminDeviceRequest(deviceId, {
        dispatch,
        params: {
          method: 'edit'
        },
        successCallback: response => setDevice({
          ...response.data,
          company_id: +response?.data?.company?.id,
          branch_office_id: +response?.data?.branch_office?.id
        })
      });
    };
    request();
  };

  const handleUpdate = values => {
    updateAdminDeviceRequest(deviceId, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: `Reloj Control actualizado con éxito` }));
        history.push(`/admin/control_clock/`);
      }
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(requestDevice, []);

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/admin/control_clock">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0">Editar Reloj Control</h2>
        </Col>
      </Row>
      <ControlClockForm device={device} action="edit" submitVariant="success" formRequest={handleUpdate} />
    </>
  );
};

export default ControlClockEdit;
