import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const createOvertimeAgreementRequest = ({ dispatch, params, formData, successCallback, callback }) =>
  ApiService.request('post', '/overtime_agreements', {
    dispatch,
    params,
    formData,
    successCallback,
    callback
  });

export const overtimeAgreementReport = ({
  dispatch,
  params,
  format,
  responseType,
  failureCallback,
  successCallback,
  callback
}) => {
  ApiService.request('get', `/overtime_reports/overtime_agreement_reports/generate_report${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    failureCallback,
    callback
  });
};

export const debounceovertimeAgreementReportReport = AwesomeDebouncePromise(overtimeAgreementReport, 300);
