import ApiService from '../services/apiService';

const API_PAYROLL_SERVICE = 'payroll';

export const indexPayrollApProcessRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_advance_payments_process`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const indexApProcessesRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_advance_payments_process/index_advance_payments_process`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const exportApProcesses = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/payroll_advance_payments_process/export_ap_processes.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const indexLoanInstallmentsRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/payroll_process_loans/index_loan_installments`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const exportPayrollApRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/payroll_advance_payments_process/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const exportDataProcesses = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/payroll_advance_payments_process/export_data_processes.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const exportPayrollApResumeRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/payroll_advance_payments_process/export_resume.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const cancelAsociationPayrollApRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('post', `/payroll_advance_payments_process/cancel_association`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });

export const createAdvancePaymentProcessRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('post', `/payroll_advance_payments_process`, {
    dispatch,
    params,
    successCallback,
    callback,
    service: API_PAYROLL_SERVICE
  });
