import React from 'react';
import { ButtonTooltip, Icon } from '../../../components';

const MassActions = ({ handleMassAction }) => {
  return (
    <div className="mb-1" style={{ display: 'flex', flexDirection: 'row' }}>
      <ButtonTooltip
        variant="circle-primary"
        className="mr-2 btn-circle"
        text="Exportar"
        onClick={() => handleMassAction('export')}
      >
        <Icon icon="download-2" />
      </ButtonTooltip>

      <ButtonTooltip
        variant="circle-primary"
        className="mr-2 btn-circle"
        text="Importar"
        onClick={() => handleMassAction('import')}
      >
        <Icon icon="cloud-upload-outline" />
      </ButtonTooltip>
    </div>
  );
};

export default MassActions;
