import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import Filters from './Filters';
import {
  exportPreviredPositionRequest,
  exportPreviredSeparatorRequest,
  indexApiPayrollRequest
} from '../../../requests/payrollProcesses';
import { downloadFile } from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';

const PreviredFile = () => {
  const { currentCompany } = useSelector(state => state.auth);
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const exportPreviredPosition = (payrollProcess, params) => {
    const filterEmployees = params.filter_ids && params.filter_ids[0] !== 'all' ? params.filter_ids : [];
    if (payrollProcess) {
      setOnRequest(true);
      exportPreviredPositionRequest(payrollProcess.id, {
        dispatch,
        params: {
          filter_ids: filterEmployees,
          filter_by_rut: params.filterNationalId || '',
          filter_cost_center: params.costCenterId || '',
          filter_job_management: params.jobManagementId || '',
          filter_branch_offices: params.branchOfficeIds || ''
        },
        successCallback: response => {
          downloadFile(response);
        },
        callback: () => setOnRequest(false),
        failureCallback: handleFailureRequest
      });
    }
  };

  const exportPreviredSeparator = (payrollProcess, params) => {
    const filterEmployees = params.filter_ids && params.filter_ids[0] !== 'all' ? params.filter_ids : [];
    if (payrollProcess) {
      const action = params.format;
      setOnRequest(true);
      exportPreviredSeparatorRequest(payrollProcess.id, {
        dispatch,
        responseType: action === 'std_separation' ? 'txt' : 'csv',
        params: {
          filter_ids: filterEmployees,
          filter_by_rut: params.filterNationalId || '',
          filter_cost_center: params.costCenterId || '',
          filter_job_management: params.jobManagementId || '',
          branch_office_ids: params.branchOfficeIds || ''
        },
        successCallback: response => downloadFile(response),
        callback: () => {
          setOnRequest(false);
        },
        failureCallback: handleFailureRequest
      });
    }
  };

  const handleActions = (params, data) => {
    const action = params.format;
    switch (action) {
      case 'std_separation':
        exportPreviredSeparator(data, params);
        break;
      case 'csv_std_separation':
        exportPreviredSeparator(data, params);
        break;
      case 'std_position':
        exportPreviredPosition(data, params);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleRequest = async params => {
    setOnRequest(true);
    const month = moment(params.monthDate).format('MM') - 1;
    const year = moment(params.yearDate).format('YYYY');
    const request = async () =>
      indexApiPayrollRequest({
        dispatch,
        service: 'payroll',
        params: {
          filter_month: month,
          year,
          filter_by_company: currentCompany.id
        },
        successCallback: res => {
          if (res.data?.data.length === 0) {
            dispatch(sendAlert({ kind: 'error', message: 'Proceso no encontrado' }));
          }
          setOnRequest(false);
          handleActions(params, res?.data?.data[0]);
        }
      });
    request();
  };

  return (
    <>
      <Filters formRequest={handleRequest} isSubmit={onRequest} />
      <div className="position-relative">
        {onRequest && (
          <div className="containerSpinnerLoad position-absolute h-100 w-100">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
    </>
  );
};

export default PreviredFile;
