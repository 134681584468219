import { Field, getIn, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import moment from 'moment';
import { Col } from 'react-bootstrap';
import { FormikTimePicker } from '../../../../components';
import { PayrollProcessContext } from '../../context/PayrollProcessProvider';

const DateInput = () => {
  const { payrollProcess } = useContext(PayrollProcessContext);
  const { values, errors, touched, setFieldValue } = useFormikContext();
  return (
    <>
      <Col>
        <Field name="payrollBalance[startDate]">
          {({ field }) => (
            <FormikTimePicker
              {...field}
              abbr
              setLocale
              label="Desde"
              minDate={new Date(payrollProcess.year, payrollProcess.month_number, 1)}
              placeholder="mm/aaaa"
              dateFormat="MM/yyyy"
              margin="mb-0"
              showMonthYearPicker
              selected={values?.payrollBalance?.startDate}
              onChange={date => {
                setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : date);
              }}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col>
        <Field name="payrollBalance[endDate]">
          {({ field }) => (
            <FormikTimePicker
              {...field}
              abbr
              setLocale
              label="Hasta"
              margin="mb-0"
              minDate={new Date(payrollProcess.year, payrollProcess.month_number, 0)}
              placeholder="mm/aaaa"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              selected={values?.payrollBalance?.endDate}
              onChange={date => {
                setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : date);
              }}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              disabled={values?.payrollBalance?.periodicity === 'permanent'}
            />
          )}
        </Field>
      </Col>
    </>
  );
};

export default DateInput;
