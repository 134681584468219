import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-google-autocomplete';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { Form, InputGroup, ButtonToolbar, OverlayTrigger, Tooltip, Button, Col } from 'react-bootstrap';
import Icon from '../../Icons';
import './style.scss';

const FormikInputMap = ({
  abbr,
  addon,
  classHelpText,
  delayHide,
  delayShow,
  defaultValue,
  direction,
  disabled,
  error,
  formInline,
  formType,
  helpText,
  inputType,
  label,
  leftAddon,
  margin,
  minInputHeight,
  placeholder,
  rightBtn,
  rightBtnClickHandler,
  selectedPlace,
  size,
  toolbarVariant,
  tooltipIcon,
  tooltipRight,
  tooltipSize,
  tooltipText,
  touched,
  widthInput,
  coordenates,
  google,
  ...props
}) => {
  const containerStyle = {
    position: 'revert',
    width: '100%',
    height: '100%'
  };
  return (
    <>
      <Col md={12}>
        <Form.Group
          className={`${formInline ? 'form-inline' : ''} ${disabled ? 'disabled' : ''} ${margin} ${
            error && touched ? 'is-invalid' : ''
          }`}
        >
          {label && (
            <>
              <Form.Label className="d-flex align-items-center">
                <span>{label}</span>
                {abbr && <abbr className="text-danger ml-1 mr-1">*</abbr>}
                {tooltipText && !tooltipRight && (
                  <ButtonToolbar className="ml-2">
                    <OverlayTrigger
                      key={direction}
                      placement={direction}
                      delay={{ show: delayShow, hide: delayHide }}
                      overlay={<Tooltip>{tooltipText}</Tooltip>}
                    >
                      <Icon icon={tooltipIcon} width={tooltipSize} />
                    </OverlayTrigger>
                  </ButtonToolbar>
                )}
              </Form.Label>
            </>
          )}
          <InputGroup>
            {leftAddon && (
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">{leftAddon}</InputGroup.Text>
              </InputGroup.Prepend>
            )}
            {formType === 'addressAutocomplete' ? (
              <Autocomplete
                onPlaceSelected={place => {
                  selectedPlace(place);
                }}
                defaultValue={defaultValue}
                placeholder=""
                types={['geocode']}
                disabled={disabled}
                className={`form-control ${disabled && 'input-disabled'}`}
                componentRestrictions={{ country: 'cl' }}
              />
            ) : (
              <Form.Control
                style={{ width: `${widthInput}px`, minHeight: `${minInputHeight}px` }}
                disabled={disabled}
                size={size}
                placeholder={placeholder}
                className={disabled && 'input-disabled'}
                type={inputType}
                {...props}
              />
            )}
            {addon && (
              <InputGroup.Append>
                <InputGroup.Text id="basic-addon2">{addon}</InputGroup.Text>
              </InputGroup.Append>
            )}
            {rightBtn && (
              <InputGroup.Append>
                <Button id="basic-btn2" variant="outline-info" onClick={rightBtnClickHandler}>
                  {rightBtn}
                </Button>
              </InputGroup.Append>
            )}
          </InputGroup>
          {tooltipText && tooltipRight && (
            <ButtonToolbar className="ml-2 mt-1">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                <Icon icon={tooltipIcon} width={tooltipSize} />
              </OverlayTrigger>
            </ButtonToolbar>
          )}
          {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
          {helpText && <Form.Text className={`${classHelpText || 'text-muted'} ml-2`}>{helpText}</Form.Text>}
        </Form.Group>
      </Col>

      <Col md={12} style={{ height: '200px' }}>
        <Map containerStyle={containerStyle} google={google} zoom={16} initialCenter={coordenates} center={coordenates}>
          <Marker position={coordenates} />
        </Map>
      </Col>
    </>
  );
};
FormikInputMap.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  formInline: PropTypes.bool,
  margin: PropTypes.string,
  toolbarVariant: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipIcon: PropTypes.string,
  tooltipSize: PropTypes.string,
  tooltipRight: PropTypes.bool
};

FormikInputMap.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  formInline: false,
  margin: '',
  toolbarVariant: '',
  tooltipText: '',
  tooltipIcon: 'help-circle',
  tooltipSize: '15',
  tooltipRight: false
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCwndRpOtXKHlX079dUfDE0fO7e4vWWgeE'
})(FormikInputMap);
