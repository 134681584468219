import React, { useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Card, Accordion } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { FormikRangePicker, FormikSelect, InputSelect } from '../../../components';
import { debounceIndexSelectEmployeesRequest, searchEmployeeRequest } from '../../../requests/employees';
import { debounceIndexJobManagementsRequest } from '../../../requests/jobManagements';
import { debounceIndexCostCentersRequest } from '../../../requests/costCenters';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';
import { absenceTypes } from './FormOptions';

const AttendanceMovementsFilter = props => {
  const { setFieldValue, values, errors, touched } = props;
  const ALL_SELECTED_LABEL = 'Todos';
  const dispatch = useDispatch();

  const resultFetchData = response => {
    const { data } = response.data;
    const tempArray = data.map(element => {
      return {
        label: element.name,
        value: element.id
      };
    });
    return tempArray;
  };

  const fetchJobManagments = (inputValue, callback) => {
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        paginate: false
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const fetchCostCenters = (inputValue, callback) => {
    debounceIndexCostCentersRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 100
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  const fetchBranchOffices = (inputValue, callback) => {
    const request = async () => {
      debounceIndexBranchOfficesRequest({
        dispatch,
        params: {
          actives: true,
          name: inputValue,
          sort_column: 'name',
          paginate: false
        },
        successCallback: data => callback(resultFetchData(data))
      });
    };
    request();
  };

  const searchEmployeesParams = () => {
    const request = async () =>
      searchEmployeeRequest({
        dispatch,
        params: {
          sort_column: 'name',
          paginate: false,
          advance: true
        },
        successCallback: response => camelCaseRecursive(response.data.data)
      });
    request();
  };

  const fetchEmployee = (inputValue, callback) => {
    debounceIndexSelectEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        sort_column: 'name',
        is_dt: false,
        display_length: 100
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const handleNewMulti = (data, field, allowEmpty = false, jsonb = false) => {
    let newData;

    if (jsonb) {
      newData = data.map(element => element.label);
    } else {
      newData = data.map(element => element.value);
    }
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData);
  };

  const hasAllSelection = (groupSelection = []) => {
    return groupSelection.some(option => option.label === ALL_SELECTED_LABEL);
  };

  const handleDisabledSelection = (options, selectedOptions) => {
    if (hasAllSelection(selectedOptions)) {
      return true;
    }
    if (selectedOptions.length === 0) return false;
    return options.label === ALL_SELECTED_LABEL;
  };

  const initialFetch = () => {
    searchEmployeesParams();
  };

  useEffect(initialFetch, []);

  return (
    <Form>
      <Card>
        <Col className="top-header-green">
          <Accordion.Toggle as={Col} eventKey="0" className="card-header-title">
            FILTROS DE BÚSQUEDA
          </Accordion.Toggle>
        </Col>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="div-content">
            <Row>
              <Col xs={12} md={4}>
                <Field name="filter_by_employee_id">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Trabajador"
                      isMulti
                      placeholder="Seleccionar Trabajador"
                      value={undefined}
                      isOptionDisabled={handleDisabledSelection}
                      request={fetchEmployee}
                      onChange={data => handleNewMulti(data || [], 'filter_by_employee_id', true)}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="filter_absence_type">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      label="Seleccione tipo de movimiento"
                      placeholder="Seleccionar tipo de movimiento"
                      options={absenceTypes}
                      defaultValue={values.absence_type}
                      isMulti
                      onChange={data => handleNewMulti(data || [], 'filter_absence_type', true)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      margin="mb-0"
                      isClearable
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="date_range">
                  {({ field }) => (
                    <FormikRangePicker
                      {...field}
                      name={field.name}
                      startDateName="date_from"
                      endDateName="date_to"
                      startDate={values.date_from}
                      endDate={values.date_to}
                      showClearDates
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Field name="filter_cost_center">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Centro de costo"
                      placeholder="Seleccionar centro de costo"
                      request={fetchCostCenters}
                      value={undefined}
                      isMulti
                      onChange={data => handleNewMulti(data || [], 'filter_cost_center', true, true)}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="filter_branch_offices">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Lugar de Prestación de Servicio"
                      placeholder="Seleccionar Lugar de Prestación"
                      request={fetchBranchOffices}
                      value={undefined}
                      isMulti
                      onChange={data => handleNewMulti(data || [], 'filter_branch_offices', true, false)}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="filter_job_management">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Area"
                      placeholder="Seleccionar area"
                      request={fetchJobManagments}
                      value={undefined}
                      isMulti
                      onChange={data => handleNewMulti(data || [], 'filter_job_management', true, true)}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-4">
              <Col md={2}>
                <Button className="btn-block" type="submit">
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Form>
  );
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: () => ({
    date_from: '',
    date_to: '',
    filter_absence_type: '',
    date_range: [],
    filter_job_management: '',
    filter_cost_center: '',
    filter_branch_offices: '',
    filter_by_employee_id: ''
  }),
  handleSubmit,
  enableReinitialize: true
})(AttendanceMovementsFilter);
