import React, { useState, useEffect } from 'react';
import { Row, Col, Tab, Nav, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import snakeCaseKeys from 'snakecase-keys';

import BranchOfficeForm from '../BranchOffice/BranchOfficeForm';
import SubsidiaryForm from '../Subsidiary/SubsidiaryForm';
import BranchOfficeIndex from '../BranchOffice/BranchOfficeIndex';
import SubsidiaryIndex from '../Subsidiary/SubsidiaryIndex';
import CertificationForm from '../Certification/CertificationForm';
import CertificationIndex from '../Certification/CertificationIndex';
import CostCenterForm from '../CostCenter/CostCenterForm';
import CostCenterIndex from '../CostCenter/CostCenterIndex';
import CustomFieldIndex from '../CustomField/CustomFieldIndex';
import CustomFieldForm from '../CustomField/CustomFieldForm';
import JobManagementIndex from '../JobManagement/JobManagementIndex';
import JobManagementForm from '../JobManagement/JobManagementForm';
import JobTitleForm from '../JobTitle/JobTitleForm';
import JobTitleIndex from '../JobTitle/JobTitleIndex';
import WorkflowTemplateForm from '../WorkflowTemplate/WorkflowTemplateForm';
import WorkflowTemplateIndex from '../WorkflowTemplate/WorkflowTemplateIndex';
import basicBranchOffice from '../BranchOffice/branchOffice';
import basicSubsidiary from '../Subsidiary/subsidiary';
import basicCertification from '../Certification/certification';
import basicCostCenter from '../CostCenter/costCenter';
import basicJobManagement from '../JobManagement/jobManagement';
import basicJobTitle from '../JobTitle/jobTitle';
import basicWorkflowTemplate from '../WorkflowTemplate/workflowTemplate';
import basicCustomField from '../CustomField/customField';
import { AbilityContext } from '../../config/abilityContext';
import { Icon, SimpleCenteredModal } from '../../components';
import { createBranchOfficeRequest } from '../../requests/branchOffices';
import { createSubsidiaryRequest } from '../../requests/subsidiaries';
import { createCertificationRequest } from '../../requests/certifications';
import { createCostCenterRequest } from '../../requests/costCenters';
import { createJobManagementRequest } from '../../requests/jobManagements';
import { createJobTitleRequest } from '../../requests/jobTitles';
import { createWorkflowTemplateRequest } from '../../requests/workflowTemplates';
import { createCustomFieldRequest } from '../../requests/customFields';
import { sendAlert } from '../../actions/utils';
import { useSetTab } from '../../services/hooks';

const CompanySettings = ({ location }) => {
  const [modalTitle, setModalTitle] = useState('Nuevo Lugar de Prestación de Servicios');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const [moreData, setMoreData] = useState(false);
  const [modalSize, setModalSize] = useState('lg');
  const [keyModel, setKeyModel] = useState('BranchOffice');
  const [key, setKey] = useSetTab('job-management', location);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const handleSuccess = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setModalShow(false);
    setMoreData(!moreData);
  };

  const handleCreateBranchOffice = (branchOfficeValue, setSubmitting) => {
    createBranchOfficeRequest({
      dispatch,
      params: snakeCaseKeys(branchOfficeValue),
      formData: true,
      successCallback: () => handleSuccess('Lugar de Prestación de Servicios creado con éxito'),
      callback: () => setSubmitting(false)
    });
  };

  const handleCreateSubsidiary = (subsidiaryValue, setSubmitting) => {
    createSubsidiaryRequest({
      dispatch,
      params: snakeCaseKeys(subsidiaryValue),
      formData: true,
      successCallback: () => handleSuccess('Sucursal creada con éxito'),
      callback: () => setSubmitting(false)
    });
  };

  const handleCreateCostCenter = (costCenterValue, setSubmitting) => {
    createCostCenterRequest({
      dispatch,
      params: snakeCaseKeys(costCenterValue),
      formData: true,
      successCallback: () => handleSuccess('Centro de costo creado con éxito'),
      callback: () => setSubmitting(false)
    });
  };

  const handleCreateJobManagement = (jobManagementValue, setSubmitting) => {
    createJobManagementRequest({
      dispatch,
      params: snakeCaseKeys(jobManagementValue),
      formData: true,
      successCallback: () => handleSuccess('Area creada con éxito'),
      callback: () => setSubmitting(false)
    });
  };

  const handleCreateJobTitle = (jobTitleValue, setSubmitting) => {
    createJobTitleRequest({
      dispatch,
      params: snakeCaseKeys(jobTitleValue),
      formData: true,
      successCallback: () => handleSuccess('Cargo creado con éxito'),
      callback: () => setSubmitting(false)
    });
  };

  const handleCreateCertification = (certificationValue, setSubmitting) => {
    createCertificationRequest({
      dispatch,
      params: snakeCaseKeys(certificationValue),
      formData: true,
      successCallback: () => handleSuccess('Certificación creada con éxito'),
      callback: () => setSubmitting(false)
    });
  };

  const handleCreateWorkflowTemplate = (workflowTemplateValue, setSubmitting) => {
    createWorkflowTemplateRequest({
      dispatch,
      params: snakeCaseKeys(workflowTemplateValue),
      formData: true,
      successCallback: () => handleSuccess('Flujo creado con éxito'),
      callback: () => setSubmitting(false)
    });
  };

  const handleCreateCustomField = (customFieldValue, setSubmitting) => {
    createCustomFieldRequest({
      dispatch,
      params: snakeCaseKeys(customFieldValue),
      formData: true,
      successCallback: () => handleSuccess('Campo creado con éxito'),
      callback: () => setSubmitting(false)
    });
  };

  const newBranchOffice = () => {
    setModalTitle('Nuevo Lugar de Prestación de Servicios');
    setModalShow(true);
    setModalSize('xl');
    setModalBody(
      <BranchOfficeForm
        branchOffice={basicBranchOffice}
        action="new"
        formRequest={handleCreateBranchOffice}
        cancelAction={() => setModalShow(false)}
      />
    );
  };

  const newSubsidiary = () => {
    setModalTitle('Nueva Sucursal');
    setModalShow(true);
    setModalSize('lg');
    setModalBody(
      <SubsidiaryForm
        subsidiary={basicSubsidiary}
        action="new"
        formRequest={handleCreateSubsidiary}
        cancelAction={() => setModalShow(false)}
      />
    );
  };

  const newCostCenter = () => {
    setModalTitle('Nuevo Centro de costo');
    setModalShow(true);
    setModalSize('lg');
    setModalBody(
      <CostCenterForm
        costCenter={basicCostCenter}
        action="new"
        formRequest={handleCreateCostCenter}
        cancelAction={() => setModalShow(false)}
      />
    );
  };

  const newJobManagement = () => {
    setModalTitle('Nueva Area');
    setModalSize('lg');
    setModalShow(true);
    setModalBody(
      <JobManagementForm jobManagement={basicJobManagement} action="new" formRequest={handleCreateJobManagement} />
    );
  };

  const newJobTitle = () => {
    setModalTitle('Nuevo Cargo');
    setModalSize('lg');
    setModalShow(true);
    setModalBody(<JobTitleForm jobTitle={basicJobTitle} action="new" formRequest={handleCreateJobTitle} />);
  };

  const newCertification = () => {
    setModalTitle('Nueva Certificación');
    setModalSize('lg');
    setModalShow(true);
    setModalBody(
      <CertificationForm
        certification={basicCertification}
        action="new"
        formRequest={handleCreateCertification}
        cancelAction={() => setModalShow(false)}
      />
    );
  };

  const newWorkflowTemplate = () => {
    setModalTitle('Nuevo flujo');
    setModalSize('lg');
    setModalShow(true);
    setModalBody(
      <WorkflowTemplateForm
        workflowTemplate={basicWorkflowTemplate}
        action="new"
        formRequest={handleCreateWorkflowTemplate}
        cancelAction={() => setModalShow(false)}
      />
    );
  };

  const newCustomField = () => {
    setModalTitle('Nuevo Campo Personalizado');
    setModalSize('lg');
    setModalShow(true);
    setModalBody(
      <CustomFieldForm
        customField={basicCustomField}
        action="new"
        formRequest={handleCreateCustomField}
        handleModalClose={() => setModalShow(false)}
      />
    );
  };

  const handleClick = () => {
    switch (key) {
      case 'branch-offices':
        return newBranchOffice();
      case 'subsidiaries':
        return newSubsidiary();
      case 'cost-centers':
        return newCostCenter();
      case 'job-management':
        return newJobManagement();
      case 'job-titles':
        return newJobTitle();
      case 'certifications':
        return newCertification();
      case 'workflow-templates':
        return newWorkflowTemplate();
      case 'custom-fields':
        return newCustomField();
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        return '';
    }
  };

  useEffect(() => {
    switch (key) {
      case 'branch-offices':
        setKeyModel('BranchOffice');
        break;
      case 'subsidiaries':
        setKeyModel('Subsidiary');
        break;
      case 'cost-centers':
        setKeyModel('CostCenter');
        break;
      case 'job-management':
        setKeyModel('JobManagement');
        break;
      case 'job-titles':
        setKeyModel('JobTitle');
        break;
      case 'certifications':
        setKeyModel('Certification');
        break;
      case 'workflow-templates':
        setKeyModel('WorkflowTemplate');
        break;
      case 'topics':
        setKeyModel('Topic');
        break;
      case 'necessity-areas':
        setKeyModel('NecessityArea');
        break;
      case 'custom-fields':
        setKeyModel('CustomField');
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        setKeyModel('');
    }
  }, [key]);

  return (
    <>
      <Row className="mt-4 mb-2">
        <Col>
          <h2 className="mb-3 mt-3">Configuraciones</h2>
        </Col>
        {ability.can('create', keyModel) && (
          <Col md={3}>
            <Button variant="primary" block onClick={handleClick}>
              Nuevo
            </Button>
          </Col>
        )}
      </Row>
      <Tab.Container id="company-settings-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="job-management">
              <Icon icon="home-outline" />
              Areas
            </Nav.Link>
          </Nav.Item>
          {ability.can('read', 'BranchOffice') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="branch-offices">
                <Icon icon="location" />
                Lugares de Prestación de Servicios
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('read', 'Subsidiary') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="subsidiaries">
                <Icon icon="location" />
                Sucursales
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="cost-centers">
              <Icon icon="cube" />
              Centros de Costo
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="job-titles">
              <Icon icon="person-circle" />
              Cargos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="certifications">
              <Icon icon="document" />
              Certificaciones
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="workflow-templates">
              <Icon icon="notifications" />
              Flujos
            </Nav.Link>
          </Nav.Item>
          {ability.can('read', 'CustomField') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="custom-fields">
                <Icon icon="notifications" />
                Campos Personalizados
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="branch-offices">
            <BranchOfficeIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="subsidiaries">
            <SubsidiaryIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="cost-centers">
            <CostCenterIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="job-management">
            <JobManagementIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="job-titles">
            <JobTitleIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="certifications">
            <CertificationIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="workflow-templates">
            <WorkflowTemplateIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="custom-fields">
            <CustomFieldIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
        size={modalSize}
      />
    </>
  );
};

export default CompanySettings;
