import React from 'react';
import { Row, Col } from 'react-bootstrap';
import DoubleVerticalBar from './DoubleVerticalBar';
import AgeDistributionCakeGraphic from './AgeDistributionCakeGraphic';
import Men from '../images/Men.png';
import Women from '../images/Women.png';
import GenderColor from '../utils/GenderColor';

function AgeDistribution({ title = '', subtitle = '', onRequest = false }) {
  let men = '';
  let fem = '';

  let NumberMen = 0;
  let NumberWomen = 0;

  let totalMen = 0;
  let totalWomen = 0;

  let totaldata = 0;
  let totaldatapercentage = 0;

  const GenderValues = {};

  if (typeof subtitle === 'object' && typeof title === 'object') {
    men = subtitle?.Masculino;
    fem = subtitle?.Femenino;

    NumberMen = title[0]?.men;
    NumberWomen = title[0]?.women;

    totalMen =
      NumberMen[0]?.range1 + NumberMen[0]?.range2 + NumberMen[0]?.range3 + NumberMen[0]?.range4 + NumberMen[0]?.range5;
    totalWomen =
      NumberWomen[0]?.range1 +
      NumberWomen[0]?.range2 +
      NumberWomen[0]?.range3 +
      NumberWomen[0]?.range4 +
      NumberWomen[0]?.range5;

    Object.keys(subtitle).forEach(groupbygender => {
      totaldata += subtitle[groupbygender];
    });

    Object.keys(subtitle).forEach(groupbygender => {
      totaldatapercentage += (subtitle[groupbygender] / totaldata) * 100;

      if (totaldatapercentage === 100) {
        totaldatapercentage += 1;
      }
      GenderValues[groupbygender] = totaldatapercentage;
    });
  }
  return (
    <>
      {!onRequest && (
        <div>
          <Row>
            <Col xl={6} md={12} xs={12} className="adjust-pad">
              <div className="AgeDistribution DisplayFlex " style={{ minHeight: 200 }}>
                <div className="AlighAgerange">
                  <p className="DividingParts AtenuatedColor">70 -</p>
                  <p className="DividingParts AtenuatedColor">55 -</p>
                  <p className="DividingParts AtenuatedColor">45 -</p>
                  <p className="DividingParts AtenuatedColor">30 -</p>
                  <p className="DividingParts AtenuatedColor">15 -</p>
                  <p className="DividingParts AtenuatedColor"> 0 -</p>
                </div>
                <div className="DisplayFlex AlighAgerangeBar">
                  <DoubleVerticalBar
                    numberMen={NumberMen[0]?.range1 ?? 0}
                    numberWomen={NumberWomen[0]?.range1 ?? 0}
                    ageRange="18-27"
                  />
                  <DoubleVerticalBar
                    numberMen={NumberMen[0]?.range2 ?? 0}
                    numberWomen={NumberWomen[0]?.range2 ?? 0}
                    ageRange="28-37"
                  />
                  <DoubleVerticalBar
                    numberMen={NumberMen[0]?.range3 ?? 0}
                    numberWomen={NumberWomen[0]?.range3 ?? 0}
                    ageRange="38-47"
                  />
                  <DoubleVerticalBar
                    numberMen={NumberMen[0]?.range4 ?? 0}
                    numberWomen={NumberWomen[0]?.range4 ?? 0}
                    ageRange="48-57"
                  />
                  <DoubleVerticalBar
                    numberMen={NumberMen[0]?.range5 ?? 0}
                    numberWomen={NumberWomen[0]?.range5 ?? 0}
                    ageRange=">60"
                  />
                  <DoubleVerticalBar numberMen={totalMen ?? 0} numberWomen={totalWomen ?? 0} ageRange="Suma Total" />
                </div>
              </div>
            </Col>

            <Col xl={6} md={12} xs={12} className="adjust-pad">
              <div className="AgeDistributionGraphic">
                <Row style={{ alignItems: 'center' }}>
                  <Col xs={4} md={4} xl={4}>
                    <AgeDistributionCakeGraphic label="Hombres" color="#f8c444" gender={men} image={Men} />
                  </Col>
                  <Col xs={4} md={4} xl={4}>
                    <AgeDistributionCakeGraphic label="Mujeres" color="#8054fc" gender={fem} image={Women} />
                  </Col>
                  <Col xs={4} md={4} xl={4}>
                    <div className="tense">
                      <div>
                        <svg width="80" height="80">
                          {' '}
                          {/* Para efectos visuales, el circulo gris esta representando por #949495, y el otro por el color del top, juntos dan un buen contorno visual de la pagina */}
                          {typeof subtitle === 'object' &&
                            Object.keys(subtitle)
                              .reverse()
                              .map((groupbygender, i = 0) => (
                                <React.Fragment key={i}>
                                  <circle
                                    alt={groupbygender}
                                    r="20%"
                                    cx="50%"
                                    cy="50%"
                                    pathLength="100"
                                    style={{
                                      stroke: GenderColor(groupbygender),
                                      strokeWidth: '40%',
                                      strokeDasharray: `${GenderValues[groupbygender]} 100`
                                    }}
                                  />
                                </React.Fragment>
                              ))}
                        </svg>
                      </div>
                      {typeof subtitle === 'object' &&
                        Object.keys(subtitle).map(groupbygender => (
                          <div key={groupbygender} className="DisplayFlex WidthModified">
                            <p
                              className="GenderSimbolModified"
                              style={{ color: GenderColor(groupbygender), paddingTop: '5px' }}
                            >
                              ●
                            </p>
                            <p className="pointgender">{`${groupbygender} ${Math.round(
                              (subtitle[groupbygender] / totaldata) * 100
                            )}%`}</p>
                          </div>
                        ))}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default AgeDistribution;
