import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';
import { Button } from 'react-bootstrap';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal } from '../../../components';
import MassiveActions from './MassiveActions';
import columns from './Columns';
import {
  createWorkflowRequest,
  downloadPayrollDocumentsRequest,
  indexPayrollRequest,
  sendSalaryStatementRequest
} from '../../../requests/payrolls';
import { camelCaseEmptyStringRecursive, downloadFile } from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import Filters from './Filters';

const SalarySettlement = () => {
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [payrolls, setPayrolls] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitleBtn, setModalTitleBtn] = useState('');
  const dispatch = useDispatch();
  const [simpleModalTitle, setSimpleModalTitle] = useState('');
  const [simpleModalAction, setSimpleModalAction] = useState(() => null);
  const [simpleModalItem, setSimpleModalItem] = useState({});
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [simpleModalBody, setSimpleModalBody] = useState('');
  const { currentCompany } = useSelector(state => state.auth);
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const [customParams, setCustomParams] = useState({});
  const [amount, setAmount] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleRequest = params => {
    const myParams = snakecaseKeys({ ...params, ...customParams });
    setOnRequest(true);
    indexPayrollRequest({
      dispatch,
      params: {
        filter_by_company: currentCompany.id,
        ...myParams
      },
      successCallback: response => {
        const { data, metadata } = response.data;
        setPayrolls(camelCaseEmptyStringRecursive(data));
        setAmount(metadata.amount);
      },
      callback: () => {
        setSubmitting(false);
        setOnRequest(false);
      }
    });
  };

  const handleFilters = params => {
    setSubmitting(true);
    setClearSelectedRows(!clearSelectedRows);
    setSearchFirstStart(true);
    setCustomParams(params);
    setMoreData(!moreData);
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const handleDownload = () => {
    setOnRequest(true);
    const ids = selectedRows.map(item => item.id);
    downloadPayrollDocumentsRequest({
      dispatch,
      params: { filter_ids: ids },
      format: '.zip',
      responseType: 'blob',
      successCallback: downloadFile,
      callback: () => {
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleSuccessSendEmail = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleSendSalaryStatement = item => {
    setOnRequest(true);
    sendSalaryStatementRequest({
      dispatch,
      params: { filter_ids: item.id },
      successCallback: () => handleSuccessSendEmail('Email enviado correctamente'),
      callback: () => {
        setOnRequest(false);
        setSimpleModalShow(false);
      }
    });
  };

  const handleMassiveSendSalaryStatement = itemIds => {
    setOnRequest(true);
    sendSalaryStatementRequest({
      dispatch,
      params: { filter_ids: itemIds },
      successCallback: () => handleSuccessSendEmail('Emails enviados correctamente'),
      callback: () => {
        setOnRequest(false);
        setSimpleModalShow(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleSuccessRequest = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleCreateWorkflow = item => {
    setOnRequest(true);
    createWorkflowRequest({
      dispatch,
      params: { filter_ids: item.id },
      successCallback: () => handleSuccessRequest('Flujo creado correactemente'),
      failureCallback: error => handleFailureRequest(error),
      callback: () => {
        setOnRequest(false);
        setSimpleModalShow(false);
      }
    });
  };

  const handleMassiveCreateWorkflow = payrollIds => {
    setOnRequest(true);
    createWorkflowRequest({
      dispatch,
      params: { filter_ids: payrollIds },
      successCallback: () => handleSuccessRequest('Flujos creado correactemente'),
      failureCallback: error => handleFailureRequest(error),
      callback: () => {
        setOnRequest(false);
        setSimpleModalShow(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleMassAction = action => {
    const payrollIds = selectedRows.map(item => item.id);

    switch (action) {
      case 'download_zip':
        handleDownload();
        break;
      case 'email':
        setSimpleModalItem(payrollIds);
        setSimpleModalShow(true);
        setSimpleModalBody(
          <>
            <p>¿Está seguro que desea enviarle al correo la liquidación de sueldo a los trabajadores seleccionado</p>
            <br />
            <p>Esta acción no podrá ser revertida.</p>
          </>
        );
        setSimpleModalTitle('Enviar Liquidación de sueldo');
        setSimpleModalAction(() => handleMassiveSendSalaryStatement);
        break;
      case 'request-sign':
        setSimpleModalItem(payrollIds);
        setSimpleModalShow(true);
        setSimpleModalBody(
          `¿Está seguro que desea enviar a flujo la liquidación de sueldo de los trabajadores seleccionado?`
        );
        setSimpleModalTitle('Enviar a flujo Liquidación de sueldo');
        setSimpleModalAction(() => handleMassiveCreateWorkflow);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleButtonClick = (item, action) => {
    const file = item.fileInfo;
    const downloadBtn = (
      <Button variant="primary" href={file?.fileUrl} target="_blank" rel="noopener noreferrer" className="ml-4">
        Descargar PDF
      </Button>
    );
    const body = (
      <iframe src={file?.fileUrl} title={`${file?.filename}`} style={{ height: '500px' }} className="w-100">
        <p>Este navegador no soporta PDFs. Por favor descarga el PDF para verlo: {downloadBtn}</p>
      </iframe>
    );
    switch (action) {
      case 'show':
        setModalTitleBtn(downloadBtn);
        setModalBody(body);
        setModalShow(true);
        break;
      case 'email':
        setSimpleModalItem(item);
        setSimpleModalShow(true);
        setSimpleModalBody(
          <>
            <p>¿Está seguro que desea enviarle al correo la liquidación de sueldo al trabajador seleccionado?</p>
            <br />
            <p>Esta acción no podrá ser revertida.</p>
          </>
        );
        setSimpleModalTitle('Enviar Liquidación de sueldo');
        setSimpleModalAction(() => handleSendSalaryStatement);
        break;
      case 'request-sign':
        setSimpleModalItem(item);
        setSimpleModalShow(true);
        setSimpleModalBody(
          `¿Está seguro que desea enviar a flujo la liquidación de sueldo del trabajador seleccionado?`
        );
        setSimpleModalTitle('Enviar a flujo Liquidación de sueldo');
        setSimpleModalAction(() => handleCreateWorkflow);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Acción no encontrada');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'full_name':
        return { sort_name: name };
      case 'employee_cost_center':
        return { sort_cost_center: name };
      case 'job_management':
        return { sort_job_management: name };
      case 'month':
        return { sort_month: name };
      case 'year':
        return { sort_year: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <Filters formRequest={handleFilters} disabled={isSubmitting} />
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        data={payrolls}
        moreData={moreData}
        handleSortCase={sortColumnCase}
        resourceRequest={handleRequest}
        searchFirstStart={searchFirstStart}
        pointerOnHover
        preName="salarySettlement"
        noDataComponent={<div className="pb-5">No se encontraron datos</div>}
        withMassActions
        massActions={
          <>
            <MassiveActions handleClick={handleMassAction} disabled={selectedCount <= 0} />
          </>
        }
        totalRows={amount}
        selectableRows
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleSelectedRows}
        withSearch={false}
      />

      <SimpleCenteredModal
        title="Detalles de Liquidación"
        titleBtn={modalTitleBtn}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
        bodyClass="mx-3 mb-3"
      />
      <DefaultModal
        title={simpleModalTitle}
        body={simpleModalBody}
        show={simpleModalShow}
        disabled={onRequest}
        handleClose={() => setSimpleModalShow(false)}
        handleConfirm={() => simpleModalAction(simpleModalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default SalarySettlement;
