import React, { useEffect, useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import { FormikSelect, FormikInput, FormikDatePicker, FormikTimePicker } from '../../../components';
import { debounceIndexCostCentersRequest } from '../../../requests/costCenters';
import { debounceIndexJobManagementsRequest } from '../../../requests/jobManagements';
import FormikSelectClear from '../../../components/Utils/Select/FormikSelectClear';
import { debounceIndexSubsidiariesRequest } from '../../../requests/subsidiaries';
import { debounceIndexEmployeesRequest } from '../../../requests/employees';
import { debounceIndexAdvancePaymentProcessRequest } from '../../../requests/advancePaymentProcess';
import { debounceIndexDataProcessRequest } from '../../../requests/dataProcess';
import { payrollEmployeesRequest } from '../../../requests/payrolls';

const Filters = props => {
  const { errors, touched, setFieldValue, values, onHide, disabled = false, setFieldTouched } = props;
  const ALL_SELECTED_LABEL = 'Todos';
  const [costCenters, setCostCenters] = useState([]);
  const [jobManagement, setJobManagement] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [advancePaymentProcess, setAdvancePaymentProcess] = useState([]);
  const dispatch = useDispatch();
  const { currentCompany } = useSelector(state => state.auth);

  const resultFetchData = (response, type) => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: `${type ? `${element.code} - ` : ''} ${element.name}`,
      value: element.id
    }));
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) newData.push('');

    setFieldValue(field, newData);
  };

  const fetchAdvancePaymentProcess = params => {
    debounceIndexAdvancePaymentProcessRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 50,
        ...params
      },
      successCallback: data => {
        setAdvancePaymentProcess(resultFetchData(data));
      }
    });
  };

  const fetchEmployees = () => {
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: response => {
        const dataToGroupSearch = [{ id: 'Todos', label: 'Todos', value: 'all' }, ...response.data.data];
        setEmployees(response.data.data.length > 0 ? dataToGroupSearch : []);
      }
    });
  };

  const fetchCostCenters = () => {
    debounceIndexCostCentersRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: data => {
        setCostCenters(resultFetchData(data));
      }
    });
  };

  const fetchJobManagments = () => {
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: data => {
        setJobManagement(resultFetchData(data));
      }
    });
  };

  const fetchSubsidiaries = () => {
    debounceIndexSubsidiariesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: data => {
        setSubsidiaries(resultFetchData(data));
      }
    });
  };

  const fetchEmployeesDataProcess = params => {
    debounceIndexDataProcessRequest({
      dispatch,
      params: {
        ...params,
        display_length: 500
      },
      successCallback: response => {
        const dataToGroupSearch = [{ id: 'Todos', label: 'Todos', value: 'all' }, ...response.data.data];
        setEmployees(response.data.data.length > 0 ? dataToGroupSearch : []);
      }
    });
  };

  const hasAllSelection = (groupSelection = []) => {
    return groupSelection.some(option => option.label === ALL_SELECTED_LABEL);
  };

  const handleDisabledSelection = (options, selectedOptions) => {
    if (hasAllSelection(selectedOptions)) {
      return true;
    }
    if (selectedOptions.length === 0) return false;
    return options.label === ALL_SELECTED_LABEL;
  };

  const concepts = [
    { label: 'Anticipo', value: 'advance_payment_process' },
    { label: 'Remuneracion', value: 'payroll_process' }
  ];

  const banks = [
    { label: 'Banco BBVA', value: 'bbva', code: 'bbva', format: '.text' },
    { label: 'Banco BCI', value: 'bci', code: 'bci', format: '.text' },
    { label: 'Banco Bice', value: 'bice', code: 'bice', format: '.csv' },
    { label: 'Banco Corpbanca', value: 'corpbanca', code: 'corpbanca', format: '.text' },
    { label: 'Banco Estado (ancho fijo)', value: 'estado', code: 'estado', format: '.text' },
    { label: 'Banco Internacional', value: 'internacional', code: 'internacional', format: '.text' },
    { label: 'Banco Itau', value: 'itau', code: 'itau', format: '.xlsx' },
    { label: 'Banco Santander (versión texto)', value: 'santander', code: 'santander', format: '.text' },
    { label: 'Banco Santander (versión excel)', value: 'santander2', code: 'santander', format: '.xlsx' },
    { label: 'Banco Security', value: 'security', code: 'security', format: '.text' },
    { label: 'Banco de Chile', value: 'chile', code: 'chile', format: '.text' },
    { label: 'Cheque', value: 'checks' },
    { label: 'Citibank (TXT 1024 caracteres)', value: 'citibank', code: 'citibank', format: '.text' },
    { label: 'Resumen de transferencias', value: 'transference_summary' },
    { label: 'Resumen por forma de pago', value: 'payment_type_summary' },
    { label: 'Scotiabank (estandar)', value: 'scotiabank', code: 'scotiabank', format: '.text' },
    { label: 'Scotiabank (bdd)', value: 'scotiabank2', code: 'scotiabank', format: '.text' }
  ];

  const withAdvancePayment = values.conceptType === 'advance_payment_process';

  const handleProcessFilters = () => {
    setEmployees([]);
    setAdvancePaymentProcess([]);
    if (values.conceptType && values.processDate) {
      const filterDate = moment(values.processDate);

      let params = {};
      if (values.conceptType === 'advance_payment_process') {
        params = { filter_month: filterDate.month(), filter_year: filterDate.year() };
        fetchAdvancePaymentProcess(params);
      }
    }
  };

  const fetchEmployeesPayrolls = params => {
    payrollEmployeesRequest({
      dispatch,
      params: {
        ...params,
        filter_by_company: currentCompany.id,
        paginate: false
      },
      successCallback: response => {
        const dataToGroupSearch = [{ id: 'Todos', label: 'Todos', value: 'all' }, ...response.data.data];
        setEmployees(response.data.data.length > 0 ? dataToGroupSearch : []);
      }
    });
  };

  const changeConceptType = () => {
    setEmployees([]);
    setAdvancePaymentProcess([]);
    setFieldValue('processDate', '');
    setFieldValue('advancePaymentProcesses', '');
    setFieldValue('advancePaymentProcess', '');
    setFieldValue('filterEmployees', '');
    setFieldValue('employees', '');
  };

  const changeDateProcess = () => {
    setEmployees([]);
    setAdvancePaymentProcess([]);
    setFieldValue('advancePaymentProcesses', '');
    setFieldValue('advancePaymentProcess', '');
    setFieldValue('filterEmployees', '');
    setFieldValue('employees', '');
  };

  const handleEmployeesFilters = () => {
    setEmployees([]);
    if (values.conceptType && values.processDate) {
      const date = moment(values.processDate).format('DD/MM/YYYY');
      let params = {};
      if (values.conceptType === 'advance_payment_process') {
        params = {
          filter_process_id: values.advancePaymentProcess,
          distinct_employees: true,
          basic_data_process: true
        };
        if (values.advancePaymentProcess) {
          fetchEmployeesDataProcess(params);
        }
      }

      if (values.conceptType === 'payroll_process') {
        params = { month_date: date, year_date: date };
        fetchEmployeesPayrolls(params);
      }
    }
  };

  useEffect(fetchCostCenters, []);
  useEffect(fetchJobManagments, []);
  useEffect(fetchSubsidiaries, []);
  useEffect(fetchEmployees, []);

  useEffect(handleProcessFilters, [values.conceptType, values.processDate]);
  useEffect(handleEmployeesFilters, [values.conceptType, values.processDate, values.advancePaymentProcess]);

  return (
    <Form>
      <Card style={{ zIndex: 'unset' }}>
        <Row>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className="top-header-green-dt card-header-title-dt pt-1 pb-1">Transferencia Bancaria</Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col md={4}>
                    <Field name="transferDate">
                      {({ field }) => (
                        <FormikDatePicker
                          {...field}
                          abbr
                          label="Fecha de Transferencia"
                          placeholder="dd/mm/aaaa"
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={withAdvancePayment ? 3 : 4}>
                    <Field name="conceptType">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          abbr
                          label="Concepto"
                          placeholder="Seleccionar concepto"
                          options={concepts}
                          onChange={data => {
                            setFieldValue(field.name, data ? data.value : '');
                            changeConceptType();
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>

                  <Col md={withAdvancePayment ? 3 : 4}>
                    <Field name="processDate">
                      {({ field }) => (
                        <FormikTimePicker
                          {...field}
                          abbr
                          label="Mes de Proceso"
                          placeholder="mm/aaaa"
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          onChange={date => {
                            setFieldValue(field.name, date);
                            changeDateProcess();
                          }}
                          selected={field.value}
                          setFieldTouched={() => setFieldTouched(field.name)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>

                  {withAdvancePayment && (
                    <Col md={withAdvancePayment ? 3 : 4}>
                      <Field name="advancePaymentProcesses">
                        {({ field }) => (
                          <FormikSelectClear
                            {...field}
                            abbr={withAdvancePayment}
                            label="Seleccionar un anticipo"
                            placeholder="debes seleccionar una opcion"
                            options={advancePaymentProcess}
                            defaultValue={values.advancePaymentProcesses}
                            value={values.advancePaymentProcesses}
                            onChange={data => {
                              setFieldValue(field.name, data || []);
                              setFieldValue('advancePaymentProcess', data.value || '');
                            }}
                            setFieldTouched={() => setFieldTouched(field.name)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  )}

                  <Col md={withAdvancePayment ? 3 : 4}>
                    <Field name="employees">
                      {({ field }) => (
                        <FormikSelectClear
                          {...field}
                          abbr
                          label="Trabajadores"
                          placeholder="Seleccionar Trabajador"
                          isMulti
                          options={employees}
                          defaultValue={values.employees}
                          value={values.employees}
                          onChange={data => {
                            handleNewMulti(data || [], 'filterEmployees', true);
                            setFieldValue(field.name, data || []);
                          }}
                          setFieldTouched={() => setFieldTouched(field.name)}
                          isOptionDisabled={handleDisabledSelection}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Field name="financialInstitution">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          abbr
                          label="Institucion Financiera"
                          menuPlacement="top"
                          placeholder="Seleccionar institucion"
                          options={banks}
                          isClearable
                          onChange={data => setFieldValue(field.name, data || '')}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col md={8}>
                    <Field name="gloss">
                      {({ field }) => (
                        <FormikInput
                          {...field}
                          abbr
                          label="Glosa"
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Field name="costCenterId">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Centro de Costos"
                          menuPlacement="top"
                          placeholder="Seleccionar Centro de Costos"
                          options={costCenters}
                          isClearable
                          onChange={data => setFieldValue(field.name, data ? data.value : '')}
                          isDisabled={values.subsidiaryId || values.jobManagementId}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>

                  <Col md={4}>
                    <Field name="jobManagementId">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Área"
                          menuPlacement="top"
                          placeholder="Seleccionar Área"
                          options={jobManagement}
                          isClearable
                          onChange={data => setFieldValue(field.name, data ? data.value : '')}
                          isDisabled={values.subsidiaryId || values.costCenterId}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>

                  <Col md={4}>
                    <Field name="subsidiaryId">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Sucursal"
                          menuPlacement="top"
                          placeholder="Seleccionar sucursal"
                          options={subsidiaries}
                          isClearable
                          onChange={data => setFieldValue(field.name, data ? data.value : '')}
                          isDisabled={values.costCenterId || values.jobManagementId}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
      <Row className="d-flex justify-content-end mt-4 mb-4">
        <Col md={2}>
          <Button className="btn-block" type="submit" onClick={onHide} disabled={disabled}>
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  transferDate: Yup.date()
    .required('La fecha de transferencia es requerida')
    .formatdate(),
  processDate: Yup.date().required('El mes de proceso es requerido'),
  conceptType: Yup.string().required('El concepto es requerido'),
  gloss: Yup.string().required('La glosa es requerida'),
  financialInstitution: Yup.string().required('La institución financiera es requerida'),
  advancePaymentProcesses: Yup.string().when('conceptType', {
    is: 'advance_payment_process',
    then: Yup.string().required('El anticipo es requerido')
  }),
  employees: Yup.array().min(1, 'Debe seleccionar al menos un trabajador')
});

const setInitialValues = () => {
  return {
    employees: [],
    filterEmployees: '',
    transferDate: '',
    processDate: '',
    conceptType: '',
    gloss: '',
    financialInstitution: '',
    costCenterId: '',
    jobManagementId: '',
    subsidiaryId: '',
    advancePaymentProcess: ''
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(Filters);
