import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Button, Navbar, Nav, Dropdown, Image, Badge } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useAbility } from '@casl/react';
import ChangePassword from '../../../screens/Auth/ChangePassword';
import ChangePin from '../../../screens/Auth/ChangePin';
import { AbilityContext } from '../../../config/abilityContext';
import { updateAbility } from '../../../config/ability';
import {
  debounceIndexNotificationsRequest,
  readNotificationRequest,
  readAllNotificationRequest
} from '../../../requests/notifications';
import { sendSignatureKeyUserRequest, stopImpersonateAccountAdminRequest } from '../../../requests/user';
import {
  debounceIndexAdminNotificationsRequest,
  readAdminNotificationRequest
} from '../../../requests/adminNotifications';
import { validateToken, requestCurrentCompany } from '../../../actions/auth';
import { toogleSidebar, setSidebar, setAdvanceSettings, sendAlert } from '../../../actions/utils';
import Icon from '../../Icons';
import ButtonTooltip from '../Tooltips/ButtonTooltip';
import MainLogo from '../MainLogo';
import './style.scss';
import { SimpleCenteredModal } from '../Modal';

import modulesTypes from './formOptions';
import DocumentPreview from '../../../screens/DocumentPreview/DocumentPreview';
import PreviredIndicators from '../../Indicators';

const NavbarLogged = ({ children, module }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalSize, setModalSize] = useState('lg');
  const [notifications, setNotifications] = useState([]);
  const [reload, setReload] = useState(false);
  const [stopImpersonate, setStopImpersonate] = useState(false);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const { user, currentCompany, currentEmployee, currentRoles } = useSelector(state => state.auth);
  const { unreadNotifications, isPretending } = useSelector(state => state.utils);
  const { id: currentCompanyId } = currentCompany;
  const { avatar } = user;
  const currentTab = currentCompany.id ? currentCompany.businessName : 'Soporte';
  const currentModule = modulesTypes.find(mod => module === mod.key);

  const getNotifications = () => {
    const myParams = { sort_column: 'created_at', sort_direction: 'desc', display_start: 0, display_length: 15 };
    if (currentEmployee === null && user.id > 0 && user.role === 'admin') {
      debounceIndexAdminNotificationsRequest({
        dispatch,
        params: myParams,
        successCallback: response => setNotifications(camelCaseRecursive(response.data.data))
      });
    } else if (currentCompanyId) {
      debounceIndexNotificationsRequest({
        dispatch,
        params: myParams,
        successCallback: response => setNotifications(camelCaseRecursive(response.data.data))
      });
    }
  };

  const getAdvanceSettings = () => {
    if (currentCompanyId) dispatch(setAdvanceSettings());
  };

  // const fetchRoles = () => {
  //   if (updateRoles) dispatch(validateToken());
  // };

  const updateAbilityRoles = () => updateAbility(ability);

  const userData = () => {
    if (user.id === 0) dispatch(validateToken());
    if (currentCompany.id !== '') {
      dispatch(requestCurrentCompany({ id: currentCompany.id }));
    }
  };

  const updateCompanyData = () => {
    if (currentCompany.id !== '') {
      dispatch(requestCurrentCompany({ id: currentCompany.id }));
    }
  };

  const updateNotification = notificationId => {
    if (!currentEmployee && user.id > 0 && user.role === 'admin') {
      readAdminNotificationRequest(notificationId, {
        dispatch,
        successCallback: getNotifications
      });
    } else {
      readNotificationRequest(notificationId, {
        dispatch,
        successCallback: getNotifications
      });
    }
  };

  const redirectTo = (path, customParams = {}) => {
    const isMobile = window.innerWidth <= 992;
    if (isMobile) dispatch(setSidebar({ show: false }));
    history.push(path, customParams);
  };

  const closeSidebar = () => {
    const isMobile = window.innerWidth <= 992;
    if (isMobile) dispatch(setSidebar({ show: false }));
  };

  const workFlowClickPermission = (admPermission, pending, owner) => {
    let pAdm;
    let tAdm;
    let team;
    if (owner) {
      pAdm = admPermission ? 'subtab=administrative-datatable' : 'subtab=perms-datatable';
      tAdm = 'tab=permissions&';
      team = '/requests?';
    } else {
      pAdm = admPermission && !pending ? 'subtab=administrative-datatable' : 'subtab=perms-datatable';
      tAdm = admPermission && pending ? 'tab=administrative&' : 'tab=permissions&';
      team = '/dashboard?board=team&team_tab=attendance&';
    }
    return `/profile${team}${tAdm}${pAdm}`;
  };

  const handleWorkflowClick = workflowRequest => {
    const pending = workflowRequest.status === 'pending';
    const owner = workflowRequest?.employee?.id === currentEmployee;
    let team = '';
    switch (workflowRequest.workflowType) {
      case 'loan':
        team = pending && !owner ? '/dashboard?board=team&team_tab=requests&' : '/requests?';
        redirectTo(`/profile${team}tab=loans`);
        break;
      case 'advance_payment':
        team = pending && !owner ? '/dashboard?board=team&team_tab=requests&' : '/requests?';
        redirectTo(`/profile${team}tab=advance_payments`);
        break;
      case 'vacation':
        team = pending && !owner ? '/dashboard?board=team&team_tab=attendance&' : '/requests?';
        redirectTo(`/profile${team}tab=vacations`);
        break;
      case 'certificate':
      case 'congratulation_letter':
      case 'contract':
      case 'contract_annex':
      case 'other_document':
      case 'personal_protection':
      case 'report_occupational_hazards':
      case 'acceptance_riohs':
      case 'induction_risk_prevention':
      case 'reprimand_document':
        setModalTitle('');
        setModalBody(
          <DocumentPreview
            documentId={workflowRequest.document.documentId}
            employeeId={workflowRequest.document.employeeId}
          />
        );
        setModalSize('xl');
        setModalShow(true);

        break;
      case 'permission':
        redirectTo(workFlowClickPermission(workflowRequest.isPermissionAdministrative, pending, owner));
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleTicketClick = ticket => {
    const isAdmin = !currentEmployee && user.id > 0;
    redirectTo(`/${isAdmin ? 'admin/' : ''}tickets/${ticket.id}`);
  };

  const handleCompanyClick = company => {
    const { status } = company;
    switch (status) {
      case 'pending':
        redirectTo(`/admin/companies/${company.id}`);
        break;
      case 'accepted':
        redirectTo('/profile/companies');
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const indicatorsClick = () => {
    setModalTitle('INDICADORES PREVISIONALES');
    setModalBody(<PreviredIndicators />);
    setModalSize('xl');
    setModalShow(true);
  };

  const notificationClick = notification => {
    const { record } = notification;
    if (record) {
      const { type } = record;
      switch (type) {
        case 'workflow_request':
          updateNotification(notification.id);
          handleWorkflowClick(record.workflowRequest);
          break;
        case 'ticket':
          updateNotification(notification.id);
          handleTicketClick(record.ticket);
          break;
        case 'company':
          updateNotification(notification.id);
          handleCompanyClick(record.company);
          break;
        case 'employee_survey':
          redirectTo(`/profile/surveys`);
          break;
        case 'announcement':
          redirectTo(`/profile/announcements`);
          break;
        case 'company_task':
          redirectTo(`/profile/announcements?tab=calendar`);
          break;
        case 'employee_evaluation':
          redirectTo(`/profile/performance_evaluations/${record.employeeEvaluation.performanceEvaluationId}`, {
            employee: true
          });
          break;
        case 'document':
          redirectTo(`/profile/documents`);
          break;
        case 'attendance':
          if (ability.can('read', 'Attendance')) redirectTo('/attendances');
          break;
        case 'employee_course':
          redirectTo(`/profile/courses/${record.employeeCourse.courseId}`);
          break;
        default:
          // eslint-disable-next-line no-console
          console.log('Error: Action not found');
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('Error: Action not found');
    }
  };

  const handleReadAllNotificationsClick = () => {
    readAllNotificationRequest({
      dispatch,
      successCallback: getNotifications
    });
  };

  const stopImpersonateAccount = params => {
    stopImpersonateAccountAdminRequest({
      dispatch,
      params,
      formData: true,
      successCallback: () => {
        if (user.isPretending && !isPretending) {
          const message = 'Empleado ha denegado el acceso a su cuenta';
          dispatch(sendAlert({ kind: 'error', message }));
        } else {
          const message = 'Cambio de sesión con éxito';
          dispatch(sendAlert({ kind: 'success', message }));
        }
        dispatch(validateToken());
        setStopImpersonate(true);
      }
    });
  };

  const redirectToAdminRootPath = () => {
    if (stopImpersonate && !user.isPretending && !isPretending) {
      updateAbility(ability);
      history.push('/admin/companies');
      setStopImpersonate(false);
    }
  };

  const accessAccountDenied = () => {
    if (user.isPretending && !isPretending) {
      stopImpersonateAccount();
    }
  };

  const showChangePassword = () => {
    setModalBody(<ChangePassword setModalShow={setModalShow} />);
    setModalSize('md');
    setModalTitle('Cambiar contraseña');
    setModalShow(true);
  };

  const handleSignatureKey = params => {
    sendSignatureKeyUserRequest({
      dispatch,
      params
    });
  };

  const showChangePin = () => {
    setModalBody(<ChangePin setModalShow={setModalShow} handleSignatureKey={handleSignatureKey} />);
    setModalSize('md');
    setModalTitle('Cambiar PIN de Firma');
    setModalShow(true);
  };

  const reloadScreen = () => {
    if (window.innerWidth >= 768) dispatch(setSidebar({ show: true }));
    setReload(!reload);
  };

  const setLinkRrhh = () => {
    if (ability.can('index', 'Dashboard')) {
      return '/dashboard';
    }
    return '/employees';
  };

  const memoReload = useCallback(reloadScreen, []);

  const displayOptions = () => {
    if (currentModule?.key === 'profile') {
      if (currentCompany.id && ability.can('rrhh', 'Employee')) {
        return (
          <Dropdown.Item as={Link} to={setLinkRrhh()}>
            Ir a RRHH
          </Dropdown.Item>
        );
      }
      if (!currentCompany.id && user.role === 'admin') {
        return (
          <Dropdown.Item as={Link} to="/admin/companies">
            Ir a Soporte
          </Dropdown.Item>
        );
      }
    }
    if (!currentCompany.id) return undefined;
    if (currentModule?.key === 'dt') return undefined;
    return (
      <Dropdown.Item as={Link} to="/profile/dashboard">
        Mi Perfil
      </Dropdown.Item>
    );
  };

  useEffect(() => {
    window.addEventListener('resize', memoReload);
    return () => {
      window.removeEventListener('resize', memoReload);
    };
  }, [memoReload]);
  useEffect(userData, []);
  useEffect(updateCompanyData, [currentEmployee]);
  useEffect(getAdvanceSettings, [currentCompanyId]);
  // useEffect(fetchRoles, [updateRoles]);
  useEffect(updateAbilityRoles, [currentRoles]);
  useEffect(getNotifications, [unreadNotifications, currentEmployee]);
  useEffect(accessAccountDenied, [isPretending]);
  useEffect(redirectToAdminRootPath, [isPretending, stopImpersonate, user]);

  return (
    <>
      <Navbar bg="white" expand="lg" fixed="top" className="sps sps--abv line-bottom">
        <Navbar.Brand as={Link} to={currentTab === 'Soporte' ? '/admin/companies' : '/profile/dashboard'}>
          <MainLogo />
        </Navbar.Brand>
        {currentModule?.key !== 'dt' && (
          <>
            <Dropdown
              alignRight
              className="notification d-lg-none ml-auto position-notifications"
              onClick={closeSidebar}
            >
              <Dropdown.Toggle variant="circle-dark" id="dropdown-basic">
                <Icon icon="notifications" />
                {unreadNotifications > 0 && <Badge variant="danger">{unreadNotifications}</Badge>}
              </Dropdown.Toggle>
              <Dropdown.Menu className="notifications t-r-0 mobile">
                <>
                  <Dropdown.Item className="title">Notificaciones</Dropdown.Item>
                  <Dropdown.Item
                    as="div"
                    onClick={() => handleReadAllNotificationsClick()}
                    disabled={!(unreadNotifications > 0)}
                  >
                    <Button block variant="primary">
                      Marcar todas como leídas
                    </Button>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <div className="notifications-scrollbar">
                    {notifications.map((notification, index) => (
                      <Dropdown.Item
                        onClick={() => notificationClick(notification)}
                        key={`notificationTop${index.toString()}`}
                      >
                        {notification.read ? <span className="dot dot-grey" /> : <span className="dot dot-danger" />}
                        <p className="notification-message mt-3">{notification.message}</p>
                      </Dropdown.Item>
                    ))}
                  </div>
                  {notifications.length === 0 && (
                    <Dropdown.Item className="no mt-3">No tiene ninguna notificación</Dropdown.Item>
                  )}
                </>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
        <Button className="d-lg-none navbar-toggler ml-auto" onClick={() => dispatch(toogleSidebar())} variant={false}>
          <span className="navbar-toggler-icon" />
        </Button>
        <Navbar.Collapse>
          <Nav className="ml-auto">
            {user.isPretending && (
              <Button className="btn" variant="warning" block onClick={() => stopImpersonateAccount()}>
                Volver a mi Sesión
              </Button>
            )}
            {currentModule?.key === 'basic' && (
              <>
                <ButtonTooltip
                  variant="circle-dark"
                  className="btn-circle ml-3"
                  direction="bottom"
                  size="sm"
                  text="Indicadores Previsionales"
                  onClick={() => indicatorsClick()}
                >
                  <Icon icon="alert-circle" />
                </ButtonTooltip>
              </>
            )}
            {currentModule?.key !== 'dt' && (
              <>
                <Dropdown alignRight className="notification">
                  <Dropdown.Toggle variant="circle-dark" id="dropdown-basic">
                    <Icon icon="notifications" />
                    {unreadNotifications > 0 && <Badge variant="danger">{unreadNotifications}</Badge>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="notifications t-r-0">
                    <>
                      <Dropdown.Item className="title">Notificaciones</Dropdown.Item>
                      <Dropdown.Item
                        as="div"
                        onClick={() => handleReadAllNotificationsClick()}
                        disabled={!(unreadNotifications > 0)}
                      >
                        <Button block variant="primary">
                          Marcar todas como leídas
                        </Button>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <div className="notifications-scrollbar">
                        {notifications.map((notification, index) => (
                          <Dropdown.Item
                            onClick={() => notificationClick(notification)}
                            key={`notificationTop${index.toString()}`}
                          >
                            {notification.read ? (
                              <span className="dot dot-grey" />
                            ) : (
                              <span className="dot dot-danger" />
                            )}
                            <p className="notification-message">{notification.message}</p>
                          </Dropdown.Item>
                        ))}
                      </div>
                      {notifications.length === 0 && (
                        <Dropdown.Item className="no">No tiene ninguna notificación</Dropdown.Item>
                      )}
                    </>
                  </Dropdown.Menu>
                </Dropdown>
                <ButtonTooltip
                  variant="circle-dark"
                  className="btn-circle ml-3"
                  direction="bottom"
                  size="sm"
                  text="Ayuda"
                  href="https://ayuda.peoplework.cl/"
                  target="_blank"
                >
                  <Icon icon="help-circle" />
                </ButtonTooltip>
              </>
            )}
            <Dropdown alignRight className="user">
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                {currentModule?.key === 'dt' && 'Fiscalizador'}
                {currentModule?.key !== 'dt' && (user.name ? user.name : user.email)}
                <span className="avatar">
                  <span className={avatar.fileUrl ? 'content' : 'empty content'}>
                    {avatar.fileUrl ? <Image src={avatar.fileUrl} fluid /> : <Icon icon="camera" />}
                  </span>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={`t-r-0 ${!user.name && 'user-email'}`}>
                {displayOptions()}
                {currentModule?.key !== 'dt' && (
                  <>
                    <Dropdown.Item onClick={showChangePin}>Cambiar PIN de Firma</Dropdown.Item>
                    <Dropdown.Item onClick={showChangePassword}>Cambiar Contraseña</Dropdown.Item>
                  </>
                )}
                {children}
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <SimpleCenteredModal
        title={modalTitle}
        size={modalSize}
        body={modalBody}
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default NavbarLogged;
