import React from 'react';

import './HRDashboardStyled.scss';
import {
  Downloadable,
  SeeMore,
  OnlyInformation,
  AntiquityAverage,
  EmployeeDistribution,
  EmployeeByNationality
} from './components';
import AgeDistribution from './components/AgeDistribution';
import BlankCard from './components/BlankCard';

const HRDashboardStyled = ({
  title = '',
  label = '',
  subtitle = '',
  AnyList = '',
  TopHeaderColor = '',
  TypeCard = '',
  onClick = () => {},
  onRequest = false,
  nationalities = []
}) => {
  function RenderDashboard() {
    switch (TypeCard) {
      case 'Downloadable':
        return <Downloadable title={title} subtitle={subtitle} TopHeaderColor={TopHeaderColor} onRequest={onRequest} />;

      case 'SeeMore':
        return (
          <SeeMore
            title={title}
            subtitle={subtitle}
            TopHeaderColor={TopHeaderColor}
            onClick={onClick}
            onRequest={onRequest}
          />
        );

      case 'OnlyInformation':
        return (
          <OnlyInformation title={title} subtitle={subtitle} TopHeaderColor={TopHeaderColor} onRequest={onRequest} />
        );

      case 'AntiquityAverage':
        return (
          <AntiquityAverage title={title} subtitle={subtitle} TopHeaderColor={TopHeaderColor} onRequest={onRequest} />
        );

      case 'EmployeeDistribution':
        return <EmployeeDistribution title={title} subtitle={subtitle} AnyList={AnyList} onRequest={onRequest} />;

      case 'AgeDistribution':
        return <AgeDistribution title={title} subtitle={subtitle} onRequest={onRequest} />;

      case 'EmployeeByNationality':
        return <EmployeeByNationality nationalities={nationalities} subtitle={subtitle} onRequest={onRequest} />;

      default:
        return <></>;
    }
  }

  return (
    <>
      {!onRequest && (
        <div
          id="HRDashboard"
          style={{
            backgroundColor: '#fff',
            boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px'
          }}
        >
          <div
            style={{
              textAlign: 'center',
              paddingTop: 10,
              backgroundColor: TopHeaderColor,
              paddingBottom: 10,
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8
            }}
          >
            <p style={{ fontSize: '16px', fontWeight: 'bold', color: 'white' }}>{label}</p>
          </div>

          <div style={{ paddingRight: 15, paddingLeft: 15 }}>
            <RenderDashboard />
          </div>
        </div>
      )}
      {onRequest && <BlankCard isChart={TypeCard === 'EmployeeDistribution' || TypeCard === 'AgeDistribution'} />}
    </>
  );
};

export default HRDashboardStyled;
