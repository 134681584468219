import React from 'react';
import { Field, Form, getIn, withFormik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FormikDatePicker, FormikNumber, FormikSelect } from '../../../components';
import { overtimeDiscountsValues, overtimeDiscountsWithoutCalculatedOvertimeValues } from './FormOptions';

const OvertimeDiscountsMovementForm = props => {
  const { errors, hideModal, isSubmitting, payrollDates, setFieldTouched, setFieldValue, touched, values } = props;

  const { currentCompany } = useSelector(state => state.auth);
  const { companyModules } = currentCompany;
  const attendanceManagement = companyModules.includes('attendance_management');
  const { overtimeDiscountMovement } = values;
  const { employee } = overtimeDiscountMovement;
  const itemValues = attendanceManagement ? overtimeDiscountsValues : overtimeDiscountsWithoutCalculatedOvertimeValues;
  const payrollStartDate = moment(payrollDates[0], 'YYYY-MM-DD');
  const payrollEndDate = moment(payrollDates[1], 'YYYY-MM-DD').set({ hour: 23, minute: 59 });

  return (
    <Form>
      <Row>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-center" style={{ maxWidth: '150px' }}>
                NOMBRE
              </th>
              <th className="text-center" style={{ minWidth: '150px' }}>
                RUT
              </th>
            </tr>
          </thead>
          <tbody>
            <td className="text-center align-middle">{employee?.fullName}</td>
            <td className="text-center align-middle">{employee?.nationalIdentification}</td>
          </tbody>
        </table>
      </Row>
      <Row>
        <Col md={6}>
          <Field name="overtimeDiscountMovement[hourValue]">
            {({ field }) => (
              <FormikSelect
                {...field}
                placeholder="Seleccionar"
                options={itemValues}
                defaultValue={overtimeDiscountMovement.hourValue}
                onChange={data => {
                  setFieldValue(field.name, data ? data.value : '');
                }}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                margin="mb-1"
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="overtimeDiscountMovement[startDate]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                isOutsideRange={day => day.isAfter(payrollEndDate) || day.isBefore(payrollStartDate)}
                placeholder="dd/mm/aaaa"
                onDateChange={date => {
                  const vDate = date !== null ? date.format('DD/MM/YYYY') : '';
                  setFieldValue(field.name, vDate);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                margin="mb-1"
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field name="overtimeDiscountMovement[hours]">
            {({ field }) => (
              <FormikNumber
                {...field}
                rightAddon="hrs"
                placeholder="1"
                fieldName="overtimeDiscountMovement[hours]"
                value={overtimeDiscountMovement.hours}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                margin="mb-0"
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="overtimeDiscountMovement[minutes]">
            {({ field }) => (
              <FormikNumber
                {...field}
                rightAddon="min"
                placeholder="0"
                fieldName="overtimeDiscountMovement[minutes]"
                value={overtimeDiscountMovement.minutes}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                margin="mb-0"
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-2">
        <Col md={3} lg={3} className="mb-2 mb-md-0 mr-md-n4 mr-lg-n2">
          <Button block variant="outline-info" onClick={hideModal}>
            Cancelar
          </Button>
        </Col>
        <Col md={5} lg={3}>
          <Button type="submit" disabled={isSubmitting} block>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { employee, hours, hourValue, minutes, startDate } = props.overtimeDiscountMovement;

  return {
    overtimeDiscountMovement: {
      employee,
      hours,
      hourValue,
      minutes,
      startDate
    }
  };
};

const validationSchema = Yup.object().shape({
  overtimeDiscountMovement: Yup.object().shape(
    {
      startDate: Yup.date()
        .required('Debes seleccionar una fecha de inicio')
        .formatdate(),
      hourValue: Yup.string().required('Debes seleccionar un tipo de horas'),
      hours: Yup.number().when('minutes', {
        is: val => val > 0,
        then: Yup.number().nullable(),
        otherwise: Yup.number()
          .required('Debes ingresar la cantidad de horas')
          .positive('Debe ser mayor a 0')
          .max(23, 'Debe ser menor a 24 horas')
      }),
      minutes: Yup.number().when('hours', {
        is: val => val > 0,
        then: Yup.number().nullable(),
        otherwise: Yup.number()
          .required('Debes ingresar la cantidad de minutos')
          .positive('Debe ser mayor a 0')
          .max(59, 'Debe ser menor a 60 minutos')
      })
    },
    [['hours', 'minutes']]
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(OvertimeDiscountsMovementForm);
