import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCitiesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/cities', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexCitiesRequest = AwesomeDebouncePromise(indexCitiesRequest, 300);
