import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Filters from './Filters';
import { exportBankTransferRequest, validateExistingBankRequest } from '../../../requests/bankTransfers';
import { downloadFile } from '../../../services/utils';
import { DefaultModal } from '../../../components';

const WireTransfer = () => {
  const dispatch = useDispatch();
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalTitle, setDefaultModalTitle] = useState('');

  const handleDefaultModalClose = () => {
    setDefaultModalShow(false);
  };

  const handleSuccessRequest = response => {
    const { data } = response;
    const fullNameEmployees = data.map(empleado => empleado.full_name);
    if (fullNameEmployees.length > 0) {
      setDefaultModalShow(true);
      setDefaultModalTitle('Validación Bancos permitidos');
      setDefaultModalBody(
        <div>
          <p>
            Los siguientes trabajadores tienen bancos asociados que no son aceptados para la transferencia de fondo a
            otros bancos:
          </p>
          <br />
          <ul>
            {fullNameEmployees.map(name => (
              <li key={name}>{name}</li>
            ))}
          </ul>
        </div>
      );
    }
  };

  const successCallback = response => {
    downloadFile(response);
  };

  const handleCreateBankTransfer = params => {
    const { financialInstitution, advancePaymentProcess, conceptType, processDate } = params;
    const validateBanks = ['bice', 'santander'];

    const bankTransferParams = {
      bank: financialInstitution.code,
      type: conceptType,
      ids: advancePaymentProcess,
      date: processDate,
      ...params
    };
    exportBankTransferRequest({
      dispatch,
      params: { ...bankTransferParams },
      format: financialInstitution.format,
      successCallback
    });

    if (validateBanks.includes(financialInstitution.code)) {
      validateExistingBankRequest({
        dispatch,
        params: { ...bankTransferParams },
        successCallback: response => handleSuccessRequest(response)
      });
    }
  };
  return (
    <>
      <Filters formRequest={handleCreateBankTransfer} />
      <DefaultModal
        backdrop="static"
        body={defaultModalBody}
        handleClose={handleDefaultModalClose}
        show={defaultModalShow}
        title={defaultModalTitle}
        titleBtnClose="Cerrar"
        withConfirm={false}
      />
    </>
  );
};

export default WireTransfer;
