import React from 'react';
import { Field, Form, getIn, withFormik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FormikDatePicker, FormikSelect } from '../../../components';
import { absenceTypes } from './FormOptions';
import { delayMethod } from '../../../services/utils';
import { reincorporationDateHolidayRequest, workdaysBetweenHolidayRequest } from '../../../requests/holidays';

const AttendanceMovementForm = props => {
  const { errors, touched, hideModal, setFieldTouched, setFieldValue, isSubmitting, values, payrollDates = [] } = props;
  const { employee } = values.attendanceMovement;
  const payrollStartDate = moment(payrollDates[0], 'YYYY-MM-DD');
  const payrollEndDate = moment(payrollDates[1], 'YYYY-MM-DD').set({ hour: 23, minute: 59 });
  const dispatch = useDispatch();

  const calculateDays = ({ attendanceMovement, startDate, endDate }) => {
    let newStartDate = '';
    let newEndDate = '';
    if (startDate) {
      newStartDate = startDate;
      newEndDate = attendanceMovement.endDate;
    }
    if (endDate) {
      newStartDate = attendanceMovement.startDate;
      newEndDate = endDate;
    }
    workdaysBetweenHolidayRequest({
      dispatch,
      params: {
        start_date: newStartDate,
        end_date: newEndDate,
        include_all_days: true
      },
      successCallback: response => setFieldValue(`attendanceMovement[days]`, response.data)
    });
  };

  const calculateReincorporationDate = ({ attendanceMovement, endDate }) => {
    reincorporationDateHolidayRequest({
      dispatch,
      params: {
        end_date: endDate,
        employee_id: attendanceMovement.employee.id
      },
      successCallback: response => setFieldValue('attendanceMovement[reincorporationDate]', response.data.date)
    });
  };

  const updateReincorporationDate = ({ attendanceMovement, endDate }) => {
    calculateDays({ attendanceMovement, endDate });
    calculateReincorporationDate({ attendanceMovement, endDate });
  };
  return (
    <Form>
      <Row>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-center" style={{ maxWidth: '150px' }}>
                NOMBRE
              </th>
              <th className="text-center" style={{ minWidth: '150px' }}>
                RUT
              </th>
            </tr>
          </thead>
          <tbody>
            <td className="text-center align-middle">{employee.fullName}</td>
            <td className="text-center align-middle">{employee.nationalIdentification}</td>
          </tbody>
        </table>
      </Row>
      <Row>
        <Col md={12}>
          <Field name="attendanceMovement[absenceType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                placeholder="Seleccionar"
                options={absenceTypes}
                defaultValue={values.attendanceMovement.absenceType}
                onChange={data => {
                  setFieldValue(field.name, data ? data.value : '');
                }}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                margin="mb-0"
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={6}>
          <Field name="attendanceMovement[startDate]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                isOutsideRange={day => day.isAfter(payrollEndDate) || day.isBefore(payrollStartDate)}
                placeholder="dd/mm/aaaa"
                onDateChange={date => {
                  const vDate = date !== null ? date.format('DD/MM/YYYY') : '';
                  const { attendanceMovement } = props;
                  calculateDays({ attendanceMovement, startDate: vDate });
                  setFieldValue(field.name, vDate);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                margin="mb-0"
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="attendanceMovement[endDate]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                isOutsideRange={day => {
                  return day.isAfter(payrollEndDate) || day.isBefore(payrollStartDate);
                }}
                placeholder="dd/mm/aaaa"
                onDateChange={date => {
                  const vDate = date !== null ? date.format('DD/MM/YYYY') : '';
                  const { attendanceMovement } = props;
                  updateReincorporationDate({ attendanceMovement, endDate: vDate });
                  setFieldValue(field.name, vDate);
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                margin="mb-0"
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-2">
        <Col md={3} lg={3} className="mb-2 mb-md-0 mr-md-n4 mr-lg-n2">
          <Button block variant="outline-info" onClick={() => delayMethod(hideModal)}>
            Cancelar
          </Button>
        </Col>
        <Col md={5} lg={3}>
          <Button type="submit" disabled={isSubmitting} block>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { employee, absenceType, days, endDate, startDate, reincorporationDate } = props.attendanceMovement;

  return {
    attendanceMovement: {
      employee,
      absenceType,
      reincorporationDate,
      days,
      endDate,
      startDate
    }
  };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AttendanceMovementForm);
