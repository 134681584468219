import React, { useState, useEffect } from 'react';
import { Row, Col, Tab } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSetTab } from '../../services/hooks';
import { HRDashboardStyled } from '../../components';
import { SimpleCenteredModal } from '../../components/Utils/Modal';
import { humanResourcesRequest } from '../../requests/dashboards';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import AdvancePaymentColumns from '../Shared/AdvancePayment/approveColumns';
import AdvancePaymentDataTable from '../Shared/AdvancePayment/AdvancePaymentDataTable';
import EmployeeDataTable from '../Shared/Employee/EmployeeDataTable';
import ExpireContractColumns from './ExpireContractColumns';
import LoanColumns from '../Shared/Loan/approveColumns';
import LoanDataTable from '../Shared/Loan/LoanDataTable';
import PendingColumns from './PendingContractsColumns';
import PermissionColumns from '../Shared/Permission/approveColumns';
import PermissionDataTable from '../Shared/Permission/PermissionDataTable';
import VacationColumns from '../Shared/Vacation/approveColumns';
import VacationDataTable from '../Shared/Vacation/VacationDataTable';
import WorkflowColumns from './WorkflowColumns';
import WorkflowRequestDataTable from '../WorkflowRequest/WorkflowRequestDataTable';
import { debounceIndexCountriesRequest } from '../../requests/countries';

const styles = {
  modalContainer: { minHeight: '350px' }
};

const HRDashboard = () => {
  const location = useLocation();
  const [nationalities, setNationalities] = useState([]);
  const [cards, setCards] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const [key, setKey] = useSetTab('contracts', location);
  const { advanceSettings } = useSelector(state => state.utils);
  const [moreData, setMoreData] = useState(false);
  const legalHolidayEnabled =
    advanceSettings.filter(asItem => asItem.code === 'legal_holiday')[0]?.textValue === 'true';
  const progressiveHolidayEnabled =
    advanceSettings.filter(asItem => asItem.code === 'progressive_holiday')[0]?.textValue === 'true';
  const additionalDaysEnabled =
    advanceSettings.filter(asItem => asItem.code === 'additional_days')[0]?.textValue === 'true';
  const loanCard = advanceSettings?.filter(asItem => asItem.code === 'loan_request')[0]?.textValue === 'true';
  const apCard = advanceSettings?.filter(asItem => asItem.code === 'advance_payment_request')[0]?.textValue === 'true';
  const vacationVisible = legalHolidayEnabled || progressiveHolidayEnabled || additionalDaysEnabled;
  const companyLeave = advanceSettings?.filter(
    asItem => asItem.code === 'paid_leave' || asItem.code === 'without_paid_leave'
  );
  const PermissionCard = companyLeave.some(x => x.textValue === 'true');
  const dispatch = useDispatch();
  const [tempExpireDate, setTempExpireDate] = useState(0);
  const [numberRenders, setNumberReders] = useState(0);
  let expireDate = 0;
  // Modal Table
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  // Modal Table

  const handleClick = (value, TopHeaderColor) => {
    document.documentElement.style.setProperty('--TopHeaderColor', TopHeaderColor);

    setKey(value);
    setModalShow(true);
    setModalBody(
      <Tab.Container id="dashboard-tabs" activeKey={value} onSelect={value} mountOnEnter>
        <Tab.Content style={styles.modalContainer}>
          <Tab.Pane eventKey="contracts" style={styles.modalContainer}>
            {expireDate > 0 && (
              <EmployeeDataTable
                columns={ExpireContractColumns}
                clickRoute="/employees"
                customParams={{ to_expire: expireDate, active_staffing: true, active: true }}
                refreshDashboard={() => setMoreData(!moreData)}
                removeExpireFilter
                importContract
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="pending_employees" style={styles.modalContainer}>
            <EmployeeDataTable
              columns={PendingColumns}
              customParams={{ pending_contracts: true, active_staffing: true, active: true }}
              clickRoute="/employees"
              refreshDashboard={() => setMoreData(!moreData)}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="workflows" style={styles.modalContainer}>
            <WorkflowRequestDataTable
              customFilter={{ status: 'pending' }}
              columns={WorkflowColumns}
              refreshDashboard={() => setMoreData(!moreData)}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="vacations" style={styles.modalContainer}>
            {vacationVisible && (
              <VacationDataTable
                columns={VacationColumns}
                customParams={{
                  status: 'pending',
                  filter_vacation_exception:
                    'administrative_days,legal_openning_balance,progressive_openning_balance,additional_days_openning_balance,administrative_days_openning_balance'
                }}
                moreData={moreData}
                setMoreData={setMoreData}
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="loans" style={styles.modalContainer}>
            {loanCard && (
              <LoanDataTable
                columns={LoanColumns}
                customParams={{ status: 'pending' }}
                moreData={moreData}
                setMoreData={setMoreData}
                withImport
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="advance_payments" style={styles.modalContainer}>
            {apCard && (
              <AdvancePaymentDataTable
                columns={AdvancePaymentColumns}
                customParams={{ status: 'pending' }}
                moreData={moreData}
                setMoreData={setMoreData}
                withImport
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="permissions" style={styles.modalContainer}>
            {PermissionCard && (
              <PermissionDataTable
                columns={PermissionColumns}
                customParams={{ status: 'pending' }}
                moreData={moreData}
                setMoreData={setMoreData}
              />
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    );
  };

  const dataTableHead = () => {
    switch (key) {
      case 'employees':
        return 'Trabajadores';
      case 'contracts':
        return 'Contratos por Vencer';
      case 'pending_employees':
        return 'Fichas por Completar';
      case 'workflows':
        return 'Documentos pendientes de Firma';
      case 'vacations':
        return 'Solicitudes de Vacaciones Pendientes';
      case 'loans':
        return 'Solicitudes de Préstamos Pendientes';
      case 'advance_payments':
        return 'Solicitudes de Anticipos Pendientes';
      case 'permissions':
        return 'Solicitudes de Permisos Pendientes';
      default:
        return '';
    }
  };

  const fetchCardData = () => {
    const request = async () => {
      humanResourcesRequest({
        dispatch,
        successCallback: response => {
          setCards(camelCaseEmptyStringRecursive(response.data));
          setOnRequest(false);
        }
      });
    };
    request();
  };

  const fetchCountries = () => {
    debounceIndexCountriesRequest({
      dispatch,
      params: {
        sort_column: 'position',
        display_length: 250,
        nationality: true
      },
      successCallback: response => {
        setNationalities(response.data.data);
      }
    });
  };

  useEffect(fetchCountries, []);
  useEffect(fetchCardData, [moreData]);
  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    const localExpireDate = advanceSettings?.filter(asItem => asItem.code === 'expire_days')[0]?.integerValue || 0;
    setTempExpireDate(localExpireDate);
    setNumberReders(old => old + 1);
  }, [advanceSettings]);

  // esto es por si no existe ningun settins tome por defecto 5 y renderize el componente
  if (
    (numberRenders >= 2 && tempExpireDate === 0) ||
    (numberRenders === 1 && tempExpireDate === 0 && advanceSettings.length > 0)
  ) {
    expireDate = 5;
  } else {
    expireDate = tempExpireDate;
  }

  return (
    <>
      <div className="MainDivCard" style={{ backgroundColor: '#f5f5f5', paddingTop: 15 }}>
        <div className="col-sm-12">
          <Row>
            <Col className="mb-4 mt-1" md={6} xl={4} xs={12}>
              <HRDashboardStyled
                title={cards.fullStaff}
                label="COLABORADORES"
                TopHeaderColor="#2bb48f"
                subtitle="Registrados"
                TypeCard="Downloadable"
                onRequest={onRequest}
              />
            </Col>
            <Col className="mb-4 mt-1" md={6} xl={4} xs={12}>
              <HRDashboardStyled
                title={cards.fixedTermContracts}
                label="CONTRATO A PLAZO FIJO"
                TopHeaderColor="#ffa600"
                subtitle={cards.percentFixedTermContracts}
                TypeCard="OnlyInformation"
                onRequest={onRequest}
              />
            </Col>
            <Col className="mb-4 mt-1" md={6} xl={4} xs={12}>
              <HRDashboardStyled
                title={cards.expireContracts}
                label="CONTRATOS VENCIDOS Y POR VENCER"
                TopHeaderColor="#b40000"
                subtitle={`En los próximos ${expireDate} días`}
                TypeCard="SeeMore"
                onClick={() => handleClick('contracts', '#b40000')}
                onRequest={onRequest}
              />
            </Col>
            <Col className="mb-4 mt-1" md={6} xl={4} xs={12}>
              <HRDashboardStyled
                title={cards.antiquityAverage}
                label="ANTIGÜEDAD PROMEDIO"
                TopHeaderColor="#2bb48f"
                subtitle="Años"
                TypeCard="AntiquityAverage"
                onRequest={onRequest}
              />
            </Col>
            <Col className="mb-4 mt-1" md={6} xl={4} xs={12}>
              <HRDashboardStyled
                title={cards.undefinedContracts}
                label="CONTRATO INDEFINIDO"
                TopHeaderColor="#ffa600"
                subtitle={cards.percentUndefinedContracts}
                TypeCard="OnlyInformation"
                onRequest={onRequest}
              />
            </Col>
            <Col className="mb-4 mt-1" md={6} xl={4} xs={12}>
              <HRDashboardStyled
                title={cards.pendingEmployees}
                label="FICHAS POR COMPLETAR"
                TopHeaderColor="#b40000"
                subtitle="Fichas por completar"
                TypeCard="SeeMore"
                onClick={() => handleClick('pending_employees', '#ffa600')}
                onRequest={onRequest}
              />
            </Col>
          </Row>

          <SimpleCenteredModal
            headerClass="TopHeaderStyle"
            size="xl"
            title={dataTableHead()}
            body={modalBody}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>

        <div className="col-sm-12 mb-4 mt-1">
          <HRDashboardStyled
            title={cards.groupByRegion}
            label="DISTRIBUCIÓN DE EMPLEADOS"
            TopHeaderColor="#2bb48f"
            subtitle={cards.groupByCostCenter}
            AnyList={cards.groupByCommune}
            TypeCard="EmployeeDistribution"
            onRequest={onRequest}
          />
        </div>

        <div className="col-sm-12 ">
          <Row>
            <Col className="mb-4 mt-1" md={8} xs={12}>
              <HRDashboardStyled
                title={cards.groupByAgeAndGender}
                label="DISTRIBUCIÓN ETAREA"
                TopHeaderColor="#2bb48f"
                subtitle={cards.groupByGender}
                TypeCard="AgeDistribution"
                onRequest={onRequest}
              />
            </Col>
            <Col className="mb-4 mt-1" md={4} xs={12}>
              <HRDashboardStyled
                title=""
                label="EMPLEADO POR NACIONALIDAD"
                TopHeaderColor="#2bb48f"
                subtitle={cards.groupByNacionality}
                TypeCard="EmployeeByNationality"
                onRequest={onRequest}
                nationalities={nationalities}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default HRDashboard;
