import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ComponentDataTable } from '../../../../components';
import ColumnsResume from '../Columns/ColumnsResume';
import { PayrollProcessContext } from '../../context/PayrollProcessProvider';
import { exportDataProcesses, indexPayrollApProcessRequest } from '../../../../requests/payrollAdvancePaymentProcess';
import { downloadFile } from '../../../../services/utils';

const ResumeDataTable = () => {
  const dispatch = useDispatch();
  const { payrollProcess } = useContext(PayrollProcessContext);
  const [onRequest, setOnRequest] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [payrollApProcessesData, setPayrollApProcessesData] = useState([]);
  const [payrollCurrentState, setPayrollCurrentState] = useState('');
  const [amount, setAmount] = useState(0);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);

  const handleRequest = params => {
    setOnRequest(true);
    indexPayrollApProcessRequest({
      dispatch,
      params: {
        filter_ids: payrollProcess.payroll_ap_process_id,
        ap_process_id: payrollProcess.selectedAdvancePaymentProcesses,
        paginate: false,
        ...params
      },
      successCallback: ({ data }) => {
        if (data.data?.at(0)?.badvance_payment_processes) {
          setPayrollApProcessesData(data.data[0].badvance_payment_processes);
          setPayrollCurrentState(data.data[0].status);
          setAmount(data.data[0].badvance_payment_processes.length);
        }
        setOnRequest(false);
        setMoreData(false);
        setClearSelectedRows(false);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleExport = (payrollId, apProcessId) => {
    setOnRequest(true);
    exportDataProcesses({
      dispatch,
      params: {
        filter_process_id: apProcessId,
        payroll_process_id: payrollId
      },
      successCallback: response => {
        setMoreData(moreData);
        downloadFile(response);
      },
      failureCallback: error => {
        // eslint-disable-next-line no-console
        console.log(error);
      },
      callback: () => setOnRequest(false)
    });
  };
  useEffect(handleRequest, [])

  return (
    <ComponentDataTable
      clearSelectedRows={clearSelectedRows}
      columns={ColumnsResume(handleExport, payrollCurrentState)}
      data={payrollApProcessesData}
      paginationServer={false}
      moreData={moreData}
      onRequest={onRequest}
      totalRows={amount}
      withSearch={false}
      pagination={false}
      sortServer={false}
    />
  );
};

export default ResumeDataTable;
