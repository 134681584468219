import React from 'react';
import memoize from 'memoize-one';
import { DatatableEmployeeName } from '../../components';

const columnsSimplify = memoize(clickHandler => [
  {
    name: 'TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'TIPO',
    selector: 'absence_type',
    cell: item => item.translated_absence_type,
    sortable: true,
    grow: '2'
  }
]);

export default columnsSimplify;
