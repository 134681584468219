const civilStateTypes = [
  { label: 'Soltero(a)', value: 'single' },
  { label: 'Casado(a)', value: 'married' },
  { label: 'Viudo(a)', value: 'widower' },
  { label: 'Conviviente Civil', value: 'civil_union_agreement' },
  { label: 'Divorciado(a)', value: 'divorced' }
];

const dateTypes = [
  { label: 'Fecha Inicio de Contrato', value: 'contract_start_date' },
  { label: 'Fecha Termino de Contrato', value: 'contract_end_date' },
  { label: 'Fecha de vencimiento Cédula de Identidad', value: 'dni_expiration_date' }
];

const educationLevelTypes = [
  { label: 'Sin Escolaridad', value: 'no_school' },
  { label: 'Básica Incompleta', value: 'basic_incomplete' },
  { label: 'Básica Completa', value: 'basic_complete' },
  { label: 'Media Incompleta', value: 'media_incomplete' },
  { label: 'Media Completa', value: 'media_complete' },
  { label: 'Superior técnica Profesional Incompleta', value: 'technical_incomplete' },
  { label: 'Superior Técnica Profesional Completa', value: 'technical_complete' },
  { label: 'Universitaria Incompleta', value: 'university_incomplete' },
  { label: 'Universitaria Completa', value: 'university_complete' }
];

const contractTypes = [
  { label: 'Indefinido', value: 'undefined_term' },
  { label: 'Plazo fijo', value: 'fixed_term' },
  { label: 'Por obra o faena', value: 'work_or_task' },
  { label: 'A Honorarios - Normal(Retención Base)', value: 'to_fees_normal' },
  { label: 'A Honorarios - Alumno en Práctica', value: 'to_fees_student_practice' },
  { label: 'A Honorarios - Extranjero(Retención 20 %)', value: 'to_fees_foreign_20' },
  { label: 'A Honorarios - Extranjero(Retención 15 %)', value: 'to_fees_foreign_15' },
  { label: 'A Honorarios - Extranjero(Retención 15, 25 %)', value: 'to_fees_foreign_15_25' },
  { label: 'A Honorarios - Sin Retención', value: 'to_fees_without_retention' },
  { label: 'A Honorarios - Contratista', value: 'to_fees_contractor' },
  { label: 'Part time (jornada parcial)', value: 'contract_part_time' }
];

const foreignTechnicianOptions = [
  { label: 'Sí', value: 'affirmative' },
  { label: 'No', value: 'negative' }
];

const identificationTypes = [
  { label: 'RUT', value: 'rut' },
  { label: 'Pasaporte', value: 'passport' },
  { label: 'Otra Identificación', value: 'other_id' }
];

const workSchedules = [
  { label: 'Jornada Completa', value: 'full_time' },
  { label: 'Jornada Parcial', value: 'part_time' },
  { label: 'Articulo 22', value: 'article_22' }
];

const contractStatuses = [
  { label: 'Activo', value: 'active' },
  { label: 'Finiquitado', value: 'finished' }
];

const gratificationTypes = [
  { label: 'Garantizada', value: 'guaranteed' },
  { label: 'Sin Gratificación', value: 'without_gratification' }
];

const gratificationPeriods = [{ label: 'Mensual', value: 'monthly' }];

const gratificationConditions = [
  { label: 'Base de gratificación', value: 'assets_subtotal' },
  { label: 'Sueldo Base', value: 'base_salary' }
];

const markingTypes = [
  { label: 'Celular', value: 'cellphone' },
  { label: 'Huella', value: 'fingerprint' },
  { label: 'Libro de Asistencia', value: 'book' },
  { label: 'Reconocimiento Facial', value: 'facial_recognition' },
  { label: 'Tarjeta', value: 'card' },
  { label: 'Contraseña', value: 'password' }
];

const paymentSchedules = [{ label: 'Mensual', value: 'monthly' }];

const isOldAgeOptions = [
  { label: 'Sí', value: 'affirmative' },
  { label: 'No', value: 'negative' }
];

const incomeTaxTypes = [
  { label: 'Impuesto de Segunda Categoria', value: 'income_tax_second_category' },
  { label: 'Impuesto Unico Obrero Agricola', value: 'income_tax_agricultural_worker' },
  { label: 'Impuesto Adicional', value: 'income_tax_additional' }
];

const ineCodeTypes = [
  {
    label: 'Directivos de la Administración Pública y de Empresas',
    value: 'directors'
  },
  {
    label: 'Profesionales, Científicos e Intelectuales',
    value: 'professionals_scientists'
  },
  {
    label: 'Técnicos y Profesionales de Nivel Medio',
    value: 'technicians'
  },
  {
    label: 'Empleados de Oficina',
    value: 'office_employees'
  },
  {
    label: 'Trabajadores de los Servicios Personales, de Protección y Seguridad',
    value: 'personal_protection'
  },
  {
    label: 'Modelos, Vendedores y Demostradores',
    value: 'models_vendors'
  },
  {
    label: 'Agricultores y Trabajadores Calificados Agropecuarios y Pesqueros',
    value: 'farmers'
  },
  {
    label: 'Oficiales, Operarios y Artesanos de Artes Mecánicas y de otros Oficios',
    value: 'officials_operators'
  },
  {
    label: 'Operadores de Instalaciones y Máquinas y Montadores',
    value: 'operators'
  },
  {
    label: 'Trabajadores no Calificados',
    value: 'workers_without_skills'
  }
];

const unitOfAccountTypes = [
  { label: 'UF', value: 'uf' },
  { label: '%', value: 'percentage' },
  { label: '$', value: 'pesos' }
];

const senceLevels = [
  { label: '1 - Ejecutivo', value: 'executive' },
  { label: '2 - Profesional', value: 'professional' },
  { label: '3 - Mando Medio', value: 'mid_level' },
  { label: '4 - Administrativo', value: 'administrative' },
  { label: '5 - Trabajador calificado', value: 'skilled' },
  { label: '6 - Trabajador semi calificado', value: 'semi_skilled' },
  { label: '7 - Trabajador NO Calificado', value: 'unskilled' }
];

const unitOfAccountVoluntaryTypes = [
  { label: 'UF', value: 'uf' },
  { label: '$', value: 'pesos' }
];

const voluntaryPensionFundTypes = [
  { label: 'A', value: 'type_a' },
  { label: 'B', value: 'type_b' }
];

const voluntaryPrevisionTypes = [
  { label: 'APV', value: 'apv' },
  { label: 'Cuenta 2', value: 'count_two' },
  { label: 'Depósito Convenido', value: 'deposit_agreed' },
  { label: 'APV Colectivo', value: 'collective_apv' },
  { label: 'Afiliado Voluntario', value: 'voluntary_affiliate' },
  { label: 'Indemnización a todo evento', value: 'indemnity_event' }
];

const relationTypes = [
  { label: 'Cónyuge', value: 'spouse' },
  { label: 'Hijo', value: 'children' },
  { label: 'Nieto', value: 'grandchild' },
  { label: 'Madre Viuda', value: 'widowed_mother' },
  { label: 'Ascendiente mayor a 65 años', value: 'greater_ascendant' },
  { label: 'Conviviente Civil', value: 'civil_coexistence' },
  { label: 'Hijo entre 18 y 24 años (estudiante)', value: 'child_of_legal_age' },
  { label: 'Cónyuge Embarazada', value: 'pregnant_spouse' },
  { label: 'Trabajadora Embarazada', value: 'pregnant_worker' },
  { label: 'Cónyuge Inválido', value: 'invalid_spouse' },
  { label: 'Hijo Inválido', value: 'invalid_child' },
  { label: 'Ascendiente Inválido', value: 'invalid_ascendant' },
  { label: 'Padre', value: 'father' },
  { label: 'Madre', value: 'mother' }
];

const paymentMethods = [
  { label: 'Directo', value: 'direct' },
  { label: 'Indirecto', value: 'indirect' }
];

const personalMovmentCodes = [{ label: 'Cese de Cotización de Afiliado Voluntario', value: 'contribution_cessation' }];

const pensionSchemes = [
  { label: 'AFP', value: 'afp' },
  { label: 'No Cotiza', value: 'not_listed' },
  { label: 'IPS (ex INP)', value: 'ips' }
];

const pensionerTypes = [
  { label: 'Activo (No Pensionado)', value: 'active_not_pensioned' },
  { label: 'Pensionado y cotiza', value: 'pensioned_and_contributing' },
  { label: 'Activo > 65 años (nunca pensionado)', value: 'active_over_65_years' }
];

const unemploymentInsuranceReasons = [
  { label: 'Contrato anterior a Octubre del 2002', value: 'old_contract' },
  { label: 'Pensionado', value: 'pensioned' }
];

const ipsTypes = [
  { label: 'Bancaria - Régimen 1', value: 'banking_regime_1' },
  { label: 'Bancaria - Régimen 2', value: 'banking_regime_2' },
  { label: 'Bancaria - Régimen 3', value: 'banking_regime_3' },
  { label: 'Bancaria - Régimen 14', value: 'banking_regime_14' },
  { label: 'Bancaria - Régimen 21', value: 'banking_regime_21' },
  { label: 'Bancaria - Régimen 34', value: 'banking_regime_34' },
  { label: 'Caja de Previsión de Gildemeister - Régimen 1', value: 'gildemeister_prevision_fund_regime_1' },
  { label: 'Caja de Previsión de Gildemeister - Régimen 2', value: 'gildemeister_prevision_fund_regime_2' },
  { label: 'Caja de Previsión Social de los EE - Régimen 1', value: 'ee_social_security_fund_regime_1' },
  { label: 'Caja de Previsión Social de los EE - Régimen 2', value: 'ee_social_security_fund_regime_2' },
  { label: 'Caja Ferro - Régimen 2', value: 'ferro_box_regime_2' },
  { label: 'Caja Ferro - Régimen 3', value: 'ferro_box_regime_3' },
  { label: 'Caja Ferro - Régimen 4', value: 'ferro_box_regime_4' },
  { label: 'Caja Ferro - Régimen 5', value: 'ferro_box_regime_5' },
  { label: 'Caja Ferro - Régimen 7', value: 'ferro_box_regime_7' },
  { label: 'Canaempu: Periodistas - Régimen 1', value: 'canaempu_journalists_regime_1' },
  { label: 'Canaempu: Periodistas - Régimen 2', value: 'canaempu_journalists_regime_2' },
  { label: 'Canaempu: Periodistas - Régimen 3', value: 'canaempu_journalists_regime_3' },
  { label: 'Canaempu: Periodistas - Régimen 4', value: 'canaempu_journalists_regime_4' },
  { label: 'Canaempu: Periodistas - Régimen 5', value: 'canaempu_journalists_regime_5' },
  { label: 'Canaempu: Periodistas - Régimen 6', value: 'canaempu_journalists_regime_6' },
  { label: 'Canaempu: Periodistas - Régimen 7', value: 'canaempu_journalists_regime_7' },
  { label: 'Canaempu: Periodistas - Régimen 8', value: 'canaempu_journalists_regime_8' },
  { label: 'Canaempu: Periodistas - Régimen 11', value: 'canaempu_journalists_regime_11' },
  { label: 'Canaempu: Periodistas - Régimen 12', value: 'canaempu_journalists_regime_12' },
  { label: 'Canaempu: Periodistas - Régimen 13', value: 'canaempu_journalists_regime_13' },
  { label: 'Canaempu: Periodistas - Régimen 14', value: 'canaempu_journalists_regime_14' },
  { label: 'Canaempu: Periodistas - Régimen 15', value: 'canaempu_journalists_regime_15' },
  { label: 'Canaempu: Periodistas - Régimen 16', value: 'canaempu_journalists_regime_16' },
  { label: 'Canaempu: Periodistas - Régimen 17', value: 'canaempu_journalists_regime_17' },
  { label: 'Canaempu: Periodistas - Régimen 18', value: 'canaempu_journalists_regime_18' },
  { label: 'Canaempu: Periodistas - Régimen 21', value: 'canaempu_journalists_regime_21' },
  { label: 'Canaempu: Periodistas - Régimen 22', value: 'canaempu_journalists_regime_22' },
  { label: 'Canaempu: Periodistas - Régimen 23', value: 'canaempu_journalists_regime_23' },
  { label: 'Canaempu: Periodistas - Régimen 24', value: 'canaempu_journalists_regime_24' },
  { label: 'Canaempu: Periodistas - Régimen 25', value: 'canaempu_journalists_regime_25' },
  { label: 'Canaempu: Periodistas - Régimen 26', value: 'canaempu_journalists_regime_26' },
  { label: 'Canaempu: Periodistas - Régimen 31', value: 'canaempu_journalists_regime_31' },
  { label: 'Canaempu: Periodistas - Régimen 32', value: 'canaempu_journalists_regime_32' },
  { label: 'Canaempu: Periodistas - Régimen 33', value: 'canaempu_journalists_regime_33' },
  { label: 'Canaempu: Periodistas - Régimen 34', value: 'canaempu_journalists_regime_34' },
  { label: 'Canaempu: Públicos - Régimen 1', value: 'canaempu_publics_regime_1' },
  { label: 'Canaempu: Públicos - Régimen 2', value: 'canaempu_publics_regime_2' },
  { label: 'Canaempu: Públicos - Régimen 3', value: 'canaempu_publics_regime_3' },
  { label: 'Canaempu: Públicos - Régimen 5', value: 'canaempu_publics_regime_5' },
  { label: 'Canaempu: Públicos - Régimen 6', value: 'canaempu_publics_regime_6' },
  { label: 'Canaempu: Públicos - Régimen 8', value: 'canaempu_publics_regime_8' },
  { label: 'Canaempu: Públicos - Régimen 9', value: 'canaempu_publics_regime_9' },
  { label: 'Canaempu: Públicos - Régimen 10', value: 'canaempu_publics_regime_10' },
  { label: 'Canaempu: Públicos - Régimen 11', value: 'canaempu_publics_regime_11' },
  { label: 'Canaempu: Públicos - Régimen 12', value: 'canaempu_publics_regime_12' },
  { label: 'Canaempu: Públicos - Régimen 21', value: 'canaempu_publics_regime_21' },
  { label: 'Canaempu: Públicos - Régimen 22', value: 'canaempu_publics_regime_22' },
  { label: 'Canaempu: Públicos - Régimen 23', value: 'canaempu_publics_regime_23' },
  { label: 'Canaempu: Públicos - Régimen 25', value: 'canaempu_publics_regime_25' },
  { label: 'Canaempu: Públicos - Régimen 26', value: 'canaempu_publics_regime_26' },
  { label: 'Canaempu: Públicos - Régimen 28', value: 'canaempu_publics_regime_28' },
  { label: 'Canaempu: Públicos - Régimen 29', value: 'canaempu_publics_regime_29' },
  { label: 'Canaempu: Públicos - Régimen 30', value: 'canaempu_publics_regime_30' },
  { label: 'Canaempu: Públicos - Régimen 31', value: 'canaempu_publics_regime_31' },
  { label: 'Canaempu: Públicos - Régimen 32', value: 'canaempu_publics_regime_32' },
  { label: 'Canaempu: Públicos - Régimen 38', value: 'canaempu_publics_regime_38' },
  { label: 'Caprebech - Régimen 1', value: 'caprebech_regime_1' },
  { label: 'Caprebech - Régimen 3', value: 'caprebech_regime_3' },
  { label: 'Caprebech - Régimen 14', value: 'caprebech_regime_14' },
  { label: 'Caprebech - Régimen 21', value: 'caprebech_regime_21' },
  { label: 'Caprebech - Régimen 34', value: 'caprebech_regime_34' },
  { label: 'Capremer - Régimen 1', value: 'capremer_regime_1' },
  { label: 'Capremer - Régimen 2', value: 'capremer_regime_2' },
  { label: 'Capremer - Régimen 3', value: 'capremer_regime_3' },
  { label: 'Capremer - Régimen 4', value: 'capremer_regime_4' },
  { label: 'Capremer - Régimen 5', value: 'capremer_regime_5' },
  { label: 'Capremer - Régimen 6', value: 'capremer_regime_6' },
  { label: 'Capremer - Régimen 8', value: 'capremer_regime_8' },
  { label: 'Copremusa EE - Régimen 1', value: 'ee_copremusa_regime_1' },
  { label: 'Copremusa EE - Régimen 2', value: 'ee_copremusa_regime_2' },
  { label: 'Copremusa EE - Régimen 3', value: 'ee_copremusa_regime_3' },
  { label: 'Diomp - Régimen 1', value: 'diomp_regime_1' },
  { label: 'EE Municipales de la República - Régimen 1', value: 'municipalities_of_republic_regime_1' },
  { label: 'EE Municipales de la República - Régimen 2', value: 'municipalities_of_republic_regime_2' },
  { label: 'EE Municipales de la República - Régimen 3', value: 'municipalities_of_republic_regime_3' },
  { label: 'EE Municipales de la República - Régimen 4', value: 'municipalities_of_republic_regime_4' },
  { label: 'EE Salitre - Régimen 1', value: 'ee_salitre_regime_1' },
  { label: 'EE Salitre - Régimen 2', value: 'ee_salitre_regime_2' },
  { label: 'Empart - Régimen 1', value: 'empart_regime_1' },
  { label: 'Empart - Régimen 2', value: 'empart_regime_2' },
  { label: 'Empart - Régimen 3', value: 'empart_regime_3' },
  { label: 'Empart - Régimen 4', value: 'empart_regime_4' },
  { label: 'Empart - Régimen 6', value: 'empart_regime_6' },
  { label: 'Empleados de Emos - Régimen 1', value: 'emos_employees_regime_1' },
  { label: 'Hípica Nacional - Régimen 1', value: 'national_equestrian_regime_1' },
  { label: 'Hípica Nacional - Régimen 2', value: 'national_equestrian_regime_2' },
  { label: 'Hípica Nacional - Régimen 3', value: 'national_equestrian_regime_3' },
  { label: 'Hípica Nacional - Régimen 4', value: 'national_equestrian_regime_4' },
  { label: 'Mauricio, Hochschild - Régimen 1', value: 'mauricio_hochschild_regime_1' },
  { label: 'Mauricio, Hochschild - Régimen 2', value: 'mauricio_hochschild_regime_2' },
  { label: 'Obreros de Emos - Régimen 1', value: 'emos_workers_regime_1' },
  { label: 'OO Municipales de la República - Régimen 1', value: 'municipal_oos_of_the_republic_regime_1' },
  { label: 'OO Municipales de la República - Régimen 2', value: 'municipal_oos_of_the_republic_regime_2' },
  { label: 'OO Municipales de la República - Régimen 3', value: 'municipal_oos_of_the_republic_regime_3' },
  { label: 'Sección Esp. Previsión Empleados - Régimen 1', value: 'special_employee_prevision_section_regime_1' },
  { label: 'Sección Esp. Previsión Empleados - Régimen 2', value: 'special_employee_prevision_section_regime_2' },
  { label: 'Secgasco - Régimen 1', value: 'secgasco_regime_1' },
  { label: 'Secgasco - Régimen 2', value: 'secgasco_regime_2' },
  { label: 'Servicios de Seguro Social - Régimen 1', value: 'social_security_services_regime_1' },
  { label: 'Servicios de Seguro Social - Régimen 2', value: 'social_security_services_regime_2' },
  { label: 'Triomar - Régimen 1', value: 'triomar_regime_1' },
  { label: 'Triomar - Régimen 2', value: 'triomar_regime_2' },
  { label: 'Triomar - Régimen 3', value: 'triomar_regime_3' },
  { label: 'Triomar - Régimen 4', value: 'triomar_regime_4' }
];

const heavyLaborAdditionals = [
  { label: 'No', value: 'no_heavy_labor' },
  { label: 'Sí, trabajo menos pesado', value: 'less_heavy_labor' },
  { label: 'Sí, trabajo pesado', value: 'with_heavy_labor' }
];

const familyAllowanceSections = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' }
];

const workScheduleTooltipText = {
  hour45:
    'Jornada de 45 horas: La jornada ordinaria de trabajo es la que establece el Código del Trabajo para todos los ' +
    'trabajadores, salvo algunas excepciones. Ésta tiene una duración máxima de 45 horas semanales, que no podrán ' +
    'distribuirse en más de seis días ni en menos de cinco, y en ningún caso podrá exceder las 10 horas diarias.',
  article22:
    'Artículo 22: Pueden quedar excluidos de la limitación de jornada de trabajo, esto es, del tope de las 45 horas ' +
    'semanales: - Los trabajadores que presten servicios a distintos empleadores - Los gerentes, administradores, ' +
    'apoderados con facultades de administración - Los agentes comisionistas y de seguro, vendedores viajantes, ' +
    'cobradores y demás similares que no ejerzan sus funciones en el local del establecimiento - Los contratados por ' +
    'el Código del Trabajo para prestar servicios en su propio hogar o en un lugar libremente elegidos por ellos; - ' +
    'Todos aquellos que trabajen sin fiscalización superior inmediata Sin perjuicio de los trabajadores señalados ' +
    'anteriormente, el artículo 22 considera la exclusión de jornada de otros trabajadores: - Los trabajadores que ' +
    'se desempeñan a bordo de naves pesqueras. - Los contratados para prestar servicios preferentemente fuera del ' +
    'lugar o sitio de funcionamiento de la empresa, mediante la utilización de medios informáticos o de ' +
    'telecomunicaciones. - Los deportistas profesionales y trabajadores que desempeñan actividades conexas.',
  partTime:
    'Jornada parcial: es aquella de 30 horas o menos de trabajo a la semana pactada en el contrato y tiene las ' +
    'siguientes características: - Se permite el pacto de horas extraordinarias(en caso de existir una necesidad o ' +
    'situación temporal) - La jornada diaria debe ser continua y no puede exceder de 10 horas, debiendo ' +
    'interrumpirse por un lapso no inferior a media hora ni superior a una hora para la colación - Las partes ' +
    'pueden pactar distintas alternativas para distribuir la jornada.'
};

const advancePaymentsTypes = [
  { label: 'Ingreso de Datos', value: 'data_loads' },
  { label: 'Pactados', value: 'contract_balances' },
  { label: 'Solicitudes', value: 'advance_payments' }
];
const workerQualities = [{ label: 'Trabajador dependiente sector privado', value: 'dependent_worker_private_sector' }];

const disabilityOptions = [
  { label: 'No', value: 'no_disability' },
  { label: 'Discapacidad certificada por compin', value: 'compin_certified_disability' },
  { label: 'Asignatario pensión por invalidez total', value: 'total_disability_pension_assignee' },
  { label: 'Pensionado con invalidez parcial', value: 'pensioner_with_partial_disability' }
];

const chargeTypes = [
  { label: 'No es Carga', value: 'is_not_charge' },
  { label: 'Simple', value: 'simple' },
  { label: 'Maternal', value: 'maternal' },
  { label: 'Inválido', value: 'charge_invalid' }
];

const loanOriginTypes = [
  { label: 'Solicitud', value: 0 },
  { label: 'Ingreso Datos', value: 1 }
];

export {
  advancePaymentsTypes,
  civilStateTypes,
  contractStatuses,
  contractTypes,
  dateTypes,
  educationLevelTypes,
  familyAllowanceSections,
  foreignTechnicianOptions,
  gratificationTypes,
  gratificationPeriods,
  gratificationConditions,
  heavyLaborAdditionals,
  identificationTypes,
  incomeTaxTypes,
  ineCodeTypes,
  isOldAgeOptions,
  ipsTypes,
  loanOriginTypes,
  markingTypes,
  paymentMethods,
  paymentSchedules,
  personalMovmentCodes,
  pensionSchemes,
  pensionerTypes,
  relationTypes,
  senceLevels,
  unemploymentInsuranceReasons,
  unitOfAccountTypes,
  unitOfAccountVoluntaryTypes,
  voluntaryPensionFundTypes,
  voluntaryPrevisionTypes,
  workSchedules,
  workScheduleTooltipText,
  workerQualities,
  disabilityOptions,
  chargeTypes
};
