import ApiService from '../services/apiService';

export const indexLoanProcessesRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/loan_processes`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createLoanProcessRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('post', `/loan_processes`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const showLoanProcessRequest = (id, { dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/loan_processes/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const destroyLoanProcessRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('delete', `/loan_processes/${id}`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const closeLoanProcessRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('put', `/loan_processes/${id}/close`, {
    dispatch,
    successCallback,
    callback
  });

export const closeMassiveLoanProcessesRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('post', `/loan_processes/massive_close`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const deleteMassiveLoanProcessesRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('delete', `/loan_processes/massive_destroy`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const updateLoanProcessRequest = (id, { dispatch, params, successCallback, failureCallback, callback }) =>
  ApiService.request('put', `/loan_processes/${id}/update_installments`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback
  });

export const exportLoanProcessRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/loan_processes/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback
  });
