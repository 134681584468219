import React from 'react';
import { useSelector } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { camelCaseEmptyStringRecursive } from '../../../../services/utils';
import { sendAlert } from '../../../../actions/utils';
import MassiveAttendanceFormReplace from './MassiveAttendanceReplaceForm';
import MassiveAttendanceChangeRejectedForm from './MassiveAttendanceChangeRejectedForm';

import {
  massiveCreateAttendanceChangeRequest,
  massiveApprovedAttendanceChangeRequest,
  massivRejectedAttendanceChangeRequest,
  massivDisabledAttendanceChangeRequest,
  massiveActiveAttendanceChangeRequest
} from '../../../../requests/AttendanceChange';

const useMassiveActionHook = (
  selectedRows,
  dispatch,
  setSimpleModalBody,
  setSimpleModalTitle,
  setSimpleModalShow,
  setModalShow,
  setModalTitle,
  setModalBody,
  setCenterModalShow,
  setModalAction,
  setSize,
  handleSuccessRequest,
  handleFailureRequest,
  handleModalClose,
  branchOffices
) => {
  const parsedRows = camelCaseEmptyStringRecursive(selectedRows);
  const { currentCompany } = useSelector(state => state.auth);

  const handleMassiveEdit = () => {
    const { timeType } = parsedRows[0];

    const excludeEditStatuses = ['disabled', 'approved', 'rejected', 'pending'];
    const hasRequiredEditStatuses = !parsedRows.some(item => excludeEditStatuses.includes(item.status));

    if (!hasRequiredEditStatuses) {
      dispatch(
        sendAlert({
          kind: 'error',
          message: 'No se pueden editar Registros Desactivados, Aprobados, Rechazados o Pendientes'
        })
      );
      return false;
    }

    const hasSameMarkingType = parsedRows.every(item => item.timeType === timeType);

    if (!hasSameMarkingType) {
      dispatch(sendAlert({ kind: 'error', message: 'Todas las asistencias deben ser del mismo tipo de marcación' }));
      return false;
    }

    const { attendanceDate } = parsedRows[0];

    const hasSameAttendanceDate = parsedRows.every(item => item.attendanceDate === attendanceDate);

    if (!hasSameAttendanceDate) {
      dispatch(sendAlert({ kind: 'error', message: 'Todas las asistencias deben ser del mismo dia' }));
      return false;
    }

    const handleMassiveUpdateRequest = (massiveForm, setSubmitting) => {
      const { shiftId, branchOfficeId } = massiveForm.massiveForm;

      const hasBranchOffice = parsedRows.every(item => item.branchOfficeIds.includes(branchOfficeId));

      const hasShift = parsedRows.every(item => item.shiftIds.includes(shiftId));

      if (!hasBranchOffice) {
        dispatch(sendAlert({ kind: 'error', message: 'Todos los trabajadores deben tener la Sucursal seleccionada' }));
        setSubmitting(false);
        return false;
      }

      if (!hasShift) {
        dispatch(sendAlert({ kind: 'error', message: 'Todos los trabajadores deben tener el Turno Seleccionado' }));
        setSubmitting(false);
        return false;
      }

      const massiveParams = {
        ...snakeCaseKeys(massiveForm),
        front_company_id: currentCompany?.id
      };

      massiveCreateAttendanceChangeRequest({
        dispatch,
        params: massiveParams,
        successCallback: () => handleSuccessRequest('massive-updated'),
        failureCallback: handleFailureRequest
      });

      return true;
    };

    setSimpleModalBody(
      <MassiveAttendanceFormReplace
        attendances={selectedRows}
        timeType={timeType}
        attendanceDate={attendanceDate}
        formRequest={(massiveForm, setSubmitting) => handleMassiveUpdateRequest(massiveForm, setSubmitting)}
        handleModalClose={handleModalClose}
        dispatch={dispatch}
        branchOffices={branchOffices}
        parsedRows={parsedRows}
      />
    );

    setSimpleModalTitle('Correción de Marcación');
    setSimpleModalShow(true);
    setSize('lg');

    return true;
  };

  const validateApprovedRejeted = () => {
    const pendingStatus = 'pending';
    const hasSamePendingStatus = parsedRows.every(item => item.status === pendingStatus);

    if (!hasSamePendingStatus) {
      dispatch(sendAlert({ kind: 'error', message: 'Todas las asistencias deben estar en estatus pendiente' }));
      return false;
    }
    return true;
  };

  const handleMassiveApproved = () => {
    const params = {
      approvedIds: parsedRows.map(item => item.lastReplace.id)
    };

    massiveApprovedAttendanceChangeRequest({
      dispatch,
      params: snakeCaseKeys(params),
      successCallback: () => handleSuccessRequest('massive-approved'),

      failureCallback: handleFailureRequest
    });
  };

  const handleMassiveRejected = data => {
    const params = {
      rejectedIds: parsedRows.map(item => item.lastReplace.id),
      reason: data.formRejected.reason
    };

    massivRejectedAttendanceChangeRequest({
      dispatch,
      params: snakeCaseKeys(params),
      successCallback: () => handleSuccessRequest('massive-rejected'),

      failureCallback: handleFailureRequest
    });
  };

  const handleDisabledMassiveAttendance = () => {
    const form = {
      attendances: parsedRows
    };

    massivDisabledAttendanceChangeRequest({
      dispatch,
      params: snakeCaseKeys(form),
      successCallback: () => handleSuccessRequest('disabled'),
      failureCallback: handleFailureRequest
    });
  };

  const handleFormmassiveRejected = () => {
    handleModalClose();
    setModalTitle('Rechazar Corrección de marcacion de asistencia');
    setModalBody(<MassiveAttendanceChangeRejectedForm formRequest={handleMassiveRejected} />);
    setCenterModalShow(true);
  };

  const handleActiveMassiveAttendance = () => {
    const form = {
      attendances: parsedRows
    };

    massiveActiveAttendanceChangeRequest({
      dispatch,
      params: snakeCaseKeys(form),
      successCallback: () => handleSuccessRequest('active'),
      failureCallback: handleFailureRequest
    });
  };

  const handleConfirmMassive = action => {
    const incompleteStatus = 'incomplete';
    const hasSameIncompleteStatus = parsedRows.every(item => item.status !== incompleteStatus);
    const disabledStatus = 'disabled';
    const hasSameDisabledStatus = parsedRows.every(item => item.status === disabledStatus);

    const requiredDisableStatuses = ['approved', 'correct', 'inconsistent'];
    const hasRequiredDisableStatuses = parsedRows.every(item => requiredDisableStatuses.includes(item.status));

    switch (action) {
      case 'approved':
        if (validateApprovedRejeted()) {
          setModalTitle('Aprobación Masiva');
          setModalBody('¿Confirma la aprobación de las correcciones seleccionadas?');
          setModalAction(() => handleMassiveApproved);
          setModalShow(true);
        }
        break;
      case 'rejected':
        if (validateApprovedRejeted()) {
          setModalTitle('Rechazo Masiva');
          setModalBody('¿Confirma el rechazo de las correcciones seleccionadas?');
          setModalAction(() => handleFormmassiveRejected);
          setModalShow(true);
        }
        break;
      case 'disabled':
        if (!hasSameIncompleteStatus) {
          dispatch(
            sendAlert({
              kind: 'error',
              message:
                'No se pueden desactivar registros en estado "Incompleto", ya que no hay marcacion que desactivar'
            })
          );
          return false;
        }

        if (!hasRequiredDisableStatuses) {
          dispatch(
            sendAlert({
              kind: 'error',
              message: 'Solo se pueden desactivar registros en estado correcto, aprobado o inconsistente'
            })
          );
          return false;
        }

        setModalTitle('Desactivación Masiva');
        setModalBody('¿Confirma la desactivación de las asistencias seleccionadas?');
        setModalAction(() => handleDisabledMassiveAttendance);
        setModalShow(true);
        break;
      case 'active':
        if (!hasSameDisabledStatus) {
          dispatch(sendAlert({ kind: 'error', message: 'Todos las marcaciones deben estar en estatus "Desactivado' }));
          return false;
        }
        setModalTitle('Activación Masiva');
        setModalBody('¿Confirma la Activación de las asistencias seleccionadas?');
        setModalAction(() => handleActiveMassiveAttendance);
        setModalShow(true);
        break;
      default:
        break;
    }

    return true;
  };

  return { handleMassiveEdit, handleConfirmMassive };
};

export default useMassiveActionHook;
