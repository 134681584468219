import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

const StatusTerminal = param => {

  let status
  switch (param) {
    case '1':
      status = <div className='text-success'>En linea</div>
      break;
    case '3':
      status = <div className='text-danger'>Fuera de linea</div>
      break;
    default:
      break;
  }
  return status;
}

const ControlClockShow = ({ data }) => {
  return (
    <>

      <Card>
        <Card.Body>
          <Row>
            <Col md={12}>
              <div className="info">
                <span className="type">Serial:</span>
                <span className="align-right" />
                <span className="answer"> {data.sn}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">Alias:</span>
                <span className="align-right" />
                <span className="answer"> {data.alias}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">Dirección IP:</span>
                <span className="align-right" />
                <span className="answer"> {data.ip_address}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">Empresa:</span>
                <span className="align-right" />
                <span className="answer"> {data.company.business_name}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">Estado del Registro:</span>
                <span className="align-right" />
                <span className={data.active ? 'answer text-success' : 'answer text-danger'}> {
                  data.active ? 'Activo' : 'Inactivo'}
                </span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">Estado del Terminal:</span>
                <span className="align-right" />
                <span className="answer"> {StatusTerminal(data.details.state)}</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="info">
                <span className="type">Ultima Actividad:</span>
                <span className="align-right" />
                <span className="answer"> {data.details.last_activity}</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>


    </>
  )
}

export default ControlClockShow