import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import LoanInstallmentsDataTable from '../Datatables/LoanInstallmentsDataTable';
import { DefaultModal } from '../../../../components';
import { createPayrollLoanRequest } from '../../../../requests/payrollLoanProcesses';
import { PayrollProcessContext } from '../../context/PayrollProcessProvider';

const SecondStep = ({ handleNextStep, handleBackStep, selectedRows }) => {
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { currentCompany } = useSelector(state => state.auth);
  const { payrollProcess, setPayrollProcess } = useContext(PayrollProcessContext);

  const handleCreatePayrollLoanProcess = () => {
    const loanProcessesIds = selectedRows.map(({ id }) => id);
    setLoading(true);
    createPayrollLoanRequest({
      dispatch,
      params: {
        payroll_process_loan: {
          payroll_process_id: payrollProcess.id,
          filter_company_id: currentCompany.id,
          filter_ids: loanProcessesIds
        }
      },
      successCallback: ({ data }) => {
        setPayrollProcess({
          ...payrollProcess,
          payroll_process_loan_id: data.id,
          selectedLoanProcesses: loanProcessesIds
        });
        handleNextStep(data.data);
      },
      callback: () => setLoading(false)
    });
  };

  const handleBeforeNextStep = () => {
    setModalShow(true);
  };

  return (
    <>
      <Row className="border-line mr-1 ml-1">
        <Col className="top-header-light-gray justify-content-start border-radius-5">
          <p className="mt-0 text-left">
            <b>Etapa 2 - Procesar</b>
          </p>
        </Col>
        <Col sm={12} className="mt-2">
          <Row>
            <Col sm={12} md={2} xl={2} className="top-header-light-gray border-radius-5 mr-2 mt-1 mb-1">
              <p className="mt-0">
                <b>Período Seleccionado: </b>
              </p>
            </Col>
            {selectedRows.length > 0 &&
              selectedRows.map(period => (
                <Col
                  key={period.id}
                  sm={12}
                  md={2}
                  xl={2}
                  className="top-header-light-blue border-radius-5 mr-2 mt-1 mb-1"
                >
                  <p className="mt-0">
                    <b>{period.parsed_month}</b>
                  </p>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
      <LoanInstallmentsDataTable onRequest={isLoading} selectedRows={selectedRows} />
      <Row className="mb-2 row">
        <Col />
        <Col md={2}>
          <Button variant="outline-info" disabled={isLoading} block onClick={() => handleBackStep()}>
            Cancelar
          </Button>
        </Col>
        <Col md={2}>
          <Button
            variant="primary"
            disabled={selectedRows.length === 0 || isLoading}
            block
            onClick={() => handleBeforeNextStep()}
          >
            Continuar
          </Button>
        </Col>
      </Row>

      <DefaultModal
        title="Confirma la selección de los siguientes períodos?"
        body={
          <Row style={{ marginRight: 'unset', marginLeft: 'unset' }}>
            {selectedRows.length > 0 &&
              selectedRows.map(period => (
                <Col key={period.id} sm={12} md={12} xl={12} className="border-radius-5">
                  <p className="mt-0">
                    <b>{period.parsed_month_year}</b>
                  </p>
                </Col>
              ))}
          </Row>
        }
        show={modalShow}
        disabled={isLoading}
        handleConfirm={() => handleCreatePayrollLoanProcess()}
        handleClose={() => setModalShow(false)}
        titleBtnClose="Cancelar"
      />
    </>
  );
};

export default SecondStep;
