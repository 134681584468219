const listedSettings = [
  {
    text: 'INCLUYE SALDO DE PRÉSTAMOS EMPRESA / CRÉDITOS EN FINIQUITO DE TRABAJO',
    name: 'includes_loan_balance',
    tooltipText: '',
    inputType: 'toggle'
  },
  {
    text: 'CARTA DE TERMINO DE CONTRATO ASOCIADA A FLUJO DE APROBACIÓN / FIRMA',
    name: 'contract_termination_letter',
    tooltipText: '',
    inputType: 'toggle'
  },
  {
    text: 'CONSIDERA EL TOPE DE 90 U.F. PARA LA BASE DE CÁLCULO',
    name: 'calculation_base_limit',
    tooltipText: '',
    inputType: 'toggle'
  },
  {
    text: 'FINIQUITO DE TRABAJO ASOCIADO A FLUJO DE APROBACION / FIRMA',
    name: 'job_settlement',
    tooltipText: '',
    inputType: 'toggle'
  },
  {
    text: 'CONSIDERA EL TOPE DE AÑOS PARA LA INDEMNIZACIÓN POR AÑOS DE SERVICIO',
    name: 'limit_year_for_compensation',
    tooltipText: '',
    inputType: 'toggle'
  },
  {
    text: 'CALCULO DE FINIQUITO ASOCIADO A FLUJO DE APROBACIÓN / FIRMA',
    name: 'settlement_calculation',
    tooltipText: '',
    inputType: 'toggle'
  },
  {
    text: 'MODIFICA DETALLE DE DÍAS DE VACACIONES DEL EMPLEADO',
    name: 'modify_detail_vacation_days',
    tooltipText: '',
    inputType: 'toggle'
  },
  {
    text: 'EMISIÓN DE COMPROBANTE CONTABLE DEL FINIQUITO DE TRABAJO',
    name: 'issue_accounting_voucher',
    tooltipText: '',
    inputType: 'toggle'
  },
  {
    text: 'MODIFICA CONCEPTOS CALCULADOS EN SIMULACIÓN',
    name: 'modifies_calculated_concepts',
    tooltipText: '',
    inputType: 'toggle'
  },
  {
    text: 'INCLUYE LA ÚLTIMA REMUNERACIÓN EN EL FINIQUITO DE TRABAJO',
    name: 'include_last_remuneration',
    tooltipText: '',
    inputType: 'toggle'
  }
];

const selectorOptions = [
  {
    label: 'Carta termino enviada a domicilio.',
    value: 'termination_letter_sent'
  },
  {
    label: 'Carta termino firmada por el trabajador.',
    value: 'termination_letter_signed_by_employee'
  },
  {
    label: 'Notificación carta de termino – DT.',
    value: 'termination_letter_notification_dt'
  },
  {
    label: 'Firma del Finiquito de Trabajo.',
    value: 'signing_of_the_employment_settlement'
  },
  {
    label: 'Pago de Finiquito de Trabajo.',
    value: 'payment_of_the_employment_settlement'
  },
  {
    label: 'Notificación Finiquito de Trabajo – DT.',
    value: 'termination_of_work_notification_dt'
  }
];

const stagesOptions = [
  {
    label: '1era Etapa',
    value: 'stage_1'
  },
  {
    label: '2da Etapa',
    value: 'stage_2'
  },
  {
    label: '3era Etapa',
    value: 'stage_3'
  },
  {
    label: '4ta Etapa',
    value: 'stage_4'
  },
  {
    label: '5ta Etapa',
    value: 'stage_5'
  },
  {
    label: '6ta Etapa',
    value: 'stage_6'
  }
];

export { listedSettings, selectorOptions, stagesOptions };
