import React from 'react';

const PrintableShiftReport = React.forwardRef(({ rows }, ref) => {
  const renderData = () => {
    return (
      <div
        className="table_to_print"
        ref={ref}
        style={{
          margin: '0 auto',
          maxWidth: '800px',
          padding: '20px'
        }}
      >
        <h2 className="mt-2">Reporte de modificación de turnos</h2>
        <table border="1">
          <thead>
            <tr>
              <td>Empresa: {rows[0]?.company_name}</td>
            </tr>
            <tr>
              <td>Rut: {rows[0]?.company_rut}</td>
            </tr>
            <tr>
              <td>
                Rango seleccionado de fechas: {rows[0]?.date_to} a {rows[0]?.date_from}
              </td>
            </tr>
          </thead>
        </table>
        <br />
        {rows &&
          rows.map(log => (
            <>
              <div style={{ marginBottom: '40px' }}>
                <table border="1">
                  <thead style={{ backgroundColor: '#EBEBEB' }}>
                    <tr>
                      <td colSpan="4">{log.employee_name}</td>
                    </tr>
                    <tr>
                      <td colSpan="4">Rut: {log.employee_rut}</td>
                    </tr>
                    <tr>
                      <th className="text-center align-middle">Fecha Asignación</th>
                      <th className="text-center align-middle">Turno Asignado</th>
                      <th className="text-center align-middle">Tipo de Turno</th>
                      <th className="text-center align-middle">Fecha de Asignación de Nuevo Turno</th>
                      <th className="text-center align-middle">Nuevo Turno Asignado</th>
                      <th className="text-center align-middle">Solicitante</th>
                    </tr>
                  </thead>
                  {log.shift_log.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan="6" style={{ textAlign: 'center' }}>
                          Sin cambios o modificaciones de turnos en el periodo consultado
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    log.shift_log.map(shiftLog => (
                      <tbody>
                        <tr>
                          <td className="text-center align-middle">{shiftLog.original_shift_date}</td>
                          <td className="text-left align-left">{shiftLog.original_turn_name}</td>
                          <td className="text-center align-middle">{shiftLog.shift_type}</td>
                          <td className="text-center align-middle">{shiftLog.new_shift_date}</td>
                          <td className="text-left align-left">{shiftLog.new_turn_name}</td>
                          <td className="text-center align-middle">{shiftLog.solicitor}</td>
                        </tr>
                      </tbody>
                    ))
                  )}
                </table>
              </div>
              <br />
            </>
          ))}
      </div>
    );
  };

  return <>{renderData()}</>;
});

export default PrintableShiftReport;
