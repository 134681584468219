import React from 'react';

const ModalWarningBody = ({ errorState, text }) => {
  return (
    <>
      {errorState.CANCEL_ERROR_PROCESS && (
        <>
          Has seleccionado procesos de anticipos que no tienen un proceso de nómina asociado o no se pueden anular en
          este mes:
          <ul>
            {errorState.PROCESSES.map(row => (
              <li key={row.id}>{row.name}</li>
            ))}
          </ul>
        </>
      )}
      {errorState.CONTINUE_ERROR && (
        <>
          Has seleccionado procesos de anticipos no válidos para continuar con el proceso de nómina
          <ul>
            {errorState.PROCESSES.map(row => (
              <li key={row.id}>{row.name}</li>
            ))}
          </ul>
        </>
      )}
      {text}
    </>
  );
};

export default ModalWarningBody;
