import ApiService from '../services/apiService';

export const exportBankTransferRequest = ({
  dispatch,
  format = '.xlsx',
  responseType = 'text',
  params = {},
  callback,
  successCallback
}) =>
  ApiService.request('get', `/bank_transfers/export${format}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });

export const transferenceSummaryRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/bank_transfers/transference_summary.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const paymentTypeSummaryRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/bank_transfers/payment_type_summary.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    callback
  });

export const validateExistingBankRequest = ({ dispatch, params, callback, successCallback }) =>
  ApiService.request('get', '/bank_transfers/validate_existing_bank', {
    dispatch,
    params,
    callback,
    successCallback
  });
