import React from 'react';
import { Table } from 'react-bootstrap';

const ArrearsReportFilterDatTable = ({ attendancesTotal }) => {
  const renderData = () => {
    return (
      <>
        <Table className="custom-data-table" bordered size="sm" style={{ fontWeight: 400 }}>
          <thead className="rdt_TableHeadRow" style={{ backgroundColor: '#EBEBEB', textTransform: 'uppercase' }}>
            <tr className="rdt_TableCol">
              <th className="text-center align-middle">Total Días Consultados</th>
              <th className="text-center align-middle">Total Días de Atraso</th>
              <th className="text-center align-middle">Total Atrasos Jornada Pactada</th>
              <th className="text-center align-middle">Total Días Salida Anticipada</th>
              <th className="text-center align-middle">Salida Anticipada Jornada Pactadas</th>
            </tr>
          </thead>
          <tbody>
            {attendancesTotal && (
              <tr>
                <td className="text-center align-middle">{attendancesTotal.total_days_consulted}</td>
                <td className="text-center align-middle">{attendancesTotal.count_late_arrival}</td>
                <td className="text-center align-middle">{attendancesTotal.p_late_arrival}</td>
                <td className="text-center align-middle">{attendancesTotal.count_early_departure}</td>
                <td className="text-center align-middle">{attendancesTotal.p_early_departure}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    );
  };

  return <>{renderData()}</>;
};

export default ArrearsReportFilterDatTable;
