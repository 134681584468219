import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon } from '../../../../components';

export default memoize((handleExport, status, onlyShow) => [
  {
    name: 'MES DE PROCESO',
    selector: 'parsed_date',
    grow: '1'
  },
  {
    name: 'CUOTAS DEL MES',
    selector: 'parsed_discount_total',
    grow: '1'
  },
  {
    name: 'TRABAJADORES',
    selector: 'employees_count',
    grow: '1'
  },
  {
    name: 'PRÉSTAMO / CRÉDITOS',
    selector: 'status',
    grow: '1',
    cell: item => {
      switch (item.status) {
        case 'closed':
          return (
            <span className="text-black" data-tag="allowRowEvents">
              Cerrado
            </span>
          );
        case 'open':
          return <span className="text-warning">Pendiente</span>;

        default:
          return <span className="text-muted">{item.process_status}</span>;
      }
    }
  },
  {
    name: 'ESTADO',
    selector: 'payroll_status',
    cell: item => {
      switch (item.payroll_status) {
        case 'associated':
          return (
            <span className="text-success" data-tag="allowRowEvents">
              Procesado
            </span>
          );
        case 'not_associated':
          return (
            <span className="text-danger" data-tag="allowRowEvents">
              Pendiente
            </span>
          );
        default:
          return (
            <span className="text-muted" data-tag="allowRowEvents">
              {item.payroll_status}
            </span>
          );
      }
    },
    grow: '1'
  },
  {
    name: 'MES DE REMUNERACIÓN',
    selector: 'payroll_process_name'
  },
  {
    name: 'DESCARGAR',
    cell: item => {
      switch (item.payroll_status) {
        case 'associated':
          return (
            !onlyShow && (
              <ButtonTooltip
                onClick={() => handleExport(item.payroll_process_id, item.id)}
                variant="circle-primary"
                toolbarVariant="mr-auto"
                className="btn-circle"
                size="sm"
                text=""
              >
                <Icon icon="download-2" />
              </ButtonTooltip>
            )
          );
        default:
          return <></>;
      }
    }
  }
]);
