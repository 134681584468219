import React from 'react';
import Icon from '../Icons';
import { BasicDropdown } from '..';

const OvertimeMovementsMassiveActions = ({ handleClick, disabled }) => {
  const actions = () => {
    const authorizedActions = [];
    authorizedActions.push({
      icon: <Icon icon="checkmark-circle" color="green" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'approved',
      props: {
        onClick: () => handleClick('approved'),
        disabled
      },
      title: 'Aprobar'
    });

    authorizedActions.push({
      key: 'undo',
      title: `Deshacer`,
      icon: <Icon icon="close-circle" height="16px" color="red" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => handleClick('undo'),
        disabled
      }
    });

    authorizedActions.push({
      key: 'destroy',
      title: `Eliminar`,
      icon: <Icon icon="trash" height="16px" color="red" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => handleClick('destroy'),
        disabled
      }
    });

    return authorizedActions;
  };

  return (
    <div className="d-flex mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
};

export default OvertimeMovementsMassiveActions;
