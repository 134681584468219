import React from 'react';
import memoize from 'memoize-one';

const columns = memoize(() => [
  {
    name: 'Nombre',
    selector: 'name',
    cell: item => <>{item.full_name}</>,
    sortable: false,
    grow: '2'
  },
  {
    name: 'Cédula de identidad (RUT)',
    selector: 'national_identification',
    sortable: false,
    grow: '1'
  },
  {
    name: 'Área',
    selector: 'job_management',
    sortable: false,
    grow: '1'
  },
  {
    name: 'Lugar de Prestación de Servicio',
    selector: 'branch_office',
    sortable: false,
    grow: '1'
  }
]);

export default columns;
