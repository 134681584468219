import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { Card, Col, Row } from 'react-bootstrap';
import { DefaultModal, ComponentDataTable, ButtonTooltip, Icon, ApprovementStatus } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import columns from './Columns';
import { useAuthorization } from '../../services/hooks';
import massActions from './massActions';
import { indexLoanProcessesRequest } from '../../requests/loanProcesses';
import useLoanProcessHook from './useLoanProcessHooks';
import ModalWarning from './ModalWarning';

const ExpandedComponent = ({ data, handleActions, permissions }) => {
  return (
    <Card>
      <Card.Body className="div-content-expander">
        <Row>
          <Col>
            <div className="info">
              <span className="type">TRABAJADORES:</span>
              <span className="line" />
              <span className="answer"> {data.employees_count}</span>
            </div>
            <div className="info">
              <span className="type">MONTO POSTERGADO:</span>
              <span className="line" />
              <span className="answer"> {data.parsed_deferred_total}</span>
            </div>
            <div className="info">
              <span className="type">ESTADO:</span>
              <span className="line" />
              <span className="answer">
                {' '}
                {<ApprovementStatus status={data.status} translatedStatus={data.parsed_status} margin="my-0" />}
              </span>
            </div>
            <div className="info">
              <span className="type">ACCIONES:</span>

              <span className="line" />
              <>
                <ButtonTooltip
                  onClick={() => handleActions(data, 'show')}
                  variant="circle-info"
                  className="btn-circle"
                  size="sm"
                  text="Ver"
                >
                  <Icon icon="eye" />
                </ButtonTooltip>
                <ButtonTooltip
                  onClick={() => handleActions(data, 'edit')}
                  variant="circle-warning"
                  className="btn-circle"
                  size="sm"
                  text="Ver"
                  disabled={data.status === 'closed' || !permissions.manage}
                >
                  <Icon icon="pencil" />
                </ButtonTooltip>
                <ButtonTooltip
                  onClick={() => handleActions(data, 'destroy')}
                  variant="circle-danger"
                  className="btn-circle"
                  size="sm"
                  text="Eliminar"
                  disabled={data.status === 'closed' || !permissions.manage}
                >
                  <Icon icon="trash" />
                </ButtonTooltip>
                <ButtonTooltip
                  onClick={() => handleActions(data, 'close')}
                  variant="circle-dark"
                  className="btn-circle"
                  size="sm"
                  text="Cerrar"
                  disabled={data.status === 'closed' || !permissions.manage}
                >
                  <Icon icon="lock" />
                </ButtonTooltip>
              </>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const LoanProcessDataTable = ({ moreData, setMoreData, customParams }) => {
  const ability = useAbility(AbilityContext);
  const [onRequest, setOnRequest] = useState(true);
  const [loanProcesses, setLoanProcesses] = useState([]);
  const [confirmModalState, setConfirmModalState] = useState({
    title: '',
    body: '',
    show: false
  });
  const [modalAction, setModalAction] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalParams, setModalParams] = useState(() => null);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loanProcessIds, setLoanProcessIds] = useState([]);
  const [sortParams, setSortParams] = useState({ sort_months: 'month', sort_direction: 'desc' });
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = window.innerWidth <= 767;

  const handleSelectedRows = item => {
    if (item) {
      const ids = item.selectedRows.map(row => row.id);
      setLoanProcessIds(ids);
      setSelectedRows(item.selectedRows);
    }
  };

  const permissions = {
    manage: useAuthorization('manage', 'LoanProcess.LoanInstallment')
  };

  const {
    handleCloseProcess,
    handleDeleteProcess,
    handleDownloadProcess,
    handleMassiveClose,
    handleMassiveDestroy
  } = useLoanProcessHook();

  const handleRequest = async params => {
    setOnRequest(true);
    setSortParams(params);
    indexLoanProcessesRequest({
      dispatch,
      params: {
        ...customParams,
        ...params
      },
      successCallback: response => {
        setLoanProcesses(response.data.data);
        setAmount(response?.data?.metadata?.amount || 0);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleConfirm = (action, item) => {
    switch (action) {
      case 'close':
        setModalParams([item.id, setMoreData, setClearSelectedRows, setOnRequest, setConfirmModalState]);
        setModalAction(() => handleCloseProcess);
        break;
      case 'destroy':
        setModalParams([item.id, setMoreData, setClearSelectedRows, setOnRequest, setConfirmModalState]);
        setModalAction(() => handleDeleteProcess);
        break;
      case 'massive_close':
        setModalParams([loanProcessIds, setMoreData, setClearSelectedRows, setOnRequest, setConfirmModalState]);
        setModalAction(() => handleMassiveClose);
        setClearSelectedRows(false);
        break;
      case 'download':
        handleDownloadProcess(
          customParams,
          loanProcessIds,
          sortParams,
          setMoreData,
          setClearSelectedRows,
          setOnRequest
        );
        setClearSelectedRows(false);
        break;
      case 'massive_destroy':
        setModalParams([loanProcessIds, setMoreData, setClearSelectedRows, setOnRequest, setConfirmModalState]);
        setModalAction(() => handleMassiveDestroy);
        setClearSelectedRows(false);
        break;
      default:
        window.location.reload();
    }
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/loan_processes/${item.id}/summary`);
        break;
      case 'edit':
        history.push(`/loan_processes/${item.id}`);
        break;
      case 'close':
        setConfirmModalState({
          title: 'Cerrar Proceso',
          body: '¿Estás seguro de que deseas cerrar el proceso?',
          show: true
        });
        setModalAction(() => handleConfirm('close', item));
        break;
      case 'destroy':
        setConfirmModalState({
          title: 'Eliminar Proceso',
          body: '¿Estás seguro de que deseas eliminar el proceso?',
          show: true
        });
        setModalAction(() => handleConfirm('destroy', item));
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'massive_close':
        setConfirmModalState({
          title: 'Cerrar Procesos',
          body: (
            <>
              <ModalWarning selectedRows={selectedRows} type="close" />
              ¿Estás seguro de que deseas cerrar los procesos seleccionados?
            </>
          ),
          show: true
        });
        setModalAction(() => handleConfirm('massive_close'));
        break;
      case 'export':
        handleConfirm('download');
        break;
      case 'massive_destroy':
        setConfirmModalState({
          title: 'Eliminar Procesos',
          body: (
            <>
              <ModalWarning selectedRows={selectedRows} type="delete" />
              ¿Estás seguro de que deseas eliminar los procesos seleccionados?
            </>
          ),
          show: true
        });
        setModalAction(() => handleConfirm('massive_destroy'));
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'month':
        return { sort_months: name };
      case 'status':
        return { sort_status: name };
      case 'sort_employees_count':
        return { sort_employees_count: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, permissions, isMobile)}
        data={loanProcesses}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        selectableRows={ability.can('manage', 'LoanProcess.LoanInstallment')}
        clearSelectedRows={clearSelectedRows}
        resourceRequest={handleRequest}
        pointerOnHover
        massActions={massActions(handleMassAction, permissions, selectedRows.length === 0, amount)}
        withMassActions={ability.can('manage', 'LoanProcess.LoanInstallment')}
        onSelectedRowsChange={handleSelectedRows}
        expandableRows={isMobile}
        expandableRowExpanded={() => isMobile}
        expandableRowsComponent={
          isMobile ? (
            <ExpandedComponent handleActions={handleButtonClick} isMobile={isMobile} permissions={permissions} />
          ) : (
            undefined
          )
        }
        expandableRowsHideExpander
        withSearch={false}
      />
      <DefaultModal
        title={confirmModalState.title}
        body={confirmModalState.body}
        show={confirmModalState.show}
        handleClose={() => setConfirmModalState(olDstate => ({ ...olDstate, show: false }))}
        handleConfirm={() => modalAction(...modalParams)}
        disabled={onRequest}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default LoanProcessDataTable;
