import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import FormikCheckBox from '../../components/Utils/Input/FormikCheckBox';
import { updateAdvanceSettingAdvancePaymentsRequest } from '../../requests/advanceSettings';
import { FormikNumber } from '../../components';

const LoanFormSettings = ({ errors, touched, setFieldValue, values, isSubmitting }) => {
  const [paymentAmount, setPaymentAmount] = useState(
    values.amountData.text_value === 'false' || values.amountData.text_value === 't'
  );
  const [paymentPercentage, setPaymentPercentage] = useState(
    values.percentageData.text_value === 'false' || values.percentageData.text_value === 't'
  );

  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col md={11}>
        <Form className="d-flex flex-column justify-content-end mt-2">
          <div>
            <p>Seleccione si el Préstamo será pagado por un monto fijo o sobre un porcentaje del sueldo base.</p>
            <Row>
              <Col md={6}>
                <Field name="amountData[text_value]">
                  {({ field }) => (
                    <>
                      <FormikCheckBox
                        {...field}
                        value={field.value === 'true' || field.value === true}
                        checked={field.value === 'true' || field.value === true}
                        field={field}
                        label="Monto"
                        custom
                        onClick={() => {
                          setFieldValue(field.name, field.value === 'true' || field.value === true);
                          setPaymentAmount(field.value === 'true' || field.value === true);
                          setPaymentPercentage(!(field.value === 'true' || field.value === true));
                          setFieldValue('percentageData[text_value]', field.value === 'true' || field.value === true);
                          setFieldValue('percentageData[integer_value]', 0);
                        }}
                      />
                    </>
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="percentageData[text_value]">
                  {({ field }) => (
                    <FormikCheckBox
                      {...field}
                      value={field.value === 'true' || field.value === true}
                      checked={field.value === 'true' || field.value === true}
                      field={field}
                      label="Porcentaje"
                      custom
                      onClick={() => {
                        setFieldValue(field.name, field.value === 'true' || field.value === true);
                        setPaymentAmount(!(field.value === 'true' || field.value === true));
                        setPaymentPercentage(field.value === 'true' || field.value === true);
                        setFieldValue('amountData[text_value]', field.value === 'true' || field.value === true);
                        setFieldValue('amountData[integer_value]', 0);
                      }}
                    />
                  )}
                </Field>
              </Col>

              <Col md={6}>
                <Field name="amountData[integer_value]">
                  {({ field }) => (
                    <FormikNumber
                      {...field}
                      disabled={paymentAmount}
                      label="Monto máximo a solicitar $"
                      thousandSeparator=""
                      fieldName={field.name}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6} className="px-2">
                <Field name="percentageData[integer_value]">
                  {({ field }) => (
                    <FormikNumber
                      {...field}
                      label="Monto máximo a solicitar %"
                      tooltipText="Corresponde al sueldo base del empleado"
                      disabled={paymentPercentage}
                      thousandSeparator=""
                      fieldName={field.name}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </div>
          <Row className="d-flex justify-content-end mt-3 mb-1">
            <Col md={6}>
              <Button type="submit" block variant="primary" disabled={isSubmitting}>
                GUARDAR
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { user, currentEmployee } = state.auth;
  return { user, currentEmployee };
};

const initialValues = ({ amountData, percentageData }) => {
  return { amountData, percentageData };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { dispatch, extraAction } = props;

  const transformValues = [{ ...values.amountData }, { ...values.percentageData }];

  setSubmitting(true);
  updateAdvanceSettingAdvancePaymentsRequest({
    dispatch,
    params: snakeCaseKeys({ advance_setting: transformValues }),
    successCallback: () => {
      setSubmitting(false);
    }
  });
  extraAction();
};

const validationSchema = () => {
  const schemaValidate = Yup.object().shape({
    amountData: Yup.object().shape({
      integer_value: Yup.number().when('text_value', {
        is: val => val === 'true' || val === true,
        then: Yup.number()
          .required('El monto es requerido')
          .min(1, 'Debe ingresar un monto mayor a 0')
          .test('containsDot', 'No se permite punto en este campo', value => !/\./.test(value)),
        otherwise: Yup.number().nullable()
      })
    }),
    percentageData: Yup.object().shape({
      integer_value: Yup.number().when('text_value', {
        is: val => val === 'true' || val === true,
        then: Yup.number()
          .required('El monto es requerido')
          .min(1, 'Debe ingresar un monto mayor a 0')
          .max(100, 'Porcentaje debe ser menor a 100')
          .test('containsDot', 'No se permite punto en este campo', value => !/\./.test(value)),
        otherwise: Yup.number().nullable()
      })
    })
  });
  return schemaValidate;
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: params => initialValues(params),
      validationSchema,
      handleSubmit
    })(LoanFormSettings)
  )
);
