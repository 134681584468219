import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormikMaterialUiTimePicker } from '../../components';
import { delayMethod } from '../../services/utils';

const DateForm = ({
  cancelRequest,
  confirmRequest,
  date,
  defAgreedSchedule,
  defEndTime,
  defStartTime,
  defStartLunch,
  defInitialTime,
  defEndLunch,
  defFlexibleShift,
  defWorkHours,
  defLunchTime,
  destroy,
  destroyRequest = null,
  id,
  oldTitle
}) => {
  const [startTime, setStartTime] = useState(defStartTime);
  const [endTime, setEndTime] = useState(defEndTime);
  const [startLunch, setStartLunch] = useState(defAgreedSchedule ? defStartLunch : '');
  const [endLunch, setEndLunch] = useState(defAgreedSchedule ? defEndLunch : '');
  const disabled = startTime === endTime;
  const params = {
    date,
    startTime,
    endTime,
    startLunch,
    endLunch,
    id,
    oldTitle,
    title: `${startTime} - ${endTime} ${defAgreedSchedule ? `/ ${startLunch} - ${endLunch} ` : ''}`
  };
  const [error, setError] = useState('');
  const [errorLunch, setErrorLunch] = useState('');

  const checkFlexibleShift = () => {
    if (defFlexibleShift) {
      const collationDuration = moment.duration(defLunchTime ?? '0', 'minutes');
      const totalWorkDuration = moment.duration(defWorkHours).add(collationDuration);
      const totalDuration = moment.duration(defInitialTime).add(totalWorkDuration);
      const endTimeWork = moment.utc(totalDuration.as('milliseconds')).format('HH:mm');
      setStartTime(defInitialTime);
      setEndTime(endTimeWork);
    }
  };

  const checkStartTime = currentTime => {
    if (currentTime === endTime) {
      setError('Hora de inicio no puede ser igual que la de termino');
      return setStartTime(currentTime);
    }
    setError('');
    return setStartTime(currentTime);
  };
  const checkEndtime = currentTime => {
    if (currentTime === startTime) {
      setError('Hora de termino no puede ser igual que la de inicio');
      return setEndTime(currentTime);
    }
    setError('');
    return setEndTime(currentTime);
  };

  const checkStartLunch = currentTime => {
    if (currentTime > endLunch) {
      setErrorLunch('Hora de inicio de colación no puede ser mayor que la de termino');
      return setStartLunch(currentTime);
    }
    setErrorLunch('');
    return setStartLunch(currentTime);
  };
  const checkEndLunch = currentTime => {
    if (currentTime < startLunch) {
      setErrorLunch('Hora de termino de colación no puede ser menor que la de inicio');
      return setEndLunch(currentTime);
    }
    setErrorLunch('');
    return setEndLunch(currentTime);
  };

  useEffect(checkFlexibleShift, []);

  return (
    <>
      <Modal.Body>
        <Row>
          <Col xs={12} md={6}>
            <FormikMaterialUiTimePicker
              abbr
              label="Desde"
              timeSelector
              defaultValue={startTime}
              onChange={checkStartTime}
              disabled={defFlexibleShift}
            />
            <p className="text-danger">{error}</p>
          </Col>
          <Col xs={12} md={6}>
            <FormikMaterialUiTimePicker
              abbr
              label="Hasta"
              timeSelector
              defaultValue={endTime}
              onChange={checkEndtime}
              disabled={defFlexibleShift}
            />
          </Col>
        </Row>
        {defAgreedSchedule && (
          <Row>
            <Col xs={12} md={6}>
              <FormikMaterialUiTimePicker
                abbr
                label="Desde"
                timeSelector
                defaultValue={startLunch}
                onChange={checkStartLunch}
              />
              <p className="text-danger">{errorLunch}</p>
            </Col>
            <Col xs={12} md={6}>
              <FormikMaterialUiTimePicker
                abbr
                label="Hasta"
                timeSelector
                defaultValue={endLunch}
                onChange={checkEndLunch}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center" variant="outline-info" onClick={() => delayMethod(cancelRequest)}>
          Cancelar
        </Button>
        {destroy && (
          <Button className="text-center" variant="danger" onClick={() => destroyRequest(params)}>
            Eliminar
          </Button>
        )}
        <Button type="submit" disabled={disabled} variant="primary" onClick={() => confirmRequest(params)}>
          Agregar
        </Button>
      </Modal.Footer>
    </>
  );
};

DateForm.propTypes = {
  confirmRequest: PropTypes.func.isRequired,
  cancelRequest: PropTypes.func.isRequired,
  defStartTime: PropTypes.string,
  defEndTime: PropTypes.string,
  defStartLunch: PropTypes.string,
  defEndLunch: PropTypes.string,
  destroy: PropTypes.bool,
  oldTitle: PropTypes.string,
  defAgreedSchedule: PropTypes.bool
};

DateForm.defaultProps = {
  defAgreedSchedule: false,
  defStartTime: '09:00',
  defEndTime: '18:00',
  defStartLunch: '14:00',
  defEndLunch: '15:00',
  destroy: false,
  oldTitle: ''
};

export default DateForm;
