import React from 'react';
import { Row, Col, Button, Container, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { FormikInput, MainLogo } from '../../components';
import { signUpRequest } from '../../actions/auth';

const RequestPassword = props => {
  const { onHide, submitVariant, errors, touched, setFieldValue, values } = props;
  const history = useHistory();
  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col xl={4}>
        <MainLogo />
        <Alert variant="danger">
          <h3 className="text-center">IMPORTANTE</h3>
          <p className="text-center">Esta clave es de uso exclusivo para la Dirección del Trabajo.</p>
        </Alert>
        <Form className="d-flex flex-column justify-content-end mt-5">
          <Row>
            <Col xl={6}>
              <Field name="name">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Nombre del Fiscalizador"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col xl={6}>
              <Field name="email">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Correo Fiscalizador"
                    addon="@dt.gob.cl"
                    error={getIn(errors, field.name)}
                    onBlur={() => setFieldValue(field.name, values.email ? values.email.trim() : '')}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Container className="d-flex">
            <Button variant="outline-info" size="lg" className="mt-4" onClick={() => history.push('/login')}>
              Volver
            </Button>
            <Button variant={submitVariant} size="lg" className="mt-4 ml-auto" type="submit" onClick={onHide}>
              Solicitar Clave
            </Button>
          </Container>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest } = state.auth;
  return {
    ongoingRequest
  };
};

const initialValues = {
  email: '',
  name: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Este campo es obligatorio')
    .min(4, 'Un mínimo de 4 caracteres es requerido')
    .emailDtValidation('Debe ingresar un correo electrónico válido'),

  name: Yup.string().required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  const email = `${values.email}@dt.gob.cl`;
  dispatch(
    signUpRequest({
      user: {
        name: values.name,
        email,
        role: 2
      }
    })
  );
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(RequestPassword)
  )
);
