import React, { useState } from 'react';
import { Row, Col, Tab, Nav, Button } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import AttendanceReportIndex from './AttendanceReportIndex';
import WorkdayReportIndex from './WorkdayReportIndex';
import OffdaysReportIndex from './OffdaysReportIndex';
import { Icon, SimpleCenteredModal } from '../../components';
import { useSetTab } from '../../services/hooks';
import { AbilityContext } from '../../config/abilityContext';
import ShiftReportIndex from './ShiftReportIndex';
import abreviations from '../../assets/images/abbreviations.png';
import './Style.scss';

const LegalReports = ({ location }) => {
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [key, setKey] = useSetTab('attendance-report', location);
  const ability = useAbility(AbilityContext);

  return (
    <div>
      <Row className="mt-2">
        <Col>
          <h2 className="mb-2 mt-2">Reportes Legales</h2>
        </Col>
        <Col md={6} className="mt-3 justify-content-end d-flex">
          <Button className="arial-dt" variant="warning" onClick={() => setModalShow(true)}>
            Glosario de Abreviaciones
          </Button>
        </Col>
      </Row>
      <Tab.Container id="company-settings-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          {ability.can('index', 'AttendanceReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1 arial-dt" eventKey="attendance-report">
                <Icon icon="location" />
                Asistencia
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('index', 'WorkdayReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1 arial-dt" eventKey="workday-report">
                <Icon icon="location" />
                Jornada Diaria
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('index', 'HolidayReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1 arial-dt" eventKey="offday-report">
                <Icon icon="location" />
                Días domingo y/o días festivos
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('index', 'ShiftReport') && (
            <Nav.Item>
              <Nav.Link className="mb-1 arial-dt" eventKey="shift-report">
                <Icon icon="location" />
                Modificaciones y/o alteraciones de turnos
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="attendance-report">
            <AttendanceReportIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="workday-report">
            <WorkdayReportIndex rrhh is moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="offday-report">
            <OffdaysReportIndex moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
          <Tab.Pane eventKey="shift-report">
            <ShiftReportIndex rrhh moreData={moreData} setMoreData={setMoreData} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <SimpleCenteredModal
        title="Glosario de Abreviaciones"
        titleClass="modal-title-dt"
        body={
          <Row className="ml-2">
            <img src={abreviations} alt="Abreviaciones" style={{ maxWidth: '97%', height: 'auto' }} />
          </Row>
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default LegalReports;
