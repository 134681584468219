import React, { useState } from 'react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { DatatableEmployeeName, DefaultModal, Reminder } from '../../../components';
import { approveVacationRequest, deleteVacationRequest, rejectVacationRequest } from '../../../requests/vacations';
import { sendAlert } from '../../../actions/utils';
import SignatureKeyForm from '../../DocumentPreview/SignatureKeyForm';
import { signWorkflowRequestRequest } from '../../../requests/workflowRequests';
import VacationRejectForm from '../../Shared/Vacation/VacationRejectForm';
import ApproveRejectStyledActions from '../../../components/DatatableActions/ApproveRejectStyledActions';
import ApprovementStatusStyled from '../../../components/DatatableColumns/ApprovementStatusStyled';

const VacationInfoTable = props => {
  const dispatch = useDispatch();
  const { item, moreData, setModalShow, setMoreData, showErrorMessage = false } = props;
  const [modalItem, setDefaultModalItem] = useState({});
  const [defaultModalTitle, setDefaultModalTitle] = useState('');
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [defaultModalButtons, setDefaultModalButtons] = useState(true);
  const [onRequest, setOnRequest] = useState(true);
  const [isDisabled, setIsDisable] = useState(false);
  const [defaultModalAction, setDefaultModalAction] = useState(() => null);
  const [reminderShow, setReminderShow] = useState(false);
  const [reminderItem, setReminderItem] = useState({});
  const {
    createdAt,
    days,
    employee,
    employeeTotalVacationByType,
    endDate,
    middayEnd,
    middayStart,
    observation,
    startDate,
    status,
    translatedVacationType
  } = camelCaseRecursive(item);

  const admVacation = translatedVacationType !== 'Administrativos';

  const handleConfirmModal = () => {
    setIsDisable(false);
    setOnRequest(false);
    setDefaultModalItem(item);
    setDefaultModalTitle('Eliminar Solicitud de Vacaciones');
    setDefaultModalBody('¿Estás seguro que deseas eliminar esta solicitud?');
    setDefaultModalButtons(true);
    setDefaultModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setDefaultModalShow(false);
    setDefaultModalTitle('');
    setDefaultModalBody('');
    setOnRequest(false);
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud eliminada con éxito' }));
    setMoreData(!moreData);
  };

  const handleSuccessRequest = action => {
    let message = '';
    switch (action) {
      case 'approved':
        message = 'Solicitud aprobada con éxito';
        break;
      case 'rejected':
        message = 'Solicitud rechazada con éxito';
        break;
      default:
        message = 'No action';
        break;
    }

    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleDeleteRequest = () => {
    setIsDisable(false);
    setOnRequest(true);
    deleteVacationRequest(item.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => handleModalClose()
    });
  };

  const handleApproveRequest = () => {
    const vacationId = item.id;
    const sendParams = snakeCaseKeys({ vacation: item });
    setIsDisable(false);
    setOnRequest(true);

    approveVacationRequest(vacationId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => handleSuccessRequest('approved'),
      callback: () => setOnRequest(false)
    });
  };

  const signDocument = (formValues, { workflowRequestId }, setSubmitting = () => null) => {
    signWorkflowRequestRequest(workflowRequestId, {
      dispatch,
      params: snakeCaseKeys(formValues),
      successCallback: () => handleSuccessRequest('approved'),
      callback: () => setSubmitting(false)
    });
  };

  const handleApproveModal = () => {
    setIsDisable(false);
    setOnRequest(false);
    setDefaultModalTitle(`${item.requestReviewerSignature ? 'Firmar y' : ''} Aprobar Solicitud de Vacaciones`);
    if (item.requestReviewerSignature) {
      setDefaultModalBody(
        <SignatureKeyForm item={item} onHide={handleModalClose} formRequest={signDocument} signatureKey="" />
      );
      setDefaultModalButtons(false);
      setDefaultModalAction(() => null);
    } else {
      setDefaultModalItem(item);
      setDefaultModalBody(
        <>
          {item.translatedVacationType !== 'Administrativos' && item.validEmployedLessThanYear && (
            <p className="text-danger text-center h4">
              Este empleado tiene menos de un año en la empresa <br />
              Presione confirmar para continuar con el proceso.
            </p>
          )}
          {'¿Estás seguro que deseas aprobar esta solicitud?'}
        </>
      );
      setDefaultModalAction(() => handleApproveRequest);
      setDefaultModalButtons(true);
    }
    setDefaultModalShow(true);
  };

  const handleRejectRequest = (vacation, setSubmitting) => {
    const { id: vacationId } = vacation.vacation;
    const sendParams = snakeCaseKeys(vacation);

    setOnRequest(true);
    rejectVacationRequest(vacationId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => handleSuccessRequest('rejected'),
      callback: () => {
        setOnRequest(false);
        setSubmitting(false);
      }
    });
  };

  const handleOpenModal = (title, body) => {
    setDefaultModalTitle(title);
    setDefaultModalBody(body);
    setDefaultModalButtons(false);
    setDefaultModalAction(() => null);
    setDefaultModalShow(true);
  };

  const handleRejectModal = () => {
    const title = 'Rechazar solicitud';
    const body = (
      <VacationRejectForm vacation={item} formRequest={handleRejectRequest} handleModalClose={handleModalClose} />
    );
    handleOpenModal(title, body);
  };

  const handleActions = action => {
    switch (action) {
      case 'approved':
        handleApproveModal(camelCaseRecursive(item));
        break;
      case 'rejected':
        handleRejectModal(camelCaseRecursive(item));
        break;
      case 'resend':
        setReminderShow(true);
        setReminderItem(snakeCaseKeys(item));
        break;
      case 'destroy':
        handleConfirmModal(item);
        setDefaultModalAction(() => handleDeleteRequest);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  return (
    <>
      <Modal.Body>
        <div className="info-box">
          <Row>
            <Col md={2} lg={2}>
              <ApprovementStatusStyled status={status} margin="mb-1" />
            </Col>
            <Col md={8} lg={8} className="title-pw">
              <b>
                Gestionar Solicitud de {item.translatedVacationType === 'Administrativos' ? 'Permisos' : 'Vacaciones'}
              </b>
            </Col>
            <Col md={2} lg={2} className="title-pw" />
          </Row>
          <table className="simple-table">
            <tbody>
              <tr>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Trabajador</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Fecha de Solicitud</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>
                  Tipo de {admVacation ? 'Vacaciones' : 'Permiso'}
                </th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Fecha de Inicio</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Fecha de Término</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Total Días Solicitados</th>
                {observation && <th style={{ textAlign: 'center', paddingTop: '25px' }}>Motivo de Rechazo</th>}
              </tr>
              <tr>
                <td>
                  <div bis_skin_checked="1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div aria-hidden="true" bis_skin_checked="1" className="employee-info" />
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <DatatableEmployeeName item={employee} fileName="fileInfo" name="shortName" fileUrl="fileUrl" />
                    </span>
                  </div>
                </td>
                <td align="center">{createdAt}</td>
                <td align="center">{translatedVacationType}</td>
                <td align="center">
                  {startDate} {middayStart && '- Medio día'}
                </td>
                <td align="center">
                  {endDate} {middayEnd && '- Medio día'}
                </td>
                <td align="center">{days}</td>
                {observation && <td align="center">{observation}</td>}
              </tr>
            </tbody>
          </table>
          <br />
          {showErrorMessage && employeeTotalVacationByType < days && status === 'pending' && (
            <div className="text-danger mb-4">
              El colaborador {employee.fullName} está solicitando {days} días y solo tiene {employeeTotalVacationByType}{' '}
              días disponibles. ¿Está seguro que desea aprobarlas/firmarlas?
            </div>
          )}

          <Row>
            <Col className="text-center">
              <ApproveRejectStyledActions
                resource="Vacation"
                item={item}
                clickHandler={handleActions}
                approveText={item.requestReviewerSignature ? 'Firmar / Aprobar' : 'Aprobar'}
                withDestroy
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <DefaultModal
        body={defaultModalBody}
        disabled={onRequest || isDisabled}
        handleClose={handleModalClose}
        handleConfirm={() => defaultModalAction(modalItem)}
        show={defaultModalShow}
        title={defaultModalTitle}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose={defaultModalButtons}
        withConfirm={defaultModalButtons}
      />
      <Reminder
        item={reminderItem}
        show={reminderShow}
        modalShow={setReminderShow}
        moreData={moreData}
        setOnRequest={setOnRequest}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default VacationInfoTable;
