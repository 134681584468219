import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const generateArrearsReport = ({
  dispatch,
  params,
  format,
  responseType,
  failureCallback,
  successCallback,
  callback
}) => {
  ApiService.request('get', `/arrears_reports/arrears_totalized_reports/generate_report${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    failureCallback,
    callback
  });
};

export const debounceGenerateArrearsReport = AwesomeDebouncePromise(generateArrearsReport, 300);
