import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Spinner, Button, Modal } from 'react-bootstrap';
import { BasicTextArea } from '../../../components';
import '../../../services/yupCustomMethods';
import { delayMethod } from '../../../services/utils';

const ObservationForm = ({
  action,
  errors,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  touched,
  values,
  document,
  handleModalClose,
  documentId,
  employeeId,
  ...props
}) => {
  const { rrhh, onHide, submitVariant } = props;
  const { status } = document;
  const isResolved = status === 'approved' || status === 'rejected';

  return (
    <Form className="mx-3">
      <Modal.Body>
        {isSubmitting && <Spinner animation="border" variant="primary" className="spinner-modal" />}
        {
          <>
            <Row>
              {rrhh && (
                <Col md={12}>
                  <Field name="document[observation]">
                    {({ field }) => (
                      <BasicTextArea
                        {...field}
                        label="Observacion"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={isResolved}
                      />
                    )}
                  </Field>
                </Col>
              )}
            </Row>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Row className="d-flex justify-content-end mt-3 mb-2">
          <Col xs={6} sm={6}>
            <Button block variant="outline-info" onClick={() => delayMethod(handleModalClose)} disabled={isSubmitting}>
              Cancelar
            </Button>
          </Col>

          <Col xs={6} sm={6}>
            <Button type="submit" disabled={isSubmitting} variant={submitVariant} block onClick={onHide}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { document } = props;
  return {
    document: {
      ...document,
      observation: document.observation || ''
    }
  };
};

const validationSchema = Yup.object().shape({
  document: Yup.object().shape({
    observation: Yup.string().max(250, 'Las observaciones deben tener como máximo 250 caracteres')
  })
});

const handleSubmit = (document, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(document, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(ObservationForm);
