import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Card, ListGroup, Row, Col } from 'react-bootstrap';
import { BasicTooltip, DocumentEditor, FormikInput, FormikSelect } from '../../components';
import Icon from '../../components/Icons';
import { orientations, sizes, templateTypes } from './FormOptions';
import { debounceIndexTemplateKeysRequest } from '../../requests/templateKeys';
import DocumentPreview from './DocumentPreview';

const TemplateForm = props => {
  const dispatch = useDispatch();
  const [templateKeys, setTemplateKeys] = useState([]);
  const [templateKeysItems, setTemplateKeysItems] = useState([]);
  const [showPreview, setShowPreview] = useState('');
  const {
    action,
    documentTemplate,
    errors,
    onHide,
    submitVariant,
    touched,
    values,
    setFieldValue,
    setFieldTouched
  } = props;
  const { templateType, size, orientation } = documentTemplate;

  const searchTemplateKey = inputValue => {
    const params = {
      sort_column: 'code',
      paginate: false
    };
    if (inputValue) {
      params.query = inputValue;
    }
    debounceIndexTemplateKeysRequest({
      dispatch,
      params,
      successCallback: response => setTemplateKeys(response.data.data)
    });
  };

  const listTemplateKeyItems = () => {
    setTemplateKeysItems(
      templateKeys.map(templateKey => {
        return (
          <ListGroup.Item key={templateKey.code}>
            <p>{templateKey.code}</p>
            <p>{templateKey.description}</p>
          </ListGroup.Item>
        );
      })
    );
  };

  useEffect(searchTemplateKey, []);
  useEffect(listTemplateKeyItems, [templateKeys]);

  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';

  return (
    <Form>
      <Row>
        <Col md={6}>
          <Field name="documentTemplate[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="documentTemplate[templateType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Documento"
                placeholder="Seleccionar Tipo de Documento"
                defaultValue={templateType}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                options={templateTypes}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Row className="mt-3 pb-0 mb-3">
            <Col xs={12} md={{ offset: 10, span: 2 }}>
              {!showPreview ? (
                <Button onClick={() => setShowPreview(true)} variant="info" block text="Previsualizar">
                  Previsualizar
                </Button>
              ) : (
                <Button onClick={() => setShowPreview(false)} variant="warning" block text="Editar">
                  Editar
                </Button>
              )}
            </Col>
          </Row>
        </Col>
        {showPreview ? (
          <DocumentPreview body={values.documentTemplate.body} />
        ) : (
          <>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Header>
                    <div className="d-flex" style={{ marginBottom: '5px' }}>
                      Campos del Documento
                      <BasicTooltip text="Puedes copiar y pegar los campos disponibles para que los datos sean remplazados en tu documento">
                        <Icon icon="help-circle" width={20} />
                      </BasicTooltip>
                    </div>
                  </Card.Header>
                  <FormikInput placeholder="Buscar" onChange={e => searchTemplateKey(e.target.value)} />
                  <ListGroup
                    variant="flush"
                    style={{
                      maxHeight: '390px',
                      overflow: 'auto'
                    }}
                  >
                    {templateKeysItems}
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <Card.Body>
                  <Field name="documentTemplate[body]">
                    {({ field }) => (
                      <DocumentEditor
                        abbr
                        label="Documento"
                        data={values.documentTemplate.body}
                        onEditorChange={content => setFieldValue(field.name, content)}
                        onBlur={() => setFieldTouched(field.name, true)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="mt-3">
              <Field name="documentTemplate[size]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Tamaño"
                    placeholder="Seleccionar Tamaño"
                    defaultValue={size}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    options={sizes}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6} className="mt-3">
              <Field name="documentTemplate[orientation]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Orientación"
                    placeholder="Seleccionar Orientación"
                    defaultValue={orientation}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    options={orientations}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
      </Row>
      <Row className="d-flex justify-content-end m-t-10 m-b-30">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { documentTemplate } = props;

  return { documentTemplate };
};

const validationSchema = Yup.object().shape({
  documentTemplate: Yup.object().shape({
    body: Yup.string().required('Debes ingresar un texto'),
    description: Yup.string()
      .alphanumeric('Deben ser caracteres alfanuméricos')
      .nullable(),
    name: Yup.string()
      .required('Debes ingresar un nombre')
      .max(120, 'Deben ser menos que 120 caracteres')
      .alphanumeric('Deben ser caracteres alfanuméricos'),
    orientation: Yup.string().required('Debes seleccionar una orientación'),
    size: Yup.string().required('Debes seleccionar un tamaño'),
    templateType: Yup.string().required('Debes seleccionar un tipo de documento')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(TemplateForm);
