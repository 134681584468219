import ApiService from '../services/apiService';

export const createDropZoneFileRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  service
}) =>
  ApiService.request('post', '/public/drop_zone_files', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    service,
    callback
  });

export const deleteDropZoneFileRequest = (
  id,
  { dispatch, params, failureCallback, successCallback, callback, service }
) =>
  ApiService.request('delete', `/public/drop_zone_files/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service,
    callback
  });
