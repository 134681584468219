import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { useHistory } from 'react-router-dom';
import {
  createBalanceRequest,
  updateBalanceRequest,
  deleteBalanceRequest,
  massiveDestroyBalanceRequest,
  indexBalancesRequest,
  deactivateBalancesRequest,
  activeBalancesRequest,
  showBalanceRequest
} from '../../requests/balances';
import { sendAlert } from '../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../services/utils';

const useConceptHook = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccess = (msg, action, tab) => {
    dispatch(sendAlert({ kind: 'success', message: msg }));
    if (['new', 'edit'].includes(action)) {
      if (action === 'new') history.push(`/remunerations_settings`);
      if (tab && tab === 'preloaded-concepts-tab') {
        history.push(`/remunerations_settings?tab=preloaded-concepts-tab`);
      } else {
        history.push(`/remunerations_settings?tab=concepts-tab`);
      }
    }
  };

  const handleSuccessIndex = (response, setBalance, setAmount, setOnRequest) => {
    const { data, metadata } = response.data;
    setBalance(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleFailureRequest = (error, handleModalClose) => {
    const { response } = error;
    handleModalClose();
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleFailureMassiveRequest = (errors, handleModalClose, handleModalErrors) => {
    const { response } = errors;
    handleModalClose();
    if (response?.data?.message?.length > 0) {
      handleModalErrors(response.data.message);
      return;
    }
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  /** **INDEX OR LIST ACTION*** */
  const handleIndexConcept = (params, setBalance, setAmount, setOnRequest) => {
    indexBalancesRequest({
      dispatch,
      params,
      successCallback: response => handleSuccessIndex(response, setBalance, setAmount, setOnRequest)
    });
  };

  /** **CREATE ACTION*** */
  const handleCreateConcept = (params, setSubmitting) => {
    createBalanceRequest({
      dispatch,
      params: { balance: snakeCaseKeys(params) },
      formData: true,
      successCallback: () => handleSuccess('Concepto creado con éxito', 'new'),
      callback: () => setSubmitting(false)
    });
  };

  /** **UPDATE ACTION*** */
  const handleUpdateConcept = (balance, setSubmitting, tab) => {
    updateBalanceRequest(balance.balance.id, {
      dispatch,
      params: snakeCaseKeys(balance, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: () => handleSuccess('Concepto actualizado con éxito', 'edit', tab),
      callback: () => setSubmitting(false)
    });
  };

  /** **SHOW ACTION*** */
  const handleFetchConcept = (balanceId, setBalance) => {
    showBalanceRequest(balanceId, {
      dispatch,
      successCallback: response => setBalance(camelCaseEmptyStringRecursive(response.data))
    });
  };

  /** **DELETE ACTION*** */
  const handleRemoveConcept = (balanceId, handleModalClose, setMoreData) => {
    deleteBalanceRequest(balanceId, {
      dispatch,
      successCallback: () => {
        handleSuccess('Concepto eliminado de manera satisfactoria');
        handleModalClose();
        setMoreData(val => !val);
      },
      failureCallback: error => handleFailureRequest(error, handleModalClose)
    });
  };

  /** **MASSIVE DEACTIVATE ACTION*** */
  const handleMassiveDeactivateConcepts = (
    selectedRows,
    setOnRequest,
    handleModalClose,
    setMoreData,
    setClearSelectedRows
  ) => {
    const conceptsSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    deactivateBalancesRequest({
      params: { ids: conceptsSelected },
      dispatch,
      successCallback: () => {
        handleModalClose();
        setMoreData(val => !val);
        handleSuccess('Conceptos desactivados correctamente');
      },
      callback: () => {
        handleModalClose();
        setClearSelectedRows(val => !val);
      }
    });
  };

  /** **MASSIVE ACTIVATE ACTION*** */
  const handleMassiveActivateConcepts = (
    selectedRows,
    setOnRequest,
    handleModalClose,
    setMoreData,
    setClearSelectedRows
  ) => {
    const conceptsSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    activeBalancesRequest({
      params: { ids: conceptsSelected },
      dispatch,
      successCallback: () => {
        handleModalClose();
        setMoreData(val => !val);
        handleSuccess('Conceptos activados correctamente');
      },
      callback: () => {
        handleModalClose();
        setClearSelectedRows(val => !val);
      }
    });
  };

  /** **DELETE MASSIVE ACTION*** */
  const handleMassiveDeleteConcepts = (
    selectedRows,
    setOnRequest,
    handleModalClose,
    setMoreData,
    setClearSelectedRows,
    handleModalErrors
  ) => {
    const conceptsSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    massiveDestroyBalanceRequest({
      params: { ids: conceptsSelected },
      dispatch,
      successCallback: () => {
        handleSuccess('Conceptos eliminados de manera satisfactoria');
        handleModalClose();
        setOnRequest(false);
        setMoreData(val => !val);
        setClearSelectedRows(val => !val);
      },
      failureCallback: error => {
        handleFailureMassiveRequest(error, handleModalClose, handleModalErrors);
        setMoreData(val => !val);
      },
      callback: () => {
        setOnRequest(false);
        setClearSelectedRows(val => !val);
      }
    });
  };

  return {
    handleCreateConcept,
    handleUpdateConcept,
    handleRemoveConcept,
    handleIndexConcept,
    handleMassiveActivateConcepts,
    handleMassiveDeactivateConcepts,
    handleMassiveDeleteConcepts,
    handleFetchConcept
  };
};

export default useConceptHook;
