import React, { useContext, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';
import Filters from './Filters';
import { ComponentDataTable } from '../../../components';
import Columns from './ColumnsHistories';
import { PayrollProcessContext } from '../context/PayrollProcessProvider';
import massiveActions from './massiveActions';
import {
  deactivatePayrollBalanceEmployees,
  indexPayrollBalanceEmployees,
  massiveDeactivatePayrollBalanceEmployees,
  updatePayrollBalanceEmployees
} from '../../../requests/payrollProcessBalances';
import EditBalanceForm from './EditForm/EditBalanceForm';
import { sendAlert } from '../../../actions/utils';
import ActionsHistories from './ActionsHistories';

const BalancesHistoriesDatatable = ({
  moreData,
  setMoreData,
  setSimpleModalState,
  setModalState,
  setParams,
  params,
  setActiveComponent,
  onlyShow
}) => {
  const [payrollBalancesEmployees, setPayrollBalancesEmployees] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [amount, setAmount] = useState(0);
  const [filters, setFilters] = useState({});
  const [clearSelection, setClearSelection] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { payrollProcess } = useContext(PayrollProcessContext);
  const dispatch = useDispatch();

  const handleFilterAdvance = filterValues => {
    setFilters(filterValues);
    setParams(filterValues);
    setMoreData(!moreData);
  };

  const handlePayrollBalancesEmployees = _params => {
    setOnRequest(true);
    indexPayrollBalanceEmployees({
      dispatch,
      params: {
        ...filters,
        ..._params,
        sort_column: 'id',
        sort_direction: 'desc',
        filter_active: true,
        show_payroll: true,
        filter_payroll_process_ids: payrollProcess.id
      },
      successCallback: ({ data }) => {
        setPayrollBalancesEmployees(data.data);
        setAmount(data?.metadata?.amount);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleSelectedRows = rows => {
    const rowsObject = rows.selectedRows.map(row => row);
    setSelectedRows(rowsObject);
  };

  const handleBalanceUpdate = (id, values, setSubmitting) => {
    const data = snakecaseKeys(values);
    updatePayrollBalanceEmployees(id, {
      dispatch,
      params: data,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Concepto actualizado exitosamente!' }));
        setMoreData(!moreData);
        setSimpleModalState(oldState => ({ ...oldState, show: false }));
      },
      callback: () => setSubmitting(false)
    });
  };

  const handleBalanceDeactivate = id => {
    setOnRequest(true);
    setModalState(oldState => ({ ...oldState, disabled: true }));
    deactivatePayrollBalanceEmployees(id, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Concepto eliminado exitosamente!' }));
        setMoreData(oldValue => !oldValue);
        setModalState(oldState => ({ ...oldState, show: false }));
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'error', message: 'No se pudo eliminar el concepto' }));
        setModalState(oldState => ({ ...oldState, show: false }));
        setOnRequest(false);
      },
      callback: () => {
        setClearSelection(true);
      }
    });
  };

  const handleMassiveDeactivate = () => {
    setOnRequest(true);
    setClearSelection(false);
    setModalState(oldState => ({ ...oldState, disabled: true }));
    massiveDeactivatePayrollBalanceEmployees({
      dispatch,
      params: {
        ids: selectedRows.map(row => row.id)
      },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Conceptos eliminado exitosamente!' }));
        setModalState(oldState => ({ ...oldState, show: false }));
        setMoreData(oldValue => !oldValue);
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'error', message: 'No se pudieron eliminar los conceptos' }));
        setModalState(oldState => ({ ...oldState, show: false }));
        setOnRequest(false);
      },
      callback: () => {
        setClearSelection(true);
      }
    });
  };

  const handleClick = (balance, action) => {
    switch (action) {
      case 'edit':
        setSimpleModalState({
          title: `Editar Concepto`,
          body: (
            <EditBalanceForm
              payrollBalance={balance}
              payrollProcess={payrollProcess}
              formRequest={handleBalanceUpdate}
              handleModalClose={() => setSimpleModalState(modalState => ({ ...modalState, show: false }))}
            />
          ),
          show: true
        });
        break;
      case 'destroy':
        {
          const { id } = balance;
          setModalState({
            title: 'Eliminar Concepto',
            body: <p>¿Está seguro que desea eliminar el concepto?</p>,
            show: true,
            action: () => handleBalanceDeactivate(id)
          });
        }
        break;
      case 'massive_destroy':
        setModalState({
          title: 'Eliminar Conceptos',
          body: <p>¿Está seguro que desea eliminar los conceptos?</p>,
          show: true,
          action: () => handleMassiveDeactivate()
        });
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Row className="mt-3 mb-3 ml-0 mr-0" style={{ position: 'relative', zIndex: '10' }}>
        <ActionsHistories
          setModalState={setModalState}
          setMoreData={setMoreData}
          params={params}
          setParams={setParams}
          setOnRequest={setOnRequest}
          setActiveComponent={setActiveComponent}
          setSimpleModalState={setSimpleModalState}
        />
      </Row>
      <Col>
        <Accordion defaultActiveKey="1" className="ml-2 mt-2 mr-2">
          <Filters formRequest={handleFilterAdvance} />
        </Accordion>
      </Col>
      <Col>
        <ComponentDataTable
          onRequest={onRequest}
          columns={Columns(handleClick, onlyShow)}
          data={payrollBalancesEmployees}
          totalRows={amount}
          moreData={moreData}
          resourceRequest={handlePayrollBalancesEmployees}
          onSelectedRowsChange={handleSelectedRows}
          clearSelectedRows={clearSelection}
          pointerOnHover
          selectableRows={!onlyShow}
          withMassActions={!onlyShow}
          withSearch={false}
          massActions={massiveActions(handleClick, selectedRows.length === 0 || payrollProcess.status !== 'open')}
        />
      </Col>
    </>
  );
};

export default BalancesHistoriesDatatable;
