import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FocusError = ({ setEmployeeTab, setAccordion, errorKeys, employeeTab, accordion }) => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating && Object.entries(errors).length) {
      const errorsCollection = [...document.getElementsByClassName('is-invalid')];
      errorsCollection.forEach((_value, key) => {
        errorsCollection[key].tabIndex = `error-${key}`;
      });
      if (errorsCollection.length === 0) {
        return;
      }
      const errorKey = errors?.employee ? Object.keys(errors.employee)[0] : null;
      if (employeeTab) {
        const employeesTab =
          errorKey === 'contract' || errorKey === 'employeePrevisionAttributes' ? 'contract' : 'profile';
        setEmployeeTab(employeesTab);
      }

      if (accordion) {
        const personalData = [
          'name',
          'firstLastName',
          'identificationType',
          'nationalIdentification',
          'birthdate',
          'countryId',
          'genderId'
        ];
        let accordionKey = '';
        personalData.some(element => {
          if (element === errorKey) {
            accordionKey = 'profile';
          }
          return setAccordion(accordionKey);
        });

        const contactKeys = ['email', 'address', 'regionId', 'communeId'];
        if (errorKeys.some(key => contactKeys.includes(key))) {
          setAccordion('contact');
        }

        if (errorKeys.includes('familyGroupsAttributes')) {
          setAccordion('family');
        }

        if (errors.employee.contract !== undefined) {
          const contractErrors = Object.keys(errors.employee.contract);
          if (errorKeys.includes('contract')) {
            setAccordion('contract');
          }

          const contractKeys = [
            'jobTitleId',
            'contractType',
            'startDate',
            'endDate',
            'weeklyHours',
            'outSourceNationalIdentification',
            'outSourceBusinessName',
            'outSourceEstablishmentName',
            'outSourceAddress',
            'outSourceAddressNumber',
            'outSourceExceptionalSystemNumber',
            'branchOfficeIds'
          ];
          if (contractErrors.some(key => contractKeys.includes(key))) {
            setAccordion('contract');
          }

          const journeyKeys = [
            'workSchedule',
            'markingTypeIds',
            'disability',
            'disabilityNationalIdentification',
            'workStartTime',
            'workEndTime',
            'errorDays'
          ];
          if (contractErrors.some(key => journeyKeys.includes(key))) {
            setAccordion('journey');
          }

          const salaryKeys = [
            'parsedSalary',
            'paymentSchedule',
            'gratificationPeriod',
            'gratificationCondition',
            'gratificationType',
            'paymentType'
          ];
          if (contractErrors.some(key => salaryKeys.includes(key))) {
            setAccordion('salary');
          }
          const discountKeys = ['contractBalancesAttributes', 'administrativeStartDate', 'additionalHolidaysStartDate'];
          if (contractErrors.some(key => discountKeys.includes(key))) {
            setAccordion('discounts');
          }

          if (contractErrors.includes('employeePrevisionAttributes')) {
            setAccordion('prevision');
          }
        }
      }
      setTimeout(() => errorsCollection[0].focus(), 100);
    }
  }, [isSubmitting, isValidating, errors, accordion, employeeTab, errorKeys, setAccordion, setEmployeeTab]);

  return null;
};

export default FocusError;
