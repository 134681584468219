import React from 'react';
import { Col } from 'react-bootstrap';

function LogicEmployeeDistributionRow({ region = '', regionslength = 0, communes = null, number = 0, colormap = '' }) {
  return (
    <>
      <div className="adjust-margin">
        <p className="point" style={{ color: colormap }}>
          ●
        </p>

        <Col md={5} xs={12} className="CountryContentText IgnorePadding">
          <p>{region}</p>
        </Col>
        <Col md={5} xs={12} className="IgnorePadding">
          <div className="position-right">
            <div className="HorizontalbarWithoutColor" style={{ width: `${number}px`, backgroundColor: colormap }} />
            <div className="CountryContentText">
              <p className="CountryCountNumber">{number}</p>
            </div>
          </div>
        </Col>
      </div>

      {regionslength === 1 &&
        typeof communes === 'object' &&
        communes.map(commune => (
          <div key={commune.name} className="adjust-margin" style={{ paddingLeft: 20 }}>
            <p className="point" style={{ color: colormap }}>
              ●
            </p>

            <Col md={5} xs={12} className="CountryContentText IgnorePadding">
              <p>{commune.name}</p>
            </Col>
            <Col md={5} xs={12} className="IgnorePadding">
              <div className="position-right">
                <div
                  className="HorizontalbarWithoutColor"
                  style={{ width: commune.employeeCount, backgroundColor: colormap }}
                />
                <div className="CountryContentText">
                  <p className="CountryCountNumber">{commune.employeeCount}</p>
                </div>
              </div>
            </Col>
          </div>
        ))}
    </>
  );
}

export default LogicEmployeeDistributionRow;
