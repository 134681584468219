import React, { useState } from 'react';
import { DefaultModal, SimpleCenteredModal } from '../../../components';

const DEFAULT_MODAL = {
  title: '',
  body: <></>,
  show: false,
  action: function empty() {}
};

const BalancesTable = ({ setActiveComponent, onlyShow, BalancesComponent }) => {
  const [modalState, setModalState] = useState({ ...DEFAULT_MODAL, disabled: false });
  const [simpleModalState, setSimpleModalState] = useState(DEFAULT_MODAL);
  const [moreData, setMoreData] = useState(false);
  const [params, setParams] = useState({});

  return (
    <>
      <BalancesComponent
        moreData={moreData}
        setMoreData={setMoreData}
        setModalState={setModalState}
        setParams={setParams}
        params={params}
        setSimpleModalState={setSimpleModalState}
        setActiveComponent={setActiveComponent}
        onlyShow={onlyShow}
      />
      <DefaultModal
        title={modalState.title}
        body={modalState.body}
        show={modalState.show}
        disabled={modalState.disabled}
        handleClose={() => setModalState(state => ({ ...state, disabled: false, show: false }))}
        handleConfirm={() => modalState.action()}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        title={simpleModalState.title}
        body={simpleModalState.body}
        show={simpleModalState.show}
        onHide={() => setSimpleModalState(state => ({ ...state, show: false }))}
      />
    </>
  );
};

export default BalancesTable;
