/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

const ContractBalanceStatus = ({ margin, status, onClick }) => {
  const renderBody = () => {
    switch (status) {
      case 'open':
      case 'active':
        return (
          <p className={`text-primary ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            Activo
          </p>
        );
      case 'inactive':
        return (
          <p className={`text-danger ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            Inactivo
          </p>
        );
      case 'finished':
        return (
          <p className={`text-dark ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            Finiquitado
          </p>
        );
      default:
        return (
          <p className={`text-dark ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            -
          </p>
        );
    }
  };
  return renderBody();
};

ContractBalanceStatus.propTypes = {
  margin: PropTypes.string,
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

ContractBalanceStatus.defaultProps = {
  margin: '',
  onClick: () => null
};

export default ContractBalanceStatus;
