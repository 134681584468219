/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

const ApprovementStatusStyled = ({ margin, status, translatedStatus, onClick }) => {
  const renderBody = () => {
    switch (status) {
      case 'pending':
      case 'in_process':
        return (
          <p className={`btn btn-warning ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Pendiente'}
          </p>
        );
      case 'approved':
        return (
          <p className={`btn btn-success ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Aprobado'}
          </p>
        );
      case 'rejected':
        return (
          <p className={`btn btn-danger ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Rechazado'}
          </p>
        );
      case 'not_signed':
        return (
          <p className={`btn btn-info ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Emitido'}
          </p>
        );
      case 'sent':
        return (
          <p className={`btn btn-info ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Enviado'}
          </p>
        );
      case 'closed':
        return (
          <p className={`btn btn-primary ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Cerrado'}
          </p>
        );
      case 'prenomina':
        return (
          <p className={`btn btn-danger ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Incompleto'}
          </p>
        );
      case 'completed':
        return (
          <p className={`btn btn-primary ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Completo'}
          </p>
        );
      case 'draft':
      case 'not_started':
        return (
          <p className={`btn btn-dark ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'No Comenzado'}
          </p>
        );
      case 'active':
        return (
          <p className={`btn btn-primary ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Activo'}
          </p>
        );
      case 'disabled':
        return (
          <p className={`btn btn-danger ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Deshabilitado'}
          </p>
        );
      case 'replaced':
        return (
          <p className={`btn btn-warning ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            {translatedStatus || 'Reemplazado'}
          </p>
        );
      default:
        return (
          <p className={`btn btn-info ${margin}`} data-tag="allowRowEvents" onClick={onClick}>
            -
          </p>
        );
    }
  };
  return renderBody();
};

ApprovementStatusStyled.propTypes = {
  margin: PropTypes.string,
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

ApprovementStatusStyled.defaultProps = {
  margin: 'my-0',
  onClick: () => null
};

export default ApprovementStatusStyled;
