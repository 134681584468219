import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import moment from 'moment';
import { FormikDatePicker } from '../Utils/Input';
import { InputSelect } from '../Utils/Select';
import DocumentEditor from '../Utils/CKEditor';
import { indexDocumentTemplateRequest } from '../../requests/documentTemplates';
import { FormikSelect } from '..';

class AnnexContractForm extends Component {
  fetchDocumentTemplates = (inputValue, callback) => {
    const { dispatch } = this.props;
    indexDocumentTemplateRequest({
      dispatch,
      params: {
        query: inputValue,
        template_type: 'contract_annex',
        sort_column: 'name',
        display_length: 150
      },
      successCallback: response => callback(response.data.data)
    });
  };

  render() {
    const { submitVariant, errors, touched, setFieldValue, isSubmitting, setFieldTouched, values } = this.props;

    return (
      <>
        <Form>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Field name="templatePdf[templateId]">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      abbr
                      label="Plantilla"
                      placeholder="Seleccionar Plantilla"
                      value={undefined}
                      request={this.fetchDocumentTemplates}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="templatePdf[extraKeys][0][value]">
                  {({ field }) => (
                    <FormikDatePicker
                      {...field}
                      isOutsideRange={() => false}
                      filedName="templatePdf[startDate]"
                      label="Fecha Inicio"
                      placeholder="dd/mm/aaaa"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="templatePdf[extraKeys][2][value]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      label="Tipo de Contrato o Periodo"
                      placeholder="Seleccionar Tipo de Contrato"
                      options={[
                        { label: 'Plazo Fijo', value: 'fixed_term' },
                        { label: 'Indefinido', value: 'undefined_term' }
                      ]}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              {values.templatePdf.extraKeys[2].value === 'fixed_term' && (
                <Col md={6}>
                  <Field name="templatePdf[extraKeys][3][value]">
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        isOutsideRange={() => false}
                        label="Fecha finalización contrato"
                        filedName="templatePdf[endDate]"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              )}
              <Col md={12}>
                <Field name="templatePdf[extraKeys][1][value]">
                  {({ field }) => (
                    <DocumentEditor
                      label="Cláusulas Adicionales"
                      onEditorChange={content => setFieldValue(field.name, content)}
                      onBlur={() => setFieldTouched(field.name, true)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn" type="submit" variant={submitVariant} disabled={isSubmitting}>
              Crear
            </Button>
          </Modal.Footer>
        </Form>
      </>
    );
  }
}

const setInitialValues = props => {
  const { employeeIds } = props;
  return {
    templatePdf: {
      documentType: 'contract_annex',
      templateId: '',
      employeeIds,
      extraKeys: [
        { code: '[[fecha_inicio_clausula]]', value: '' },
        { code: '[[clausula_adicional]]', value: '' },
        { code: '[[tipo_de_contrato]]', value: '' },
        { code: '[[fecha_fin_anexo_contrato]]', value: '' }
      ]
    }
  };
};

const validationSchema = Yup.object().shape({
  templatePdf: Yup.object().shape({
    templateId: Yup.string().required('Debes seleccionar una plantilla'),
    extraKeys: Yup.array(),
    'extraKeys[0]': Yup.object().when(['extraKeys[0].value', 'extraKeys[3].value'], {
      is: (startDate, endDate) => {
        const startDateMoment = moment(startDate, 'DD/MM/YYYY', true);
        const endDateMoment = moment(endDate, 'DD/MM/YYYY', true);

        return startDateMoment.isValid() && endDateMoment.isValid() && startDateMoment.isSameOrAfter(endDateMoment);
      },
      then: Yup.object().shape({
        value: Yup.date().required('Debe ser menor a la fecha de término')
      }),
      otherwise: Yup.object()
    }),
    'extraKeys[3]': Yup.object()
      .when(['extraKeys[2].value', 'extraKeys[3].value'], {
        is: (contractType, endDate) => contractType === 'fixed_term' && endDate === undefined,
        then: Yup.object().shape({
          value: Yup.date().required('Debes seleccionar una fecha de fin')
        })
      })
      .when(['extraKeys[0].value', 'extraKeys[3].value'], {
        is: (startDate, endDate) => {
          const startDateMoment = moment(startDate, 'DD/MM/YYYY', true);
          const endDateMoment = moment(endDate, 'DD/MM/YYYY', true);

          return startDateMoment.isValid() && endDateMoment.isValid() && endDateMoment.isSameOrBefore(startDateMoment);
        },
        then: Yup.object().shape({
          value: Yup.date().required('Debe ser mayor a la fecha de inicio')
        })
      })
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(connect()(AnnexContractForm));
