import React, { Component } from 'react';
import { connect } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { createSyndicateRequest } from '../../requests/syndicates';
import { sendAlert } from '../../actions/utils';
import SyndicateForm from './SyndicateForm';

class SyndicateNew extends Component {
  state = {
    syndicate: {
      address: '',
      addressFloor: '',
      addressNumber: '',
      addressOffice: '',
      code: '',
      communeId: '',
      employeeSyndicatesAttributes: [],
      identificationNumber: '',
      name: '',
      phone: '',
      phoneCountryCode: '+56',
      regionId: ''
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleFailureRequest = error => {
    const { dispatch } = this.props;
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  handleCreateRequest = (syndicate, setSubmitting) => {
    const { dispatch } = this.props;

    createSyndicateRequest({
      dispatch,
      params: snakeCaseKeys(syndicate),
      successCallback: this.handleSuccessCreate,
      failureCallback: error => {
        this.handleFailureRequest(error);
        setSubmitting(false);
      }
    });
  };

  handleSuccessCreate = response => {
    const { history, dispatch } = this.props;
    const { data: syndicate } = response;
    dispatch(sendAlert({ kind: 'success', message: 'Sindicato creado con éxito' }));
    history.push(`/syndicates/${syndicate.id}`);
  };

  render() {
    const { syndicate } = this.state;
    return (
      <>
        <h2 className="text-uppercase mb-3 mt-5">Nuevo Sindicato</h2>
        <SyndicateForm
          syndicate={syndicate}
          action="new"
          submitVariant="success"
          formRequest={this.handleCreateRequest}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const { company } = state.auth;
  return { company };
};

export default connect(mapStateToProps)(SyndicateNew);
