const ColorGenderRandom = {};

function GenderColor(Gender) {
  if (Gender === 'Femenino') {
    return '#8054fc';
  }
  if (Gender === 'Masculino') {
    return '#f8c444';
  }
  if (Gender === 'No Especificado') {
    return '#636363';
  }
  if (Gender === 'No binario') {
    return '#73ff67';
  }
  if (Gender === 'Sin género') {
    return '#90ffed';
  }

  if (!Object.values(ColorGenderRandom).includes(Gender)) {
    ColorGenderRandom[Gender] = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
      Math.random() * 256
    )}, ${Math.floor(Math.random() * 256)})`;
  }

  return ColorGenderRandom[Gender];
}

export default GenderColor;
