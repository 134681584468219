import React, { useEffect } from 'react';
import AttendanceReportDataTable from './AttendanceReport';

const AttendanceReportIndex = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <AttendanceReportDataTable rrhh />
    </>
  );
};

export default AttendanceReportIndex;
