import React, { Component } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { FormikMaterialUiTimePicker, FormikCheckBox, FormikInput, FormikNumber } from '../../components/index';
import daysOfWeek from './FormOptions';
import ShiftContractsForm from './ShiftContractsForm';
import Icon from '../../components/Icons';
import './style.scss';

class ShiftForm extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
  }

  componentDidUpdate(prevProps) {
    const { setFieldValue, values } = this.props;
    const { shiftDaysAttributes, lunchTime, workHours, flexibleShift, initialTime } = values.shift;

    if (lunchTime !== prevProps?.values?.shift?.lunchTime && flexibleShift) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        shiftDaysAttributes.forEach((_item, index) => {
          const totalWorkDuration = moment.duration(workHours);
          const collationDuration = moment.duration(lunchTime ?? '0', 'minutes');
          const totalDurationWithLunch = moment.duration(totalWorkDuration).add(collationDuration);

          const startTime = moment.utc(initialTime, 'HH:mm');
          const endTime = moment
            .utc(startTime)
            .add(totalDurationWithLunch)
            .format('HH:mm');

          setFieldValue(`shift[shiftDaysAttributes][${index}][endTime]`, endTime);
        });
      }, 600);
    }
  }

  dayCheckBoxValue = (day, index, element) => {
    const { setFieldValue, values } = this.props;
    if (element._dispatchListeners[0] === undefined) {
      values.shift.shiftDaysAttributes[index].send = !values.shift.shiftDaysAttributes[index].send;
    }
    setFieldValue(`shift[shiftDaysAttributes][${index}][weekDay]`, day);
  };

  render() {
    const { values, errors, onHide, touched, setFieldValue, shift } = this.props;
    const {
      shiftDaysAttributes,
      collationTime,
      agreedSchedule,
      flexibleShift,
      withAttendances,
      workHours,
      initialTime,
      lunchTime
    } = values.shift;
    const disabled = !!withAttendances > 0;
    return (
      <Form>
        <Row>
          <Col md={6}>
            <Field name="shift[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  placeholder="Nombre de turno"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  disabled={disabled}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2}>
            <Field name="shift[parsedTolerance]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr={!flexibleShift}
                  label="Tolerancia"
                  rightAddon="min"
                  placeholder="0"
                  fieldName="shift[tolerance]"
                  value={values.shift.tolerance}
                  tooltipSize="21"
                  tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                  setFieldValue={setFieldValue}
                  disabled={flexibleShift || disabled}
                  errors={errors}
                  touched={touched}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2} className="mt-4 d-flex align-items-center">
            <Field name="shift[workHolidays]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="Trabaja festivos"
                  tooltipSize="23"
                  tooltipText="Turno con días festivos incluídos"
                  disabled={disabled}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={2} className="mt-4 d-flex align-items-center">
            <Field name="shift[flexibleShift]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="Turno flexible"
                  tooltipSize="23"
                  onClick={() => {
                    setFieldValue(field.name, field.value === 'true' || field.value === true);
                    setFieldValue('shift[tolerance]', 0);
                    setFieldValue('shift[parsedTolerance]', 0);
                    if (field.value !== 'true' || field.value !== true) {
                      setFieldValue('shift[initialTime]', '');
                      setFieldValue('shift[maximumStartTime]', '');
                      setFieldValue('shift[workHours]', '');
                      setFieldValue('shift[agreedSchedule]', false);
                    }
                  }}
                  disabled={disabled}
                />
              )}
            </Field>
          </Col>
          {flexibleShift && (
            <>
              <Col xs={4} md={2}>
                <Field name="shift[initialTime]">
                  {({ field }) => (
                    <FormikMaterialUiTimePicker
                      {...field}
                      abbr
                      timeSelector
                      label="Hora de Inicio"
                      onChange={time => {
                        setFieldValue(field.name, time);
                        if (flexibleShift) {
                          shiftDaysAttributes.map((_item, index) =>
                            setFieldValue(`shift[shiftDaysAttributes][${index}][startTime]`, time)
                          );
                          if (workHours) {
                            shiftDaysAttributes.forEach((_item, index) => {
                              const totalWorkDuration = moment.duration(workHours);
                              const collationDuration = moment.duration(lunchTime ?? '0', 'minutes');
                              const totalDurationWithLunch = moment.duration(totalWorkDuration).add(collationDuration);

                              const startTime = moment.utc(time, 'HH:mm');
                              const endTime = moment
                                .utc(startTime)
                                .add(totalDurationWithLunch)
                                .format('HH:mm');
                              setFieldValue(`shift[shiftDaysAttributes][${index}][endTime]`, endTime);
                            });
                          }
                        }
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled={disabled}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={4} md={2}>
                <Field name="shift[maximumStartTime]">
                  {({ field }) => (
                    <FormikMaterialUiTimePicker
                      {...field}
                      abbr
                      timeSelector
                      label="Tiempo Max. Inicio"
                      onChange={time => setFieldValue(field.name, time)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled={disabled}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={4} md={2}>
                <Field name="shift[workHours]">
                  {({ field }) => (
                    <FormikMaterialUiTimePicker
                      {...field}
                      abbr
                      timeSelector
                      label="Horas Trabajo"
                      onChange={time => {
                        setFieldValue(field.name, time);
                        shiftDaysAttributes.forEach((_item, index) => {
                          const totalWorkDuration = moment.duration(time);
                          const collationDuration = moment.duration(lunchTime ?? '0', 'minutes');
                          const totalDurationWithLunch = moment.duration(totalWorkDuration).add(collationDuration);
                          const startTime = moment.utc(initialTime, 'HH:mm');
                          const endTime = moment
                            .utc(startTime)
                            .add(totalDurationWithLunch)
                            .format('HH:mm');
                          setFieldValue(`shift[shiftDaysAttributes][${index}][endTime]`, endTime);
                        });
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled={disabled}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
        </Row>
        <Row className="mt-1">
          <Col xs={12} md={2} className="d-flex align-items-center">
            <Field name="shift[lunchCheck]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="Marcar colación"
                  tooltipSize="23"
                  tooltipText="Indica si deben marcar el ingreso y salida de colación"
                  disabled={disabled}
                />
              )}
            </Field>
          </Col>
          <Col xs={12} md={2} className="d-flex align-items-center">
            <Field name="shift[agreedSchedule]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="Horario pactado"
                  tooltipSize="23"
                  tooltipText="Indica que deben marcar el ingreso y salida de colación en cada uno de los días seleccionados"
                  disabled={collationTime || flexibleShift || disabled}
                  onClick={() => {
                    setFieldValue(field.name, field.value === 'true' || field.value === true);
                    setFieldValue('shift[lunchTime]', 0);
                  }}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2} className="d-flex align-items-center">
            <Field name="shift[collationTime]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="Tiempo de colación"
                  tooltipSize="23"
                  disabled={agreedSchedule || disabled}
                />
              )}
            </Field>
          </Col>
          {collationTime && (
            <>
              <Col xs={6} md={2} className="d-flex align-items-center">
                <Field name="shift[parsedLunchTime]">
                  {({ field }) => (
                    <FormikNumber
                      {...field}
                      abbr
                      rightAddon="min"
                      placeholder="0"
                      label="Colación"
                      fieldName="shift[lunchTime]"
                      value={values.shift.lunchTime}
                      tooltipSize="21"
                      tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      disabled={disabled}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
        </Row>
        {(errors?.shift?.agreedSchedule || errors?.shift?.collationTime) && (
          <p className="text-danger">Debes seleccionar una de las opciones de marcado</p>
        )}
        <hr />
        <p>Haz click en un día para activarlo o desactivarlo en este turno semanal.</p>
        {agreedSchedule ? (
          <Row className="justify-content-center">
            {daysOfWeek.map((item, index) => (
              <Col
                xs={5}
                sm={2}
                lg
                key={item.value}
                onClick={e => disabled || this.dayCheckBoxValue(item.value, index, e)}
                className={`shift-card-container${shiftDaysAttributes[index].send === true ? '' : '-disabled'}`}
              >
                <Row>
                  <Col md={12}>
                    {shiftDaysAttributes[index].send && (
                      <Icon
                        className="text-primary"
                        style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                        width={18}
                        icon="checkmark-circle"
                      />
                    )}
                    <p className="text-uppercase shift-card-title">{item.label}</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="align-items-center" md={12}>
                    <p className="text-uppercase shift-card-label">Desde</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][startTime]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          defaultValue="09:00"
                          onChange={time => setFieldValue(field.name, time)}
                          disabled={!shiftDaysAttributes[index].send || disabled}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col md={12}>
                    <p className="text-uppercase shift-card-label">Hasta</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][endTime]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          defaultValue="18:00"
                          onChange={time => setFieldValue(field.name, time)}
                          disabled={!shiftDaysAttributes[index].send || disabled}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="text-uppercase shift-card-title">Colación</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="align-items-center" md={12}>
                    <p className="text-uppercase shift-card-label">Desde</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][startLunch]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          defaultValue="14:00"
                          onChange={time => setFieldValue(field.name, time)}
                          disabled={!shiftDaysAttributes[index].send || disabled}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col md={12}>
                    <p className="text-uppercase shift-card-label">Hasta</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][endLunch]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          defaultValue="15:00"
                          onChange={time => setFieldValue(field.name, time)}
                          disabled={!shiftDaysAttributes[index].send || disabled}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="justify-content-center">
            {daysOfWeek.map((item, index) => (
              <Col
                xs={5}
                sm={2}
                lg
                key={item.value}
                onClick={e => disabled || this.dayCheckBoxValue(item.value, index, e)}
                className={`shift-card-container${shiftDaysAttributes[index].send === true ? '' : '-disabled'}`}
              >
                <Row>
                  <Col md={12}>
                    {shiftDaysAttributes[index].send && (
                      <Icon
                        className="text-primary"
                        style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                        width={18}
                        icon="checkmark-circle"
                      />
                    )}
                    <p className="text-uppercase shift-card-title">{item.label}</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3 align-items-center" md={12}>
                    <p className="text-uppercase shift-card-label">Desde</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][startTime]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          defaultValue="09:00"
                          onChange={time => setFieldValue(field.name, time)}
                          disabled={!shiftDaysAttributes[index].send || flexibleShift || disabled}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3" md={12}>
                    <p className="text-uppercase shift-card-label">Hasta</p>
                    <Field name={`shift[shiftDaysAttributes][${index}][endTime]`}>
                      {({ field }) => (
                        <FormikMaterialUiTimePicker
                          {...field}
                          abbr
                          defaultValue="18:00"
                          onChange={time => setFieldValue(field.name, time)}
                          disabled={!shiftDaysAttributes[index].send || flexibleShift || disabled}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        )}
        <ShiftContractsForm shift={shift} />
        <Row className="d-flex justify-content-end my-3">
          <Col md={3}>
            <Button type="submit" variant="primary" block onClick={onHide}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const setInitialValues = props => {
  const { action } = props;
  const { shiftDaysAttributes } = props.shift;
  let shiftDays = daysOfWeek;
  if (shiftDaysAttributes.length) {
    shiftDays = daysOfWeek.map(day => {
      const shiftDay = shiftDaysAttributes.filter(item => item.weekDay === day.value);
      if (shiftDay.length) {
        return { ...shiftDay[0], send: true };
      }
      return { ...day, send: false };
    });
  }
  const shiftEmpty = shiftDays.map(day => {
    return { ...day, send: false };
  });
  return {
    shift: {
      ...props.shift,
      shiftDaysAttributes: action === 'new' ? shiftEmpty : shiftDays,
      shiftType: 'weekly'
    }
  };
};

const validationSchema = Yup.object().shape({
  shift: Yup.object().shape(
    {
      workHolidays: Yup.boolean(),
      tolerance: Yup.number().when('flexibleShift', {
        is: val => val !== 'true',
        then: Yup.number()
          .required('Debes ingresar una tolerancia')
          .min(0, 'Debe ser mayor o igual a 0')
      }),
      lunchCheck: Yup.bool(),
      collationTime: Yup.bool().when('agreedSchedule', {
        is: aSchedule => aSchedule === false,
        then: Yup.bool().oneOf([true], 'Debes seleccionar una opción')
      }),
      agreedSchedule: Yup.bool().when('collationTime', {
        is: colTime => colTime === false,
        then: Yup.bool().oneOf([true], 'Debes seleccionar una opción')
      }),
      lunchTime: Yup.number().when('collationTime', {
        is: val => val,
        then: Yup.number()
          .required('Debes ingresar un número')
          .min(0, 'Debe ser mayor o igual a 0 '),
        otherwise: Yup.number().nullable()
      }),
      initialTime: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar un horario'),
        otherwise: Yup.string().nullable()
      }),
      maximumStartTime: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar tiempo máximo de inicio')
      }),
      workHours: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar cantidad de horas a trabajar')
      }),
      name: Yup.string().required('Debes ingresar un nombre'),
      shiftDaysAttributes: Yup.array()
        .when('agreedSchedule', {
          is: val => val,
          then: Yup.array().of(
            Yup.object({
              endLunch: Yup.string().when('send', {
                is: val => val,
                then: Yup.string()
                  .required('Debes ingresar hora de término')
                  .test('is-greater', 'La hora de término debe ser mayor o igual que la hora de inicio', function(
                    value
                  ) {
                    const { startLunch } = this.parent;
                    return startLunch && value >= startLunch;
                  }),
                otherwise: Yup.string().nullable()
              }),
              startLunch: Yup.string().when('send', {
                is: val => val,
                then: Yup.string().required('Debes ingresar hora de inicio'),
                otherwise: Yup.string().nullable()
              })
            })
          )
        })
        .of(
          Yup.object().shape({
            endTime: Yup.string().when('send', {
              is: val => val,
              then: Yup.string()
                .required('Debes ingresar hora de término')
                .notOneOf([Yup.ref('startTime'), null], 'Las horas de inicio y termino no pueden ser iguales'),
              otherwise: Yup.string().nullable()
            }),
            startTime: Yup.string().when('send', {
              is: val => val,
              then: Yup.string().required('Debes ingresar hora de inicio'),
              otherwise: Yup.string().nullable()
            }),
            send: Yup.boolean()
          })
        )
    },
    [['agreedSchedule', 'collationTime']]
  )
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ShiftForm);
