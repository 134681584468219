import ApiService from '../services/apiService';

const API_PAYROLL_SERVICE = 'payroll';

export const preImportHistoriesRequest = ({ params, dispatch, failureCallback, successCallback }) =>
  ApiService.request('get', '/payroll_histories/pre_import', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service: API_PAYROLL_SERVICE
  });

export const importHistoriesTemplateRequest = ({ params, dispatch, failureCallback, successCallback }) =>
  ApiService.request('get', '/payroll_histories/import_template', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service: API_PAYROLL_SERVICE
  });

export const importHistoriesRequest = ({ params, dispatch, failureCallback, successCallback }) =>
  ApiService.request('post', '/payroll_histories/import', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service: API_PAYROLL_SERVICE
  });

export const preImportBalancesHistoriesRequest = ({ params, dispatch, failureCallback, successCallback }) =>
  ApiService.request('get', '/payroll_histories/pre_import_balances', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service: API_PAYROLL_SERVICE
  });

export const importBalancesHistoriesTemplateRequest = ({ params, dispatch, failureCallback, successCallback }) =>
  ApiService.request('get', '/payroll_histories/import_template_balances', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service: API_PAYROLL_SERVICE
  });

export const importBalancesHistoriesRequest = ({ params, dispatch, failureCallback, successCallback }) =>
  ApiService.request('post', '/payroll_histories/import_balances', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service: API_PAYROLL_SERVICE
  });
