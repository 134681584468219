import React from 'react';
import Icon from '../../../components/Icons';
import { BasicDropdown } from '../../../components';

const RowActions = ({ handleClick, item, disabled = false }) => {
  const actions = () => {
    const authorizedActions = [];

    authorizedActions.push({
      icon: <Icon icon="eye" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'show',
      props: {
        onClick: () => handleClick(item, 'show'),
        disabled
      },
      title: 'Ver'
    });

    authorizedActions.push({
      icon: <Icon icon="paper-plane-outline" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'email',
      props: {
        onClick: () => handleClick(item, 'email'),
        disabled
      },
      title: 'Enviar al correo'
    });

    authorizedActions.push({
      icon: <Icon icon="paper-plane-outline" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'request-sign',
      props: {
        onClick: () => handleClick(item, 'request-sign'),
        disabled
      },
      title: 'Enviar al flujo'
    });

    return authorizedActions;
  };

  return (
    <>
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </>
  );
};

export default RowActions;
