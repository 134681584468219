import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, getIn, useFormikContext } from 'formik';
import { Col } from 'react-bootstrap';

import { sendAlert } from '../../actions/utils';
import { debounceIndexCommunesRequest, indexCommunesRequest } from '../../requests/communes';
import { debounceIndexCitiesRequest, indexCitiesRequest } from '../../requests/cities';
import { debounceIndexRegionsRequest } from '../../requests/regions';
import { InputSelect } from '../Utils/Select';

const RegionCityCommune = ({
  allDisabled,
  communeAbbr,
  modelKey,
  regionAbbr,
  cityAbbr,
  nameFieldCommune = 'communeId',
  nameFieldRegion = 'regionId',
  nameFieldCity = 'cityId'
}) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const [communeDisabled, setCommuneDisabled] = useState(true);
  const [cityDisabled, setCityDisabled] = useState(true);
  const [communes, setCommunes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const dispatch = useDispatch();

  const currentValues = getIn(values, modelKey);
  const regionId = currentValues[nameFieldRegion];
  const cityId = currentValues[nameFieldCity];
  const communeId = currentValues[nameFieldCommune];

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleRegionData = response => {
    const { data } = response.data;
    setRegions(data);
    return data;
  };

  const fetchRegions = (inputValue, callback) => {
    debounceIndexRegionsRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 20
      },
      successCallback: response => callback(handleRegionData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const handleCommuneData = response => {
    const { data } = response.data;
    setCommunes(data);
    return data;
  };

  const fetchCommunes = (inputValue, callback) => {
    debounceIndexCommunesRequest({
      dispatch,
      params: {
        name: inputValue,
        city: cityId,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 50
      },
      successCallback: response => callback(handleCommuneData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const fetchInitialCommunes = (params = {}) => {
    indexCommunesRequest({
      dispatch,
      params: {
        ...params,
        sort_column: 'name',
        sort_direction: 'asc',
        paginate: false
      },
      successCallback: response => handleCommuneData(response),
      failureCallback: handleFailureRequest
    });
    setCommuneDisabled(false);
  };

  const handleCitiesData = response => {
    const { data } = response.data;
    setCities(data)
    return data;
  }

  const fetchInitialCities = (params = {}) => {
    indexCitiesRequest({
      dispatch,
      params: {
        ...params,
        sort_column: 'name',
        sort_direction: 'asc',
        paginate: false
      },
      successCallback: response => handleCitiesData(response),
      failureCallback: handleFailureRequest
    });
    setCityDisabled(false);
  }

  const fetchCities = (inputValue, callback) => {
    debounceIndexCitiesRequest({
      dispatch,
      params: {
        name: inputValue,
        region: regionId,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 50
      },
      successCallback: response => callback(handleCommuneData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const handleRegionId = () => {
    if (regionId) fetchInitialCities({ region: regionId });
  };

  const handleCityId = () => {
    if (cityId) fetchInitialCommunes({ city: cityId })
  }

  useEffect(handleRegionId, [regionId]);

  useEffect(handleCityId, [cityId]);

  const initialDefaultValues = (array, attributeId) => {
    const data = array.find(obj => obj.value === attributeId);
    if (data === undefined) return false;
    return data;
  };

  const handleRegionChange = value => {
    setFieldValue(`${modelKey}[${nameFieldRegion}]`, value);
    setFieldValue(`${modelKey}[${nameFieldCity}]`, '');
    setFieldValue(`${modelKey}[${nameFieldCommune}]`, '');
    if (value !== '') {
      fetchInitialCities({ region: value });
      setCommunes([])
      return;
    }
    setCityDisabled(true);
  };

  return (
    <>
      <Col md={6}>
        <Field name={`${modelKey}[${nameFieldRegion}]`}>
          {({ field }) => (
            <InputSelect
              {...field}
              abbr={regionAbbr}
              isClearable={!regionAbbr}
              label="Región"
              placeholder="Seleccionar Región"
              disabled={allDisabled}
              value={initialDefaultValues(regions, regionId)}
              onChange={data => handleRegionChange(data ? data.value : '')}
              request={fetchRegions}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6}>
        <Field name={`${modelKey}[${nameFieldCity}]`}>
          {({ field }) => (
            <InputSelect
              {...field}
              abbr={cityAbbr}
              isClearable={!regionAbbr}
              label="Ciudad"
              placeholder="Seleccionar Ciudad"
              disabled={allDisabled || cityDisabled}
              value={initialDefaultValues(cities, cityId)}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              defaultOptions={cities}
              request={fetchCities}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6}>
        <Field name={`${modelKey}[${nameFieldCommune}]`}>
          {({ field }) => (
            <InputSelect
              {...field}
              abbr={communeAbbr}
              isClearable={!communeAbbr}
              label="Comuna"
              placeholder="Seleccionar Comuna"
              disabled={allDisabled || communeDisabled}
              value={initialDefaultValues(communes, communeId)}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              defaultOptions={communes}
              request={fetchCommunes}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </>
  );
};

export default RegionCityCommune;
