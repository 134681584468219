import React from 'react';
import memoize from 'memoize-one';
import { BasicDropdown, Icon } from '../../components';
import { useAuthorization } from '../../services/hooks';

const RemunerationsImporter = memoize(({ handleClick }) => {
  const permissions = {
    read: useAuthorization('read_concept', 'Balance'),
    update: useAuthorization('update_concept', 'Balance'),
    destroy: useAuthorization('destroy_concept', 'Balance')
  };

  const actions = () => {
    const authorizedActions = [];

    if (permissions.update) {
      authorizedActions.push({
        icon: <Icon icon="checkmark-circle" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'assets',
        props: {
          onClick: () => handleClick('assets')
        },
        title: 'Haberes'
      });

      authorizedActions.push({
        icon: <Icon icon="close-circle" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'discounts',
        props: {
          onClick: () => handleClick('discounts')
        },
        title: 'Descuentos'
      });
    }

    return authorizedActions;
  };

  return (
    <div className="mt-3">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Importar <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
});

export default RemunerationsImporter;
