import React from 'react';
import { useAbility } from '@casl/react';
import Icon from '../Icons';
import { AbilityContext } from '../../config/abilityContext';
import BasicDropdown from '../Utils/Dropdown/BasicDropdown';

const DocumentMassiveActions = ({ handleClick, disabled }) => {
  const ability = useAbility(AbilityContext);

  const actions = () => {
    const authorizedActions = [];
    if (ability.can('destroy_document', 'WorkflowRequest')) {
      authorizedActions.push({
        icon: <Icon icon="close-circle" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'remove-workflow-massive',
        props: {
          onClick: () => handleClick('remove-workflow-massive'),
          disabled
        },
        title: 'Cancelar Flujos'
      });
    }
    if (ability.can('show', 'Document')) {
      authorizedActions.push({
        icon: <Icon icon="download-2" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'export',
        props: {
          onClick: () => handleClick('export')
        },
        title: 'Descargar'
      });
    }
    if (ability.can('show', 'Document')) {
      authorizedActions.push({
        icon: <Icon icon="download-2" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'download',
        props: {
          onClick: () => handleClick('download'),
          disabled
        },
        title: 'Descargar Documentos'
      });
    }
    if (ability.can('destroy_file', 'DirectoryNode')) {
      authorizedActions.push({
        icon: <Icon icon="trash" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'destroy',
        props: {
          onClick: () => handleClick('destroy'),
          disabled
        },
        title: 'Eliminar'
      });
    }
    if (ability.can('create_document', 'WorkflowRequest')) {
      authorizedActions.push({
        icon: <Icon icon="paper-plane-outline" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'request-sign',
        props: {
          onClick: () => handleClick('request-sign'),
          disabled
        },
        title: 'Enviar a Flujo'
      });
    }
    if (ability.can('resend_notification', 'WorkflowRequest')) {
      authorizedActions.push({
        icon: <Icon icon="notifications" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'resend',
        props: {
          onClick: () => handleClick('resend'),
          disabled
        },
        title: 'Enviar Recordatorio'
      });
    }
    if (ability.can('create', 'DocumentTemplate')) {
      authorizedActions.push({
        icon: <Icon icon="document" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'generate-documents',
        props: {
          onClick: () => handleClick('generate-documents')
        },
        title: 'Generar Documentos'
      });
    }
    return authorizedActions;
  };

  return (
    <div className="mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
};

export default DocumentMassiveActions;
