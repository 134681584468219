import React, { useState, useEffect } from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import snakeCaseKeys from 'snakecase-keys';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import memoize from 'memoize-one';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { FormikInput, ComponentDataTable, DefaultModal, DualList } from '../../components';
import { downloadFile } from '../../services/utils';
import { es } from './FormOptions';
import { handleCustomReportRequest, deleteReportRequest } from '../../requests/reports';

const CustomReport = ({ queryOptions, report, loadReports }) => {
  const { errors, touched, setFieldValue, values } = useFormikContext();
  const { reportQueryOptionsAttributes } = values.report;
  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(true);
  const [moreData, setMoreData] = useState(true);
  const [columnsData, setColumnsData] = useState([]);
  const [fullTableData, setFullTableData] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [queryOpts, setQueryOpts] = useState([]);
  const reportId = report?.report?.id;
  const selected = reportQueryOptionsAttributes.map(e => e.value);

  const columns = memoize(() =>
    reportQueryOptionsAttributes.map(item => ({
      name: item.label,
      selector: item.name,
      sortable: false,
      grow: '1'
    }))
  );

  const removeReport = () => {
    deleteReportRequest(reportId, {
      dispatch,
      successCallback: () => {
        loadReports(true, 'eliminado');
      },
      callback: () => setModalShow(false)
    });
  };

  const reportData = (params, download) => {
    const vParams = params || values;
    handleCustomReportRequest({
      dispatch,
      params: snakeCaseKeys({ ...vParams, download }),
      successCallback: response => {
        if (download) {
          downloadFile(response);
        } else {
          setFullTableData(response.data.result);
          setMoreData(!moreData);
        }
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleRequest = params => {
    const { displayStart, displayLength } = params;
    const filteredData = fullTableData.filter((_, key) => key >= displayStart && key < displayStart + displayLength);
    setDataTable(filteredData);
  };

  const handleUpdateColumns = data => {
    setOnRequest(true);
    const params = {
      report: { reportQueryOptionsAttributes: data, report_type: 'custom', name: values.report.name }
    };
    setColumnsData(data.map(e => e.label));
    return data.length > 0 ? reportData(params) : setFullTableData([]);
  };

  const initialReportData = () => {
    const vQueryOpts = [];
    const rqoa = reportQueryOptionsAttributes.map(item => {
      const qoa = queryOptions.filter(qo => qo.value === item.queryOptionId)[0];
      return {
        queryOptionId: qoa.value,
        optionType: 'q_attribute',
        label: qoa.label,
        value: qoa.value,
        name: qoa.name
      };
    });
    queryOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
    queryOptions.reduce((_, qop) => {
      const qosLabel = vQueryOpts.filter(qo => qo.label === qop.group)[0];
      if (qosLabel) {
        qosLabel.options.push({
          queryOptionId: qop.value,
          optionType: 'q_attribute',
          label: qop.label,
          value: qop.value,
          name: qop.name
        });
      } else {
        vQueryOpts.push({ label: qop.group, options: [] });
      }
      return true;
    });
    setQueryOpts(vQueryOpts.sort((a, b) => (a.label > b.label ? 1 : -1)));
    setFieldValue('report[reportQueryOptionsAttributes]', rqoa);
    setFieldValue('reportId', reportId);
    setFieldValue('reportType', 'custom');
    setFullTableData(report?.result ? snakeCaseKeys(report?.result) : []);
    setColumnsData(rqoa.map(e => e.label));
    setOnRequest(false);
  };

  useEffect(initialReportData, [report]);

  return (
    <>
      <hr />
      <Row>
        <Col md={12}>
          <Field name="report[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col className="mt-4" xs={12}>
          <DualList
            options={queryOpts}
            es={es}
            selected={selected.includes(undefined) ? [] : selected}
            filterPlaceholder="Buscar Columnas..."
            name="report[reportQueryOptionsAttributes]"
            canFilter
            isCustomReport
            showOrderButtons
            preserveSelectOrder
            onChange={sel => {
              const data = [];
              sel.forEach(id => {
                queryOpts.forEach(qo => {
                  qo.options.filter(option => option.value === id && data.push(option));
                });
              });
              setFieldValue('report[reportQueryOptionsAttributes]', data || []);
              handleUpdateColumns(data || []);
            }}
          />
        </Col>
      </Row>
      <Row className="mr-md-2 mr-xl-3 mt-5">
        <Col md={{ span: 3, offset: 3 }}>
          {reportId && (
            <Button className="mb-5" variant="danger" block onClick={() => setModalShow(true)}>
              ELIMINAR REPORTE
            </Button>
          )}
        </Col>
        <Col md={{ span: 3 }}>
          {columnsData.length > 0 && (
            <Button className="mb-5" variant="secondary" block onClick={() => reportData(false, true)}>
              DESCARGAR REPORTE
            </Button>
          )}
        </Col>
        <Col md={{ span: 3 }}>
          <Button className="mb-5" type="submit" variant="primary" block>
            {`${reportId ? 'ACTUALIZAR' : 'CREAR'} REPORTE`}
          </Button>
        </Col>
      </Row>
      {fullTableData.length > 0 ? (
        <>
          <h2 className="text-uppercase">Tabla de Datos</h2>
          <ComponentDataTable
            columns={columns()}
            data={dataTable}
            onRequest={onRequest}
            moreData={moreData}
            resourceRequest={e => handleRequest(camelCaseRecursive(e))}
            totalRows={fullTableData.length}
            withSearch={false}
          />
        </>
      ) : (
        <div className="my-4 position-relative">
          {onRequest && (
            <div className="containerSpinnerLoad position-absolute h-100">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>
      )}
      <DefaultModal
        title="Eliminar Reporte"
        body="Está seguro que desea eliminar este reporte?"
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => removeReport()}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default CustomReport;
