import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounceIndexDocumentsRequest } from '../../requests/documents';
import { ComponentDataTable, Icon, DefaultModal, BasicDropdown, SimpleCenteredModal } from '../../components';
import { delayMethod } from '../../services/utils';
import { rejectWorkflowRequestRequest, approveWorkflowRequestRequest } from '../../requests/workflowRequests';
import { sendAlert } from '../../actions/utils';
import columns from './Columns';
import DocumentPreview from '../DocumentPreview/DocumentPreview';

const InternalDocumentationDataTable = () => {
  const [documents, setDocuments] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const [modalItem, setModalItem] = useState(null);
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [documentModalBody, setDocumentModalBody] = useState('');
  const [documentStatus, setDocumentStatus] = useState('');
  const [amount, setAmount] = useState(0);
  const [customParams, setCustomParams] = useState({});
  const [filters, setFilters] = useState({});
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const { user } = useSelector(state => state.auth);
  const myName = user.fullName?.trim();
  const dispatch = useDispatch();

  const handleRequest = async params => {
    setOnRequest(true);
    debounceIndexDocumentsRequest({
      dispatch,
      format: '',
      params: {
        document_type: [
          'personal_protection',
          'report_occupational_hazards',
          'acceptance_riohs',
          'induction_risk_prevention'
        ],
        ...customParams,
        ...params
      },
      successCallback: response => {
        const { data, metadata } = response.data;
        setDocuments(data);
        setAmount(metadata.amount);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleFilters = action => {
    if (filters[action]) {
      setFilters({ [action]: false });
      setCustomParams({});
      setMoreData(!moreData);
    } else {
      setFilters({ [action]: true });
      setCustomParams({ status: [action] });
      setMoreData(!moreData);
    }
  };

  const handleDocument = workflowRequestId => {
    if (documentStatus === 'approved') {
      approveWorkflowRequestRequest(workflowRequestId, {
        dispatch,
        successCallback: () => {
          dispatch(sendAlert({ kind: 'success', message: 'Documento aprobado con éxito' }));
          setMoreData(!moreData);
          setModalShow(false);
        }
      });
    } else {
      setOnRequest(true);
      rejectWorkflowRequestRequest(workflowRequestId, {
        params: {
          workflow_request: {
            id: workflowRequestId,
            record: {
              id: undefined
            }
          }
        },
        dispatch,
        successCallback: () => {
          dispatch(sendAlert({ kind: 'success', message: 'Documento rechazado con éxito' }));
          setOnRequest(false);
          setMoreData(!moreData);
          setModalShow(false);
        }
      });
    }
  };

  const setModal = (title, item, type) => {
    setModalTitle(title);
    setModalItem(item);
    setModalShow(true);
    setModalBody(
      <div className="ml-2">
        ¿Estás seguro que deseas {type} el documento {item.file_info?.clean_filename}?
      </div>
    );
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        setDocumentModalBody(<DocumentPreview documentId={item.id} employeeId={item.employee?.id} />);
        setDocumentModalShow(true);

        break;
      case 'approved':
        setModal('Aprobar Documento Interno', item, 'aprobar');
        setDocumentStatus('approved');
        break;
      case 'rejected':
        setModal('Rechazar Documento Interno', item, 'rechazar');
        setDocumentStatus('rejected');
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleDocumentModalClose = () => {
    setDocumentModalShow(false);
  };

  const sortColumnCase = value => {
    switch (value) {
      case 'name':
        return { sort_employees: value };
      case 'job_title':
        return { sort_job_titles: value };
      case 'status':
        return { sort_status: value };
      default:
        return { sort_column: value };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, myName)}
        handleSortCase={sortColumnCase}
        data={documents}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={handleRequest}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
        withMassActions
        massActions={
          <BasicDropdown
            variant="outline-primary"
            responsive
            noArrow
            block
            right
            titleDrop={
              <span className="align-middle">
                Filtros <Icon icon="ellipsis-vertical" height="20px" width="20px" />
              </span>
            }
            items={[
              {
                key: 'notSigned',
                title: 'Emitidos',
                props: {
                  onClick: () => handleFilters('not_signed'),
                  active: filters.not_signed
                }
              },
              {
                key: 'approved',
                title: 'Aprobados',
                props: {
                  onClick: () => handleFilters('approved'),
                  active: filters.approved
                }
              },
              {
                key: 'pending',
                title: 'Pendientes',
                props: {
                  onClick: () => handleFilters('pending'),
                  active: filters.pending
                }
              },
              {
                key: 'rejected',
                title: 'Rechazados',
                props: {
                  onClick: () => handleFilters('rejected'),
                  active: filters.rejected
                }
              }
            ]}
          />
        }
        preName="documents"
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => delayMethod(() => setModalShow(false))}
        handleConfirm={() => handleDocument(modalItem.workflow_request_id)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
      <SimpleCenteredModal
        body={documentModalBody}
        onHide={handleDocumentModalClose}
        show={documentModalShow}
        size="xl"
      />
    </>
  );
};

export default InternalDocumentationDataTable;
