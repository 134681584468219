import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Icon } from '../../components';
import StagesSelector from './StagesSelector';

export default function StagesForm({ settingData, setSettingData }) {
  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={12} md={12}>
              <Card.Title>DEFINICIÓN DE LAS ETAPAS DEL SIMULADOR</Card.Title>
            </Col>
            <Col xs={12} md={6}>
              <StagesSelector settingData={settingData} setSettingData={setSettingData} />
            </Col>
            <Col xs={12} md={6}>
              <Col xs={12} className="d-flex">
                <Icon size="1.5rem" icon="warning" color="#ff9c07" />
                <Card.Title className="mt-1"> Notas</Card.Title>
              </Col>
              <ul>
                <li>- LAS ETAPAS AQUÍ DESCRITAS, SON SÓLO UNA GUÍA PARA EL USUARIO.</li>
                <li>- LAS ETAPAS APARECEN EN LA GRILLA DEL SIMULADOR, SEGÚN EL ORDEN AQUÍ ESTABLECIDO.</li>
                <li>- SÓLO PUEDE DEFINIRSE UN MAXIMO DE 6 ETAPAS.</li>
              </ul>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
