import React, { createContext, useState } from 'react';

const initialState = {
  payrollProcess: {}
};

export const PayrollProcessContext = createContext({});

export const PayrollProcessProvider = ({ children }) => {
  const [payrollProcess, setPayrollProcess] = useState(initialState);

  return (
    <PayrollProcessContext.Provider value={{ payrollProcess, setPayrollProcess }}>
      {children}
    </PayrollProcessContext.Provider>
  );
};
