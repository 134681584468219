import React from 'react';
import PropTypes from 'prop-types';
import BasicTooltip from '../Utils/Tooltips/BasicTooltip';

const AdvancePaymentProcessStatus = ({ item, type }) => {
  const text = item?.status;
  const currentType = type ? 'text' : 'dot dot';
  const currentClass = text === 'Cerrado' ? `${currentType}-danger` : `${currentType}-success`;
  const textSpan = type && text;

  return <>
    <BasicTooltip text={text}>
      <span className={currentClass} data-tag="allowRowEvents">
        <b>{textSpan}</b>
      </span>
    </BasicTooltip>
  </>
}

AdvancePaymentProcessStatus.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

AdvancePaymentProcessStatus.defaultProps = {};

export default AdvancePaymentProcessStatus;
