import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip } from '../../../../components';
import Icon from '../../../../components/Icons/Icon';

export default memoize(handleExport => [
  {
    name: 'PERIODO SELECCIONADO',
    selector: 'name',
    accessor: 'name',
    grow: '1',
    center: true
  },
  {
    name: 'EMPLEADOS PROCESADOS',
    selector: 'data_processes_count',
    accessor: 'data_processes_count',
    grow: '1',
    sortable: true,
    center: true
  },
  {
    name: 'MONTO PROCESADO',
    selector: 'parsed_total_amount',
    accessor: 'parsed_total_amount',
    grow: '1',
    center: true
  },
  {
    name: 'ESTADO',
    accessor: 'status',
    grow: '1',
    cell: row => {
      switch (row.payroll_status) {
        case 'associated':
          return (
            <span className="text-primary" data-tag="allowRowEvents">
              <b>PROCESADO</b>
            </span>
          );

        default:
          return <span className="text-muted">{row.status}</span>;
      }
    },
    center: true
  },
  {
    name: 'DESCARGAR',
    selector: 'concept',
    cell: row => (
      <ButtonTooltip
        onClick={() => handleExport(row.payroll_process_id, row.id)}
        variant="circle-primary"
        className="btn-circle"
        size="sm"
        text=""
      >
        <Icon icon="download-2" />
      </ButtonTooltip>
    ),
    center: true
  }
]);
