export default {
  name: '',
  shiftType: 'weekly',
  workHolidays: false,
  lunchCheck: false,
  lunchTime: '',
  collationTime: false,
  agreedSchedule: false,
  flexibleShift: false,
  maximumStartTime: '',
  workHours: '',
  initialTime: '',
  tolerance: '',
  weekends: false,
  shiftDaysAttributes: [],
  startDate: '',
  contractIds: [],
  contracts: []
};
