import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { ButtonTooltip, FormikSelect, Icon, Loader, SimpleCenteredModal } from '../../../../components';
import { camelCaseEmptyStringRecursive } from '../../../../services/utils';
import EmployeeSearchModalPayroll from '../../../../components/Employee/EmployeeSearchModalPayroll';
import { payrollEmployeesRequest } from '../../../../requests/payrolls';

const ALL_CONTRACTS = 'all_contracts';
const ALL_EMPLOYES = [{ label: 'Todos', value: 'all_contracts' }];

const hasAllSelection = (groupSelection = []) => {
  return groupSelection.some(option => option.value === ALL_CONTRACTS);
};

const handleDisabledSelection = (options, selectedOptions) => {
  if (hasAllSelection(selectedOptions)) {
    return true;
  }
  if (selectedOptions.length === 0) return false;
  return options.value === ALL_CONTRACTS;
};

const EmployeeFilterModal = ({ showInput, payrollProcess, customParams }) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [selector, setSelector] = useState([]);

  const fetchEmployees = params => {
    payrollEmployeesRequest({
      dispatch,
      params: { ...params, ...customParams },
      successCallback: response => {
        const employees = camelCaseEmptyStringRecursive(response.data.data);
        const formatedEmployees = employees
          .map(employee => ({
            ...employee,
            id: employee.id,
            value: employee.payrollId,
            label: employee.startDateContract ? `${employee.fullName} - (${employee.startDateContract})` : employee.fullName
          }))

        setSelector([...ALL_EMPLOYES, ...formatedEmployees]);
        setFieldValue('selected', formatedEmployees.map(item => item.id));
        setLoading(false)
      },
      failureCallback: () => setLoading(false)
    });
  };
  /**
   * Get employees Object by selected ids from selector State
   * @param {Array} selectedEmployees - Array of selected employees ids
   * @returns {Array} formatedEmployees - Array of employees with balances
   */
  const getEmployeesById = selectedEmployees => {
    if (selectedEmployees.length === 0) return [];
    const formatedEmployees = selector
      .filter(employee => selectedEmployees.includes(employee.id))
      .map(employee => ({ ...employee, balances: [] }));
    return formatedEmployees;
  };

  /**
   * Search for repeated Ids
   * @param {Array} selectedEmployees - Array of selected employees ids
   * @returns {Array} formatedEmployees - Clean Array of employees with balances that can be added to the payroll
   */
  const validatedEmployees = selectedEmployeeIds => {
    if (selectedEmployeeIds.length === 0) return [];
    const { employeeIds } = values.payrollBalance;
    const filteredEmployees = selectedEmployeeIds.filter(
      employeeId => !employeeIds.find(employee => employee.id === employeeId)
    );
    const newEmployees = getEmployeesById(filteredEmployees);
    return newEmployees;
  };

  const handleSearch = selectedEmployees => {
    if (selectedEmployees.length === 0) return;
    const formatedEmployees = validatedEmployees(selectedEmployees);
    const clearedEmployees = values?.payrollBalance?.employees;
    const currentEmployeeIds = values?.payrollBalance?.employeeIds;
    const filteredSelector = selector.filter(
      employee =>
        selectedEmployees.includes(employee.id) && !currentEmployeeIds.find(current => current.id === employee.id)
    );
    setFieldValue('payrollBalance[employees]', [...clearedEmployees, ...formatedEmployees]);
    setFieldValue('payrollBalance[employeeIds]', [...currentEmployeeIds, ...filteredSelector], false);
    setModalShow(false);
  };

  const iterateEmployees = employeesSelected => {
    if (values?.payrollBalance?.employees?.length === 0) return employeesSelected;

    if (values?.payrollBalance?.balanceIds?.length === 0) return employeesSelected;

    if (employeesSelected.length === 0) return [];
    return employeesSelected.map(employee => {
      const employeeInForm = values?.payrollBalance?.employees?.find(({ id }) => id === employee.id);
      if (!employeeInForm) return { ...employee, balances: [] };
      return { ...employee, balances: employeeInForm?.balances || [] };
    });
  };

  const handleEmployees = (field, data) => {
    const employeeFilter = selector.filter(item => item?.id);

    const employeeWithBalances = iterateEmployees(data);
    setFieldValue(field, employeeWithBalances);

    if (data.find(({ value }) => value === 'all_contracts') === undefined) {
      setFieldValue('payrollBalance[employees]', employeeWithBalances || []);
    }

    if (data.find(({ value }) => value === 'all_contracts') !== undefined) {
      const employeesSelected = iterateEmployees(employeeFilter);
      setFieldValue('payrollBalance[employees]', employeesSelected);
    }
  };

  useEffect(fetchEmployees, []);

  return (
    <>
      <Row className="align-items-center">
        {showInput && (
          <Col xs={12} md={8}>
            <Field name="payrollBalance[employeeIds]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Filtro Avanzado"
                  isMulti
                  options={selector}
                  isOptionDisabled={handleDisabledSelection}
                  onChange={data => {
                    handleEmployees(field.name, data || []);
                  }}
                  defaultMultiValues={values?.payrollBalance?.employeeIds || []}
                  setFieldTouched={() => setFieldTouched(field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        {loading ? (
          <Col md={2} xl={2}>
            <Loader
              containerStyle={{ height: '35px' }}
              loaderSize={30}
              message="Cargando trabajadores..."
              messageStyle={{ fontSize: '14px' }}
            />
          </Col>
        ) : (
          <Col xs={12} md={2}>
            <ButtonTooltip
              variant="circle-primary"
              className="advance-search"
              toolbarVariant="mt-1"
              text="Búsqueda avanzada de empleados mediante filtros de cargos, Lugares de Prestación de Servicios, otros"
              onClick={() => setModalShow(true)}
            >
              <Icon icon="people-circle" className="w-100 h-100" />
            </ButtonTooltip>
          </Col>
        )}
      </Row>
      <SimpleCenteredModal
        title="Buscar Empleados"
        body={
          <EmployeeSearchModalPayroll
            customParams={{
              filter_payroll_process: payrollProcess.id
            }}
            payrollValidIds={payrollProcess.payroll_employee_ids || []}
            handleClose={() => setModalShow(false)}
            formRequest={handleSearch}
          />
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default EmployeeFilterModal;
