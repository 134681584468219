import React from 'react';

const ModalLoanSettings = () => {
  return (
    <>
      <ul>
        <li>Las solicitudes permanecerán vigentes, solo durante el mes en que se realiza esta.</li>
        <li>Luego de expirado el mes, las solicitudes serán eliminadas del sistema.</li>
      </ul>
    </>
  );
};

export default ModalLoanSettings;
