import React from 'react';
import RemunerationProcessesHistories from './RemunerationProcessesHistories';
import { PayrollProcessProvider } from './context/PayrollProcessProvider';

const RemunerationProcessesHistoriesIndex = props => {
  return (
    <PayrollProcessProvider>
      <RemunerationProcessesHistories {...props} />
    </PayrollProcessProvider>
  );
};

export default RemunerationProcessesHistoriesIndex;
