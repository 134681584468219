import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useSelector } from 'react-redux';
import { DefaultModal } from '../../Utils/Modal';
import CustomMarker from './CustomMarker';

const MapMarkingReport = ({ brachOfficesCoordinates = null, employeeCoordinates = null, middlePoint = null }) => {
  const [modalShow, setModalShow] = useState(false);
  const [body, setBody] = useState(false);
  const { advanceSettings } = useSelector(state => state.utils);
  const attendanceRadius = advanceSettings?.filter(asItem => asItem.code === 'mobile_assistance_distance')[0]
    ?.integerValue;

  const defaultProps = {
    center: {
      lat: brachOfficesCoordinates?.length === 1 ? brachOfficesCoordinates[0]?.lat : middlePoint?.lat,
      lng: brachOfficesCoordinates?.length === 1 ? brachOfficesCoordinates[0]?.lng : middlePoint?.lng
    },
    zoom: brachOfficesCoordinates?.length === 1 ? 16 : 12
  };

  return (
    <>
      <div style={{ height: '80vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyCwndRpOtXKHlX079dUfDE0fO7e4vWWgeE',
            language: 'es',
            region: 'cl'
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            if (brachOfficesCoordinates.length > 0) {
              brachOfficesCoordinates.forEach(({ lat, lng }) => {
                // eslint-disable-next-line no-new
                new maps.Circle({
                  strokeColor: '#FF0000',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: '#FF0000',
                  fillOpacity: 0.3,
                  map,
                  center: { lat, lng },
                  radius: attendanceRadius
                });
              });
            }
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          center={defaultProps.center}
          zoom={brachOfficesCoordinates?.length === 1 ? 16 : 12}
        >
          {employeeCoordinates.map(c => {
            if (c.lat && c.lng) {
              return <CustomMarker title={c.title} key={c.id} lat={c.lat} lng={c.lng} />;
            }
            return null;
          })}
          {brachOfficesCoordinates.map(c => {
            if (c.lat && c.lng) {
              return (
                <CustomMarker
                  key={c.employee_branch_office_id}
                  lat={c.lat}
                  lng={c.lng}
                  employeeBranchOfficeId={c.employee_branch_office_id}
                  setModalShow={setModalShow}
                  setBody={setBody}
                  isBranchOffice
                  red
                />
              );
            }
            return null;
          })}
        </GoogleMapReact>
      </div>
      <DefaultModal
        title="Lista de Colaboradores"
        body={body}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        withClose={false}
        withConfirm={false}
        centered
      />
    </>
  );
};

export default MapMarkingReport;
