import React from 'react';
import Errors from './Errors';

function ModalErrors({ errors = [] }) {
  return (
    <div>
      <div className="alert alert-danger">
        {errors.map(error => {
          return (
            <>
              <p className="mx-3 font-weight-normal"> {error.full_name}</p>
              <Errors className="" key={error.id} errorLabels={error.messages} />
            </>
          );
        })}
      </div>
    </div>
  );
}

export default ModalErrors;
