import React from 'react';
import RingLoader from 'react-spinners/RingLoader';

const Loader = ({
  loaderColor = '#36d7b7',
  loaderSize = 150,
  message = 'Estamos generando un nuevo proceso, por favor espera unos segundos.',
  containerStyle = {}, // Estilos custom para el contenedor
  loaderStyle = {}, // Estilos custom para el RingLoader
  messageStyle = {} // Estilos custom para el mensaje
}) => {
  // Estilos por defecto
  const defaultContainerStyle = {
    height: 300,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  };

  const defaultLoaderStyle = {
    position: 'absolute',
    zIndex: 10,
    top: '10%',
    left: '40%',
    transform: 'translate(-50%, -50%)'
  };

  const defaultMessageStyle = {
    textAlign: 'center',
    position: 'absolute',
    top: '75%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 16,
    color: 'gray'
  };

  return (
    <div style={{ ...defaultContainerStyle, ...containerStyle }}>
      <RingLoader color={loaderColor} size={loaderSize} style={{ ...defaultLoaderStyle, ...loaderStyle }} />
      <span style={{ width: '100%' }}>
        {message && <p style={{ ...defaultMessageStyle, ...messageStyle }}>{message}</p>}
      </span>
    </div>
  );
};

export default Loader;
