import { useAbility } from '@casl/react';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { sendAlert } from '../../actions/utils';
import { ComponentDataTable, DefaultModal, FormikSelect, SimpleCenteredModal } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import {
  createAdvancePaymentProcessRequest,
  massiveCloseMonthRequest,
  massiveDeleteAdvancePaymentRequest,
  massiveExportAdvancePaymentRequest,
  debounceIndexAdvancePaymentProcessRequest,
  deleteAdvancePaymentProcessRequest,
  closeAdvancePaymentProcessRequest
} from '../../requests/advancePaymentProcess';

import { useAuthorization, useSetTab } from '../../services/hooks';
import AdvancePaymentProcessForm from './AdvancePaymentProcessForm';
import columns from './APColumns';
import ApProcessIndexMassiveActions from '../../components/DatatableActions/ApProcessIndexMassiveActions';
import { downloadFile } from '../../services/utils';
import { indexEmployeesRequest } from '../../requests/employees';

const AdvancePaymentProccessIndex = ({ location }) => {
  const [onRequest, setOnRequest] = useState(true);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const [apRequests, setApRequests] = useState([]);
  const history = useHistory();
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalAction, setModalAction] = useState(() => null);
  const [moreData, setMoreData] = useState(false);
  const [year, setYear] = useSetTab(moment().year(), location, 'year', e => parseInt(e, 10));
  const years = useMemo(() => Array.from(Array(10), (e, i) => ({ label: i + 2022, value: i + 2022 })), []);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [amount, setAmount] = useState(0);

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setAmount(metadata.amount);
    setApRequests(data);
    setOnRequest(false);
  };

  const handleRequest = params => {
    setOnRequest(true);
    debounceIndexAdvancePaymentProcessRequest({
      params: { ...params, filter_year: year },
      dispatch,
      successCallback: handleSuccessIndex
    });
  };

  const handleCreate = (values, setSubmitting) => {
    const { advancePaymentProcess } = values;

    indexEmployeesRequest({
      dispatch,
      params: {
        advance_payment_process: true,
        is_active: true,
        sort_column: 'name',
        sort_direction: 'asc',
        month: advancePaymentProcess.month,
        year: advancePaymentProcess.year,
        paginate: false
      },
      successCallback: employee => {
        const employees = employee.data.data;
        const updatedValues = {
          ...values,
          advancePaymentProcess: {
            ...advancePaymentProcess,
            data_processes_attributes: employees
          }
        };

        createAdvancePaymentProcessRequest({
          dispatch,
          params: snakeCaseKeys(updatedValues),
          successCallback: response => {
            dispatch(sendAlert({ kind: 'success', message: 'Proceso creado con éxito' }));
            setMoreData(!moreData);
            setSimpleModalShow(false);
            history.push({
              pathname: `/advance_payments_process/${response.data.id}`,
              state: { month: advancePaymentProcess.month, year: advancePaymentProcess.year }
            });
          },
          callback: () => setSubmitting(false)
        });
      }
    });
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Anticipo eliminado correctamente' }));
    setMoreData(!moreData);
    setClearSelectedRows(!clearSelectedRows);
  };

  const handleSuccessClose = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Anticipo cerrado correctamente' }));
    setMoreData(!moreData);
    setClearSelectedRows(!clearSelectedRows);
  };

  const removeAdvancePaymentProcess = item => {
    setOnRequest(true);
    deleteAdvancePaymentProcessRequest(item.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => {
        setDefaultModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const closeAdvancePaymentProcess = item => {
    setOnRequest(true);
    closeAdvancePaymentProcessRequest(item.id, {
      dispatch,
      successCallback: handleSuccessClose,
      callback: () => {
        setDefaultModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleMassiveFailureRequest = error => {
    const { response } = error;
    setDefaultModalShow(false);
    setOnRequest(false);
    setMoreData(!moreData);
    handleRequest();
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
    setClearSelectedRows(!clearSelectedRows);
  };

  const massiveCloseMonth = () => {
    const processSelected = selectedRows.filter(asItem => asItem.status !== 'Cerrado').map(item => item.id);
    setOnRequest(true);
    massiveCloseMonthRequest({
      params: { ids: processSelected },
      dispatch,
      successCallback: handleSuccessClose,
      failureCallback: handleMassiveFailureRequest,
      callback: () => {
        setDefaultModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const massiveDeleteAdvancePayment = () => {
    const apSelected = selectedRows.filter(asItem => asItem.status !== 'Cerrado').map(item => item.id);

    setOnRequest(true);
    massiveDeleteAdvancePaymentRequest({
      params: { ids: apSelected },
      dispatch,
      successCallback: handleSuccessRemove,
      failureCallback: handleMassiveFailureRequest,
      callback: () => {
        setDefaultModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const massiveExportAdvancePayment = () => {
    const processSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    massiveExportAdvancePaymentRequest({
      params: { filter_ids: processSelected },
      dispatch,
      successCallback: response => {
        setDefaultModalShow(false);
        downloadFile(response);
        setClearSelectedRows(!clearSelectedRows);
        setOnRequest(false);
      },
      failureCallback: handleMassiveFailureRequest,
      callback: () => {
        setDefaultModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const modalBodyWithProcessClosed = closedProcess => {
    setModalBody(
      <>
        <p>
          Se han seleccionado Anticipos que se encuentran <b>CERRADOS</b> y no serán tomados en cuenta:
        </p>
        <ul>
          {closedProcess.map(item => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
        <p>¿Estás seguro que deseas continuar?</p>
      </>
    );
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/advance_payments_process/${item.id}/cards`);
        break;
      case 'edit':
        history.push({
          pathname: `/advance_payments_process/${item.id}`,
          state: { month: item.month, year: item.year }
        });
        break;
      case 'close_month':
        setModalTitle('Cerrar Proceso de Anticipo');
        setDefaultModalShow(true);
        setModalBody('¿Estás seguro que deseas cerrar este proceso de Anticipo?');
        setModalAction(() => closeAdvancePaymentProcess);
        setModalItem(item);
        break;
      case 'destroy':
        setModalTitle('Eliminar Anticipo');
        setDefaultModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar este proceso de Anticipo?');
        setModalAction(() => removeAdvancePaymentProcess);
        setModalItem(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleMassAction = action => {
    const closedProcess = selectedRows.filter(asItem => asItem.status === 'Cerrado');
    switch (action) {
      case 'close_month':
        setDefaultModalShow(true);
        setModalTitle('Cerrar Anticipos');
        if (closedProcess.length > 0) {
          modalBodyWithProcessClosed(closedProcess);
        } else {
          setModalBody('¿Estás seguro que deseas cerrar los Anticipos seleccionados?');
        }
        setModalAction(() => massiveCloseMonth);
        break;
      case 'destroy':
        setDefaultModalShow(true);
        setModalTitle('Eliminar Anticipos');
        if (closedProcess.length > 0) modalBodyWithProcessClosed(closedProcess);
        else setModalBody('¿Estás seguro que deseas eliminar los Anticipos seleccionados?');
        setModalAction(() => massiveDeleteAdvancePayment);
        break;
      case 'export':
        setDefaultModalShow(true);
        setModalTitle('Exportar Anticipos');
        setModalBody('¿Estás seguro que deseas exportar los Anticipos seleccionados?');
        setModalAction(() => massiveExportAdvancePayment);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'parsed_month':
        return { sort_column: 'month' };
      case 'employees_count':
        return { sort_column: 'employee_count' };
      case 'parsed_total_amount':
        return { sort_column: 'total_amount' };
      default:
        return { sort_column: name };
    }
  };

  useEffect(handleRequest, [year]);

  return (
    <>
      <Row className="m-top mb-2">
        <Col>
          <h2 className="m-top mb-3 mt-0">Proceso de Anticipos</h2>
        </Col>
        <Col md={3}>
          <FormikSelect
            options={years}
            label=""
            defaultValue={year}
            value={year}
            onChange={data => {
              setYear(data ? data.value : '');
              setMoreData(!moreData);
            }}
            placeholder="Selecciona un año"
          />
        </Col>
        {useAuthorization('create', 'AdvancePaymentProcess') && (
          <Col md={2}>
            <Button variant="primary" block onClick={() => setSimpleModalShow(true)}>
              Nuevo
            </Button>
          </Col>
        )}
      </Row>
      <ComponentDataTable
        resourceRequest={handleRequest}
        columns={columns(handleActions, ability)}
        data={apRequests}
        onRequest={onRequest}
        moreData={moreData}
        handleSortCase={sortColumnCase}
        withMassActions
        massActions={
          <ApProcessIndexMassiveActions
            model="AdvancePaymentProcess"
            handleClick={handleMassAction}
            disabledAction={selectedCount}
          />
        }
        onRowClicked={item => handleActions(item, 'show')}
        onSelectedRowsChange={handleSelectedRows}
        selectedRowCount={selectedCount}
        selectableRows
        totalRows={amount}
        clearSelectedRows={clearSelectedRows}
        withSearch={false}
      />

      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={defaultModalShow}
        handleClose={() => setDefaultModalShow(false)}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        variantBtnClose="outline-info"
        variantBtnSave="primary"
      />
      <SimpleCenteredModal
        title="Nuevo Proceso de Anticipo"
        body={<AdvancePaymentProcessForm formRequest={handleCreate} />}
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
    </>
  );
};

export default AdvancePaymentProccessIndex;
