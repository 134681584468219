import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAbility } from '@casl/react';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav, NavDropdown, Image } from 'react-bootstrap';
import { setSidebar, sendAlert } from '../../../actions/utils';
import { AbilityContext } from '../../../config/abilityContext';
import { updateAbility } from '../../../config/ability';
import { SimpleCenteredModal } from '../Modal';
import CompaniesModal from './CompaniesModal';
import SidebarAdmin from './SidebarAdmin';
import SidebarProfile from './SidebarProfile';
import SidebarBasic from './SidebarBasic';
import SidebarDt from './SidebarDt';
import Icon from '../../Icons/Icon';
import './style.scss';
import ChangePassword from '../../../screens/Auth/ChangePassword';
import { sendSignatureKeyUserRequest } from '../../../requests/user';
import ChangePin from '../../../screens/Auth/ChangePin';

const Sidebar = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const ability = useAbility(AbilityContext);
  const { user, currentEmployee, currentCompany } = useSelector(state => state.auth);
  const { userCompanies } = useSelector(state => state.utils);
  const { children, module } = props;
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalSize, setModalSize] = useState('');

  const pathName = () => {
    const { defaultActive } = props;
    const { pathname } = location;
    const paths = pathname.split('/');
    switch (paths[1]) {
      case 'admin':
        return paths[2] || 'companies';
      case 'profile':
        switch (paths[2]) {
          case 'syndicates':
            return 'groupings';
          case 'joint_committees':
            return 'groupings';
          default:
            return paths[2] || 'profile';
        }
      case 'dt':
        return paths[2] || 'dt';
      case 'documents':
        return paths[2] || defaultActive;
      case 'syndicates':
        return 'groupings';
      case 'joint_committees':
        return 'groupings';
      case 'companies':
        return 'company';
      default:
        return paths[1] || defaultActive;
    }
  };

  const closeSidebar = () => {
    const width = window.innerWidth;
    const isMobile = width <= 992;
    if (isMobile) {
      dispatch(setSidebar({ show: false }));
    }
  };

  const changeEmployee = () => {
    updateAbility(ability);
  };

  const authorization = () => {
    if (ability.cannot('manage', module)) {
      dispatch(sendAlert({ kind: 'error', message: 'No tienes permisos para acceder a este sitio' }));
      if (user.role === 'admin' && !currentEmployee) history.push('/admin/companies');
      else history.push('/profile/dashboard');
    }
    if (module === 'basic' && ability.cannot('rrhh', 'Employee')) {
      dispatch(sendAlert({ kind: 'error', message: 'No tienes permisos para acceder a este sitio' }));
      history.push('/profile/dashboard');
    }
  };

  const setViewport = () => {
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1024');
  };
  const showChangePassword = () => {
    setModalBody(<ChangePassword setModalShow={setModalShow} />);
    setModalSize('md');
    setModalTitle('Cambiar contraseña');
    setModalShow(true);
  };

  const handleSignatureKey = params => {
    sendSignatureKeyUserRequest({
      dispatch,
      params
    });
  };

  const showChangePin = () => {
    setModalBody(<ChangePin setModalShow={setModalShow} handleSignatureKey={handleSignatureKey} />);
    setModalSize('md');
    setModalTitle('Cambiar PIN de Firma');
    setModalShow(true);
  };
  const renderSidebar = () => {
    switch (module) {
      case 'admin':
        return (
          <SidebarAdmin
            pathName={pathName()}
            closeSidebar={closeSidebar}
            showChangePassword={showChangePassword}
            showChangePin={showChangePin}
          >
            {children}
          </SidebarAdmin>
        );
      case 'basic':
        return (
          <SidebarBasic
            pathName={pathName()}
            closeSidebar={closeSidebar}
            showChangePassword={showChangePassword}
            showChangePin={showChangePin}
          >
            {children}
          </SidebarBasic>
        );
      case 'dt':
        return (
          <SidebarDt pathName={pathName()} closeSidebar={closeSidebar}>
            {children}
          </SidebarDt>
        );
      default:
        return (
          <SidebarProfile
            pathName={pathName()}
            closeSidebar={closeSidebar}
            showChangePassword={showChangePassword}
            showChangePin={showChangePin}
          >
            {children}
          </SidebarProfile>
        );
    }
  };

  const showCompanyModal = () => {
    setModalBody(<CompaniesModal fromSidebar handleModalClose={() => setModalShow(false)} />);
    setModalTitle('Selecciona una empresa');
    setModalSize('md');
    setModalShow(true);
  };

  useEffect(changeEmployee, [module]);
  useEffect(authorization, [ability]);

  const canOpenModal =
    (currentCompany?.id && ability.can('create', 'Company')) ||
    (currentCompany?.id ? userCompanies > 1 : userCompanies) ||
    user.role === 'dt';
  let companyName = currentCompany?.businessName || '';
  companyName = companyName === '' && user.role === 'admin' ? 'Soporte' : companyName;
  return (
    <>
      {renderSidebar()}
      <NavDropdown.Divider className="m-divider" />
      <Nav>
        <Nav.Link className="m-nav-link" onClick={() => (canOpenModal ? showCompanyModal() : undefined)}>
          {currentCompany?.businessLogo?.fileUrl ? (
            <Image roundedCircle height={30} width={30} src={currentCompany.businessLogo.fileUrl} />
          ) : (
            <Icon icon="camera" width={30} />
          )}
          <span className="animation-link position-absolute">{companyName}</span>
        </Nav.Link>
        <Nav.Link className="d-md-none position-mobile" onClick={setViewport}>
          Version de Escritorio
        </Nav.Link>
      </Nav>
      <SimpleCenteredModal
        size={modalSize}
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Sidebar;
