import React from 'react';
import memoize from 'memoize-one';
import { BasicDropdown, Icon } from '../../../components';

const massActions = memoize((handleClick, disabled, hasRows) => {
  const actions = () => {
    const authorizedActions = [];

    authorizedActions.push({
      icon: <Icon icon="document" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'close',
      props: {
        onClick: () => handleClick('send_massive_document'),
        disabled
      },
      title: 'Enviar Documento'
    });

    authorizedActions.push({
      icon: <Icon icon="download-2" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'download',
      props: {
        onClick: () => handleClick('export'),
        disabled: !hasRows
      },
      title: 'Descargar'
    });

    return authorizedActions;
  };

  return (
    <div className="mb-4" style={{ display: 'flex', flexDirection: 'row' }}>
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
});

export default massActions;
