import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { LinkBtn } from '../../../components';
import ControlClockDataTable from './ControlClockDataTable';

const ControlClockIndex = () => {
  return (
    <>
      <Row className="mt-4 mb-1">
        <Col>
          <h2 className="text-uppercase mt-3">Reloj Control</h2>
        </Col>
        <Col md={3} xl={2}>
          <LinkBtn variant="primary" className="mt-3" block to="/admin/control_clock/new">
            Nuevo
          </LinkBtn>
        </Col>
      </Row>
      <ControlClockDataTable clickRoute="/admin/control_clock" />
    </>
  );
};

export default ControlClockIndex