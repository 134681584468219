/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { Button } from 'react-bootstrap';
import { DatatableEmployeeName, ApprovementStatus } from '../../../components';

const columns = memoize((clickHandler, showActions) => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} />,
    sortable: true,
    grow: '2',
    width: '200px',
    align: 'left'
  },
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA INICIO',
    selector: 'start_date',
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA TÉRMINO',
    selector: 'end_date',
    accessor: 'end_date',
    cell: item => (item.end_date ? item.end_date : `${item.start_time} - ${item.end_time}`),
    sortable: true,
    grow: '2'
  },
  {
    name: 'TIPO DE PERMISO',
    selector: 'permission_type',
    cell: item => item.translated_permission_type || item.translated_vacation_type,
    sortable: true,
    grow: '2'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    grow: '1',
    sortable: true,
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => {
      return (
        <>
          {showActions && (
            <Button variant="pw" block onClick={() => clickHandler(item, 'actions')} size="sm">
              Gestionar
            </Button>
          )}
        </>
      );
    },
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    center: true
  }
]);

export default columns;
