import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { FormikDatePicker, InputSelect, FormikSelect, FormikTimePicker } from '../../../../components';

import { delayMethod } from '../../../../services/utils';
import { markingTypes } from '../formOptions';
import { debounceIndexShiftsRequest } from '../../../../requests/shifts';
import { debounceIndexEmployeesRequest } from '../../../../requests/employees';

const MassiveCreateAttendanceForm = props => {
  const {
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleModalClose,
    onHide,
    isSubmitting,
    branchOffices
  } = props;
  const dispatch = useDispatch();
  const [branchOfficeId, setBranchOfficeId] = useState(null);
  const [shiftIdId, setShiftId] = useState(null);
  const [employeesFiltered, setEmployeesFiltered] = useState({});
  const [defaultEmployees, setDefaultEmployees] = useState([]);

  const handleNewMulti = (data, field, allowEmpty = false) => {
    if (allowEmpty && !data.length) data.push('');
    const existAll = data.some(v => v.value === 'all');
    if (existAll) setFieldValue(field, [{ label: 'Todos', value: 'all' }]);
    else setFieldValue(field, data);
  };

  const resultFetchData = (response, type) => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: `${type ? `${element.code} - ` : ''} ${element.name}`,
      value: element.id
    }));
  };

  const fetchContractShift = (inputValue, callback) => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const fetchEmployees = () => {
    if (branchOfficeId !== null && shiftIdId !== null) {
      debounceIndexEmployeesRequest({
        dispatch,
        params: {
          is_dt: false,
          active: true,
          filter_active_branch_offices: branchOfficeId,
          filter_active_shifts: shiftIdId,
          sort_column: 'name'
        },
        successCallback: response => {
          const result = response.data.data;
          const mapResult = result.map(element => ({
            ...element,
            label: `${element.short_name}`,
            value: element.id
          }));

          setEmployeesFiltered(mapResult);
        }
      });
    }
  };

  useEffect(fetchEmployees, [branchOfficeId, shiftIdId]);

  const setCommonValues = (field, data, setValueFunc) => {
    setValueFunc(data.value);
    setFieldValue(field.name, data ? data.value : '');
    setDefaultEmployees([]);
  };

  return (
    <>
      {isSubmitting && <div className="loader">Loading...</div>}
      <Form>
        <Modal.Body>
          <Row>
            <Col md={12} className="d-grid">
              <span>
                <b>Nueva Hora de Marcación</b>
              </span>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Field name="attendance[branchOfficeId]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Lugar de Prestación de Servicios"
                    placeholder="Seleccionar Lugar de Prestación de Servicios"
                    options={branchOffices}
                    onChange={data => {
                      setCommonValues(field, data, setBranchOfficeId);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="attendance[shiftId]">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    label="Turno"
                    placeholder="Seleccionar Turno"
                    value={undefined}
                    request={fetchContractShift}
                    onChange={data => {
                      setCommonValues(field, data, setShiftId);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>

          {branchOfficeId && shiftIdId && (
            <Row>
              <Col md={6}>
                <Field name="attendance[employeeIds]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      isMulti
                      label="Colaboradores"
                      placeholder="Seleccionar Colaboradores"
                      options={employeesFiltered}
                      defaultMultiValues={defaultEmployees}
                      value={defaultEmployees}
                      onChange={data => {
                        handleNewMulti(data || [], field.name, true);
                        setDefaultEmployees(data);
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={4}>
              <Field name="attendance[timeType]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Tipo de Marcación"
                    placeholder="Seleccionar Tipo de Marcación"
                    options={markingTypes}
                    isClearable
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field name="attendance[date]">
                {({ field }) => (
                  <FormikDatePicker
                    abbr
                    name={field.name}
                    value={field.value}
                    isOutsideRange={() => false}
                    label="Fecha"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field name="attendance[substitutionTime]">
                {({ field }) => (
                  <FormikTimePicker
                    {...field}
                    abbr
                    label="Nueva Hora"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeFormat="HH:mm aa"
                    placeholder=""
                    onChange={date => {
                      setFieldValue(
                        field.name,
                        date.toLocaleTimeString('en-ES', {
                          hour: 'numeric',
                          minute: '2-digit',
                          second: '2-digit'
                        })
                      );
                    }}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="text-center"
            variant="outline-info"
            disabled={isSubmitting}
            onClick={() => delayMethod(handleModalClose)}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="primary" onClick={onHide} disabled={isSubmitting}>
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

const setInitialValues = () => {
  return {
    attendance: {
      employeeIds: [],
      timeType: '',
      date: '',
      substitutionTime: '',
      branchOfficeId: '',
      shiftId: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  attendance: Yup.object().shape({
    employeeIds: Yup.array()
      .required('Debes escoger los Colaboradores')
      .min(1, 'Debes escoger al menos un colaborador.'),
    timeType: Yup.string().required('Tipo de Marcación es requerida'),
    date: Yup.string().required('La Fecha es requerida'),
    substitutionTime: Yup.string().required('La hora es requerida'),
    branchOfficeId: Yup.string().required('Debes seleccionar un lugar de prestación de servicios')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  const customValues = {
    attendance: {
      ...values.attendance,
      employeeIds: values.attendance.employeeIds.map(e => e.value)
    }
  };

  formRequest(customValues, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(MassiveCreateAttendanceForm);
