import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTable, useGlobalFilter, useSortBy, useExpanded, useRowSelect } from 'react-table';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Header from './Header';
import Body from './Body';
import Pagination from './Pagination';
import Checkbox from './Checkbox';
import Filters from './Filters';
import './custom-data-table.scss';

const styles = {
  noText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 15
  }
};

const Loading = React.memo(() => (
  <div className="containerSpinnerLoad h-100" style={{ width: '100%', borderRadius: 10 }}>
    <Spinner animation="border" variant="primary" />
  </div>
));

const DataTableStyled = ({
  expanded,
  ExpandedComponent,
  pageSizes,
  withGlobalFilter,
  withSelection,
  resourceRequest,
  searchFirstStart,
  noContentText,
  defaultStartDate,
  defaultEndDate,
  rangePicker,
  onRequest,
  onRowPress,
  onSelectedRowsChange,
  selectedRowCount,
  withMassActions,
  massActions,
  withDateSelect,
  withStartDate,
  withEndDate,
  withSearch,
  withHeaders,
  moreData,
  nameRangePicker,
  columns,
  clearSelection = false,
  headers = null,
  data,
  amount
}) => {
  const [textInput, setTextInput] = useState('');
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [dateRangeName, setDateRangeName] = useState('date_range');
  const [focusStartDate, setFocusStartDate] = useState(false);
  const [focusEndDate, setFocusEndDate] = useState(false);
  const [colSpan, setColSpan] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [repeatRequest, setRepeatRequest] = useState(false);
  const mounted = useRef(false);
  const pageCount = Math.ceil(amount / pageSize);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    state: { globalFilter },
    toggleAllRowsSelected,
    setGlobalFilter,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      autoResetSelectedRows: false,
      initialState: { pageIndex, pageSize, pageCount }
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(cols => {
        if (withSelection) {
          setColSpan(cols.length + 1);
          return [
            {
              id: 'selection',
              width: '90px',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <>
                  <Checkbox {...getToggleAllRowsSelectedProps()} />
                </>
              ),
              Cell: ({ row }) => (
                <>
                  <Checkbox {...row.getToggleRowSelectedProps()} disabled={row.original.disabledCheck} />
                </>
              )
            },
            ...cols
          ];
        }
        setColSpan(cols.length);
        return [...cols];
      });
    }
  );

  const handleGoToPage = newPageIndex => {
    if (newPageIndex >= 0) {
      setPageIndex(newPageIndex);
    }
  };

  const massActionsProps = {
    withMassActions,
    massActions,
    withSearch,
    setTextInput,
    textInput,
    withGlobalFilter,
    filter: globalFilter,
    setFilter: setGlobalFilter
  };

  const dateSelectionProps = {
    withDateSelect,
    withStartDate,
    withEndDate,
    startDate,
    focusStartDate,
    setFocusEndDate,
    setFocusStartDate,
    setStartDate,
    endDate,
    focusEndDate,
    setEndDate
  };

  const debounceRequest = AwesomeDebouncePromise(resourceRequest, 500);

  const setRequest = params => {
    const sortColumn = { sort_column: 'created_at' };
    let dateFilter = {
      date_from: moment.isMoment(startDate) ? startDate.format('DD/MM/YYYY') : '',
      date_to: moment.isMoment(endDate) ? endDate.format('DD/MM/YYYY') : ''
    };
    if (rangePicker && startDate && endDate) {
      dateFilter = { [dateRangeName]: [startDate.format('DD/MM/YYYY'), endDate.format('DD/MM/YYYY')] };
    }
    if (searchFirstStart) {
      debounceRequest({
        query: textInput,
        display_start: pageIndex * pageSize,
        display_length: pageSize,
        sort_direction: 'desc',
        ...dateFilter,
        ...sortColumn,
        ...params
      });
    }
  };

  const selectedRowHandler = useCallback(() => {
    if (withSelection && selectedRowCount !== selectedFlatRows?.length) {
      onSelectedRowsChange({
        selectedRows: selectedFlatRows?.map(r => r),
        selectedCount: selectedFlatRows?.length
      });
    }
  }, [selectedFlatRows, withSelection, onSelectedRowsChange, selectedRowCount]);

  const handleDateRange = () => {
    if (nameRangePicker) setDateRangeName(nameRangePicker);
    else setDateRangeName('date_range');
  };

  const handleSetPageSize = newPageSize => {
    if (pageIndex !== 0) {
      setPageSize(newPageSize);
      setPageIndex(0);
      return;
    }
    setPageSize(newPageSize);
    setRepeatRequest(oldValue => !oldValue);
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(
    () => handleDateRange(),
    // eslint-disable-next-line
    [nameRangePicker]
  );
  useEffect(
    () => setRequest(),
    // eslint-disable-next-line
    [startDate, endDate, textInput, dateRangeName, searchFirstStart, moreData, pageIndex, repeatRequest]
  );
  useEffect(
    () => selectedRowHandler(),
    // eslint-disable-next-line
    [selectedFlatRows]
  );

  useEffect(() => {
    toggleAllRowsSelected(false);
    // eslint-disable-next-line
  }, [clearSelection]);

  if (mounted.current === false) return null;

  return (
    <>
      <Filters {...massActionsProps} {...dateSelectionProps} headers={headers} withHeaders={withHeaders} />
      {onRequest ? (
        <Loading />
      ) : (
        <>
          {data.length > 0 ? (
            <>
              <table className="simple-table" {...getTableProps()}>
                <Header headerGroups={headerGroups} />
                <Body
                  page={rows}
                  prepareRow={prepareRow}
                  getTableBodyProps={getTableBodyProps}
                  colSpan={colSpan}
                  expanded={expanded}
                  onRowPress={onRowPress}
                  ExpandedComponent={props => <ExpandedComponent {...props} />}
                />
              </table>
              <Pagination
                handleGoToPage={handleGoToPage}
                totalRegisters={data.length}
                nextPage={nextPage}
                previousPage={previousPage}
                pageOptions={pageOptions}
                gotoPage={gotoPage}
                pageCount={pageCount}
                amount={amount}
                setPageSize={param => handleSetPageSize(param)}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageSizes={pageSizes}
              />
            </>
          ) : (
            <div style={styles.noText}>
              <strong>{noContentText}</strong>
            </div>
          )}
        </>
      )}
    </>
  );
};

DataTableStyled.propTypes = {
  expanded: PropTypes.bool,
  ExpandedComponent: PropTypes.func,
  selectedRowCount: PropTypes.number,
  moreData: PropTypes.bool,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  withGlobalFilter: PropTypes.bool,
  withSelection: PropTypes.bool,
  resourceRequest: PropTypes.func,
  searchFirstStart: PropTypes.bool,
  noContentText: PropTypes.string,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  rangePicker: PropTypes.string,
  query: PropTypes.string,
  onRequest: PropTypes.bool,
  onRowPress: PropTypes.func,
  onSelectedRowsChange: PropTypes.func,
  withMassActions: PropTypes.bool,
  massActions: PropTypes.element,
  withDateSelect: PropTypes.bool,
  withStartDate: PropTypes.bool,
  withEndDate: PropTypes.bool,
  withSearch: PropTypes.bool,
  withHeaders: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object)
};

DataTableStyled.defaultProps = {
  expanded: false,
  ExpandedComponent: () => <></>,
  selectedRowCount: 0,
  moreData: false,
  pageSizes: [10],
  withGlobalFilter: false,
  withSelection: false,
  resourceRequest: () => {},
  searchFirstStart: true,
  noContentText: 'No hay resultados',
  defaultStartDate: null,
  defaultEndDate: null,
  rangePicker: null,
  query: '',
  onRequest: false,
  onRowPress: () => {},
  onSelectedRowsChange: () => {},
  withMassActions: false,
  massActions: null,
  withDateSelect: false,
  withStartDate: false,
  withEndDate: false,
  withSearch: false,
  withHeaders: false,
  columns: [],
  data: []
};

export default DataTableStyled;
