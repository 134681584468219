/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import Icon from '../Icons';
import { ButtonTooltip } from '../Utils/Tooltips';

import { AttendanceUpdateActionBtns, DatatableEmployeeName } from '../index';
import ApprovementStatus from '../DatatableColumns/ApprovementStatus';

const overtimeAgreementsIcon = item => {
  if (item.overtime_agreements && ['approved', 'expired'].includes(item.overtime_agreements.status)) {
    let iconShow = '';
    let iconText = '';
    if (item.overtime_agreements.status === 'approved') {
      iconShow = <Icon title="Pacto Aprobado" icon="checkmark-circle" height="16px" width="16px" color="#29b48f" />;
      iconText = 'Pacto Aprobado';
    }
    if (item.overtime_agreements.status === 'expired') {
      iconShow = <span title="Pacto Expirado" className="dot dot-danger" data-tag="allowRowEvents" />;
      iconText = 'Pacto Expirado';
    }

    return (
      <ButtonTooltip onClick={() => ''} variant="circle-danger" className="btn-circle" size="sm" text={iconText}>
        {iconShow}
      </ButtonTooltip>
    );
  }
  return '';
};

const columns = memoize((clickHandler = true) => [
  {
    name: 'TRABAJADOR',
    selector: 'employee_full_name',
    cell: item => (
      <DatatableEmployeeName item={item.employee} withPhoto={false} onClick={() => clickHandler(item, 'show')} />
    ),
    sortable: true,
    grow: '2'
  },
  {
    name: 'CENTRO DE COSTO',
    selector: 'employee_cost_center',
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA',
    selector: 'real_marking_date',
    sortable: true,
    grow: '2'
  },
  {
    name: 'TURNO',
    selector: 'shift',
    cell: item => item.shift && item.shift.name,
    sortable: true,
    grow: '2'
  },
  {
    name: 'HORA',
    selector: 'time',
    cell: item => item.complete_parsed_time,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO',
    selector: 'time_type',
    cell: item => item.translated_time_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'PACTOS APROBADOS',
    selector: 'overtime_agreements',
    cell: item => overtimeAgreementsIcon(item),
    grow: '1',
    center: true
  },
  {
    name: 'MODALIDAD',
    selector: 'translated_device_type',
    cell: item => (item.translated_device_type ? item.translated_device_type : item.translated_marking_type),
    sortable: false,
    grow: '2',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <AttendanceUpdateActionBtns
        item={item}
        resource="Attendance"
        clickHandler={clickHandler}
        withEdit={false}
        withDestroy={false}
        withShow
      />
    ),
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    minWidth: '123px'
  }
]);

export default columns;
