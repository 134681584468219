import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon } from '../../../../components';

export default memoize(handleExport => [
  {
    name: 'MES DE PROCESO',
    selector: 'parsed_date',
    grow: '1'
  },
  {
    name: 'NOMBRE DEL PROCESO',
    selector: 'name',
    center: true,
    grow: '1',
    style: {
      textAlign: 'center'
    }
  },
  {
    name: 'MONTO ANTICIPOS',
    selector: 'parsed_total_amount',
    center: true,
    grow: '1'
  },
  {
    name: 'TRABAJADORES',
    selector: 'data_processes_count',
    accessor: 'data_processes_count',
    center: true,
    grow: '1'
  },
  {
    name: 'PROCESO DE ANTICIPOS',
    selector: 'status',
    accessor: 'status',
    grow: '1',
    cell: row => {
      switch (row.status) {
        case 'close':
          return (
            <span className="text-black" data-tag="allowRowEvents">
              <b>Cerrado</b>
            </span>
          );
        case 'open':
          return (
            <span className="text-warning">
              <b>Abierto</b>
            </span>
          );

        default:
          return <span className="text-muted">{row.status}</span>;
      }
    },
    center: true
  },
  {
    name: 'ESTADO',
    selector: 'parsed_payroll_status',
    accessor: 'parsed_payroll_status',
    cell: row => {
      switch (row.payroll_status) {
        case 'associated':
          return (
            <span className="text-success" data-tag="allowRowEvents">
              Procesado
            </span>
          );
        case 'not_associated':
          return (
            <span className="text-danger" data-tag="allowRowEvents">
              Pendiente
            </span>
          );
        default:
          return (
            <span className="text-muted" data-tag="allowRowEvents">
              {row.payroll_status}
            </span>
          );
      }
    },
    center: true,
    grow: '1'
  },
  {
    name: 'MES DE REMUNERACIÓN',
    selector: 'payroll_process_name',
    accessor: 'payroll_process_name',
    center: true
  },
  {
    name: 'DESCARGAR',
    cell: row => {
      switch (row.payroll_status) {
        case 'associated':
          return (
            <ButtonTooltip
              onClick={() => handleExport(row.payroll_process_id, row.id)}
              variant="circle-primary"
              className="btn-circle"
              size="sm"
              text=""
            >
              <Icon icon="download-2" />
            </ButtonTooltip>
          );
        default:
          return <></>;
      }
    },
    center: true
  }
]);
