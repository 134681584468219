import memoize from 'memoize-one';
import React from 'react';
import { ButtonTooltip, DatatableEmployeeName, Icon } from '../../../components';

export default memoize((clickHandler, disabled = () => {}, onlyShow) => [
  {
    name: 'TRABAJADOR',
    selector: 'employee',
    cell: item => (
      <>
        <DatatableEmployeeName
          item={item.employee}
          withPhoto={false}
          name="full_name"
          startDateContract={item?.start_date_contract}
        />
      </>
    ),
    sortable: true,
    grow: '2'
  },
  {
    name: 'CENTRO DE COSTO',
    selector: 'cost_center',
    cell: item => {
      return <span>{item.employee?.cost_center_name}</span>;
    },
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO',
    selector: 'translated_absence_type',
    grow: '1',
    sortable: true
  },
  {
    name: 'DÍAS',
    selector: 'days',
    grow: '1',
    maxWidth: '5px',
    center: true,
    sortable: true
  },
  {
    name: 'INICIO',
    selector: 'start_date',
    grow: '1',
    sortable: true
  },
  {
    name: 'TERMINO',
    selector: 'end_date',
    grow: '1',
    sortable: true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      switch (item.status) {
        case 'approved':
          return (
            <span className="text-success" data-tag="allowRowEvents">
              Aprobado
            </span>
          );
        default:
          return (
            <span className="text-warning" data-tag="allowRowEvents">
              Pendiente
            </span>
          );
      }
    },
    grow: '1',
    sortable: true
  },
  {
    name: 'ACCIONES',
    selector: 'concept',
    cell: item => (
      <>
        {!onlyShow && (
          <>
            {!disabled && (
              <ButtonTooltip
                onClick={() => clickHandler(item, 'edit')}
                variant="circle-warning"
                toolbarVariant="ml-auto"
                style={{ zIndex: 10 }}
                className="btn-circle"
                size="sm"
                text="Editar"
                disabled={disabled || item.absence_type === 'without_pay'}
              >
                <Icon icon="pencil" />
              </ButtonTooltip>
            )}
            <ButtonTooltip
              onClick={() => clickHandler(item, 'approved')}
              variant="circle-success"
              className="btn-circle"
              toolbarVariant="ml-auto"
              style={{ zIndex: 10 }}
              disabled={item.status !== 'pending'}
              size="sm"
              text="Aprobar"
            >
              <Icon icon="checkmark-circle" />
            </ButtonTooltip>
            <ButtonTooltip
              onClick={() => clickHandler(item, 'undo')}
              variant="circle-danger"
              className="btn-circle"
              toolbarVariant="ml-auto"
              style={{ zIndex: 10 }}
              disabled={item.status === 'pending'}
              size="sm"
              text="Deshacer"
            >
              <Icon icon="close-circle" />
            </ButtonTooltip>
            <ButtonTooltip
              onClick={() => clickHandler(item, 'cancel')}
              variant="circle-danger"
              className="btn-circle"
              toolbarVariant="ml-auto"
              disabled={item.status !== 'pending'}
              style={{ zIndex: 10 }}
              size="sm"
              text="Eliminar"
            >
              <Icon icon="trash" />
            </ButtonTooltip>
          </>
        )}
      </>
    ),
    grow: '1',
    center: true
  }
]);
