import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { useHistory } from 'react-router-dom';
import { updateShiftRequest, showShiftRequest } from '../../requests/shifts';
import { sendAlert } from '../../actions/utils';
import { LinkBtn, Icon, DefaultModal } from '../../components';
import ShiftForm from './ShiftForm';
import ShiftMonth from './ShiftMonth';
import ShiftRotative from './ShiftRotative';
import basicShift from './shift';

const ShiftEdit = ({ match }) => {
  const [shift, setShift] = useState(basicShift);
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState(false);
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [modalErrorBody, setModalErrorBody] = useState(false);
  const [shifts, setShifts] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [withConfirm, setWithConfirm] = useState(true);

  const hideModal = () => {
    setModalBody('');
    setModalShow(false);
    setModalErrorShow(false);
    setModalErrorBody('');
    setOnRequest(false);
  };

  const fetchShift = () => {
    const shiftId = match.params.id;
    setOnRequest(true);
    showShiftRequest(shiftId, {
      dispatch,
      successCallback: response => setShift(camelCaseRecursive(response.data)),
      callback: () => setOnRequest(false)
    });
  };

  const handleOpenModal = shiftValues => {
    setModalBody(
      <>
        <p>Los turnos asignados no podrán ser distintos de la jornada asignada a cada trabajador.</p>
        <br />
        <span>Recordatorio: No se pueden crear turnos con el mismo tipo y horario </span>
      </>
    );
    setModalShow(true);
    setShifts(shiftValues);
  };

  const handleCloseModal = () => {
    setModalShow(false);
    setModalErrorShow(false);
    setDisableButton(false);
  };

  const handleCloseErrorModal = () => {
    setModalShow(false);
    setModalErrorShow(false);
    setDisableButton(false);
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchShift, []);

  const handleFailureRequest = error => {
    const { response } = error;
    setModalErrorBody(response?.data?.message);
    setWithConfirm(false);
    setModalErrorShow(true);
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Turno actualizado con éxito' }));
    history.push('/shifts');
  };

  const handleUpdateRequest = () => {
    setDisableButton(true);
    const { id } = shifts.shift;
    const daysAttributes = shifts.shift.shiftDaysAttributes.filter(item => item && item.send !== '');
    const myParams = {
      shift: {
        ...shifts.shift,
        shiftDaysAttributes: daysAttributes.map(shiftDay => {
          if (!shiftDay.send) return { ...shiftDay, _destroy: 'true' };
          return shiftDay;
        })
      }
    };

    updateShiftRequest(id, {
      dispatch,
      params: snakeCaseKeys(myParams, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: error => {
        handleFailureRequest(error);
      },
      callback: () => {
        setDisableButton(false);
      }
    });
    handleCloseModal();
  };

  const body = () => {
    switch (shift.shiftType) {
      case 'monthly':
        return <ShiftMonth shift={shift} action="edit" formRequest={handleOpenModal} />;
      case 'rotative':
        return <ShiftRotative shift={shift} action="edit" formRequest={handleOpenModal} />;
      default:
        return <ShiftForm shift={shift} action="edit" formRequest={handleOpenModal} />;
    }
  };

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="mt-4 mb-4 align-items-center">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/shifts">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">Editar Turno</h2>
        </Col>
      </Row>
      {!onRequest && body()}
      <DefaultModal
        title="Recordatorio"
        body={modalBody}
        show={modalShow}
        handleClose={handleCloseModal}
        handleConfirm={handleUpdateRequest}
        withClose={false}
        titleBtnSave="Confirmar"
        disabled={disableButton}
      />
      <DefaultModal
        title="Notificacion de Error"
        body={modalErrorBody}
        show={modalErrorShow}
        onHide={hideModal}
        withConfirm={withConfirm}
        handleClose={handleCloseErrorModal}
        withClose
        disabled={disableButton}
      />
    </>
  );
};

export default ShiftEdit;
