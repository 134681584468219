import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import SyndicateInfo from './SyndicateInfo';
import { LinkBtn, Icon } from '../../components';
import { showSyndicateRequest } from '../../requests/syndicates';

class SlideShow extends Component {
  state = {
    syndicate: {
      name: '',
      code: '',
      address: '',
      addressNumber: '',
      identificationNumber: '',
      phone: '',
      employeeSyndicatesAttributes: []
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { match, dispatch } = this.props;
    const syndicateId = match.params.id;
    showSyndicateRequest(syndicateId, {
      dispatch,
      successCallback: response => this.handleSuccessShow(response)
    });
  }

  handleSuccessShow = response => {
    const syndicate = camelCaseRecursive(response.data);
    this.setState({ syndicate });
  };

  render() {
    const { syndicate } = this.state;
    return (
      <>
        <Row className="mt-4 mb-4">
          <Col md={1}>
            <LinkBtn variant="circle-dark" className="btn-circle" block to="/groupings?tab=syndicates">
              <Icon icon="chevron-back" />
            </LinkBtn>
          </Col>
          <Col />
          <Col md={2}>
            <LinkBtn variant="warning" block to={`/syndicates/${syndicate.id}/edit`}>
              Editar
            </LinkBtn>
          </Col>
        </Row>
        <SyndicateInfo syndicate={syndicate} />
      </>
    );
  }
}

export default connect()(SlideShow);
