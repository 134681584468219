import React from 'react';
import memoize from 'memoize-one';
import { Button } from 'react-bootstrap';
import { ApprovementStatus, DatatableEmployeeName } from '../../components';

export default memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'document_employee_name',
    cell: item => {
      return <DatatableEmployeeName item={item?.document_employee_name} />;
    },
    sortable: true,
    grow: '2',
    align: 'left'
  },
  {
    name: 'TIPO DOCUMENTO',
    selector: 'translated_workflow_type',
    sortable: true,
    grow: '2'
  },
  {
    name: 'DOCUMENTO',
    cell: item => {
      return item.document.file_name;
    },
    selector: 'name',
    sortable: true,
    grow: '1',
    align: 'left'
  },
  {
    name: 'FECHA',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'GENERADO POR',
    cell: item => {
      return <DatatableEmployeeName item={item.employee} />;
    },
    selector: 'employees',
    sortable: false,
    grow: '2',
    align: 'left'
  },
  {
    name: 'ESTADO',
    cell: item => {
      return <ApprovementStatus status={item.status} />;
    },
    accessor: 'status',
    sortable: false,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => {
      return (
        <Button variant="pw" block onClick={() => clickHandler(item, 'show')} size="sm">
          Gestionar
        </Button>
      );
    },
    ignoreRowClick: true,
    right: true,
    grow: '1',
    minWidth: '123px'
  }
]);
