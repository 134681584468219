import React from 'react';
import Icon from '../../../components/Icons';
import { BasicDropdown } from '../../../components';

const MassiveActions = ({ handleClick, disabled, isArial = false }) => {
  const actions = () => {
    const authorizedActions = [];
    authorizedActions.push({
      icon: <Icon icon="download-2" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'download-2',
      props: {
        onClick: () => handleClick('download_zip'),
        disabled
      },
      title: 'Descargar'
    });

    authorizedActions.push({
      icon: <Icon icon="paper-plane-outline" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'email',
      props: {
        onClick: () => handleClick('email'),
        disabled
      },
      title: 'Enviar al correo'
    });

    authorizedActions.push({
      icon: <Icon icon="paper-plane-outline" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'request-sign',
      props: {
        onClick: () => handleClick('request-sign'),
        disabled
      },
      title: 'Enviar al flujo'
    });

    return authorizedActions;
  };

  return (
    <div className="d-flex mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className={`align-middle ${isArial ? 'arial-dt' : ''}`}>
            Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
};

export default MassiveActions;
