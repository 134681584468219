import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import './style.scss';

const RemoteFormikRutSuggest = ({
  request,
  onChange,
  fieldValue,
  label,
  placeholder,
  error,
  touched,
  name,
  setFieldValue
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState(fieldValue || '');

  const onSuggestionsFetchRequested = ({ value }) => {
    request(value, fetchedSuggestions => {
      setSuggestions(fetchedSuggestions || []);
    });
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = suggestion => suggestion;

  const renderSuggestion = suggestion => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="suggestion-container" onClick={() => setFieldValue(name, suggestion.replace(/\./g, ''))}>
      {suggestion}
    </div>
  );

  const handleChange = (e, { newValue }) => {
    setInputValue(newValue);
    onChange(newValue);
  };

  const inputProps = {
    placeholder,
    value: inputValue,
    onChange: handleChange,
    className: 'form-control'
  };

  return (
    <Form.Group className={`${error && touched ? 'is-invalid' : ''}`}>
      {label && (
        <>
          <Form.Label className="d-flex align-items-center">
            <span>{label}</span>{' '}
          </Form.Label>
        </>
      )}
      <InputGroup>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          containerProps={{ className: 'autosuggest-normal' }} // class to style the container
        />
      </InputGroup>
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form.Group>
  );
};

RemoteFormikRutSuggest.propTypes = {
  request: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  fieldValue: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool
};

RemoteFormikRutSuggest.defaultProps = {
  label: null,
  placeholder: null,
  error: null,
  touched: false
};

export default RemoteFormikRutSuggest;
