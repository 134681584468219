import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { WorkflowTimeline, ComponentDataTable, DefaultModal, Reminder, SimpleCenteredModal } from '../../components';
import { debounceIndexDocumentsRequest, updateDocumentRequest } from '../../requests/documents';
import { deleteDirectoryFileRequest } from '../../requests/directoryNodes';
import { deleteWorkflowRequestRequest, resendNotificationRequest } from '../../requests/workflowRequests';
import { downloadFile, delayMethod } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import WorkflowRequestNew from '../WorkflowRequest/WorkflowRequestNew';
import columns from './Columns';
import DocumentPreview from '../DocumentPreview/DocumentPreview';
import DocumentFilter from './DocumentFilter';
import DocumentMassiveActions from '../../components/DatatableActions/DocumentMassiveActions';
import './Style.scss';
import WorkflowRequestMassiveNew from '../WorkflowRequest/WorkflowRequestMassiveNew';
import GenerateDocuments from './GenerateDocuments';
import internalDocumentation from '../RiskPreventionDocument/internalDocumentation';
import createTemplatePdfRequest from '../../requests/templatePdfs';
import ObservationForm from '../Shared/Document/ObservationForm';

const DocumentsDatatable = ({
  moreData,
  setMoreData,
  customParams,
  setCustomParams,
  employeeSearch,
  advanceFilters,
  allowedActions,
  rrhh = false
}) => {
  const [documents, setDocuments] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [query, setQuery] = useState({});
  const [amount, setAmount] = useState(0);
  const [onRequest, setOnRequest] = useState(true);
  const [reminderItem, setReminderItem] = useState({});
  const [reminderShow, setReminderShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalActions, setModalActions] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalSize, setModalSize] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState('');
  const [defaultModalConfirmAction, setDefaultModalConfirmAction] = useState('');
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [documentModalBody, setDocumentModalBody] = useState('');
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [isRequestSign, setIsRequestSign] = useState(false);
  const [isMassiveRequestSign, setMassiveIsRequestSign] = useState(false);

  const dispatch = useDispatch();

  const handleRequest = async params => {
    setOnRequest(true);
    const localParams = { ...params };
    if (rrhh === false) {
      localParams.documents_filter_guidelines = JSON.stringify({
        work_contract: ['approved'],
        contract_annex: ['approved'],
        salary_settlement: ['approved'],
        work_settlement: ['approved'],
        reprimand_document: ['approved'],
        congratulation_letter: ['approved']
      });
    }
    setQuery({ ...localParams, ...customParams });

    debounceIndexDocumentsRequest({
      dispatch,
      format: '',
      params: { ...params, ...customParams },
      successCallback: response => {
        const { data, metadata } = response.data;
        setDocuments(data);
        setAmount(metadata.amount);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleSuccessRequest = (message, clearRow = false) => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    setModalShow(false);
    setOnRequest(false);
    if (clearRow) {
      setClearSelectedRows(!clearSelectedRows);
    }
  };

  const handleErrorRequest = message => {
    dispatch(sendAlert({ kind: 'error', message }));
    setMoreData(!moreData);
    setModalShow(false);
    setOnRequest(false);
    setClearSelectedRows(!clearSelectedRows);
  };

  const removeDirectoryFile = documentFile => {
    const folderId = documentFile.directory_node_id;
    const documentId = documentFile.id;
    delayMethod(() => setModalShow(false), 160);
    setOnRequest(true);
    deleteDirectoryFileRequest({
      params: {
        documents: [
          {
            directory_node_id: folderId,
            document_id: documentId,
            employee_id: documentFile.employee.id
          }
        ]
      },
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Archivo eliminado con éxito' }));
        setMoreData(!moreData);
      },
      failureCallback: error => {
        const { response } = error;

        let errorMessage = response.statusText;
        if (response.data && response.data.message) {
          errorMessage = response.data.message;
        }

        setModalTitle(
          <span role="img" aria-label="">
            {' '}
            ¡UPS! &#128559;{' '}
          </span>
        );
        setModalBody(errorMessage);
        setModalActions(false);
        setModalShow(true);
      },
      callback: () => setOnRequest(false)
    });
  };

  const removeDocumentWorkflow = document => {
    const workflowId = document.workflow_request_id;

    delayMethod(() => setModalShow(false), 160);
    setOnRequest(true);
    deleteWorkflowRequestRequest([workflowId], {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Flujo eliminado con éxito' }));
        setMoreData(!moreData);
      },
      callback: () => setOnRequest(false)
    });
  };

  const removeDocumentWorkflowMassive = () => {
    const workflowIds = selectedRows.filter(item => item.status === 'pending').map(item => item.workflow_request_id);
    setOnRequest(true);
    if (workflowIds.length > 0) {
      deleteWorkflowRequestRequest(workflowIds, {
        dispatch,
        successCallback: () => {
          dispatch(sendAlert({ kind: 'success', message: 'Los flujos fueron cancelados con éxito' }));
          setMoreData(!moreData);
          setModalShow(false);
          setClearSelectedRows(!clearSelectedRows);
        },
        callback: () => setOnRequest(false)
      });
    } else {
      dispatch(sendAlert({ kind: 'error', message: 'No se encontraron flujos que cancelar' }));
      setModalShow(false);
      setOnRequest(false);
      setClearSelectedRows(!clearSelectedRows);
    }
  };

  const handleDestroy = () => {
    const documentsSelected = selectedRows.map(item => ({
      document_id: item.id,
      directory_node_id: item.directory_node_id,
      employee_id: item.employee.id
    }));

    setDisabled(true);
    setOnRequest(true);
    deleteDirectoryFileRequest({
      params: { documents: documentsSelected },
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Archivo eliminado con éxito' }));
        setMoreData(!moreData);
        setModalShow(false);
        setDisabled(false);
        setClearSelectedRows(!clearSelectedRows);
      },
      failureCallback: error => {
        const { response } = error;

        let errorMessage = response.statusText;
        if (response.data && response.data.message) {
          errorMessage = response.data.message;
        }

        setModalTitle(
          <span role="img" aria-label="">
            {' '}
            ¡UPS! &#128559;{' '}
          </span>
        );
        setModalBody(errorMessage);
        setModalActions(false);
        setModalShow(true);
        setMoreData(!moreData);
        setDisabled(false);
        setClearSelectedRows(!clearSelectedRows);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleFailureRequest = error => {
    const { response } = error;
    if (!response) {
      dispatch(sendAlert({ kind: 'error', message: error.message }));
    } else {
      let errorMessage = response.statusText;
      if (response.data && response.data.message) {
        errorMessage = response.data.message;
      }
      dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    }
    setOnRequest(false);
    setDocumentModalShow(false);
    setModalShow(false);
  };

  const handleExportRequest = () => {
    setOnRequest(true);
    const ids = selectedRows.map(item => item.id);
    debounceIndexDocumentsRequest({
      dispatch,
      format: '.xlsx',
      params: { paginate: false, ...query, filter_ids: ids },

      successCallback: response => {
        downloadFile(response);
        setOnRequest(false);
        setModalShow(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleCreateObservationDocument = document => {
    setOnRequest(true);
    const documentId = document.document.id;
    const employeeId = document.document.employee.id;

    updateDocumentRequest(documentId, {
      employeeId,
      dispatch,
      params: {
        ...document
      },
      successCallback: () => handleSuccessRequest('Observacion guardada con éxito')
      // callback: defaultCallback
    });
  };

  const resendNotificationMassive = () => {
    const workflowIds = selectedRows.filter(item => item.status === 'pending').map(item => item.workflow_request_id);
    setOnRequest(true);
    if (workflowIds.length > 0) {
      resendNotificationRequest(workflowIds, {
        dispatch,
        successCallback: () => {
          setMoreData(!moreData);
          setClearSelectedRows(!clearSelectedRows);
          dispatch(sendAlert({ kind: 'success', message: 'Recordatorio enviado con éxito.' }));
        },
        callback: () => setOnRequest(false)
      });
    } else {
      setMoreData(!moreData);
      dispatch(
        sendAlert({ kind: 'error', message: 'Recordatorio no enviado: Los documentos seleccionados no tienen flujo' })
      );
      setOnRequest(false);
      setClearSelectedRows(!clearSelectedRows);
    }
    setModalShow(false);
  };
  const handleDefaultModalConfirmAction = (item, action) => {
    if (item.has_pending_annexes && isRequestSign) {
      setModalTitle(`Enviar a firmar ${item.file_info.clean_filename}`);
      setModalBody(
        <WorkflowRequestNew
          document={camelCaseRecursive(item)}
          workflowType={item.documentType}
          handleModalClose={() => setModalShow(false)}
          handleSuccessWorkflowRequest={handleSuccessRequest}
        />
      );
      setModalActions(false);
      setModalSize('xl');
      setModalShow(true);
      return;
    }

    if (isMassiveRequestSign) {
      const haveWorkflow = selectedRows.filter(i => i.status !== 'not_signed');
      const noHaveWorkflowNotSigned = selectedRows.filter(i => i.status === 'not_signed');
      setModalTitle('ENVIAR A FIRMA O APROBACIÓN DOCUMENTOS');
      setModalBody(
        <WorkflowRequestMassiveNew
          documentWithWorkflow={haveWorkflow}
          documents={camelCaseRecursive(noHaveWorkflowNotSigned)}
          handleModalClose={() => setModalShow(false)}
          handleSuccessWorkflowRequest={handleSuccessRequest}
          handleErrorRequest={handleErrorRequest}
          setMoreData={setMoreData}
          moreData={moreData}
        />
      );
      setModalActions(false);
      setModalShow(true);
      setModalSize('xl');
      setDefaultModalConfirmAction('request-sign');
      return;
    }

    switch (action) {
      case 'remove':
        removeDirectoryFile(item);
        break;
      case 'remove-workflow':
        removeDocumentWorkflow(item);
        break;
      case 'destroy_file':
        handleDestroy();
        break;
      case 'remove-workflow-massive':
        removeDocumentWorkflowMassive();
        break;
      case 'resend':
        resendNotificationMassive();
        break;
      case 'export':
        handleExportRequest();
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleButtonClick = (item, action) => {
    setIsRequestSign(false);
    switch (action) {
      case 'show':
        setDocumentModalBody(<DocumentPreview documentId={item.id} employeeId={item.employee?.id} />);
        setDocumentModalShow(true);
        break;
      case 'download':
        window.open(item.file_info.file_url, '_blank');
        break;
      case 'remove':
        setModalTitle('Eliminar Archivo');
        setModalBody(`¿Estás seguro que deseas eliminar ${item.file_info.filename}?`);
        setModalItem(item);
        setDefaultModalConfirmAction('remove');
        setModalSize('');
        setModalActions(true);
        setModalShow(true);
        break;
      case 'remove-workflow':
        setModalTitle('Cancelar Flujo');
        setModalBody(`¿Estás seguro que deseas cancelar el flujo del documento ${item.file_info.filename}?`);
        setModalItem(item);
        setDefaultModalConfirmAction('remove-workflow');
        setModalSize('');
        setModalActions(true);
        setModalShow(true);
        break;
      case 'show-workflow':
        setModalTitle('Detalles del flujo');
        setModalBody(
          <WorkflowTimeline
            workflowId={item.workflow_request_id}
            document={camelCaseRecursive(item)}
            handleClose={() => setModalShow(false)}
          />
        );
        setModalActions(false);
        setModalSize('lg');
        setModalShow(true);
        break;
      case 'resend':
        setReminderShow(true);
        setReminderItem(item);
        break;
      case 'request-sign':
        setIsRequestSign(true);
        setModalItem(item);
        if (item.has_pending_annexes) {
          setModalTitle(`Anexos de contratos`);
          setModalBody(
            <>
              <p>
                El trabajador {item.employee.full_name} tiene los siguientes anexos de contrato pendientes de
                firma/aprobación:
              </p>
              <br />
              <ul>
                {item.pending_annexes.map(document => (
                  <li>{document.file_name}</li>
                ))}
              </ul>
              <br />
              <p> ¿Estás seguro que deseas continuar? </p>
            </>
          );
          setModalActions(true);
          setModalSize('md');
          setModalShow(true);
          break;
        } else {
          setModalTitle(`Enviar a firmar ${item.file_info.clean_filename}`);
          setModalBody(
            <WorkflowRequestNew
              document={camelCaseRecursive(item)}
              workflowType={item.documentType}
              handleModalClose={() => setModalShow(false)}
              handleSuccessWorkflowRequest={handleSuccessRequest}
            />
          );
          setModalActions(false);
          setModalSize('xl');
          setModalShow(true);
        }
        break;
      case 'export':
        setModalTitle('Exportar Reporte de Firma');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas exportar reporte de firma?');
        setModalActions(() => handleExportRequest);
        break;
      case 'observation':
        setModalTitle('Observación');
        setModalShow(true);
        setModalBody(
          <ObservationForm
            rrhh
            document={camelCaseRecursive(item)}
            documentId={item.id}
            employeeId={item.employee?.id}
            formRequest={handleCreateObservationDocument}
            handleModalClose={() => setModalShow(false)}
          />
        );
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleDownload = () => {
    setOnRequest(true);
    const ids = selectedRows.map(item => item.id);
    debounceIndexDocumentsRequest({
      dispatch,
      params: { ...query, filter_ids: ids },
      format: '.zip',
      responseType: 'blob',
      successCallback: downloadFile,
      callback: () => {
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleDocumentModalClose = () => {
    setMoreData(!moreData);
    setDocumentModalShow(false);
  };

  const handleSuccessPdf = setSubmitting => {
    setModalShow(false);
    setSubmitting(false);
    setOnRequest(false);
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'success', message: 'Documentos generados con éxito' }));
  };

  const handleFailurePdf = (error, setSubmitting) => {
    const { response } = error;
    if (!response) {
      dispatch(sendAlert({ kind: 'error', message: error.message }));
    } else {
      let errorMessage = response.statusText;
      if (response.data && response.data.message) {
        errorMessage = response.data.message.split('. ');
      }
      setOnRequest(false);
      setSubmitting(false);
      setModalShow(false);
      setMoreData(!moreData);
      setModalTitle(
        <span role="img" aria-label="">
          {' '}
          ¡UPS! &#128559;{' '}
        </span>
      );
      setModalBody(
        <ul>
          {errorMessage.map(message => (
            <li>{message}</li>
          ))}
        </ul>
      );
      setModalActions(false);
      setModalSize('md');

      setModalShow(true);
    }
  };

  const createPdfRequest = (params, setSubmitting) => {
    createTemplatePdfRequest({
      dispatch,
      params,
      formData: true,
      successCallback: () => handleSuccessPdf(setSubmitting),
      failureCallback: e => handleFailurePdf(e, setSubmitting)
    });
  };
  const handleGenerateDocument = (values, setSubmitting) => {
    let myParams = snakeCaseKeys(values);
    const { templatePdf } = values;
    if (templatePdf.templateId === 'work_contract') {
      myParams = { template_pdf: { employee_ids: templatePdf.employeeIds, document_type: 'work_contract' } };
    }
    setOnRequest(true);
    createPdfRequest(myParams, setSubmitting);
  };
  const handleFilter = ({
    filterEmployeeNationalId = '',
    filterDocumentType = '',
    filterDocumentStatus = '',
    dateFrom = '',
    dateTo = '',
    filterActiveJobManagements = [],
    filterActiveJobTitles = [],
    filterActiveCostCenters = [],
    filterActiveBranchOffices = []
  }) => {
    const filterCustomParams = {
      filter_by_employee_national_id: filterEmployeeNationalId,
      document_type: filterDocumentType,
      status: filterDocumentStatus,
      date_from: dateFrom,
      date_to: dateTo,
      filter_active_job_managements: filterActiveJobManagements,
      filter_active_job_titles: filterActiveJobTitles,
      filter_active_cost_centers: filterActiveCostCenters,
      filter_active_branch_offices: filterActiveBranchOffices
    };

    setCustomParams(old => ({ ...old, ...filterCustomParams }));
    setMoreData(!moreData);
  };

  const handleMassAction = action => {
    const noHaveWorkflow = selectedRows.filter(item => item.status !== 'pending');
    const haveWorkflowPending = selectedRows.filter(item => item.status === 'pending');
    const noHaveWorkflowNotSigned = selectedRows.filter(item => item.status === 'not_signed');
    const haveWorkflow = selectedRows.filter(item => item.status !== 'not_signed');
    const employeeSet = new Set();
    const massivePendingAnnexes = selectedRows.filter(item => {
      if (item.has_pending_annexes) {
        const isUniqueEmployee = !employeeSet.has(item.employee.id);
        if (isUniqueEmployee) {
          employeeSet.add(item.employee.id);
          return true;
        }
      }
      return false;
    });

    setMassiveIsRequestSign(false);
    switch (action) {
      case 'download':
        handleDownload();
        break;
      case 'destroy':
        setModalTitle('Eliminar Documentos');

        setModalBody(
          <>
            <p>Usted ha seleccionado los siguientes documentos para ser eliminados:</p>
            <ul>
              {selectedRows.map(document => (
                <li>{document.file_info.filename}</li>
              ))}
            </ul>
            <p>¿Esta seguro que desea continuar?. Una vez ejecutada esta acción no podrá revestirlo</p>
          </>
        );
        setModalActions(true);
        setModalShow(true);
        setModalSize('');
        setDefaultModalConfirmAction('destroy_file');
        break;
      case 'remove-workflow-massive':
        setModalTitle('Cancelar Flujos');
        setModalBody(
          <>
            {noHaveWorkflow.length > 0 && (
              <>
                <p>Los siguientes documentos no poseen flujos de trabajos:</p>
                <ul>
                  {' '}
                  {noHaveWorkflow.map(document => (
                    <li>{document.file_info.filename}</li>
                  ))}{' '}
                </ul>
              </>
            )}
            <p>¿Esta seguro que desea continuar?. Una vez ejecutada esta acción no podrá revestirlo</p>
          </>
        );

        setModalActions(true);
        setModalShow(true);
        setModalSize('');
        setDefaultModalConfirmAction('remove-workflow-massive');
        break;
      case 'resend':
        setModalTitle('Enviar recordatorios');
        setModalBody(
          <>
            {noHaveWorkflow.length > 0 && (
              <>
                <p>Los siguientes documentos no poseen flujos de trabajos:</p>
                <ul>
                  {noHaveWorkflow.map(document => (
                    <li>{document.file_info.filename}</li>
                  ))}
                </ul>
              </>
            )}
            {haveWorkflowPending.length > 0 && (
              <>
                <p>Este recordatorio se enviará a las siguientes personas:</p>
                <ul>
                  {haveWorkflowPending.map(document => (
                    <li>
                      {document.request_reviewer_names} ({document.file_info.filename})
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        );
        setModalActions(true);
        setModalShow(true);
        setModalSize('lg');
        setDefaultModalConfirmAction('resend');
        break;
      case 'request-sign':
        setMassiveIsRequestSign(true);
        setModalItem(massivePendingAnnexes);
        if (massivePendingAnnexes.length > 0) {
          setModalTitle(`Anexos de contratos`);
          const isSingleWorker = massivePendingAnnexes.length === 1;
          const workerText = isSingleWorker ? 'El siguiente trabajador tiene' : 'Los siguientes trabajadores tienen';

          setModalBody(
            <>
              <p>{`${workerText} anexos de contratos pendientes:`}</p>
              <br />
              <ul>
                {massivePendingAnnexes.map(item => (
                  <li key={item.id}>{item.employee.full_name}</li>
                ))}
              </ul>
              <p>¿Estás seguro que deseas continuar?</p>
            </>
          );
          setModalActions(true);
          setModalSize('md');
          setModalShow(true);
          break;
        } else {
          setModalTitle('ENVIAR A FIRMA O APROBACIÓN DOCUMENTOS');
          setModalBody(
            <WorkflowRequestMassiveNew
              documentWithWorkflow={haveWorkflow}
              documents={camelCaseRecursive(noHaveWorkflowNotSigned)}
              handleModalClose={() => setModalShow(false)}
              handleSuccessWorkflowRequest={handleSuccessRequest}
              handleErrorRequest={handleErrorRequest}
              setMoreData={setMoreData}
              moreData={moreData}
            />
          );
          setModalActions(false);
          setModalShow(true);
          setModalSize('xl');
          setDefaultModalConfirmAction('request-sign');
        }
        break;
      case 'generate-documents':
        setModalTitle('GENERAR DOCUMENTOS');
        setModalBody(
          <GenerateDocuments
            action="new"
            onRequest={onRequest}
            templatePdf={internalDocumentation}
            formRequest={handleGenerateDocument}
          />
        );
        setModalActions(false);
        setModalShow(true);
        setModalSize('xl');
        setDefaultModalConfirmAction('generate-documents');
        break;
      case 'export':
        setModalActions(true);
        setModalTitle('Exportar Reporte de Firma');
        setModalBody('¿Estás seguro que deseas exportar reporte de Firma?');
        setDefaultModalConfirmAction('export');
        setModalShow(true);

        break;
      case 'observation':
        setModalTitle('Observación');
        setModalBody(<>{haveWorkflowPending.length > 0 && <></>}</>);
        setModalActions(true);
        setModalShow(true);
        setModalSize('lg');
        setDefaultModalConfirmAction('resend');
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  return (
    <>
      {advanceFilters && (
        <>
          <DocumentFilter formRequest={handleFilter} />
        </>
      )}
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, allowedActions)}
        data={documents}
        selectableRows
        totalRows={amount}
        moreData={moreData}
        resourceRequest={handleRequest}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
        withMassActions={rrhh}
        massActions={
          <>
            <DocumentMassiveActions handleClick={handleMassAction} disabled={!selectedCount} />
          </>
        }
        withSearch={!employeeSearch}
        preName="documents"
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleSelectedRows}
      />
      <Reminder
        item={reminderItem}
        show={reminderShow}
        modalShow={setReminderShow}
        moreData={moreData}
        setOnRequest={setOnRequest}
        setMoreData={setMoreData}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => handleDefaultModalConfirmAction(modalItem, defaultModalConfirmAction)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        modalSize={modalSize}
        withClose={modalActions}
        withConfirm={modalActions}
        disabled={onRequest || disabled}
      />
      <SimpleCenteredModal
        body={documentModalBody}
        onHide={handleDocumentModalClose}
        show={documentModalShow}
        size="xl"
      />
    </>
  );
};

export default DocumentsDatatable;
