import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import moment from 'moment';
import { FormikTimePicker } from '../../components';

const LoanProcessForm = ({ onHide, submitVariant, errors, touched, setFieldValue, isSubmitting }) => {
  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <Field name="loanProcess[selectedDate]">
              {({ field }) => (
                <FormikTimePicker
                  abbr
                  label="Mes y Año de proceso"
                  placeholder="Seleccionar mes y año de proceso"
                  dateFormat="MMMM - yyyy"
                  showMonthYearPicker
                  setLocale
                  onChange={date => {
                    setFieldValue(field.name, date);
                    if (!date) return;
                    moment.locale('en');
                    const month = moment(date)
                      .format('MMMM')
                      .toLowerCase();
                    const year = moment(date).format('YYYY');
                    setFieldValue('loanProcess[month]', month);
                    setFieldValue('loanProcess[year]', year);
                  }}
                  selected={field.value}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn" variant={submitVariant} onClick={onHide} disabled={isSubmitting}>
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { currentYear } = props;
  return {
    loanProcess: {
      year: currentYear,
      month: '',
      selectedDate: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  loanProcess: Yup.object().shape({
    year: Yup.number().required('Debes ingresar un año'),
    month: Yup.string().required('Debes ingresar un mes'),
    selectedDate: Yup.string().required('Debes ingresar un mes y año de proceso')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(LoanProcessForm);
