import React, { useContext, useState } from 'react';
import { useAbility } from '@casl/react';
import { useDispatch, useSelector } from 'react-redux';
import { ComponentDataTable } from '../../../../components';
import { AbilityContext } from '../../../../config/abilityContext';
import ColumnInstallments from '../Columns/ColumnInstallments';
import MassiveActionsInstallments from './MassiveActionsInstallments';
import { exportLoanInstallments, indexLoanInstallmentsRequest } from '../../../../requests/payrollLoanProcesses';
import { PayrollProcessContext } from '../../context/PayrollProcessProvider';
import { downloadFile } from '../../../../services/utils';

const LoanProcessesDataTable = ({ selectedRows }) => {
  const dispatch = useDispatch();
  const { payrollProcess } = useContext(PayrollProcessContext);
  const { currentCompany } = useSelector(state => state.auth);
  const ability = useAbility(AbilityContext);
  const [loanProcessesData, setLoanProcessesData] = useState([]);
  const [amount, setAmount] = useState(0);
  const [onRequest, setOnRequest] = useState(false);
  const [moreData, setMoreData] = useState(false);

  const selectedProcesses = selectedRows.map(({ id }) => id);

  const handlePayrollLoanInstallments = params => {
    setOnRequest(true);

    indexLoanInstallmentsRequest({
      dispatch,
      params: {
        filter_company_id: currentCompany.id,
        filter_process_id: selectedProcesses,
        payroll_process_id: payrollProcess.id,
        ...params
      },
      successCallback: ({ data }) => {
        setLoanProcessesData(data.data);
        setAmount(data.metadata.amount);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleMassAction = action => {
    switch (action) {
      case 'download_installments':
        setOnRequest(true);
        exportLoanInstallments({
          dispatch,
          params: {
            filter_company_id: currentCompany.id,
            filter_process_id: selectedProcesses,
            payroll_process_id: payrollProcess.id,
            paginate: false
          },
          successCallback: response => {
            setMoreData(moreData);
            downloadFile(response);
          },
          failureCallback: error => {
            // eslint-disable-next-line no-console
            console.warn(error);
          },
          callback: () => setOnRequest(false)
        });
        break;

      default:
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'month':
        return { sort_month: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <ComponentDataTable
      onRequest={onRequest}
      columns={ColumnInstallments(ability)}
      data={loanProcessesData}
      totalRows={amount}
      handleSortCase={sortColumnCase}
      moreData={moreData}
      resourceRequest={handlePayrollLoanInstallments}
      pointerOnHover
      withMassActions
      withSearch={false}
      massActions={<MassiveActionsInstallments disabled={onRequest} handleMassAction={handleMassAction} />}
    />
  );
};

export default LoanProcessesDataTable;
