import React from 'react';
import { Row, Col } from 'react-bootstrap';

function OnlyInformation({ title = '', subtitle = '', TopHeaderColor = '', onRequest = false }) {
  const percentage = subtitle;

  const styling = {
    stroke: TopHeaderColor,
    strokeDasharray: `${101} 100`,
    animationName: 'Fill', // Nombre del keyframe
    animationDuration: '2s', // Duración de la animación
    animationTimingFunction: 'linear',
    animationIterationCount: 'forwards'
  };

  return (
    <>
      {!onRequest && (
        <div style={{ height: '145px' }}>
          <Row style={{ alignItems: 'center', paddingRight: 20, paddingLeft: 20 }}>
            <Col md={4} xs={4} xl={4}>
              <div className="Percentage">
                <svg width="110" height="110" style={{ paddingRight: 25 }}>
                  <circle r="30%" cx="50%" cy="50%" pathLength="100" style={{ stroke: '#adadad' }} />
                  <circle r="30%" cx="50%" cy="50%" pathLength="100" style={styling} />
                </svg>
                <span style={{ color: TopHeaderColor, alignSelf: 'center' }}>{title}</span>
              </div>
            </Col>
            <Col xs={8} md={8} xl={8}>
              <p style={{ fontSize: '18px', fontWeight: 'bold', color: 'black' }}>Colaboradores</p>
            </Col>
            <Col className="CenteredDiv mb-2" xs={12} md={12} xl={12}>
              <div className="Subtitle" style={{ color: TopHeaderColor }}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', color: TopHeaderColor }}>
                  {percentage} {'  '}% De la dotación
                </p>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default OnlyInformation;
