import React from 'react';
import PropTypes from 'prop-types';
import { useAbility } from '@casl/react';
import { Button } from 'react-bootstrap';
import { AbilityContext } from '../../config/abilityContext';

const ShiftChangeStyledActions = ({ item, clickHandler, resource, approveText, withDestroy }) => {
  const ability = useAbility(AbilityContext);

  const hasPermission = ability.can('shift_change_action', resource);

  const canDestroy = ability.can('destroy', resource);

  if (item.status === 'pending') {
    if (hasPermission) {
      return (
        <>
          <Button variant="pw" className="mx-sm-2" onClick={() => clickHandler('approved')}>
            {approveText}
          </Button>
          <Button variant="danger" className="mx-sm-2" onClick={() => clickHandler('rejected')}>
            Rechazar
          </Button>
        </>
      );
    }
    return 'No tienes permisos para esta acción';
  }
  if (canDestroy && withDestroy && (item.status === 'approved' || item.status === 'rejected')) {
    return (
      <Button variant="danger" className="mx-sm-2" onClick={() => clickHandler('destroy')}>
        Eliminar
      </Button>
    );
  }
  return '';
};

ShiftChangeStyledActions.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  clickHandler: PropTypes.func.isRequired,
  approveText: PropTypes.string,
  disabled: PropTypes.bool,
  withDestroy: PropTypes.bool
};

ShiftChangeStyledActions.defaultProps = {
  approveText: 'Aceptar',
  disabled: false,
  withDestroy: false
};

export default ShiftChangeStyledActions;
