import React, { Component } from 'react';
import { connect } from 'react-redux';

import { downloadFile } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal, ImportActions, ImportModal } from '../../components';
import BookkeeperDocumentTypeActions from '../../components/DatatableActions/BookkeeperDocumentTypeActions';
import columns from './columns';
import {
  debounceIndexBookkeeperDocumentTypesRequest,
  deleteBookkeeperDocumentTypeRequest,
  exportBookkeeperDocumentTypeRequest,
  importBookkeeperDocumentTypeRequest,
  importTemplateBookkeeperDocumentTypeRequest,
  preImportBookkeeperDocumentTypeRequest,
  deactivateTemplateBookkeeperDocumentTypeRequest,
  activateTemplateBookkeeperDocumentTypeRequest,
  massDeleteBookkeeperDocumentTypeRequest
} from '../../requests/bookkeepersDocumentTypes';

class BookkeeperDocumentType extends Component {
  state = {
    onRequest: true,
    bookkeeperDocumentTypes: [],
    amount: 0,
    modalShow: false,
    modalItem: null,
    modalBody: '',
    simpleModalShow: false,
    query: {},
    modalAction: () => null,
    modalTitle: '',
    selectedRows: [],
    selectedCount: '',
    clearSelectedRows: false
  };

  handleRequest = async params => {
    const { dispatch } = this.props;
    this.setState({
      onRequest: true,
      query: params
    });
    debounceIndexBookkeeperDocumentTypesRequest({
      dispatch,
      params,
      successCallback: this.handleSuccessIndex
    });
  };

  handleSuccessIndex = response => {
    const bookkeeperDocumentTypes = response.data.data;
    const { amount } = response.data.metadata;
    this.setState({
      bookkeeperDocumentTypes,
      amount,
      onRequest: false
    });
  };

  removeBookkeeperDocumentType = bookkeeperDocumentTypeId => {
    const { dispatch } = this.props;
    this.setState({
      onRequest: true
    });
    deleteBookkeeperDocumentTypeRequest(bookkeeperDocumentTypeId, {
      dispatch,
      successCallback: this.handleSuccessRemove('Tipo de Documento Contable Eliminado Correctamente')
    });
  };

  handleButtonClick = (item, action) => {
    const { editBookkeeperDocumentType, showBookkeeperDocumentType } = this.props;
    switch (action) {
      case 'show':
        showBookkeeperDocumentType(item);
        break;
      case 'edit':
        editBookkeeperDocumentType(item);
        break;
      case 'destroy':
        this.setState({
          modalTitle: 'Eliminar Tipo de Documento',
          modalShow: true,
          modalItem: item,
          modalBody: '¿Estás seguro que deseas eliminar este tipo de documento?',
          modalAction: this.handleModalConfirm
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  handleImportModalExceptions = error => {
    const { dispatch } = this.props;
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      this.setState({
        modalTitle: 'Información Relevante',
        modalShow: true,
        modalBody: alertInfo,
        modalAction: this.handleModalClose
      });
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  handleModalClose = () => {
    this.setState({
      modalShow: false,
      simpleModalShow: false,
      onRequest: false
    });
  };

  handleModalConfirm = () => {
    const { modalItem } = this.state;
    this.removeBookkeeperDocumentType(modalItem.id);
  };

  handleSuccessRemove = message => {
    const { setMoreData, moreData, dispatch } = this.props;
    this.handleModalClose();
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  handleMassAction = action => {
    switch (action) {
      case 'import':
        this.setState({ simpleModalShow: true });
        break;
      case 'export':
        this.setState({
          modalTitle: 'Exportar tipos de documentos ',
          modalShow: true,
          modalBody: '¿Estás seguro que deseas exportar los tipos de documentos?',
          modalAction: this.handleExportRequest
        });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  handleDestroy = () => {
    const { selectedRows, clearSelectedRows } = this.state;
    const { setMoreData, moreData } = this.props;
    const documentsSelected = selectedRows.map(item => ({
      document_id: item.id
    }));

    const { dispatch } = this.props;
    this.setState({
      onRequest: true
    });
    massDeleteBookkeeperDocumentTypeRequest({
      params: { documents: documentsSelected },
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Archivos eliminados con éxito' }));
        this.setState({
          onRequest: false,
          clearSelectedRows: !clearSelectedRows
        });
      },
      callback: () => {
        this.setState({ onRequest: false, modalShow: false });
        setMoreData(!moreData);
      }
    });
  };

  handleMassActivate = () => {
    const { selectedRows, clearSelectedRows } = this.state;
    const { setMoreData, moreData, dispatch } = this.props;
    this.setState({
      onRequest: true
    });
    let documentTypeSelected = [...selectedRows];
    const myParams = { document_types_ids: documentTypeSelected.map(documentType => documentType.id) };
    activateTemplateBookkeeperDocumentTypeRequest({
      dispatch,
      params: myParams,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Acción realizada con éxito' }));
        this.setState({
          onRequest: false,
          clearSelectedRows: !clearSelectedRows
        });
        documentTypeSelected = [];
        setMoreData(!moreData);
      },
      callback: () => this.setState({ onRequest: false, modalShow: false })
    });
  };

  handleMassDeactivate = () => {
    const { selectedRows, clearSelectedRows } = this.state;
    const { setMoreData, moreData, dispatch } = this.props;
    this.setState({
      onRequest: true
    });
    let documentTypeSelected = [...selectedRows];
    const myParams = { document_types_ids: documentTypeSelected.map(documentType => documentType.id) };
    deactivateTemplateBookkeeperDocumentTypeRequest({
      dispatch,
      params: myParams,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Acción realizada con éxito' }));
        this.setState({
          onRequest: false,
          clearSelectedRows: !clearSelectedRows
        });
        documentTypeSelected = [];
        setMoreData(!moreData);
      },
      callback: () => this.setState({ onRequest: false, modalShow: false })
    });
  };

  validateMassActions = action => {
    const actionTypes = { destroy: 'Eliminar', activate: 'Activar', deactivate: 'Desactivar' };
    const { selectedRows } = this.state;
    const { dispatch } = this.props;

    const hasPreloadedItems = selectedRows.some(item => item.is_preloaded);

    if (hasPreloadedItems) {
      const errorMessage = `No se pueden ${actionTypes[action]} Los Tipos de Cuenta Contable Precargados`;
      dispatch(sendAlert({ kind: 'error', message: errorMessage }));

      return false;
    }

    return true;
  };

  handleMassBookkeeperActions = action => {
    if (this.validateMassActions(action)) {
      switch (action) {
        case 'destroy':
          this.setState({
            modalTitle: 'Eliminar Tipo de Documento',
            modalShow: true,
            modalBody: '¿Estás seguro que deseas eliminar este tipo de documento?',
            modalAction: this.handleDestroy
          });
          break;
        case 'activate':
          this.setState({
            modalTitle: 'Activar Tipo de Documento',
            modalShow: true,
            modalBody: '¿Estás seguro que deseas activar este tipo de documento?',
            modalAction: this.handleMassActivate
          });
          break;
        case 'deactivate':
          this.setState({
            modalTitle: 'Desactivar Tipo de Documento',
            modalShow: true,
            modalBody: '¿Estás seguro que deseas desactivar este tipo de documento?',
            modalAction: this.handleMassDeactivate
          });
          break;
        default:
          // eslint-disable-next-line no-console
          console.log('Error: Action not found');
      }
    }
  };

  handleExportRequest = () => {
    const { query } = this.state;
    const { dispatch } = this.props;
    this.setState({ onRequest: true });
    exportBookkeeperDocumentTypeRequest({
      dispatch,
      params: query,
      successCallback: response => {
        downloadFile(response);
        this.setState({ onRequest: false, modalShow: false });
      },
      failureCallback: this.handleFailureRequest
    });
  };

  handleFailureRequest = error => {
    const { dispatch } = this.props;
    const { response } = error;
    this.handleModalClose();
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  handleSelectedRows = item => {
    this.setState({
      selectedRows: item.selectedRows,
      selectedCount: item.selectedCount
    });
  };

  render() {
    const {
      bookkeeperDocumentTypes,
      amount,
      onRequest,
      modalShow,
      modalBody,
      simpleModalShow,
      modalTitle,
      modalAction,
      selectedCount,
      clearSelectedRows
    } = this.state;
    const { moreData, setMoreData } = this.props;
    return (
      <>
        <ComponentDataTable
          onRequest={onRequest}
          columns={columns(this.handleButtonClick)}
          data={bookkeeperDocumentTypes}
          totalRows={amount}
          withMassActions
          massActions={
            <>
              <ImportActions model="BookkeeperDocumentType" handleClick={this.handleMassAction} />
              <BookkeeperDocumentTypeActions handleClick={this.handleMassBookkeeperActions} disabled={!selectedCount} />
            </>
          }
          moreData={moreData}
          resourceRequest={this.handleRequest}
          onRowClicked={item => this.handleButtonClick(item, 'show')}
          pointerOnHover
          selectableRows
          clearSelectedRows={clearSelectedRows}
          onSelectedRowsChange={this.handleSelectedRows}
        />
        <DefaultModal
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          handleClose={this.handleModalClose}
          handleConfirm={modalAction}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
          disabled={onRequest}
        />
        <SimpleCenteredModal
          title="Importar Tipos de Documentos"
          body={
            <ImportModal
              onDropUploaded={preImportBookkeeperDocumentTypeRequest}
              handleTemplate={importTemplateBookkeeperDocumentTypeRequest}
              hideModal={() => this.setState({ simpleModalShow: false })}
              onHide={importBookkeeperDocumentTypeRequest}
              updateData={() => setMoreData(!moreData)}
              handleExceptions={this.handleImportModalExceptions}
            />
          }
          show={simpleModalShow}
          onHide={() => this.setState({ simpleModalShow: false })}
        />
      </>
    );
  }
}

export default connect()(BookkeeperDocumentType);
