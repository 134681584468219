import React from 'react';
import { ButtonTooltip, Icon } from '../../../components';

const ActionButtons = ({ clickHandler, isEditing, editingId, row }) => {
  return (
    <>
      <ButtonTooltip
        onClick={() => clickHandler(row, 'edit')}
        variant="circle-warning"
        toolbarVariant=""
        style={{ zIndex: 1 }}
        className="btn-circle"
        size="sm"
        text="Editar"
        disabled={isEditing && editingId !== row?.original?.id}
      >
        <Icon icon="pencil" />
      </ButtonTooltip>
      <ButtonTooltip
        onClick={() => clickHandler(row, 'destroy')}
        variant="circle-danger"
        toolbarVariant=""
        style={{ zIndex: 1 }}
        className="btn-circle"
        size="sm"
        text="Eliminar"
        disabled={isEditing && editingId !== row?.original?.id}
      >
        <Icon icon="trash" />
      </ButtonTooltip>
    </>
  );
};

export default ActionButtons;
