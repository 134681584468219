import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon } from '../../components';
import AdvancePaymentProcessStatus from '../../components/DatatableColumns/AdvancePaymentProcessStatus';

const columns = memoize((clickHandler, ability) => [
  {
    name: 'FECHA CREACIÓN',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'NOMBRE',
    selector: 'name',
    id: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'MES',
    selector: 'parsed_month',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TRABAJADORES',
    selector: 'employees_count',
    grow: '1',
    sortable: true,
    center: true
  },
  {
    name: 'MONTO TOTAL',
    selector: 'parsed_total_amount',
    grow: '1',
    sortable: true,
    center: true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <AdvancePaymentProcessStatus item={item} type="text" />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => {
      return (
        <>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'show')}
            variant="circle-info"
            className="btn-circle"
            size="sm"
            text="Ver"
          >
            <Icon icon="eye" />
          </ButtonTooltip>

          {ability.can('update', 'AdvancePaymentProcess') && (
            <ButtonTooltip
              onClick={() => clickHandler(item, 'edit')}
              variant="circle-warning"
              className="btn-circle"
              disabled={item.status === 'Cerrado'}
              size="sm"
              text="Editar"
            >
              <Icon icon="pencil" />
            </ButtonTooltip>
          )}

          {ability.can('destroy', 'AdvancePaymentProcess') && (
            <ButtonTooltip
              onClick={() => clickHandler(item, 'destroy')}
              variant="circle-danger"
              className="btn-circle"
              disabled={item.status === 'Cerrado'}
              size="sm"
              text="Eliminar"
            >
              <Icon icon="trash" />
            </ButtonTooltip>
          )}

          {ability.can('update', 'AdvancePaymentProcess') && (
            <ButtonTooltip
              onClick={() => clickHandler(item, 'close_month')}
              variant="circle-dark"
              className="btn-circle"
              disabled={item.status === 'Cerrado'}
              size="sm"
              text="Cerrar Mes"
            >
              <Icon icon="lock" />
            </ButtonTooltip>
          )}
        </>
      );
    },
    grow: '1'
  }
]);

export default columns;
