import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import PropTypes from 'prop-types';
import VacationInfo from '../../Profile/Vacation/VacationInfo';
import { debounceIndexVacationsRequest } from '../../../requests/vacations';
import { searchEmployeesWithAvailableVacationsRequest } from '../../../requests/employees';
import { ComponentDataTable, DefaultModal, FormikSelect } from '../../../components';
import VacationInfoTable from '../../Profile/Vacation/VacationInfoTable';
import columns from './approveStyledColumns';

const VacationDataTableStyled = ({
  currentEmployee,
  defaultStatus,
  employeesBelongBoss,
  moreData,
  preName,
  setMoreData,
  showActions = true,
  todayMoment
}) => {
  const [amount, setAmount] = useState(0);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalButtons, setModalButtons] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [vacations, setVacations] = useState([]);
  const [status, setStatus] = useState(defaultStatus);
  const vacationStatus = [
    { label: 'Todos', value: '' },
    { label: 'Aprobado', value: '1' },
    { label: 'Pendiente', value: '0' },
    { label: 'Rechazado', value: '2' }
  ];

  const handleModalClose = () => {
    setModalShow(false);
    setModalTitle('');
    setModalBody('');
    setOnRequest(false);
  };

  const cleanSelectedData = () => {
    setClearSelectedRows(!clearSelectedRows);
  };

  const handleCustomParams = () => {
    if (status !== '0' && preName === 'vacations' && employeesBelongBoss.length > 0) {
      return {
        filter_vacation_exception: 'administrative_days',
        filter_employee: employeesBelongBoss
      };
    }
    if (status === '0' && preName === 'vacations') {
      return {
        filter_vacation_exception: 'administrative_days',
        pending_review: currentEmployee
      };
    }
    if (status !== '0' && preName === 'administrative_days' && employeesBelongBoss.length > 0) {
      return {
        filter_vacation_type: 'administrative_days',
        filter_employee: employeesBelongBoss
      };
    }

    if (preName === 'vacations_absences' && employeesBelongBoss.length > 0) {
      return {
        filter_employee: employeesBelongBoss,
        filter_vacation_exception: 'administrative_days'
      };
    }

    if (preName === 'administrative_absences' && employeesBelongBoss.length > 0) {
      return {
        filter_employee: employeesBelongBoss,
        filter_vacation_type: 'administrative_days'
      };
    }

    return {
      filter_vacation_type: 'administrative_days',
      review: currentEmployee
    };
  };

  const handleSuccessIndex = response => {
    const responseVacations = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setVacations(responseVacations);
    setAmount(responseAmount);
    setOnRequest(false);
    cleanSelectedData();
  };

  const handleIndexRequest = params => {
    const customParams = handleCustomParams(status);

    const sendParams = {
      ...params,
      ...customParams,
      status
    };
    if (
      !sendParams.sort_employees &&
      !sendParams.sort_job_titles &&
      !sendParams.sort_job_managements &&
      !sendParams.sort_status
    ) {
      sendParams.sort_column = params.sort_column || customParams.sort_column || 'start_date';
    }
    if (customParams.vacationsAvailableType) {
      const myParams = {
        vacationsAvailableType: customParams.vacationsAvailableType,
        active: true,
        active_contracts: true
      };
      searchEmployeesWithAvailableVacationsRequest({
        dispatch,
        params: myParams,
        successCallback: handleSuccessIndex,
        callback: setOnRequest(true)
      });
    } else {
      debounceIndexVacationsRequest({
        dispatch,
        params: sendParams,
        successCallback: handleSuccessIndex,
        callback: setOnRequest(true)
      });
    }
  };

  const handleModalShow = item => {
    setModalTitle(`Solicitud de ${item.translatedVacationType === 'Administrativos' ? 'Permiso' : 'Vacaciones'}`);
    setModalBody(<VacationInfo item={item} />);
    setModalButtons(false);
    setModalShow(true);
    setModalSize('md');
  };

  const handleManageRequest = item => {
    setModalBody(
      <VacationInfoTable
        item={item}
        moreData={moreData}
        setModalShow={setModalShow}
        setMoreData={setMoreData}
        showErrorMessage
      />
    );
    setModalButtons(false);
    setModalShow(true);
    setModalSize('xl');
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        handleModalShow(camelCaseRecursive(item));
        break;
      case 'actions':
        handleManageRequest(camelCaseRecursive(item));
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee':
        return { sort_employees: name };
      default:
        return { sort_column: name };
    }
  };

  useEffect(() => {
    setStatus(defaultStatus);
  }, [preName, defaultStatus]);

  return (
    <>
      <ComponentDataTable
        columns={columns(handleActions, showActions, preName === 'administrative_days')}
        data={vacations}
        handleSortCase={sortColumnCase}
        customHeaderCenter={
          <>
            <FormikSelect
              options={vacationStatus}
              label=""
              value={status}
              defaultValue={status}
              onChange={data => {
                setStatus(data ? data.value : '');
                setOnRequest(true);
                setMoreData(!moreData);
              }}
              placeholder="Todos"
            />
          </>
        }
        moreData={moreData}
        defaultStartDate={todayMoment}
        defaultEndDate={todayMoment}
        onRequest={onRequest}
        rangePicker
        resourceRequest={handleIndexRequest}
        totalRows={amount}
        withEndDate
        withStartDate
      />
      <DefaultModal
        body={modalBody}
        disabled={onRequest}
        handleClose={handleModalClose}
        modalSize={modalSize}
        show={modalShow}
        title={modalTitle}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose={modalButtons}
        withConfirm={modalButtons}
      />
    </>
  );
};

VacationDataTableStyled.propTypes = {
  preName: PropTypes.string
};

VacationDataTableStyled.defaultProps = {
  preName: ''
};

export default VacationDataTableStyled;
