import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, getIn, useFormikContext } from 'formik';
import { Col } from 'react-bootstrap';
import { sendAlert } from '../../actions/utils';
import { debounceIndexGendersRequest, indexGendersRequest } from '../../requests/genders';
import { InputSelect } from '../Utils/Select';

const Genders = ({ allDisabled, modelKey, genderAbbr, md = '6' }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const [genders, setGenders] = useState([]);
  const dispatch = useDispatch();
  const currentValues = getIn(values, modelKey);
  const { genderId } = currentValues;

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleGenderData = response => {
    const { data } = response.data;
    setGenders(data);
    return data;
  };

  const fetchGenders = (inputValue, callback) => {
    debounceIndexGendersRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 20
      },
      successCallback: response => callback(handleGenderData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const initialDefaultValues = (array, attributeId) => {
    const data = array.find(obj => obj.value === attributeId);
    if (data === undefined) return false;
    return data;
  };

  const handleGenderChange = value => {
    setFieldValue(`${modelKey}[genderId]`, value);
  };

  const fetchInitialGenders = () => {
    indexGendersRequest({
      dispatch,
      params: {
        sort_column: 'name',
        sort_direction: 'asc',
        paginate: false
      },
      successCallback: response => handleGenderData(response),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchInitialGenders, []);

  return (
    <>
      <Col md={md}>
        <Field name={`${modelKey}[genderId]`}>
          {({ field }) => (
            <InputSelect
              {...field}
              abbr={genderAbbr}
              isClearable={!genderAbbr}
              label="Genero"
              placeholder="Seleccionar Genero"
              disabled={allDisabled}
              value={initialDefaultValues(genders, genderId)}
              defaultOptions={genders}
              onChange={data => handleGenderChange(data ? data.value : '')}
              request={fetchGenders}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </>
  );
};

export default Genders;
