import React from 'react';
import { Row, Col, FormControl, Form, InputGroup } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import PropTypes from 'prop-types';
import { ICON_AFTER_POSITION } from 'react-dates/constants';
import RenderMonthElement from '../Input/RenderMonthElement';
import Icon from '../../Icons/Icon';

const StartDateInput = ({ startDate, focusStartDate, setFocusEndDate, setFocusStartDate, setStartDate, endDate }) => (
  <SingleDatePicker
    block
    id="-start-date-input"
    date={startDate}
    placeholder="Desde"
    focused={focusStartDate}
    onFocusChange={({ focused }) => {
      setFocusEndDate(false);
      setFocusStartDate(focused);
    }}
    numberOfMonths={1}
    onDateChange={date => {
      setStartDate(date);
    }}
    showClearDate
    customCloseIcon={<Icon icon="close" width={18} />}
    customInputIcon={<Icon icon="calendar" width={18} />}
    hideKeyboardShortcutsPanel
    inputIconPosition={ICON_AFTER_POSITION}
    firstDayOfWeek={1}
    renderMonthElement={RenderMonthElement}
    isOutsideRange={day => day.isAfter(endDate)}
  />
);

const EndDateInput = ({ endDate, focusEndDate, setFocusStartDate, setFocusEndDate, setEndDate, startDate }) => (
  <SingleDatePicker
    block
    id="end-date-input"
    date={endDate}
    placeholder="Hasta"
    focused={focusEndDate}
    onFocusChange={({ focused }) => {
      setFocusStartDate(false);
      setFocusEndDate(focused);
    }}
    numberOfMonths={1}
    onDateChange={date => {
      setEndDate(date);
    }}
    showClearDate
    customCloseIcon={<Icon icon="close" width={18} />}
    customInputIcon={<Icon icon="calendar" width={18} />}
    hideKeyboardShortcutsPanel
    inputIconPosition={ICON_AFTER_POSITION}
    firstDayOfWeek={1}
    renderMonthElement={RenderMonthElement}
    isOutsideRange={day => day.isBefore(startDate)}
  />
);

const Filters = ({
  withMassActions,
  withDateSelect,
  massActions,
  withStartDate,
  withEndDate,
  withSearch,
  withHeaders,
  setTextInput,
  textInput,
  startDate,
  focusStartDate,
  setFocusEndDate,
  setFocusStartDate,
  setStartDate,
  endDate,
  focusEndDate,
  setEndDate,
  headers
}) => {
  return (
    <>
      <Row
        style={{
          paddingTop: 20,
          alignItems: 'center',
          justifyContent: withMassActions && !withDateSelect ? 'flex-start' : 'space-between',
          width: '100%'
        }}
      >
        {withMassActions && <Col style={{ display: 'flex', flexDirection: 'row' }}>{massActions}</Col>}
        {headers && withHeaders && (
          <Col xs={12} md={6} xl={3}>
            {headers}
          </Col>
        )}
        {withDateSelect && (
          <>
            <Col xs={12} md={6} xl={4} style={{ paddingLeft: '30px' }}>
              <Row style={{ flexWrap: 'nowrap' }}>
                {withStartDate && (
                  <Form.Group>
                    <InputGroup className="margin-sm" style={{ paddingRight: '5px' }}>
                      <StartDateInput
                        startDate={startDate}
                        focusStartDate={focusStartDate}
                        setFocusEndDate={setFocusEndDate}
                        setFocusStartDate={setFocusStartDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                      />
                    </InputGroup>
                  </Form.Group>
                )}
                {withEndDate && (
                  <Form.Group>
                    <InputGroup>
                      <EndDateInput
                        endDate={endDate}
                        focusEndDate={focusEndDate}
                        setFocusStartDate={setFocusStartDate}
                        setFocusEndDate={setFocusEndDate}
                        setEndDate={setEndDate}
                        startDate={startDate}
                      />
                    </InputGroup>
                  </Form.Group>
                )}
              </Row>
            </Col>
          </>
        )}
        <Col xs={12} md={6} xl={3}>
          {withSearch && (
            <Form.Group>
              <FormControl
                placeholder="Buscar"
                name="textInput"
                value={textInput}
                onChange={e => {
                  setTextInput(e.target.value);
                }}
              />
            </Form.Group>
          )}
        </Col>
      </Row>
    </>
  );
};

Filters.propTypes = {
  withMassActions: PropTypes.bool,
  withDateSelect: PropTypes.bool,
  massActions: PropTypes.element,
  withStartDate: PropTypes.bool,
  withEndDate: PropTypes.bool,
  withSearch: PropTypes.bool,
  setTextInput: PropTypes.func,
  textInput: PropTypes.string,
  startDate: PropTypes.string,
  focusStartDate: PropTypes.bool,
  setFocusEndDate: PropTypes.func,
  setFocusStartDate: PropTypes.func,
  setStartDate: PropTypes.func,
  endDate: PropTypes.string,
  focusEndDate: PropTypes.bool,
  setEndDate: PropTypes.func
};

Filters.defaultProps = {
  withMassActions: false,
  withDateSelect: false,
  massActions: () => <></>,
  withStartDate: false,
  withEndDate: false,
  withSearch: false,
  textInput: '',
  setTextInput: () => {},
  startDate: '',
  focusStartDate: false,
  setFocusEndDate: () => {},
  setFocusStartDate: () => {},
  setStartDate: () => {},
  endDate: '',
  focusEndDate: false,
  setEndDate: () => {}
};

export default React.memo(Filters);
