import React from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { Icon } from '../../components';
import { useSetTab } from '../../services/hooks';
import ProcessReportsIndex from './ProcessSummary/ProcessReportsIndex';
import ability from '../../config/ability';
import SummaryProcessIndex from './SummaryProcessIndex';

function ProcessSummary({ location }) {
  const defaultKey = () => {
    if (ability.can('process_summary', 'ProcessSummary')) return 'process-summary';
    if (ability.can('process_reports', 'ProcessSummary')) return 'process-reports';
    return 'process-summary';
  };

  const [key, setKey] = useSetTab(defaultKey(), location);

  return (
    <>
      <Row className="mt-4 mb-2">
        <Col>
          <h2 className="mb-3 mt-3">Reportes</h2>
        </Col>
      </Row>
      <Tab.Container id="company-settings-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          {ability.can('process_summary', 'ProcessSummary') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="process-summary">
                <Icon icon="document" className="mb-1" />
                Resumen del Proceso
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('process_reports', 'ProcessSummary') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="process-reports">
                <Icon icon="document" className="mb-1" />
                Reportes del Proceso
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="process-summary">
            <SummaryProcessIndex />
          </Tab.Pane>
          <Tab.Pane eventKey="process-reports">
            <ProcessReportsIndex />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
}

export default ProcessSummary;
