import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useAbility } from '@casl/react';
import { indexLoanRequest } from '../../../requests/loans';
import { SimpleCenteredModal, DefaultModal, FormikSelect, ComponentDataTable } from '../../../components';
import { AbilityContext } from '../../../config/abilityContext';
import ItemsInfo from '../../Employee/Items/ItemsInfo';
import columns from '../../Profile/Loan/StyledColumns';
import LoanInfoTable from './LoanInfoTable';

const LoanDataTableStyled = ({ currentEmployee, defaultStatus = '0', employeesBelongBoss, moreData, setMoreData }) => {
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [firstModalShow, setFirstModalShow] = useState(false);
  const [defaultModalButtons, setDefaultModalButtons] = useState(true);
  const [defaultModalSize, setDefaultModalSize] = useState('lg');
  const [loans, setLoans] = useState([]);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalSize, setModalSize] = useState('lg');
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(defaultStatus);
  const loanStatus = [
    { label: 'Todos', value: '' },
    { label: 'Aprobado', value: '1' },
    { label: 'Pendiente', value: '0' },
    { label: 'Rechazado', value: '2' }
  ];

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setLoans(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleCustomParams = () => {
    if (status !== '0' && employeesBelongBoss.length > 0) {
      return {
        filter_employee: employeesBelongBoss
      };
    }

    return {
      pending_review: currentEmployee
    };
  };

  const handleIndexRequest = async params => {
    const customParams = handleCustomParams(status);
    setOnRequest(true);
    const sendParams = {
      ...customParams,
      ...params,
      status
    };
    indexLoanRequest({ dispatch, params: sendParams, successCallback: handleSuccessIndex });
  };

  const handleDefaultModalClose = () => {
    setFirstModalShow(false);
  };

  const handleManageRequest = item => {
    setDefaultModalBody(
      <LoanInfoTable
        item={item}
        currentEmployee={currentEmployee}
        setModalShow={setFirstModalShow}
        moreData={moreData}
        setMoreData={setMoreData}
      />
    );
    setDefaultModalButtons(false);
    setFirstModalShow(true);
    setDefaultModalSize('xl');
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'actions':
        handleManageRequest(camelCaseRecursive(item));
        break;
      case 'show':
        setModalTitle('Préstamo');
        setModalBody(<ItemsInfo type="loan" item={item} />);
        setModalShow(true);
        setModalSize('md');
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee':
        return { sort_employees: name };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        columns={columns(handleActions, ability)}
        data={loans}
        handleSortCase={sortColumnCase}
        customHeaderCenter={
          <>
            <FormikSelect
              options={loanStatus}
              label=""
              value={status}
              defaultValue={status}
              onChange={data => {
                setStatus(data ? data.value : '');
                setOnRequest(true);
                setMoreData(!moreData);
              }}
              placeholder="Todos"
            />
          </>
        }
        moreData={moreData}
        nameRangePicker="filter_dates"
        onRequest={onRequest}
        rangePicker
        resourceRequest={handleIndexRequest}
        totalRows={amount}
        withEndDate
        withStartDate
      />
      <SimpleCenteredModal
        size={modalSize}
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <DefaultModal
        body={defaultModalBody}
        disabled={onRequest}
        handleClose={handleDefaultModalClose}
        modalSize={defaultModalSize}
        show={firstModalShow}
        withClose={defaultModalButtons}
        withConfirm={defaultModalButtons}
      />
    </>
  );
};

export default LoanDataTableStyled;
