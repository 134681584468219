import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounceIndexEmployeesRequest } from '../requests/employees';

const useEmployeeSearch = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchEmployees = (inputValue, callback, addAllOption = false, customFilters = {}) => {
    const currentCompany = window.localStorage.getItem('currentCompany');
    const cacheKey = `employees_${currentCompany}`;
    const cachedData = window.sessionStorage.getItem(cacheKey);

    // Verificar si los resultados están en la caché
    if (cachedData) {
      const data = JSON.parse(cachedData);
      const filteredData = inputValue
        ? data.filter(item => item.label.toLowerCase().includes(inputValue.toLowerCase()))
        : data;
      const copyData = addAllOption ? [{ label: 'Todos', value: 'all' }, ...filteredData] : filteredData;

      // Verificar si se encontraron resultados
      if (filteredData.length > 0) {
        callback(copyData);
        return;
      }
    }

    // Hacer la consulta a la API
    setLoading(true);
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        active_contracts: true,
        sort_column: 'name',
        is_dt: false,
        display_length: 300,
        ...customFilters
      },
      successCallback: response => {
        const { data } = response.data;

        // Agregar elemento "Todos" si se especifica en el parámetro addAllOption
        const copyData = addAllOption ? [{ label: 'Todos', value: 'all' }, ...data] : data;

        const oldData = JSON.parse(cachedData);

        const newData = oldData ? [...oldData, ...data] : data;

        window.sessionStorage.setItem(cacheKey, JSON.stringify(newData));

        setLoading(false);

        callback(copyData);
      }
    });
  };

  return { fetchEmployees, loading };
};

export default useEmployeeSearch;
