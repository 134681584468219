import React, { useEffect, useState } from 'react';
import { useAbility } from '@casl/react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import { Icon, LinkBtn, SimpleCenteredModal } from '../../components';
import EmployeeApDataTable from './EmployeeApDatatable';
import {
  advancePaymentProcessCardsRequest,
  updateAdvancePaymentProcessRequest
} from '../../requests/advancePaymentProcess';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { AbilityContext } from '../../config/abilityContext';
import DataProcessesNew from './DataProcessesNew';
import { indexEmployeesRequest } from '../../requests/employees';
import { indexDataProcessRequest } from '../../requests/dataProcess';
import { sendAlert } from '../../actions/utils';

const AdvancePaymentProcess = props => {
  const dispatch = useDispatch();
  const ability = useAbility(AbilityContext);
  const { match, location } = props;
  const { month, year } = location.state;
  const apProcessId = match.params.id;
  const [advancePaymentProcess, setAdvancePaymentProcess] = useState({});
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [dataProcess, setDataProcess] = useState([]);
  const [onRequest, setOnRequest] = useState(false);

  const fetchAdvancePaymentProcess = () => {
    const { id } = match.params;
    advancePaymentProcessCardsRequest(id, {
      dispatch,
      successCallback: response => {
        setAdvancePaymentProcess(camelCaseEmptyStringRecursive(response.data));
      }
    });
  };

  const handleSuccessCreate = () => {
    fetchAdvancePaymentProcess();
    setMoreData(!moreData);
    setModalShow(false);
  };

  const handleNewElement = nextMonth => {
    setModalTitle('Ingresar Anticipo');
    setModalBody(
      <DataProcessesNew
        nextMonth={nextMonth}
        onSuccess={handleSuccessCreate}
        rrhh
        apProcessId={apProcessId}
        cancelAction={() => setModalShow(false)}
      />
    );
    setModalShow(true);
  };

  const handleIndexDataProcess = () => {
    indexDataProcessRequest({
      dispatch,
      params: {
        filter_process_id: apProcessId,
        paginate: false
      },
      successCallback: response => setDataProcess(response.data.data)
    });
  };

  const handleUpdateElement = () => {
    setOnRequest(true);
    indexEmployeesRequest({
      dispatch,
      params: {
        advance_payment_process: true,
        is_active: true,
        sort_column: 'name',
        sort_direction: 'asc',
        month: advancePaymentProcess.month,
        year: advancePaymentProcess.year,
        paginate: false
      },
      successCallback: employee => {
        const employees = employee.data.data;
        const dataProcessIds = dataProcess.map(item => item.advance_payment_id);
        const updatedEmployees = employees.filter(item => !dataProcessIds.includes(item.advance_payment_id));
        const updatedValues = {
          advancePaymentProcess: {
            ...advancePaymentProcess,
            dataProcessesAttributes: updatedEmployees
          }
        };
        updateAdvancePaymentProcessRequest(apProcessId, {
          dispatch,
          params: snakecaseKeys(updatedValues),
          successCallback: () => {
            dispatch(sendAlert({ kind: 'success', message: 'Proceso actualizado con éxito' }));
            setMoreData(!moreData);
          },
          callback: () => {
            setOnRequest(false);
          }
        });
      }
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  useEffect(fetchAdvancePaymentProcess, [moreData]);
  useEffect(handleIndexDataProcess, [moreData]);

  return (
    <>
      <Row className="m-top mb-2">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to="/advance_payments_process">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        {advancePaymentProcess && (
          <Col md={6}>
            <h2 className="m-top mb-3 mt-0">
              {advancePaymentProcess.name} {advancePaymentProcess.monthYearProccess}
            </h2>
            <h2 className="m-top mt-0 text-green mb-2">Abierto</h2>
          </Col>
        )}
        {ability.can('create', 'AdvancePaymentProcess') && (
          <Col md={{ span: 2, offset: 2 }}>
            <Button variant="primary" block onClick={() => handleNewElement()}>
              Nuevo
            </Button>
            <Button variant="primary" block onClick={() => handleUpdateElement()}>
              Actualizar
            </Button>
          </Col>
        )}
      </Row>
      <Row className="sm-12">
        <Col sm={12} md={12}>
          <Card className="mb-1 mt-2">
            <Col sm={12} md={12}>
              <Row className="div-content-white">
                <Col>
                  <EmployeeApDataTable
                    apProcessId={apProcessId}
                    advancePaymentProcess={advancePaymentProcess}
                    month={month}
                    year={year}
                    moreData={moreData}
                    setMoreData={setMoreData}
                    setOnRequest={setOnRequest}
                    onRequest={onRequest}
                  />
                </Col>
              </Row>
            </Col>
          </Card>
        </Col>
      </Row>
      <SimpleCenteredModal title={modalTitle} body={modalBody} onHide={handleModalClose} show={modalShow} size="xl" />
    </>
  );
};
export default withRouter(AdvancePaymentProcess);
