import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Filters from './Filters';
import { downloadFile } from '../../../services/utils';
import { indexApiPayrollRequest } from '../../../requests/payrollProcesses';
import { sendAlert } from '../../../actions/utils';

const ProcessReportsIndex = () => {
  const dispatch = useDispatch();
  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { currentCompany } = useSelector(state => state.auth);
  const history = useHistory();

  const handleRedirect = (data, reportType) => {
    history.push(`/process_summary/${reportType}/${data.id}`);
  };

  const handleRequestProcess = params => {
    setIsLoading(true);
    indexApiPayrollRequest({
      dispatch,
      params: {
        ...params,
        filter_by_company: currentCompany.id
      },
      successCallback: ({ data }) => {
        if (!data?.data?.length) {
          dispatch(
            sendAlert({ kind: 'error', message: 'No existen procesos de remuneracion en las fechas proporcionadas' })
          );
          return;
        }
        const _payrollProcess = data.data[0];
        sessionStorage.setItem(`processDetailsFilters`, JSON.stringify(params));
        handleRedirect(_payrollProcess, params.reportType);
      },
      callback: () => {
        setIsLoading(false);
      }
    });
  };

  const handleRequest = (params, request) => {
    setIsLoading(true);
    if (!request) {
      handleRequestProcess(params);
      return;
    }
    request({
      dispatch,
      params: {
        ...params,
        filter_by_company: currentCompany.id
      },
      format: params.format,
      successCallback: response => {
        if (params.format) {
          downloadFile(response);
        }
      },
      callback: () => {
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <Filters report={report} isLoading={isLoading} setReport={setReport} formRequest={handleRequest} />
      <div className="position-relative">
        {isLoading && (
          <div className="containerSpinnerLoad position-absolute h-100 w-100">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
    </>
  );
};

export default ProcessReportsIndex;
