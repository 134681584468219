import React from 'react';
import PropTypes from 'prop-types';
import BasicTooltip from '../Utils/Tooltips/BasicTooltip';

const GenericActiveDot = ({ item, field }) => {
  const isActive = item[field];

  const currentClass = isActive ? 'dot dot-success' : 'dot dot-danger';
  const text = isActive ? 'Activo' : 'Inactivo';

  return (
    <BasicTooltip text={text}>
      <span className={currentClass} data-tag="allowRowEvents" />
    </BasicTooltip>
  );
};

GenericActiveDot.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  field: PropTypes.string.isRequired
};

export default GenericActiveDot;
