import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ComposedChart,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer
} from 'recharts';
import './style.scss';

function CustomGraph({ chartType, data, title, xLegend, activeAnimation, customizedLabel, pieLegend, mb = '4', ...props }) {
  const { customColors, customHeight, classResponsiveContainer } = props;
  const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#571263',
    '#5be93f',
    '#81b7ad',
    '#930090',
    '#5c27a7',
    '#d5fe0b',
    '#cd0e40',
    '#864aed',
    '#800636',
    '#9b067a',
    '#2f2751',
    '#7a507c',
    '#11b3ce',
    '#3bc06b',
    '#a19748',
    '#d21d1d',
    '#e4709e',
    '#2755e1',
    '#787cd9',
    '#fc5bfa',
    '#35b1dd',
    '#9c4fcb',
    '#0b65f3',
    '#2f9061',
    '#f7613d',
    '#8bddfe',
    '#A4CF42'
  ];

  const graphColors = customColors || COLORS;

  const formatNumber = value => {
    return new Intl.NumberFormat('en').format(value).replace(/,/g, '.');
  };

  function CustomBarChart() {
    const height = customHeight || '80%';
    return (
      <div style={{ width: '100%', height: '100%' }} className="carousel-inner-graph-active">
        <div className="container-chart">
          {title && <h3 className="text-center mt-3">{title}</h3>}
          {data.length > 0 ? (
            <ResponsiveContainer className={classResponsiveContainer} width="95%" height={height}>
              <BarChart data={data}>
                <XAxis dataKey="name" stroke="#666" />
                <YAxis />
                <Tooltip formatter={value => [value, 'Valor']} />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <Bar dataKey="value" barSize={20}>
                  {data.map((entry, index) => (
                    <Cell key={`cell-${entry}`} fill={graphColors[index % graphColors.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <h1>Sin Datos</h1>
          )}
          <p className="text-center mt-2">{xLegend}</p>
        </div>
      </div>
    );
  }

  function CustomSideBarChart() {
    const height = customHeight || '75%';
    return (
      <div style={{ width: '100%', height: '100%' }} className="carousel-inner-graph-active">
        <div className="container-chart">
          {title && <h3 className="text-center mt-3">{title}</h3>}
          {data.length > 0 ? (
            <ResponsiveContainer className={classResponsiveContainer} width="90%" height={height}>
              <ComposedChart layout="vertical" data={data} margin={{ left: 10 }}>
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis type="number" tickFormatter={value => formatNumber(value)} />
                <YAxis dataKey="name" type="category" width={140} />
                <Tooltip formatter={value => [formatNumber(value), 'Valor']} />
                <Bar dataKey="value" barSize={5} fill="#413ea0" />
              </ComposedChart>
            </ResponsiveContainer>
          ) : (
            <h1>Sin Datos</h1>
          )}
          <p className="text-center mt-2">{xLegend}</p>
        </div>
      </div>
    );
  }

  function CustomPieChart() {
    const RADIAN = Math.PI / 180;
    const total = data.reduce((prev, next) => prev + next.value, 0);
    const defaultLegendSetting = { layout: 'horizontal', verticalAlign: 'bottom', align: 'center' };
    const legendSetting = pieLegend || defaultLegendSetting;
    const height = customHeight || '75%';

    const renderCustomizedLabel = ({ cx, cy, midAngle, outerRadius, percent }) => {
      const radius = outerRadius + 10;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return percent ? (
        <text x={x} y={y} fill="#333" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      ) : (
        ''
      );
    };

    const defaultLabel = specificData => `${specificData.payload.value} (${(specificData.percent * 100).toFixed(2)}%)`;

    return (
      <div style={{ width: '100%', height: '100%' }} className={`carousel-inner-graph-active mb-${mb}`}>
        <div className="container-chart">
          {title && <h3 className="text-center mt-3">{title}</h3>}
          {data.length > 0 ? (
            <ResponsiveContainer className={classResponsiveContainer} width="100%" height={height}>
              <PieChart>
                <Pie
                  isAnimationActive={activeAnimation}
                  data={data}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  labelLine={!customizedLabel}
                  label={customizedLabel ? renderCustomizedLabel : defaultLabel}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${entry}`} fill={graphColors[index % graphColors.length]} />
                  ))}
                </Pie>
                <Legend
                  layout={legendSetting.layout}
                  verticalAlign={legendSetting.verticalAlign}
                  align={legendSetting.align}
                  wrapperStyle={{ position: 'absolute', botton: 0 }}
                />
                <Tooltip
                  formatter={label =>
                    `${new Intl.NumberFormat('es-CL').format(label)} (${((label / total) * 100).toFixed(2)}%)`
                  }
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <h3 className="text-center text-uppercase mt-4">Sin Datos</h3>
          )}
          <p className="text-center mt-2">{xLegend}</p>
        </div>
      </div>
    );
  }

  class CustomizedLabel extends PureComponent {
    render() {
      const { x, y, stroke, value } = this.props;
      return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
          {value}
        </text>
      );
    }
  }
  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, payload } = this.props;
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
            {payload.value.length > 15 ? `${payload.value.substring(0, 15)}...` : payload.value}
          </text>
        </g>
      );
    }
  }

  function CustomLineChart() {
    const height = customHeight || '80%';
    return (
      <div style={{ width: '100%', height: '100%' }} className="carousel-inner-graph-active">
        <div className="container-chart">
          {title && <h3 className="text-center mt-3">{title}</h3>}
          {data.length > 0 ? (
            <ResponsiveContainer className={classResponsiveContainer} width="95%" height={height}>
              <LineChart
                data={data}
                margin={{
                  top: 25,
                  right: 30,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" stroke="#666" padding={{ left: 15, right: 15 }} tick={<CustomizedAxisTick />} />
                <YAxis domain={['auto', 'auto']} tickFormatter={value => formatNumber(value)} />
                <Tooltip formatter={value => [formatNumber(value), 'Valor']} />
                <Line type="monotone" dataKey="value" stroke="#8884d8" label={<CustomizedLabel />} />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <h1>Sin Datos</h1>
          )}
          <p className="text-center mt-2">{xLegend}</p>
        </div>
      </div>
    );
  }

  switch (chartType) {
    case 'bar':
      return CustomBarChart();
    case 'side':
      return CustomSideBarChart();
    case 'pie':
      return CustomPieChart();
    case 'line':
      return CustomLineChart();
    default:
      // eslint-disable-next-line no-console
      return console.log('Error: Chart not found');
  }
}

CustomGraph.propTypes = {
  title: PropTypes.string,
  chartType: PropTypes.string,
  classResponsiveContainer: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  activeAnimation: PropTypes.bool,
  customizedLabel: PropTypes.bool
};

CustomGraph.defaultProps = {
  activeAnimation: true,
  chartType: 'bar',
  classResponsiveContainer: '',
  customizedLabel: false,
  title: ''
};

export default CustomGraph;
