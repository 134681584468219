import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';
import {
  closeLoanProcessRequest,
  closeMassiveLoanProcessesRequest,
  deleteMassiveLoanProcessesRequest,
  destroyLoanProcessRequest,
  exportLoanProcessRequest
} from '../../requests/loanProcesses';
import { downloadFile } from '../../services/utils';

const useLoanProcessHook = () => {
  const dispatch = useDispatch();

  const handleSuccessRequest = (msg, setMoreData, setClearSelectedRows, setConfirmModalState = () => {}) => {
    dispatch(sendAlert({ kind: 'success', message: msg }));
    setMoreData(val => !val);
    setClearSelectedRows(true);
    setConfirmModalState(state => ({ ...state, show: false }));
  };

  const handleCallbackRequest = (setOnRequest, setConfirmModalState) => {
    setOnRequest(false);
    setConfirmModalState(state => ({ ...state, show: false }));
  };

  const handleCloseProcess = (id, setMoreData, setClearSelectedRows, setOnRequest, setConfirmModalState) => {
    setOnRequest(true);
    closeLoanProcessRequest(id, {
      dispatch,
      successCallback: () =>
        handleSuccessRequest('Proceso cerrado correctamente', setMoreData, setClearSelectedRows, setConfirmModalState),
      callback: () => handleCallbackRequest(setOnRequest, setConfirmModalState)
    });
  };

  const handleDeleteProcess = (id, setMoreData, setClearSelectedRows, setOnRequest, setConfirmModalState) => {
    setOnRequest(true);
    destroyLoanProcessRequest(id, {
      dispatch,
      successCallback: () =>
        handleSuccessRequest(
          'Proceso Eliminado correctamente',
          setMoreData,
          setClearSelectedRows,
          setConfirmModalState
        ),
      callback: () => handleCallbackRequest(setOnRequest, setConfirmModalState)
    });
  };

  const handleSortKeys = sortParams => {
    return Object.keys(sortParams).reduce((acc, key) => {
      return key.startsWith('sort') && sortParams[key] ? { ...acc, [key]: sortParams[key] } : acc;
    }, {});
  };

  const handleDownloadProcess = (
    customParams,
    selectedIds,
    sortParams,
    setMoreData,
    setClearSelectedRows,
    setOnRequest
  ) => {
    const sortKeys = handleSortKeys(sortParams);
    setOnRequest(true);
    const params =
      selectedIds.length === 0
        ? {
            paginate: false,
            ...customParams,
            ...sortKeys
          }
        : {
            filter_ids: [...selectedIds],
            ...customParams,
            ...sortKeys
          };
    exportLoanProcessRequest({
      dispatch,
      params,
      successCallback: response => {
        downloadFile(response);
        setMoreData(val => !val);
        setClearSelectedRows(true);
        setOnRequest(false);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleMassiveClose = (selectedRows, setMoreData, setClearSelectedRows, setOnRequest, setConfirmModalState) => {
    setOnRequest(true);
    closeMassiveLoanProcessesRequest({
      dispatch,
      params: { ids: [...selectedRows] },
      successCallback: () =>
        handleSuccessRequest('Procesos cerrados exitosamente', setMoreData, setClearSelectedRows, setConfirmModalState),
      callback: () => {
        setClearSelectedRows(true);
        setMoreData(val => !val);
        handleCallbackRequest(setOnRequest, setConfirmModalState);
      }
    });
  };

  const handleMassiveDestroy = (
    selectedRows,
    setMoreData,
    setClearSelectedRows,
    setOnRequest,
    setConfirmModalState
  ) => {
    setOnRequest(true);
    deleteMassiveLoanProcessesRequest({
      dispatch,
      params: { ids: [...selectedRows] },
      successCallback: () =>
        handleSuccessRequest(
          'Procesos eliminados exitosamente',
          setMoreData,
          setClearSelectedRows,
          setConfirmModalState
        ),
      callback: () => {
        setClearSelectedRows(true);
        setMoreData(val => !val);
        handleCallbackRequest(setOnRequest, setConfirmModalState);
      }
    });
  };

  return {
    handleCloseProcess,
    handleDeleteProcess,
    handleDownloadProcess,
    handleMassiveClose,
    handleMassiveDestroy,
    handleSortKeys
  };
};

export default useLoanProcessHook;
