import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import { showAttendanceMovementRequest, updateAttendanceMovementRequest } from '../../../requests/attendanceMovements';
import { sendAlert } from '../../../actions/utils';
import defaultMovement from './defaultMovement';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import AttendanceMovementForm from './AttendanceMovementForm';

const AttendanceMovementsEdit = ({ id, setMoreData, moreData, hideModal, payrollDates = [] }) => {
  const dispatch = useDispatch();
  const [attendanceMovement, setAttendanceMovement] = useState(defaultMovement);
  const [onRequest, setOnRequest] = useState(true);

  const handleUpdateRequest = (benefitValues, setSubmitting) => {
    const myParams = snakecaseKeys(benefitValues);
    setOnRequest(true);
    updateAttendanceMovementRequest(id, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => {
        hideModal();
        setOnRequest(false);
        setMoreData(!moreData);
        dispatch(sendAlert({ kind: 'success', message: 'Movimiento actualizado con éxito' }));
      },
      failureCallback: error => {
        setSubmitting(false);
        const { response } = error;
        setOnRequest(false);
        dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
      },
      callback: () => setMoreData(!moreData)
    });
  };

  const handleShowRequest = () => {
    setOnRequest(true);
    showAttendanceMovementRequest(id, {
      dispatch,
      successCallback: res => {
        setAttendanceMovement(camelCaseEmptyStringRecursive(res.data));
        setOnRequest(false);
      }
    });
  };

  useEffect(handleShowRequest, []);

  return (
    <>
      {onRequest && <Spinner className="spinner-modal fix-spinner" animation="border" variant="primary" />}
      <div className={`${onRequest && 'bg-opacity'}`}>
        <AttendanceMovementForm
          attendanceMovement={attendanceMovement}
          action="edit"
          formRequest={handleUpdateRequest}
          hideModal={hideModal}
          payrollDates={payrollDates}
        />
      </div>
    </>
  );
};

export default AttendanceMovementsEdit;
