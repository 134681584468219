import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import { FormikInput, FormikSelect } from '../../../components';
import { debounceIndexAdminCompaniesRequest } from '../../../requests/adminCompanies';
import { debounceIndexBranchOfficesRequest } from '../../../requests/adminBranchOffices';

const ControlClockForm = ({ submitVariant, isSubmitting, setFieldValue, errors, device, touched, action }) => {
  const dispatch = useDispatch();
  const [branchOffices, setBranchOffices] = useState([]);
  const [companies, setCompanies] = useState([]);
  const { company_id: companyId } = device

  const resultCompanyFetchData = response => {
    const result = response.data.data;
    const tempArray = result?.map(element => {
      return {
        label: `${element.national_identification} - ${element.business_name}`,
        value: element.id,
        api_key: element.api_key,
        secret_key: element.secret_key
      };
    });
    return tempArray;
  };

  const fetchCompanies = () => {
    debounceIndexAdminCompaniesRequest({
      dispatch,
      params: {
        order_by_attribute: 'business_name',
        paginate: false
      },
      successCallback: result => {
        setCompanies(resultCompanyFetchData(result));
      }
    });
  };

  const fetchBranchOffices = params => {
    const filterCompanyId = params || companyId;
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        company_id: filterCompanyId,
        paginate: false
      },
      successCallback: result => {
        setBranchOffices(result.data.data);
      }
    });
  };

  useEffect(fetchCompanies, []);
  useEffect(fetchBranchOffices, [companyId]);
  return (
    <Form>
      <Row>
        <Col md={6}>
          <Field name="device[company_id]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Empresa"
                placeholder="Seleccionar Empresa"
                options={companies}
                defaultValue={device.company_id}
                onChange={data => {
                  setFieldValue(field.name, data ? data.value : '');
                  if (data) {
                    const businessName = data.label.split(' - ').pop();
                    setFieldValue('device[company]', data ? {
                      id: data.value, business_name: businessName,
                      api_key: data.api_key,
                      secret_key: data.secret_key
                    } : '');
                    setFieldValue('device[branch_office_id]', '');
                    setFieldValue('device[branch_office]', '');
                    fetchBranchOffices(data.value)
                  }
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="device[branch_office_id]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Lugar de Prestación de Servicios"
                placeholder="Seleccionar Lugar de Prestación de Servicios"
                options={branchOffices}
                defaultValue={device.branch_office_id}
                onChange={data => {
                  setFieldValue(field.name, data ? data.value : '');
                  setFieldValue('device[branch_office]', data ? { id: data.value, name: data.label } : '');
                }}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Field name="device[sn]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                disabled={action !== 'new'}
                label="Serial"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="device[alias]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Alias"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="device[ip_address]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                disabled={action !== 'new'}
                label="Dirección IP"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="device[heartbeat]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Intervalo de Solicitud"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-5 mt-md-3 mb-3">
        <Col md={3} xl={2}>
          <Button type="submit" disabled={isSubmitting} variant={submitVariant} block>
            {action === 'new' ? 'Crear' : 'Actualizar'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { device } = props;
  return {
    device: {
      sn: device.sn,
      alias: device.alias,
      ip_address: device.ip_address,
      heartbeat: device.heartbeat,
      company: device.company,
      branch_office: device.branch_office,
      company_id: device.company_id,
      branch_office_id: device.branch_office_id
    }
  };
};

const validationSchema = Yup.object().shape({
  device: Yup.object().shape({
    sn: Yup.string().required('Debes ingresar un serial'),
    alias: Yup.string().required('Debes ingresar un alias'),
    ip_address: Yup.string().required('Debes ingresar una Dirección Ip')
      .matches(
        /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/,
        'Ingresa una dirección IP válida'
      ),
    heartbeat: Yup.number()
      .required('Debes ingresar un Intervalo de Solicitud')
      .nullable()
      .typeError('Debes ingresar un número')
      .min(0, 'Debe ser mayor o igual a 0')
      .max(99, 'Deben ser menos que 2 caracteres'),
    company_id: Yup.string().required('Debes seleccionar una Empresa'),
    branch_office_id: Yup.string().required('Debes seleccionar un Lugar de Prestación de Servicios')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ControlClockForm);
