import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexBranchOfficesRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', `/admin/branch_offices`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexBranchOfficesRequest = AwesomeDebouncePromise(indexBranchOfficesRequest, 300);
