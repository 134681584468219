import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import moment from 'moment';
import { Accordion, Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormikInput, FormikRangePicker, FormikSelect, InputSelect } from '../..';
import { debounceIndexEmployeesRequest, debounceIndexSelectEmployeesRequest } from '../../../requests/employees';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';
import { markingTypes } from '../../../screens/CompanyReports/formOptions';

const ALL_SELECTED_LABEL = 'Todos';

const MarkingsReportFilters = props => {
  const { errors, touched, setFieldValue, values, filterRRHH = true, isSubmit } = props;
  const [accordionKey, setAccordionKey] = useState('0');
  const [branchOffices, setBranchOffices] = useState([]);
  const [employees, setEmployees] = useState([]);
  const dispatch = useDispatch();

  const fetchEmployees = () => {
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        active: true,
        sort_column: 'name',
        is_dt: false,
        display_length: 100,
        filter_active_branch_offices: branchOffices
      },
      successCallback: response => {
        const dataToGroupSearch = [{ id: 'Todos', label: 'Todos', value: 'Todos' }, ...response.data.data];
        setEmployees(response.data.data.length > 0 ? dataToGroupSearch : []);
      }
    });
  };

  const fetchEmployee = (inputValue, callback) => {
    debounceIndexSelectEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        sort_column: 'name',
        is_dt: false,
        display_length: 100,
        active_contracts: true
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchBranchOffices = (inputValue, callback) => {
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const hasAllSelection = (groupSelection = []) => {
    return groupSelection.some(option => option.value === ALL_SELECTED_LABEL);
  };

  const handleDisabledSelection = (options, selectedOptions) => {
    if (hasAllSelection(selectedOptions)) {
      return true;
    }
    if (selectedOptions.length === 0) return false;
    return options.value === ALL_SELECTED_LABEL;
  };

  const handleEmployeesSearch = (field, data) => {
    switch (field) {
      case 'filter_active_branch_offices':
        setBranchOffices(data);
        break;
      default:
        break;
    }
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (newData.length && newData.includes(ALL_SELECTED_LABEL)) {
      newData.push('');
      return;
    }

    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData);
    handleEmployeesSearch(field, newData);
  };

  const workSchedules = [
    {
      label: 'Semanal',
      value: [
        moment()
          .subtract(1, 'weeks')
          .format('L'),
        moment().format('L')
      ]
    },
    {
      label: 'Quincenal',
      value: [
        moment()
          .subtract(15, 'days')
          .format('L'),
        moment().format('L')
      ]
    },
    {
      label: 'Mensual',
      value: [
        moment()
          .subtract(1, 'months')
          .format('L'),
        moment().format('L')
      ]
    },
    {
      label: 'Anual',
      value: [
        moment()
          .subtract(1, 'years')
          .format('L'),
        moment().format('L')
      ]
    }
  ];

  useEffect(() => {
    fetchEmployees(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (branchOffices.length > 0) {
      fetchEmployees();
    }
    // eslint-disable-next-line
  }, [branchOffices]);

  return (
    <Form>
      <Accordion defaultActiveKey="0">
        <Card>
          <Col className="top-header-green">
            <Accordion.Toggle
              as={Col}
              defaultActiveKey="0"
              eventKey={accordionKey}
              onClick={() => setAccordionKey('0')}
              className="card-header-title"
            >
              FILTROS DE BÚSQUEDA
            </Accordion.Toggle>
          </Col>
          <Accordion.Collapse eventKey={accordionKey} style={{ marginBottom: 60 }}>
            <>
              <Row>
                <Col className="mt-2">
                  <Card className="card-dt">
                    <Col className="top-header-green-dt card-header-title-dt">
                      Rango de Fechas o Periodo Predeterminado
                    </Col>
                    <Card.Body className="div-content card-body-padding">
                      <Row>
                        <Col sm={12} md={6} xs={12}>
                          <Field name="date_range">
                            {({ field }) => (
                              <FormikRangePicker
                                {...field}
                                abbr
                                startDateName="date_from_workday"
                                endDateName="date_to_workday"
                                startDate={values.date_from_workday}
                                endDate={values.date_to_workday}
                                showClearDates
                                error={getIn(errors, field.name)}
                                touched={getIn(touched, field.name)}
                              />
                            )}
                          </Field>
                        </Col>
                        <Col sm={12} md={6} xs={12}>
                          <Field name="date_range">
                            {({ field }) => (
                              <FormikSelect
                                {...field}
                                label="Periodo"
                                options={workSchedules}
                                onChange={data => {
                                  setFieldValue(field.name, data ? data.value : '');
                                  setFieldValue('date_from_workday', data?.value[0] || '');
                                  setFieldValue('date_to_workday', data?.value[1] || '');
                                }}
                                error={getIn(errors, field.name)}
                                touched={getIn(touched, field.name)}
                              />
                            )}
                          </Field>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {filterRRHH && (
                <>
                  <Row>
                    <Col md={6}>
                      <Card className="card-dt">
                        <Col className="top-header-green-dt card-header-title">Búsqueda Individual</Col>
                        <Card.Body className="div-content card-body-padding">
                          <Row>
                            <Col sm={12} md={6} xs={12} className="mt-2">
                              <Field name="employee">
                                {({ field }) => (
                                  <InputSelect
                                    {...field}
                                    label="Nombre y Apellido"
                                    placeholder="Seleccione colaborador"
                                    value={values.employee}
                                    isClearable
                                    request={fetchEmployee}
                                    onChange={data => {
                                      setFieldValue(field.name, data || '');
                                      setFieldValue('filter_id', data?.value || '');
                                      setFieldValue('employees', []);
                                      setFieldValue('filter_ids', []);
                                    }}
                                    error={getIn(errors, field.name)}
                                    touched={getIn(touched, field.name)}
                                  />
                                )}
                              </Field>
                            </Col>
                            <Col sm={12} md={6} xs={12} className="mt-2">
                              <Field name="filter_national_id">
                                {({ field }) => (
                                  <FormikInput
                                    {...field}
                                    label="RUT"
                                    placeholder="Ingrese el RUT a Buscar"
                                    error={getIn(errors, field.name)}
                                    touched={getIn(touched, field.name)}
                                  />
                                )}
                              </Field>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card className="card-dt">
                        <Col className="top-header-green-dt card-header-title">Búsqueda Grupal</Col>
                        <Card.Body className="div-content card-body-padding">
                          <Row>
                            <Col className="mt-2">
                              <Field name="employees">
                                {({ field }) => (
                                  <FormikSelect
                                    {...field}
                                    label="Nombres y Apellidos"
                                    isMulti
                                    options={employees}
                                    placeholder="Seleccione colaboradores"
                                    onChange={data => {
                                      handleNewMulti(data || [], 'filter_ids', true);
                                      setFieldValue(field.name, data || []);
                                      setFieldValue('employee', '');
                                      setFieldValue('filter_id', '');
                                    }}
                                    isOptionDisabled={handleDisabledSelection}
                                    isClearable
                                    error={getIn(errors, field.name)}
                                    touched={getIn(touched, field.name)}
                                  />
                                )}
                              </Field>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="card-dt">
                        <Col className="top-header-green-dt card-header-title">Búsqueda Grupal Listado</Col>
                        <Card.Body className="div-content card-body-padding">
                          <Row>
                            <Col sm={12} md={6} xs={12} className="mt-2">
                              <Field name="branch_offices">
                                {({ field }) => (
                                  <InputSelect
                                    {...field}
                                    label="Lugar de Prestación de Servicios"
                                    isMulti
                                    menuPlacement="top"
                                    isOptionDisabled={handleDisabledSelection}
                                    placeholder="Seleccione Lugar de Prestación de Servicios"
                                    values={values.filter_active_branch_offices}
                                    request={fetchBranchOffices}
                                    onChange={data => {
                                      handleNewMulti(data || [], 'filter_active_branch_offices', true);
                                      setFieldValue(field.name, data || []);
                                    }}
                                    error={getIn(errors, field.name)}
                                    touched={getIn(touched, field.name)}
                                  />
                                )}
                              </Field>
                            </Col>
                            <Col sm={12} md={6} xs={12} className="mt-2">
                              <Field name="filter_by_time_type">
                                {({ field }) => (
                                  <FormikSelect
                                    {...field}
                                    label="Tipo de Marcación"
                                    menuPlacement="top"
                                    placeholder="Seleccione Tipo de Marcación"
                                    options={markingTypes}
                                    isClearable
                                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                                    error={getIn(errors, field.name)}
                                    touched={getIn(touched, field.name)}
                                  />
                                )}
                              </Field>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="d-flex justify-content-end mt-4">
                <Col md={2}>
                  <Button
                    className="btn-block ellipsis"
                    disabled={isSubmit}
                    type="submit"
                    onClick={() => {
                      setFieldValue('type', null);
                      if (values?.date_range?.length > 0) {
                        setAccordionKey('1');
                      }
                    }}
                  >
                    PreVisualizar
                  </Button>
                </Col>
              </Row>
            </>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  date_range: Yup.array().when('filter_offday', {
    is: true,
    then: Yup.array().rangedate(true)
  })
});

const setInitialValues = props => {
  const { filterOffday = true } = props;
  return {
    date_from_workday: '',
    date_to_workday: '',
    date_range: [],
    filter_id: '',
    filter_ids: [],
    filter_national_id: '',
    filter_active_job_managements: [],
    filter_active_job_titles: [],
    filter_active_shifts: [],
    filter_active_branch_offices: [],
    filter_work_schedule: '',
    filter_by_time_type: '',
    filter_offday: filterOffday,
    type: null
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: false
})(MarkingsReportFilters);
