import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import moment from 'moment';
import {
  DefaultModal,
  SimpleCenteredModal,
  ImportModal,
  ComponentDataTable,
  ButtonTooltip,
  Icon,
  ApprovementStatus
} from '../../../components';
import { indexLoanInstallmentsRequest } from '../../../requests/loanInstallments';
import columns from './Columns';
import { useAuthorization } from '../../../services/hooks';
import ability from '../../../config/ability';
import MassActions from './MassActions';
import LoanShow from './LoanShow';
import useLoanInstallmentsHook from './useLoanInstallmentHooks';
import LoanInstallmentsFilter from './LoanInstallmentsFilter';
import LoanForm from '../Loan/LoanForm';
import { sendAlert } from '../../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import {
  importTemplateLoanRequest,
  preImportLoanRequest,
  updateLoanRequest,
  importLoanRequest
} from '../../../requests/loans';
import useLoanProcessHook from '../useLoanProcessHooks';

const ExpandedComponent = ({ data, handleActions, permissions }) => {
  return (
    <Card>
      <Card.Body className="div-content-expander">
        <Row>
          <Col>
            <div className="info">
              <span className="type">PRIMER DESCUENTO:</span>
              <span className="line" />
              <span className="answer"> {data.parsed_first_discount_date}</span>
            </div>
            <div className="info">
              <span className="type">TOTAL CUOTAS:</span>
              <span className="line" />
              <span className="answer"> {data.total_installments}</span>
            </div>
            <div className="info">
              <span className="type">MONTO CRÉDITO:</span>
              <span className="line" />
              <span className="answer"> {data.parsed_loan_amount}</span>
            </div>
            <div className="info">
              <span className="type">VALOR CUOTA:</span>
              <span className="line" />
              <span className="answer"> {data.parsed_amount}</span>
            </div>
            <div className="info">
              <span className="type">ESTADO:</span>
              <span className="line" />
              <span className="answer">
                <ApprovementStatus status={data.status} translatedStatus={data.parsed_status} margin="my-0" />
              </span>
            </div>
            <div className="info">
              <span className="type">ACCIONES:</span>
              <span className="line" />
              <>
                <ButtonTooltip
                  onClick={() => handleActions(data, 'show')}
                  variant="circle-info"
                  className="btn-circle"
                  size="sm"
                  text="Ver"
                >
                  <Icon icon="eye" />
                </ButtonTooltip>
                <ButtonTooltip
                  onClick={() => handleActions(data, 'edit')}
                  variant="circle-warning"
                  className="btn-circle"
                  size="sm"
                  text="Editar"
                  disabled={
                    data?.loan_process?.status === 'closed' ||
                    !permissions.manage ||
                    data?.origin_type === 'request' ||
                    data?.installment_number > 1 ||
                    data.status === 'deferred'
                  }
                >
                  <Icon icon="pencil" />
                </ButtonTooltip>
                <ButtonTooltip
                  onClick={() => handleActions(data, 'postpone')}
                  variant="circle-dark"
                  className="btn-circle"
                  size="sm"
                  text="Postergar"
                  disabled={
                    data?.loan_process?.status === 'closed' || !permissions.manage || data?.status === 'deferred'
                  }
                >
                  <Icon icon="close" />
                </ButtonTooltip>
              </>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const LoanInstallmentsDataTable = ({
  moreData,
  setMoreData,
  setReload,
  loanProcessId,
  loanProcess,
  reqAmountLimit,
  reqPercentageLimit,
  setLoanUpdate,
  balanceOptions
}) => {
  const [loanInstallments, setLoanInstallments] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filters, setFilters] = useState({});
  const [amount, setAmount] = useState(0);
  const [sortParams, setSortParams] = useState({});
  const [loanInstallmentsIds, setLoanInstallmentsIds] = useState([]);
  const [confirmModalState, setConfirmModalState] = useState({
    title: '',
    body: '',
    show: false
  });
  const [errorModalState, setErrorModalState] = useState({
    title: '',
    body: '',
    show: false
  });
  const [modalAction, setModalAction] = useState(() => {});
  const [modalParams, setModalParams] = useState([]);
  const [modalState, setModalState] = useState({
    title: '',
    body: '',
    show: false,
    size: 'md'
  });
  const dispatch = useDispatch();
  const isMobile = window.innerWidth <= 767;

  const {
    handlePostponeInstallment,
    handleDownloadInstallments,
    handleImportModalExceptions
  } = useLoanInstallmentsHook();

  const { handleSortKeys } = useLoanProcessHook();

  const permissions = {
    manage: useAuthorization('manage', 'LoanProcess.LoanInstallment')
  };

  const handleRequest = async params => {
    setOnRequest(true);
    setSortParams(params);
    indexLoanInstallmentsRequest({
      dispatch,
      params: {
        filter_process_id: loanProcessId,
        ...params,
        ...filters
      },
      successCallback: response => {
        setLoanInstallments(response.data.data);
        setAmount(response?.data?.metadata?.amount || 0);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleAfterUpdateLoan = () => {
    setLoanUpdate(val => !val);
    setModalState(state => ({ ...state, show: false }));
  };

  const handleUpdateLoan = (values, setSubmitting = () => {}) => {
    updateLoanRequest(values?.loan?.id, {
      dispatch,
      params: snakecaseKeys(values),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Préstamo actualizado correctamente' }));
        handleAfterUpdateLoan();
      },
      callback: () => {
        setSubmitting(false);
      }
    });
  };

  const handleFilterAdvance = async params => {
    setFilters(params);
    setMoreData(!moreData);
  };

  const handleImportLoanRequest = () => {
    setLoanUpdate(val => !val);
    setMoreData(val => !val);
  };

  const handleSelectedRows = item => {
    if (item) {
      const ids = item.selectedRows.map(row => row.id);
      setLoanInstallmentsIds(ids);
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'export': {
        const sortKeys = handleSortKeys(sortParams);
        const exportFilters =
          loanInstallmentsIds.length === 0 ? { paginate: false } : { filter_ids: [...loanInstallmentsIds] };
        const params = {
          filter_process_id: loanProcessId,
          ...exportFilters,
          ...filters,
          ...sortKeys
        };
        handleDownloadInstallments(params, setClearSelectedRows, setMoreData, setOnRequest);
        break;
      }
      case 'import':
        setModalState({
          title: 'Importar Préstamos / Créditos',
          body: (
            <ImportModal
              onDropUploaded={preImportLoanRequest}
              handleTemplate={importTemplateLoanRequest}
              onHide={importLoanRequest}
              hideModal={() => setModalState(state => ({ ...state, show: false }))}
              updateData={() => handleImportLoanRequest()}
              handleExceptions={(...params) => handleImportModalExceptions(...params, setErrorModalState)}
              loanProcess
            />
          ),
          show: true,
          size: 'md'
        });
        break;
      default:
        break;
    }
  };

  const handleConfirm = (action, item) => {
    switch (action) {
      case 'postpone':
        setModalParams([item.id, setMoreData, setOnRequest, setConfirmModalState, setReload]);
        setModalAction(() => handlePostponeInstallment);
        break;
      case 'edit':
        setModalParams([item.id, setMoreData, setOnRequest, setConfirmModalState, setReload]);
        setModalAction(() => handleUpdateLoan);
        break;
      default:
        break;
    }
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        setModalState({
          title: 'Préstamo / Crédito',
          body: <LoanShow item={item} />,
          show: true
        });
        break;
      case 'postpone':
        setConfirmModalState({
          title: 'Anular Solicitud',
          body: '¿Estás seguro de que deseas anular la cuota a descontar para este mes?',
          show: true
        });
        setModalAction(() => handleConfirm('postpone', item));
        break;
      case 'edit': {
        const object = {
          ...item.loan,
          start_date: moment(item.loan.start_date, 'YYYY-MM-DD').format('MM/YYYY'),
          grant_date: item.loan.grant_date ? moment(item.loan.grant_date, 'YYYY-MM-DD').format('MM/YYYY') : '',
          parsed_amount: `${item.loan_amount}`,
          amount: item.loan_amount,
          employee: { label: item.employee.label, value: item.employee.value },
          installment_amount: item.amount
        };
        setModalState({
          title: `Editar Préstamo / Crédito ${item.loan.name}`,
          body: (
            <LoanForm
              loan={camelCaseEmptyStringRecursive(object)}
              dataEntry
              newLoan
              editing
              rrhh
              balanceOptions={balanceOptions}
              loanProcess={loanProcess}
              formRequest={handleUpdateLoan}
              reqAmountLimit={reqAmountLimit}
              reqPercentageLimit={reqPercentageLimit}
              btnMessage="Actualizar"
              handleModalClose={() => setModalState(state => ({ ...state, show: false }))}
            />
          ),
          show: true
        });
        break;
      }
      default:
        break;
    }
  };
  const sortColumnCase = name => {
    switch (name) {
      case 'amount':
        return { sort_amount: name };
      case 'employee_name':
        return { sort_employees: name };
      case 'employee_status':
        return { sort_employee_status: name };
      case 'origin':
        return { sort_loan_origin: name };
      case 'parsed_first_discount_date':
        return { sort_first_discount_date: name };
      case 'parsed_loan_amount':
        return { sort_loan_amount: name };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };
  return (
    <>
      <Accordion defaultActiveKey="1" className="ml-2 mt-2 mr-2">
        <LoanInstallmentsFilter formRequest={handleFilterAdvance} />
      </Accordion>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, permissions, isMobile)}
        data={loanInstallments}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        selectableRows={ability.can('manage', 'LoanProcess.LoanInstallment')}
        clearSelectedRows={clearSelectedRows}
        resourceRequest={handleRequest}
        pointerOnHover
        massActions={<MassActions handleMassAction={handleMassAction} />}
        withMassActions={ability.can('manage', 'LoanProcess.LoanInstallment')}
        onSelectedRowsChange={handleSelectedRows}
        expandableRows={isMobile}
        expandableRowExpanded={() => isMobile}
        expandableRowsComponent={
          isMobile ? (
            <ExpandedComponent handleActions={handleButtonClick} isMobile={isMobile} permissions={permissions} />
          ) : (
            undefined
          )
        }
        expandableRowsHideExpander
        withSearch={false}
      />
      <SimpleCenteredModal
        size={modalState.size}
        title={modalState.title}
        body={modalState.body}
        show={modalState.show}
        onHide={() => setModalState(state => ({ ...state, show: false }))}
      />
      <SimpleCenteredModal
        size="md"
        title={errorModalState.title}
        body={errorModalState.body}
        show={errorModalState.show}
        onHide={() => setErrorModalState(state => ({ ...state, show: false }))}
      />
      <DefaultModal
        title={confirmModalState.title}
        body={confirmModalState.body}
        show={confirmModalState.show}
        handleClose={() => setConfirmModalState(olDstate => ({ ...olDstate, show: false }))}
        handleConfirm={() => modalAction(...modalParams)}
        disabled={onRequest}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default LoanInstallmentsDataTable;
