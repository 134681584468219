import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { withFormik, Form, getIn, Field } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { Calendar, FormikCheckBox, FormikInput, FormikNumber, FormikMaterialUiTimePicker } from '../../../components';

const ShiftMonth = ({ values, errors, touched, setFieldValue, shift }) => {
  const [events, setEvents] = useState([]);

  const validEvent = newEvent => !events.some(item => item.title === newEvent.title && item.date === newEvent.date);

  const setValidEvents = eventArray => {
    if (!eventArray) return [];
    return eventArray.filter(item => item.send).map(item => ({ title: item.title, date: item.date, id: item.id }));
  };

  const onDropEvent = ({ dateStr, draggedEl }) => {
    const { firstChild } = draggedEl;
    const [startTime, endTime] = firstChild.data.replace(/\s/g, '').split('-');
    const { shiftDaysAttributes } = values.shift;
    const newEvent = { title: firstChild.data, startTime, endTime, date: dateStr, send: true };
    if (validEvent(newEvent)) {
      setEvents(setValidEvents([...shiftDaysAttributes, newEvent]));
      setFieldValue('shift[shiftDaysAttributes]', [...shiftDaysAttributes, newEvent]);
    }
  };

  const { flexibleShift, collationTime } = values.shift;

  useEffect(() => {
    setEvents(shift.shiftDaysAttributes.map(item => ({ id: item.id, title: item.title, date: item.date })));
  }, [shift]);

  return (
    <>
      <Form>
        <Row>
          <Col md={6}>
            <Field name="shift[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  disabled
                  label="Nombre"
                  placeholder="NOMBRE DE TURNO"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2}>
            <Field name="shift[parsedTolerance]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr
                  disabled
                  label="Tolerancia"
                  rightAddon="min"
                  placeholder="0"
                  fieldName="shift[tolerance]"
                  value={values.shift.tolerance}
                  tooltipSize="21"
                  tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2} className="mt-4 d-flex align-items-center">
            <Field name="shift[workHolidays]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  disabled
                  field={field}
                  label="Trabaja festivos"
                  tooltipSize="23"
                  tooltipText="Turno con días festivos incluídos"
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={2} className="mt-4 d-flex align-items-center">
            <Field name="shift[flexibleShift]">
              {({ field }) => (
                <FormikCheckBox {...field} custom field={field} disabled label="Turno flexible" tooltipSize="23" />
              )}
            </Field>
          </Col>
          {flexibleShift && (
            <>
              <Col xs={4} md={2}>
                <Field name="shift[initialTime]">
                  {({ field }) => (
                    <FormikMaterialUiTimePicker
                      {...field}
                      abbr
                      disabled
                      timeSelector
                      label="Hora de Inicio"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={4} md={2}>
                <Field name="shift[maximumStartTime]">
                  {({ field }) => (
                    <FormikMaterialUiTimePicker
                      {...field}
                      abbr
                      disabled
                      timeSelector
                      label="Tiempo Max. Inicio"
                      onChange={time => setFieldValue(field.name, time)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={4} md={2}>
                <Field name="shift[workHours]">
                  {({ field }) => (
                    <FormikMaterialUiTimePicker
                      {...field}
                      abbr
                      disabled
                      timeSelector
                      label="Horas Trabajo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
        </Row>
        <Row className="mt-1">
          <Col xs={12} md={2} className="d-flex align-items-center">
            <Field name="shift[lunchCheck]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  disabled
                  field={field}
                  label="Marcar colación"
                  tooltipSize="23"
                  tooltipText="Indica si deben marcar el ingreso y salida de colación"
                />
              )}
            </Field>
          </Col>
          <Col xs={12} md={2} className="d-flex align-items-center">
            <Field name="shift[agreedSchedule]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  disabled
                  field={field}
                  label="Horario pactado"
                  tooltipSize="23"
                  tooltipText="Indica que deben marcar el ingreso y salida de colación en cada uno de los días seleccionados"
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2} className="d-flex align-items-center">
            <Field name="shift[collationTime]">
              {({ field }) => (
                <FormikCheckBox {...field} custom disabled field={field} label="Tiempo de colación" tooltipSize="23" />
              )}
            </Field>
          </Col>
          {collationTime && (
            <>
              <Col xs={6} md={2} className="d-flex align-items-center">
                <Field name="shift[parsedLunchTime]">
                  {({ field }) => (
                    <FormikNumber
                      {...field}
                      abbr
                      disabled
                      rightAddon="min"
                      placeholder="0"
                      label="Colación"
                      fieldName="shift[lunchTime]"
                      value={values.shift.lunchTime}
                      tooltipSize="21"
                      tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
          <Col xs={12} md={2} className="d-flex align-items-center">
            <Field name="shift[weekends]">
              {({ field }) => (
                <FormikCheckBox {...field} custom field={field} label="Incluir fines de semana" disabled />
              )}
            </Field>
          </Col>
        </Row>
        <hr />
        <Calendar events={events} drop={onDropEvent} />
        <hr />
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const { shiftDaysAttributes } = props.shift;
  let shiftDays = [];
  if (shiftDaysAttributes.length) {
    shiftDays = shiftDaysAttributes.map(item => ({ ...item, send: true }));
  }

  return {
    shift: {
      ...props.shift,
      shiftDaysAttributes: shiftDays,
      shiftType: 'monthly'
    }
  };
};

const validationSchema = Yup.object().shape({
  shift: Yup.object().shape({
    lunchCheck: Yup.boolean(),
    workHolidays: Yup.boolean(),
    tolerance: Yup.number()
      .required('Debes ingresar un número')
      .min(0, 'Debe ser mayor o igual a 0'),
    lunchTime: Yup.number()
      .required('Debes ingresar tiempo de colación')
      .min(0, 'Debe ser mayor o igual a 0'),
    name: Yup.string().required('Debes ingresar un nombre'),
    shiftDaysAttributes: Yup.array().of(
      Yup.object().shape({
        endTime: Yup.string().required('Debes ingresar hora de término'),
        startTime: Yup.string().required('Debes ingresar hora de inicio')
        // date: Yup.date()
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ShiftMonth);
