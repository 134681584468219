import React, { useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es-mx';
import { useFormikContext } from 'formik';
import { Form, InputGroup, ButtonToolbar, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { DateRangePicker } from 'react-dates';
import { ICON_AFTER_POSITION } from 'react-dates/constants';
import RenderMonthElement from './RenderMonthElement';
import Icon from '../../Icons';
import './style.scss';

const FormikRangePicker = ({
  abbr,
  delayHide,
  delayShow,
  direction,
  disabled,
  endDate,
  endDateName,
  error,
  helpText,
  labelLeft,
  labelRight,
  margin,
  minCurrentDate,
  name,
  startDate,
  startDateName,
  tooltipText,
  touched,
  isArial = false,
  ...props
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  moment.locale('es-mx');
  const [focus, setFocus] = useState(undefined);
  let startDateValue = null;
  let endDateValue = null;
  const isMobile = window.innerWidth <= 768;
  if (moment.isMoment(startDate)) startDateValue = startDate;
  else if (startDate) startDateValue = moment(startDate, 'DD/MM/YYYY');
  if (moment.isMoment(endDate)) endDateValue = endDate;
  else if (endDate) endDateValue = moment(endDate, 'DD/MM/YYYY');

  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${margin} ${error && touched ? 'is-invalid' : ''}`}>
      <Row>
        <Col xs={5} className="pr-0">
          <Form.Label className="d-flex align-items-center">
            <span className={`${isArial ? 'arial-dt' : ''}`}>{labelLeft}</span>{' '}
            {abbr && <abbr className={`text-danger ml-1 ${isArial ? 'arial-dt' : ''}`}>*</abbr>}
            {tooltipText && (
              <ButtonToolbar className="ml-2">
                <OverlayTrigger
                  key={direction}
                  placement={direction}
                  delay={{ show: delayShow, hide: delayHide }}
                  overlay={<Tooltip>{tooltipText}</Tooltip>}
                >
                  <Icon icon="help-circle" height="15px" width="15px" />
                </OverlayTrigger>
              </ButtonToolbar>
            )}
          </Form.Label>
        </Col>
        <Col xs={{ span: 6, offset: 1 }} className="pl-0">
          <Form.Label className="d-flex align-items-center">
            <span className={`${isArial ? 'arial-dt' : ''}`}>{labelRight}</span>{' '}
            {abbr && <abbr className={`text-danger ml-1 ${isArial ? 'arial-dt' : ''}`}>*</abbr>}
          </Form.Label>
        </Col>
      </Row>
      <InputGroup>
        <DateRangePicker
          startDate={startDateValue}
          startDateId={startDateName}
          endDate={endDateValue}
          endDateId={endDateName}
          onDatesChange={({ startDate: startDateV, endDate: endDateV }) => {
            const startValue = moment.isMoment(startDateV) ? startDateV.format('DD/MM/YYYY') : '';
            const endValue = moment.isMoment(endDateV) ? endDateV.format('DD/MM/YYYY') : '';
            setFieldValue(startDateName, startValue);
            setFieldValue(endDateName, endValue);
            setFieldTouched(name);
            setFieldValue(name, startValue && endValue ? [startValue, endValue] : []); // Important to be the last one
          }}
          isOutsideRange={() => false}
          focusedInput={focus}
          onFocusChange={focused => setFocus(focused)}
          startDatePlaceholderText="dd/mm/aaaa"
          endDatePlaceholderText="dd/mm/aaaa"
          block
          numberOfMonths={isMobile ? 1 : 2}
          customInputIcon={<Icon icon="calendar" width={`${isArial ? 15 : 18}`} />}
          hideKeyboardShortcutsPanel
          customArrowIcon={<Icon icon="chevron-forward" width={`${isArial ? 15 : 18}`} />}
          inputIconPosition={ICON_AFTER_POSITION}
          firstDayOfWeek={1}
          renderMonthElement={RenderMonthElement}
          minimumNights={0}
          {...props}
        />
      </InputGroup>
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikRangePicker.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  margin: PropTypes.string,
  tooltipText: PropTypes.string
};

FormikRangePicker.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  labelLeft: 'Desde',
  labelRight: 'Hasta',
  margin: '',
  tooltipText: ''
};

export default FormikRangePicker;
