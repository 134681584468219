/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { DatatableEmployeeName } from '../index';

const columns = memoize((clickHandler = true) => [
  {
    name: 'TRABAJADOR',
    selector: 'employee_full_name',
    cell: item => (
      <DatatableEmployeeName item={item.employee} withPhoto={false} onClick={() => clickHandler(item, 'show')} />
    ),
    sortable: true,
    grow: '2'
  },
  {
    name: 'CENTRO DE COSTO',
    selector: 'employee_cost_center',
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA',
    selector: 'real_marking_date',
    sortable: true,
    grow: '2'
  },
  {
    name: 'TURNO',
    selector: 'shift',
    cell: item => item.shift && item.shift.name,
    sortable: true,
    grow: '2'
  },
  {
    name: 'HORA',
    selector: 'time',
    cell: item => item.complete_parsed_time,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO',
    selector: 'time_type',
    cell: item => item.translated_time_type,
    sortable: true,
    grow: '1'
  }
]);

export default columns;
