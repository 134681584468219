import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const todayAbsencesReport = ({ dispatch, params, format, responseType, successCallback, callback }) => {
  ApiService.request('get', `/today_absences/attendance_report${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });
};

export const debounceTodayAbsencesReport = AwesomeDebouncePromise(todayAbsencesReport, 300);
