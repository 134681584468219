import React from 'react';

export default function ModalWarning({ selectedRows }) {
  const closedRows = selectedRows.filter(row => row.status === 'closed');

  return (
    <>
      {closedRows.length > 0 && (
        <>
          <p>
            Se han seleccionado Préstamos que se encuentran <b>CERRADOS</b> y no serán tomados en cuenta:
          </p>
          <ul>
            {closedRows.map(row => (
              <li>{row.parsed_month}</li>
            ))}
          </ul>
        </>
      )}
    </>
  );
}
