import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon } from '../../components';

export default memoize((clickHandler = () => {}) => [
  {
    name: 'MES',
    selector: 'month',
    grow: '1',
    sortable: true
  },
  {
    name: 'HABERES',
    selector: 'asset_total',
    grow: '1'
  },
  {
    name: 'DESCUENTOS',
    selector: 'discount_total',
    grow: '1'
  },
  {
    name: 'TOTAL LÍQUIDO',
    selector: 'liquid_total',
    grow: '1'
  },
  {
    name: 'IMPUESTO ÚNICO',
    selector: 'tax_total',
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => {
      switch (item.status) {
        case 'open':
          return (
            <span className="text-success" data-tag="allowRowEvents">
              Abierto
            </span>
          );
        case 'closed':
          return (
            <span className="text-danger" data-tag="allowRowEvents">
              Cerrado
            </span>
          );
        default:
          return (
            <span className="text-muted" data-tag="allowRowEvents">
              {item.parsed_status}
            </span>
          );
      }
    },
    grow: '1',
    sortable: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'edit')}
          variant="circle-warning"
          toolbarVariant="ml-auto"
          style={{ zIndex: 10 }}
          className="btn-circle"
          size="sm"
          text="Editar"
          disabled={item.status === 'closed'}
        >
          <Icon icon="pencil" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'destroy')}
          variant="circle-danger"
          toolbarVariant="ml-auto"
          style={{ zIndex: 10 }}
          className="btn-circle"
          size="sm"
          text="Eliminar"
          disabled={item.status === 'closed' && item.is_historical === false}
        >
          <Icon icon="trash" />
        </ButtonTooltip>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'close')}
          variant="circle-dark"
          className="btn-circle"
          toolbarVariant="ml-auto"
          style={{ zIndex: 10 }}
          disabled={item.status === 'closed'}
          size="sm"
          text="Cerrar Mes"
        >
          <Icon icon="lock" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    right: true,
    grow: '1',
    minWidth: '123px'
  }
]);
