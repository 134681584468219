import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Card, Col, Row, Button, Spinner } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { useHistory } from 'react-router-dom';
import DigitalSignImageEdit from '../../../screens/Employee/EmployeeForm/DigitalSignImageEdit';
import { updateEmployeeRequest } from '../../../requests/employees';
import Icon from '../../Icons';
import { sendAlert } from '../../../actions/utils';
import { useAuthorization } from '../../../services/hooks';
import CustomWebcam from '../../CustomWebCam';
import { UploadImage } from '../../Utils/Upload';
import { SimpleCenteredModal } from '../../Utils/Modal';

const PersonalData = ({ employee, setMoreData, moreData, onRequest, employeeProfile, activeAccordion, activeKey }) => {
  const {
    country,
    firstLastName,
    name,
    nationalIdentification,
    birthdate,
    nationalIdExpirationDate,
    profiles,
    secondLastName,
    translatedCivilState,
    gender,
    translatedIdentificationType,
    parsedDigitalSign,
    username
  } = employee;

  const profileNames = profiles.map(profile => profile.label);
  const collaborator = employee.collaborator ? 'Sí' : 'No';

  const history = useHistory();

  const canRecordCard = useAuthorization('update_as_employee', 'Employee');

  const canUpdateData = canRecordCard;

  const onlyIconButton = {
    padding: '0.2rem',
    marginRigth: 10
  };

  const [modalShow, setModalShow] = useState(false);
  const [fieldType, setFieldType] = useState('');
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const dispatch = useDispatch();

  const handleShowModal = field => {
    setFieldType(field);
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessUpdate = () => {
    setLoadingUpdate(false);
    dispatch(sendAlert({ kind: 'success', message: 'Trabajador actualizado con éxito' }));
    setModalShow(false);
    history.go(0);
  };

  const pickVariables = (_employee = {}, ...params) => ({
    ...params.reduce((prev, current) => ({ ...prev, [current]: _employee[current] }), {})
  });

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const { currentEmployee } = useSelector(state => state.auth);

  const handleUpdateRequest = (employeeValues, setSubmitting) => {
    setLoadingUpdate(true);
    const { employee: copyEmployee } = employeeValues;

    let myParams = {
      employee: { ...copyEmployee }
    };
    const keepVariables = {
      digitalSign: ['digitalSign']
    };

    myParams.employee = {
      ...pickVariables(myParams.employee, ...keepVariables.digitalSign),
      send_email_udpated_employee_signature: true,
      digital_sign: employeeValues.digitalSign
    };

    let testParams = { employee: { send_email_udpated_employee_signature: true } };

    testParams = snakeCaseKeys(testParams, {
      exclude: ['_destroy']
    });

    testParams.employee.digital_sign = employeeValues.employee.digitalSign;

    myParams = snakeCaseKeys(myParams, {
      exclude: ['_destroy']
    });

    const employeeId = employee.id || currentEmployee;

    updateEmployeeRequest(employeeId, {
      dispatch,
      params: testParams,
      formData: true,
      failureCallback: handleFailureRequest,
      successCallback: handleSuccessUpdate,
      callback: () => {
        setSubmitting(false);
        setLoadingUpdate(false);
      }
    });
  };

  return (
    <Card>
      <Col className={`${activeKey === 'personal-data' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
        <Accordion.Toggle as={Col} eventKey="0" onClick={activeAccordion} className="card-header-title">
          INFORMACIÓN PERSONAL
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="0">
        <Card.Body className="div-content">
          <Row>
            <Col>
              <div className="info">
                <span className="type">Nombres:</span>
                <span className="align-right" />
                <span className="answer"> {name}</span>
              </div>
              <div className="info">
                <span className="type">Primer Apellido:</span>
                <span className="align-right" />
                <span className="answer"> {firstLastName}</span>
              </div>
              <div className="info">
                <span className="type">Segundo Apellido:</span>
                <span className="align-right" />
                <span className="answer"> {secondLastName}</span>
              </div>
              <div className="info">
                <span className="type">Usuario del Sistema:</span>
                <span className="align-right" />
                <span className="answer"> {username}</span>
              </div>
              <div className="info">
                <span className="type">Fecha de nacimiento:</span>
                <span className="align-right" />
                <span className="answer"> {birthdate}</span>
              </div>
              <div className="info">
                <span className="type">Género:</span>
                <span className="align-right" />
                <span className="answer"> {gender?.label}</span>
              </div>
              <div className="info">
                <span className="type">Identificación ({translatedIdentificationType}):</span>
                <span className="align-right" />
                <span className="answer"> {nationalIdentification}</span>
              </div>
              <div className="info">
                <span className="type">Fecha de vencimiento Cédula de Identidad:</span>
                <span className="align-right" />
                <span className="answer"> {nationalIdExpirationDate}</span>
              </div>
              <div className="info">
                <span className="type">Nacionalidad:</span>
                <span className="align-right" />
                <span className="answer"> {country?.label}</span>
              </div>
              <div className="info">
                <span className="type">Estado civil:</span>
                <span className="align-right" />
                <span className="answer"> {translatedCivilState}</span>
              </div>
              <div className="info">
                <span className="type">Permisos:</span>
                <span className="align-right" />
                <span className="answer"> {profileNames.join(', ')}</span>
              </div>
              <div className="info">
                <span className="type">Es colaborador:</span>
                <span className="align-right" />
                <span className="answer"> {collaborator}</span>
              </div>
              <div className="info">
                <span className="type">Firma del Colaborador:</span>
                <span className="align-right" />
                <span className="answer"> {parsedDigitalSign}</span>
                {canUpdateData && employeeProfile && (
                  <>
                    <Button
                      disabled={onRequest}
                      size="sm"
                      className="ml-3"
                      style={onlyIconButton}
                      onClick={() => {
                        setMoreData(!moreData);
                        handleShowModal(true);
                      }}
                    >
                      <Icon icon="pencil" className="icon-15" />
                    </Button>
                  </>
                )}
              </div>
              {onRequest && (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>

      <SimpleCenteredModal
        title="Datos de contacto"
        body={
          <DigitalSignImageEdit
            fieldType={fieldType}
            employee={employee}
            CustomWebcam={CustomWebcam}
            UploadImage={UploadImage}
            formRequest={handleUpdateRequest}
            handleModalClose={handleModalClose}
            dispatch={dispatch}
            loadingUpdate={loadingUpdate}
          />
        }
        show={modalShow && !onRequest}
        centered
        size="lg"
        onHide={() => setModalShow(false)}
      />
    </Card>
  );
};

export default PersonalData;
