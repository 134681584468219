import { useDispatch } from 'react-redux';
import { indexEmployeesRequest } from '../../../requests/employees';

const PrintFunctions = () => {
  const dispatch = useDispatch();

  const getEmployeeIds = async params => {
    return new Promise(res => {
      indexEmployeesRequest({
        dispatch,
        params: {
          ...params,
          paginate: false,
          active: true,
          is_dt: false,
          filter_work_schedules: 'full_time,part_time,article_22',
          sort_direction: 'asc',
          sort_column: 'name'
        },
        responseType: 'json',
        successCallback: response => {
          const { data } = response;
          res(data.data);
        },
        callback: () => res([])
      });
    });
  };
  return {
    getEmployeeIds
  };
};

export default PrintFunctions;
