import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import memoize from 'memoize-one';
import {
  ComponentDataTable,
  ButtonTooltip,
  Icon,
  SimpleCenteredModal,
  DefaultModal
} from '../../../components';
import { sendAlert } from '../../../actions/utils';
import { deactivateAdminAttendanceControlClockRequest, debounceIndexAdminAttendancesControlClockRequest } from '../../../requests/adminAttendancesControlClock';
import AttendancesControlClockShow from './AttendancesControlClockShow';

const columns = memoize(clickHandler => [
  {
    name: 'EMPRESA',
    selector: 'company',
    cell: item => item.device.company.business_name,

    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'L. DE P. DE SERVICIOS',
    selector: 'branch_office',
    cell: item => item.device.branch_office.name,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'COD EMPLEADO',
    selector: 'emp_code',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'SERIAL DEL TERMINAL',
    selector: 'terminal_sn',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'TIPO DE VERIFICACIÓN',
    selector: 'verify_type',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'HORA DE MARCACIÓN',
    selector: 'punch_time',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'TIPO DE MARCACIÓN',
    selector: 'punch_state',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'SINCRONIZADO',
    selector: 'is_sync',
    cell: item => item.is_sync ? 'Si' : 'No',
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ERROR',
    selector: 'has_error',
    cell: item => item.has_error ? 'Si' : 'No',
    sortable: false,
    grow: '1',
    center: true
  },

  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>

        <ButtonTooltip
          onClick={() => clickHandler(item, 'change_status')}
          variant={`circle-${item.is_sync ? 'danger' : 'success'}`}
          className="btn-circle"
          size="sm"
          text={item.is_sync ? 'Desactivar' : 'Activar'}
        >
          <Icon icon="archive" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '160px'
  }
]);

const AttendancesControlClockDataTable = () => {
  const [onRequest, setOnRequest] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [modalItem, setModalItem] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalAction, setModalAction] = useState();
  const [modalSize, setModalSize] = useState('md');
  const [amount, setAmount] = useState(0);
  const [simpleModalTitle, setSimpleModalTitle] = useState();
  const [simpleModalBody, setSimpleModalBody] = useState(<></>);
  const [simpleModalShow, setSimpleModalShow] = useState();
  const [moreData, setMoreData] = useState(false);

  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setEmployees(data);
    setAmount(metadata.amount);
  };

  const handleRequest = async params => {
    setOnRequest(true);
    debounceIndexAdminAttendancesControlClockRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex,
      callback: () => setOnRequest(false)
    });
  };


  const handleShow = data => {
    setSimpleModalTitle(`Log Reloj Control`)
    setSimpleModalBody(<AttendancesControlClockShow data={data} />);
    setSimpleModalShow(true);
  }

  const handleChangeStatus = async item => {
    setOnRequest(true);
    deactivateAdminAttendanceControlClockRequest(item.id, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Acción realizada con éxito' }));
        setModalShow(false)
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'error', message: 'Error al realizar la acción' }));
        setModalShow(false)
      },
      callback: () => setMoreData(!moreData)

    })
  }

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        setModalSize('lg')
        handleShow(item);
        break;
      case 'change_status':
        setModalItem(item)
        setModalSize('md')
        setModalTitle('Cambiar Estado del Log')
        setModalBody('¿Esta seguro que desea cambiar el estado de este registro?')
        setModalShow(true)
        setModalAction(() => handleChangeStatus)
        break;

      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        break;
    }
  };



  const sortColumnCase = name => {
    switch (name) {
      case 'company':
        return { sort_company: name };
      case 'branch_office':
        return { sort_branch_office: name };
      default:
        return { sort_column: name };
    }
  };


  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions)}
        data={employees}
        moreData={moreData}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        resourceRequest={handleRequest}
        pointerOnHover
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        show={simpleModalShow}
        size={modalSize}
        onHide={() => setSimpleModalShow(false)}
      />
    </>
  );
};

export default AttendancesControlClockDataTable;
