import React from 'react';

import { camelCaseEmptyStringRecursive } from '../../services/utils';

const AttendanceInfo = ({ attendance }) => {
  const {
    realMarkingDate,
    late,
    lateMinutes,
    parsedTime,
    translatedTimeType,
    children,
    parent
  } = camelCaseEmptyStringRecursive(attendance);

  function Replace(props) {
    if (props.Children) {
      return (
        <div className="info-box">
          <h4> Reemplazando a: </h4>
          <p className="info">
            <span>Fecha:</span> {props.Children.realMarkingDate}
          </p>
          <p className="info">
            <span>Hora:</span> {props.Children.completeParsedTime}
          </p>
          <p className="info">
            <span>Tipo:</span> {props.Children.translatedTimeType}
          </p>
        </div>
      );
    }
    if (props.Parent) {
      return (
        <div className="info-box">
          <h4> Reemplazado por: </h4>
          <p className="info">
            <span>Fecha:</span> {props.Parent.realMarkingDate}
          </p>
          <p className="info">
            <span>Hora:</span> {props.Parent.completeParsedTime}
          </p>
          <p className="info">
            <span>Tipo:</span> {props.Parent.translatedTimeType}
          </p>
        </div>
      );
    }
    if (!props.Parent && !props.Children) {
      return null;
    }
  }

  return (
    <div className="info-box">
      <p className="info">
        <span>Fecha:</span> {realMarkingDate}
      </p>
      <p className="info">
        <span>Hora:</span> {parsedTime}
      </p>
      <p className="info">
        <span>Tipo:</span> {translatedTimeType}
      </p>
      {late && (
        <p className="info">
          <span>Entrada atrasada de:</span> {lateMinutes} Minutos
        </p>
      )}
      <Replace Children={children} Parent={parent} />
    </div>
  );
};

export default AttendanceInfo;
