import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import * as Yup from 'yup';
import { withFormik, Field, Form, getIn } from 'formik';
import { sendAlert } from '../../actions/utils';
import FormikInput from '../../components/Utils/Input/FormikInput';
import FormikCheckBox from '../../components/Utils/Input/FormikCheckBox';
import { changePinRequest } from '../../requests/user';

const ChangePin = props => {
  const { onHide, submitVariant, errors, touched, handleSignatureKey, setFieldValue, values } = props;
  const [addonCounter, setAddonCounter] = useState(0);

  // Third Attempts
  useEffect(() => {
    const timer = addonCounter > 0 && setInterval(() => setAddonCounter(addonCounter - 0.1), 100);
    return () => clearInterval(timer);
  }, [addonCounter]);

  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col md={11}>
        <Form className="d-flex flex-column justify-content-end mt-2">
          <div className="mx-3">
            <p>Seleccione la via donde se enviará el código de confirmación</p>
            <Row>
              {/* <Col md={6}>
                <Field name="user[send_sms]">
                  {({ field }) => (
                    <FormikCheckBox
                      {...field}
                      field={field}
                      label="Enviar SMS"
                      custom
                      onClick={() => {
                        setFieldValue(field.name, field.value);
                        setFieldValue('user[send_email]', field.value);
                      }}
                      tooltipClass="ml-2 mt-2"
                      tooltipText="Al marcar esta casilla se enviará el código de confirmación a través de un mensaje 
                                   de texto."
                    />
                  )}
                </Field>
              </Col> */}
              <Col md={6}>
                <Field name="user[send_email]">
                  {({ field }) => (
                    <FormikCheckBox
                      {...field}
                      field={field}
                      label="Enviar Email"
                      custom
                      onClick={() => {
                        setFieldValue(field.name, field.value);
                        setFieldValue('user[send_sms]', field.value);
                      }}
                      tooltipClass="ml-2 mt-2"
                      tooltipText="Al marcar esta casilla se enviará el código de confirmación a través del correo
                  electronico."
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Field name="user[confirmation_code]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  inputType="password"
                  placeholder="Código de Cambio de PIN"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  addon={addonCounter > 0 ? `REINTENTAR EN ${addonCounter.toFixed(0)}` : ''}
                  rightBtn={addonCounter > 0 ? '' : 'Enviar código'}
                  rightBtnClickHandler={() => {
                    handleSignatureKey({
                      resend: true,
                      send_email: values.user.send_email,
                      send_sms: values.user.send_sms
                    });
                    setAddonCounter(30);
                  }}
                />
              )}
            </Field>
            <Field name="user[pin]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  inputType="password"
                  placeholder="Nuevo PIN"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
            <Field name="user[pin_confirmation]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  inputType="password"
                  placeholder="Confirmar Nuevo PIN"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </div>
          <Button variant={submitVariant} size="lg" className="mt-4 ml-auto" type="submit" onClick={onHide}>
            CAMBIAR PIN
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { user, currentEmployee } = state.auth;
  return { user, currentEmployee };
};

const initialValues = {
  user: {
    confirmation_code: '',
    pin: '',
    pin_confirmation: '',
    send_email: true,
    send_sms: false
  }
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    confirmation_code: Yup.string()
      .required('Este campo es obligatorio')
      .min(6, 'La contraseña debe tener más de 6 caracteres'),
    pin: Yup.string()
      .required('Este campo es obligatorio')
      .min(6, 'La contraseña debe tener más de 6 caracteres'),
    pin_confirmation: Yup.string()
      .required('Este campo es obligatorio')
      .min(6, 'La contraseña debe tener más de 6 caracteres')
      .oneOf([Yup.ref('pin'), null], 'Las contraseñas no coinciden')
  })
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  changePinRequest({
    dispatch,
    params: snakeCaseKeys(values),
    successCallback: () => {
      const { setModalShow } = props;
      setModalShow(false);
      const message = 'PIN modificado con éxito';
      dispatch(sendAlert({ kind: 'success', message }));
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(ChangePin)
  )
);
