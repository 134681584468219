import React, { useState } from 'react';
import BalancesTable from './BalancesTable';
import PayrollBalancesIndex from './Form/PayrollBalancesIndex';
import BalancesDatatable from './BalancesDatatable';

const componentMap = {
  BalancesTable,
  PayrollBalancesIndex
};

const BalancesIndex = ({ onlyShow = false }) => {
  const [activeComponent, setActiveComponent] = useState('BalancesTable');
  const ActiveComponent = componentMap[activeComponent];

  return (
    <>
      {ActiveComponent && (
        <ActiveComponent
          setActiveComponent={setActiveComponent}
          onlyShow={onlyShow}
          BalancesComponent={activeComponent === 'BalancesTable' ? BalancesDatatable : ''}
        />
      )}
    </>
  );
};

export default BalancesIndex;
