import React, { useEffect, useMemo, useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { BasicTextArea, FormikCheckBox, FormikNumber, FormikTimePicker, InputSelect } from '../../components';
import { indexSelectEmployeesRequest, showEmployeeRequest } from '../../requests/employees';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { useAuthorization } from '../../services/hooks';
import { debounceIndexBalancesRequest } from '../../requests/balances';

const DataProcessesForm = ({
  errors,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
  employee,
  apProcessId,
  advancePayment,
  ...props
}) => {
  const { advanceSettings } = useSelector(state => state.utils);
  const dispatch = useDispatch();
  const fetchEmployees = (inputValue, callback) => {
    indexSelectEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        active_contracts: true,
        paginate: false,
        sort_column: 'name',
        sort_direction: 'asc'
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const { action, onHide, onlyShow, rrhh, employeeId } = props;
  const { payday, startDate, endDate } = values.advancePayment;
  const btnMessage = action === 'new' ? 'Ingresar' : 'Actualizar';
  const isDisabled = action === 'edit' && !payday;
  const today = new Date();
  const [currentSelectedEmployee, setCurrentSelectedEmployee] = useState({});
  const canShowEmployee = useAuthorization('record_card', 'Employee');
  const canShowContract = useAuthorization('contract_card', 'Contract');

  const selectedStartDate = useMemo(() => {
    if (!startDate || (startDate && startDate instanceof Date)) return startDate;
    const dateArray = startDate.split('/');
    return new Date(dateArray[1], dateArray[0], 1);
  }, [startDate]);

  const selectedEndDate = useMemo(() => {
    if (!endDate || (endDate && endDate instanceof Date)) return endDate;
    const dateArray = endDate.split('/');
    return new Date(dateArray[1], dateArray[0], 1);
  }, [endDate]);

  const resultFetchData = response => {
    const { data } = response.data;
    const tempArray = data.map(element => {
      return {
        label: element.name,
        value: element.id
      };
    });
    return tempArray;
  };

  const getEmployee = selectedEmployeeId => {
    if (selectedEmployeeId !== 'undefined' && canShowEmployee && canShowContract) {
      showEmployeeRequest(selectedEmployeeId, {
        dispatch,
        successCallback: response => setCurrentSelectedEmployee(camelCaseEmptyStringRecursive(response.data))
      });
    }
  };

  const apAmountLimitCalculation = () => {
    // Si no hay nadie seleccionado significa que se debe buscar la info del employee que se le pase al formulario
    if (Object.keys(currentSelectedEmployee).length === 0 && typeof employeeId !== 'undefined') {
      getEmployee(employeeId);
    }

    const advancePaymentLimitPercentage = advanceSettings.find(
      advanceSetting => advanceSetting.code === 'advance_payment_percentage_limit'
    );
    const advancePaymentAmountLimit = advanceSettings.find(
      advanceSetting => advanceSetting.code === 'advance_payment_amount_limit'
    );
    let amountLimit = 0;
    if (
      advancePaymentLimitPercentage.textValue === 'true' &&
      advancePaymentAmountLimit.textValue === 'false' &&
      typeof currentSelectedEmployee.contract !== 'undefined'
    ) {
      if ('contract' in currentSelectedEmployee && Object.keys(currentSelectedEmployee.contract).length > 1) {
        const percentage = advancePaymentLimitPercentage.integerValue;
        const advancePaymentAmountPercentageLimit = (currentSelectedEmployee.contract.salary * percentage) / 100;
        amountLimit = advancePaymentAmountPercentageLimit;
      }
    } else {
      amountLimit = advancePaymentAmountLimit.integerValue;
    }
    setFieldValue('advancePayment[apAmountLimit]', amountLimit);
  };

  const handleEmployeeSelection = selectedEmployeeId => {
    getEmployee(selectedEmployeeId);
  };

  const fetchBalanceAssets = (inputValue, callback) => {
    const request = async () => {
      debounceIndexBalancesRequest({
        dispatch,
        params: {
          selector: true,
          filter_type: 1,
          name: inputValue,
          filter_by_advance: 1
        },
        successCallback: response => callback(resultFetchData(response))
      });
    };
    request();
  };
  useEffect(apAmountLimitCalculation, [currentSelectedEmployee.id]);

  return (
    <Form className="mx-3">
      {isSubmitting && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      {(action === 'new' || action === 'items') && (
        <>
          <Row>
            {rrhh && action !== 'items' && (
              <Col md={12}>
                <Field name="advancePayment[employeeId]">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      abbr
                      label="Trabajador"
                      placeholder="Seleccionar Trabajador"
                      values={values?.employeeId}
                      model={[advancePayment, 'employee']}
                      request={fetchEmployees}
                      onChange={data => {
                        handleEmployeeSelection(data ? data.value : '');
                        return setFieldValue(field.name, data ? data.value : '');
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            )}
            <Col md={6}>
              <Field name="advancePayment[parsedAmount]">
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    abbr
                    fieldName="advancePayment[amount]"
                    value={getIn(values, 'advancePayment[amount]')}
                    label="Monto"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    leftAddon="$"
                    disabled={onlyShow}
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={6}>
              <Field name="advancePayment[balanceId]">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    abbr
                    label="Concepto"
                    placeholder="Seleccionar Concepto"
                    request={fetchBalanceAssets}
                    model={[advancePayment, 'balance']}
                    values={values.advancePayment}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isClearable
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field name="advancePayment[startDate]">
                {({ field }) => (
                  <FormikTimePicker
                    {...field}
                    abbr
                    setLocale
                    minDate={rrhh ? true : new Date(today.getFullYear(), today.getMonth(), 1)}
                    label="Mes de Inicio"
                    placeholder="mm/aaaa"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    selected={selectedStartDate}
                    onChange={date => {
                      setFieldTouched(field.name);
                      setFieldValue(field.name, moment.isMoment(date) ? date.format('MM/YYYY') : date);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    disabled={onlyShow}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="advancePayment[endDate]">
                {({ field }) => (
                  <FormikTimePicker
                    {...field}
                    setLocale
                    minDate={rrhh ? true : new Date(today.getFullYear(), today.getMonth(), 1)}
                    label="Mes de Término"
                    placeholder="mm/aaaa"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    selected={selectedEndDate}
                    onChange={date => {
                      setFieldValue(field.name, moment.isMoment(date) ? date.format('MM/YYYY') : date);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    disabled={onlyShow || values.advancePayment.permanent}
                  />
                )}
              </Field>
            </Col>
            {action === 'new' && (
              <>
                <Col md={12}>
                  <Field name="advancePayment[reason]">
                    {({ field }) => (
                      <BasicTextArea
                        {...field}
                        label="Motivo"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={onlyShow}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4} className="d-flex align-items-center">
                  <Field name="advancePayment[permanent]">
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        field={field}
                        onClick={e => {
                          if (e.target.checked) {
                            setFieldValue('advancePayment[endDate]', '');
                          }
                        }}
                        label="Anticipo permanente"
                      />
                    )}
                  </Field>
                </Col>
              </>
            )}
          </Row>
        </>
      )}
      {action === 'items' && (
        <>
          {!employee && (
            <Row>
              <Col md={5} className="mt-md-4 pt-md-2">
                <Field name="advancePayment[permanent]">
                  {({ field }) => <FormikCheckBox {...field} field={field} label="Anticipo permanente" margin="mb-0" />}
                </Field>
              </Col>
            </Row>
          )}
        </>
      )}

      <Row className="d-flex justify-content-end mt-3 mb-2">
        <Col xs={6} sm={4}>
          <Button block type="submit" variant="primary" onClick={onHide} disabled={isSubmitting || isDisabled}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const tranformDate = date => {
  if (!date) return '';
  const dateArray = date.split('/');
  return new Date(dateArray[1], dateArray[0] - 1, 1);
};

const setInitialValues = props => {
  const { advancePayment, action, reviewerSignature, apAmountLimit, nextMonth, apProcessId } = props;
  const {
    id,
    amount,
    directApprove,
    endDate,
    name,
    observation,
    payday,
    employeeId,
    balanceId,
    permanent,
    reason,
    signatureKey,
    startDate,
    dataProcessId,
    balance
  } = advancePayment;
  let newObservation = observation;
  if (action === 'items') {
    newObservation = 'Solicitado desde RRHH';
  }

  return {
    advancePayment: {
      id,
      advancePaymentProcessId: apProcessId,
      dataProcessId,
      advanceProcessed: true,
      amount,
      apAmountLimit,
      balanceId,
      directApprove,
      employeeId,
      endDate: tranformDate(endDate),
      name,
      nextMonth,
      observation: newObservation,
      payday,
      permanent,
      reason,
      reviewerSignature,
      signatureKey,
      startDate: tranformDate(startDate),
      balance
    }
  };
};

const today = new Date();
const lastMonth = new Date(today.getFullYear(), today.getMonth(), -1);

const validationSchema = Yup.object().shape({
  advancePayment: Yup.object().shape(
    {
      amount: Yup.number()
        .required('Debes ingresar un monto')
        .positive('Debe ser mayor a 0'),
      employeeId: Yup.string().required('Debes seleccionar un trabajador'),
      endDate: Yup.date().when(
        'startDate',
        (startDate, schema) => startDate && schema.min(startDate, 'Debe ser mayor o igual al mes de inicio')
      ),
      balanceId: Yup.string().required('Debes seleccionar un concepto'),
      permanent: Yup.boolean(),
      reason: Yup.string().nullable(),
      startDate: Yup.date()
        .required('Debes seleccionar un mes de inicio')
        .min(lastMonth, 'Debes seleccionar una fecha mayor o igual a la fecha actual.')
        .when(
          'endDate',
          (endDate, schema) => endDate && schema.max(endDate, 'Debe ser menor o igual a la fecha de término')
        )
    },
    [['endDate', 'startDate']]
  )
});

const editSchema = Yup.object().shape({
  advancePayment: Yup.object().shape(
    {
      permanent: Yup.boolean(),
      amount: Yup.number()
        .required('Debes ingresar un monto')
        .positive('Debe ser mayor a 0'),
      balanceId: Yup.string().required('Debes seleccionar un concepto'),

      endDate: Yup.date().when(
        'startDate',
        (startDate, schema) => startDate && schema.min(startDate, 'Debe ser mayor o igual al mes de inicio')
      )
    },
    [['endDate', 'startDate']]
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema: props => (props.action === 'items' ? editSchema : validationSchema),
  handleSubmit,
  enableReinitialize: true
})(DataProcessesForm);
