import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Spinner, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Filters from './Filters';
import { CustomGraph, Icon } from '../../components';
import './PayrollData.css';
import './DepartmentStats.css';
import { reportSummaryProcessRequest } from '../../requests/payrollProcesses';
import { downloadFile } from '../../services/utils';
import { sendAlert } from '../../actions/utils';

const graphColors = ['#29B48F', '#FB7474', '#FFC107', '#17A2B8'];
const graphLegend = { layout: 'vertical', verticalAlign: 'middle', align: 'right' };
const customColor = payroll => {
  const count = payroll.length;
  const colors = Array.from({ length: count }, (_, index) => (index === 0 ? '#0088FE' : '#949494'));
  return colors;
};

const Graph = ({ payrollProcess, type, title }) => {
  switch (type) {
    case 1:
      return (
        <Row>
          <Col xl={4} lg={4} md={4} sm={4} xs={12} className="h-custom-graph">
            <Col>
              <div style={{ paddingLeft: 80 }}>Distribución Porcentual</div>
              <div style={{ paddingLeft: 90 }}>Totales del Periodo</div>
            </Col>
            <CustomGraph
              customizedLabel
              chartType="pie"
              data={payrollProcess?.calculate_percentage_values}
              activeAnimation={false}
              customHeight={280}
            />
          </Col>
          <Col xl={4} lg={4} md={4} sm={4} xs={12}>
            <div className="payroll-container">
              <div className="payroll-row">
                <span>Conceptos</span>
                <div className="amount">Monto($)</div>
              </div>
              <div className="payroll-row">
                <span>Total Imponible y Tributable</span>
                <span className="amount">{payrollProcess?.calculate_values[5]?.value.toLocaleString()}</span>
              </div>
              <div className="payroll-row">
                <span>Total Haberes</span>
                <span className="amount">{payrollProcess?.calculate_values[4]?.value.toLocaleString()}</span>
              </div>
              <div className="payroll-row">
                <span>Total Impuesto</span>
                <span className="amount">{payrollProcess?.calculate_values[3]?.value.toLocaleString()}</span>
              </div>
              <div className="payroll-row">
                <span>Total Descuentos</span>
                <span className="amount">{payrollProcess?.calculate_values[2]?.value.toLocaleString()}</span>
              </div>
              <div className="payroll-row">
                <span>Total No Imponible No Tributable</span>
                <span className="amount">{payrollProcess?.calculate_values[6]?.value.toLocaleString()}</span>
              </div>
              <div className="payroll-row">
                <span>Costo Empresa</span>
                <span className="amount">{payrollProcess?.calculate_values[0]?.value.toLocaleString()}</span>
              </div>
              <div className="payroll-row">
                <span>Líquido a Pagar</span>
                <span className="amount">{payrollProcess?.calculate_values[1]?.value.toLocaleString()}</span>
              </div>
            </div>
          </Col>
          <Col xl={4} lg={4} md={4} sm={4} xs={12} className="h-custom-graph">
            <Col>
              <div style={{ paddingLeft: 80 }}>Distribución Porcentual</div>
            </Col>
            <CustomGraph
              customizedLabel
              chartType="pie"
              data={payrollProcess?.percentaje_by_gender}
              customColors={graphColors}
              pieLegend={graphLegend}
              activeAnimation={false}
              customHeight={200}
            />
          </Col>
        </Row>
      );
    case 2:
      return (
        <Row>
          <Col md={6} className="h-custom-graph mt-4 mb-4">
            <Col>
              <div style={{ padding: 20, paddingLeft: 140 }}>Distribución a Totales por Empleado</div>
            </Col>
            <CustomGraph
              customizedLabel
              chartType="side"
              data={payrollProcess?.calculate_values}
              pieLegend={graphLegend}
              activeAnimation={false}
              customHeight={250}
            />
          </Col>
          <Col md={6} className="h-custom-graph mt-4 mb-4">
            <Col style={{ padding: 20 }}>
              <div style={{ paddingLeft: 180 }}>Costo Empresa</div>
              <div style={{ paddingLeft: 190 }}>
                <p style={{ fontSize: 10, color: 'gray' }}>últimos 6 meses</p>
              </div>
            </Col>
            <CustomGraph
              customizedLabel
              chartType="line"
              data={payrollProcess?.cal_last_six_month}
              customColors={graphColors}
              pieLegend={graphLegend}
              activeAnimation={false}
              customHeight={250}
            />
          </Col>
        </Row>
      );
    case 3:
      return (
        <>
          <Row>
            <Col md={4} className="h-custom-graph mb-4">
              <Col>
                <div style={{ padding: 20, paddingLeft: 80 }}>{`Total ${title} Empresa`}</div>
              </Col>
              <CustomGraph
                customizedLabel
                chartType="pie"
                data={payrollProcess?.cal_percentage_total}
                customColors={customColor(payrollProcess?.cal_percentage_total)}
                activeAnimation={false}
                customHeight={280}
              />
            </Col>
            <Col xl={4} className="mt-4 mb-4">
              <div className="payroll-container">
                <div className="payroll-row">
                  <span>Conceptos</span>
                  <div className="amount">Monto($)</div>
                </div>
                <div className="payroll-row">
                  <span>Total Imponible y Tributable</span>
                  <span className="amount">{payrollProcess?.calculate_custom_totals[5]?.value.toLocaleString()}</span>
                </div>
                <div className="payroll-row">
                  <span>Total Haberes</span>
                  <span className="amount">{payrollProcess?.calculate_custom_totals[4]?.value.toLocaleString()}</span>
                </div>
                <div className="payroll-row">
                  <span>Total Impuesto</span>
                  <span className="amount">{payrollProcess?.calculate_custom_totals[3]?.value.toLocaleString()}</span>
                </div>
                <div className="payroll-row">
                  <span>Total Descuentos</span>
                  <span className="amount">{payrollProcess?.calculate_custom_totals[2]?.value.toLocaleString()}</span>
                </div>
                <div className="payroll-row">
                  <span>Total No Imponible No Tributable</span>
                  <span className="amount">{payrollProcess?.calculate_custom_totals[6]?.value.toLocaleString()}</span>
                </div>
                <div className="payroll-row">
                  <span>Costo Empresa</span>
                  <span className="amount">{payrollProcess?.calculate_custom_totals[0]?.value.toLocaleString()}</span>
                </div>
                <div className="payroll-row">
                  <span>Líquido a Pagar</span>
                  <span className="amount">{payrollProcess?.calculate_custom_totals[1]?.value.toLocaleString()}</span>
                </div>
              </div>
            </Col>
            <Col xl={4} className="mt-4 mb-4">
              <div className="department-stats">
                <div className="stats-header">
                  <div>Departamento</div>
                  <div>Total</div>
                  <div>Mujeres</div>
                  <div>Hombres</div>
                </div>
                {payrollProcess?.cal_gender_by_type.map((dept, index) => (
                  <div key={`${index + 100}ad`} className="stats-row">
                    <div>{dept.name}</div>
                    <div>{dept.total}</div>
                    <div>{dept.womens}</div>
                    <div>{dept.mens}</div>
                  </div>
                ))}
                <div className="stats-row total">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>Total</div>
                    <ButtonToolbar className="ml-2">
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        delay={{ show: 250, hide: 0 }}
                        overlay={
                          <Tooltip>
                            En este campo se muestran el total de colaboradores sin tomar en cuenta que pueden existir
                            mas de un lugar de prestación de servicio por colaborador
                          </Tooltip>
                        }
                      >
                        <Icon icon="help-circle" height="15px" width="15px" />
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                  <div>{payrollProcess?.totals_gender_by_type.total}</div>
                  <div>{payrollProcess?.totals_gender_by_type.womens}</div>
                  <div>{payrollProcess?.totals_gender_by_type.mens}</div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      );
    default:
      return null;
  }
};

function SummaryProcessIndex() {
  const [filters, setFilters] = useState([]);
  const [currentGraph, setCurrentGraph] = useState(null);
  const [payrollProcess, setPayrollProcess] = useState();
  const [disabled, setDisabled] = useState(false);
  const [title, setTitle] = useState('');
  const dispatch = useDispatch();
  const { currentCompany } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleRequest = (params, format = null, responseType = 'json', setSubmitting) => {
    setDisabled(true);
    const responseFormat = format === '.print' ? '.json' : format;
    reportSummaryProcessRequest({
      dispatch,
      params: { ...params, filter_by_company: currentCompany.id },
      format: responseFormat,
      responseType,
      successCallback: response => {
        if (format === '.xlsx') {
          downloadFile(response);
        } else if (format === '.print') {
          setPayrollProcess(response.data.data.data[0]);
        }
      },
      failureCallback: error => handleFailureRequest(error),
      callback: () => {
        setLoading(false);
        setDisabled(false);
        setSubmitting(false);
      }
    });
  };

  const selectedTitle = params => {
    if (params?.costCenterId) return setTitle('Centro de Costo');
    if (params?.branchOfficeId) return setTitle('Lugar de Prestacion de Servicio');
    if (params?.jobManagementId) return setTitle('Areas');

    return setTitle('');
  };

  const handleFilters = (params, setSubmitting) => {
    setLoading(true);
    if (
      params?.employeeId === 'all' &&
      params?.monthDate !== '' &&
      params?.yearDate !== '' &&
      !params?.costCenterId &&
      !params?.branchOfficeId &&
      !params?.jobManagementId
    ) {
      setCurrentGraph(1);
    }
    if (
      params?.employeeId !== 'all' &&
      (params?.filterNationalId !== '' || params?.employeeId !== '') &&
      params?.monthDate !== '' &&
      params?.yearDate !== ''
    ) {
      setCurrentGraph(2);
    }

    if (
      params?.employeeId === '' &&
      params?.monthDate !== '' &&
      params?.yearDate !== '' &&
      (params?.costCenterId || params?.branchOfficeId || params?.jobManagementId)
    ) {
      setCurrentGraph(3);
    }
    selectedTitle(params);
    setFilters(params);
    handleRequest(params, '.print', '.json', setSubmitting);
  };

  return (
    <>
      <Filters formRequest={handleFilters} />
      {loading && (
        <Row className="d-flex justify-content-center align-items-center" style={{ minHeight: '30vh' }}>
          <Col className="text-center">
            <Spinner animation="border" variant="primary" />
          </Col>
        </Row>
      )}
      {!loading && payrollProcess && (
        <>
          <Graph payrollProcess={payrollProcess} type={currentGraph} title={title} />
          <Row className="d-flex justify-content-end mt-4 mb-4">
            <Col xs={12} sm={6} md={4} lg={3} xl={2}>
              <Button
                className="btn-block"
                type="submit"
                disabled={disabled}
                onClick={() => handleRequest(filters, '.xlsx', 'text', () => true)}
              >
                Descargar Reporte
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default SummaryProcessIndex;
