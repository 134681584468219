import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import moment from 'moment';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormikRangePicker, FormikSelect, FormikRutSuggest, InputSelect } from '../../../components';
import { debounceIndexEmployeesRequest, debounceIndexSelectEmployeesRequest } from '../../../requests/employees';
import { indexJobTitleRequest } from '../../../requests/jobTitles';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';
import { debounceIndexShiftsRequest } from '../../../requests/shifts';

const ALL_SELECTED_LABEL = 'Todos';

const LegalReportFilterDt = props => {
  const { errors, touched, setFieldValue, values, filterRRHH = true, filterOffday = true, onRequest, rrhh } = props;
  const dispatch = useDispatch();
  const [jobTitles, setJobTitles] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [ruts, setRuts] = useState(['']);
  const [suggestions, setSuggestions] = useState(['']);

  const fetchEmployees = (initial = false) => {
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        active: true,
        sort_column: 'name',
        is_dt: false,
        display_length: 100,
        filter_active_branch_offices: branchOffices,
        filter_active_shifts: shifts,
        filter_active_job_titles: jobTitles
      },
      successCallback: response => {
        const dataToGroupSearch = [{ id: 'Todos', label: 'Todos', value: 'Todos' }, ...response.data.data];
        setEmployees(response.data.data.length > 0 ? dataToGroupSearch : []);
        if (initial) {
          setRuts(response.data.data.map(r => r.national_identification));
          setSuggestions(response.data.data.map(r => r.national_identification));
        }
      }
    });
  };

  const fetchEmployee = (inputValue, callback) => {
    debounceIndexSelectEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        sort_column: 'name',
        is_dt: false,
        display_length: 100
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchJobTitles = (inputValue, callback) => {
    indexJobTitleRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: response => {
        callback([{ id: 'Todos', label: 'Todos', value: 'Todos' }, ...response.data.data]);
      }
    });
  };

  const fetchShifts = (inputValue, callback) => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: response => callback([{ id: 'Todos', label: 'Todos', value: 'Todos' }, ...response.data.data])
    });
  };

  const fetchBranchOffices = (inputValue, callback) => {
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: response => callback([{ id: 'Todos', label: 'Todos', value: 'Todos' }, ...response.data.data])
    });
  };

  const hasAllSelection = (groupSelection = []) => {
    return groupSelection.some(option => option.value === ALL_SELECTED_LABEL);
  };

  const handleDisabledSelection = (options, selectedOptions) => {
    if (hasAllSelection(selectedOptions)) {
      return true;
    }
    if (selectedOptions.length === 0) return false;
    return options.value === ALL_SELECTED_LABEL;
  };

  const handleEmployeesSearch = (field, data) => {
    switch (field) {
      case 'filter_active_branch_offices':
        setBranchOffices(data);
        break;
      case 'filter_active_shifts':
        setShifts(data);
        break;
      case 'filter_active_job_titles':
        setJobTitles(data);
        break;
      default:
        break;
    }
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (newData.length && newData.includes(ALL_SELECTED_LABEL)) {
      newData.push('');
      return;
    }
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData);
    handleEmployeesSearch(field, newData);
  };

  const workSchedules = [
    {
      label: 'Semanal',
      value: [
        moment()
          .subtract(1, 'weeks')
          .format('L'),
        moment().format('L')
      ]
    },
    {
      label: 'Quincenal',
      value: [
        moment()
          .subtract(15, 'days')
          .format('L'),
        moment().format('L')
      ]
    },
    {
      label: 'Mensual',
      value: [
        moment()
          .subtract(1, 'months')
          .format('L'),
        moment().format('L')
      ]
    },
    {
      label: 'Anual',
      value: [
        moment()
          .subtract(1, 'years')
          .format('L'),
        moment().format('L')
      ]
    }
  ];

  useEffect(() => {
    fetchEmployees(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (branchOffices.length > 0 || shifts.length > 0 || jobTitles.length > 0) {
      fetchEmployees();
    }
    // eslint-disable-next-line
  }, [branchOffices, shifts, jobTitles]);

  return (
    <Form>
      <Field type="hidden" name="action" />
      <Card>
        {filterRRHH && (
          <>
            {filterOffday && (
              <Row>
                <Col className="mt-2">
                  <Card className="card-dt">
                    <Col className="top-header-green-dt card-header-title-dt arial-dt">
                      Rango de Fechas o Periodo Predeterminado
                    </Col>
                    <Card.Body className="div-content card-body-padding">
                      <Row>
                        <Col sm={12} md={6} xs={12}>
                          <Field name="date_range">
                            {({ field }) => (
                              <FormikRangePicker
                                {...field}
                                abbr
                                startDateName="from"
                                endDateName="to"
                                startDate={values.from}
                                endDate={values.to}
                                showClearDates
                                error={getIn(errors, field.name)}
                                touched={getIn(touched, field.name)}
                                isArial
                              />
                            )}
                          </Field>
                        </Col>
                        <Col sm={12} md={6} xs={12}>
                          <Field name="date_range">
                            {({ field }) => (
                              <FormikSelect
                                {...field}
                                label="Periodo"
                                options={workSchedules}
                                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                                isClearable
                                error={getIn(errors, field.name)}
                                touched={getIn(touched, field.name)}
                                isArial
                              />
                            )}
                          </Field>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
            {rrhh && (
              <>
                <Row>
                  <Col xs={12} sm={12} md={6} xl={6}>
                    <Card className="card-dt">
                      <Col className="top-header-green-dt">
                        <Row className="card-header-title-dt arial-dt">Búsqueda Individual</Row>
                      </Col>
                      <Card.Body className="div-content card-body-padding">
                        <Row>
                          <Col sm={12} md={6} xs={12} className="mt-2">
                            <Field name="employee">
                              {({ field }) => (
                                <InputSelect
                                  {...field}
                                  label="Nombre y Apellido"
                                  placeholder="Seleccione colaborador"
                                  value={values.employee}
                                  isClearable
                                  request={fetchEmployee}
                                  onChange={data => {
                                    setFieldValue(field.name, data || '');
                                    setFieldValue('employees', []);
                                    setFieldValue('filter_ids', data?.value ? [data.value] : []);
                                  }}
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                  isArial
                                />
                              )}
                            </Field>
                          </Col>
                          <Col sm={12} md={6} xs={12} className="mt-2">
                            <Field name="filter_national_id">
                              {({ field }) => (
                                <FormikRutSuggest
                                  {...field}
                                  suggestions={suggestions}
                                  setSuggestions={setSuggestions}
                                  ruts={ruts}
                                  label="RUT"
                                  placeholder="Sin puntos (ej: 11111111-1)"
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                  setFieldValue={setFieldValue}
                                  isArial
                                />
                              )}
                            </Field>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} sm={12} md={6} xl={6}>
                    <Card className="card-dt">
                      <Col className="top-header-green-dt">
                        <Row className="card-header-title-dt arial-dt">Búsqueda Grupal</Row>
                      </Col>
                      <Card.Body className="div-content card-body-padding">
                        <Row>
                          <Col sm={12} md={12} xs={12} className="mt-2">
                            <Field name="employees">
                              {({ field }) => (
                                <FormikSelect
                                  {...field}
                                  label="Nombres y Apellidos"
                                  isMulti
                                  options={employees}
                                  placeholder="Seleccione colaboradores"
                                  onChange={data => {
                                    handleNewMulti(data || [], 'filter_ids', true);
                                    setFieldValue(field.name, data || []);
                                    setFieldValue('employee', '');
                                    setFieldValue('filter_id', '');
                                  }}
                                  isOptionDisabled={handleDisabledSelection}
                                  isClearable
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                  isArial
                                />
                              )}
                            </Field>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="card-dt">
                      <Col className="top-header-green-dt">
                        <Row className="card-header-title-dt arial-dt">Búsqueda Grupal Listado</Row>
                      </Col>
                      <Card.Body className="div-content card-body-padding">
                        <Row>
                          <Col sm={12} md={4} xs={12} className="mt-2">
                            <Field name="branch_offices">
                              {({ field }) => (
                                <InputSelect
                                  {...field}
                                  label="Lugar de Prestación de Servicios"
                                  isMulti
                                  placeholder="Seleccione Lugar de Prestación de Servicios"
                                  values={values.filter_active_branch_offices}
                                  menuPlacement="top"
                                  request={fetchBranchOffices}
                                  isOptionDisabled={handleDisabledSelection}
                                  onChange={data => {
                                    handleNewMulti(data || [], 'filter_active_branch_offices', true);
                                    setFieldValue(field.name, data || []);
                                  }}
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                  isArial
                                />
                              )}
                            </Field>
                          </Col>
                          <Col sm={12} md={4} xs={12} className="mt-2">
                            <Field name="shifts">
                              {({ field }) => (
                                <InputSelect
                                  {...field}
                                  menuPlacement="top"
                                  label="Turnos"
                                  isMulti
                                  placeholder="Seleccione Turno"
                                  values={values.filter_active_shifts}
                                  request={fetchShifts}
                                  isOptionDisabled={handleDisabledSelection}
                                  onChange={data => {
                                    handleNewMulti(data || [], 'filter_active_shifts', true);
                                    setFieldValue(field.name, data || []);
                                  }}
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                  isArial
                                />
                              )}
                            </Field>
                          </Col>
                          <Col sm={12} md={4} xs={12} className="mt-2">
                            <Field name="job_titles">
                              {({ field }) => (
                                <InputSelect
                                  {...field}
                                  menuPlacement="top"
                                  isMulti
                                  placeholder="Seleccione cargo"
                                  label="Cargo o Función"
                                  values={values.filter_active_job_titles}
                                  request={fetchJobTitles}
                                  onChange={data => {
                                    handleNewMulti(data || [], 'filter_active_job_titles', true);
                                    setFieldValue(field.name, data || []);
                                  }}
                                  isOptionDisabled={handleDisabledSelection}
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                  isArial
                                />
                              )}
                            </Field>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Card>
      <Row className="d-flex">
        <Col md={2}>
          <Button
            className="btn-block ellipsis arial-dt"
            type="submit"
            onClick={() => {
              setFieldValue('action', '.docx');
            }}
            disabled={onRequest}
          >
            Descargar Word
          </Button>
        </Col>
        <Col md={2}>
          <Button
            className="btn-block ellipsis arial-dt"
            type="submit"
            onClick={() => {
              setFieldValue('action', '.xlsx');
            }}
            disabled={onRequest}
          >
            Descargar Excel
          </Button>
        </Col>
        <Col md={2}>
          <Button
            className="btn-block ellipsis arial-dt"
            type="submit"
            onClick={() => {
              setFieldValue('action', '.pdf');
            }}
            disabled={onRequest}
          >
            Descargar PDF
          </Button>
        </Col>
        <Col md={2}>
          <Button
            className="btn-block ellipsis arial-dt"
            type="submit"
            onClick={() => {
              setFieldValue('action', '.print');
            }}
            disabled={onRequest}
          >
            Imprimir
          </Button>
        </Col>
        <Col md={2}>
          <Button
            className="btn-block ellipsis arial-dt"
            type="submit"
            onClick={() => {
              setFieldValue('action', 'filter');
            }}
            disabled={onRequest}
          >
            PreVisualizar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  date_range: Yup.array().when('filter_offday', {
    is: true,
    then: Yup.array()
      .required('Debes seleccionar un periodo')
      .rangedate(true)
  })
});

const setInitialValues = props => {
  const { filterOffday = true } = props;
  return {
    from: '',
    to: '',
    date_range: [],
    filter_id: '',
    filter_ids: [],
    filter_national_id: '',
    filter_active_job_managements: [],
    filter_active_job_titles: [],
    filter_active_shifts: [],
    filter_active_branch_offices: [],
    filter_work_schedule: '',
    filter_offday: filterOffday,
    action: 'filter'
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: false
})(LegalReportFilterDt);
