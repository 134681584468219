import ApiService from '../services/apiService';

export const markingsReport = ({ dispatch, params, format, responseType, successCallback, callback }) => {
  ApiService.request('get', `/markings/markings_report${format || ''}`, {
    dispatch,
    params,
    responseType,
    successCallback,
    callback
  });
};

export default markingsReport;
