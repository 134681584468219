import React from 'react';
import memoize from 'memoize-one';
import { ApprovementStatus, ButtonTooltip, Icon, DatatableEmployeeName } from '../../components';

export default memoize((clickHandler, myName) => [
  {
    name: 'RUT Y NOMBRE',
    selector: 'name',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    grow: '2',
    sortable: true
  },
  {
    name: 'CARGO',
    selector: 'job_title',
    cell: item => item.employee.job_title,
    grow: '1',
    sortable: true
  },
  {
    name: 'FECHA EMISIÓN',
    selector: 'created_at',
    sortable: true,
    center: true,
    grow: '1'
  },
  {
    name: 'TIPO DE DOCUMENTO',
    selector: 'document_type',
    cell: item => item.translated_document_type,
    sortable: true,
    grow: '2'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus margin="my-0" status={item.status} onClick={() => clickHandler(item, 'show')} />,
    center: true,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        {item.status === 'pending' && item.request_reviewer_names?.trim() === myName ? (
          <>
            <ButtonTooltip
              onClick={() => clickHandler(item, 'approved')}
              variant="circle-success"
              className="btn-circle"
              size="sm"
              text="Aprobar"
            >
              <Icon icon="checkmark-circle" />
            </ButtonTooltip>
            <ButtonTooltip
              onClick={() => clickHandler(item, 'rejected')}
              variant="circle-danger"
              className="btn-circle"
              size="sm"
              text="Rechazar"
            >
              <Icon icon="close-circle" />
            </ButtonTooltip>
          </>
        ) : (
          <ButtonTooltip
            variant="circle-info"
            className="btn-circle"
            text="Ver"
            onClick={() => clickHandler(item, 'show')}
          >
            <Icon icon="eye" />
          </ButtonTooltip>
        )}
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    center: true,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);
