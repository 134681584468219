import React from 'react';
import { Row, Col } from 'react-bootstrap';
import AntiquityAverageImage from '../images/AntiquityAverage.png';

function AntiquityAverage({ title = '', subtitle = '', TopHeaderColor = '', onRequest = false }) {
  const styling = {
    stroke: TopHeaderColor,
    strokeDasharray: `${101} 100`
  };
  return (
    <>
      {!onRequest && (
        <div style={{ height: '145px' }}>
          <Row style={{ alignItems: 'center', paddingRight: 20, paddingLeft: 20 }} className="pt-2">
            <Col xs={4} xl={4} md={4}>
              <div className="Percentage">
                <svg width="110" height="110" style={{ paddingRight: 20 }}>
                  <circle r="30%" cx="50%" cy="50%" pathLength="100" style={styling} />
                </svg>
                <span style={{ color: TopHeaderColor, alignSelf: 'center', marginLeft: 5 }}>
                  <img src={AntiquityAverageImage} alt="antiguityAverage.png" />
                </span>
              </div>
            </Col>
            <Col xs={8} xl={8} md={8} className="pb-3">
              <div className="Percentage">
                <p style={{ fontSize: '20px', fontWeight: 'bold', color: TopHeaderColor }}>
                  {title} {subtitle}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
export default AntiquityAverage;
