import React, { useState, useEffect } from 'react';
import { Spinner, Row, Col, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { LinkBtn, Icon, BasicCard, BasicTooltip } from '../../components';
import { showCourseRequest, updateCourseRequest } from '../../requests/courses';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import EmployeeCourseIndex from '../EmployeeCourse/EmployeeCourseIndex';
import basicCourse from './course';

const CourseShow = ({ match }) => {
  const [checked, setChecked] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const [course, setCourse] = useState(basicCourse);
  const dispatch = useDispatch();

  const successCallback = response => {
    setCourse(camelCaseEmptyStringRecursive(response.data));
    setChecked(response.data.status === 'published');
  };

  const handleShowRequest = () => {
    setOnRequest(true);
    showCourseRequest(match.params.id, {
      dispatch,
      successCallback,
      callback: () => setOnRequest(false)
    });
  };

  const handleUpdateRequest = () => {
    const message = !checked ? 'Curso publicado' : 'Curso no publicado';
    updateCourseRequest(match.params.id, {
      dispatch,
      params: {
        course: {
          status: course.status !== 'published' ? 'published' : 'no_published'
        }
      },
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message }));
        setChecked(!checked);
      }
    });
  };

  const bodyButtonSwitch = (
    <Row>
      <Col xs={12} md={7} xl={7}>
        <p className="text-uppercase ml-md-3 mt-1 title-switch">Curso publicado</p>
      </Col>
      <Col xs={6} md={3} xl={2}>
        <Form.Group>
          <Form.Check
            checked={checked}
            id="custom-switch"
            className={checked ? 'as-checkbox' : 'as-checkbox-disabled'}
            label=""
            type="switch"
            onChange={() => handleUpdateRequest()}
          />
        </Form.Group>
      </Col>
      <Col xs={6} md={2} xl={3} className="d-flex justify-content-end">
        <Form.Label className="mt-1">
          <BasicTooltip text="Publicar curso a los empleados">
            <Icon icon="help-circle" width={22} />
          </BasicTooltip>
        </Form.Label>
      </Col>
    </Row>
  );

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(handleShowRequest, []);

  return (
    <div className={`${onRequest && 'bg-opacity'} form-course `}>
      {onRequest && (
        <div className="containerSpinnerLoad fix-middle center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="my-4 align-items-center">
        <LinkBtn variant="circle-dark" className="btn-circle mt-2" to="/courses">
          <Icon icon="chevron-back" />
        </LinkBtn>
        <h2 className="text-uppercase ml-1 mt-3 d-inline">Curso #{course.id}</h2>
        <Col className="ml-auto" md={4} xl={3}>
          <LinkBtn variant="primary" className="btn-circle mt-3" block to={`/courses/${course.id}/edit`}>
            Editar información
          </LinkBtn>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={6} xl={8}>
          <h2 className="text-uppercase ml-4 mb-4">{course.name}</h2>
        </Col>
        {course.status === 'no_published' || course.status === 'published' ? (
          <Col md={6} xl={4}>
            <BasicCard
              size="100%"
              classNameCard="z-index-auto"
              bodyMarginBottom="-10px"
              colorCard="white"
              divBody
              text={bodyButtonSwitch}
            />
          </Col>
        ) : null}
      </Row>
      <Row className="justify-content-between mx-1">
        <Col md={6} className="m-0 bg-color-course b-radius fix-spacing-col p-4 mb-4 mb-md-0">
          <Row>
            <Col md={12} xl={6}>
              <p className="h-info text-uppercase font-weight-bold">
                Dirección:
                <span className="font-weight-light ml-2">{course.fullAddress}</span>
              </p>
              <p className="h-info text-uppercase font-weight-bold">
                Fecha de Inicio:
                <span className="font-weight-light ml-2">{course.startDate}</span>
              </p>
              <p className="h-info text-uppercase font-weight-bold">
                Fecha de Término:
                <span className="font-weight-light ml-2">{course.endDate}</span>
              </p>
            </Col>
            <Col md={12} xl={6}>
              <p className="h-info text-uppercase font-weight-bold">
                Registro en Sence:
                <span className="font-weight-light ml-2"> {course.senceRegister ? 'SI' : 'NO'}</span>
              </p>
              {course.senceRegister && (
                <p className="h-info text-uppercase font-weight-bold">
                  Código sence:
                  <span className="font-weight-light ml-2"> {course.senceCode}</span>
                </p>
              )}
              <p className="h-info text-uppercase font-weight-bold">
                Valor total:
                <span className="font-weight-light ml-2">{course.parsedTotalValue}</span>
              </p>
              <p className="h-info text-uppercase font-weight-bold">
                Fecha límite de inscripción:
                <span className="font-weight-light ml-2">{course.limitDate}</span>
              </p>
            </Col>
          </Row>
          <p className="mb-2 mt-md-n2 text-uppercase font-weight-bold">Descripción:</p>
          <pre>
            <p className="font-weight-light ml-md-2 text-justify">{course.description}</p>
          </pre>
        </Col>
        <Col md={6} className="m-0 bg-color-course b-radius fix-spacing-col p-4 mb-4 mb-md-0">
          <Row>
            <Col md={12} xl={6}>
              <p className="h-info text-uppercase font-weight-bold">
                Días de Clases:
                <span className="font-weight-light ml-2">{course.journeyDistribution}</span>
              </p>
              <p className="h-info text-uppercase font-weight-bold">
                Hora de Inicio:
                <span className="font-weight-light ml-2">{course.startTime}</span>
              </p>
              <p className="h-info text-uppercase font-weight-bold">
                Hora de Término:
                <span className="font-weight-light ml-2">{course.endTime}</span>
              </p>
              <p className="h-info text-uppercase font-weight-bold">
                Modalidad:
                <span className="font-weight-light ml-2">{course.translatedCourseModality}</span>
              </p>
              <p className="h-info text-uppercase font-weight-bold">
                Tipo de Actividad:
                <span className="font-weight-light ml-2">{course.translatedActivityType}</span>
              </p>
            </Col>
            <Col md={12} xl={6}>
              <p className="h-info text-uppercase font-weight-bold">
                Comité Bipartito:
                <span className="font-weight-light ml-2">{course.bipartiteCommittee ? 'SI' : 'NO'}</span>
              </p>
              <p className="h-info text-uppercase font-weight-bold">
                Tipo de Capacitación:
                <span className="font-weight-light ml-2">{course.actionType}</span>
              </p>
              <p className="h-info text-uppercase font-weight-bold">
                Indicador de Mandato:
                <span className="font-weight-light ml-2">{course.mandateIndicator}</span>
              </p>
              <p className="h-info text-uppercase font-weight-bold">
                Organismo que imparte el curso:
                <span className="font-weight-light ml-2">{course.courseAgency}</span>
              </p>
              <p className="h-info text-uppercase font-weight-bold">
                Tema:
                <span className="font-weight-light ml-2">{course.theme}</span>
              </p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={12} xl={6}>
              <p className="text-uppercase font-weight-bold pb-3">
                Relatores:
                {course.instructorsAttributes?.length > 0 ? (
                  <>
                    {course.instructorsAttributes.map(item => (
                      <span key={item.id} className="font-weight-light">
                        <br />- {item.name}
                      </span>
                    ))}
                  </>
                ) : (
                  <span className="font-weight-light ml-2">No hay relatores</span>
                )}
              </p>
            </Col>
            <Col md={12} xl={6}>
              <p className="h-info text-uppercase font-weight-bold">
                Máximo de Cupos:
                <span className="font-weight-light ml-2">{course.maxAvailability}</span>
              </p>
            </Col>
          </Row>
          {course.courseCertificate?.fileUrl && (
            <Row>
              <Col>
                <p className="text-uppercase font-weight-bold">
                  Certificado o Diploma:
                  <span className="font-weight-light ml-2">
                    <a
                      target="_blank"
                      className="font-weight-bold underline"
                      rel="noopener noreferrer"
                      href={course.courseCertificate.fileUrl}
                    >
                      {course.courseCertificate.cleanFilename}
                    </a>
                  </span>
                </p>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <EmployeeCourseIndex
        onRequest={onRequest}
        setOnRequest={setOnRequest}
        course={course}
        successCallback={successCallback}
      />
    </div>
  );
};

export default CourseShow;
