import React from 'react';
import memoize from 'memoize-one';
import { DatatableEmployeeName } from '../index';

const columns = memoize(clickHandler => [
  {
    name: 'Nombre y Rut',
    selector: 'employee_full_name',
    cell: item => (
      <DatatableEmployeeName item={item.employee} withPhoto={false} onClick={() => clickHandler(item, 'show')} />
    ),
    sortable: true,
    grow: '1'
  },
  {
    name: 'Fecha/Hora Marcación',
    selector: 'time',
    cell: item => item.parsed_date_time,
    sortable: true,
    grow: '1'
  },
  {
    name: 'Lugar de prestación de servicios',
    cell: item => (item.branch_office ? item.branch_office.full_address : item.employee_branch_office),
    selector: 'employee_branch_office',
    grow: '2'
  },
  {
    name: 'Dirección de marcación',
    selector: 'address',
    sortable: true,
    grow: '2'
  },
  {
    name: 'Tipo de Marcación',
    selector: 'time_type',
    cell: item => item.translated_time_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'Modalidad de marcación',
    selector: 'translated_device_type',
    cell: item => (item.translated_device_type ? item.translated_device_type : item.translated_marking_type),
    sortable: true,
    grow: '2'
  }
]);

export default columns;
