import React from 'react';
import './style.css';

const AttendancesPrint = React.forwardRef(({ headers, rows = [], customStyles }, ref) => {
  const getCustomStyleByRow = (field, row) => {
    let objectStyle = {};
    if (field.conditionalCellStyles) {
      objectStyle = field.conditionalCellStyles.reduce(
        (acc, current) => (current.when(row) ? { ...current.style, ...acc } : acc),
        {}
      );
    }

    return {
      ...customStyles.cells.style,
      ...objectStyle
    };
  };

  return (
    <>
      <div className="table_to_print" ref={ref}>
        <h1>Reporte de asistencias</h1>
        {rows &&
          rows.map(employee => (
            <div key={employee.employee_rut}>
              <div className="headData">
                <p>{employee.company_name}</p>
                <p>{employee.company_rut}</p>
                <p>{employee.employee_name}</p>
                <p>{employee.employee_rut}</p>
                <p>Rango seleccionado de fechas: {employee.dates.join(' a ')}</p>
              </div>
              <table>
                <thead>
                  <tr>
                    {headers &&
                      headers.map(head => (
                        <td
                          width={`${head.grow * 100}px`}
                          style={{ ...head.style }}
                          key={`${employee.employee_rut}${head.name}`}
                        >
                          {head.name}
                        </td>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {employee.records &&
                    employee.records.map(record => (
                      <tr style={customStyles.rows.style} key={`${employee.employee_name}${record.date}`}>
                        {headers.map(field => (
                          <td style={getCustomStyleByRow(field, record)}>{record[field.selector]}</td>
                        ))}
                      </tr>
                    ))}
                  {employee.records.length === 0 && (
                    <tr>
                      <td colSpan={4}>NO SE ENCONTRARON DATOS</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ))}
      </div>
    </>
  );
});

export default AttendancesPrint;
