import React, { useEffect, useState } from 'react';
import PropTypes, { object } from 'prop-types';

const SubRow = ({ row, colSpan, ExpandedComponent }) => (
  <>
    <tr style={{ padding: 20, backgroundColor: '#d6d6d6' }}>
      <td colSpan={colSpan}>
        <ExpandedComponent data={row} />
      </td>
    </tr>
  </>
);

const renderRowSubComponent = ({ row, colSpan, ExpandedComponent }) => (
  <SubRow row={row} colSpan={colSpan} ExpandedComponent={ExpandedComponent} />
);

const Body = ({
  getTableBodyProps,
  page,
  prepareRow,
  colSpan = 0,
  expanded = false,
  ExpandedComponent = () => <></>,
  onRowPress = () => {}
}) => {
  const [styleResponsiveCell, setStyleResponsiveCell] = useState({ justifyContent: 'center' });
  const [isPhone, setIsPhone] = useState(false);

  useEffect(
    () => {
      if (window?.innerWidth <= 880) {
        setStyleResponsiveCell({ justifyContent: 'flex-end', paddingRight: '15px' });
        setIsPhone(true);
      } else {
        setStyleResponsiveCell({ justifyContent: 'center' });
        setIsPhone(false);
      }
    },
    // eslint-disable-next-line
    [window?.innerWidth]
  );

  return (
    <tbody {...getTableBodyProps()}>
      {page.map((row, index) => {
        prepareRow(row);
        return (
          <React.Fragment key={index.toString()}>
            <tr {...row.getRowProps()} style={{ borderRadius: isPhone ? 15 : 0 }}>
              {row.cells.map((cell, i) => {
                const tdWidth = cell.column?.width;
                const tdAlign = cell.column?.align;
                const textAlign = tdAlign || 'center';
                return (
                  <td
                    key={(i + 10).toString()}
                    {...cell.getCellProps()}
                    style={{
                      width: tdWidth && !isPhone ? tdWidth : 'auto',
                      textAlign
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: isPhone ? 'space-between' : textAlign,
                        alignItems: textAlign
                      }}
                    >
                      {isPhone && (
                        <span style={{ display: 'flex', paddingLeft: '15px', justifyContent: 'flex-start' }}>
                          <strong>
                            {cell.column.id === 'selection' ? 'SELECCIONAR' : cell.column.render('Header')}
                          </strong>
                        </span>
                      )}
                      <div
                        style={{
                          position: 'absolute',
                          left: isPhone ? 30 : 400,
                          right: isPhone ? 200 : 160,
                          minHeight: 40,
                          backgroundColor: 'transparent'
                        }}
                        onClick={() => onRowPress(row)}
                        aria-hidden="true"
                      />
                      <span style={{ display: 'flex', ...styleResponsiveCell }}>{cell.render('Cell')}</span>
                    </div>
                  </td>
                );
              })}
            </tr>
            {expanded && renderRowSubComponent({ row, colSpan, ExpandedComponent })}
          </React.Fragment>
        );
      })}
    </tbody>
  );
};

Body.propTypes = {
  getTableBodyProps: PropTypes.func,
  page: PropTypes.arrayOf(object),
  prepareRow: PropTypes.func,
  colSpan: PropTypes.number,
  expanded: PropTypes.bool,
  ExpandedComponent: PropTypes.func,
  onRowPress: PropTypes.func
};

Body.defaultProps = {
  getTableBodyProps: () => {},
  page: [2],
  prepareRow: () => {},
  colSpan: 0,
  expanded: false,
  ExpandedComponent: () => <></>,
  onRowPress: () => {}
};

export default Body;
