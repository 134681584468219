import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import { updateSettlementSettingRequest } from '../../requests/settlementSettings';
import FormikSelectClear from '../../components/Utils/Select/FormikSelectClear';
import { selectorOptions, stagesOptions } from './CardInfo';
import { sendAlert } from '../../actions/utils';

const selectedValue = (attribute, options) => {
  return options.find(option => option.value === attribute) ?? undefined;
};

const filterSelectedOptions = settingData => {
  const selectedOptions = settingData.map(setting => setting.textValue);
  return selectorOptions.filter(option => !selectedOptions.includes(option.value));
};

const settingBeforeValue = (settingData, stage) => {
  const stageValue = Number(stage.value.split('_')[1]);
  const beforeValue = settingData ? settingData.find(setting => setting.code === `stage_${stageValue - 1}`) : null;
  const afterValue = settingData ? settingData.find(setting => setting.code === `stage_${stageValue + 1}`) : null;
  const hasBeforeValue = !!beforeValue?.textValue;
  const hasAfterValue = !!afterValue?.textValue;
  return {
    isDisabled: !hasBeforeValue && stage.value !== 'stage_1',
    isClearable: (hasBeforeValue && !hasAfterValue) || stage.value === 'stage_6'
  };
};

const RowSelector = ({ stage, settingData, setSettingData, dispatch, handleSuccessUpdate }) => {
  const indexSelected = settingData ? settingData.findIndex(setting => setting.code === stage.value) : null;
  const stageSelected =
    indexSelected || indexSelected === 0 ? selectedValue(settingData[indexSelected]?.textValue, selectorOptions) : null;
  const availableOptions = filterSelectedOptions(settingData);
  const { isClearable, isDisabled } = settingBeforeValue(settingData, stage);

  const settingUpdate = settlementSetting => {
    updateSettlementSettingRequest(settlementSetting.id, {
      dispatch,
      params: snakecaseKeys({ settlementSetting }),
      formData: true,
      successCallback: () => {
        handleSuccessUpdate('Configuración modificada con éxito');
      }
    });
  };

  const changeSetting = (settIndex, settValue) => {
    const newSettData = [...settingData];
    newSettData[settIndex].textValue = settValue === undefined ? '' : String(settValue);
    setSettingData(newSettData);
  };

  const handleChange = (stageName, value) => {
    const settIndex = settingData.findIndex(setting => setting.code === stageName.value);
    changeSetting(settIndex, value);
    settingUpdate(settingData[settIndex]);
  };

  return (
    <Row>
      <Col md={4} className="d-flex justify-content-center align-items-center">
        <p className="font-weight-bold mb-0">{stage.label}</p>
      </Col>
      <Col md={6} className="align-items-center">
        <FormikSelectClear
          abbr
          placeholder="Seleccionar etapa"
          value={stageSelected}
          options={availableOptions}
          onChange={data => handleChange(stage, data?.value)}
          margin="mb-1"
          isClearable={isClearable}
          isDisabled={isDisabled}
        />
      </Col>
    </Row>
  );
};

export default function StagesSelector({ settingData, setSettingData }) {
  const dispatch = useDispatch();
  const handleSuccessUpdate = message => {
    dispatch(sendAlert({ kind: 'success', message }));
  };
  return (
    <>
      {stagesOptions.map(stage => (
        <RowSelector
          key={stage.value}
          stage={stage}
          settingData={settingData}
          setSettingData={setSettingData}
          dispatch={dispatch}
          handleSuccessUpdate={handleSuccessUpdate}
        />
      ))}
    </>
  );
}
