import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import ShiftChangeForm from '../../Shared/ShiftChange/ShiftChangeForm';
import { createShiftChangeRequest } from '../../../requests/shiftChange';

const basicShiftChange = {
  replacementDate: '',
  shiftId: '',
  reason: '',
  observation: '',
  employeeId: '',
  employeeReplacementId: '',
  shiftReplacementId: ''
};

const ShiftChangeNew = ({ onSuccess, action, employeeId, rrhh = false }) => {
  const dispatch = useDispatch();

  useEffect(() => window.scrollTo(0, 0), []);

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud  de cambio de turno creada con éxito' }));
    onSuccess();
  };

  const handleCreateRequest = (params, setSubmitting) => {
    const snakeCaseShiftChange = snakeCaseKeys(params);
    createShiftChangeRequest({
      dispatch,
      params: {
        ...snakeCaseShiftChange
      },
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  return (
    <Row>
      <Col>
        <ShiftChangeForm
          action={action || 'new'}
          shiftChange={{ ...basicShiftChange, directApprove: rrhh }}
          employeeId={employeeId}
          formRequest={handleCreateRequest}
          rrhh={rrhh}
        />
      </Col>
    </Row>
  );
};

export default ShiftChangeNew;
