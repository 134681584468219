import React, { useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap';

import { createDropZoneFileRequest } from '../../requests/dropzone';
import {
  massivePreImportDirectoryNodeRequest,
  massiveImportTemplateDirectoryNodeRequest
} from '../../requests/directoryNodes';
import { FormikDropzone } from '../../components';
import { downloadFile } from '../../services/utils';

const ImportDocumentsForm = ({ setFieldValue, values, errors, touched, isSubmitting, onRequest, setOnRequest }) => {
  const [warnings, setWarnings] = useState([]);
  const [clearValues, setClearValues] = useState(false);
  const [msgMaxFiles, setMsgMaxFiles] = useState('');
  const [countFiles, setCountFiles] = useState(0);
  const [isValidate, setIsValidate] = useState(false);
  const dispatch = useDispatch();

  const handleOnDrop = async (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'import',
        documents
      }
    };
    setOnRequest(true);
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => {
        return response;
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleValidate = async () => {
    setOnRequest(true);
    setIsValidate(false);
    return massivePreImportDirectoryNodeRequest({
      dispatch,
      params: {
        file_keys: values.fileKeys.map(f => f.code),
        match_file: [values.matchFile.code]
      },
      formData: true,
      successCallback: response => {
        setWarnings(response.data.collisions);
        setIsValidate(true);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleTemplate = () => {
    setOnRequest(true);
    massiveImportTemplateDirectoryNodeRequest({
      dispatch,
      successCallback: downloadFile,
      callback: () => setOnRequest(false)
    });
  };

  const setValidateRequest = () => {
    setOnRequest(true);
    setIsValidate(false);
  };

  const texts = (files, fileType) => {
    let textDefault = (
      <div
        className="dropzone-add flex-column pt-3 pb-1"
        onClick={() => setValidateRequest()}
        role="button"
        aria-hidden="true"
        tabIndex={0}
      >
        <p className="font-weight-bold">Arrastra aquí tus archivos</p>
        <p>
          <small>o haz click para buscar</small>
        </p>
        <div>
          <Button variant="primary">Buscar Archivos</Button>
        </div>
      </div>
    );
    switch (fileType) {
      case 'matchFile':
      case 'files':
        if (files.length > 0) {
          textDefault = (
            <div className="dropzone-add flex-row">
              <span>
                <p className="my-auto">
                  <b>Arrastra aquí tus archivos </b> <small>o haz click para buscar </small>
                </p>
                <Button className="display-inline" variant="primary">
                  Buscar Archivos
                </Button>
              </span>
            </div>
          );
        }
        break;
      default:
        break;
    }

    return {
      textDefault,
      reject: 'Archivo no valido para subir.',
      active: 'Suelta los archivos aquí para subir...',
      disabled: 'Alcanzaste la cantidad máxima de archivos.',
      formatType: 'Los siguientes archivos no son del tipo admitido',
      tooLarge: 'Los siguientes archivos exceden el peso máximo permitido y no serán cargados:'
    };
  };

  const cleanValues = () => {
    setWarnings([]);
    setFieldValue('fileKeys', []);
    setFieldValue('matchFile', {});
    setClearValues(!clearValues);
    setIsValidate(false);
    setMsgMaxFiles('');
  };

  const afterDelete = () => setIsValidate(false);
  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad mt-5 center-spinner position-fixed">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Form className={`${onRequest && 'bg-opacity'}`}>
        <Card>
          <Card.Body>
            <Row>
              <Col md={7}>
                <Field name="fileKeys">
                  {({ field }) => (
                    <FormikDropzone
                      {...field}
                      abbr
                      fileAccept=".pdf .xls .doc .docx .rtf .xlsx .ppt .pptx .jpg .jpeg .gif .mp3 .mp4 .wmv .avi .png"
                      maxSize={3000000}
                      label="Agrega los archivos que deseas importar (el máximo de subida son 1000 archivos, peso máximo 3mb por archivo.)"
                      customClass="long-dropzone"
                      onDrop={handleOnDrop}
                      texts={texts(values.files, field.name)}
                      onDropUploaded={files => setFieldValue(field.name, files)}
                      afterDelete={afterDelete}
                      error={getIn(errors, field.name)}
                      clearValues={clearValues}
                      batchNumber={20}
                      maxFiles={1000}
                      msgMaxFiles={msg => setMsgMaxFiles(msg)}
                      onFileDialogOpen={() => setValidateRequest()}
                      onFileDialogCancel={() => setOnRequest(false)}
                      countFiles={n => setCountFiles(n)}
                    />
                  )}
                </Field>
                {
                  <div className="d-flex justify-content-between flex-wrap">
                    <p className="font-weight-bold">{msgMaxFiles}</p>
                    <p>
                      Cantidad de archivos: <strong>{countFiles}</strong>
                    </p>
                  </div>
                }

                {isValidate && (
                  <>
                    {values.matchFile && warnings.length > 0 ? (
                      <>
                        <p>
                          Se encontraron los siguientes problemas al verificar la información, por favor verifique su
                          archivo de asociación.
                        </p>
                        <div className="warning-message-box">
                          {warnings.map((warning, index) => (
                            <p key={`warning-message-${warning}`}>
                              {index + 1}. {warning}
                            </p>
                          ))}
                        </div>
                      </>
                    ) : (
                      <p>La asociación de archivos está correcta.</p>
                    )}
                  </>
                )}
              </Col>
              <Col md={5}>
                <Field name="matchFile">
                  {({ field }) => (
                    <FormikDropzone
                      {...field}
                      abbr
                      label="Agrega un excel de asociación"
                      texts={texts(values.matchFile, field.name)}
                      onDrop={handleOnDrop}
                      clearValues={clearValues}
                      multiple={false}
                      className="h-max"
                      fileAccept=".xlsx"
                      tooltipText="Este archivo permitirá hacer la relación entre los documentos cargados y la carpeta del trabajador donde deben ser guardados."
                      onDropUploaded={files => setFieldValue(field.name, files[0])}
                      afterDelete={afterDelete}
                      error={getIn(errors, 'matchFile[code]')}
                      touched={getIn(touched, 'matchFile[code]')}
                      onFileDialogCancel={() => setOnRequest(false)}
                    />
                  )}
                </Field>
                <Button variant="warning" onClick={handleTemplate} className="text-uppercase mt-0 mb-3">
                  Descargar Ejemplo
                </Button>
                {values.matchFile?.code && values.fileKeys.length > 0 && (
                  <Button variant="primary" onClick={handleValidate} className="text-uppercase mt-0 mb-3 float-right">
                    Verificar
                  </Button>
                )}
              </Col>
            </Row>
          </Card.Body>
          <Row className="d-flex justify-content-center justify-content-md-end mt-3 mb-2">
            <Col xs={11} md={3} xl={2}>
              <Button block variant="outline-info" onClick={cleanValues}>
                Eliminar Todo
              </Button>
            </Col>
            {isValidate && (
              <Col xs={11} md={3} xl={2} className="mt-2 mt-md-0 mr-md-4 mb-md-2 mr-xl-4">
                <Button block type="submit" variant="primary" disabled={onRequest || isSubmitting}>
                  Importar
                </Button>
              </Col>
            )}
          </Row>
        </Card>
      </Form>
    </>
  );
};

const mapPropsToValues = () => {
  return {
    matchFile: {},
    fileKeys: []
  };
};

const validationSchema = Yup.object().shape({
  matchFile: Yup.object().shape({
    code: Yup.string().required('Debes ingresar un archivo de asociación')
  }),
  fileKeys: Yup.array().of(
    Yup.object()
      .shape({
        code: Yup.string()
      })
      .required('Debes ingresar al menos un archivo')
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues,
  validationSchema,
  handleSubmit
})(ImportDocumentsForm);
