import React, { useEffect } from 'react';
import ShiftReportDataTable from './ShiftReport';

const ShiftReportIndex = ({ rrhh }) => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <ShiftReportDataTable rrhh={rrhh} />
    </>
  );
};

export default ShiftReportIndex;
