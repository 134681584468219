import React from 'react';
import LogicCountryRow from './LogicCountryRow';

function EmployeeByNationality({ subtitle = '', onRequest = false, nationalities = [] }) {
  return (
    <>
      {!onRequest && (
        <div className="Nationality-adjust-pad" style={{ minHeight: '200px' }}>
          <div className="NationalityPadCard">
            {Object.entries(subtitle).map(([countryLabel, number]) => {
              if (countryLabel !== 'Sin nacionalidad') {
                const nationality = nationalities.find(n => n.label === countryLabel);
                return (
                  <div key={countryLabel} className="adjust-margin">
                    <LogicCountryRow nationality={nationality} country={countryLabel} number={number} />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default EmployeeByNationality;
