import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultModal } from '../../../../components';
import DataProcessDataTable from '../Datatables/DataProcessDataTable';
import { PayrollProcessContext } from '../../context/PayrollProcessProvider';
import { createAdvancePaymentProcessRequest } from '../../../../requests/payrollAdvancePaymentProcess';
import PeriodsInfo from '../PeriodsInfo';

const SecondStep = ({ handleNextStep, handleBackStep, selectedRows }) => {
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { currentCompany } = useSelector(state => state.auth);
  const { payrollProcess, setPayrollProcess } = useContext(PayrollProcessContext);

  const handleCreateAdvancePaymentProcess = () => {
    const advancePaymentProcessIds = selectedRows.map(({ id }) => id);
    setLoading(true);
    createAdvancePaymentProcessRequest({
      dispatch,
      params: {
        payroll_advance_payments_process: {
          payroll_process_id: payrollProcess.id,
          filter_company_id: currentCompany.id,
          filter_ids: advancePaymentProcessIds
        }
      },
      successCallback: ({ data }) => {
        setPayrollProcess({
          ...payrollProcess,
          payroll_ap_process_id: data.id,
          selectedAdvancePaymentProcesses: advancePaymentProcessIds
        });
        handleNextStep(data.data);
      },
      callback: () => setLoading(false)
    });
  };

  const handleBeforeNextStep = () => {
    setModalShow(true);
  };

  return (
    <>
      <Row className="border-line mr-1 ml-1">
        <Col className="top-header-light-gray justify-content-start border-radius-5">
          <p className="mt-0 text-left">
            <b>Etapa 2 - Procesar</b>
          </p>
        </Col>
        <Col sm={12} className="mt-2">
          <PeriodsInfo payrollProcess={payrollProcess} selectedRows={selectedRows} />
        </Col>
      </Row>

      <DataProcessDataTable onRequest={isLoading} selectedRows={selectedRows} />
      <Row className="mb-2 row">
        <Col />
        <Col md={2}>
          <Button variant="outline-info" disabled={isLoading} block onClick={() => handleBackStep()}>
            Cancelar
          </Button>
        </Col>
        <Col md={2}>
          <Button
            variant="primary"
            disabled={selectedRows.length === 0 || isLoading}
            block
            onClick={() => handleBeforeNextStep()}
          >
            Continuar
          </Button>
        </Col>
      </Row>

      <DefaultModal
        title="¿Confirma la selección de los siguientes períodos?"
        body={
          <Row style={{ marginRight: 'unset', marginLeft: 'unset' }}>
            {selectedRows.length > 0 &&
              selectedRows.map(period => (
                <Col key={period.id} sm={12} md={12} xl={12} className=" border-radius-5">
                  <p className="mt-0">
                    <b>{period.name}</b>
                  </p>
                </Col>
              ))}
          </Row>
        }
        disabled={isLoading}
        show={modalShow}
        handleConfirm={() => handleCreateAdvancePaymentProcess()}
        handleClose={() => setModalShow(false)}
        titleBtnClose="Cancelar"
      />
    </>
  );
};

export default SecondStep;
