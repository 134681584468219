import React from 'react';

const AttendanceShow = ({ attendance }) => {
  const { parseAttendanceDate, agreedTime, parsedTimeType, lastReplace, parsedOldTime, shift, timeType } = attendance;
  const { collationTime, lunchCheck } = shift;

  const isAgreedDialingValid =
    ([2, 3].includes(timeType) && lunchCheck === true && collationTime === false) || [0, 1].includes(timeType);

  return (
    <div className="info-box">
      <p className="info">
        <span>Tipo:</span> {parsedTimeType}
      </p>
      <p className="info">
        <span>Fecha:</span> {parseAttendanceDate}
      </p>
      <p className="info">
        <span>Hora Pactada:</span> {isAgreedDialingValid ? agreedTime : ''}
      </p>
      <p className="info">
        <span>Hora Marcada:</span> {parsedOldTime}
      </p>

      {lastReplace && Object.keys(lastReplace).length > 0 && (
        <div className="info-box">
          <h4> Reemplazando a: </h4>
          <p className="info">
            <span>Fecha Solicitud:</span> {lastReplace.remplaceDate}
          </p>

          {lastReplace?.parsedSubstitutionTime && (
            <p className="info">
              <span>Nueva Hora:</span> {lastReplace.parsedSubstitutionTime}
            </p>
          )}

          {lastReplace?.parsedSubstitutionStatus && (
            <p className="info">
              <span>Nuevo Estatus:</span> {lastReplace.parsedSubstitutionStatus}
            </p>
          )}

          <p className="info">
            <span>Remplazado Por:</span> {lastReplace.reaplacedBy}
          </p>

          <p className="info">
            <span>Estatus Remplazo:</span> {lastReplace.parsedStatus}
          </p>
          {lastReplace.observation && (
            <p className="info">
              <span>Observación RRH:</span> {lastReplace.observation}
            </p>
          )}

          {lastReplace.reason && (
            <p className="info">
              <span>Observación Trabajador:</span> {lastReplace.reason}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default AttendanceShow;
