import memoize from 'memoize-one';
import React from 'react';
import { ButtonTooltip, DatatableEmployeeName, DatatableHourMinute, Icon } from '../../../components';

export default memoize((clickHandler, disabled = () => {}, onlyShow) => [
  {
    name: 'TRABAJADOR',
    cell: item => (
      <>
        <DatatableEmployeeName
          item={item.employee}
          withPhoto={false}
          name="full_name"
          startDateContract={item?.start_date_contract}
        />
      </>
    ),
    grow: '1'
  },
  {
    name: 'CENTRO DE COSTOS',
    cell: row => {
      const { employee } = row;
      return <span>{employee?.cost_center}</span>;
    },
    grow: '1',
    center: true
  },
  {
    name: 'ITEM',
    selector: 'hour_value',
    cell: row => {
      return row.translated_hour_value;
    },
    grow: '1',
    center: true,
    sortable: true,
    minWidth: '120px',
    maxWidth: '80px'
  },
  {
    name: 'CANTIDAD',
    cell: row => {
      return <DatatableHourMinute item={row} />;
    },
    grow: '1',
    center: true
  },
  {
    name: 'DIA',
    selector: 'start_date',
    grow: '1',
    center: true,
    sortable: true
  },
  {
    name: 'FACTOR',
    selector: 'factor_value',
    cell: row => {
      return row.factor;
    },
    grow: '1',
    center: true,
    sortable: true,
    minWidth: '40px',
    maxWidth: '60px'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: row => {
      switch (row.status) {
        case 'approved':
          return (
            <span className="text-success" data-tag="allowRowEvents">
              Aprobado
            </span>
          );
        default:
          return (
            <span className="text-warning" data-tag="allowRowEvents">
              Pendiente
            </span>
          );
      }
    },
    grow: '1',
    center: true,
    sortable: true
  },
  {
    name: 'ACCIONES',
    cell: row => (
      <>
        {!onlyShow && (
          <>
            {row.hour_value !== 'without_pay' && (
              <ButtonTooltip
                onClick={() => clickHandler(row, 'edit')}
                variant="circle-warning"
                className="btn-circle"
                size="sm"
                text="Editar"
                disabled={disabled && row.hour_value !== 'calculated_overtime' && row.hour_value === 'without_pay'}
              >
                <Icon icon="pencil" />
              </ButtonTooltip>
            )}
            <ButtonTooltip
              onClick={() => clickHandler(row, 'approved')}
              variant="circle-success"
              className="btn-circle"
              disabled={row.status !== 'pending' || (row.hour_value === 'calculated_overtime' && row.factor === null)}
              size="sm"
              text="Aprobar"
            >
              <Icon icon="checkmark-circle" />
            </ButtonTooltip>
            <ButtonTooltip
              onClick={() => clickHandler(row, 'undo')}
              variant="circle-danger"
              className="btn-circle"
              disabled={row.status === 'pending'}
              size="sm"
              text="Deshacer"
            >
              <Icon icon="close-circle" />
            </ButtonTooltip>
            <ButtonTooltip
              onClick={() => clickHandler(row, 'cancel')}
              variant="circle-danger"
              className="btn-circle"
              disabled={row.status === 'approved'}
              size="sm"
              text="Eliminar"
            >
              <Icon icon="trash" />
            </ButtonTooltip>
          </>
        )}
      </>
    ),
    center: true,
    grow: '1'
  }
]);
