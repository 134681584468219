import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexSettlementSettingsRequest = ({
  dispatch,
  params = {},
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('get', '/settlement_settings', {
    dispatch,
    params,
    successCallback,
    callback,
    service
  });

export const updateSettlementSettingRequest = (
  id,
  { dispatch, params, formData, successCallback, callback, service = 'payroll' }
) =>
  ApiService.request('put', `/settlement_settings/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    service
  });

export const setSettlementSettingStagesRequest = (
  id,
  { dispatch, params, formData, successCallback, callback, service = 'payroll' }
) =>
  ApiService.request('put', `/settlement_settings/${id}/stages`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    service
  });

export const debounceIndexSettlementSettingsRequest = AwesomeDebouncePromise(indexSettlementSettingsRequest, 300);
