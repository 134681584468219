import React, { useMemo, useState } from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { useAbility } from '@casl/react';
import snakeCaseKeys from 'snakecase-keys';
import { useDispatch, useSelector } from 'react-redux';
import { useSetTab } from '../../services/hooks';
import { sendAlert } from '../../actions/utils';
import { Loader, FormikSelect, SimpleCenteredModal } from '../../components';
import { createApiPayrollProcessRequest } from '../../requests/payrollProcesses';
import { AbilityContext } from '../../config/abilityContext';
import RemunerationProcessDataTable from './RemunerationProcessDataTable';
import RemunerationProcessForm from './RemunerationProcessForm';
import basicPayroll from './basicPayroll';

const RemunerationProcessIndex = ({ location }) => {
  const [onRequest, setOnRequest] = useState(false);
  const ability = useAbility(AbilityContext);
  const [year, setYear] = useSetTab(moment().year(), location, 'year', e => parseInt(e, 10));
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [loading, setLoading] = useState();
  const years = useMemo(() => Array.from(Array(100), (e, i) => ({ label: i + 2020, value: i + 2020 })), []);
  const { currentCompany } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const handleCreate = (values, setSubmitting) => {
    setLoading(true);
    setOnRequest(true);
    const { payrollProcess } = values;
    const payrollValues = {
      payrollProcess: {
        ...payrollProcess,
        company: { id: currentCompany.id }
      }
    };
    createApiPayrollProcessRequest({
      dispatch,
      params: snakeCaseKeys(payrollValues),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Proceso creado con éxito' }));
        setMoreData(!moreData);
        setModalShow(false);
      },
      callback: () => {
        setSubmitting(false);
        setLoading(false);
        setOnRequest(false);
      }
    });
  };

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="m-top mb-4">
        <Col>
          <h2 className="m-top mt-1 mb-1">Nomina de Sueldos</h2>
        </Col>
        <Col md={3}>
          <FormikSelect
            options={years}
            label=""
            defaultValue={year}
            value={year}
            onChange={data => {
              setYear(data ? data.value : '');
              setMoreData(!moreData);
            }}
            placeholder="Selecciona un año"
          />
        </Col>
        {ability.can('create', 'PayrollProcess') && (
          <Col md={3}>
            <Button variant="primary" block onClick={() => setModalShow(true)}>
              Nuevo
            </Button>
          </Col>
        )}
      </Row>
      {currentCompany?.id && (
        <RemunerationProcessDataTable
          moreData={moreData}
          setOnRequest={setOnRequest}
          onRequest={onRequest}
          setMoreData={setMoreData}
          customParams={{
            year,
            filter_by_company: currentCompany.id,
            historical: false
          }}
        />
      )}
      <SimpleCenteredModal
        title="Nuevo Proceso"
        show={modalShow}
        onHide={() => setModalShow(false)}
        body={
          <>
            {loading ? (
              <Loader />
            ) : (
              <RemunerationProcessForm formRequest={handleCreate} payrollProcess={basicPayroll} />
            )}
          </>
        }
      />
    </>
  );
};

export default RemunerationProcessIndex;
