import React, { useState } from 'react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { DatatableEmployeeName, DefaultModal, Reminder } from '../../../components';
import { signWorkflowRequestRequest } from '../../../requests/workflowRequests';
import { sendAlert } from '../../../actions/utils';
import SignatureKeyForm from '../../DocumentPreview/SignatureKeyForm';
import {
  approvePermissionRequest,
  deletePermissionRequest,
  rejectPermissionRequest
} from '../../../requests/permissions';
import ApproveRejectStyledActions from '../../../components/DatatableActions/ApproveRejectStyledActions';
import WorkflowRequestReject from '../../WorkflowRequest/WorkflowRequestReject';
import ApprovementStatusStyled from '../../../components/DatatableColumns/ApprovementStatusStyled';

const PermissionInfoTable = props => {
  const dispatch = useDispatch();
  const { item, moreData, setModalShow, setMoreData } = props;
  const [modalItem, setDefaultModalItem] = useState({});
  const [defaultModalTitle, setDefaultModalTitle] = useState('');
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [defaultModalButtons, setDefaultModalButtons] = useState(true);
  const [defaultModalAction, setDefaultModalAction] = useState(() => null);
  const [onRequest, setOnRequest] = useState(true);
  const [isDisabled, setIsDisable] = useState(false);
  const [reminderShow, setReminderShow] = useState(false);
  const [reminderItem, setReminderItem] = useState({});
  const {
    employee,
    endDate,
    endTime,
    middayEnd,
    middayStart,
    reason,
    startDate,
    startTime,
    status,
    translatedPermissionType,
    workflowObservation
  } = item;

  const handleConfirmModal = () => {
    setIsDisable(false);
    setOnRequest(false);
    setDefaultModalItem(item);
    setDefaultModalTitle('Eliminar Solicitud de Vacaciones');
    setDefaultModalBody('¿Estás seguro que deseas eliminar esta solicitud?');
    setDefaultModalButtons(true);
    setDefaultModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setDefaultModalShow(false);
    setDefaultModalTitle('');
    setDefaultModalBody('');
    setOnRequest(false);
    setMoreData(!moreData);
    setModalShow(false);
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud eliminada con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleSuccessAction = message => {
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'success', message }));
    handleModalClose();
  };

  const rejectDocument = (values, setSubmitting) => {
    setOnRequest(true);
    const {
      workflowRequest: { record }
    } = values;

    rejectPermissionRequest(record.id, {
      params: snakeCaseKeys(values),
      dispatch,
      successCallback: () => handleSuccessAction('Solicitud rechazada con éxito'),
      callback: () => setSubmitting(false)
    });
  };

  const handleFailureRequest = error => {
    const { response } = error;
    setIsDisable(false);
    setOnRequest(false);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const aproveWorfklow = workflowId => {
    const signDocument = signatureKey => {
      signWorkflowRequestRequest(workflowId, {
        dispatch,
        params: snakeCaseKeys(signatureKey),
        successCallback: () => handleSuccessAction('Solicitud aprobada con éxito'),
        failureCallback: error => handleFailureRequest(error)
      });
    };

    setDefaultModalButtons(false);
    setDefaultModalTitle('Firmar Documento');
    setDefaultModalBody(<SignatureKeyForm formRequest={signDocument} onHide={handleModalClose} signatureKey="" />);
  };

  const handleUpdateRequest = () => {
    setOnRequest(true);
    approvePermissionRequest(item.id, {
      dispatch,
      successCallback: () => handleSuccessAction('Solicitud aprobada con éxito')
    });
  };

  const removePermission = () => {
    setOnRequest(true);
    deletePermissionRequest(item.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => handleModalClose()
    });
  };

  const handleApproveModal = () => {
    setIsDisable(false);
    setOnRequest(false);
    setDefaultModalItem(item);
    setDefaultModalButtons(true);
    setDefaultModalTitle('Aprobar Solicitud');
    setDefaultModalBody('¿Estás seguro que deseas aprobar esta solicitud?');
    setDefaultModalAction(() => handleUpdateRequest);
  };

  const handleRejectModal = () => {
    setDefaultModalTitle('Rechazar Solicitud');
    setDefaultModalBody(
      <WorkflowRequestReject
        record={item}
        workflowRequest={item}
        cancelAction={handleModalClose}
        formRequest={rejectDocument}
      />
    );
    setDefaultModalShow(true);
    setDefaultModalButtons(false);
  };

  const handleActions = action => {
    switch (action) {
      case 'approved':
        if (item.requestReviewerSignature) {
          aproveWorfklow(item.workflowRequestId);
        } else {
          handleApproveModal(item);
        }
        setDefaultModalShow(true);
        break;
      case 'rejected':
        handleRejectModal(camelCaseRecursive(item));
        break;
      case 'destroy':
        handleConfirmModal(item);
        setDefaultModalAction(() => removePermission);
        break;
      case 'resend':
        setReminderShow(true);
        setReminderItem(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  return (
    <>
      <Modal.Body>
        <div className="info-box">
          <Row>
            <Col md={2} lg={2}>
              <ApprovementStatusStyled status={status} margin="mb-1" />
            </Col>
            <Col md={8} lg={8} className="title-pw">
              <b>Gestionar Solicitud de Permiso</b>
            </Col>
            <Col md={2} lg={2} className="title-pw" />
          </Row>
          <table className="simple-table">
            <tbody>
              <tr>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Trabajador</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Tipo de Permiso</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Fecha de Inicio</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Fecha de Término</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Hora de Inicio</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Hora de Término</th>
                {reason && <th style={{ textAlign: 'center', paddingTop: '25px' }}>Motivo de la Solicitud</th>}
                {workflowObservation && <th style={{ textAlign: 'center', paddingTop: '25px' }}>Motivo Rechazo</th>}
              </tr>
              <tr>
                <td>
                  <div bis_skin_checked="1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div aria-hidden="true" bis_skin_checked="1" className="employee-info" />
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <DatatableEmployeeName item={employee} fileName="fileInfo" name="shortName" fileUrl="fileUrl" />
                    </span>
                  </div>
                </td>
                <td align="center">{translatedPermissionType}</td>
                <td align="center">
                  {startDate} {middayStart && '- Medio día'}
                </td>
                <td align="center">
                  {endDate} {middayEnd && '- Medio día'}
                </td>
                <td align="center">{startTime || '--'}</td>
                <td align="center">{endTime || '--'}</td>
                {reason && <td align="center">{reason}</td>}
                {workflowObservation && <td align="center">{workflowObservation}</td>}
              </tr>
            </tbody>
          </table>
          <br />

          <Row>
            <Col className="text-center">
              <ApproveRejectStyledActions
                item={item}
                resource="Permission"
                clickHandler={handleActions}
                approveText={item.requestReviewerSignature ? 'Firmar / Aprobar' : 'Aprobar'}
                withDestroy
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <DefaultModal
        body={defaultModalBody}
        disabled={onRequest || isDisabled}
        handleClose={handleModalClose}
        handleConfirm={() => defaultModalAction(modalItem)}
        show={defaultModalShow}
        title={defaultModalTitle}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose={defaultModalButtons}
        withConfirm={defaultModalButtons}
      />
      <Reminder
        item={reminderItem}
        show={reminderShow}
        modalShow={setReminderShow}
        moreData={moreData}
        setOnRequest={setOnRequest}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default PermissionInfoTable;
