import React from 'react';
import { useDispatch } from 'react-redux';
import { exportLoanInstallmentsRequest, postponeLoanInstallmentRequest } from '../../../requests/loanInstallments';
import { sendAlert } from '../../../actions/utils';
import { downloadFile } from '../../../services/utils';

const useLoanInstallmentsHook = () => {
  const dispatch = useDispatch();

  const handleSuccessRequest = (msg, setMoreData, setConfirmModalState, setReload = () => {}) => {
    dispatch(sendAlert({ kind: 'success', message: msg }));
    setMoreData(val => !val);
    setConfirmModalState(state => ({ ...state, show: false }));
    setReload(val => !val);
  };

  const handlePostponeInstallment = (id, setMoreData, setOnRequest, setConfirmModalState, setReload) => {
    setOnRequest(true);
    postponeLoanInstallmentRequest(id, {
      dispatch,
      successCallback: () =>
        handleSuccessRequest('Cuota Postergada correctamente', setMoreData, setConfirmModalState, setReload),
      callback: () => setOnRequest(false)
    });
  };

  const handleDownloadInstallments = (params, setClearSelectedRows, setMoreData, setOnRequest) => {
    setOnRequest(true);
    exportLoanInstallmentsRequest({
      dispatch,
      params: { ...params },
      successCallback: response => {
        downloadFile(response);
        setClearSelectedRows(val => !val);
        setMoreData(val => !val);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleImportModalExceptions = (error, setModalState) => {
    if (error?.response?.status === 422) {
      setModalState({
        title: 'Información Relevante',
        body: <pre>{error?.response?.data?.message}</pre>,
        show: true,
        size: 'md'
      });
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  return {
    handlePostponeInstallment,
    handleDownloadInstallments,
    handleImportModalExceptions
  };
};

export default useLoanInstallmentsHook;
