import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import Autosuggest from 'react-autosuggest';
import './style.scss';

const FormikRutSuggest = ({
  abbr,
  classHelpText,
  disabled,
  error,
  formInline,
  helpText,
  label,
  margin,
  placeholder,
  touched,
  suggestions,
  setSuggestions,
  ruts,
  setFieldValue,
  name,
  isArial = false
}) => {
  const [suggestionValue, setSuggestionValue] = useState('');

  const filterRuts = val => {
    const inputValue = val.value;
    const inputLength = inputValue.length;
    const filteredRuts = ruts.filter(rut => {
      if (rut.replace(/\./g, '').match(inputValue)) {
        return rut;
      }
      return '';
    });

    return inputLength === 0 ? [] : filteredRuts;
  };

  const getSuggestionValue = suggestion => {
    return suggestion;
  };

  const onSuggestionsFetchRequested = val => {
    setSuggestions(filterRuts(val));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions(ruts);
  };

  const onChange = (e, { newValue }) => {
    setFieldValue(name, newValue.replace(/\./g, ''));
    setSuggestionValue(newValue.replace(/\./g, ''));
  };

  const inputProps = {
    placeholder,
    value: suggestionValue,
    onChange
  };

  const renderSuggestion = suggestion => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="suggestion-container" onClick={() => setFieldValue(name, suggestion.replace(/\./g, ''))}>
      {suggestion}
    </div>
  );

  return (
    <Form.Group
      className={`${formInline ? 'form-inline' : ''} ${disabled ? 'disabled' : ''} ${margin} ${
        error && touched ? 'is-invalid' : ''
      }`}
    >
      {label && (
        <>
          <Form.Label className="d-flex align-items-center">
            <span className={`${isArial ? 'arial-dt' : ''}`}>{label}</span>{' '}
            {abbr && <abbr className="text-danger ml-1 mr-1">*</abbr>}
          </Form.Label>
        </>
      )}
      <InputGroup>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          containerProps={{ className: isArial ? 'autosuggest-dt' : 'autosuggest-normal' }}
        />
      </InputGroup>
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className={`${classHelpText || 'text-muted'} ml-2`}>{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikRutSuggest.propTypes = {
  formInline: PropTypes.bool,
  margin: PropTypes.string
};

FormikRutSuggest.defaultProps = {
  formInline: false,
  margin: ''
};

export default FormikRutSuggest;
