import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { withFormik, Form, getIn, Field } from 'formik';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import {
  Calendar,
  DefaultModal,
  FormikCheckBox,
  FormikInput,
  FormikMaterialUiTimePicker,
  FormikNumber,
  SimpleCenteredModal
} from '../../components';
import DateForm from './DateForm';
import ShiftContractsForm from './ShiftContractsForm';

const ShiftMonth = ({ values, errors, touched, setFieldValue, onHide, shift }) => {
  const [modalShow, setModalShow] = useState(false);
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const [events, setEvents] = useState([]);
  const {
    collationTime,
    flexibleShift,
    initialTime,
    shiftDaysAttributes,
    workHours,
    agreedSchedule,
    weekends,
    // withAttendances,
    lunchTime
  } = values.shift;
  // !!withAttendances > 0;
  // se comentara mientra esta listo el modulo de cambio de turno
  const disabled = false
  const [dragEvents, setDragEvents] = useState([]);
  const { startLunch, endLunch } = shiftDaysAttributes;
  const isWeekends = !!(weekends === 'undefined' || weekends === true);
  const checkWeekends = () => {
    const dates = [];
    if (shiftDaysAttributes.length) {
      shiftDaysAttributes.map(shiftDay => dates.push(moment(shiftDay.date)));
      const checkDate = dates.map(day => {
        if ([0, 6].includes(day.day())) {
          return true;
        }
        return false;
      });
      if (checkDate.includes(true)) {
        setFieldValue('shift[weekends]', true);
      }
    }
  };

  const settleDragEvents = (newEvents, newEvent) => {
    if (newEvents.some(item => item.title === newEvent.title)) return;
    let newDragEvents = newEvents;
    if (newDragEvents.length >= 3) {
      newDragEvents = [dragEvents[1], dragEvents[2], newEvent];
    } else {
      newDragEvents.push(newEvent);
    }
    setDragEvents(newDragEvents);
  };

  const validEvent = newEvent => !events.some(item => item.title === newEvent.title && item.date === newEvent.date);

  const handleNewDate = event => {
    setModalShow(false);
    const newEvent = { ...event, send: true };
    if (!newEvent.startTime || !newEvent.endTime) return;
    const currentDays = values.shift.shiftDaysAttributes;
    if (validEvent(newEvent)) {
      setEvents([
        ...events,
        {
          title: newEvent.title,
          startLunch: newEvent.startLunch,
          endLunch: newEvent.endLunch,
          date: newEvent.date
        }
      ]);
      setFieldValue('shift[shiftDaysAttributes]', [...currentDays, newEvent]);
      settleDragEvents(dragEvents, newEvent);
    } else {
      setDefaultModalShow(true);
    }
  };

  const setValidEvents = eventArray => {
    if (!eventArray) return [];
    return eventArray.filter(item => item.send).map(item => ({ title: item.title, date: item.date, id: item.id }));
  };

  const handleUpdateDate = event => {
    let updateIndex;
    const newEvent = { ...event, send: true };
    setModalShow(false);
    const currentDays = values.shift.shiftDaysAttributes;
    if (newEvent.id && newEvent.id !== 'null' && newEvent.id !== 'undefined') {
      updateIndex = currentDays.findIndex(item => item.id === parseInt(newEvent.id, 10));
      newEvent.id = parseInt(newEvent.id, 10);
    } else {
      updateIndex = currentDays.findIndex(item => item.date === newEvent.date && item.title === newEvent.oldTitle);
      delete newEvent.id;
    }
    if (validEvent(newEvent)) {
      currentDays[updateIndex] = newEvent;
      setEvents(setValidEvents(currentDays));
      setFieldValue('shift[shiftDaysAttributes]', currentDays);
      settleDragEvents(dragEvents, newEvent);
    } else {
      setDefaultModalShow(true);
    }
  };

  const destroyEvent = ({ id, date, title }) => {
    let currentDays;
    setModalShow(false);
    if (id && id !== 'null' && id !== 'undefined') {
      currentDays = shiftDaysAttributes;
      const destroyIndex = currentDays.findIndex(item => item.id === parseInt(id, 10));
      currentDays[destroyIndex] = { ...currentDays[destroyIndex], send: false };
    } else {
      currentDays = shiftDaysAttributes.filter(
        item => !(item?.date?.trim() === date?.trim() && item.title?.trim() === title?.trim())
      );
    }
    setEvents(setValidEvents(currentDays));
    setFieldValue('shift[shiftDaysAttributes]', currentDays);
  };

  const handleDateClick = dateParams => {
    if (
      values?.shift?.flexibleShift === true &&
      values?.shift?.initialTime === '' &&
      values?.shift?.maximumStartTime === '' &&
      values?.shift?.workHours === ''
    ) {
      setModalShow(false);
    } else if (!disabled) {
      setModalBody(
        <DateForm
          date={dateParams.dateStr}
          defStartLunch={startLunch}
          defEndLunch={endLunch}
          defAgreedSchedule={agreedSchedule}
          defCollationTime={collationTime}
          defInitialTime={initialTime}
          defWorkHours={workHours}
          defLunchTime={lunchTime}
          defFlexibleShift={flexibleShift}
          confirmRequest={handleNewDate}
          cancelRequest={() => setModalShow(false)}
        />
      );
      setModalShow(true);
    }
  };

  const onDropEvent = ({ dateStr, draggedEl }) => {
    if (!disabled) {
      const { firstChild } = draggedEl;
      let startTime = '';
      let endTime = '';
      let startLunchEvent = '';
      let endLunchEvent = '';
      if (firstChild.data.includes('/')) {
        const [dateShift, lunch] = firstChild.data.replace(/\s/g, '').split('/');
        [startLunchEvent, endLunchEvent] = lunch.split('-');
        [startTime, endTime] = dateShift.split('-');
      } else {
        [startTime, endTime] = firstChild.data.replace(/\s/g, '').split('-');
      }
      const newEvent = {
        title: firstChild.data,
        startTime,
        endTime,
        myStartLunch: startLunchEvent,
        myEndLunch: endLunchEvent,
        date: dateStr,
        send: true
      };
      if (validEvent(newEvent)) {
        setEvents(setValidEvents([...shiftDaysAttributes, newEvent]));
        setFieldValue('shift[shiftDaysAttributes]', [...shiftDaysAttributes, newEvent]);
      } else {
        setDefaultModalShow(true);
      }
    }
  };

  const eventClick = props => {
    const {
      _def: eventDrop,
      _instance: { range }
    } = props.event;
    const { extendedProps } = eventDrop;
    let startTime = '';
    let endTime = '';
    let startLunchEvent = '';
    let endLunchEvent = '';
    if (eventDrop.title.includes('/')) {
      const [dateShift, lunch] = eventDrop.title.replace(/\s/g, '').split('/');
      [startLunchEvent, endLunchEvent] = lunch.split('-');
      [startTime, endTime] = dateShift.split('-');
    } else {
      [startTime, endTime] = eventDrop.title.replace(/\s/g, '').split('-');
    }
    if (
      values?.shift?.flexibleShift === true &&
      values?.shift?.initialTime === '' &&
      values?.shift?.maximumStartTime === '' &&
      values?.shift?.workHours === ''
    ) {
      setModalShow(false);
    } else if (!disabled) {
      setModalBody(
        <DateForm
          id={eventDrop.publicId}
          date={range.start.toISOString().slice(0, 10)}
          confirmRequest={handleUpdateDate}
          defStartTime={startTime}
          defEndTime={endTime}
          defStartLunch={startLunchEvent || extendedProps.startLunch || startLunch}
          defEndLunch={endLunchEvent || extendedProps.endLunch || endLunch}
          defCollationTime={collationTime}
          defAgreedSchedule={agreedSchedule}
          defInitialTime={initialTime}
          defWorkHours={workHours}
          defLunchTime={lunchTime}
          defFlexibleShift={flexibleShift}
          cancelRequest={() => setModalShow(false)}
          destroyRequest={destroyEvent}
          oldTitle={eventDrop.title}
          destroy
        />
      );
      setModalShow(true);
    }
  };
  const getDates = (startDate, stopDate) => {
    const dateArray = [];
    const currentStopDate = moment(stopDate)
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
    let currentDate = startDate;
    while (currentDate <= currentStopDate) {
      const day = moment(currentDate);
      if (isWeekends !== true) {
        if (![0, 6].includes(day.day())) {
          dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
        }
      } else {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
      }
      currentDate = moment(currentDate)
        .add(1, 'day')
        .format('YYYY-MM-DD');
    }
    return dateArray;
  };

  const handleManyNewDate = event => {
    setModalShow(false);
    const { date } = event;
    const selectEvent = [];
    const dates = getDates(date.startStr, date.endStr);
    let newEvent = [];
    newEvent = dates.map(shiftDate => {
      return { ...event, send: true, date: shiftDate };
    });
    const currentDays = values.shift.shiftDaysAttributes;
    newEvent.forEach(eventDate => {
      if (!eventDate.startTime || !eventDate.endTime) return;
      if (validEvent(eventDate)) {
        selectEvent.push({
          title: eventDate.title,
          startLunch: eventDate.startLunch,
          endLunch: eventDate.endLunch,
          date: eventDate.date
        });
        settleDragEvents(dragEvents, eventDate);
      } else {
        setDefaultModalShow(true);
      }
    });
    setFieldValue('shift[shiftDaysAttributes]', [...currentDays, ...newEvent]);
    setEvents([...events, ...selectEvent]);
  };

  const handleDateSelect = selectInfo => {
    if (
      values?.shift?.flexibleShift === true &&
      values?.shift?.initialTime === '' &&
      values?.shift?.maximumStartTime === '' &&
      values?.shift?.workHours === ''
    ) {
      setModalShow(false);
    } else if (!disabled) {
      setModalBody(
        <DateForm
          date={selectInfo}
          defStartLunch={startLunch}
          defEndLunch={endLunch}
          defAgreedSchedule={agreedSchedule}
          defCollationTime={collationTime}
          defInitialTime={initialTime}
          defWorkHours={workHours}
          defLunchTime={lunchTime}
          defFlexibleShift={flexibleShift}
          confirmRequest={handleManyNewDate}
          cancelRequest={() => setModalShow(false)}
        />
      );
      setModalShow(true);
    }
  };

  useEffect(checkWeekends, []);
  useEffect(() => {
    setEvents(
      shift.shiftDaysAttributes.map(item => ({
        id: item.id,
        title: item.title,
        date: item.date
      }))
    );
  }, [shift]);

  useEffect(() => {
    if (flexibleShift && workHours && initialTime) {
      setEvents(prevEvents => {
        return prevEvents.map(e => {
          const totalWorkDuration = moment.duration(workHours);
          const collationDuration = moment.duration(lunchTime ?? '0', 'minutes');
          const totalDurationWithLunch = moment.duration(totalWorkDuration).add(collationDuration);
          const startTime = moment.utc(initialTime, 'HH:mm');
          const endTime = moment
            .utc(startTime)
            .add(totalDurationWithLunch)
            .format('HH:mm');

          return {
            id: e.id,
            title: `${initialTime} - ${endTime}`,
            date: e.date
          };
        });
      });
    }
  }, [lunchTime, flexibleShift, workHours, initialTime]);

  return (
    <>
      <Form>
        <Row>
          <Col md={6}>
            <Field name="shift[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  placeholder="Nombre de turno"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  disabled={disabled}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2}>
            <Field name="shift[parsedTolerance]">
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr
                  label="Tolerancia"
                  rightAddon="min"
                  placeholder="0"
                  fieldName="shift[tolerance]"
                  value={values.shift.tolerance}
                  tooltipSize="21"
                  tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                  setFieldValue={setFieldValue}
                  disabled={flexibleShift || disabled}
                  errors={errors}
                  touched={touched}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2} className="mt-4 d-flex align-items-center">
            <Field name="shift[workHolidays]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="Trabaja festivos"
                  tooltipSize="23"
                  tooltipText="Turno con días festivos incluídos"
                  disabled={disabled}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={2} className="mt-4 d-flex align-items-center">
            <Field name="shift[flexibleShift]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="Turno flexible"
                  tooltipSize="23"
                  onClick={() => {
                    if (values?.shift?.shiftDaysAttributes?.length > 0) {
                      setFieldValue('shift[shiftDaysAttributes]', []);
                      setEvents([]);
                      setDragEvents([]);
                    }

                    setFieldValue(field.name, field.value === 'true' || field.value === true);
                    setFieldValue('shift[tolerance]', 0);
                    setFieldValue('shift[parsedTolerance]', 0);

                    if (field.value !== 'true' || field.value !== true) {
                      setFieldValue('shift[initialTime]', '');
                      setFieldValue('shift[maximumStartTime]', '');
                      setFieldValue('shift[workHours]', '');
                      setFieldValue('shift[agreedSchedule]', false);
                    }
                  }}
                  disabled={disabled}
                />
              )}
            </Field>
          </Col>
          {flexibleShift && (
            <>
              <Col xs={4} md={2}>
                <Field name="shift[initialTime]">
                  {({ field }) => (
                    <FormikMaterialUiTimePicker
                      {...field}
                      abbr
                      timeSelector
                      label="Hora de Inicio"
                      onChange={time => {
                        setFieldValue(field.name, time);
                        if (flexibleShift) {
                          shiftDaysAttributes.map((_item, index) =>
                            setFieldValue(`shift[shiftDaysAttributes][${index}][startTime]`, time)
                          );
                          if (workHours) {
                            shiftDaysAttributes.forEach((_item, index) => {
                              const totalWorkDuration = moment.duration(workHours);
                              const collationDuration = moment.duration(lunchTime ?? '0', 'minutes');
                              const totalDurationWithLunch = moment.duration(totalWorkDuration).add(collationDuration);

                              const startTime = moment.utc(time, 'HH:mm');
                              const endTime = moment
                                .utc(startTime)
                                .add(totalDurationWithLunch)
                                .format('HH:mm');
                              setFieldValue(`shift[shiftDaysAttributes][${index}][endTime]`, endTime);
                            });
                          }
                        }
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled={disabled}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={4} md={2}>
                <Field name="shift[maximumStartTime]">
                  {({ field }) => (
                    <FormikMaterialUiTimePicker
                      {...field}
                      abbr
                      timeSelector
                      label="Tiempo Max. Inicio"
                      onChange={time => setFieldValue(field.name, time)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled={disabled}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={4} md={2}>
                <Field name="shift[workHours]">
                  {({ field }) => (
                    <FormikMaterialUiTimePicker
                      {...field}
                      abbr
                      timeSelector
                      label="Horas Trabajo"
                      onChange={time => {
                        setFieldValue(field.name, time);
                        shiftDaysAttributes.forEach((_item, index) => {
                          const totalWorkDuration = moment.duration(time);
                          const collationDuration = moment.duration(lunchTime ?? '0', 'minutes');
                          const totalDurationWithLunch = moment.duration(totalWorkDuration).add(collationDuration);
                          const startTime = moment.utc(initialTime, 'HH:mm');
                          const endTime = moment
                            .utc(startTime)
                            .add(totalDurationWithLunch)
                            .format('HH:mm');
                          setFieldValue(`shift[shiftDaysAttributes][${index}][endTime]`, endTime);
                        });
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled={disabled}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
        </Row>
        <Row className="mt-1">
          <Col xs={12} md={2} className="d-flex align-items-center">
            <Field name="shift[lunchCheck]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="Marcar colación"
                  tooltipSize="23"
                  tooltipText="Indica si deben marcar el ingreso y salida de colación"
                  disabled={disabled}
                />
              )}
            </Field>
          </Col>
          <Col xs={12} md={2} className="d-flex align-items-center">
            <Field name="shift[agreedSchedule]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="Horario pactado"
                  tooltipSize="23"
                  tooltipText="Indica que deben marcar el ingreso y salida de colación en cada uno de los días seleccionados"
                  disabled={collationTime || flexibleShift || disabled}
                  onClick={() => {
                    setFieldValue(field.name, field.value === 'true' || field.value === true);
                    setFieldValue('shift[lunchTime]', 0);
                  }}
                />
              )}
            </Field>
          </Col>
          <Col xs={6} md={2} className="d-flex align-items-center">
            <Field name="shift[collationTime]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="Tiempo de colación"
                  tooltipSize="23"
                  disabled={agreedSchedule || disabled}
                  onClick={() => {
                    setFieldValue('shift[collationTime]', 0);
                    setFieldValue('shift[lunchTime]', 0);
                    setFieldValue('shift[parsedLunchTime]', 0);
                  }}
                />
              )}
            </Field>
          </Col>
          {collationTime && (
            <>
              <Col xs={6} md={2} className="d-flex align-items-center">
                <Field name="shift[parsedLunchTime]">
                  {({ field }) => (
                    <FormikNumber
                      {...field}
                      abbr
                      rightAddon="min"
                      placeholder="0"
                      label="Colación"
                      fieldName="shift[lunchTime]"
                      value={values.shift.lunchTime}
                      tooltipSize="21"
                      tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      disabled={disabled}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
          <Col xs={12} md={2} className="d-flex align-items-center">
            <Field name="shift[weekends]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  field={field}
                  label="Incluir fines de semana"
                  disabled={disabled}
                  onClick={() => setFieldValue(field.name, field.value === 'true' || field.value === true)}
                />
              )}
            </Field>
          </Col>
        </Row>
        {(errors?.shift?.agreedSchedule || errors?.shift?.collationTime) && (
          <p className="text-danger">Debes seleccionar una de las opciones de marcado</p>
        )}
        <hr />
        <Calendar
          dateClick={handleDateClick}
          events={events}
          drop={onDropEvent}
          eventClick={eventClick}
          dragEvents={dragEvents}
          selectable
          select={handleDateSelect}
          weekends={isWeekends}
        />
        <hr />
        <ShiftContractsForm shift={shift} />
        <Row className="d-flex justify-content-end my-3">
          <Col md={3}>
            <Button type="submit" variant="primary" block onClick={onHide}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
      <SimpleCenteredModal
        title="Agregar Horario"
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <DefaultModal
        title="Turno Duplicado"
        body="El turno que agregaste ya existe para este día."
        show={defaultModalShow}
        handleClose={() => setDefaultModalShow(false)}
        variantBtnClose="outline-info"
        titleBtnClose="Aceptar"
        withConfirm={false}
      />
    </>
  );
};

const setInitialValues = props => {
  const { shiftDaysAttributes } = props.shift;
  let shiftDays = [];
  if (shiftDaysAttributes.length) {
    shiftDays = shiftDaysAttributes.map(item => ({ ...item, send: true }));
  }

  return {
    shift: {
      ...props.shift,
      shiftDaysAttributes: shiftDays,
      shiftType: 'monthly'
    }
  };
};

const validationSchema = Yup.object().shape({
  shift: Yup.object().shape(
    {
      lunchCheck: Yup.boolean(),
      workHolidays: Yup.boolean(),
      tolerance: Yup.number().when('flexibleShift', {
        is: val => val !== 'true',
        then: Yup.number()
          .required('Debes ingresar una tolerancia')
          .min(0, 'Debe ser mayor o igual a 0')
      }),
      lunchTime: Yup.number().when('collationTime', {
        is: val => val,
        then: Yup.number()
          .required('Debes ingresar un número')
          .min(0, 'Debe ser mayor o igual a 0 '),
        otherwise: Yup.number().nullable()
      }),
      initialTime: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar un horario'),
        otherwise: Yup.string().nullable()
      }),
      maximumStartTime: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar tiempo máximo de inicio')
      }),
      workHours: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar cantidad de horas a trabajar')
      }),
      collationTime: Yup.bool().when('agreedSchedule', {
        is: aSchedule => aSchedule === false,
        then: Yup.bool().oneOf([true], 'Debes seleccionar una opción')
      }),
      agreedSchedule: Yup.bool().when('collationTime', {
        is: colTime => colTime === false,
        then: Yup.bool().oneOf([true], 'Debes seleccionar una opción')
      }),
      name: Yup.string().required('Debes ingresar un nombre'),
      shiftDaysAttributes: Yup.array().of(
        Yup.object().shape({
          endTime: Yup.string().required('Debes ingresar hora de término'),
          startTime: Yup.string().required('Debes ingresar hora de inicio')
          // date: Yup.date()
        })
      )
    },
    [['agreedSchedule', 'collationTime']]
  )
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ShiftMonth);
