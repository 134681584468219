import React, { useContext, useState } from 'react';
import snakecaseKeys from 'snakecase-keys';
import { useDispatch } from 'react-redux';
import payrollBalance from './payrollBalance';
import { sendAlert } from '../../../../actions/utils';
import PayrollBalancesForm from './PayrollBalancesForm';
import { PayrollProcessContext } from '../../context/PayrollProcessProvider';
import { createPayrollBalanceEmployees } from '../../../../requests/payrollProcessBalances';

const PayrollBalancesIndex = ({ setActiveComponent }) => {
  const dispatch = useDispatch();
  const [moreData, setMoreData] = useState(false);
  const [listEmployees, setListEmployees] = useState([]);
  const { payrollProcess, setPayrollProcess } = useContext(PayrollProcessContext);

  const handleSuccessAction = (message, resetForm, setFieldValue) => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    setFieldValue('payrollBalance[employeeIds]', []);
    resetForm();
    setActiveComponent('BalancesTable');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
  };

  const handleCreate = (values, setSubmitting, resetForm, setFieldValue) => {
    const params = snakecaseKeys(values);
    createPayrollBalanceEmployees({
      dispatch,
      params,
      successCallback: () => {
        handleSuccessAction('Conceptos cargados exitosamente!', resetForm, setFieldValue);
      },
      failureCallback: handleFailureRequest,
      callback: () => setSubmitting(false)
    });
  };

  return (
    <>
      <PayrollBalancesForm
        payrollBalance={payrollBalance}
        action="new"
        formRequest={handleCreate}
        setListEmployees={setListEmployees}
        listEmployees={listEmployees}
        moreData={moreData}
        payrollProcess={payrollProcess}
        setActiveComponent={setActiveComponent}
        setPayrollProcess={setPayrollProcess}
      />
    </>
  );
};

export default PayrollBalancesIndex;
