import React from 'react';

const customFieldInfo = ({ customField }) => {
  const {
    active,
    name,
    description,
    translatedFieldType,
    keyNameTemplate,
    customFieldTypesAttributes,
    fieldDisplay,
    visibleWorker
  } = customField;
  const activeMessage = active ? 'Sí' : 'No';
  const translastedVisibleWorker = visibleWorker ? 'Sí' : 'No';
  const employeeDisplay = fieldDisplay === 'card_employee_display';

  return (
    <>
      <div className="info-box">
        <h4 className="text-uppercase">Información Cargo</h4>
        <p className="info">
          <span>Nombre:</span>
          {name}
        </p>
        <p className="info">
          <span>Descripción:</span>
          {description}
        </p>
        <p className="info">
          <span>Nombre de llave en plantilla:</span>
          {keyNameTemplate}
        </p>
        <p className="info">
          <span>Tipo de campo:</span>
          {translatedFieldType}
        </p>
        {translatedFieldType === 'Selector' && (
          <ul className="info">
            {customFieldTypesAttributes.map(element => (
              <li key={element.id} style={{ marginLeft: '35%' }}>
                {element.name}
              </li>
            ))}
          </ul>
        )}
        <p className="info">
          <span>Visualización del campo:</span>
          {customField.translatedFieldDisplay}
        </p>
        {employeeDisplay && (
          <p className="info">
            <span>Visible trabajador:</span>
            {translastedVisibleWorker}
          </p>
        )}
        <p className="info">
          <span>Activo:</span>
          {activeMessage}
        </p>
      </div>
    </>
  );
};

export default customFieldInfo;
