import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import memoize from 'memoize-one';
import { useAbility } from '@casl/react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { DataTableDT, DefaultModal, Icon, SimpleCenteredModal } from '../../components';
import { indexEmployeesRequest } from '../../requests/employees';
import { legalAttendanceReport } from '../../requests/legalreports';
import { AbilityContext } from '../../config/abilityContext';
import { AttendanceFilterDataTable } from '../../components/LegalReport';
import { downloadFile } from '../../services/utils';
import LegalReportFilterDt from './Filters/LegalReportFilterDt';
import ReportActions from './ReportActions';
import AttendancesPrint from './Print/AttendancesPrint';
import PrintFunctions from './Print/PrintFunctions';

const customStyles = {
  rows: {
    style: {
      minHeight: '45px'
    }
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      borderLeft: '1px solid rgb(212, 212, 212)',
      borderRight: '1px solid rgb(212, 212, 212)',
      borderTop: '1px solid rgb(212, 212, 212)',
      borderBottom: '1px solid rgb(212, 212, 212)'
    }
  }
};

const columns = memoize(() => [
  {
    name: 'Nombre',
    selector: 'name',
    cell: item => <>{item.full_name}</>,
    sortable: true,
    grow: '2'
  },
  {
    name: 'Cédula de Identidad (RUT)',
    selector: 'national_identification',
    sortable: true,
    grow: '1'
  }
]);

const recordsColumns = memoize(() => [
  {
    name: 'FECHA',
    selector: 'date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ASISTENCIA',
    selector: 'attendance',
    sortable: true,
    grow: '1'
  },
  {
    name: 'AUSENCIA',
    selector: 'absence',
    sortable: true,
    grow: '2'
  },
  {
    name: 'OBSERVACIONES',
    selector: 'justification',
    sortable: true,
    grow: '4',
    conditionalCellStyles: [
      {
        when: row => row.justification && row.justification.includes('Marcación fuera de turno'),
        style: {
          backgroundColor: 'rgba(248, 148, 6, 0.9)',
          color: 'white',
          '&:hover': {
            cursor: 'pointer'
          }
        }
      },
      {
        when: row => row.justification && row.justification.includes('No tiene turno asignado'),
        style: {
          backgroundColor: 'rgba(255, 160, 122, 0.9)',
          '&:hover': {
            cursor: 'pointer'
          }
        }
      }
    ]
  }
]);

const printTypeText = {
  print: '¿Estás seguro que quieres imprimir el reporte de asistencia?',
  all: '¿Estás seguro que quieres exportar el reporte de asistencia en formato '
};

export const MyExpander = props => {
  const { data, dateRange } = props;
  const { id } = data;

  return (
    <AttendanceFilterDataTable
      columns={recordsColumns}
      customParams={{ filter_by_employee: id, date_range: dateRange }}
      props
      customStyles={customStyles}
    />
  );
};

const AttendanceReportDataTable = ({ currentEmployee, rrhh }) => {
  const [onRequest, setOnRequest] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [modalState, setModalState] = useState({
    show: false,
    type: '',
    text: '',
    actionType: ''
  });
  const [modalShowGlosary, setModalShowGlosary] = useState(false);
  const [query, setQuery] = useState({});
  const [moreData, setMoreData] = useState(false);
  const [amount, setAmount] = useState(0);
  const [dateRange, setDateRange] = useState([]);
  const ability = useAbility(AbilityContext);
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const dispatch = useDispatch();
  const [employeeIds, setEmployeeIds] = useState([]);
  const [showFilter, setShowFilter] = useState(true);
  const [rowsDataPrint, setRowsDataPrint] = useState([]);
  const componentRef = useRef();
  const { getEmployeeIds } = PrintFunctions();

  const handleClearRows = () => {
    setShowFilter(true);
    setFilteredEmployees([]);
  };

  const handleBeforePrint = () => {
    setOnRequest(false);
  };

  const printInfo = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: handleBeforePrint
  });

  const isDownloableFile = format => {
    return ['.xlsx', '.docx', '.pdf'].includes(format);
  };

  const verifyFilters = params => {
    return ['filter_active_shifts', 'filter_active_branch_offices', 'filter_active_job_titles'].find(field => {
      return params[field] && params[field].length > 0 && !params[field].includes('');
    });
  };

  const getEmployeeIdsAsync = async params => {
    const filterIds = params.filter_ids || [];
    return new Promise(async res => {
      if (currentEmployee) res([currentEmployee]);
      if (verifyFilters(params)) {
        const clientData = await getEmployeeIds(params);
        res(clientData.map(client => client.id));
      }
      if ((filterIds && filterIds.length === 0) || filterIds.includes('')) res('All');
      res(filterIds);
    });
  };

  const handleAttendanceReportRequest = async (params, format, type = 'json', actionType) => {
    setOnRequest(true);
    const employeeIdsSelected = actionType === 'download' ? await getEmployeeIdsAsync(params) : employeeIds;
    const responseType = format === '.docx' ? 'blob' : type;
    legalAttendanceReport({
      dispatch,
      params: { filter_by_employee: employeeIdsSelected, date_range: dateRange, paginate: false },
      format,
      responseType,
      successCallback: response => {
        if (isDownloableFile(format)) {
          downloadFile(response);
        }
        if (format === '.print') {
          setRowsDataPrint(response.data);
          printInfo();
        }
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleRequest = async (params, format, responseType = 'json', currentFilters = {}) => {
    setOnRequest(true);
    const filterIds = currentEmployee || params.filter_ids;
    if (modalState.show) return;
    indexEmployeesRequest({
      dispatch,
      params: {
        display_start: 0,
        display_length: 15,
        ...params,
        filter_ids: filterIds,
        ...currentFilters,
        active: true,
        is_dt: false,
        filter_work_schedules: 'full_time,part_time,article_22',
        sort_direction: 'asc',
        sort_column: 'name'
      },
      format,
      responseType,
      successCallback: response => {
        const { data } = response;
        setAmount(data.metadata.amount);
        setFilteredEmployees(data.data);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleOptionSelection = (action, actionType) => {
    switch (true) {
      case ['download_excel', '.xlsx'].includes(action):
        setModalState({ text: `${printTypeText.all} Excel?`, type: '.xlsx', show: true, actionType });
        break;
      case ['download_docx', '.docx'].includes(action):
        setModalState({ text: `${printTypeText.all} Word?`, type: '.docx', show: true, actionType });
        break;
      case ['download_pdf', '.pdf'].includes(action):
        setModalState({ text: `${printTypeText.all} Pdf?`, type: '.pdf', show: true, actionType });
        break;
      case ['print', '.print'].includes(action):
        setModalState({ text: printTypeText.print, type: '.print', show: true, actionType });
        break;
      default:
    }
  };

  const handleFilters = params => {
    const filterIds = currentEmployee || params.filter_ids;
    setQuery({ ...params, filter_ids: filterIds });
    setDateRange(params.date_range);
    if (['.docx', '.xlsx', '.pdf', '.print'].includes(params.action)) {
      handleOptionSelection(params.action, 'download');
      return;
    }
    setSearchFirstStart(true);
    setMoreData(!moreData);
    setShowFilter(params.action === '.print');
    handleRequest(params);
  };

  const handleMassAction = actionType => {
    setEmployeeIds(filteredEmployees.map(employee => employee.id));
    handleOptionSelection(actionType, 'preview');
  };

  return (
    <>
      <div className={showFilter ? '' : 'd-none'}>
        <LegalReportFilterDt
          formRequest={handleFilters}
          onRequest={onRequest}
          setModalShowGlosary={setModalShowGlosary}
          rrhh={rrhh}
          currentEmployee={currentEmployee}
        />
      </div>
      <div className={showFilter ? 'd-none' : 'attendance_report'}>
        <Row>
          <Col md={6} className="mt-3">
            <Button variant="primary" onClick={handleClearRows}>
              <Icon icon="chevron-back" width={15} />
              <span className="arial-dt">Volver al filtro</span>
            </Button>
          </Col>
        </Row>
        <DataTableDT
          onRequest={onRequest}
          columns={columns()}
          data={filteredEmployees}
          totalRows={amount}
          moreData={moreData}
          withMassActions={ability.can('index', 'AttendanceReport')}
          massActions={<ReportActions disabled={onRequest} handleClick={handleMassAction} dates={dateRange} isArial />}
          expandableRows
          expandableRowsComponent={<MyExpander dateRange={dateRange} />}
          expandableRowExpanded={() => true}
          resourceRequest={params => handleRequest(params, '', '', query)}
          searchFirstStart={searchFirstStart}
        />
      </div>
      <DefaultModal
        title={modalState.type === '.print' ? 'Imprimir Reporte de Asistencias' : 'Exportar Reporte de Asistencias'}
        body={modalState.text}
        show={modalState.show}
        handleClose={() => setModalState(oldState => ({ ...oldState, show: false }))}
        handleConfirm={() => {
          handleAttendanceReportRequest(query, modalState.type, 'text', modalState.actionType);
          setModalState(oldState => ({ ...oldState, show: false }));
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        title="Glosario de Abreviaciones"
        body={
          <Card>
            <Card.Body>
              <Col md={12} className="mb-5">
                <Row>
                  <Col>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">A.I</p> Ausencia
                    Injustificada
                  </Col>
                  <Col>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">A.J</p> Ausencia
                    Justificada
                  </Col>
                  <Col>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">A.T</p> Atraso
                  </Col>
                  <Col md={4}>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">D.E.J</p>{' '}
                    Distribucion Excepcional de Jornada
                  </Col>
                </Row>
              </Col>
              <Col md={12} className="mb-5">
                <Row>
                  <Col>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">H.E</p> Horas
                    Extraordinarias
                  </Col>
                  <Col>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">J.O</p> Jornada
                    Ordinaria
                  </Col>
                  <Col>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">L.M</p> Licencia
                    Medica
                  </Col>
                  <Col md={4}>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">P.G.R</p> Permiso
                    con goce de Remuneraciones
                  </Col>
                </Row>
              </Col>
              <Col md={12} className="mb-5">
                <Row>
                  <Col>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">C.T</p> Cambio de
                    Turno
                  </Col>
                  <Col>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">PREN</p> Prenatal
                  </Col>
                  <Col>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">POSTN</p>{' '}
                    Postnatal
                  </Col>
                  <Col md={4}>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">P.S.G.R</p>{' '}
                    Permiso sin goce de Remuneraciones
                  </Col>
                </Row>
              </Col>
              <Col md={12} className="mb-5">
                <Row>
                  <Col>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">VAC</p> Vacaciones
                  </Col>
                  <Col>
                    <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">S.A</p> Salida
                    Anticipada
                  </Col>
                  <Col>
                    <p className="d-inline p-2 w-25 bg-warning rounded-circle text-white text-center">. . .</p>{' '}
                    Marcaciones Agregadas
                  </Col>
                  <Col md={4}>
                    <p className="d-inline p-2 w-25 bg-success rounded-circle text-white text-center">. . .</p>{' '}
                    Marcaciones Modificadas
                  </Col>
                </Row>
              </Col>
            </Card.Body>
          </Card>
        }
        size="xl"
        show={modalShowGlosary}
        onHide={() => setModalShowGlosary(false)}
      />
      <div style={{ display: 'none' }}>
        <AttendancesPrint
          ref={componentRef}
          headers={recordsColumns()}
          rows={rowsDataPrint}
          customStyles={customStyles}
        />
      </div>
    </>
  );
};

export default AttendanceReportDataTable;
