import React, { useEffect, useState } from 'react';
import { withFormik, Field, Form } from 'formik';
import { Button, Row, Col, Card, Accordion } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { FormikSelect, InputSelect } from '../../components';
import { debounceIndexSelectEmployeesRequest, searchEmployeeRequest } from '../../requests/employees';
import { debounceIndexJobManagementsRequest } from '../../requests/jobManagements';
import { debounceIndexCostCentersRequest } from '../../requests/costCenters';
import { debounceIndexBranchOfficesRequest } from '../../requests/branchOffices';
import { debounceIndexBalancesRequest } from '../../requests/balances';
import { advancePaymentsTypes } from '../Employee/EmployeeForm/FormOptions';

const EmployeeFilterAp = props => {
  const { setFieldValue } = props;
  const dispatch = useDispatch();
  const [status, setStatus] = useState('1');
  const employeeStatus = [
    { label: 'Activo', value: '1' },
    { label: 'Inactivo', value: '0' }
  ];

  const resultFetchData = response => {
    const { data } = response.data;
    const tempArray = data.map(element => {
      return {
        label: element.name,
        value: element.id
      };
    });

    return tempArray;
  };

  const fetchJobManagments = (inputValue, callback) => {
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        paginate: false
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const fetchCostCenters = (inputValue, callback) => {
    debounceIndexCostCentersRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 100
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  const fetchBranchOffices = (inputValue, callback) => {
    const request = async () => {
      debounceIndexBranchOfficesRequest({
        dispatch,
        params: {
          actives: true,
          name: inputValue,
          sort_column: 'name',
          paginate: false
        },
        successCallback: data => callback(resultFetchData(data))
      });
    };
    request();
  };

  const fetchBalanceAssets = (inputValue, callback) => {
    const request = async () => {
      debounceIndexBalancesRequest({
        dispatch,
        params: {
          selector: true,
          filter_type: 1,
          filter_by_advance: true,
          name: inputValue
        },
        successCallback: response => callback(resultFetchData(response))
      });
    };
    request();
  };

  const apTypes = advancePaymentsTypes.filter(asItem => asItem.value !== 'contract_balances');

  const searchEmployeesParams = () => {
    const request = async () =>
      searchEmployeeRequest({
        dispatch,
        params: {
          sort_column: 'name',
          paginate: false,
          advance: true,
          filter_employee_status: '1'
        },
        successCallback: response => camelCaseRecursive(response.data.data)
      });
    request();
  };

  const fetchEmployee = (inputValue, callback) => {
    debounceIndexSelectEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        sort_column: 'name',
        is_dt: false,
        display_length: 100
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const initialFetch = () => {
    searchEmployeesParams();
  };

  useEffect(initialFetch, []);

  return (
    <Form>
      <Card>
        <Col className="top-header-green">
          <Accordion.Toggle as={Col} eventKey="0" className="card-header-title">
            FILTROS DE BÚSQUEDA
          </Accordion.Toggle>
        </Col>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="div-content">
            <Row>
              <Col xs={12} md={4}>
                <Field name="filterId">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Trabajador"
                      placeholder="Seleccionar Trabajador"
                      value={undefined}
                      request={fetchEmployee}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="filterByBalanceId">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Concepto"
                      placeholder="Seleccionar Concepto"
                      request={fetchBalanceAssets}
                      value={undefined}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="filterByAdvanceType">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Origen del Anticipo"
                      placeholder="Seleccionar por Anticipo"
                      defaultOptions={apTypes}
                      value={undefined}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Field name="filterCostCenter">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Centro de costo"
                      placeholder="Seleccionar centro de costo"
                      request={fetchCostCenters}
                      value={undefined}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="filterBranchOffice">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Lugar de Prestación de Servicio"
                      placeholder="Seleccionar Lugar de Prestación"
                      request={fetchBranchOffices}
                      value={undefined}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="filterJobManagement">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Area"
                      placeholder="Seleccionar area"
                      request={fetchJobManagments}
                      value={undefined}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Field name="filterEmployeeStatus">
                  {({ field }) => (
                    <FormikSelect
                      options={employeeStatus}
                      label="Estado del Trabajador"
                      value={status}
                      defaultValue={status}
                      onChange={data => {
                        setStatus(data ? data.value : '');
                        setFieldValue(field.name, data ? data.value : '');
                      }}
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-4">
              <Col md={2}>
                <Button className="btn-block" type="submit">
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Form>
  );
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: () => ({
    filterJobManagement: '',
    filterCostCenter: '',
    filterBranchOffice: '',
    filterByAdvanceType: '',
    filterByBalanceId: '',
    filterId: '',
    filterEmployeeStatus: ''
  }),
  handleSubmit,
  enableReinitialize: true
})(EmployeeFilterAp);
