/* eslint-disable no-await-in-loop */
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Image, Row, Col, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FileText } from 'react-feather';

import LoadSpiner from '../Spinner';
import defaultImg from '../../../assets/images/default.png';
import ButtonTooltip from '../Tooltips/ButtonTooltip';
import Icon from '../../Icons/Icon';
import { formatBytes } from '../../../services/utils';

class DropzoneComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: props.files,
      isDisabled: props.disabled || false,
      maxFiles: props.multiple ? props.maxFiles || undefined : 1,
      onClear: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { clearValues, msgMaxFiles } = this.props;
    const { files, maxFiles } = this.state;

    if (clearValues !== prevProps.clearValues) {
      this.clearAllFiles();
    }

    if (prevState.files?.length !== files?.length) {
      if (files.length >= maxFiles) {
        msgMaxFiles(`Superaste la cantidad máxima de archivos. El límite de archivos es ${maxFiles}.`);
      } else {
        msgMaxFiles('');
      }
      this.getCountFiles();
    }
  }

  getCountFiles = () => {
    const { files } = this.state;
    const { countFiles } = this.props;
    if (countFiles) countFiles(files.length);
  };

  clearAllFiles = () => {
    this.setState({ files: [], isDisabled: false, onClear: true });
  };

  removeFile = fileId => {
    const { files, maxFiles } = this.state;
    const { onDelete, afterDelete } = this.props;
    const newFiles = [];
    let fileToRemove;

    files.forEach(file => {
      if (file.code !== fileId) {
        newFiles.push(file);
      } else {
        fileToRemove = file;
      }
    });
    this.setState({ files: newFiles, onClear: true });
    onDelete(fileToRemove);
    if (afterDelete) afterDelete(fileToRemove);
    if (newFiles.length < maxFiles) {
      this.setState({ isDisabled: false });
    }
  };

  removePersistedFile = fileId => {
    const { maxFiles } = this.state;
    const { persistedFiles, onDeletePersistedFiles } = this.props;
    const newFiles = [];
    let fileToRemove;

    persistedFiles.forEach(file => {
      if (file.id !== fileId) {
        newFiles.push(file);
      } else {
        fileToRemove = file;
      }
    });

    onDeletePersistedFiles(fileToRemove);
    this.setState({ onClear: true });
    if (newFiles.length < maxFiles) {
      this.setState({ isDisabled: false });
    }
  };

  addFiles = async newFiles => {
    const { duplicateFile, batchNumber, onDrop, onDropUploaded } = this.props;
    const { maxFiles } = this.state;

    await newFiles.forEach(async file => {
      const { files } = this.state;

      if (maxFiles !== undefined && files.length + 1 >= maxFiles) {
        this.setState({ isDisabled: true });
        if (files.length >= maxFiles) return;
      }
      if (!duplicateFile) {
        const duplicate = files.filter(f => f.name === file.name && f.size === file.size && f.type === file.type);
        if (duplicate.length) return;
      }

      const reader = new FileReader();
      const code = Math.floor(Math.random() * 10000000000).toString(16);

      this.setState({
        files: [
          ...files,
          {
            code,
            name: file.path,
            result: defaultImg,
            size: file.size,
            type: file.type,
            file,
            loaded: false,
            uploaded: false
          }
        ]
      });
      reader.onloadend = () => {
        const { files: loadFiles } = this.state;
        const newFile = loadFiles.map(f => {
          if (f.code === code) return { ...f, result: reader.result };
          return f;
        });
        this.setState({ files: newFile });
      };
      reader.readAsDataURL(file);
    });

    const { files: totalFiles } = this.state;
    const { id: recordId } = this.props;
    const addedFiles = [];
    let newTotalFiles = [];

    totalFiles.forEach(f => {
      if (!f.loaded) addedFiles.push(f);
      newTotalFiles.push({ ...f, loaded: true });
    });
    this.setState({ files: newTotalFiles });

    const batchN = batchNumber || addedFiles.length;
    const rangeBatchNumber = Math.ceil(addedFiles.length / batchN);
    let batchFiles;

    for (let index = 0; index < rangeBatchNumber; index += 1) {
      batchFiles = addedFiles.slice(index * batchN, (index + 1) * batchN);
      const filesToFetch = batchFiles.map(bf => ({
        document_key: bf.code,
        document: bf.file
      }));
      const response = await onDrop(recordId, filesToFetch);

      if (response && response.status === 201) {
        const result = await response.data;
        const { files: totalFilesLoaded } = this.state;
        newTotalFiles = totalFilesLoaded.map(ntf => {
          const fileUploaded = result.filter(r => r.document_key === ntf.code)[0];
          return fileUploaded ? { ...ntf, uploaded: true, id: fileUploaded.id } : ntf;
        });
      }
      onDropUploaded(newTotalFiles);
      this.setState({ files: newTotalFiles });
    }
  };

  textDropzone = ({ isDragActive, isDragReject, isDisabled, isFileTooLarge, isFileNotFormat, rejectedFiles }) => {
    const {
      texts: { textDefault, reject, active, disabled, tooLarge, formatType }
    } = this.props;
    const { onClear } = this.state;

    if (isDisabled) return disabled;
    if (isDragReject) return reject;
    if (isFileTooLarge && !onClear) {
      return (
        <p className="too-large ml-md-4">
          <strong>{tooLarge}</strong>
          <br />
          {rejectedFiles.map((file, i) => (
            <span key={`rejected-${i.toString()}`}>
              {i + 1}. {file.name} <br />
            </span>
          ))}
        </p>
      );
    }
    if (isFileNotFormat && !onClear) {
      return (
        <p className="too-large ml-md-4">
          <strong>{formatType}</strong>
          <br />
          {rejectedFiles.map((file, i) => (
            <span key={`rejected-${i.toString()}`}>
              {i + 1}. {file.name} <br />
            </span>
          ))}
        </p>
      );
    }
    if (isDragActive) return active;
    return textDefault;
  };

  classContainer = ({ isDragActive, isDragReject, isDisabled, isFileTooLarge, isFileNotFormat }) => {
    const {
      classes: { container, reject, active, disabled }
    } = this.props;
    let className = container;
    if (isDragReject || isFileTooLarge || isFileNotFormat) {
      className += ` ${reject}`;
      return className;
    }
    if (isDragActive) className += ` ${active}`;
    if (isDisabled) className += ` ${disabled}`;
    return className;
  };

  preview = files =>
    files.map(file => (
      <Row className="d-flex align-items-center mx-0 mt-1" key={`preview-${file.code}`}>
        <Col md={1}>
          <div className="dropzone-box">
            <div className="box-img ml-2 mb-1 mb-md-0 ml-md-0 d-flex justify-content-start justify-content-md-center">
              <div className="content">
                {file.type.includes('image') ? (
                  <Image src={file.result} className="image-fit" width="40" />
                ) : (
                  <FileText className="icon-file-text" strokeWidth="1.5" />
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col md={7}>
          <p className="mb-0">{file.name}</p>
        </Col>
        <Col>
          <Row className="d-flex align-items-center">
            <Col className="hover-dropzone text-right">{file.uploaded ? formatBytes(file.size) : <LoadSpiner />}</Col>
            <Col md={5} className="hover-dropzone d-flex justify-content-end">
              {file.uploaded && (
                <ButtonTooltip
                  onClick={() => this.removeFile(file.code)}
                  variant="circle-danger"
                  className="btn-circle"
                  text="Eliminar"
                >
                  <Icon icon="trash" />
                </ButtonTooltip>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    ));

  previewPersistedFiles = files =>
    files.map(file => (
      <Row className="d-flex align-items-center mx-0 mt-1" key={`persisted-${file.id}`}>
        <Col md={1} key={file.id}>
          <div className="dropzone-box">
            <div className="box-img d-flex justify-content-center">
              <div className="content">
                {file.type.includes('image') ? (
                  <Image src={file.url} className="image-fit" width="100" />
                ) : (
                  <FileText className="icon-file-text" strokeWidth="1.5" />
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col md={7}>
          <p className="text-truncate mb-0">{file.filename}</p>
        </Col>
        <Col>
          <Row className="d-flex align-items-center">
            <Col className="text-right">
              <p className="mb-0 font-weight-normal">{formatBytes(file.size)}</p>
            </Col>
            <Col md={5} className="d-flex justify-content-end">
              <ButtonTooltip
                onClick={() => this.removePersistedFile(file.id)}
                variant="circle-danger"
                className="btn-circle"
                text="Eliminar"
              >
                <Icon icon="trash" />
              </ButtonTooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    ));

  afterNewFileAttempt = () => {
    const { onFileDialogCancel } = this.props;
    this.setState({ onClear: false });
    onFileDialogCancel();
  };

  render() {
    const { files, isDisabled } = this.state;
    const isFiles = files.length > 0;
    const {
      multiple,
      maxSize,
      minSize,
      fileAccept,
      persistedFiles,
      customClass,
      onFileDialogOpen,
      onFileDialogCancel,
      className,
      shortDropzone
    } = this.props;
    return (
      <section className={`dropzone-section ${customClass}`}>
        <div
          onClick={onFileDialogOpen}
          role="button"
          tabIndex={0}
          aria-hidden="true"
          className={`${!isFiles ? 'h-94' : ''}`}
        >
          <Dropzone
            onDrop={newFiles => this.addFiles(newFiles)}
            disabled={isDisabled}
            multiple={multiple}
            maxSize={maxSize}
            minSize={minSize}
            accept={fileAccept.replaceAll(' ', ',')}
            onFileDialogCancel={onFileDialogCancel}
            onDropAccepted={this.afterNewFileAttempt}
            onDropRejected={this.afterNewFileAttempt}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
              const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
              const isFileNotFormat =
                rejectedFiles.length > 0 && !fileAccept.split('.').includes(rejectedFiles[0].type.split('/')[1]);
              return (
                <div
                  {...getRootProps()}
                  className={`${!isFiles ? 'h-100' : ''} ${this.classContainer({
                    isDragActive,
                    isDragReject,
                    isDisabled,
                    isFileTooLarge,
                    isFileNotFormat
                  })}`}
                >
                  <input {...getInputProps()} />
                  <div
                    className={`font-weight-bold ${!className && 'default-size'} ${shortDropzone && 'h-short mt-2'}`}
                  >
                    {this.textDropzone({
                      isDragActive,
                      isDragReject,
                      isDisabled,
                      isFileTooLarge,
                      isFileNotFormat,
                      rejectedFiles
                    })}
                  </div>
                  {isDragActive && (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}
                </div>
              );
            }}
          </Dropzone>
        </div>
        {files.length > 0 && (
          <div className={`box-files mt-2 ${className || ''} ${shortDropzone && 'h-short'}`}>
            {this.previewPersistedFiles(persistedFiles)}
            {this.preview(files)}
          </div>
        )}
      </section>
    );
  }
}

DropzoneComponent.propTypes = {
  texts: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  countFiles: PropTypes.func,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  persistedFiles: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.number,
  onDrop: PropTypes.func,
  onDropUploaded: PropTypes.func,
  onDelete: PropTypes.func,
  onDeletePersistedFiles: PropTypes.func,
  onFileDialogOpen: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  batchNumber: PropTypes.number,
  duplicateFile: PropTypes.bool,
  maxFiles: PropTypes.number,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  msgMaxFiles: PropTypes.func,
  fileAccept: PropTypes.string,
  customClass: PropTypes.string
};

DropzoneComponent.defaultProps = {
  texts: {
    textDefault: (
      <div className="dropzone-add pt-3 pb-1">
        <p className="font-weight-bold mb-2">Arrastra aquí tus archivos</p>
        <p>o haz click para buscar</p>
      </div>
    ),
    reject: 'Archivo no valido para subir.',
    active: 'Suelta los archivos aquí para subir...',
    disabled: 'Alcanzaste la cantidad máxima de archivos.',
    tooLarge: 'Los siguientes archivos exceden el peso máximo permitido y no serán cargados:',
    formatType: 'Los siguientes archivos no son del tipo admitido'
  },
  classes: {
    container: 'dropzone-container pl-2',
    reject: 'dropzone-reject',
    active: 'dropzone-active',
    disabled: 'dropzone-disabled'
  },
  countFiles: () => null,
  files: [],
  persistedFiles: [],
  id: Date.now(),
  // eslint-disable-next-line no-console
  onDrop: (id, files) => console.log(id, files),
  // eslint-disable-next-line no-console
  onDropUploaded: files => console.log(files),
  // eslint-disable-next-line no-console
  onDelete: file => console.log(file),
  // eslint-disable-next-line no-console
  onDeletePersistedFiles: file => console.log(file),
  onFileDialogOpen: () => null,
  onFileDialogCancel: () => null,
  batchNumber: 20,
  duplicateFile: true,
  maxFiles: 100,
  disabled: false,
  multiple: true,
  maxSize: 50000000, // 50 MB
  minSize: undefined,
  msgMaxFiles: () => null,
  fileAccept: '',
  customClass: ''
};

export default DropzoneComponent;
