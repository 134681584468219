import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Spinner } from 'react-bootstrap';
import Icon from '../../Icons';
import { debounceIndexEmployeesRequest } from '../../../requests/employees';
import MarkerCompanyLogo from '../empresa-pin.png';

const Loading = () => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
    <Spinner animation="border" variant="primary" />
  </div>
);

const CustomMarker = ({
  isBranchOffice = false,
  employeeBranchOfficeId = null,
  setModalShow = null,
  setBody = null,
  title = null
}) => {
  const dispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);

  const searchEmployeesParams = () => {
    const request = async () => {
      setBody(<Loading />);
      debounceIndexEmployeesRequest({
        dispatch,
        params: {
          filter_active_branch_offices: employeeBranchOfficeId
        },
        successCallback: response => {
          const { data } = response.data;
          const myData = data
            // eslint-disable-next-line
            .map(({ full_name, user: { national_identification } }) => {
              return { full_name, national_identification };
            })
            .sort((a, b) => a.national_identification.localeCompare(b.national_identification));

          setBody(
            <>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingLeft: 15
                }}
              >
                {myData.length > 0 ? (
                  myData.map(v => (
                    <Row
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                      key={v.national_identification}
                    >
                      <Col style={{ fontWeight: 'normal', textAlign: 'left' }}>
                        <>{v.national_identification}</>
                      </Col>
                      <Col style={{ fontWeight: 'normal', textAlign: 'left' }}>
                        <>{v.full_name}</>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <Col>
                      <>No hay colaboradores</>
                    </Col>
                  </Row>
                )}
              </Row>
            </>
          );
        }
      });
    };
    request();
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      {isBranchOffice ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <img
          alt="Company"
          src={MarkerCompanyLogo}
          style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            width: 25,
            height: 30
          }}
          onClick={() => {
            searchEmployeesParams();
            setModalShow(true);
          }}
        />
      ) : (
        <>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {showTooltip && (
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'black',
                  color: 'white',
                  fontSize: '18',
                  height: 20,
                  width: `${title.length * 7}px`,
                  top: -30,
                  transform: 'translate(-50%, -50%)',
                  borderRadius: 10,
                  zIndex: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 3
                  }}
                >
                  {title}
                </div>
              </div>
            )}
            <Icon
              icon="location"
              style={{
                color: isBranchOffice ? 'black' : 'red',
                height: 30,
                width: 30,
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                left: '-50%',
                top: '-50%'
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CustomMarker;
