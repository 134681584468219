import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon, ApprovementStatus, DatatableEmployeeName } from '../../components';

export default memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'document_employee_name.full_name',
    cell: item => (
      <DatatableEmployeeName item={item.document_employee_name} onClick={() => clickHandler(item, 'show')} />
    ),
    sortable: false,
    grow: '2'
  },
  {
    name: 'TIPO DOCUMENTO',
    selector: 'translated_workflow_type',
    sortable: false,
    grow: '2'
  },
  {
    name: 'DOCUMENTO',
    selector: 'document.file_name',
    sortable: false,
    grow: '2'
  },
  {
    name: 'FECHA',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'GENERADO POR',
    selector: 'employees.full_name',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    sortable: false,
    grow: '2'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        {item.status === 'pending' && !item.review_turn && (
          <ButtonTooltip
            variant="circle-warning"
            className="mr-2 btn-circle"
            text="Enviar Recordatorio"
            onClick={() => clickHandler(item, 'resend')}
          >
            <Icon icon="notifications" />
          </ButtonTooltip>
        )}
        <ButtonTooltip
          variant="circle-info"
          onClick={() => clickHandler(item)}
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
      </>
    ),
    ignoreRowClick: true,
    right: true,
    grow: '1',
    minWidth: '123px'
  }
]);
