import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useAbility } from '@casl/react';
import { ButtonTooltip } from '../Utils/Tooltips';
import { AbilityContext } from '../../config/abilityContext';
import BasicDropdown from '../Utils/Dropdown/BasicDropdown';
import Icon from '../Icons';

const EmployeeActions = ({ handleClick, handleFilters, disabled, filters, removeExpireFilter, showActions }) => {
  const ability = useAbility(AbilityContext);
  const { advanceSettings } = useSelector(state => state.utils);
  const expireDate = advanceSettings?.filter(asItem => asItem.code === 'expire_days')[0]?.integerValue || 5;

  const items = [
    {
      key: 'toExpire',
      title: `Contratos por vencer (próx. ${expireDate} días)`,
      props: {
        onClick: () => handleFilters('toExpire'),
        active: filters.toExpire
      }
    },
    {
      key: 'activeStaffing',
      title: 'Dotación de hoy',
      props: {
        onClick: () => handleFilters('activeStaffing'),
        active: filters.activeStaffing
      }
    },
    {
      key: 'active',
      title: 'Activos',
      props: {
        onClick: () => handleFilters('active'),
        active: filters.active
      }
    },
    {
      key: 'inactive',
      title: 'Inactivos',
      props: {
        onClick: () => handleFilters('inactive'),
        active: filters.inactive
      }
    }
  ];

  if (removeExpireFilter) {
    items.shift();
  }

  return (
    <>
      {ability.can('generate_contract_view_rrhh', 'Contract') && (
        <ButtonTooltip
          variant="circle-primary"
          className="mr-2 btn-circle mb-3 mr-2"
          text="Generar Contrato"
          disabled={disabled}
          onClick={() => handleClick('contract')}
        >
          <Icon icon="document" />
        </ButtonTooltip>
      )}
      {ability.can('generate_annex_view_rrhh', 'Contract') && (
        <>
          <ButtonTooltip
            variant="circle-primary"
            className="mr-2 btn-circle mb-3 mr-2"
            text="Generar Anexo de Contrato"
            disabled={disabled}
            onClick={() => handleClick('annex')}
          >
            <Icon icon="document-attach" />
          </ButtonTooltip>
          <ButtonTooltip
            variant="circle-primary"
            className="mr-2 btn-circle mb-3 mr-2"
            text="Generar Pactos de horas extras"
            disabled={disabled}
            onClick={() => handleClick('overtime_agreements')}
          >
            <Icon icon="document-text" />
          </ButtonTooltip>
        </>
      )}
      {ability.can('send_activation_email', 'Employee') && (
        <ButtonTooltip
          variant="circle-primary"
          className="btn-circle mb-3 mr-2"
          text="Enviar correo de enrolamiento"
          disabled={disabled}
          onClick={() => handleClick('send_activation_email')}
        >
          <Icon icon="paper-plane-outline" />
        </ButtonTooltip>
      )}
      {ability.can('deactivate', 'Employee') && (
        <ButtonTooltip
          variant="circle-danger"
          className="btn-circle mb-3 mr-2"
          text="Desactivar/Activar"
          disabled={disabled}
          onClick={() => handleClick('archive')}
        >
          <Icon icon="archive" />
        </ButtonTooltip>
      )}
      {showActions && (
        <BasicDropdown
          variant="outline-primary"
          responsive
          noArrow
          block
          right
          titleDrop={
            <span className="align-middle">
              Mostrar <Icon icon="ellipsis-vertical" height="20px" width="20px" />
            </span>
          }
          items={items}
        />
      )}
    </>
  );
};

EmployeeActions.propTypes = {
  handleClick: PropTypes.func.isRequired,
  showActions: PropTypes.bool
};

EmployeeActions.defaultProps = {
  showActions: true
};

export default EmployeeActions;
