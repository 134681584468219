import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal, Spinner } from 'react-bootstrap';
import { FormikSelect } from '../../../components/index';
import { calculateDistance, delayMethod } from '../../../services/utils';
import MapAttendance from '../../../components/Map/MapAttendance/MapAttendance';

const DashboardAttendance = props => {
  const { errors, touched, setFieldValue, handleModalClose, branchOffices, isSubmitting } = props;
  const [geolocalitation, setGeolocalitation] = useState(null);
  const [distance, setDistance] = useState([]);
  const [nearestBranch, setNearestBranch] = useState(null);

  const timeTypes = [
    { label: 'Entrada', value: 'arrival' },
    { label: 'Salida', value: 'departure' },
    { label: 'Entrada Colación', value: 'arrival_break_time' },
    { label: 'Salida Colación', value: 'departure_break_time' }
  ];

  const findNearestPlace = (lat, lng) => {
    let nearbyPlace = null;
    let closestDistance = Infinity;
    if (branchOffices.length > 0) {
      branchOffices.forEach(branchOffice => {
        const dist = parseFloat(calculateDistance(lat, lng, branchOffice.lat, branchOffice.lng));
        setDistance([...distance, dist]);
        if (dist < closestDistance) {
          closestDistance = dist;
          nearbyPlace = branchOffice;
        }
      });
    }
    return nearbyPlace;
  };

  const fetchGelocation = () => {
    let coordenates = null;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
        coordenates = { lat, lng };
        setGeolocalitation(coordenates);
      });
    } else {
      setGeolocalitation(null);
    }
  };

  const setBranchOfficeId = () => {
    if (geolocalitation) {
      const nearestPlace = findNearestPlace(geolocalitation?.lat, geolocalitation?.lng);
      setNearestBranch(nearestPlace);
      setFieldValue('attendance[branchOfficeId]', nearestPlace?.id || '');
    }
  };
  useEffect(fetchGelocation, []);

  useEffect(setBranchOfficeId, [geolocalitation, branchOffices]);

  const CoordenatesForBackEnd = data => {
    if (geolocalitation) {
      const coordenates = data;
      coordenates.field.value.lat = geolocalitation.lat;
      coordenates.field.value.lng = geolocalitation.lng;
    }
    return null;
  };

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Field name="attendance[timeType]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Hora"
                  placeholder="Seleccionar Tipo de Hora"
                  options={timeTypes}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="attendance[branchOfficeId]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Lugar de Prestación de Servicios"
                  placeholder="Seleccionar Lugar de Prestación de Servicios"
                  options={branchOffices}
                  defaultValue={nearestBranch?.id}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={4}>
            <Field name="attendance[coordenates]">{({ field }) => <CoordenatesForBackEnd field={field} />}</Field>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ height: '30vh' }}>
            {geolocalitation ? (
              <MapAttendance brachOfficesCoordinates={branchOffices} myLocation={geolocalitation} />
            ) : (
              <>
                <p>Debe Aceptar la Geolocalización. De lo contrario, no se puede registrar su asistencia</p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 80,
                    width: '100%'
                  }}
                >
                  <Spinner animation="border" variant="primary" />
                </div>
              </>
            )}
          </Col>
          <Col>*La dirección es referencial y no refleja la ubicación exacta</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
          Cancelar
        </Button>
        {geolocalitation != null ? (
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            Guardar
          </Button>
        ) : (
          <Button type="submit" variant="primary" disabled={geolocalitation === null}>
            Guardar
          </Button>
        )}
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { attendances } = props;
  const attendance = attendances[0];

  return {
    attendance
  };
};

const validationSchema = Yup.object().shape({
  attendance: Yup.object().shape({
    date: Yup.string().required('Debes escoger una fecha'),
    time: Yup.string().required('Debes escoger la hora'),
    timeType: Yup.string().required('Debes escoger el tipo de hora'),
    employeeId: Yup.string().required('Debe haber un empleado asociado'),
    branchOfficeId: Yup.string().required('Debe escoger un lugar de prestación de servicios')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(DashboardAttendance);
