import React from 'react';
import { Icon, BasicDropdown } from '../../../../components';

const MassiveActions = ({ handleClick, disabled, employeeMenu }) => {
  const actions = () => {
    const authorizedActions = [];
    if (employeeMenu[0]) {
      authorizedActions.push({
        icon: <Icon icon="create" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'massive-edit',
        props: {
          onClick: () => handleClick('massive-edit'),
          disabled
        },
        title: 'Corregir'
      });
    }

    if (employeeMenu[2]) {
      authorizedActions.push({
        icon: <Icon icon="checkmark-circle" color="green" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'massive-approved',
        props: {
          onClick: () => handleClick('massive-approved'),
          disabled
        },
        title: 'Aprobar'
      });

      authorizedActions.push({
        key: 'massive-rejected',
        title: `Rechazar`,
        icon: <Icon icon="close-circle" height="16px" color="orange" width="16px" style={{ marginRight: 5 }} />,
        props: {
          onClick: () => handleClick('massive-rejected'),
          disabled
        }
      });
    }

    if (employeeMenu[3]) {
      authorizedActions.push({
        icon: <Icon icon="archive" color="red" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'massive-disabled',
        props: {
          onClick: () => handleClick('massive-disabled'),
          disabled
        },
        title: 'Desactivar'
      });
    }

    return authorizedActions;
  };

  return (
    <div className="mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
};

export default MassiveActions;
