import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ComponentDataTable } from '../../../../components';
import ColumnsPeriods from '../Columns/ColumnsPeriods';
import { PayrollProcessContext } from '../../context/PayrollProcessProvider';
import { exportPayrollLoanRequest, indexPayrollLoanProcessRequest } from '../../../../requests/payrollLoanProcesses';
import { downloadFile } from '../../../../services/utils';
import { sendAlert } from '../../../../actions/utils';

const PeriodsDataTable = () => {
  const dispatch = useDispatch();
  const { payrollProcess } = useContext(PayrollProcessContext);
  const [payrollLoanProcessesData, setPayrollLoanProcessesData] = useState([]);
  const [amount, setAmount] = useState(0);
  const [payrollCurrentState, setPayrollCurrentState] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const handleRequest = () => {
    setOnRequest(true);
    indexPayrollLoanProcessRequest({
      dispatch,
      params: {
        filter_ids: payrollProcess.payroll_process_loan_id,
        loan_process_id: payrollProcess.selectedLoanProcesses,
        paginate: false
      },
      successCallback: ({ data }) => {
        if (data.data?.at(0)?.loan_processes) {
          setPayrollLoanProcessesData(data.data[0].loan_processes);
          setPayrollCurrentState(data.data[0].status);
          setAmount(data.data[0].loan_processes.length);
        }
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleExport = (payrollId, loanProcessId) => {
    setOnRequest(true);
    exportPayrollLoanRequest({
      dispatch,
      params: {
        payroll_process_id: payrollId,
        loan_process_id: loanProcessId
      },
      successCallback: response => {
        downloadFile(response);
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'danger', message: 'No se pudo exportar error fatal' }));
      },
      callback: () => setOnRequest(false)
    });
  };

  useEffect(handleRequest, [])


  return (
    <ComponentDataTable
      onRequest={onRequest}
      columns={ColumnsPeriods(handleExport, payrollCurrentState)}
      data={payrollLoanProcessesData}
      totalRows={amount}
      moreData={false}
      paginationServer={false}
      withSearch={false}
      sortServer={false}
      pagination={false}
      withMassActions
    />
  );
};

export default PeriodsDataTable;
