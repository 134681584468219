import React from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { FormikSelect } from '../../../../components';
import BalanceTypeOptions from '../Form/BalanceTypeOptions';
import '../Form/custom.css';

const PeriodSelect = () => {
  const { values, setFieldTouched, setFieldValue, errors, touched } = useFormikContext();
  const handleDisableDate = periodSelected => {
    if (periodSelected === 'permanent') {
      setFieldValue('payrollBalance[endDate]', null);
      setFieldTouched('payrollBalance[endDate]', false);
    }
  };

  return (
    <Field name="payrollBalance[periodicity]">
      {({ field }) => (
        <FormikSelect
          {...field}
          margin="mb-0"
          label="Periodicidad"
          placeholder="Selecionar Periodo.."
          defaultValue={values.payrollBalance.periodicity}
          options={BalanceTypeOptions}
          setFieldTouched={() => setFieldTouched(field.name)}
          onChange={data => {
            handleDisableDate(data.value || null);
            setFieldValue(field.name, data ? data.value : '');
          }}
          isClearable
          error={getIn(errors, field.name)}
          touched={getIn(touched, field.name)}
          menuPlacement="auto"
        />
      )}
    </Field>
  );
};

export default PeriodSelect;
