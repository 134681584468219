import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexAttendanceChangeRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/attendance_change_requests', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const deleteAttendanceChangeRequest = (id, { dispatch, successCallback, callback }) =>
  ApiService.request('delete', `/attendance_change_requests/${id}`, {
    dispatch,
    successCallback,
    callback
  });

export const approvedAttendanceChangeRequest = (
  id,
  { dispatch, successCallback, callback, failureCallback, params = {} }
) =>
  ApiService.request('post', `/attendance_change_requests/${id}/approve`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback
  });

export const rejectAttendanceChangeRequest = (id, { dispatch, successCallback, callback, params = {} }) =>
  ApiService.request('post', `/attendance_change_requests/${id}/reject`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const createAttendanceChangeRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', '/attendance_change_requests', {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });

export const massiveCreateAttendanceChangeRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/attendance_change_requests/massive_create', {
    dispatch,
    params,
    formData,
    showLeafArrayIndexes: true,
    failureCallback,
    successCallback
  });

export const massiveApprovedAttendanceChangeRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/attendance_change_requests/massive_approved', {
    dispatch,
    params,
    formData,
    showLeafArrayIndexes: true,
    failureCallback,
    successCallback
  });

export const massivRejectedAttendanceChangeRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/attendance_change_requests/massive_rejected', {
    dispatch,
    params,
    formData,
    showLeafArrayIndexes: true,
    failureCallback,
    successCallback
  });

export const massivDisabledAttendanceChangeRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/attendance_change_requests/massive_disabled', {
    dispatch,
    params,
    formData,
    showLeafArrayIndexes: true,
    failureCallback,
    successCallback
  });

export const massiveActiveAttendanceChangeRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/attendance_change_requests/massive_active', {
    dispatch,
    params,
    formData,
    showLeafArrayIndexes: true,
    failureCallback,
    successCallback
  });

export const massiveCreateAttendance = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/attendance_change_requests/massive_create_attendance', {
    dispatch,
    params,
    formData,
    showLeafArrayIndexes: true,
    failureCallback,
    successCallback
  });

export const debounceIndexAttendanceChangeRequest = AwesomeDebouncePromise(indexAttendanceChangeRequest, 300);
