import React, { useEffect } from 'react';
import TotalizedAbsenceReportDataTable from './TotalizedAbsenceReportDataTable';

const TotalizedAbsenceReportIndex = ({ rrhh }) => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <TotalizedAbsenceReportDataTable rrhh={rrhh} />
    </>
  );
};

export default TotalizedAbsenceReportIndex;
