import { useFormikContext } from 'formik';
import React from 'react';
import { FormikNumber } from '../../../../components';

const AmountInput = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  return (
    <>
      <FormikNumber
        abbr
        leftAddon="$"
        placeholder="0"
        label="Monto"
        fieldName="payrollBalance[amount]"
        setFieldValue={setFieldValue}
        value={values?.payrollBalance?.amount}
        errors={errors}
        touched={touched}
        margin="mb-0"
      />
    </>
  );
};

export default AmountInput;
