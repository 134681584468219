import React from 'react';
import { Row, Col, Card, Accordion } from 'react-bootstrap';

const yesNo = value => {
  return value ? 'Sí' : 'No';
};

const VoluntaryPrevisionShow = ({ voluntaryPrevision }) => {
  const { voluntaryPrevisionType } = voluntaryPrevision;
  const previsionCollectiveApv = voluntaryPrevisionType === 'collective_apv';
  const previsionIndemnityEvent = voluntaryPrevisionType === 'indemnity_event';
  const previsionVoluntaryAffiliate = voluntaryPrevisionType === 'voluntary_affiliate';
  const withTaxReduction = voluntaryPrevisionType === 'collective_apv' || voluntaryPrevisionType === 'deposit_agreed';
  const previsionApv = voluntaryPrevisionType === 'apv';
  const labelAgreedQuantity = previsionCollectiveApv ? 'Aporte Trabajador' : 'Cantidad Acordada';

  return (
    <>
      <div className="info">
        <span className="type">Tipo de Previsión Voluntaria:</span>
        <span className="line" />
        <span className="answer" />
        {voluntaryPrevision.translatedVoluntaryPrevisionType}
      </div>
      {withTaxReduction && (
        <div className="info">
          <span className="type">Rebaja de la base tributaria:</span>
          <span className="line" />
          <span className="answer" />
          {yesNo(voluntaryPrevision.taxReduction)}
        </div>
      )}
      {voluntaryPrevisionType !== 'voluntary_affiliate' && (
        <div className="info">
          <span className="type">{labelAgreedQuantity}:</span>
          <span className="line" />
          <span className="answer" />
          {voluntaryPrevision.parsedAgreedQuantity}
        </div>
      )}
      {previsionCollectiveApv && (
        <div className="info">
          <span className="type">Aporte Empleador:</span>
          <span className="line" />
          <span className="answer" />
          {voluntaryPrevision.parsedEmployerContribution}
        </div>
      )}
      {previsionVoluntaryAffiliate && (
        <>
          <div className="info">
            <span className="type">Rut Afiliado Voluntario:</span>
            <span className="line" />
            <span className="answer" />
            {voluntaryPrevision.voluntaryIdentificationNumber}
          </div>

          <div className="info">
            <span className="type">Nombre Afiliado Voluntario:</span>
            <span className="line" />
            <span className="answer" />
            {voluntaryPrevision.voluntaryName
              ? voluntaryPrevision.voluntaryName.charAt(0).toUpperCase() +
                voluntaryPrevision.voluntaryName.slice(1).toLowerCase()
              : ''}
          </div>

          <div className="info">
            <span className="type">Apellido Paterno Afiliado Voluntario:</span>
            <span className="line" />
            <span className="answer" />
            {voluntaryPrevision.voluntaryLastName
              ? voluntaryPrevision.voluntaryLastName.charAt(0).toUpperCase() +
                voluntaryPrevision.voluntaryLastName.slice(1).toLowerCase()
              : ''}
          </div>

          <div className="info">
            <span className="type">Apellido Materno Afiliado Voluntario:</span>
            <span className="line" />
            <span className="answer" />
            {voluntaryPrevision.voluntarySecondLastName
              ? voluntaryPrevision.voluntarySecondLastName.charAt(0).toUpperCase() +
                voluntaryPrevision.voluntarySecondLastName.slice(1).toLowerCase()
              : ''}
          </div>

          <div className="info">
            <span className="type">Fecha Desde:</span>
            <span className="line" />
            <span className="answer" />
            {voluntaryPrevision.periodFrom}
          </div>

          <div className="info">
            <span className="type">Fecha Hasta:</span>
            <span className="line" />
            <span className="answer" />
            {voluntaryPrevision.periodTo}
          </div>

          <div className="info">
            <span className="type">Código Movimiento de Personal</span>
            <span className="line" />
            <span className="answer" />
            {voluntaryPrevision.translatedPersonalMovmentCode}
          </div>
        </>
      )}
      <div className="info">
        <span className="type">Institución:</span>
        <span className="line" />
        <span className="answer"> {voluntaryPrevision.voluntaryPensionFundName}</span>
      </div>
      {previsionApv && (
        <>
          <div className="info">
            <span className="type">Régimen Tributario:</span>
            <span className="line" />
            <span className="answer"> {voluntaryPrevision.translatedVoluntaryPensionFundType}</span>
          </div>
          <div className="info">
            <span className="type">Número de Contrato:</span>
            <span className="line" />
            <span className="answer"> {voluntaryPrevision.contractNumber}</span>
          </div>
          <div className="info">
            <span className="type">Forma de Pago:</span>
            <span className="line" />
            <span className="answer"> {voluntaryPrevision.translatedPaymentMethod}</span>
          </div>
        </>
      )}
      {previsionCollectiveApv && (
        <>
          <div className="info">
            <span className="type">Número de Contrato:</span>
            <span className="line" />
            <span className="answer"> {voluntaryPrevision.contractNumber}</span>
          </div>
          <div className="info">
            <span className="type">Forma de Pago:</span>
            <span className="line" />
            <span className="answer"> {voluntaryPrevision.translatedPaymentMethod}</span>
          </div>
        </>
      )}
      {previsionIndemnityEvent && (
        <>
          <div className="info">
            <span className="type">Número de periodo:</span>
            <span className="line" />
            <span className="answer"> {voluntaryPrevision.periodNumber}</span>
          </div>
          <div className="info">
            <span className="type">Periodo desde:</span>
            <span className="line" />
            <span className="answer"> {voluntaryPrevision.periodFrom}</span>
          </div>
          <div className="info">
            <span className="type">Periodo hasta:</span>
            <span className="line" />
            <span className="answer"> {voluntaryPrevision.periodTo}</span>
          </div>
          <div className="info">
            <span className="type">Tasa de indemnización:</span>
            <span className="line" />
            <span className="answer"> {voluntaryPrevision.parsedIndemnificationRate}</span>
          </div>
        </>
      )}
      {previsionVoluntaryAffiliate && (
        <>
          <div className="info">
            <span className="type">Monto Ahorro Voluntario:</span>
            <span className="line" />
            <span className="answer" />
            {voluntaryPrevision.parsedVoluntarySavingsAmount}
          </div>
        </>
      )}
    </>
  );
};

const pensionSchemeData = (pensionScheme, prevision, pensionAffiliationDate) => {
  const { pensionFund } = prevision;
  switch (pensionScheme) {
    case 'ips':
      return (
        <>
          <div className="info">
            <span className="type">IPS (ex INP):</span>
            <span className="line" />
            <span className="answer"> {prevision?.translatedIpsType}</span>
          </div>
          <div className="info">
            <span className="type">Tasa del régimen general:</span>
            <span className="line" />
            <span className="answer"> {prevision?.parsedPensionSchemeRate || '0'}</span>
          </div>
          <div className="info">
            <span className="type">Tasa Cotización Desahucio Ex-Cajas de Previsión:</span>
            <span className="line" />
            <span className="answer"> {prevision?.parsedEvictionContributionRate || '0'}</span>
          </div>
        </>
      );
    case 'afp':
      return (
        <>
          <div className="info">
            <span className="type">AFP:</span>
            <span className="line" />
            <span className="answer"> {pensionFund?.name}</span>
          </div>
          {pensionAffiliationDate && (
            <div className="info">
              <span className="type">Ingreso Afiliación:</span>
              <span className="line" />
              <span className="answer"> {pensionAffiliationDate}</span>
            </div>
          )}
        </>
      );
    default:
      return '';
  }
};

const EmployeePrevisionInfo = ({ employeePrevisionAttributes = {} }) => {
  const {
    fun,
    healthPrevision,
    ineCode,
    agreedQuantity,
    heavyLaborAdditional,
    heavyLaborStation,
    parsedAgreedQuantity,
    parsedSecondAgreedAmount,
    pensionScheme,
    pensionAffiliationDate,
    secondAgreedAmount,
    firstAgreedAmount,
    translatedIsOldAge,
    parsedFirstAgreedAmount,
    translatedHeavyLaborAdditional,
    translatedIneCode,
    translatedPensionScheme,
    translatedPensioner,
    translatedUnemploymentInsuranceReason,
    unemploymentInsurance,
    voluntaryPrevisionsAttributes,
    youthAllowance
  } = employeePrevisionAttributes;

  const hasHeavyLabor = heavyLaborAdditional === 'less_heavy_labor' || heavyLaborAdditional === 'with_heavy_labor';

  let voluntaryPrevisions;
  if (voluntaryPrevisionsAttributes) {
    voluntaryPrevisions = voluntaryPrevisionsAttributes.map((voluntaryPrevision, index) => {
      return (
        <VoluntaryPrevisionShow
          key={`voluntary_prevision_${index.toString()}`}
          voluntaryPrevision={voluntaryPrevision}
        />
      );
    });
  }

  return (
    <Accordion defaultActiveKey="0">
      <Card className="mt-3 ml-2 mr-2">
        <Col className="top-header-green br-5">
          <Accordion.Toggle as={Col} eventKey="0" className="card-header-title" style={{ textAlign: 'right' }}>
            PREVISIÓN
          </Accordion.Toggle>
        </Col>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="div-content">
            <Row>
              <Col md={6} xs={12}>
                <Card.Title>Previsión</Card.Title>
                <div className="info">
                  <span className="type">Régimen Previsional:</span>
                  <span className="line" />
                  <span className="answer"> {translatedPensionScheme}</span>
                </div>
                {pensionSchemeData(pensionScheme, employeePrevisionAttributes, pensionAffiliationDate)}
                <div className="info">
                  <span className="type">Isapre:</span>
                  <span className="line" />
                  <span className="answer"> {healthPrevision?.name}</span>
                </div>
                {fun && (
                  <div className="info">
                    <span className="type">FUN:</span>
                    <span className="line" />
                    <span className="answer"> {fun}</span>
                  </div>
                )}
                {agreedQuantity ? (
                  <div className="info">
                    <span className="type">Cantidad Acordada %:</span>
                    <span className="line" />
                    <span className="answer"> {parsedAgreedQuantity}</span>
                  </div>
                ) : null}
                {firstAgreedAmount ? (
                  <div className="info">
                    <span className="type">Cantidad Acordada Adicional UF:</span>
                    <span className="line" />
                    <span className="answer"> {parsedFirstAgreedAmount}</span>
                  </div>
                ) : null}
                {secondAgreedAmount ? (
                  <div className="info">
                    <span className="type">Cantidad Acordada Adicional $:</span>
                    <span className="line" />
                    <span className="answer"> {parsedSecondAgreedAmount}</span>
                  </div>
                ) : null}
                <div className="info">
                  <span className="type">Tipo de Trabajador:</span>
                  <span className="line" />
                  <span className="answer"> {translatedPensioner}</span>
                </div>
                {translatedIsOldAge ? (
                  <div className="info">
                    <span className="type">¿Pensionado por Vejez?:</span>
                    <span className="line" />
                    <span className="answer"> {translatedIsOldAge}</span>
                  </div>
                ) : null}
                <div className="info">
                  <span className="type">¿Adscribe al seguro de cesantía?:</span>
                  <span className="line" />
                  <span className="answer"> {yesNo(unemploymentInsurance)}</span>
                </div>
                {translatedUnemploymentInsuranceReason && (
                  <div className="info">
                    <span className="type">Razón</span>
                    <span className="line" />
                    <span className="answer"> {translatedUnemploymentInsuranceReason}</span>
                  </div>
                )}
                <div className="info">
                  <span className="type">Trabajo Pesado - Porcentaje de Cotización Adicional:</span>
                  <span className="line" />
                  <span className="answer"> {translatedHeavyLaborAdditional}</span>
                </div>
                {hasHeavyLabor && heavyLaborStation && (
                  <div className="info">
                    <span className="type">Puesto trabajo pesado:</span>
                    <span className="line" />
                    <span className="answer"> {heavyLaborStation}</span>
                  </div>
                )}
                {ineCode && (
                  <div className="info">
                    <span className="type">Código INE:</span>
                    <span className="line" />
                    <span className="answer"> {translatedIneCode}</span>
                  </div>
                )}
                <div className="info">
                  <span className="type">Se encuentra bajo subsidio al empleo joven:</span>
                  <span className="line" />
                  <span className="answer"> {yesNo(youthAllowance)}</span>
                </div>
              </Col>
              <Col>
                <Card.Title>Cotización Voluntaria</Card.Title>
                {voluntaryPrevisions}
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default EmployeePrevisionInfo;
