import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAbility } from '@casl/react';
import snakecaseKeys from 'snakecase-keys';
import { AbilityContext } from '../../config/abilityContext';
import { Icon, LinkBtn, SimpleCenteredModal } from '../../components';
import { showLoanProcessRequest, updateLoanProcessRequest } from '../../requests/loanProcesses';
import LoanProcessCard from './LoanProcessCard';
import LoanInstallmentsDataTable from './LoanInstallments/LoanInstallmentsDataTable';
import LoanForm from './Loan/LoanForm';
import basicLoan from './Loan/basicLoan';
import { createLoanRequest } from '../../requests/loans';
import { sendAlert } from '../../actions/utils';
import { indexBalancesRequest } from '../../requests/balances';

const LoanProcessShow = ({ match }) => {
  const { id } = match.params;
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const [reload, setReload] = useState(false);
  const [loanUpdate, setLoanUpdate] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const history = useHistory();
  const [loanProcess, setLoanProcess] = useState({});
  const [balanceOptions, setBalanceOptions] = useState([]);
  const successCallback = useCallback(response => setLoanProcess(response.data), []);
  const { advanceSettings } = useSelector(state => state.utils);
  const reqAmountLimit = advanceSettings?.filter(asItem => asItem.code === 'loan_amount_limit')[0]?.integerValue;
  const reqPercentageLimit = advanceSettings?.filter(asItem => asItem.code === 'loan_percentage_limit')[0]
    ?.integerValue;

  const handleSuccessAction = message => {
    setModalShow(false);
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const fetchLoanProcess = () => {
    showLoanProcessRequest(id, {
      dispatch,
      successCallback,
      callback: () => setOnRequest(false)
    });
  };

  const fetchLoanUpdate = () => {
    setOnRequest(true);
    updateLoanProcessRequest(id, {
      dispatch,
      successCallback: () => {
        setReload(val => !val);
        setMoreData(val => !val);
      },
      failureCallback: error => {
        history.push('/loan_processes');
        dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message || error?.message }));
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleCreateLoan = (loan, setSubmitting) => {
    createLoanRequest({
      dispatch,
      params: snakecaseKeys(loan),
      successCallback: () => {
        handleSuccessAction('Préstamo creado correctamente');
      },
      callback: () => {
        setSubmitting(false);
      }
    });
  };

  const handleLoanProcessSummary = () => {
    history.push(`/loan_processes/${id}/summary`);
  };

  const fetchBalances = () => {
    indexBalancesRequest({
      dispatch,
      params: { filter_loan_options: true },
      successCallback: ({ data }) => {
        setBalanceOptions(data.data);
      }
    });
  };

  useEffect(fetchLoanProcess, [id, reload]);
  useEffect(fetchLoanUpdate, [loanUpdate]);
  useEffect(fetchBalances, []);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad position-absolute h-100" style={{ bottom: '35px', left: '130px' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Row className="mt-4 mb-2 row">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to="/loan_processes">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2>{loanProcess.name}</h2>
        </Col>
        {ability.can('manage', 'LoanProcess.LoanInstallment') && (
          <Col md={2}>
            <Button variant="primary" block onClick={() => setModalShow(true)}>
              Nuevo
            </Button>
          </Col>
        )}
      </Row>
      <LoanProcessCard loanProcess={loanProcess} handleUpdate={fetchLoanUpdate} />
      <LoanInstallmentsDataTable
        moreData={moreData}
        setReload={setReload}
        setMoreData={setMoreData}
        loanProcess={loanProcess}
        loanProcessId={id}
        reqAmountLimit={reqAmountLimit}
        reqPercentageLimit={reqPercentageLimit}
        setLoanUpdate={setLoanUpdate}
        balanceOptions={balanceOptions}
      />
      <Row className="mt-4 mb-2 row">
        <Col />
        {ability.can('manage', 'LoanProcess.LoanInstallment') && (
          <Col md={2}>
            <Button variant="primary" block onClick={() => handleLoanProcessSummary()}>
              Procesar
            </Button>
          </Col>
        )}
      </Row>
      <SimpleCenteredModal
        title="Nuevo Proceso de PRÉSTAMO / CRÉDITO"
        body={
          <LoanForm
            rrhh
            dataEntry
            loan={basicLoan}
            newLoan
            showWarning
            balanceOptions={balanceOptions}
            loanProcess={loanProcess}
            formRequest={handleCreateLoan}
            reqAmountLimit={reqAmountLimit}
            reqPercentageLimit={reqPercentageLimit}
            btnMessage="Crear Préstamo"
            handleModalClose={() => setModalShow(false)}
          />
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default LoanProcessShow;
