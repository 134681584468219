import React, { useState } from 'react';
import { Button, Row, Modal, Col, Spinner, Form as BSForm } from 'react-bootstrap';
import * as Yup from 'yup';
import { Field, getIn, withFormik, Form } from 'formik';
import { delayMethod } from '../../../services/utils';
import { CheckBoxBtn } from '../../../components/Utils/Input';

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

const DigitalSignImageEdit = props => {
  const {
    employee,
    errors,
    touched,
    setFieldValue,
    handleModalClose,
    isSubmitting,
    CustomWebcam,
    UploadImage,
    loadingUpdate,
    values
  } = props;

  const [webcam, setWebcam] = useState(false);

  const handleWebCamButton = () => {
    setWebcam(!webcam);
  };

  return (
    <>
      <Form>
        <Modal.Body>
          <Row>
            {!webcam && (
              <>
                <Col md={10}>
                  <Field name="employee[digitalSign]">
                    {({ field }) => (
                      <UploadImage
                        {...field}
                        isDigitalSign
                        rectangleImage
                        name="Adjuntar firma digital del colaborador."
                        imageUrl={getIn(employee.digitalSignImage, 'fileUrl')}
                        onChange={_avatar => setFieldValue(field.name, _avatar)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        fileAccept=".png, .jpg, .jpeg"
                        helpText="Formato sugerido 150x50px de máximo 5mb."
                      />
                    )}
                  </Field>
                </Col>
              </>
            )}
            {webcam && (
              <>
                <Col md={5} className="d-flex flex-column align-items-center h-100 mb-3">
                  <Field name="employee[digitalSign]">
                    {({ field }) => (
                      <CustomWebcam
                        {...field}
                        onChange={avatar => setFieldValue(field.name, avatar)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </>
            )}
          </Row>
          <BSForm.Group className="button-group max-width-group">
            <Row>
              <Field name="selectedImage">
                {({ field }) => (
                  <CheckBoxBtn
                    {...field}
                    id="selectedImage"
                    label="Subir un Archivo"
                    checked={!webcam}
                    onClick={handleWebCamButton}
                  />
                )}
              </Field>
              <Field name="selectedImage">
                {({ field }) => (
                  <CheckBoxBtn
                    {...field}
                    onClick={handleWebCamButton}
                    id="selectedImage"
                    label="Tomar una Foto"
                    checked={webcam}
                  />
                )}
              </Field>
            </Row>
          </BSForm.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose, 100)}>
            Cerrar
          </Button>
          <Button
            className="text-center"
            variant="primary"
            type="submit"
            disabled={values?.employee?.digitalSign === null || isSubmitting}
          >
            Guardar
          </Button>

          {loadingUpdate && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </Modal.Footer>
      </Form>
    </>
  );
};

const initialValues = () => {
  return {
    employee: {
      employeeSignImage: '',
      digitalSign: null
    }
  };
};

const validationSchema = () => {
  return Yup.object().shape({
    employee: Yup.object().shape({
      digitalSign: Yup.mixed()
        .nullable()
        .test(
          'REQUIRED',
          'El campo de firma digital es obligatorio.',
          value => value !== null && value !== undefined && value !== ''
        )
        .test(
          'FILE_SIZE',
          'La imagen cargada excede el tamaño maximo permitido (5mb).',
          value => !value?.size || (value && value?.size <= 5242880)
        )
        .test(
          'FILE_FORMAT',
          'El archivo cargado tiene un formato no compatible.',
          value =>
            (!value && !value?.type) ||
            (value && ['image/jpg', 'image/png', 'image/jpeg', 'image/gif'].includes(value?.type))
        )
        .test(
          'REPLACE_LETTER',
          'El archivo no debe tener la  letra Ñ en su nombre',
          value => (!value && typeof (value?.name === 'undefined')) || (value && !value?.name.includes('ñ'))
        )
    })
  });
};

export default withFormik({
  mapPropsToValues: props => initialValues(props),
  validationSchema,
  handleSubmit
})(DigitalSignImageEdit);
