import React from 'react';
import memoize from 'memoize-one';

import { ButtonTooltip, Icon } from '../../../components';

export default memoize((clickHandler, isMobile, permission) => {
  if (isMobile) {
    return [
      {
        name: 'RUT',
        selector: 'national_identification',
        grow: '1',
        sortable: true
      },
      {
        name: 'NOMBRE',
        selector: 'employee_name',
        grow: '1',
        sortable: true
      },
      {
        name: 'CENTRO DE COSTO',
        selector: 'cost_center',
        grow: '1',
        sortable: true
      }
    ];
  }
  return [
    {
      name: 'RUT',
      selector: 'national_identification',
      grow: '1',
      sortable: true
    },
    {
      name: 'NOMBRE',
      selector: 'employee_name',
      grow: '1',
      sortable: true
    },
    {
      name: 'CENTRO DE COSTO',
      selector: 'cost_center',
      grow: '1',
      sortable: true
    },
    {
      name: 'CONCEPTO',
      selector: 'parsed_loan_type',
      grow: '1',
      sortable: true
    },
    {
      name: 'ORIGEN',
      selector: 'origin',
      cell: item => item.parsed_origin_type,
      grow: '1',
      sortable: true
    },
    {
      name: 'VALOR CUOTA',
      selector: 'amount',
      cell: item => item.parsed_amount,
      grow: '1',
      sortable: true
    },
    {
      name: 'ESTADO',
      selector: 'status',
      cell: item => {
        switch (item.status) {
          case 'to_discount':
            return (
              <span className="text-success" data-tag="allowRowEvents">
                <b>A DESCONTAR</b>
              </span>
            );
          case 'deferred':
            return (
              <span className="text-danger" data-tag="allowRowEvents">
                <b>POSTERGADA</b>
              </span>
            );
          default:
            return (
              <span className="text-muted" data-tag="allowRowEvents">
                <b>{item.status}</b>
              </span>
            );
        }
      },
      grow: '1',
      sortable: true
    },
    {
      name: 'ACCIONES',
      cell: item => (
        <>
          <ButtonTooltip
            onClick={() => clickHandler(item, 'send_document')}
            variant="circle-info"
            className="btn-circle"
            size="sm"
            text="Enviar"
            disabled={
              item.origin_type !== 'data_entry' || item.concept !== 'Préstamo' || item.sended_document || !permission
            }
          >
            <Icon icon="document" />
          </ButtonTooltip>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      right: true,
      grow: '1',
      minWidth: '123px'
    }
  ];
});
