import React from 'react';
import { useAbility } from '@casl/react';
import Icon from '../Icons';
import { AbilityContext } from '../../config/abilityContext';
import BasicDropdown from '../Utils/Dropdown/BasicDropdown';

const AdvancePaymentProcessMassiveActions = ({ handleClick, disabled, model, statusProcess }) => {
  const ability = useAbility(AbilityContext);
  const actions = () => {
    const authorizedActions = [];
    if (ability.can('index', model)) {
      authorizedActions.push({
        icon: <Icon icon="document" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'generate_document',
        props: {
          onClick: () => handleClick('generate_documents'),
          disabled
        },
        title: 'Generar Documentos'
      });
    }

    authorizedActions.push({
      icon: <Icon icon="download-2" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'download',
      props: {
        onClick: () => handleClick('export'),
        disabled: disabled || statusProcess
      },
      title: 'Descargar'
    });

    return authorizedActions;
  };

  return (
    <div className="mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
};

export default AdvancePaymentProcessMassiveActions;
