import React, { useState } from 'react';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import ThirdStep from './Steps/ThirdStep';

const AdvancePaymentStepSelection = ({ handleNextStep, handleBackStep, step, handleFinishStep, onlyShow = false }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
  };

  switch (step) {
    case 0: {
      return (
        <FirstStep
          handleNextStep={handleNextStep}
          step={step}
          handleSelectedRows={handleSelectedRows}
          selectedRows={selectedRows}
          onlyShow={onlyShow}
        />
      );
    }
    case 1: {
      return <SecondStep handleBackStep={handleBackStep} handleNextStep={handleNextStep} selectedRows={selectedRows} />;
    }
    case 2: {
      return <ThirdStep handleFinishStep={handleFinishStep} />;
    }
    default:
      return <FirstStep />;
  }
};

export default AdvancePaymentStepSelection;
