import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon } from '../../components';

const columns = memoize((clickHandler, ability) => [
  {
    name: 'RUT',
    selector: 'national_identification',
    sortable: true,
    grow: '1',
    wrap: true
  },
  {
    name: 'NOMBRE',
    selector: 'full_name',
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'CENTRO DE COSTO',
    selector: 'cost_center',
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'AREA',
    selector: 'job_management',
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'CONCEPTO',
    selector: 'balance_name',
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'ORIGEN',
    selector: 'source_type',
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'MONTO',
    selector: 'parsed_amount',
    sortable: true,
    grow: '1',
    wrap: true,
    center: true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    grow: '2',
    wrap: true
  },
  {
    name: 'ACCIONES',
    cell: item => {
      return (
        <>
          <ButtonTooltip
            variant="circle-primary"
            className="btn-circle"
            onClick={() => clickHandler(item, 'show')}
            text="Ver detalles"
          >
            <Icon icon="eye" />
          </ButtonTooltip>
          {ability.can('update', 'AdvancePaymentProcess') && (
            <ButtonTooltip
              variant="circle-warning"
              className="btn-circle"
              onClick={() => clickHandler(item, 'edit')}
              text="Editar"
              disabled={item.source_type === 'Solicitudes'}
            >
              <Icon icon="pencil" />
            </ButtonTooltip>
          )}
          {ability.can('destroy', 'AdvancePaymentProcess') && (
            <ButtonTooltip
              variant="circle-danger"
              className="btn-circle"
              onClick={() => clickHandler(item, 'destroy')}
              text="Eliminar"
            >
              <Icon icon="trash" />
            </ButtonTooltip>
          )}
        </>
      );
    },
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
