import React from 'react';
import { Row, Col } from 'react-bootstrap';

function SeeMore({ title = '', subtitle = '', TopHeaderColor = '', onClick = () => { }, onRequest = false }) {
  const styling = {
    stroke: TopHeaderColor,
    strokeDasharray: `${101} 100`,
    animationName: 'Fill',
    animationDuration: '2s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'forwards'
  };
  return (
    <>
      {!onRequest && (
        <div style={{ height: '145px' }}>
          <Row style={{ alignItems: 'center', height: 90, paddingRight: 20, paddingLeft: 20 }}>
            <Col xs={4} md={4} xl={4}>
              <div className="Percentage">
                <svg width="110" height="110" style={{ paddingRight: 25 }}>
                  <circle r="30%" cx="50%" cy="50%" pathLength="100" style={{ stroke: '#adadad' }} />
                  <circle r="30%" cx="50%" cy="50%" pathLength="100" style={styling} />
                </svg>
                <span style={{ color: TopHeaderColor }}>{title}</span>
              </div>
            </Col>
            <Col xs={8} md={8} xl={8}>
              <p style={{ fontSize: '18px', fontWeight: 'bold', color: 'black' }}>{subtitle}</p>
            </Col>
          </Row>

          <Row>
            <Col xs={8} md={8} xl={8} />
            <Col xs={4} md={4} xl={4}>
              <div className="ViewMoreButton">
                <button type="button" onClick={onClick} style={{ backgroundColor: TopHeaderColor }}>
                  <p style={{ fontSize: '12px', fontWeight: 'bold', paddingTop: 4 }}>Ver más</p>
                </button>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default SeeMore;
