import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { Tab, Nav } from 'react-bootstrap';
import { AbilityContext } from '../../../config/abilityContext';
import ContractInfo from './ContractInfo';
import EmployeeInfo from './EmployeeInfo';
import DirectoryInfo from './DirectoryInfo';
import WorkflowInfo from './WorkflowInfo';
import ItemsIndex from '../../../screens/Employee/Items/ItemsIndex';
import { useSetTab, useScrollPosition, useAuthorization } from '../../../services/hooks';
import useDocumentsAbility from '../../../screens/Shared/DirectoryNode/documentAbility';
import Icon from '../../Icons';
import EmployeeAssistanceTabs from './EmployeeAssistanceTabs';
import EmployeePrevisionInfo from './EmployeePrevisionInfo';
import EmployeeVacationTabs from './EmployeeVacationTabs';
import { indexCustomFieldsRequest } from '../../../requests/customFields';

const EmployeeTabs = ({ employee, getEmployee, currentEmployee }) => {
  const { advanceSettings } = useSelector(state => state.utils);
  const location = useLocation();
  const [key, setKey] = useSetTab('profile', location);
  const [scrollPos] = useScrollPosition('y');
  const loanTab = advanceSettings.filter(asItem => asItem.code === 'loan_request').some(x => x.textValue === 'true');
  const ability = useAbility(AbilityContext);
  const permissions = [
    ability.can('read', 'Attendance'),
    ability.can('index', 'Absence'),
    ability.can('index', 'Vacation'),
    ability.can('index', 'Permission')
  ];
  const canReadPrevision = useAuthorization('read', 'EmployeePrevision');
  const documentReadPermission = useDocumentsAbility();
  const signaturePermission = ability.can('index_document', 'WorkflowRequest') || employee.id === currentEmployee;
  const vacationPermission = ability.can('index', 'Vacation');
  const assistancePermission = permissions.some(item => item) || employee.id === currentEmployee;
  const itemsPermission = (ability.can('index', 'Loan') || employee.id === currentEmployee) && loanTab;
  const [contractCustomFields, setContractCustomFields] = useState([]);
  const [employeeCustomFields, setEmployeeCustomFields] = useState([]);
  const dispatch = useDispatch();
  const { currentCompany } = useSelector(state => state.auth);

  useEffect(() => {
    const handleRequestAndSuccessCustomFields = async params => {
      const filterCondition = field => field.company_id === currentCompany.id && field.active;

      indexCustomFieldsRequest({
        dispatch,
        params,
        successCallback: response => {
          const { data } = response.data;
          const contractFields = data.filter(
            field => filterCondition(field) && field.field_display === 'contract_display'
          );
          const employeeFields = data.filter(
            field => filterCondition(field) && field.field_display === 'card_employee_display'
          );

          setContractCustomFields(contractFields);
          setEmployeeCustomFields(employeeFields);
        }
      });
    };
    handleRequestAndSuccessCustomFields();
  }, [currentCompany.id, dispatch]);

  return (
    <Tab.Container id="employee-form-tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
      <Nav variant="pills" fill className={`employee ${scrollPos >= 11 && 'shadow-bottom'}`}>
        <Nav.Item>
          <Nav.Link eventKey="profile">
            <Icon icon="person" />
            Ficha
          </Nav.Link>
        </Nav.Item>
        {ability.can('read', 'Contract') && (
          <Nav.Item>
            <Nav.Link eventKey="contract" disabled={!employee.contract.id}>
              <Icon icon="document" />
              Contractual
            </Nav.Link>
          </Nav.Item>
        )}
        {ability.can('read', 'Contract') && (
          <Nav.Item>
            <Nav.Link eventKey="prevision" disabled={!employee.contract.id}>
              <Icon icon="document" />
              Previsión
            </Nav.Link>
          </Nav.Item>
        )}
        {assistancePermission && (
          <Nav.Item>
            <Nav.Link eventKey="assistance" disabled={!employee.id}>
              <Icon icon="finger-print" />
              Asistencias
            </Nav.Link>
          </Nav.Item>
        )}
        {ability.can('index_view_rrhh', 'Vacation') && (
          <Nav.Item>
            <Nav.Link eventKey="vacations" disabled={!employee.id}>
              <Icon icon="sunny" />
              Vacaciones
            </Nav.Link>
          </Nav.Item>
        )}
        {documentReadPermission && (
          <Nav.Item>
            <Nav.Link eventKey="documents" disabled={!employee.id}>
              <Icon icon="folder-open" />
              Documentos
            </Nav.Link>
          </Nav.Item>
        )}
        {itemsPermission && (
          <Nav.Item>
            <Nav.Link eventKey="items" disabled={!employee.id}>
              <Icon icon="stack" />
              Préstamos
            </Nav.Link>
          </Nav.Item>
        )}
      </Nav>
      <Tab.Content className="main-div mt-3">
        <Tab.Pane eventKey="profile">
          <EmployeeInfo employee={employee} employeeCustomFields={employeeCustomFields} />
        </Tab.Pane>
        {localStorage.getItem('currentCompany') && (
          <>
            {employee.contract.id && (
              <>
                <Tab.Pane eventKey="contract">
                  <ContractInfo
                    contract={employee.contract}
                    employee={employee}
                    employeeId={employee.id}
                    getEmployee={getEmployee}
                    contractCustomFields={contractCustomFields}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="prevision">
                  {canReadPrevision && (
                    <EmployeePrevisionInfo
                      employeePrevisionAttributes={employee.contract.employeePrevisionAttributes}
                    />
                  )}
                </Tab.Pane>
              </>
            )}
            {signaturePermission && (
              <Tab.Pane eventKey="signature">
                <WorkflowInfo employee={employee} />
              </Tab.Pane>
            )}
            {assistancePermission && (
              <Tab.Pane eventKey="assistance">
                <EmployeeAssistanceTabs permissions={permissions} employee={employee} />
              </Tab.Pane>
            )}
            {vacationPermission && (
              <Tab.Pane eventKey="vacations">
                <EmployeeVacationTabs permissions={permissions} employee={employee} />
              </Tab.Pane>
            )}
            {itemsPermission && (
              <Tab.Pane eventKey="items">
                <ItemsIndex employeeId={employee.id} />
              </Tab.Pane>
            )}
            {documentReadPermission && (
              <Tab.Pane eventKey="documents" unmountOnExit>
                <DirectoryInfo employeeId={employee.id} />
              </Tab.Pane>
            )}
          </>
        )}
      </Tab.Content>
    </Tab.Container>
  );
};

export default EmployeeTabs;
