import React, { useEffect, useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { BasicTextArea, FormikDatePicker, FormikSelect, InputSelect } from '../../../components';
import {
  indexSelectByLoadEmployeesRequest,
  indexSelectEmployeesRequest,
  shiftEmployeeRequest,
  showEmployeeRequest
} from '../../../requests/employees';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';

const ShiftChangeForm = ({
  errors,
  isSubmitting,
  setFieldTouched,
  shiftChange,
  setFieldValue,
  touched,
  values,
  employeeId,
  ...props
}) => {
  const { shiftChangeRequest } = values;
  const { employeeReplacementId } = shiftChangeRequest;
  const id = shiftChangeRequest.employeeId;
  const [shifts, setShifts] = useState([]);
  const [shiftIds, setShiftIds] = useState([]);
  const [employeeReplacements, setEmployeeReplacements] = useState([]);
  const [shiftReplacements, setShiftReplacements] = useState([]);
  const [employeeReplacement, setEmployeeReplacement] = useState({});
  const [contract, setContract] = useState({});
  const dispatch = useDispatch();

  const localEmployeeId = employeeId || id;
  const setValue = () => {
    setFieldValue(`shiftChangeRequest[shiftId]`, '');
    setFieldValue(`shiftChangeRequest[employeeReplacementId]`, '');
    setFieldValue(`shiftChangeRequest[shiftReplacementId]`, '');
    setEmployeeReplacements([]);
    setShiftReplacements([]);
  };

  const { action, cancelAction, onHide, onlyShow, rrhh } = props;

  const message = action === 'items' ? 'Asignar' : 'Confirmar';
  const btnMessage = action === 'new' ? 'Solicitar' : message;
  const isDisabled = action === 'edit';

  const fetchShiftReplacement = () => {
    if (employeeReplacementId !== '') {
      shiftEmployeeRequest(employeeReplacementId, {
        dispatch,
        params: {
          shift_id: shiftIds,
          paginate: false
        },
        successCallback: response => {
          setShiftReplacements(response.data.data);
        }
      });
    }
  };

  const fetchReplacementEmployees = () => {
    indexSelectByLoadEmployeesRequest({
      dispatch,
      params: {
        parent_id: contract.parentId ? contract.parentId : '',
        job_title_id: contract.jobTitleId ? contract.jobTitleId : '',
        employee_id: employeeReplacement.id,
        paginate: false
      },
      successCallback: response => {
        setEmployeeReplacements(response.data.data);
      }
    });
  };

  const fetchEmployee = (inputValue, callback) => {
    indexSelectEmployeesRequest({
      dispatch,
      params: { active_user: true, filter_name: inputValue, paginate: false },
      successCallback: response => callback(response.data.data)
    });
  };

  const fetchShift = () => {
    if (typeof localEmployeeId !== 'undefined') {
      shiftEmployeeRequest(localEmployeeId, {
        dispatch,
        params: { paginate: false },
        successCallback: response => {
          const { data } = response.data;
          setShifts(data);
          setShiftIds(data.map(e => e.value));
        }
      });
    }
  };

  const fetchEmployeeDetail = () => {
    if (typeof localEmployeeId !== 'undefined') {
      showEmployeeRequest(localEmployeeId, {
        dispatch,
        params: { active: true },
        successCallback: response => {
          const { data } = response;
          setEmployeeReplacement(data);
          setContract(camelCaseEmptyStringRecursive(data.contract));
          fetchShift();
        }
      });
    }
  };

  useEffect(fetchEmployeeDetail, [localEmployeeId]);
  useEffect(fetchShift, [localEmployeeId]);
  useEffect(fetchShiftReplacement, [employeeReplacementId, shiftIds]);
  useEffect(fetchReplacementEmployees, [contract, employeeReplacement]);

  return (
    <Form className="mx-3">
      {isSubmitting && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      {(action === 'new' || action === 'items') && (
        <>
          <Row>
            {rrhh && (
              <Col md={12}>
                <Field name="shiftChangeRequest[employeeId]">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      abbr
                      label="Trabajador"
                      placeholder="Seleccionar Trabajador"
                      values={values}
                      model={[shiftChange, 'employee']}
                      request={fetchEmployee}
                      onChange={data => {
                        setFieldValue(field.name, data ? data.value : '');
                        setValue();
                      }}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            )}
            <Col md={6}>
              <Field name="shiftChangeRequest[shiftId]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Horario"
                    placeholder="Seleccionar Horario"
                    options={shifts}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isDisabled={onlyShow}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="shiftChangeRequest[replacementDate]">
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    isOutsideRange={day => moment().diff(day) > 0}
                    label="Fecha de reemplazo"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    onChange={date => setFieldValue(field.name, date)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field name="shiftChangeRequest[employeeReplacementId]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Reemplazar con"
                    placeholder="Seleccionar Trabajador"
                    options={employeeReplacements}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isDisabled={onlyShow}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="shiftChangeRequest[shiftReplacementId]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Horario"
                    placeholder="Seleccionar Horario"
                    options={shiftReplacements}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isDisabled={onlyShow}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            {action === 'new' && (
              <>
                <Col md={12}>
                  <Field name="shiftChangeRequest[reason]">
                    {({ field }) => (
                      <BasicTextArea
                        {...field}
                        label="Motivo"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={onlyShow}
                      />
                    )}
                  </Field>
                </Col>
              </>
            )}
          </Row>
        </>
      )}

      <Row className="d-flex justify-content-end mt-3 mb-2">
        {action === 'edit' && (
          <Col xs={6} sm={4}>
            <Button block variant="outline-info" onClick={cancelAction} disabled={isSubmitting}>
              Cancelar
            </Button>
          </Col>
        )}
        {!onlyShow && (
          <Col xs={6} sm={4}>
            <Button block type="submit" variant="primary" onClick={onHide} disabled={isSubmitting || isDisabled}>
              {btnMessage}
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { shiftChange, action, employeeId, rrhh = false } = props;
  const {
    id,
    replacementDate,
    shiftId,
    reason,
    observation,
    employeeReplacementId,
    shiftReplacementId,
    directApprove
  } = shiftChange;
  let newObservation = observation;
  if (action === 'items') {
    newObservation = 'Solicitado desde RRHH';
  }
  return {
    shiftChangeRequest: {
      id,
      replacementDate,
      shiftId,
      employeeId,
      reason,
      observation: newObservation,
      employeeReplacementId,
      shiftReplacementId,
      directApprove,
      rrhh
    }
  };
};

const today = new Date();
const lastMonth = new Date(today.getFullYear(), today.getMonth(), -1);

const validationSchema = Yup.object().shape({
  shiftChangeRequest: Yup.object().shape({
    replacementDate: Yup.date()
      .required('Debes seleccionar una fecha de reemplazo')
      .formatdate()
      .min(lastMonth, 'Debes seleccionar una fecha mayor o igual a la fecha actual.'),
    employeeReplacementId: Yup.string().required('Debes seleccionar un trabajador'),
    shiftId: Yup.string().required('Debes seleccionar un horario'),
    shiftReplacementId: Yup.string().required('Debes seleccionar un horario'),
    reason: Yup.string().required('Debe indicar el motivo del cambio de turno')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(ShiftChangeForm);
