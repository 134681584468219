import ApiService from '../services/apiService';

export const assistanceDashboardRequest = ({ dispatch, failureCallback, successCallback }) =>
  ApiService.request('get', '/dashboards/assistance', {
    dispatch,
    failureCallback,
    successCallback
  });

export const pendingAssistanceDashboardRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/dashboards/pending_assistance', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const overtimesDashboardRequest = ({ dispatch, failureCallback, successCallback }) =>
  ApiService.request('get', '/dashboards/overtimes', {
    dispatch,
    failureCallback,
    successCallback
  });

export const pendingAssistanceBadgeRequest = ({ dispatch, failureCallback, successCallback }) =>
  ApiService.request('get', '/dashboards/pending_assistance_badge', {
    dispatch,
    failureCallback,
    successCallback
  });

export const profileAssistanceRequest = ({ dispatch, failureCallback, successCallback }) =>
  ApiService.request('get', '/dashboards/profile_assistance', {
    dispatch,
    failureCallback,
    successCallback
  });

export const profilePermissions = ({ dispatch, failureCallback, successCallback }) =>
  ApiService.request('get', '/dashboards/profile_permissions', {
    dispatch,
    failureCallback,
    successCallback
  });

export const profileRegisterRequest = ({ dispatch, failureCallback, successCallback }) =>
  ApiService.request('get', '/dashboards/profile_register', {
    dispatch,
    failureCallback,
    successCallback
  });

export const signaturesRequest = ({ dispatch, callback, successCallback }) =>
  ApiService.request('get', '/dashboards/signatures', {
    dispatch,
    callback,
    successCallback
  });

export const humanResourcesRequest = ({ dispatch, successCallback }) =>
  ApiService.request('get', '/dashboards/human_resources', { dispatch, successCallback });

export const HRVacationsRequest = ({ dispatch, successCallback }) =>
  ApiService.request('get', '/dashboards/hr_vacations', { dispatch, successCallback });

export const HRPermissionsRequest = ({ dispatch, successCallback }) =>
  ApiService.request('get', '/dashboards/hr_permissions', { dispatch, successCallback });

export const HRShiftChangeRequest = ({ dispatch, successCallback }) =>
  ApiService.request('get', '/dashboards/hr_shift_change', { dispatch, successCallback });

export const HRLoansRequest = ({ dispatch, successCallback }) =>
  ApiService.request('get', '/dashboards/hr_loans', { dispatch, successCallback });

export const HRAdvancePaymentsRequest = ({ params = {}, dispatch, successCallback }) =>
  ApiService.request('get', '/dashboards/hr_advance_payments', { params, dispatch, successCallback });

export const profileDashboardRequest = ({ dispatch, successCallback }) => {
  ApiService.request('get', '/dashboards/profile_dashboard', { dispatch, successCallback });
};

export const profileTeamDashboardRequest = ({ dispatch, successCallback }) => {
  ApiService.request('get', '/dashboards/profile_team_dashboard', { dispatch, successCallback });
};

export const coursesDashboardRequest = ({ dispatch, successCallback }) => {
  ApiService.request('get', '/dashboards/courses_dashboard', { dispatch, successCallback });
};

export const absencesTeamDashboardRequest = ({ dispatch, params = {}, successCallback }) => {
  ApiService.request('get', '/dashboards/absences_team_dashboard', {
    dispatch,
    params,
    successCallback
  });
};
