import React from 'react';
import memoize from 'memoize-one';
import { BasicDropdown, Icon } from '../../../../components';

const massiveActions = memoize((handleClick, permissions, disabled, onlyShow) => {
  const actions = () => {
    const authorizedActions = [];

    authorizedActions.push({
      icon: <Icon icon="download-2" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'massive_export',
      props: {
        onClick: () => handleClick('massive_export')
      },
      title: 'Descargar'
    });

    authorizedActions.push({
      icon: <Icon icon="close-circle" height="16px" width="16px" style={{ marginRight: 5 }} />,
      key: 'massive_deactivate',
      props: {
        onClick: () => handleClick('massive_deactivate'),
        disabled
      },
      title: 'Eliminar'
    });

    return authorizedActions;
  };

  return (
    !onlyShow && (
      <div className="mb-4">
        <BasicDropdown
          block
          items={actions()}
          noArrow
          right
          titleDrop={
            <span className="align-middle">
              Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
            </span>
          }
          variant="outline-primary"
        />
      </div>
    )
  );
});

export default massiveActions;
