import React, { useState } from 'react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { DatatableEmployeeName, DefaultModal, Reminder } from '../../../components';
import ApproveRejectStyledActions from '../../../components/DatatableActions/ApproveRejectStyledActions';
import LoanForm from './LoanForm';
import { approveLoanRequest, deleteLoanRequest, rejectLoanRequest } from '../../../requests/loans';
import { sendAlert } from '../../../actions/utils';
import LoanRejectForm from './LoanRejectForm';
import ApprovementStatusStyled from '../../../components/DatatableColumns/ApprovementStatusStyled';

const LoanInfoTable = props => {
  const { currentEmployee, item, moreData, setModalShow, setMoreData } = props;

  const {
    createdAt,
    grantDate,
    parsedAmount,
    pendingFees,
    reason,
    startDate,
    status,
    translatedStatus
  } = camelCaseRecursive(item);
  const { advanceSettings } = useSelector(state => state.utils);
  const dispatch = useDispatch();
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [defaultModalButtons, setDefaultModalButtons] = useState(true);
  const [defaultModalTitle, setDefaultModalTitle] = useState('');
  const [defaultModalAction, setDefaultModalAction] = useState(() => null);
  const [modalItem, setDefaultModalItem] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const [isDisabled, setIsDisable] = useState(false);
  const [reminderShow, setReminderShow] = useState(false);
  const [reminderItem, setReminderItem] = useState({});

  const handleModalClose = () => {
    setModalShow(false);
    setDefaultModalShow(false);
    setDefaultModalTitle('');
    setDefaultModalBody('');
    setOnRequest(false);
  };

  const handleSuccessRequest = () => {
    switch (status) {
      case 'approved':
        dispatch(sendAlert({ kind: 'success', message: 'Préstamo aceptado correctamente' }));
        break;
      case 'rejected':
        dispatch(sendAlert({ kind: 'success', message: 'Préstamo rechazado correctamente' }));
        break;
      default:
        break;
    }
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleApproveRequest = (loan, setSubmitting) => {
    const { id: loanId } = loan.loan;
    const myParams = snakeCaseKeys(loan);

    approveLoanRequest(loanId, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => handleSuccessRequest('approved'),
      failureCallback: error => {
        dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
        setMoreData(!moreData);
        setDefaultModalShow(false);
      },
      callback: () => setSubmitting(false)
    });
  };

  const handleApproveModal = loan => {
    const reviewerSignature = loan.requestReviewerSignature;
    const reqAmountLimit = advanceSettings?.filter(asItem => asItem.code === 'loan_amount_limit')[0]?.integerValue;

    setDefaultModalTitle(reviewerSignature ? 'Firmar Documento' : 'Aprobar Solicitud');
    setDefaultModalBody(
      <LoanForm
        newLoan={false}
        formRequest={handleApproveRequest}
        handleModalClose={() => setDefaultModalShow(false)}
        loan={loan}
        reqAmountLimit={reqAmountLimit}
        reviewerSignature={reviewerSignature}
        employee={currentEmployee}
      />
    );
    setDefaultModalButtons(false);
    setDefaultModalShow(true);
  };

  const handleRejectRequest = loan => {
    const { id: loanId } = loan.loan;
    const myParams = snakeCaseKeys(loan);

    rejectLoanRequest(loanId, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => handleSuccessRequest('rejected')
    });
  };

  const handleRejectModal = loan => {
    setDefaultModalBody(
      <LoanRejectForm
        formRequest={handleRejectRequest}
        handleModalClose={() => setDefaultModalShow(false)}
        loan={loan}
      />
    );
    setDefaultModalTitle('Rechazar solicitud');
    setDefaultModalButtons(false);
    setDefaultModalShow(true);
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud eliminada con éxito' }));
    setMoreData(!moreData);
  };

  const handleDeleteRequest = () => {
    setOnRequest(true);
    deleteLoanRequest(item?.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => {
        setDefaultModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleConfirmModal = () => {
    setIsDisable(false);
    setOnRequest(false);
    setDefaultModalShow(true);
    setDefaultModalItem(item);
    setDefaultModalBody('¿Estás seguro que deseas eliminar esta solicitud?');
    setDefaultModalTitle('Eliminar solicitud de préstamo');
  };

  const handleActions = action => {
    switch (action) {
      case 'approved':
        handleApproveModal(camelCaseRecursive(item));
        break;
      case 'rejected':
        handleRejectModal(camelCaseRecursive(item));
        break;
      case 'resend':
        setReminderShow(true);
        setReminderItem(snakeCaseKeys(item));
        break;
      case 'destroy':
        handleConfirmModal(item);
        setDefaultModalAction(() => handleDeleteRequest);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  return (
    <>
      <Modal.Body>
        <div className="info-box">
          <Row>
            <Col md={2} lg={2}>
              <ApprovementStatusStyled margin="mt-1" status={status} />
            </Col>
            <Col md={8} lg={8} className="title-pw">
              <b>Gestionar Solicitud de Préstamo</b>
            </Col>
            <Col md={2} lg={2} className="title-pw" />
          </Row>
          <table className="simple-table">
            <tbody>
              <tr>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Solicita</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Fecha de Solicitud</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Monto</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Fecha Otorgamiento</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Fecha Primer Descuento</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Estado Solicitud</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Motivo del Préstamo</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Cuotas Pendientes</th>
              </tr>
              <tr>
                <td style={{ width: '150px' }}>
                  <div bis_skin_checked="1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div aria-hidden="true" bis_skin_checked="1" className="employee-info" />
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <DatatableEmployeeName
                        item={item.employee}
                        fileName="fileInfo"
                        name="shortName"
                        fileUrl="fileUrl"
                      />
                    </span>
                  </div>
                </td>
                <td align="center">{createdAt}</td>
                <td align="center">{parsedAmount}</td>
                <td align="center">{grantDate}</td>
                <td align="center">{startDate}</td>
                <td align="center">{translatedStatus}</td>
                <td align="center">{reason || '--'}</td>
                <td align="center">{pendingFees || 0}</td>
              </tr>
            </tbody>
          </table>
          <br />

          <Row>
            <Col className="text-center">
              <ApproveRejectStyledActions
                resource="Loan"
                item={item}
                clickHandler={handleActions}
                approveText={item.requestReviewerSignature ? 'Firmar / Aprobar' : 'Aprobar'}
                withDestroy
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <DefaultModal
        body={defaultModalBody}
        disabled={onRequest || isDisabled}
        handleClose={handleModalClose}
        handleConfirm={() => defaultModalAction(modalItem)}
        show={defaultModalShow}
        title={defaultModalTitle}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose={defaultModalButtons}
        withConfirm={defaultModalButtons}
      />
      <Reminder
        item={reminderItem}
        show={reminderShow}
        modalShow={setReminderShow}
        moreData={moreData}
        setOnRequest={setOnRequest}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default LoanInfoTable;
