import React, { useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { FormikTimePicker, BasicTextArea, FormikSelect, FormikInput, InputSelect } from '../../../../components';
import { delayMethod } from '../../../../services/utils';
import { markingTypes } from '../formOptions';
import { debounceIndexShiftsRequest } from '../../../../requests/shifts';

const MassivemassiveFormReplaceForm = props => {
  const {
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    timeType,
    attendanceDate,
    handleModalClose,
    onHide,
    isSubmitting,
    branchOffices
  } = props;
  const dispatch = useDispatch();
  const typeFilter = markingTypes.find(type => type.value === timeType);
  const selectedMarkingType = [typeFilter];
  const [branchOfficeId, setBranchOfficeId] = useState(null);
  const [shiftIdId, setShiftId] = useState(null);

  const setCommonValues = (field, data, setValueFunc) => {
    setValueFunc(data.value);
    setFieldValue(field.name, data ? data.value : '');
  };

  const fetchContractShift = (inputValue, callback) => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: response => {
        const { data: elements } = response.data;
        callback(
          elements.map(element => ({
            ...element,
            label: element.name,
            value: element.id
          }))
        );
      }
    });
  };

  return (
    <>
      {isSubmitting && <div className="loader">Loading...</div>}
      <Form>
        <Modal.Body>
          <Row>
            <Col md={12} className="d-grid">
              <span>
                <b>Nueva Hora de marcacion</b>
              </span>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Field name="massiveForm[date]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Fecha"
                    value={attendanceDate}
                    touched={getIn(touched, field.name)}
                    readOnly
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field name="massiveForm[substitutionTime]">
                {({ field }) => (
                  <FormikTimePicker
                    {...field}
                    abbr
                    label="Nueva Hora"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    timeFormat="h:mm:ss aa"
                    placeholder=""
                    onChange={date => {
                      setFieldValue(
                        field.name,
                        date.toLocaleTimeString('en-ES', {
                          hour: 'numeric',
                          minute: '2-digit',
                          second: '2-digit'
                        })
                      );
                    }}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field name="massiveForm[timeType]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    label="Tipo de Marcación"
                    placeholder={typeFilter.label}
                    defaultValue={typeFilter.value}
                    options={selectedMarkingType}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isDisabled
                  />
                )}
              </Field>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Field name="massiveForm[branchOfficeId]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Lugar de Prestación de Servicios"
                    placeholder="Seleccionar Lugar de Prestación de Servicios"
                    options={branchOffices}
                    onChange={data => {
                      setCommonValues(field, data, setBranchOfficeId);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="massiveForm[shiftId]">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    label="Turno"
                    placeholder="Seleccionar Turno"
                    value={undefined}
                    request={fetchContractShift}
                    onChange={data => {
                      setCommonValues(field, data, setShiftId);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Field name="massiveForm[comment]">
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    label="Observaciones"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={onHide}
            disabled={isSubmitting || !branchOfficeId || !shiftIdId}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const { attendances, timeType } = props;
  return {
    massiveForm: {
      substitutionTime: '',
      comment: '',
      timeType,
      attendances
    }
  };
};

const validationSchema = Yup.object().shape({
  massiveForm: Yup.object().shape({
    substitutionTime: Yup.string().required('La hora es requerida'),
    comment: Yup.string()
      .max(200, 'Deben ser como máximo 200 caracteres')
      .nullable(true)
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;

  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(MassivemassiveFormReplaceForm);
