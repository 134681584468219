import React from 'react';
import './styles.scss';

const OffdayReportToPrint = React.forwardRef(({ data }, ref) => {
  const selectedStyle = {
    backgroundColor: '#FF0000',
    color: '#FFFFFF'
  };

  const selectedEndStyle = {
    backgroundColor: '#c7c7c7',
    color: '#000000'
  };

  return (
    <div className="print_report" ref={ref}>
      {data.length > 0 && (
        <>
          <p>Reporte de días domingo y/o días festivos</p>
          <p>Empresa: {data[0].company_name}</p>
          <p>RUT: {data[0].company_rut}</p>
          <p>
            Rango seleccionado de fechas: {data[0].dates[0]} - {data[0].dates[1]}
          </p>

          {data.map(employee => (
            <>
              <p style={{ marginTop: '12px' }}>{employee.employee_name}</p>
              <p>RUT: {employee.employee_rut}</p>
              <table className="table-print table-sm table-bordered">
                <tbody>
                  <tr style={{ backgroundColor: '#EBEBEB' }}>
                    <th className="text-center align-middle">
                      T.C.B.D.D.A.
                      <br /> Descanso Dominical Adicional
                    </th>
                    <th className="text-center align-middle">Fecha</th>
                    <th className="text-center align-middle">Asistencia</th>
                    <th className="text-center align-middle">Ausencia</th>
                    <th className="text-center align-middle">Observaciones</th>
                  </tr>
                  {employee.records
                    .filter(record => record.default_no !== 'Total Final: ')
                    .map(record => (
                      <>
                        <tr style={record.justification === 'Marcación fuera de turno' ? selectedStyle : null}>
                          <td className="text-center align-middle">{record.default_no}</td>
                          <td className="text-center align-middle">{record.date}</td>
                          <td className="text-center align-middle">{record.attendance}</td>
                          <td className="text-center align-middle">{record.absence}</td>
                          <td className="text-center align-middle">{record.justification}</td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>

              {employee.records.length > 0 && (
                <table className="table-print table-sm table-bordered">
                  <tbody>
                    <tr style={{ backgroundColor: '#EBEBEB' }}>
                      <th className="text-center align-middle"> </th>
                      <th className="text-center align-middle">Días Asistidos:</th>
                      <th className="text-center align-middle">Días Ausente:</th>
                    </tr>
                    {employee.records
                      .filter(record => record.default_no === 'Total Final: ')
                      .map(record => (
                        <>
                          <tr style={selectedEndStyle}>
                            <td className="text-center align-middle">{record.default_no}</td>
                            <td className="text-center align-middle">{record.attendance}</td>
                            <td className="text-center align-middle">{record.absence}</td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              )}
              {employee.records.length === 0 && (
                <>
                  <p>La jornada de este trabajador no incluye domingos y festivos</p>
                </>
              )}
            </>
          ))}
        </>
      )}
    </div>
  );
});

export default OffdayReportToPrint;
