import React from 'react';

import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import { FormikCheckBox, FormikTimePicker, FormikInput, BasicTextArea } from '../../../components';
import { delayMethod } from '../../../services/utils';

const AttendanceFormReplace = props => {
  const { errors, touched, setFieldValue, setFieldTouched, values, handleModalClose, onHide, isSubmitting } = props;
  const { attendance } = values;
  const { correctionType, parsedTimeType, agreedTime, timeType, shift } = attendance;
  const { collationTime, lunchCheck } = shift;

  const isAgreedDialingValid =
    ([2, 3].includes(timeType) && lunchCheck === true && collationTime === false) || [0, 1].includes(timeType);

  return (
    <Form>
      <Modal.Body>
        <Row>
          {(correctionType === 'replacing' || attendance.name) && (
            <Col md={12} className="d-grid">
              <span>
                <b>Trabajador:</b> {attendance.name}
              </span>
              <span>
                <b>Rut:</b> {attendance.rut}
              </span>
              <span>
                <b>Fecha:</b> {attendance.parseReplaceDate}
              </span>
            </Col>
          )}
        </Row>

        <Row>
          <Col md={3}>
            <h4>{parsedTimeType}: Jornada Pactada</h4>
          </Col>
          <Col md={3}>
            <Field name="attendance[agreedTime]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Marcación Pactada"
                  value={isAgreedDialingValid ? agreedTime : ''}
                  touched={getIn(touched, field.name)}
                  readOnly
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="attendance[clockInOutTime]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Marcación Original"
                  value={attendance.clockInOutTime}
                  touched={getIn(touched, field.name)}
                  readOnly
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="attendance[substitutionTime]">
              {({ field }) => (
                <FormikTimePicker
                  {...field}
                  abbr
                  label="Nuevo Registro"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeFormat="h:mm:ss aa"
                  placeholder=""
                  onChange={date => {
                    setFieldValue(
                      field.name,
                      date.toLocaleTimeString('en-ES', {
                        hour: 'numeric',
                        minute: '2-digit',
                        second: '2-digit'
                      })
                    );
                  }}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Field name="defaultsubstitutionTime">
              {({ field }) => (
                <>
                  <FormikCheckBox
                    {...field}
                    value={field.value === 'true' || field.value === true}
                    checked={field.value === 'true' || field.value === true}
                    field={field}
                    label="Usar hora pactada en el turno"
                    custom
                    onClick={() => {
                      if (isAgreedDialingValid) {
                        setFieldValue('attendance[substitutionTime]', agreedTime);
                      }
                    }}
                  />
                </>
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field name="attendance[comment]">
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  label="Observaciones"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center" variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
          Cancelar
        </Button>
        <Button type="submit" variant="primary" onClick={onHide} disabled={isSubmitting}>
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { correctionType } = props;
  const attendance = { ...props.attendance, correctionType };

  return {
    attendance
  };
};

const validationSchema = Yup.object().shape({
  attendance: Yup.object().shape({
    substitutionTime: Yup.string().required('Debes escoger la nueva hora'),
    comment: Yup.string()
      .max(200, 'Deben ser como máximo 200 caracteres')
      .nullable(true)
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(AttendanceFormReplace);
