import React from 'react';
import memoize from 'memoize-one';
import { BasicTooltip, ButtonTooltip, Icon } from '../../../components';

const PayrollActions = memoize(({ handleClick, item }) => {
  const payrollErrors = item?.payrollErrors;
  const noContract = payrollErrors?.noContract;
  const noRut = payrollErrors?.noRut;
  const missingFields = payrollErrors?.missingFields;
  const noContractOrNoRut = noContract
    ? { icon: 'close-circle-outline', text: 'Trabajador sin contrato' }
    : (noRut && { icon: 'close-circle-outline', text: 'Trabajador sin RUT' });

  return (
    <>
      {payrollErrors && missingFields && !noContract && (
        <ButtonTooltip
          size="sm"
          variant="circle-dark"
          toolbarVariant="d-inline-block"
          className="text-danger"
          text="Más detalles"
          onClick={() => handleClick(missingFields, 'missing_fields')}
        >
          <Icon icon="close-circle-outline" />
        </ButtonTooltip>
      )}

      {noContractOrNoRut && (
        <BasicTooltip toolbarVariant="d-inline-block" text={noContractOrNoRut.text}>
          <Icon icon={noContractOrNoRut.icon} width="21px" />
        </BasicTooltip>
      )}
    </>
  );

});

export default PayrollActions;