import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import moment from 'moment';
import { FormikInput, FormikTimePicker } from '../../components';

const advancePaymentProcessForm = ({ onHide, submitVariant, errors, touched, setFieldValue, isSubmitting }) => {
  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={8}>
            <Field name="advancePaymentProcess[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Nombre del proceso"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="advancePaymentProcess[selectedDate]">
              {({ field }) => (
                <FormikTimePicker
                  abbr
                  label="Mes y Año de proceso"
                  placeholder="Seleccionar mes y año de proceso"
                  dateFormat="MMMM - yyyy"
                  showMonthYearPicker
                  setLocale
                  onChange={date => {
                    setFieldValue(field.name, date);
                    if (!date) return;
                    moment.locale('en');
                    const month = moment(date)
                      .format('MMMM')
                      .toLowerCase();
                    const year = moment(date).format('YYYY');
                    setFieldValue('advancePaymentProcess[month]', month);
                    setFieldValue('advancePaymentProcess[year]', year);
                  }}
                  selected={field.value}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn" variant={submitVariant} onClick={onHide} disabled={isSubmitting}>
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = () => {
  return {
    advancePaymentProcess: {
      year: '',
      month: '',
      name: '',
      selectedDate: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  advancePaymentProcess: Yup.object().shape({
    year: Yup.number().required('Debes ingresar un año'),
    month: Yup.string().required('Debes ingresar un mes'),
    name: Yup.string()
      .required('Debes ingresar el nombre del proceso')
      .min(4, 'El nombre del proceso debe ser de al menos 4 caracteres')
      .max(50, 'El nombre del proceso no debe ser mayor a 50 caracteres')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(advancePaymentProcessForm);
