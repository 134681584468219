import React, { useState } from 'react';
import { Row, Col, Accordion } from 'react-bootstrap';
import PersonalData from './PersonalData';
import ContactData from './ContactData';
import AcademicInformation from './AcademicInformation';
import EmergencyContact from './EmergencyContact';
import CustomFieldsInfo from './CustomFieldsInfo';

const EmployeeInfo = ({ employee, setMoreData, moreData, onRequest, employeeProfile, employeeCustomFields }) => {
  const [activeKey, setKey] = useState('personal-data');

  return (
    <>
      <Accordion defaultActiveKey="0" className="ml-2">
        <Row>
          <Col sm={12} md={6} xs={12} className="mt-2">
            <PersonalData
              employee={employee}
              setMoreData={setMoreData}
              moreData={moreData}
              onRequest={onRequest}
              employeeProfile={employeeProfile}
              activeKey={activeKey}
              activeAccordion={() => setKey('personal-data')}
            />
            <EmergencyContact
              employee={employee}
              activeKey={activeKey}
              activeAccordion={() => setKey('emergency-contact')}
            />
          </Col>
          <Col sm={12} md={6} xs={12} className="mt-2">
            <ContactData
              employee={employee}
              activeKey={activeKey}
              activeAccordion={() => setKey('contact-data')}
              employeeProfile={employeeProfile}
            />
            <AcademicInformation
              employee={employee}
              activeKey={activeKey}
              activeAccordion={() => setKey('academic-information')}
            />
            {employeeCustomFields && employeeCustomFields.length > 0 && (
              <CustomFieldsInfo
                extraFieldsAttributes={employee.extraFieldsAttributes}
                customFields={employeeCustomFields}
                activeAccordion={() => setKey('custom-fields')}
                activeKey={activeKey}
                employeeProfile={employeeProfile}
              />
            )}
          </Col>
        </Row>
      </Accordion>
    </>
  );
};

export default EmployeeInfo;
