import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import memoize from 'memoize-one';

import {
  indexCustomFieldsRequest,
  deleteCustomFieldRequest,
  exportCustomFieldsRequest,
  preImportCustomFieldRequest,
  importCustomFieldRequest,
  importTemplateCustomFieldRequest,
  activeCustomFieldsRequest,
  deactivateCustomFieldsRequest,
  massiveDestroyCustomFieldRequest
} from '../../requests/customFields';

import {
  ActiveDot,
  ComponentDataTable,
  DefaultModal,
  BasicActionBtns,
  ImportActions,
  ImportModal,
  SimpleCenteredModal,
  SettingsMassiveActions
} from '../../components';
import { sendAlert } from '../../actions/utils';

import { downloadFile } from '../../services/utils';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'DESCRIPCIÓN',
    selector: 'description',
    sortable: true,
    grow: '1'
  },

  {
    name: 'ACTIVO',
    selector: 'active',
    cell: item => <ActiveDot item={item} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => <BasicActionBtns item={item} resource="CustomField" clickHandler={clickHandler} />,
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

const CustomFieldDataTable = ({ moreData, setMoreData, editCustomField, showCustomField }) => {
  const [amount, setAmount] = useState(0);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [query, setQuery] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const [customFields, setCustomFields] = useState([]);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState('');

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setCustomFields(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleRequest = async params => {
    setOnRequest(false);
    setQuery(params);
    indexCustomFieldsRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
    setSimpleModalShow(false);
    setOnRequest(false);
  };

  const handleSuccessRemove = message => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    handleModalClose();
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const removeCustomField = item => {
    const { id } = item;
    setOnRequest(true);
    deleteCustomFieldRequest(id, {
      dispatch,
      successCallback: () => {
        handleSuccessRemove('Campo eliminado correctamente');
      },
      failureCallback: handleFailureRequest,
      callback: () => {
        handleModalClose();
      }
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'custom_fields':
        return { sort_by_custom_fields: name };
      default:
        return { sort_column: name };
    }
  };

  const handleSuccessDownload = response => {
    downloadFile(response);
    setOnRequest(false);
    setModalShow(false);
  };

  const handleExportRequest = () => {
    setOnRequest(true);
    exportCustomFieldsRequest({
      dispatch,
      params: { ...query, paginate: false },
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        showCustomField(item);
        break;
      case 'edit':
        editCustomField(item);
        break;
      case 'destroy':
        setModalTitle('Eliminar Campo');
        setModalShow(true);
        setModalItem(item);
        setModalBody('¿Estás seguro que deseas eliminar este campo?');
        setModalAction(() => removeCustomField);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const massiveDeleteCustomField = () => {
    const customFieldsSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    massiveDestroyCustomFieldRequest({
      params: { ids: customFieldsSelected },
      dispatch,
      successCallback: handleSuccessRemove('Campos Eliminados correctamente'),
      failureCallback: handleFailureRequest,
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveActiveCustomField = () => {
    const customFieldsSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    activeCustomFieldsRequest({
      params: { ids: customFieldsSelected },
      dispatch,
      successCallback: handleSuccessRemove('Campos Activados correctamente'),
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveDeactivateCustomField = () => {
    const customFieldsSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    deactivateCustomFieldsRequest({
      params: { ids: customFieldsSelected },
      dispatch,
      successCallback: handleSuccessRemove('Campos Desactivados correctamente'),
      callback: () => {
        handleModalClose();
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'import':
        setSimpleModalShow(true);
        break;
      case 'export':
        setModalTitle('Exportar Campos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas exportar campos?');
        setModalAction(() => handleExportRequest);
        break;
      case 'massive_destroy':
        setModalTitle('Eliminar campos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar los campos seleccionados?');
        setModalAction(() => massiveDeleteCustomField);
        break;
      case 'massive_active':
        setModalTitle('Activar Campos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas activar los campos seleccionados?');
        setModalAction(() => massiveActiveCustomField);
        break;
      case 'massive_deactivate':
        setModalTitle('Desactivar Campos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas desactivar los campos seleccionados?');
        setModalAction(() => massiveDeactivateCustomField);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        handleSortCase={sortColumnCase}
        data={customFields}
        totalRows={amount}
        selectableRows
        moreData={moreData}
        resourceRequest={handleRequest}
        withMassActions
        massActions={
          <>
            <ImportActions handleClick={handleMassAction} model="CustomField" />
            <SettingsMassiveActions model="CustomField" handleClick={handleMassAction} disabled={!selectedCount} />
          </>
        }
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
        preName="custom_fields"
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={handleSelectedRows}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
      <SimpleCenteredModal
        title="Importar Campos"
        body={
          <ImportModal
            onDropUploaded={preImportCustomFieldRequest}
            handleTemplate={importTemplateCustomFieldRequest}
            hideModal={() => setSimpleModalShow(false)}
            onHide={importCustomFieldRequest}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
          />
        }
        show={simpleModalShow}
        onHide={() => setSimpleModalShow(false)}
      />
    </>
  );
};

export default CustomFieldDataTable;
