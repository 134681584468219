import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Card } from 'react-bootstrap';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import PendingDocuments from '../../../components/Employee/Dashboard/PendingDocuments';
import MyTeamRequests from '../../../components/Employee/Dashboard/MyTeamRequests';
import CompanyTaskDatatable from '../../CompanyTask/CompanyTaskDatatable';
import WorkflowStyledColumns from '../../Dashboard/WorkflowStyledColumns';
import { indexEmployeesRequest } from '../../../requests/employees';
import PendingRequests from '../../../components/Employee/Dashboard/PendingRequests';
import WorkflowRequestDataTableStyled from '../../WorkflowRequest/WorkflowRequestDataTableStyled';
import TeamAbsencesToday from '../../../components/Employee/Dashboard/TeamAbsencesToday';
import { profileTeamDashboardRequest } from '../../../requests/dashboards';
import TeamAbsenceIndex from '../../Absence/TeamAbsenceIndex';

const TeamDashboardIndex = ({ companyName, currentTab, employeeName, user }) => {
  const dispatch = useDispatch();
  const [key, setKey] = useState('requests');
  const [employees, setEmployees] = useState([]);
  const employee = user.employeeCard;
  const currentEmployee = employee.id;
  const [moreData, setMoreData] = useState(false);
  const [cards, setCards] = useState({});
  const [showSpinner, setShowSpinner] = useState(1);
  const [showSpinnerToday, setShowSpinnerToday] = useState(true);
  const [successFetchEmployee, setSuccessFetchEmployee] = useState(false);

  const fetchEmployees = () => {
    if (currentEmployee) {
      indexEmployeesRequest({
        dispatch,
        params: {
          employees_belong_boss: currentEmployee,
          is_dt: false,
          paginate: false
        },
        successCallback: res => {
          const ids = [];
          res.data.data.map(item => ids.push(item.id));
          setEmployees(ids.length > 0 ? ids : []);
          setMoreData(!moreData);
          setSuccessFetchEmployee(true);
        }
      });
    }
  };
  useEffect(fetchEmployees, [currentEmployee]);

  const fetchCardData = () => {
    profileTeamDashboardRequest({
      dispatch,
      successCallback: response => {
        setCards(camelCaseEmptyStringRecursive(response.data).allRequestsCount);
        setShowSpinner(0);
      }
    });
  };
  useEffect(fetchCardData, [moreData]);
  const setDatatable = () => {
    switch (key) {
      case 'tasks-datatable':
        return <CompanyTaskDatatable />;
      case 'absences':
        return (
          <TeamAbsenceIndex
            cards={cards}
            currentEmployee={currentEmployee}
            employeesBelongBoss={employees}
            moreData={moreData}
            setMoreData={setMoreData}
          />
        );
      case 'workflows':
        return (
          <WorkflowRequestDataTableStyled
            columns={WorkflowStyledColumns}
            withMassActions
            customFilter={{
              not_document_employee: currentEmployee,
              status: 'pending',
              pending_review: currentEmployee
            }}
            refreshDashboard={() => setMoreData(!moreData)}
            currentTab={currentTab}
          />
        );
      default:
        return '';
    }
  };

  return (
    <>
      <Row className="main-div sm-12 mb-3">
        <Col sm={6} md={6} className="mt-2">
          {employeeName && (
            <p className="title">
              Hola <b>{employeeName}</b>, bienvenido a <b>{companyName}</b>
            </p>
          )}
        </Col>
        <Col sm={12}>
          <Row>
            <Col sm={4} className="mb-1 mt-2">
              <PendingRequests
                cards={cards}
                handleMoreInfoButton={() => setKey('requests')}
                showSpinner={showSpinner}
              />
            </Col>
            <Col sm={4} className="mb-1 mt-2">
              <PendingDocuments
                handleMoreInfoButton={() => setKey('workflows')}
                topClass="top-header-green"
                moreData={moreData}
                tab="team"
              />
            </Col>
            <Col sm={4} className="mb-1 mt-2">
              <TeamAbsencesToday
                employeesBelongBoss={employees}
                successFetchEmployee={successFetchEmployee}
                showSpinnerToday={showSpinnerToday}
                setShowSpinnerToday={setShowSpinnerToday}
                handleMoreInfoButton={() => setKey('absences')}
              />
            </Col>
          </Row>
          {key === 'requests' && (
            <Row>
              <Col sm={12} className="mb-1">
                <MyTeamRequests
                  cards={cards}
                  currentEmployee={currentEmployee}
                  employeesBelongBoss={employees}
                  moreData={moreData}
                  setMoreData={setMoreData}
                />
              </Col>
            </Row>
          )}

          {key === 'workflows' && (
            <Card className="mb-1">
              <Col className="top-header-green">
                <Col className="card-header-title">GESTIÓN DE DOCUMENTOS</Col>
              </Col>
              <Col sm={12} md={12} lg={12}>
                {setDatatable(key)}
              </Col>
            </Card>
          )}

          {key === 'absences' && setDatatable(key)}
        </Col>
      </Row>
    </>
  );
};

export default TeamDashboardIndex;
