const absenceTypes = [
  { label: 'Licencia: Enfermedad o Accidente común', value: 'sickness_or_accident' },
  { label: 'Licencia: Medicina preventiva', value: 'preventive_medicine' },
  { label: 'Licencia: Pre o postnatal', value: 'pre_postnatal' },
  { label: 'Licencia: Enfermedad grave del niño menor del año', value: 'underage_sickness' },
  { label: 'Licencia: Accidente del trabajo o del trayecto', value: 'work_accident' },
  { label: 'Licencia: Enfermedad profesional', value: 'profesional_sickness' },
  { label: 'Licencia: Patologías del embarazo', value: 'pregnacy_pathologies' },
  { label: 'Falta Injustificada', value: 'unjustified_absence' },
  { label: 'Permiso sin Goce', value: 'without_pay' }
];

const selectorTypes = [{ label: 'Todos', value: 'all_employees' }];

export { absenceTypes, selectorTypes };
