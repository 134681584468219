import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { Spinner, Row, Col } from 'react-bootstrap';

import { sendAlert } from '../../../actions/utils';
import { LinkBtn, Icon } from '../../../components';
import { showPayrollProcessRequest } from '../../../requests/payrollProcesses';
import OvertimeDiscountsMovementsForm from './OvertimeDiscountsMovementsForm';
import { createOvertimeDiscountsMovementsRequest } from '../../../requests/overtimeDiscountsMovements';

const OvertimeDiscountsMovementsNew = () => {
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const returnTo = new URLSearchParams(location.search).get('returnTo') || 'absences';
  const id = new URLSearchParams(location.search).get('id');
  const [payroll, setPayroll] = useState({});

  const handleShowRequest = () => {
    setOnRequest(true);
    showPayrollProcessRequest(id, {
      dispatch,
      service: 'payroll',
      successCallback: res => {
        setPayroll(res.data);
        setOnRequest(false);
      }
    });
  };

  const handleSuccessCreate = () => {
    const message = 'Movimientos creados con éxito';
    setOnRequest(false);
    history.push(`/${returnTo}/${id}/edit?tab=overtime-discounts`);
    dispatch(sendAlert({ kind: 'success', message }));
  };

  const validateLength = odValues => {
    if (!odValues.overtimeDiscountsMovement.length) {
      dispatch(sendAlert({ kind: 'error', message: 'Debes seleccionar al menos un trabajador' }));
      return true;
    }
    return false;
  };

  const handleFailureRequest = error => {
    history.push(`/${returnTo}/${id}/edit?tab=overtime-discounts`);
    const { response } = error;
    setOnRequest(false);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleCreate = (odValues, setSubmitting) => {
    if (validateLength(odValues)) {
      setSubmitting(false);
      return;
    }
    const overtimeDiscountsMovementsValues = {
      overtimeDiscountsMovements: odValues.overtimeDiscountsMovement.map(movement => ({
        ...movement,
        payrollProcessId: id,
        date: movement.startDate,
        status: 'approved'
      }))
    };
    setOnRequest(true);
    const params = snakeCaseKeys(overtimeDiscountsMovementsValues);
    createOvertimeDiscountsMovementsRequest({
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest,
      callback: () => {
        setSubmitting(false);
        setOnRequest(false);
      }
    });
  };

  useEffect(handleShowRequest, []);

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn
            variant="circle-dark"
            className="btn-circle mt-3"
            block
            to={`/${returnTo}/${id}/edit?tab=overtime-discounts&id=${id}`}
          >
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">Ingreso Horas Extras / Horas de Atraso</h2>
        </Col>
      </Row>
      {payroll?.id && <OvertimeDiscountsMovementsForm action="new" formRequest={handleCreate} payroll={payroll} />}
    </>
  );
};

export default OvertimeDiscountsMovementsNew;
