import React, { useEffect, useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FormikTimePicker, FormikSelect, FormikInput } from '../../../components';
import { debounceIndexCostCentersRequest } from '../../../requests/costCenters';
import { debounceIndexJobManagementsRequest } from '../../../requests/jobManagements';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';
import { payrollEmployeesRequest } from '../../../requests/payrolls';
import FormikSelectClear from '../../../components/Utils/Select/FormikSelectClear';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';

const Filters = props => {
  const { errors, touched, setFieldValue, values, onHide, disabled = false, setFieldTouched } = props;
  const [costCenters, setCostCenters] = useState([]);
  const ALL_SELECTED_LABEL = 'Todos';
  const [jobManagement, setJobManagement] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [employees, setEmployees] = useState([]);
  const { currentCompany } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const resultFetchData = (response, type) => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: `${type ? `${element.code} - ` : ''} ${element.name}`,
      value: element.id
    }));
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData.includes('all') ? [] : newData);
  };

  const successEmployees = data => {
    const parsedEmployees = camelCaseEmptyStringRecursive(data);
    return parsedEmployees.map(employee => ({
      label: employee.startDateContract ? `${employee.fullName} - (${employee.startDateContract})` : employee.fullName,
      value: employee.payrollId
    }));
  };

  const fetchEmployeesPayrolls = params => {
    payrollEmployeesRequest({
      dispatch,
      params: {
        ...params,
        filter_by_company: currentCompany.id,
        paginate: false
      },
      successCallback: response => {
        const allEmployees = successEmployees(response.data.data);
        const dataToGroupSearch = [{ id: 'Todos', label: 'Todos', value: 'all' }, ...allEmployees];
        setEmployees(response.data.data.length > 0 ? dataToGroupSearch : []);
      }
    });
  };

  const fetchCostCenters = () => {
    debounceIndexCostCentersRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: data => {
        setCostCenters(resultFetchData(data));
      }
    });
  };

  const fetchJobManagments = () => {
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: data => {
        setJobManagement(resultFetchData(data));
      }
    });
  };

  const fetchBranchOffices = () => {
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: data => {
        setBranchOffices(resultFetchData(data));
      }
    });
  };

  const hasAllSelection = (groupSelection = []) => {
    return groupSelection.some(option => option.label === ALL_SELECTED_LABEL);
  };

  const handleDisabledSelection = (options, selectedOptions) => {
    if (hasAllSelection(selectedOptions)) {
      return true;
    }
    if (selectedOptions.length === 0) return false;
    return options.label === ALL_SELECTED_LABEL;
  };

  const handleEmployeeFilters = () => {
    if ((values.dateFrom && values.dateTo) || (values.monthDate && values.yearDate)) {
      setFieldValue('employees', []);
      setFieldValue('filterIds', []);
      let params = {};
      if (values.dateFrom && values.dateTo) {
        params = { within_date_range: [values.dateFrom, values.dateTo] };
      } else if (values.monthDate && values.yearDate) {
        params = { month_date: values.monthDate, year_date: values.yearDate };
      }
      fetchEmployeesPayrolls(params);
    }
  };

  useEffect(fetchCostCenters, []);
  useEffect(fetchJobManagments, []);
  useEffect(fetchBranchOffices, []);

  useEffect(() => {
    if (values.dateFrom && values.dateTo) {
      setFieldValue('dateRange', [values.dateFrom, values.dateTo]);
    }
  }, [values.dateFrom, values.dateTo, setFieldValue]);

  useEffect(handleEmployeeFilters, [values.dateFrom, values.dateTo, values.monthDate, values.yearDate]);

  return (
    <Form>
      <Card style={{ zIndex: 'unset' }}>
        <Row>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className="top-header-green-dt card-header-title-dt pt-1 pb-1">Liquidaciones de Sueldo</Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="monthDate">
                      {({ field }) => (
                        <FormikTimePicker
                          abbr
                          label="Mes y Año de proceso"
                          placeholder="Seleccionar mes y año de proceso"
                          dateFormat="MMMM - yyyy"
                          showMonthYearPicker
                          setLocale
                          onChange={date => {
                            setFieldValue('dateTo', '');
                            setFieldValue('dateFrom', '');
                            setFieldValue('dateRange', []);
                            setFieldValue(field.name, date);
                            setFieldValue('yearDate', date);
                          }}
                          selected={field.value}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  {/*                   <Col sm={12} md={3} xs={12}>
                    <Field name="yearDate">
                      {({ field }) => (
                        <FormikTimePicker
                          abbr
                          label="Año de Proceso"
                          placeholder="Seleccionar año de proceso"
                          dateFormat="yyyy"
                          showYearPicker
                          onChange={date => {
                            setFieldValue('dateTo', '');
                            setFieldValue('dateFrom', '');
                            setFieldValue('dateRange', []);
                            setFieldValue(field.name, date);
                          }}
                          selected={field.value}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col> */}
                  <Col sm={6} md={3} xs={6}>
                    <Field name="dateFrom">
                      {({ field }) => (
                        <FormikTimePicker
                          {...field}
                          abbr
                          setLocale
                          label="Desde"
                          placeholder="mm/aaaa"
                          dateFormat="MM/yyyy"
                          maxDate={values.dateTo}
                          showMonthYearPicker
                          selected={field.value}
                          onChange={date => {
                            setFieldValue('monthDate', '');
                            setFieldValue('yearDate', '');
                            setFieldValue(field.name, date);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={6} md={3} xs={6}>
                    <Field name="dateTo">
                      {({ field }) => (
                        <FormikTimePicker
                          {...field}
                          abbr
                          setLocale
                          label="Hasta"
                          placeholder="mm/aaaa"
                          dateFormat="MM/yyyy"
                          minDate={values.dateFrom}
                          showMonthYearPicker
                          selected={field.value}
                          onChange={date => {
                            setFieldValue('monthDate', '');
                            setFieldValue('yearDate', '');
                            setFieldValue(field.name, date);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="employees">
                      {({ field }) => (
                        <FormikSelectClear
                          {...field}
                          label="Trabajadores"
                          placeholder="Seleccionar Trabajador"
                          isMulti
                          options={employees}
                          defaultValue={values.employees}
                          value={values.employees}
                          onChange={data => {
                            handleNewMulti(data || [], 'filterIds', true);
                            setFieldValue(field.name, data || []);
                          }}
                          setFieldTouched={() => setFieldTouched(field.name)}
                          isOptionDisabled={handleDisabledSelection}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="filterNationalId">
                      {({ field }) => (
                        <FormikInput
                          {...field}
                          label="RUT:"
                          placeholder="Sin puntos y sin guion"
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="costCenterId">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Centro de Costos"
                          menuPlacement="top"
                          placeholder="Seleccionar Centro de Costos"
                          options={costCenters}
                          isClearable
                          onChange={data => setFieldValue(field.name, data ? data.value : '')}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="jobManagementId">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Área"
                          menuPlacement="top"
                          placeholder="Seleccionar Área"
                          options={jobManagement}
                          isClearable
                          onChange={data => setFieldValue(field.name, data ? data.value : '')}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="branchOfficeIds">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Lugar de Prestación de Servicios"
                          placeholder="Seleccionar Lugar de Prestación de Servicios"
                          menuPlacement="top"
                          options={branchOffices}
                          isClearable
                          onChange={data => setFieldValue(field.name, data ? data.value : '')}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
      <Row className="d-flex justify-content-end mt-4 mb-4">
        <Col md={2}>
          <Button className="btn-block" type="submit" onClick={onHide} disabled={disabled}>
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const customValidation = values => {
  const { monthDate, yearDate, dateTo, dateFrom } = values;

  if (!monthDate && !yearDate && !dateTo && !dateFrom) {
    return Yup.object().shape({
      monthDate: Yup.string().required('Este campo es obligatorio'),
      yearDate: Yup.string()
        .required('Este campo es obligatorio')
        .nullable(),
      dateFrom: Yup.string().required('Debes ingresar una fecha de inicio'),
      dateTo: Yup.string().required('Debes ingresar una fecha de termino')
    });
  }

  if (dateTo && !dateFrom) {
    return Yup.object().shape({
      dateFrom: Yup.string().required('Este campo es obligatorio')
    });
  }

  if (!dateTo && dateFrom) {
    return Yup.object().shape({
      dateTo: Yup.string().required('Este campo es obligatorio')
    });
  }

  if (!monthDate && yearDate) {
    return Yup.object().shape({
      monthDate: Yup.string().required('Este campo es obligatorio')
    });
  }

  if (monthDate && !yearDate) {
    return Yup.object().shape({
      yearDate: Yup.string()
        .required('Este campo es obligatorio')
        .nullable()
    });
  }

  return Yup.object().shape({});
};

const setInitialValues = () => {
  return {
    employees: [],
    filterNationalId: '',
    monthDate: '',
    yearDate: '',
    dateRange: [],
    dateFrom: '',
    dateTo: '',
    costCenterId: '',
    jobManagementId: '',
    branchOfficeIds: '',
    filterIds: []
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema: Yup.lazy(values => customValidation(values)),
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(Filters);
