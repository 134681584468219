/* eslint-disable no-nested-ternary */
import React from 'react';

const Header = ({ headerGroups }) => {
  return (
    <thead style={{ height: '50px' }}>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, columnIndex) => (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              style={{ width: columnIndex === 0 ? '30px' : column.width ?? 'auto' }}
            >
              <>
                {column.render('Header')}
                <div>{column.canFilter ? column.render('Filter') : null}</div>
                <span>{column.isSorted ? (column.isSortedDesc ? ' ⇩' : ' ⇧') : ''}</span>
              </>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default Header;
