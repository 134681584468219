import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Field, Form, getIn, withFormik } from 'formik';
import * as Yup from 'yup';
import { FormikTimePicker, ImportModal } from '../../components';
import { sendAlert } from '../../actions/utils';
import {
  importHistoriesRequest,
  importHistoriesTemplateRequest,
  preImportHistoriesRequest
} from '../../requests/payrollHistories';

function ImportForm({ setMoreData, setSimpleModalState, setModalState, setFieldValue, errors, touched, values }) {
  const dispatch = useDispatch();
  const handleModalClose = () => {
    setModalState(state => ({
      ...state,
      body: <></>,
      show: false
    }));
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalState({
        title: 'Importar Haberes',
        body: alertInfo,
        show: true,
        action: handleModalClose
      });
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  return (
    <>
      <Form>
        <Row className="m-top mb-1">
          <Col md={12}>
            <Field name="selectedDate">
              {({ field }) => (
                <FormikTimePicker
                  abbr
                  label="Mes y Año de proceso"
                  placeholder="Seleccionar mes y año de proceso"
                  dateFormat="MMMM - yyyy"
                  showMonthYearPicker
                  setLocale
                  onChange={date => {
                    setFieldValue(field.name, date);
                    if (!date) return;
                    const month = moment(date).format('M');
                    const year = moment(date).format('YYYY');
                    setFieldValue('month', month);
                    setFieldValue('year', year);
                  }}
                  selected={field.value}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <ImportModal
          onDropUploaded={preImportHistoriesRequest}
          handleTemplate={importHistoriesTemplateRequest}
          onHide={importHistoriesRequest}
          hideModal={() => setSimpleModalState(oldState => ({ ...oldState, show: false }))}
          updateData={() => setMoreData(oldValue => !oldValue)}
          handleExceptions={handleImportModalExceptions}
          params={{
            year: values.year,
            month: values.month ? +values.month - 1 : ''
          }}
          disabled={!values.year || !values.month}
          service="payroll"
        />
      </Form>
    </>
  );
}

const setInitialValues = () => {
  return {
    year: '',
    month: '',
    selectedDate: ''
  };
};

const validationSchema = Yup.object().shape({
  advancePaymentProcess: Yup.object().shape({
    year: Yup.number().required('Debes ingresar un año'),
    month: Yup.string().required('Debes ingresar un mes'),
    name: Yup.string()
      .required('Debes ingresar el nombre del proceso')
      .min(4, 'El nombre del proceso debe ser de al menos 4 caracteres')
      .max(50, 'El nombre del proceso no debe ser mayor a 50 caracteres')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ImportForm);
