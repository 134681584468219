import React, { useEffect } from 'react';
import { withFormik, Field, Form } from 'formik';
import { Button, Row, Col, Card, Accordion } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { debounceIndexCostCentersRequest } from '../../../requests/costCenters';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';
import { InputSelect } from '../../../components';
import { debounceIndexSelectEmployeesRequest, searchEmployeeRequest } from '../../../requests/employees';
import { debounceIndexJobManagementsRequest } from '../../../requests/jobManagements';
import { debounceIndexBalancesRequest } from '../../../requests/balances';
/* import { debounceIndexSubsidiariesRequest } from '../../../requests/subsidiaries'; */
import BalanceTypeOptions from './Form/BalanceTypeOptions';

const Filters = props => {
  const { setFieldValue } = props;
  const dispatch = useDispatch();

  const resultFetchData = response => {
    const { data } = response.data;
    const tempArray = data.map(element => {
      return {
        label: element.name,
        value: element.id
      };
    });
    return tempArray;
  };

  const fetchJobManagments = (inputValue, callback) => {
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        paginate: false
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  };

  const fetchCostCenters = (inputValue, callback) => {
    debounceIndexCostCentersRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 100
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  const fetchBranchOffices = (inputValue, callback) => {
    const request = async () => {
      debounceIndexBranchOfficesRequest({
        dispatch,
        params: {
          actives: true,
          name: inputValue,
          sort_column: 'name',
          paginate: false
        },
        successCallback: data => {
          callback(resultFetchData(data));
        }
      });
    };
    request();
  };

  /*   const fetchSubsidiaries = (inputValue, callback) => {
    debounceIndexSubsidiariesRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: data => {
        callback(resultFetchData(data));
      }
    });
  }; */

  const searchEmployeesParams = () => {
    const request = async () =>
      searchEmployeeRequest({
        dispatch,
        params: {
          sort_column: 'name',
          paginate: false,
          advance: true
        },
        successCallback: response => camelCaseRecursive(response.data.data)
      });
    request();
  };

  const fetchEmployee = (inputValue, callback) => {
    debounceIndexSelectEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        sort_column: 'name',
        is_dt: false,
        display_length: 100
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchBalances = (inputValue, callback) => {
    debounceIndexBalancesRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        paginate: false,
        show_payroll: true
      },
      successCallback: response => {
        callback(resultFetchData(response));
      }
    });
  };

  const handleNewMulti = (data, field, allowEmpty = false, jsonb = false) => {
    let newData;

    if (jsonb) {
      newData = data.map(element => element.label);
    } else {
      newData = data.map(element => element.value);
    }
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData);
  };

  const initialFetch = () => {
    searchEmployeesParams();
  };

  useEffect(initialFetch, []);

  return (
    <Form>
      <Card>
        <Col className="top-header-green">
          <Accordion.Toggle as={Col} eventKey="0" className="card-header-title">
            FILTROS DE BÚSQUEDA
          </Accordion.Toggle>
        </Col>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="div-content">
            <Row>
              <Col xs={12} md={4}>
                <Field name="filterEmployeeIds">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Trabajador"
                      placeholder="Seleccionar Trabajador"
                      value={undefined}
                      request={fetchEmployee}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="filterBalanceIds">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Concepto"
                      placeholder="Seleccionar concepto"
                      request={fetchBalances}
                      value={undefined}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="filterPeriodType">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Periodo"
                      placeholder="Seleccionar por periodo"
                      defaultOptions={BalanceTypeOptions}
                      value={undefined}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Field name="filterCostCenter">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Centro de costo"
                      placeholder="Seleccionar centro de costo"
                      request={fetchCostCenters}
                      value={undefined}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="filter_branch_offices">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Lugar de Prestación de Servicio"
                      placeholder="Seleccionar Lugar de Prestación"
                      request={fetchBranchOffices}
                      value={undefined}
                      isMulti
                      onChange={data => handleNewMulti(data || [], 'filter_branch_offices', true, false)}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="filterJobManagement">
                  {({ field }) => (
                    <InputSelect
                      {...field}
                      label="Área"
                      placeholder="Seleccionar área"
                      request={fetchJobManagments}
                      value={undefined}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      isClearable
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-4">
              <Col md={2}>
                <Button className="btn-block" type="submit">
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Form>
  );
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: () => ({
    filterJobManagement: '',
    filter_branch_offices: '',
    filterCostCenter: '',
    filterBalanceIds: '',
    filterPeriodType: '',
    filterEmployeeIds: ''
  }),
  handleSubmit,
  enableReinitialize: true
})(Filters);
