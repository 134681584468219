import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { LinkBtn, Icon } from '../../components';
import ConceptForm from './Form/ConceptForm';
import { emptyConcept } from './Form/FormOptions';
import useConceptHook from './useConceptHook';

const ConceptNew = () => {
  const { handleCreateConcept } = useConceptHook();

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/remunerations_settings">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">Nuevo Concepto</h2>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ConceptForm
            balance={emptyConcept}
            action="new"
            formRequest={({ balance }, setSubmitting) => {
              handleCreateConcept(balance, setSubmitting);
            }}
            submitVariant="primary"
          />
        </Col>
      </Row>
    </>
  );
};

export default ConceptNew;
