import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Spinner, Button, Modal } from 'react-bootstrap';

import { BasicTextArea, FormikInput, FormikCheckBox, FormikSelect, NestedAttributes } from '../../components';
import { fieldTypes, fieldDisplays } from './FormOptions';
import '../../services/yupCustomMethods';
import { delayMethod } from '../../services/utils';

const FieldTypeInput = ({ errors, touched, index, assignedToExtraField }) => {
  return (
    <Col>
      <Row>
        <Col md={12}>
          <Field name={`customField[customFieldTypesAttributes][${index}][name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                placeholder="Opcion de selector"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled={assignedToExtraField}
              />
            )}
          </Field>
        </Col>
      </Row>
    </Col>
  );
};

const CustomFieldForm = ({
  action,
  errors,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  touched,
  values,
  customField,
  handleModalClose,
  ...props
}) => {
  const { onHide, submitVariant } = props;
  const selectorType = values.customField.fieldType === 'selector';
  const employeeDisplay = values.customField.fieldDisplay === 'card_employee_display';
  const setNewCustomField = () => {
    if (!values.newCustomField) setFieldValue('customField[newCustomField]', true);
  };

  const fieldTypesInputs = () => {
    const { customFieldTypesAttributes } = values.customField;

    const mapResults = customFieldTypesAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <FieldTypeInput
          key={`field-type-${index.toString()}`}
          fieldTypesAttributes={body}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
          index={index}
          assignedToExtraField={customField.assignedToExtraField}
        />
      );
    });

    mapResults.push(
      <Col>
        <Row>
          <Col className="sample-row">
            <FormikSelect placeholder="Inserta nueva opción" isDisabled />
          </Col>
        </Row>
      </Col>
    );

    return (
      <>
        <NestedAttributes
          removeFirstItem
          mapInputs={mapResults}
          arrayValues={customFieldTypesAttributes}
          setFieldValue={setFieldValue}
          actionOnAdd={setNewCustomField}
          valuePath="customField[customFieldTypesAttributes]"
          newAttributes={{
            name: ''
          }}
        />
      </>
    );
  };

  return (
    <Form className="mx-3">
      <Modal.Body>
        {isSubmitting && <Spinner animation="border" variant="primary" className="spinner-modal" />}
        {
          <>
            <Row>
              <Col md={12}>
                <Field name="customField[name]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      label="Nombre"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled={customField.assignedToExtraField}
                    />
                  )}
                </Field>
              </Col>
              <Col md={12}>
                <Field name="customField[description]">
                  {({ field }) => (
                    <BasicTextArea
                      {...field}
                      abbr
                      label="Descripción"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled={customField.assignedToExtraField}
                    />
                  )}
                </Field>
              </Col>
              <Col md={12}>
                <Field name="customField[active]">
                  {({ field }) => <FormikCheckBox {...field} field={field} label="Activo" />}
                </Field>
              </Col>
              <Col md={12}>
                <Field name="customField[keyNameTemplate]">
                  {({ field, form }) => (
                    <FormikInput
                      {...field}
                      abbr
                      label="Nombre de la llave del Campo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      disabled={customField.assignedToExtraField}
                      onChange={e => {
                        const newValue = e.target.value.replace(/[\sáéíóúÁÉÍÓÚ]/g, '');
                        form.setFieldValue(field.name, newValue);
                      }}
                    />
                  )}
                </Field>
              </Col>
              <Col md={12}>
                <Field name="customField[fieldType]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      label="Tipo de Campo"
                      placeholder="Seleccionar Tipo de Campo"
                      options={fieldTypes}
                      defaultValue={customField.fieldType}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      setFieldTouched={() => setFieldTouched(field.name)}
                      field_type
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      isDisabled={customField.assignedToExtraField}
                    />
                  )}
                </Field>
                {selectorType && (
                  <>
                    <h4 className="text-uppercase">Opciónes del Selector</h4>
                    {fieldTypesInputs()}
                  </>
                )}
              </Col>
              <Col md={12}>
                <Field name="customField[fieldDisplay]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      label="Visualización del Campo"
                      placeholder="Seleccionar Visualización del Campo"
                      options={fieldDisplays}
                      defaultValue={customField.fieldDisplay}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      setFieldTouched={() => setFieldTouched(field.name)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      isDisabled={customField.assignedToExtraField}
                    />
                  )}
                </Field>
              </Col>
              <Col md={12}>
                {employeeDisplay && (
                  <Field name="customField[visibleWorker]">
                    {({ field }) => <FormikCheckBox {...field} field={field} custom label="Visible Trabajador" />}
                  </Field>
                )}
              </Col>
            </Row>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Row className="d-flex justify-content-end mt-3 mb-2">
          <Col xs={6} sm={6}>
            <Button block variant="outline-info" onClick={() => delayMethod(handleModalClose)} disabled={isSubmitting}>
              Cancelar
            </Button>
          </Col>
          <Col xs={6} sm={6}>
            <Button type="submit" disabled={isSubmitting} variant={submitVariant} block onClick={onHide}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const currentCompany = localStorage.getItem('currentCompany');
  const { customField } = props;
  return {
    customField: {
      ...customField,
      name: customField.name || '',
      companyId: currentCompany
    }
  };
};

const validationSchema = Yup.object().shape({
  customField: Yup.object().shape({
    name: Yup.string()
      .required('Debes ingresar un nombre del campo')
      .max(50, 'Campo de texto de máximo 50 caracteres.'),
    description: Yup.string()
      .required('Debes ingresar un descripcion del campo')
      .max(80, 'Campo de texto de máximo 80 caracteres.'),
    keyNameTemplate: Yup.string()
      .required('Debes ingresar un nombre la llave campo')
      .max(40, 'Campo de texto de máximo 40 caracteres.')
      .matches(/^\S*$/, 'No debe contener espacios en blanco'),
    fieldType: Yup.string().required('Debes escoger el tipo de campo'),
    fieldDisplay: Yup.string().required('Debes escoger la visualizacion del campo')
  })
});
const handleSubmit = (customField, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(customField, setSubmitting);
};
export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(CustomFieldForm);
