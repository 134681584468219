import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import { LinkBtn, Icon } from '../../../components';
import ControlClockForm from './ControlClockForm';
import basicDevice from './controlClock';
import { createAdminDeviceRequest } from '../../../requests/adminDevices';
import { sendAlert } from '../../../actions/utils';

const ControlClockNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => window.scrollTo(0, 0), []);

  const handleCreate = async device => {
    createAdminDeviceRequest({
      dispatch,
      params: snakecaseKeys(device),
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Control Control creado con éxito' }));
        history.push(`/admin/control_clock/`);
      }
    });
  };

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/admin/control_clock">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">Nuevo Reloj Control</h2>
        </Col>
      </Row>
      <ControlClockForm device={basicDevice} action="new" formRequest={handleCreate} />
    </>
  );
};

export default ControlClockNew;
