import React from 'react';
import memoize from 'memoize-one';
import { useAbility } from '@casl/react';
import { Icon, ApprovementStatus, DatatableEmployeeName, BasicDropdown } from '../../../components';

import { AbilityContext } from '../../../config/abilityContext';

export default memoize((clickHandler, allowedActions) => {
  const ability = useAbility(AbilityContext);

  const actions = item => {
    const authorizedActions = [];

    if (ability.can('show', 'Document') && allowedActions.find(e => e === 'show')) {
      authorizedActions.push({
        icon: <Icon icon="eye" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: `${item.id}-show`,
        props: {
          onClick: () => clickHandler(item, 'show')
        },
        title: 'Ver'
      });
    }

    if (ability.can('show', 'Document') && allowedActions.find(e => e === 'show')) {
      authorizedActions.push({
        icon: <Icon icon="git-branch-outline" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: `${item.id}-show-workflow`,
        props: {
          onClick: () => clickHandler(item, 'show-workflow'),
          disabled: item.status === 'not_signed'
        },
        title: 'Detalle del flujo'
      });
    }

    if (ability.can('show', 'Document') && allowedActions.find(e => e === 'download')) {
      authorizedActions.push({
        icon: <Icon icon="cloud-download" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: `${item.id}-download`,
        props: {
          onClick: () => clickHandler(item, 'download')
        },
        title: 'Descargar'
      });
    }

    return authorizedActions;
  };

  return [
    {
      name: 'RUT Y NOMBRE',
      selector: 'employee_name',
      cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
      grow: '1'
    },
    {
      name: 'DOCUMENTO',
      selector: 'document_type',
      cell: item => (
        <div className="d-flex flex-column" onClick={() => clickHandler(item, 'show')} aria-hidden="true">
          <span className="text-muted" data-tag="allowRowEvents">
            {item.translated_document_type}
          </span>
          <span className="text-truncate" data-tag="allowRowEvents">
            {item.file_info?.filename}
          </span>
        </div>
      ),
      sortable: true,
      grow: '2'
    },
    {
      name: 'FECHA EMISIÓN',
      selector: 'created_at',
      sortable: true,
      center: true,
      grow: '1'
    },
    {
      name: 'ESTADO',
      selector: 'status',
      cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
      center: true,
      sortable: true,
      grow: '1'
    },
    {
      name: 'ACCIONES',
      cell: item => {
        if (!allowedActions) {
          return <></>;
        }

        return (
          <BasicDropdown
            block
            items={actions(item)}
            noArrow
            right
            titleDrop={
              <span className="align-middle">
                Acciones <Icon icon="ellipsis-vertical" height="20px" width="20px" />
              </span>
            }
            variant="outline-primary"
          />
        );
      },
      center: true,
      ignoreRowClick: true,
      sortable: false
    }
  ];
});
