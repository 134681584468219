import ApiService from '../services/apiService';

export const indexLoanInstallmentsRequest = ({ dispatch, params = {}, successCallback, callback }) =>
  ApiService.request('get', `/loan_installments`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const postponeLoanInstallmentRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('put', `/loan_installments/${id}/postpone`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const exportLoanInstallmentsRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) =>
  ApiService.request('get', `/loan_installments/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback
  });

export const sendDocumentLoanInstallmentRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('post', `/loan_installments/${id}/generate_document`, {
    dispatch,
    params,
    successCallback,
    callback
  });

export const sendMassiveDocumentLoanInstallmentRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('post', `/loan_installments/mass_generate_document`, {
    dispatch,
    params,
    successCallback,
    callback
  });
