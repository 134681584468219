import React, { useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { FormikCheckBox, FormikSelect, FormikInput, PhoneWithCode, InputSelect, Dropzone } from '../../../components';
import { clasificationTypes, criticalityTypes } from './FormOptions';
import { indexAdminCompanyRequest } from '../../../requests/adminCompanies';
import { createDropZoneFileRequest } from '../../../requests/dropzone';
import { indexSelectEmployeesRequest } from '../../../requests/employees';
import { indexUserAdminRequest } from '../../../requests/adminUsers';

const TicketForm = props => {
  const { errors, handleModalClose, setFieldTouched, setFieldValue, ticket, touched, values, isSubmitting } = props;
  const { phoneCountryCode } = ticket;
  const [employeeSelector, setEmployeeSelector] = useState({});
  const [descriptionCounter, setDescriptionCounter] = useState(0);
  const [disableInputs, setDisableInputs] = useState(true);
  const dispatch = useDispatch();

  const handleEmployeeSelect = (data, field) => {
    setFieldValue('ticket[email]', data.email);
    setFieldValue('ticket[phoneCountryCode]', data.phoneCountryCode);
    setFieldValue('ticket[phone]', data.phone);
    setFieldValue(field.name, data ? data.value : '');
  };

  const handleSuccessIndex = response => {
    const employees = camelCaseEmptyStringRecursive(response.data.data);
    setEmployeeSelector(employees);
    if (employees.length) {
      setDisableInputs(false);
    } else {
      setDisableInputs(true);
    }
  };

  const fetchEmployees = companyId => {
    indexSelectEmployeesRequest({
      dispatch,
      params: { active: true, company_id: companyId, sort_column: 'name', sort_direction: 'asc' },
      successCallback: handleSuccessIndex
    });
  };

  const handleCompanySelect = (data, field) => {
    setFieldValue(field.name, data ? data.value : '');
    setFieldValue('ticket[employeeId]', '');
    setFieldValue('ticket[email]', '');
    setFieldValue('ticket[phone]', '');
    fetchEmployees(data.value);
  };

  const handleDescriptionChange = e => {
    setDescriptionCounter(e.target.value.length);
    setFieldValue(e.target.name, e.target.value);
  };

  const resultCompanyFetchData = response => {
    const result = response.data.data;
    const companies = result.map(element => ({
      label: element.business_name,
      value: element.id
    }));
    return companies;
  };

  const fetchCompanies = (inputValue, callback) => {
    indexAdminCompanyRequest({
      dispatch,
      params: {
        business_name: inputValue,
        sort_column: 'business_name',
        paginate: false
      },
      successCallback: data => callback(resultCompanyFetchData(data))
    });
  };

  const fetchUsers = (inputValue, callback) => {
    indexUserAdminRequest({
      dispatch,
      params: {
        business_name: inputValue,
        sort_column: 'email',
        paginate: false
      },
      successCallback: data => callback(data.data.data)
    });
  };

  const onDropUploadedFile = document => {
    document.map(doc => setFieldValue(`ticket[documentKeys][${doc.id}]`, doc.code));
  };

  const handleDestroyFile = ({ id }) => {
    delete values.ticket.documentKeys[id];
  };

  const handleOnDrop = (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'import',
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => response
    });
  };

  return (
    <>
      <Form className="container">
        <>
          <Row>
            <Col md={6}>
              <Field name="ticket[companyId]">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    abbr
                    label="Seleccionar empresa"
                    placeholder="Seleccionar Empresa"
                    values={values}
                    model={[ticket, 'company']}
                    request={fetchCompanies}
                    onChange={data => handleCompanySelect(data, field)}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="ticket[title]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Titulo del ticket"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="ticket[userId]">
                {({ field }) => (
                  <InputSelect
                    {...field}
                    label="Responsable"
                    placeholder={ticket.user?.label || 'Seleccionar responsable'}
                    values={values}
                    model={[ticket, 'user']}
                    request={fetchUsers}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field name="ticket[clasification]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    label="Clasificación"
                    placeholder={ticket.translatedClasification || 'Seleccionar Clasificación'}
                    options={clasificationTypes}
                    defaultValue={ticket.clasification}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="ticket[criticality]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    label="Criticidad"
                    placeholder={ticket.translatedCriticality || 'Seleccionar Criticidad'}
                    options={criticalityTypes}
                    defaultValue={ticket.criticality}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <hr />
        </>
        <Row>
          <Col md={6}>
            <Field name="ticket[employeeId]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  isDisabled={disableInputs}
                  label="Solicitado Por"
                  options={employeeSelector}
                  placeholder={field.value || 'Seleccionar Trabajador'}
                  onChange={data => handleEmployeeSelect(data, field)}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <PhoneWithCode
              countryCodeField="ticket[phoneCountryCode]"
              label="Teléfono"
              phoneField="ticket[phone]"
              disabled={disableInputs}
              workPhoneCountryCode={phoneCountryCode}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="ticket[email]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Correo"
                  disabled={disableInputs}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={12}>
                <p className="ticket-false-label">Canal de Respuesta</p>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="d-flex align-items-center">
                <Field name="ticket[viaEmail]">
                  {({ field }) => (
                    <FormikCheckBox
                      {...field}
                      custom
                      field={field}
                      label="Correo"
                      tooltipSize="14"
                      tooltipText="Canal de Correo"
                    />
                  )}
                </Field>
              </Col>
              <Col md={6} className="d-flex align-items-center">
                <Field name="ticket[viaPhone]">
                  {({ field }) => (
                    <FormikCheckBox
                      {...field}
                      custom
                      field={field}
                      label="Teléfono"
                      tooltipSize="14"
                      tooltipText="Canal de Teléfono"
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field name="ticket[description]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  as="textarea"
                  label={`Detalle del Incidente (${descriptionCounter}/2000)`}
                  minInputHeight="150"
                  onChange={e => handleDescriptionChange(e)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Dropzone
              fileAccept=".pdf .xls .doc .docx .rft .xlsxl .ppt .pptx .jpg .jpeg .gif .mp3 .mp4 .wmv .avi .png"
              maxSize={50000000}
              multiple
              customClass="long-dropzone"
              onDelete={handleDestroyFile}
              onDrop={handleOnDrop}
              onDropUploaded={onDropUploadedFile}
            />
          </Col>
        </Row>
        <Row className="justify-content-end mt-3">
          <Col md={{ span: 3, offset: 6 }}>
            <Button className="btn" variant="outline-info" block onClick={handleModalClose}>
              CANCELAR
            </Button>
          </Col>
          <Col md={3}>
            <Button type="submit" disabled={isSubmitting} block>
              CONFIRMAR
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const { phoneCountryCode, phone, email, viaEmail, viaPhone, description } = props.ticket;

  return {
    ticket: {
      phoneCountryCode,
      phone,
      email,
      viaEmail,
      viaPhone,
      description
    }
  };
};

const validationSchema = Yup.object().shape({
  ticket: Yup.object().shape({
    description: Yup.string()
      .required('Debes ingresar el detalle del incidente')
      .max(2000, 'Máximo 2000 caracteres permitidos'),
    email: Yup.string()
      .required('Debes ingresar un correo')
      .email('El email que ingresaste no es válido'),
    employeeId: Yup.string().required('Debes ingresar un nombre'),
    phone: Yup.string()
      .when('phoneCountryCode', {
        is: val => val === '+56',
        then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
        otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
      })
      .when('viaPhone', {
        is: val => val,
        then: Yup.string().required('Debe ingresar un teléfono'),
        otherwise: Yup.string().nullable()
      }),
    viaEmail: Yup.boolean(),
    viaPhone: Yup.boolean()
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit
})(TicketForm);
