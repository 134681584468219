import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { downloadFile, camelCaseEmptyStringRecursive } from '../../../services/utils';
import { processDetailReportRequest, processQuoteReportRequest } from '../../../requests/payrollProcessesReports';
import ProcessDetails from './ProcessDetails';
import QuoteDetails from './QuoteDetails';

const processRequests = {
  process_details: processDetailReportRequest,
  quote_details: processQuoteReportRequest
};

const reportName = {
  process_details: 'Detalle del Proceso',
  quote_details: 'Detalle de la Cotización'
};

export default function ProcessDetailReport({ match, reportType }) {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = match.params;
  const [reportData, setReportData] = useState(false);
  const { currentCompany } = useSelector(state => state.auth);

  const handlePayrollProcessReport = format => {
    setIsLoading(true);
    const params = sessionStorage.getItem('processDetailsFilters');
    const request = processRequests[reportType];

    request(id, {
      dispatch,
      params: {
        ...JSON.parse(params),
        filter_by_company: currentCompany.id
      },
      format,
      resonseType: 'json',
      successCallback: response => {
        if (format === '.xlsx') {
          downloadFile(response);
          return;
        }
        const formatedData = camelCaseEmptyStringRecursive(response.data);
        setReportData({ reportName: reportName[reportType], data: formatedData, filterParams: JSON.parse(params) });
      },
      callback: () => {
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    handlePayrollProcessReport('.json');
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {isLoading && (
        <div className="position-relative">
          <div className="containerSpinnerLoad position-absolute h-100 w-100">
            <Spinner animation="border" variant="primary" />
          </div>
        </div>
      )}
      {reportData && (
        <div className="container">
          <div className="row">
            <div className="col-12">
              {reportType === 'process_details' ? (
                <ProcessDetails
                  reportData={reportData}
                  handleDownload={() => handlePayrollProcessReport('.xlsx')}
                  disabled={isLoading}
                />
              ) : (
                <QuoteDetails
                  reportData={reportData}
                  handleDownload={() => handlePayrollProcessReport('.xlsx')}
                  disabled={isLoading}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
