import React from 'react';
import { ButtonTooltip, Icon } from '../../../../components';

const MassiveActionDataProcess = ({ handleMassAction, disabled }) => {
  return (
    <div className="dflex row" style={{ 'marginRight': 'unset', 'marginLeft': 'unset' }}>
      <ButtonTooltip
        variant="circle-primary"
        className="mr-2 btn-circle mb-3"
        text="Exportar"
        disabled={disabled}
        onClick={() => handleMassAction('export')}
      >
        <Icon icon="download-2" />
      </ButtonTooltip>
    </div>
  );
};

export default MassiveActionDataProcess;
