import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';

const CustomFieldsInfo = ({ activeAccordion, activeKey, extraFieldsAttributes, customFields }) => {
  if (!extraFieldsAttributes || extraFieldsAttributes.length === 0) {
    return null;
  }

  const getFieldDisplayValue = (customField, extraField) => {
    if (!extraField) return null;

    if (customField.field_type === 'boolean') {
      return extraField.value === '1' ? 'Sí' : 'No';
    }
    if (customField.field_type === 'selector') {
      const matchingAttribute = customField.custom_field_types_attributes.find(e => String(e.id) === extraField.value);
      return matchingAttribute ? matchingAttribute.name : null;
    }

    return extraField.value;
  };

  const getExtraFieldForCustomField = customField => {
    return extraFieldsAttributes.find(field => field.customFieldId === customField.id);
  };

  return (
    <Card>
      <Col className={`${activeKey === 'custom-fields' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
        <Accordion.Toggle
          as={Col}
          eventKey="5"
          className="card-header-title"
          onClick={activeAccordion}
          style={{ textAlign: 'right' }}
        >
          CAMPOS PERSONALIZADOS
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="5">
        <Card.Body className="div-content">
          {customFields.map(
            customField =>
              customField.active && (
                <Row key={customField.id}>
                  <Col>
                    <div className="info">
                      <span className="type">{customField.name}:</span>
                      <span className="line" />
                      <span className="answer">
                        {getFieldDisplayValue(customField, getExtraFieldForCustomField(customField))}
                      </span>
                    </div>
                  </Col>
                </Row>
              )
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default CustomFieldsInfo;
