import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { createAdvancePaymentRequest } from '../../requests/advancePayments';
import { sendAlert } from '../../actions/utils';
import DataProcessesForm from './DataProcessesForm';

const basicDataProcess = {
  advanceNumber: '',
  amount: '',
  endDate: '',
  name: '',
  payDay: '',
  permanent: false,
  reason: '',
  startDate: '',
  employeeId: '',
  advancePaymentProcessId: '',
  paymentType: '',
  sourceType: false,
  balanceId: ''
};

const DataProcessesNew = ({
  onSuccess,
  action,
  apAmountLimit,
  apDayLimit,
  nextMonth,
  employeeId,
  rrhh = false,
  directApprove = false,
  apProcessId
}) => {
  const dispatch = useDispatch();

  useEffect(() => window.scrollTo(0, 0), []);

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Anticipo creado con éxito' }));
    onSuccess();
  };

  const handleCreateRequest = (params, setSubmitting) => {
    createAdvancePaymentRequest({
      dispatch,
      params: snakeCaseKeys(params),
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  return (
    <Row>
      <Col>
        <DataProcessesForm
          action={action || 'new'}
          nextMonth={nextMonth}
          advancePayment={{ ...basicDataProcess, directApprove: rrhh || directApprove, advanceProcessed: true }}
          apAmountLimit={apAmountLimit}
          apDayLimit={apDayLimit}
          employeeId={employeeId}
          formRequest={handleCreateRequest}
          rrhh={rrhh}
          apProcessId={apProcessId}
        />
      </Col>
    </Row>
  );
};

export default DataProcessesNew;
