import React from 'react';
import { Col, Row } from 'react-bootstrap';

const PeriodsInfo = ({ selectedRows, payrollProcess }) => {
  return (
    <>
      <Row>
        <Col sm={12} md={2} xl={2} className="top-header-light-gray border-radius-5 mr-2 mt-1 mb-1">
          <p className="mt-0">
            <b>Período Seleccionado: </b>
          </p>
        </Col>
        {selectedRows.length > 0 &&
          selectedRows.map(period => (
            <Col key={period.id} sm={12} md={2} xl={2} className="top-header-light-blue border-radius-5 mr-2 mt-1 mb-1">
              <p className="mt-0">
                <b>{period.name}</b>
              </p>
            </Col>
          ))}
      </Row>
      <Row>
        <Col sm={12} md={2} xl={2} className="top-header-light-gray border-radius-5">
          <p className="mt-0">
            <b>Nombre Nómina: </b>
          </p>
        </Col>
        <Col sm={12} md={2} xl={2} className="top-header-light-blue border-radius-5 mr-2 ml-2">
          <p className="mt-0">
            <b>
              {payrollProcess.month} / {payrollProcess.year}
            </b>
          </p>
        </Col>
      </Row>
    </>
  );
};

export default PeriodsInfo;
