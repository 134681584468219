import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useAbility } from '@casl/react';

import { indexAdvancePaymentRequest, generatePaymentAdvancePaymentRequest } from '../../../requests/advancePayments';
import { sendAlert } from '../../../actions/utils';
import { AbilityContext } from '../../../config/abilityContext';
import { ComponentDataTable, DefaultModal, FormikSelect, SimpleCenteredModal } from '../../../components';
import ItemsInfo from '../../Employee/Items/ItemsInfo';
import columns from './approveStyledColumns';
import AdvancePaymentInfoTable from './AdvancePaymentInfoTable';

const AdvancePaymentDataTableStyled = ({
  currentEmployee,
  defaultStatus = '0',
  employeesBelongBoss,
  customParams,
  defaultStartDate,
  moreData,
  setMoreData,
  setWorkflowIds = () => null
}) => {
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalButtons, setModalButtons] = useState(true);
  const [modalSize, setModalSize] = useState('lg');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [workflowRequests, setWorkflowRequests] = useState([]);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(defaultStatus);
  const apStatus = [
    { label: 'Todos', value: '' },
    { label: 'Pendiente', value: '0' },
    { label: 'Aprobado', value: '1' },
    { label: 'Rechazado', value: '2' }
  ];

  const handleStatus = () => {
    if (status !== '0' && employeesBelongBoss.length > 0) {
      return {
        filter_employee: employeesBelongBoss
      };
    }

    return {
      pending_review: currentEmployee
    };
  };

  const handleSuccessIndex = response => {
    const { data } = response.data;
    setAmount(response?.data?.metadata?.amount || 0);
    setWorkflowRequests(data);
    setWorkflowIds(data.map(element => element.id));
    setOnRequest(false);
  };

  const handleRequest = params => {
    const filterByStatus = handleStatus(status);
    setOnRequest(true);
    const sendParams = {
      ...params,
      ...customParams,
      ...filterByStatus,
      status
    };
    indexAdvancePaymentRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessIndex
    });
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
    setModalShow(false);
  };

  const generatePaymentDocument = (item = modalItem) => {
    setOnRequest(true);
    generatePaymentAdvancePaymentRequest(item.id, {
      dispatch,
      params: { date: defaultStartDate.format('MM_YY') },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Documento generado con éxito' }));
        setMoreData(!moreData);
      },
      callback: () => {
        handleModalClose();
        setOnRequest(false);
      }
    });
  };

  const handleManageRequest = item => {
    setModalBody(
      <AdvancePaymentInfoTable
        item={item}
        currentEmployee={currentEmployee}
        moreData={moreData}
        setModalShow={setModalShow}
        setMoreData={setMoreData}
      />
    );
    setModalButtons(false);
    setModalShow(true);
    setModalSize('xl');
  };

  const handleActions = (item, action) => {
    setModalItem({});
    const rut = item.employee.national_identification.replace(/\W+/g, '');
    const fileName = `${rut}_${customParams?.date}`;
    switch (action) {
      case 'actions':
        handleManageRequest(camelCaseRecursive(item));
        break;
      case 'payment_document':
        if (item.documents.filter(doc => doc.name.includes(fileName)).length >= 1) {
          setModalTitle('¡Ya existe un documento para este anticipo!');
          setModalShow(true);
          setModalBody('¿Estás seguro que deseas remplazarlo?');
          setModalAction(() => generatePaymentDocument);
          setModalItem(item);
        } else {
          generatePaymentDocument(item);
        }
        break;
      case 'show':
        setModalTitle('Anticipo');
        setModalBody(<ItemsInfo type="advancePayment" item={item} />);
        setModalItem(item);
        setCenterModalShow(true);
        break;
      case 'sign_request':
        setModalTitle(`Enviar a Firmar`);
        setModalShow(true);
        setModalItem(item);
        setModalBody(`¿Estás seguro que deseas enviar a firmar el documento de pago del mes seleccionado?`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee':
        return { sort_employees: name };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        columns={columns(handleActions, ability)}
        data={workflowRequests}
        handleSortCase={sortColumnCase}
        customHeaderCenter={
          <>
            <FormikSelect
              options={apStatus}
              label=""
              value={status}
              defaultValue={status}
              onChange={data => {
                setStatus(data ? data.value : '');
                setOnRequest(true);
                setMoreData(!moreData);
              }}
              placeholder="Todos"
            />
          </>
        }
        moreData={moreData}
        nameRangePicker="filter_dates"
        onRequest={onRequest}
        rangePicker
        resourceRequest={handleRequest}
        totalRows={amount}
        withEndDate
        withStartDate
      />
      <SimpleCenteredModal
        size="md"
        title={modalTitle}
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        body={modalBody}
        disabled={onRequest}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        modalSize={modalSize}
        show={modalShow}
        title={modalTitle}
        variantBtnClose="outline-info"
        variantBtnSave="primary"
        withClose={modalButtons}
        withConfirm={modalButtons}
      />
    </>
  );
};

export default AdvancePaymentDataTableStyled;
