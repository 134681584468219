import React from 'react';

const branchOfficeInfo = props => {
  const { branchOffice } = props;
  const {
    addressFloor,
    addressOffice,
    code,
    companyName,
    email,
    fullAddress,
    name,
    parsedPhone,
    lat,
    lng
  } = branchOffice;
  const active = branchOffice.active ? 'Sí' : 'No';

  return (
    <>
      <div className="info-box">
        <h4 className="text-uppercase">Información Lugar de Prestación de Servicios</h4>

        <p className="info">
          <span>Empresa:</span> {companyName}
        </p>
        <p className="info">
          <span>Nombre:</span> {name}
        </p>
        <p className="info">
          <span>Código:</span> {code}
        </p>
        <hr />
      </div>
      <div className="info-box">
        <h4 className="text-uppercase">Contacto</h4>
        <p className="info">
          <span>Dirección:</span> {fullAddress}
        </p>
        {addressFloor && (
          <p className="info">
            <span>Piso:</span> {addressFloor}
          </p>
        )}
        {addressOffice && (
          <p className="info">
            <span>Oficina:</span> {addressOffice}
          </p>
        )}
        {lat && (
          <p className="info">
            <span>Latitud:</span> {lat}
          </p>
        )}
        {lng && (
          <p className="info">
            <span>Longitud:</span> {lng}
          </p>
        )}
        {parsedPhone && (
          <p className="info">
            <span>Teléfono:</span> {parsedPhone}
          </p>
        )}
        {email && (
          <p className="info">
            <span>Email:</span> {email}
          </p>
        )}

        <p className="info">
          <span>Activo:</span> {active}
        </p>
      </div>
    </>
  );
};

export default branchOfficeInfo;
