import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { ComponentDataTable, DefaultModal, FormikSelect, SimpleCenteredModal } from '../../../components';
import { debounceIndexPermissionsRequest } from '../../../requests/permissions';
import PermissionInfo from '../../Profile/Permission/PermissionInfo';
import PermissionInfoTable from '../../Profile/Permission/PermissionInfoTable';
import columns from './approveStyledColumns';

const PermissionDataTableStyled = ({
  currentEmployee,
  defaultStatus = '0',
  employeesBelongBoss,
  moreData,
  setMoreData,
  todayMoment,
  showActions = true
}) => {
  const [amount, setAmount] = useState(0);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalButtons, setModalButtons] = useState(true);
  const [modalSize, setModalSize] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [workflowRequests, setWorkflowRequests] = useState([]);
  const [status, setStatus] = useState(defaultStatus);
  const permissionStatus = [
    { label: 'Todos', value: '' },
    { label: 'Aprobado', value: '1' },
    { label: 'Pendiente', value: '0' },
    { label: 'Rechazado', value: '2' }
  ];

  const dispatch = useDispatch();

  const handleCustomParams = () => {
    if (status !== '0' && employeesBelongBoss.length > 0) {
      return {
        filter_employee: employeesBelongBoss
      };
    }

    return {
      pending_review: currentEmployee
    };
  };

  const handleRequest = params => {
    const customParams = handleCustomParams();
    setOnRequest(true);
    const sendParams = {
      ...params,
      ...customParams,
      status
    };

    const request = async () => {
      debounceIndexPermissionsRequest({
        dispatch,
        params: sendParams,
        successCallback: response => {
          const { data, metadata } = response.data;
          setWorkflowRequests(data);
          setAmount(metadata.amount);
          setOnRequest(false);
        }
      });
    };
    request();
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
    setModalShow(false);
    setOnRequest(false);
  };

  const handleManageRequest = item => {
    setModalBody(
      <PermissionInfoTable item={item} moreData={moreData} setModalShow={setModalShow} setMoreData={setMoreData} />
    );
    setModalButtons(false);
    setModalShow(true);
    setModalSize('xl');
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        setModalTitle('Solicitud de Permiso');
        setModalBody(<PermissionInfo item={item} />);
        setCenterModalShow(true);
        break;
      case 'actions':
        handleManageRequest(camelCaseRecursive(item));
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee':
        return { sort_employees: name };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        columns={columns(handleActions, showActions)}
        data={workflowRequests}
        handleSortCase={sortColumnCase}
        customHeaderCenter={
          <FormikSelect
            options={permissionStatus}
            label=""
            value={status}
            defaultValue={status}
            onChange={data => {
              setStatus(data ? data.value : '');
              setOnRequest(true);
              setMoreData(!moreData);
            }}
            placeholder="Todos"
          />
        }
        moreData={moreData}
        defaultStartDate={todayMoment}
        defaultEndDate={todayMoment}
        onRequest={onRequest}
        rangePicker
        resourceRequest={handleRequest}
        totalRows={amount}
        withEndDate
        withStartDate
      />
      <SimpleCenteredModal
        title={modalTitle}
        size="md"
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        body={modalBody}
        disabled={onRequest}
        handleClose={handleModalClose}
        modalSize={modalSize}
        show={modalShow}
        title={modalTitle}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose={modalButtons}
        withConfirm={modalButtons}
      />
    </>
  );
};

export default PermissionDataTableStyled;
