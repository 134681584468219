import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, Card, Col, Row, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { useHistory } from 'react-router-dom';
import { SimpleCenteredModal } from '../../Utils/Modal';
import { sendAlert } from '../../../actions/utils';
import { updateEmployeeRequest } from '../../../requests/employees';
import Icon from '../../Icons';
import EmployeeQuickEdit from '../../../screens/Employee/EmployeeForm/EmployeeQuickEdit';
import { useAuthorization } from '../../../services/hooks';

const ContactData = ({ employee, activeAccordion, activeKey, employeeProfile }) => {
  const { email, fullAddress, parsedPhone, parsedWorkPhone, workEmail } = employee;

  const history = useHistory();

  const canRecordCard = useAuthorization('update_as_employee', 'Employee');

  const canUpdateData = employeeProfile && canRecordCard;

  const onlyIconButton = {
    padding: '0.2rem'
  };

  const [modalShow, setModalShow] = useState(false);
  const [fieldType, setFieldType] = useState('');
  const dispatch = useDispatch();

  const handleShowModal = field => {
    setFieldType(field);
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Trabajador actualizado con éxito' }));
    setModalShow(false);
    history.go(0);
  };

  const pickVariables = (_employee = {}, ...params) => ({
    ...params.reduce((prev, current) => ({ ...prev, [current]: _employee[current] }), {})
  });

  const handleUpdateRequest = (employeeValues, setSubmitting) => {
    const { employee: copyEmployee } = employeeValues;

    let myParams = {
      employee: { ...copyEmployee }
    };
    const keepVariables = {
      email: ['email'],
      phone: ['phoneCountryCode', 'phone'],
      address: ['address', 'addressNumber', 'addressApt', 'communeId', 'regionId']
    };

    myParams.employee = {
      ...pickVariables(myParams.employee, ...keepVariables[fieldType]),
      sendEmailUdpatedEmployee: true
    };

    myParams = snakeCaseKeys(myParams, {
      exclude: ['_destroy']
    });

    updateEmployeeRequest(employee.id, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  return (
    <Card className="mr-2">
      <Col className={`${activeKey === 'contact-data' ? 'top-header-green' : 'top-header-light-gray'} br-5`}>
        <Accordion.Toggle as={Col} eventKey="1" onClick={activeAccordion} className="card-header-title">
          DATOS DE CONTACTO
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="1">
        <Card.Body className="div-content">
          <Row>
            <Col>
              <div className="info">
                <span className="type">Teléfono laboral:</span>
                <span className="align-right" />
                <span className="answer"> {parsedWorkPhone}</span>
              </div>
              <div className="info">
                <span className="type">Email laboral:</span>
                <span className="align-right" />
                <span className="answer"> {workEmail}</span>
              </div>
              <div className="info">
                <span className="type">Teléfono personal:</span>
                <span className="align-right" />
                <span className="answer"> {parsedPhone}</span>
                {canUpdateData && (
                  <Button size="sm" className="ml-3" style={onlyIconButton} onClick={() => handleShowModal('phone')}>
                    <Icon icon="pencil" className="icon-15" />
                  </Button>
                )}
              </div>
              <div className="info">
                <span className="type">Email personal:</span>
                <span className="align-right" />
                <span className="answer" style={{ alignSelf: 'center' }}>
                  {' '}
                  {email}
                </span>
                {canUpdateData && (
                  <Button size="sm" className="ml-3" style={onlyIconButton} onClick={() => handleShowModal('email')}>
                    <Icon icon="pencil" className="icon-15" />
                  </Button>
                )}
              </div>
              <div className="info">
                <span className="type">Dirección:</span>
                <span className="align-right" />
                <span className="answer"> {fullAddress}</span>
                {canUpdateData && (
                  <Button size="sm" className="ml-3" style={onlyIconButton} onClick={() => handleShowModal('address')}>
                    <Icon icon="pencil" className="icon-15" />
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>

      <SimpleCenteredModal
        title="Datos de contacto"
        body={
          <EmployeeQuickEdit
            fieldType={fieldType}
            employee={employee}
            formRequest={handleUpdateRequest}
            handleModalClose={handleModalClose}
            dispatch={dispatch}
          />
        }
        show={modalShow}
        centered
        size="lg"
        onHide={() => setModalShow(false)}
      />
    </Card>
  );
};

export default ContactData;
