import React from 'react';
import memoize from 'memoize-one';

import { Button } from 'react-bootstrap';
import { ApprovementStatus } from '../../../components';

const shiftChangeStyledColumns = memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => item.employee.full_name,
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '1',
    wrap: true
  },
  {
    name: 'TURNO ACTUAL',
    selector: 'shift',
    cell: item => item.shift.name,
    sortable: true,
    grow: '1'
  },
  {
    name: 'REEMPLAZAR CON',
    selector: 'employee_replacement',
    cell: item => item.employee_replacement.full_name,
    sortable: true,
    grow: '2'
  },
  {
    name: 'TURNO A REEMPLAZAR',
    selector: 'shift_replacement',
    cell: item => item.shift_replacement.name,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA REEMPLAZO',
    selector: 'replacement_date',
    sortable: true,
    grow: '1',
    wrap: true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} />,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => {
      return (
        <>
          <Button variant="pw" block onClick={() => clickHandler(item, 'actions')} size="sm">
            Gestionar
          </Button>
        </>
      );
    },
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    center: true
  }
]);

export default shiftChangeStyledColumns;
