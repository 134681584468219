import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { showLoanProcessRequest } from '../../../requests/loanProcesses';
import LoanProcessSummaryCards from './LoanProcessSummaryCards';
import LoanProcessSummaryDataTable from './LoanProcessSummaryDataTable';
import { SimpleCenteredModal } from '../../../components';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';

const LoanProcessSummary = ({ match }) => {
  const [loanProcess, setLoanProcess] = useState(null);
  const [onRequest, setOnRequest] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const { id } = match.params;

  const fetchLoanProcess = () => {
    setOnRequest(true);
    showLoanProcessRequest(id, {
      dispatch,
      successCallback: response => {
        setLoanProcess(camelCaseEmptyStringRecursive(response.data));
      },
      callback: () => setOnRequest(false)
    });
  };

  useEffect(fetchLoanProcess, []);

  return (
    <>
      {onRequest && (
        <div className="containerSpinnerLoad position-absolute h-100" style={{ bottom: '35px', left: '130px' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <LoanProcessSummaryCards loanProcess={loanProcess} />
      <LoanProcessSummaryDataTable loanProcess={loanProcess} />
      <SimpleCenteredModal
        title="Nuevo Proceso de PRÉSTAMO / CRÉDITO"
        body={<div> Some</div>}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default LoanProcessSummary;
