/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { ApprovementStatus, DatatableEmployeeName } from '../../../components';
import { handleDaysShow } from '../../../services/utils';

const vacationColumns = memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '2'
  },
  {
    name: 'CARGO',
    selector: 'job_title',
    cell: item => item.employee?.job_title,
    sortable: false,
    grow: '2'
  },
  {
    name: 'ÁREA',
    selector: 'job_management',
    cell: item => item.employee?.job_management,
    sortable: false,
    grow: '2'
  },
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'created_at',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO DE VACACIÓN',
    selector: 'vacation_type',
    cell: item => item.translated_vacation_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'DESDE',
    selector: 'start_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'HASTA',
    selector: 'end_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TOTAL DÍAS',
    selector: 'days',
    cell: item => handleDaysShow(item, 'days'),
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1',
    center: true
  }
]);

export default vacationColumns;
